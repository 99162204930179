import styled from 'styled-components';

export const Styles = styled.div`
  table:not(td table) {
    min-width: calc(100vw - 22rem);
    border-spacing: 0;
    #header-row {
      background-color:#D8D8D8  
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    thead th {
      height: 40px;
    }

    th,
    td {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      font-size: 14px;
    }

    tr:first-child {
      background-color: #efefef;
    }
    tr:nth-child(even) {
    background-color: #cdcdcd;
    }
    tr:nth-child(odd) {
    background-color: #fff;
    }
}
  input {
    background-color: #fff;
  }
  .sort-icon {
    color: white;
    background-color: black;
    border-radius: 0.15rem:
    margin: 1rem;
    height: 0.8rem;
    width: 0.8rem;
  }
`;
