import { Accordion, Grid, Text, Badge, Button, Checkbox } from '@mantine/core';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from '../../../../common/table/VeamScopeTable';
import { AppLoader } from '../../../common/loader';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import ContentPaper from '../../../layouts/rfs/Content';
import { ActionModal } from '../../../../pages/veam/productDevelopment/actionModal';
import ProductionLog from '../../../../pages/veam/productDevelopment/productionSiteLogModal';
import { RfsContext } from '../../../../contexts/RfsContext';
import { CSVLink } from 'react-csv';
import { TableExport } from 'tabler-icons-react';
import { implementationPermission } from '../../../../constants';
import { NoData } from '../../../../common/noData';

export const TransferPricing = ({ activeTab }) => {
  const params = useParams();
  const [state, handlers] = useState(0);
  const [infoLoading, setInfoLoading] = useState(false);
  const [skus, setSkus] = useState([]);
  const [manualClosureLoading, setManualClosureLoading] = useState(false);
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const { form } = useContext(RfsContext);
  const { rfs_type } = form.rfsInformation;
  const permissions = implementationPermission[rfs_type];

  useEffect(() => {
    handlers(0);
  }, []);

  useEffect(() => {
    if (activeTab === 'tp') fetchInfo();
  }, [params.rfsNumber, activeTab]);

  const save = (payload, id) => {
    payload = { ...payload, comment: payload.remark, status_id: payload.status_ids };
    delete payload.remark; // deleting remark because required fieldname is comment
    delete payload.status_ids; // deleting remark because required fieldname is comment

    setManualClosureLoading(true);
    axios
      .post(`/manualClosure/create`, payload)
      .then(() => {
        fetchInfo(false);
        setManualClosureLoading(false);
      })
      .finally(() => setActionModal(false));
  };

  function fetchInfo(loading = true) {
    setInfoLoading(loading);
    axios
      .get(`/openSku/implementation/transfer-pricing/fetch/${params.rfsNumber}`)
      .then(
        (res) => {
          setSkus(res.data.data);
        },
        (error) => {},
      )
      .finally(() => setInfoLoading(false));
  }

  const getLogs = (imp_id, event) => {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  };

  const getStatus = (data, type) => {
    const info = data.other_status.filter((item) => item[type]);
    return info[0] ? info[0][type] : 'Not Started';
  };

  const getCsvData = () => {
    const data = [];

    [...skus].forEach((info) => {
      const standardPricing = getStatus(info, 'Standard Price');
      const vendorOpen = getStatus(info, 'Vendor Open');
      const vicCheck = getStatus(info, 'Vic Check');
      const vlcCheck = getStatus(info, 'Vlc Check');
      const transferPricing = getStatus(info, 'Transfer Check');

      data.push({
        'SKU Code': info.int_rfs_scope_selection?.selection_number,
        'SKU Description': info.int_rfs_scope_selection?.selection_name,
        'New Markets': info.int_rfs_sku?.market,
        'Standard Price': standardPricing,
        'Vendor Open': vendorOpen,
        'VIC Check': vicCheck,
        'VLC Check': vlcCheck,
        'Transfer Check': transferPricing,
        Action:
          info.status === 'Closed'
            ? info.manually_closed
              ? 'Manually Closed'
              : 'Automatic Closure'
            : 'NA',
        'Step Deadline': info.sla_date,
        'Step Finished': info.finished_date,
      });

      return info;
    });

    return data;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'New Markets',
        maxWidth: '120px',
        accessor: 'new-market',
        filter: false,
        Cell: ({ row }) => row.original.int_rfs_sku.market,
      },
      {
        Header: 'Standard Price',
        accessor: 'standard-price',
        filter: false,
        Cell: ({ row }) => {
          const data = row.original.other_status.filter((item) => item['Standard Price']);
          const status = data[0] ? data[0]['Standard Price'] : 'Not Started';

          return (
            <Badge
              color={status === 'In Progress' ? 'yellow' : status === 'Closed' ? 'green' : 'gray'}
              size='lg'
            >
              {status}
            </Badge>
          );
        },
      },
      {
        Header: 'Vendor Open',
        accessor: 'vendor-open',
        filter: false,
        Cell: ({ row }) => {
          const data = row.original.other_status.filter((item) => item['Vendor Open']);
          const status = data[0] ? data[0]['Vendor Open'] : 'Not Started';

          return (
            <Badge
              color={status === 'In Progress' ? 'yellow' : status === 'Closed' ? 'green' : 'gray'}
              size='lg'
            >
              {status}
            </Badge>
          );
        },
      },

      {
        Header: 'VIC Check',
        accessor: 'vic-check',
        filter: false,
        Cell: ({ row }) => {
          const data = row.original.other_status.filter((item) => item['Vic Check']);
          const status = data[0] ? data[0]['Vic Check'] : 'Not Started';

          return (
            <Badge
              color={status === 'In Progress' ? 'yellow' : status === 'Closed' ? 'green' : 'gray'}
              size='lg'
            >
              {status}
            </Badge>
          );
        },
      },
      {
        Header: 'VLC Check',
        accessor: 'vlc-check',
        filter: false,
        Cell: ({ row }) => {
          const data = row.original.other_status.filter((item) => item['Vlc Check']);
          const status = data[0] ? data[0]['Vlc Check'] : 'Not Started';

          return (
            <Badge
              color={status === 'In Progress' ? 'yellow' : status === 'Closed' ? 'green' : 'gray'}
              size='lg'
            >
              {status}
            </Badge>
          );
        },
      },
      {
        Header: 'Transfer Check',
        accessor: 'tansfer-check',
        filter: false,
        Cell: ({ row }) => {
          const data = row.original.other_status.filter((item) => item['Transfer Check']);
          const status = data[0] ? data[0]['Transfer Check'] : 'Not Started';

          return (
            <Badge
              color={status === 'In Progress' ? 'yellow' : status === 'Closed' ? 'green' : 'gray'}
              size='lg'
            >
              {status}
            </Badge>
          );
        },
      },
      {
        Header: 'Log',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              disabled={['Not Started'].includes(row.original.status)}
              color='dark'
              onClick={() => getLogs(row.original.id)}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Action',
        disableSortBy: true,
        Cell: ({ row }) => {
          return row.original.status === 'In Progress' || row.original.status === 'Not Started' ? (
            <Checkbox
              onChange={() => {
                setActionModal({ open: true, id: row.original.id });
              }}
              defaultChecked={false}
              label='Confirm and Close'
              disabled={row.original.status === 'Not Started' || !permissions['transfer_pricing']}
            />
          ) : row.original.manually_closed && row.original.status === 'Closed' ? (
            <Text>Manual Closure</Text>
          ) : (
            <Text>Automatic Closure</Text>
          );
        },
      },
      {
        Header: 'Step Deadline',
        accessor: 'sla_date',
        filter: false,
        Cell: ({ row }) => {
          const date = row.original.sla_date;
          return <Text>{date ? moment(date).format('YYYY-MM-DD') : 'N/A'}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        accessor: 'finished_date',
        filter: false,
        Cell: ({ row }) => {
          const date = row.original.finished_date;
          const status = row.original.status;
          return <Text>{date ? moment(date).format('YYYY-MM-DD') : status}</Text>;
        },
      },
    ],
    [],
  );

  if (infoLoading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <CSVLink
          data={getCsvData()}
          filename={'Transfer Pricing_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button
            leftIcon={<TableExport />}
            color='dark'
            style={{
              marginRight: '30px ',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      {skus.length === 0 && !infoLoading ? (
        <NoData />
      ) : (
        <Accordion id='outside' state={state} onChange={handlers}>
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              const accordionKey = `accordion_${index}`;
              const childs = Array.isArray(skus)
                ? (skus || []).filter((sku) => sku?.scope_id === item?.id)
                : [];

              return (
                childs.length > 0 && (
                  <Accordion.Item value={accordionKey} key={accordionKey}>
                    <Accordion.Control>
                      {`${name.substring(0, 3).toUpperCase()} - ${item['selection_number']} - ${
                        item['selection_name']
                      }`}
                    </Accordion.Control>

                    <Accordion.Panel>
                      <Grid>
                        <Grid.Col span={12}>
                          <Table
                            columns={columns}
                            data={skus.filter((sku) => sku.scope_id === item.id)}
                            selections={false}
                          />
                        </Grid.Col>
                      </Grid>
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            }),
          )}
        </Accordion>
      )}

      {actionModal.open && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={actionModal.id}
          state={state}
          updateLoading={manualClosureLoading}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Transfer Pricing'
        />
      )}
    </div>
  );
};
