import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { RfsContext } from '../../../contexts/RfsContext';
import { Row, Col } from 'react-bootstrap';
import { Select, SimpleGrid, Grid, Text, Button, Textarea, Accordion } from '@mantine/core';

import { DatePickerInput } from '@mantine/dates';
import moment from 'moment';
import { axios } from '../../../utils/axios';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { AppLoader } from '../../../components/common/loader';

import '../../impFeasibility/impFeasibility.css';
import { rfsStatus } from '../../../constants';
import ProjectName from '../../../components/projectName/projectName';
import { ImpFeasTable } from './ImpFeasibilityTable';
import { roles } from '../../../constants';
import { AuthContext } from '../../../contexts/AuthContext';

export default function ImpFeasiblityProdSite(props) {
  const { userRole } = useContext(AuthContext);
  const openRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const { form, updateRfsStatus } = useContext(RfsContext);
  const {
    project_name,
    first_shipment,
    trials_needed,
    submission_date,
    fast_track,
    status,
    rfs_type,
  } = form.rfsInformation;
  const [firstShipmentPmo, setFirstShipmentPmo] = useState(
    moment(new Date()).add(11, 'week').toDate(),
  );
  const [fastTrackPmo, setFastTrackPmo] = useState(fast_track ? 'Yes' : 'No');
  const [attachment, setAttachment] = useState({});
  const [impFeasibility, setImpFeasibility] = useState({});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState({});
  const [validate, setValidate] = useState(false);
  const [validateFeilds, setValidateFeilds] = useState(false);
  const [submitProjectModal, setSubmitProjectModal] = useState(false);
  const [impLoading, setImpLoading] = useState(false);
  const accessors = {
    materials: 'selection_number',
    liquids: 'selection_number',
    skus: 'selection_number',
  };
  const secondaryAccessor = {
    materials: 'selection_name',
    liquids: 'selection_name',
    skus: 'selection_name',
  };
  const tertiaryAccessor = {
    materials: null,
    liquids: null,
    skus: null,
  };
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });

  const editable = () => {
    return status === rfsStatus.imp_feasible;
  };
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo].includes(userRole)) setDisable(false);
  }, [userRole]);

  useEffect(() => {
    if (rfs_type.length > 0) {
      setFastTrackPmo(fast_track ? 'Yes' : 'No');

      fetchImpFeasibility(params.rfsNumber);
    }
  }, [rfs_type.length]);
  const fetchImpFeasibility = (rfsNumber) => {
    setImpLoading(true);
    axios
      .get(`/implementation/${rfsNumber}`)
      .then(
        (res) => {
          const response = res.data.fetchedData[0];
          let saveResponse = response === undefined ? {} : response;
          setFetched(saveResponse);
          if (
            Object.keys(saveResponse).length > 0 &&
            (rfs_type === 'Open SKU to market' || rfs_type === 'Production Site')
          ) {
            setFirstShipmentPmo(
              moment(first_shipment).isValid() ? moment(first_shipment).toDate() : firstShipmentPmo,
            );
          }
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setImpLoading(false));
  };

  const Footer = () => (
    <Row>
      <Col md={{ span: 2, offset: 8 }}>
        <Button
          onClick={() => handleNext(props.trials === true ? 'Impl Feas Rejected' : 'rejected')}
          className='rejectpr'
          color='red'
          radius='md'
          size='md'
          fullWidth
          style={{
            color: 'white',
            width: '200px',
          }}
        >
          Reject Project
        </Button>
      </Col>
      <Col md={{ span: 2 }}>
        <Button
          onClick={() => handleNext('Implementation')}
          className='approvepr'
          color='green'
          radius='md'
          size='md'
          fullWidth
          style={{
            color: 'white',
            width: '200px',
          }} 
        >
          Approve Project
        </Button>
      </Col>
    </Row>
  );
  const createImpFeasibility = (payload, callback) => {
    let formData = new FormData();
    if (payload.attachment.files !== undefined) {
      if (payload.attachment.files.length > 0) {
        for (let k in payload.attachment.files) {
          if (false) {
          } else {
            formData.append('attachments', payload.attachment.files[k]);
            formData.append('bus_unit', payload.prod_bus_unit);
            formData.append('rfs', payload.rfs_number);
            formData.append('status', payload.tf_status);
          }
        }
      } else {
        formData.append('attachmentId[]', []);
        formData.append('bus_unit', payload.prod_bus_unit);
        formData.append('rfs', payload.rfs_number);
        formData.append('status', payload.tf_status);
        formData.append('first_shipment', payload.first_shipment);
        formData.append('fast_track', payload.fast_track);
      }

      axios
        .put(`/implementation`, formData, {
          prod_bus: payload.prod_bus_unit,
          rfs: payload.rfs_number,
          tf: payload.tf_status,
          first_shipment: payload.first_shipment,
          fast_track: payload.fast_track,
        })
        .then(
          (res) => {
            callback();
          },
          (err) => {
            console.log(err);
          },
        );
    } else {
      formData.append('attachments', []);
      formData.append('bus_unit', payload.prod_bus_unit);
      formData.append('rfs', payload.rfs_number);
      formData.append('status', payload.tf_status);
      formData.append('first_shipment', payload.first_shipment);
      formData.append('fast_track', payload.fast_track);
      axios
        .put(`/implementation`, formData, {
          prod_bus: payload.prod_bus_unit,
          rfs: payload.rfs_number,
          tf: payload.tf_status,
          first_shipment: payload.first_shipment,
          fast_track: payload.fast_track,
        })
        .then(
          (res) => {
            callback();
          },
          (err) => {
            console.log(err);
          },
        );
    }
  };

  const updateImpFeasibility = (payload, callback) => {
    axios.put(`/implementation/${params.rfsNumber}`, payload).then(
      (res) => {
        callback();
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const isDisabled = () => {
    return !fetched.prod_bus_unit || (fast_track && !fastTrackPmo);
  };

  const handleNext = (type) => {
    setValidate(true);
    if (isDisabled()) {
      setSubmitProjectModal(true);
      setValidateFeilds(true);
    } else {
      if (fetched.id) {
        updateRfsStatus(params.rfsNumber, type);
        updateImpFeasibility(
          {
            prod_bus_unit: fetched.prod_bus_unit,
            tf_status: type,
            rfs_number: params.rfsNumber,
          },
          () => window.location.reload(),
        );
      } else {
        updateRfsStatus(params.rfsNumber, type);
        createImpFeasibility(
          {
            prod_bus_unit: fetched.prod_bus_unit,
            attachment: attachment,
            tf_status: type,
            rfs_number: params.rfsNumber,
            first_shipment: moment(firstShipmentPmo).format('YYYY-MM-DD'),
            fast_track: fastTrackPmo,
          },
          () => window.location.reload(),
        );
      }
    }
  };

  const handleSelect = (value) => {
    const currentValues = { ...fetched };
    currentValues['prod_bus_unit'] = value;
    setFetched(currentValues);
  };

  if (loading || rfs_type.length === 0 || impLoading)
    return (
      <ContentPaper>
        <AppLoader center size='lg' />
      </ContentPaper>
    );

  return (
    <ContentPaper page='imp-feasibility' footer={editable() ? <Footer /> : null}>
      <ProjectName initialItem={0} />
      <Row>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col
          md={{ span: 6 }}
          style={{
            marginTop: '1rem',
          }}
        >
          <h5 className='sectionHeadings'>Implementation Feasibility</h5>
        </Col>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600}>
              Project Name:
            </Text>
            <Text size='sm' weight={400}>
              {project_name}
            </Text>
          </SimpleGrid>
        </Col>

        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600}>
              Project Submission Date:
            </Text>
            <Text size='sm' weight={400}>
              {submission_date}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600}>
              First Shipment Date:
            </Text>
            <Text size='sm' weight={400}>
              {first_shipment}
            </Text>
          </SimpleGrid>
        </Col>

        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            {isDisabled() && rfs_type === 'Article Modification/Value Engineering' ? (
              <Text size='sm' weight={600} color='red'>
                Production Business Unit*:
              </Text>
            ) : (
              <Text size='sm' weight={600}>
                Production Business Unit:
              </Text>
            )}
            <Select
              disabled={!editable() || disable || rfs_type === 'Open SKU to market'}
              required
              className='impfeasibility-input'
              searchable
              clearable={editable()}
              nothingFound='No such Production Business Unit'
              radius='md'
              onChange={(value) => handleSelect(value)}
              data={[
                'BE',
                'NL',
                'FR',
                'LU',
                'DE',
                'UK',
                'IT',
                'ES',
                'RUK',
                'Expansion South',
                'Expansion North',
                'EXTREME',
              ]}
              name='prodBusinessUnit'
              value={fetched?.prod_bus_unit}
            />
          </SimpleGrid>
        </Col>
      </Row>
      {(Object.keys(impFeasibility).length > 0 ||
        rfs_type === 'Open SKU to market' ||
        rfs_type === 'Production Site') && (
        <>
          <Row>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              <SimpleGrid cols={2} style={{ marginTop: '0.5rem' }}>
                <Text size='sm' weight={600}>
                  Obsoletes Calculation:
                </Text>
                <Button
                  color='dark'
                  onClick={() => setModal(true)}
                  disabled={rfs_type === 'Open SKU to market' || rfs_type === 'Production Site'}
                >
                  Obsoletes Calculation
                </Button>
              </SimpleGrid>
            </Col>

            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              <SimpleGrid cols={2} style={{ marginTop: '0.5rem' }}>
                <Text size='sm' weight={600}>
                  Obsoletes Attachment:
                </Text>
                <Button
                  color='dark'
                  onClick={() => openRef.current()}
                  disabled={
                    !editable() ||
                    rfs_type === 'Open SKU to market' ||
                    rfs_type === 'Production Site'
                  }
                >
                  Obsoletes Attachment
                </Button>
              </SimpleGrid>
            </Col>
          </Row>

          {(fast_track && trials_needed == 'No') ||
          ((rfs_type === 'Open SKU to market' || rfs_type === 'Production Site') && fast_track) ? (
            <Row style={{ marginTop: '1rem' }}>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2}>
                  {isDisabled() ? (
                    <Text size='sm' weight={600} color='red'>
                      Fast Track:
                    </Text>
                  ) : (
                    <Text size='sm' weight={600}>
                      Fast Track:
                    </Text>
                  )}
                  <Select
                    disabled={!editable() || (rfs_type === 'Open SKU to market' && !fast_track)}
                    error={isDisabled()}
                    required
                    radius='md'
                    data={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                    name='fastTrackPmo'
                    value={fastTrackPmo}
                    onChange={(value) => setFastTrackPmo(value)}
                  />
                </SimpleGrid>
              </Col>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
              {fastTrackPmo === 'Yes' ? null : (
                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2}>
                    <Text size='sm' weight={600}>
                      First Shipment Date:
                    </Text>
                    <DatePickerInput
                      disabled={
                        !disable ||
                        !editable() ||
                        (rfs_type === 'Open SKU to market' && !fast_track)
                      }
                      name='firstShipmentPmo'
                      value={firstShipmentPmo || new Date()}
                      onChange={(e) => {
                        setFirstShipmentPmo(e);
                      }}
                      placeholder='Pick date'
                      minDate={moment(new Date()).add(11, 'days').toDate()}
                    />
                  </SimpleGrid>
                </Col>
              )}
            </Row>
          ) : null}
        </>
      )}
      <br />

      <Row>
        <Accordion>
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              return (
                <Accordion.Item
                  className='scope-label'
                  key={`key_${name}_${index}`}
                  value={`key_${name}_${index}`}
                >
                  <Accordion.Control className={scopeErrors.errors[item.id] ? 'error-label' : ''}>
                    {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                      item[secondaryAccessor[name]]
                    }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                  </Accordion.Control>
                  <Accordion.Panel>
                    {
                      <Grid>
                        <Grid.Col span={12}>
                          <ImpFeasTable
                            name={item[accessors[name]]}
                            scope={item}
                            isSubmissionReview
                          />
                        </Grid.Col>
                      </Grid>
                    }
                  </Accordion.Panel>
                </Accordion.Item>
              );
            }),
          )}
        </Accordion>
      </Row>
    </ContentPaper>
  );
}
