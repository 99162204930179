import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Table, Tabs, Title, Accordion, Text, Center, Checkbox } from '@mantine/core';
import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import { getBody, getHeader } from '../../productDevelopment/TableHelper';
import { ProductDevelopmentTableMaterial } from '../../../components/tables/ProductDevelopmentTable/productDevelopmentTableMaterial';
import { ProductDevelopmentTableLiquid } from '../../../components/tables/ProductDevelopmentTable/productDevelopmentTableLiquids';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';
import { AppLoader } from '../../../components/common/loader';
import { RfsContext } from '../../../contexts/RfsContext';
import ViewSkus from '../../../components/modal/productdevelopmentmodals/view-skus';
import MaterialCreationCodeVeam from './Material Code Creation/MaterialCreation';
import Supplier from './Supplier';
import StatusCheckVEAM from './StatusCheck/Statuscheck';
import ChangeOverVEAM from './ChangeOver/ChangeOver';
import MaterialsContract from './MaterialsContract/MaterialsContract';
import TrialsVEAM from './Trials/TrialsVEAM';
import ArtworkDevelopmentCode from './ArtworkDevelopment/ArtworkDevelopment';
import EanApprovalCode from './Ean/Ean';
import ReversalVEAM from './Reversal';
import Dot from './Dot';
import { prodDevPermissions } from '../../../constants';
import { AuthContext } from '../../../contexts/AuthContext';
import { VeamContext } from '../veamContext';
import { tabStyles } from '../../../utils/helpers';
import { routes } from '../../../routes';

export default function ProductDevlopmentComp({ trials = false, scopeUnlock = false, editable }) {
  const {
    //fetchProductdevelopmentMaterials,
    getVendors,
    materials,
    liquids,
    scopeLiquids,
    //getLiquids,
    getScopeLiquids,
    reset,
    loading,
    getSLAWeeksIdsForRFS,
  } = useContext(ProjectOverviewContext);

  const { form, downloadFile } = useContext(RfsContext);
  const { userRole } = useContext(AuthContext);
  const { fetchAllSteps } = useContext(VeamContext);
  const [tab, setTab] = useState('mcc');
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { rfs_number, rfs_type = '' } = form.rfsInformation;

  const [skuModel, setSkuModel] = useState({ open: false, plant: null });
  const [slaWeeksIds, setSlaWeeksIds] = useState([]);
  const [trialsReversal, setTrialsReversal] = useState({ display: 'none' });

  const [loader, setLoader] = useState([]);
  const [tabStatus, setTabStatus] = useState({});

  const checkClosuresMat = (d) => setTabStatus({ ...tabStatus, material_creation: d });
  const checkClosuresStatus = (d) => setTabStatus({ ...tabStatus, status_check: d });
  const checkClosuresSupplier = (d) => setTabStatus({ ...tabStatus, supplier_name: d });
  const checkClosuresChangeOver = (d) => setTabStatus({ ...tabStatus, changeover: d });
  const checkClosuresArtWork = (d) => setTabStatus({ ...tabStatus, artwork_development: d });
  const checkClosuresEAN = (d) => setTabStatus({ ...tabStatus, ean_approval: d });
  const checkClosuresMatConract = (d) => setTabStatus({ ...tabStatus, material_contracts: d });
  const checkClosuresTrials = (d) => setTabStatus({ ...tabStatus, trials: d });
  const params = useParams();

  useEffect(() => {
    getVendors();
    //getLiquids(trials);
    getScopeLiquids();
    //fetchProductdevelopmentMaterials(trials);
    return () => reset();
  }, []);

  useEffect(() => {
    fetchSLAWeeksIds(rfs_number);
    fetchAllStepsFn(rfs_number);
  }, [rfs_number]);

  useEffect(() => {
    const sub = urlParams.get('sub');
    if (sub) {
      setTab(sub);
    }
  }, [urlParams]);

  useEffect(() => {
    if (slaWeeksIds.length > 0) setTab(checkWithSLAWeeksData([211, 227]) ? 'mcc' : 'sc');
  }, [slaWeeksIds]);

  const handleViewSkus = (data) => {
    setSkuModel({ open: true, plant: data.plant });
  };

  const fetchAllStepsFn = (rfs_number) => {
    fetchAllSteps(rfs_number).then((res) => {
      let obj = {};
      let fetchedData = res.data.data;
      obj['material_creation'] = fetchedData.material_creation.difference;
      obj['status_check'] = fetchedData.status_check.difference;
      obj['supplier_name'] = fetchedData.supplier_name.difference;
      obj['changeover'] = fetchedData.changeover.difference;
      obj['artwork_development'] = fetchedData.artwork_development.difference;
      obj['ean_approval'] = fetchedData.ean_approval.difference;
      obj['material_contracts'] = fetchedData.material_contracts.difference;
      obj['trials'] = fetchedData.trials.difference;
      obj['trialReversal'] = fetchedData.trialReversal.All.difference;

      setTabStatus(obj);
    });
  };

  const fetchSLAWeeksIds = (rfs_number) => {
    rfs_number =
      rfs_number === undefined
        ? window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]
        : rfs_number;
    setLoader(true);
    getSLAWeeksIdsForRFS(rfs_number).then((res) => {
      let sla_weeks_ids = [];
      res.data.data.map(
        (item) =>
          sla_weeks_ids.includes(item.sla_weeks_id) === false &&
          sla_weeks_ids.push(item.sla_weeks_id),
      );
      setSlaWeeksIds(sla_weeks_ids);
      setTrialsReversal({ display: 'block' });

      setLoader(false);
    });
  };

  const checkWithSLAWeeksData = (arr) => arr.reduce((a, c) => a + slaWeeksIds.includes(c), 0) == 1;

  const array = [
    '211',
    '212',
    '213',
    '214',
    '215',
    '216',
    '217',
    '218',
    '227',
    '228',
    '229',
    '230',
    '231',
    '232',
    '233',
    '234',
    '252',
    '253',
    '254',
    '255',
  ];

  if (
    (scopeLiquids.length === 0 && materials.length === 0 && loading) ||
    loader ||
    rfs_type.length === 0
  )
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader center size='lg' />
      </ContentPaper>
    );

  const permissions = prodDevPermissions[form.rfsInformation.rfs_type];

  return (
    <>
      <ContentPaper page={'ProductDevelopment'}>
        <ProjectName initialItem={0} />
        <br />
        {scopeUnlock && (
          <Text className='padded-error'>
            <Center>Changeover removal in Progress</Center>
          </Text>
        )}
        <br />

        <Tabs
          onTabChange={(value) =>
            navigate(
              `${routes.project(params.rfsNumber).root}?tab=${urlParams.get('tab')}&sub=${value}`,
            )
          }
          grow
          variant='unstyled'
          styles={tabStyles}
          className='prod-dev-tabs prod-dev-tabs-veam'
          color='primary'
          value={tab}
          keepMounted={false}
        >
          <Tabs.List>
            {checkWithSLAWeeksData([211, 227]) && (
              <Tabs.Tab className='topTabs' value='mcc'>
                Materials Code Creation <Dot dotStatus={tabStatus.material_creation} />
              </Tabs.Tab>
            )}
            {checkWithSLAWeeksData([212, 228]) && (
              <Tabs.Tab className='topTabs' value='sc'>
                Status Check <Dot dotStatus={tabStatus.status_check} />
              </Tabs.Tab>
            )}
            {checkWithSLAWeeksData([213, 229]) && (
              <Tabs.Tab className='topTabs' value='sn'>
                Supplier Name <Dot dotStatus={tabStatus.supplier_name} />
              </Tabs.Tab>
            )}
            {checkWithSLAWeeksData([214, 230]) && (
              <Tabs.Tab className='topTabs' value='co'>
                Changeover <Dot dotStatus={tabStatus.changeover} />
              </Tabs.Tab>
            )}
            {checkWithSLAWeeksData([215, 231]) && (
              <Tabs.Tab className='topTabs' value='ad'>
                Artwork Development <Dot dotStatus={tabStatus.artwork_development} />
              </Tabs.Tab>
            )}
            {checkWithSLAWeeksData([216, 232]) && (
              <Tabs.Tab className='topTabs' value='ean'>
                EAN Approval <Dot dotStatus={tabStatus.ean_approval} />
              </Tabs.Tab>
            )}
            {checkWithSLAWeeksData([217, 233]) && (
              <Tabs.Tab className='topTabs' value='mc'>
                Materials Contract <Dot dotStatus={tabStatus.material_contracts} />
              </Tabs.Tab>
            )}
            {checkWithSLAWeeksData([234]) && (
              <Tabs.Tab className='topTabs' value='tr'>
                Trials <Dot dotStatus={tabStatus.trials} />
              </Tabs.Tab>
            )}
            {(slaWeeksIds.includes(252) ||
              slaWeeksIds.includes(253) ||
              slaWeeksIds.includes(254) ||
              slaWeeksIds.includes(255)) && (
              <Tabs.Tab className='topTabs' value='trv' style={trialsReversal}>
                {' '}
                Trials Reversal <Dot dotStatus={tabStatus.trialReversal} />
              </Tabs.Tab>
            )}
            {!array.some((r) => slaWeeksIds.indexOf(parseInt(r)) >= 0) && (
              <Tabs.Tab className='topTabs' value='nd'>
                No Data
              </Tabs.Tab>
            )}
          </Tabs.List>

          {checkWithSLAWeeksData([211, 227]) && (
            <Tabs.Panel value='mcc'>
              <MaterialCreationCodeVeam
                checkClosuresMat={checkClosuresMat}
                readOnly={!permissions['material_code_creation'].includes(userRole)}
              />
            </Tabs.Panel>
          )}

          {checkWithSLAWeeksData([212, 228]) && (
            <Tabs.Panel value='sc'>
              <div>
                <StatusCheckVEAM
                  checkClosuresStatus={checkClosuresStatus}
                  readOnly={!permissions['status_check'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {checkWithSLAWeeksData([213, 229]) && (
            <Tabs.Panel value='sn'>
              <div>
                <Supplier
                  checkClosuresSupplier={checkClosuresSupplier}
                  readOnly={!permissions['supplier_name'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {checkWithSLAWeeksData([214, 230]) && (
            <Tabs.Panel value='co'>
              <div>
                <ChangeOverVEAM
                  checkClosuresChangeOver={checkClosuresChangeOver}
                  readOnly={!permissions['changeover'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {checkWithSLAWeeksData([215, 231]) && (
            <Tabs.Panel value='ad'>
              <div>
                <ArtworkDevelopmentCode
                  checkClosuresArtWork={checkClosuresArtWork}
                  readOnly={!permissions['artowrk_development'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {checkWithSLAWeeksData([216, 232]) && (
            <Tabs.Panel value='ean'>
              <div>
                <EanApprovalCode
                  checkClosuresEAN={checkClosuresEAN}
                  readOnly={!permissions['ean'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {checkWithSLAWeeksData([217, 233]) && (
            <Tabs.Panel value='mc'>
              <Box
                sx={(theme) => ({
                  backgroundColor: 'black',
                  textAlign: 'center',
                  padding: theme.spacing.sm,
                  borderRadius: theme.radius.md,
                  left: '1rem',
                  color: '#e5b611',
                  fontSize: '1.5em',
                  fontWeight: '700',
                  '&:hover': {
                    backgroundColor: 'black',
                  },
                })}
              >
                Material code
              </Box>
              <div>
                <MaterialsContract
                  checkClosuresMatConract={checkClosuresMatConract}
                  readOnly={!permissions['material_contracts'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {checkWithSLAWeeksData([234]) && (
            <Tabs.Panel value='tr'>
              <div>
                <TrialsVEAM
                  checkClosuresTrials={checkClosuresTrials}
                  readOnly={!permissions['trials'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {(slaWeeksIds.includes(252) ||
            slaWeeksIds.includes(253) ||
            slaWeeksIds.includes(254) ||
            slaWeeksIds.includes(255)) && (
            <Tabs.Panel value='trv'>
              <div>
                <ReversalVEAM
                  readOnly={!permissions['trials'].includes(userRole)}
                  slaWeeksIds={slaWeeksIds}
                  rfs_number={rfs_number}
                />
              </div>
            </Tabs.Panel>
          )}

          {!array.some((r) => slaWeeksIds.indexOf(parseInt(r)) >= 0) && (
            <Tabs.Panel value='nd'>No Data</Tabs.Panel>
          )}
        </Tabs>
      </ContentPaper>
    </>
  );
}
