import { Box, TextInput, Accordion, Grid, Text, Badge, Button, Checkbox } from '@mantine/core';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from '../../../../common/table/VeamScopeTable';
import { AppLoader } from '../../../common/loader';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import ContentPaper from '../../../layouts/rfs/Content';
import { ActionModal } from '../../../../pages/veam/productDevelopment/actionModal';
import ProductionLog from '../../../../pages/veam/productDevelopment/productionSiteLogModal';
import { RfsContext } from '../../../../contexts/RfsContext';
import { CSVLink } from 'react-csv';
import { TableExport } from 'tabler-icons-react';
import { implementationPermission } from '../../../../constants';
import { roles } from '../../../../constants';
import { AuthContext } from '../../../../contexts/AuthContext';
import { AccfComment } from '../../../../common/accf';
import { NoData } from '../../../../common/noData';

export const Status99 = ({ activeTab }) => {
  const { userRole } = useContext(AuthContext);
  const params = useParams();
  const [state, handlers] = useState(0);
  const [infoLoading, setInfoLoading] = useState(false);
  const [skus, setSkus] = useState([]);
  const [manualClosureLoading, setManualClosureLoading] = useState(false);
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const { form, storeAccfComment, getAccfComment, sendAccf, info } = useContext(RfsContext);
  const { rfs_type, rfs_number } = form.rfsInformation;
  const permissions = implementationPermission[rfs_type];
  const [disable, setDisable] = useState(true);
  const [slaId, setSlaId] = useState(0);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    handlers(0);
  }, []);

  useEffect(() => {
    if (activeTab === 's99') fetchInfo();
  }, [params.rfsNumber, activeTab]);

  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo, roles.masterDataTeam].includes(userRole))
      setDisable(false);
  }, [userRole]);

  const save = (payload, id) => {
    payload = { ...payload, comment: payload.remark, status_id: payload.status_ids };
    delete payload.remark; // deleting remark because required fieldname is comment
    delete payload.status_ids; // deleting remark because required fieldname is comment

    setManualClosureLoading(true);
    axios
      .post(`/manualClosure/create`, payload)
      .then(() => {
        info(params.rfsNumber, false);
        fetchInfo(false);
        setManualClosureLoading(false);
      })
      .finally(() => setActionModal(false));
  };

  function fetchInfo(loading = true) {
    setInfoLoading(loading);
    axios
      .get(`/openSku/implementation/status99/fetch/${params.rfsNumber}`)
      .then(
        (res) => {
          if (Array.isArray(res.data?.data)) setSkus(res.data?.data);
          if (res.data.data.length > 0) setSlaId(res.data.data[0].sla_weeks_id);
        },
        (error) => {},
      )
      .finally(() => setInfoLoading(false));
  }

  const getLogs = (imp_id, event) => {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  };

  useEffect(() => {
    let arr = [];
    const MAX_ITERATIONS = 1000;
    if (skus.length < MAX_ITERATIONS) {
      skus.forEach((sku) => {
        arr.push(sku.status);
      });
    }

    if (arr) {
      const setArray = [...new Set(arr)];
      if (
        (setArray.length === 1 && setArray.includes('Closed')) ||
        (setArray.length === 1 && setArray.includes('Not Started'))
      ) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    }
  }, [skus]);

  const getCsvData = () => {
    const data = [];

    [...skus].forEach((info) => {
      data.push({
        'SKU Code': info.int_rfs_scope_selection?.selection_number,
        'SKU Description': info.int_rfs_scope_selection?.selection_name,
        'New Markets': info.int_rfs_sku?.market,
        Status: info.status,
        Action:
          info.status === 'Closed'
            ? info.manually_closed
              ? 'Manually Closed'
              : 'Automatic Closure'
            : 'NA',
        'Step Deadline': info.sla_date,
        'Step Finished': info.finished_date,
      });

      return info;
    });

    return data;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'New Markets',
        maxWidth: '120px',
        accessor: 'new-market',
        filter: false,
        Cell: ({ row }) => row.original.int_rfs_sku.market,
      },
      {
        Header: 'Status',
        accessor: 'status',
        filter: false,
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.status === 'In Progress'
                  ? 'yellow'
                  : row.original.status === 'Closed'
                  ? 'green'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.status}
            </Badge>
          );
        },
      },
      {
        Header: 'Log',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              disabled={['Not Started'].includes(row.original.status)}
              color='dark'
              onClick={() => getLogs(row.original.id)}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Action',
        disableSortBy: true,
        Cell: ({ row }) => {
          return row.original.status === 'In Progress' || row.original.status === 'Not Started' ? (
            <Checkbox
              onChange={() => {
                setActionModal({ open: true, id: row.original.id });
              }}
              defaultChecked={false}
              label='Confirm and Close'
              disabled={row.original.status === 'Not Started' || !permissions['status_99']}
            />
          ) : row.original.manually_closed && row.original.status === 'Closed' ? (
            <Text>Manual Closure</Text>
          ) : (
            <Text>Automatic Closure</Text>
          );
        },
      },
      {
        Header: 'Step Deadline',
        accessor: 'sla_date',
        filter: false,
        Cell: ({ row }) => {
          const date = row.original.sla_date;
          return <Text>{date ? moment(date).format('YYYY-MM-DD') : 'N/A'}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        accessor: 'finished_date',
        filter: false,
        Cell: ({ row }) => {
          const date = row.original.finished_date;
          const status = row.original.status;
          return <Text>{date ? moment(date).format('YYYY-MM-DD') : status}</Text>;
        },
      },
    ],
    [],
  );

  if (infoLoading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <CSVLink
          data={getCsvData()}
          filename={'Status 99_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button
            leftIcon={<TableExport />}
            color='dark'
            style={{
              marginRight: '30px ',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      {skus.length === 0 && !infoLoading ? (
        <NoData />
      ) : (
        <Accordion id='s99' state={state} onChange={handlers}>
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              const accordionKey = `accordion_${index}`;
              const childs = Array.isArray(skus)
                ? (skus || []).filter((sku) => sku?.scope_id === item?.id)
                : [];

              return (
                childs.length > 0 && (
                  <Accordion.Item value={accordionKey} key={accordionKey}>
                    <Accordion.Control>
                      {`${name.substring(0, 3).toUpperCase()} - ${item['selection_number']} - ${
                        item['selection_name']
                      }`}
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Grid>
                        <Grid.Col span={12}>
                          <Table
                            columns={columns}
                            data={skus.filter((sku) => sku.scope_id === item.id)}
                            selections={false}
                          />
                        </Grid.Col>
                      </Grid>
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            }),
          )}
        </Accordion>
      )}
      {!disable && <AccfComment slaId={slaId} status={status} rfs_number={rfs_number} />}

      {actionModal.open && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={actionModal.id}
          state={state}
          updateLoading={manualClosureLoading}
        />
      )}

      {logModal && (
        <ProductionLog logModal={logModal} setLogModal={setLogModal} id={imp_id} type='Status 99' />
      )}
    </div>
  );
};
