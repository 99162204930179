import { Skeleton } from '@mantine/core';

export const renderSkeletonLoader = (loading) => (
  <div style={{ marginTop: '24px' }}>
    <Skeleton visible={loading}>
      <div style={{ height: '36px' }} />
    </Skeleton>
  </div>
);

export const getExistingRoles = (users) => {
  const usersObject = {};

  users.forEach(({ id, int_user_role_mappings }) => {
    if (!usersObject[id]) {
      usersObject[id] = Array.from(
        new Set(
          int_user_role_mappings
            .filter((role) => role.is_active)
            .map((mapping) => mapping?.int_role?.name),
        ),
      ).join(', ');
    }
  });

  return usersObject;
};
