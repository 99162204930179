import React, { useContext } from 'react';

import Rfsinitiation2 from '../forms/rfsInitiationForm/rfsinitiation2';
import { AppLoader } from '../common/loader';
import { RfsContext } from '../../contexts/RfsContext';
import { DelistingProvider } from '../../contexts/DelistingContext';
import Rfsinitiation2DLST from '../../pages/DLST/ValidationPage/rfsinitiation2DLST';
import SupplierChangeValidate from '../../pages/supplierChange/validate';
import { SupplierProvider } from '../../contexts/SupplierContext';

export default function SubmissionComp(props) {
  const { form, infoLoading } = useContext(RfsContext);

  const component = () => {
    const { rfs_type } = form.rfsInformation;
    const components = {
      Delisting: (
        <DelistingProvider>
          <Rfsinitiation2DLST isSubmissionReview initialItems={0} {...props} />
        </DelistingProvider>
      ),
      'Supplier Change': (
        <SupplierProvider>
          <SupplierChangeValidate isSubmissionReview initialItems={0} {...props} />
        </SupplierProvider>
      ),
    };
    return components[rfs_type] ? (
      components[rfs_type]
    ) : (
      <Rfsinitiation2 isSubmissionReview initialItems={0} {...props} />
    );
  };

  const ReviewComponent = () => {
    return component();
  };

  if (infoLoading) return <AppLoader center size='lg' />;

  return <ReviewComponent />;
}
