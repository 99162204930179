import React, { useEffect, useMemo, useState } from 'react';
import BTable from 'react-bootstrap/Table';
import { Button, Switch } from '@mantine/core';
import { useSticky } from 'react-table-sticky';
import { ChevronDown, ChevronUp, ArrowsSort, Maximize, Minimize } from 'tabler-icons-react';
import { useTable, useSortBy, useFilters, usePagination, useRowSelect } from 'react-table';

import ExportButton from '../../../pages/scopeInitiation1/export';
import {
  Styles,
  TableButtonsConatiner,
  TableContainer,
  PaginationContainer,
  TableRightButtons,
} from './Styles';
import { DefaultColumnFilter, checkboxColumn } from './Helpers';
import { TablePagination } from './Pagination';
import { AppLoader } from '../../common/loader';

export const SkuDetailsTable = ({
  columns,
  data,
  sortOptions = [],
  handleSelect = null,
  type,
  handleExport,
  loading,
  noSelectAll,
  fullScreenToggle = false,
  fullscreen = false,
  selectedIds = {},
  toggleSelected = false,
  showOnlySelected = false,
  handleToggle = null,
  autoResetPage = true,
  count = null,
  customPageIndex = 0,
  setCustomPageIndex = null,
  hideRowOptions = false,
}) => {
  const [checkboxClick, setCheckboxClick] = useState({});

  useEffect(() => {
    return () => setCheckboxClick(false);
  });

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const handleCheckboxClick = (data) => {
    setCheckboxClick(data.original);
  };

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: autoResetPage,
      initialState: {
        pageIndex: customPageIndex,
        selectedRowIds: selectedIds,
        sortBy: sortOptions,
      },
      getRowId: (row, relativeIndex, parent) => {
        if (type === 'liquids') {
          return `${row.LiquidCode}`;
        } else if (type === 'materials') {
          return `${row.material_code}`;
        } else if (type === 'skus') {
          return `${row.material}`;
        } else {
          return parent ? [parent.id, relativeIndex].join('.') : relativeIndex;
        }
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
    (hooks) => {
      if (!['rfs', 'users'].includes(type)) {
        hooks.allColumns.push((columns) => [
          checkboxColumn(noSelectAll, handleCheckboxClick),
          ...columns,
        ]);
      }
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    },
  );

  useEffect(() => {
    if (handleSelect) handleSelect(selectedFlatRows, type, checkboxClick, selectedRowIds);
  }, [Object.keys(checkboxClick).length, selectedFlatRows.length, selectedRowIds]);

  useEffect(() => {
    if (fullscreen) {
      setPageSize(20);
    } else {
      setPageSize(10);
    }
  }, [fullscreen, setPageSize]);

  useEffect(() => {
    if (setCustomPageIndex) setCustomPageIndex(pageIndex);
  }, [data]);

  return (
    <>
      <Styles>
        {(handleExport || fullScreenToggle) && (
          <TableButtonsConatiner>
            <div>
              {toggleSelected && (
                <Switch
                  className='my-selections'
                  label='My Selections'
                  size={'md'}
                  checked={showOnlySelected}
                  color='dark'
                  onChange={(event) => handleToggle(event.currentTarget.checked)}
                />
              )}
            </div>
            <TableRightButtons>
              {handleExport && <ExportButton data={handleExport(page)} />}
              {fullScreenToggle && (
                <Button
                  variant='gradient'
                  radius='sm'
                  leftIcon={fullscreen ? <Minimize /> : <Maximize />}
                  onClick={fullScreenToggle}
                >
                  {fullscreen ? 'Exit FullScreen' : 'FullScreen'}
                </Button>
              )}
            </TableRightButtons>
          </TableButtonsConatiner>
        )}

        <TableContainer>
          <BTable className='table sticky' responsive={'xl'} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    const props = {
                      ...column.getHeaderProps(column.getSortByToggleProps()),
                    };

                    return (
                      <td
                        colSpan={column?.columns?.length > 0 ? column?.columns?.length : 1}
                        data-sticky-last-left-td={props['data-sticky-last-left-td']}
                        data-sticky-td={props['data-sticky-td']}
                        style={{
                          ...props['style'],
                          width: column.width ? column.width : 'unset',
                          minWidth: column.minWidth ? column.minWidth : 'unset',
                          maxWidth: column.maxWidth ? column.maxWidth : 'unset',
                          textAlign: column?.columns?.length > 0 ? 'center' : 'unset',
                        }}
                      >
                        {column?.columns?.length > 0 ? (
                          column.render('Header')
                        ) : (
                          <>
                            <span
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              style={{ boxShadow: 'none' }}
                            >
                              {column.render('Header')}
                              {!column.disableSortBy && (
                                <>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <ChevronDown className='sort-icon' />
                                    ) : (
                                      <ChevronUp className='sort-icon' />
                                    )
                                  ) : column.originalId === 'selection_placeholder' ? null : (
                                    <ArrowsSort className='sort-icon' />
                                  )}
                                </>
                              )}
                            </span>
                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                          </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} key={`tr_${i}`}>
                    {row.cells.map((cell) => {
                      return (
                        <td style={{ paddingLeft: '20px' }} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}

              {loading && (
                <tr>
                  <td style={{ paddingLeft: '20px' }} colSpan={13}>
                    <div className='loader-container'>
                      <AppLoader size={'md'} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </BTable>
        </TableContainer>

        <PaginationContainer>
          <TablePagination
            hideRowOptions={hideRowOptions}
            total={count ? count : data.length}
            page={page}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </PaginationContainer>
      </Styles>
    </>
  );
};
