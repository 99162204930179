import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SubmissionComp from '../../components/submissionComp/submissionComp';
import RfsLayout from '../../components/layouts/RfsLayout';
import { RfsContext } from '../../contexts/RfsContext';
import { rfsStatus } from '../../constants';
import { routes } from '../../routes';

function SubmissionReview() {
  const params = useParams();
  const navigate = useNavigate();
  const { info, reset, form } = useContext(RfsContext);

  useEffect(() => {
    if (params.rfsNumber) info(params.rfsNumber);

    return () => reset();
  }, [params.rfsNumber]);

  if (
    form.rfsInformation.status &&
    ![
      rfsStatus.draft,
      rfsStatus.tech_feasible,
      rfsStatus.tech_feasible2,
    ].includes(form.rfsInformation.status)
  ) {
    navigate(routes.submission(params.rfsNumber).overView);
  }

  return (
    <RfsLayout>
      <SubmissionComp rfsNumber={params.rfsNumber} />
    </RfsLayout>
  );
}

export default SubmissionReview;
