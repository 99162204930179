import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './report.css';
import Report from './report';
import DecomplexityReport from './decomplexityReport';
import RawdataReport from './rawdataReport';
import TeamsStepsDaysReport from './teamsStepsReportDays';
import IndivisualStepsTeamsReport from './indivisualstepsTeamsReport';

const ReportCustomComp = ({
  columns,
  setValidationErrors,
  validationErrors,
  statusData = [],
  unique,
  enableEditing = false,
  type,
  readOnly = null,
}) => {
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <div className=''>
      <QueryClientProvider client={queryClient}>
        {type === 'decomplexity' ? (
          <DecomplexityReport
            columns={columns}
            setValidationErrors={setValidationErrors}
            validationErrors={validationErrors}
            statusData={statusData}
            unique={unique}
            enableEditing={enableEditing}
            readOnly={readOnly}
          />
        ) : type === 'rawdata' ? (
          <RawdataReport
            columns={columns}
            setValidationErrors={setValidationErrors}
            validationErrors={validationErrors}
            statusData={statusData}
            unique={unique}
            enableEditing={enableEditing}
            readOnly={readOnly}
          />
        ) : type === 'teamssteps' ? (
          <TeamsStepsDaysReport
            columns={columns}
            setValidationErrors={setValidationErrors}
            validationErrors={validationErrors}
            statusData={statusData}
            unique={unique}
            enableEditing={enableEditing}
            readOnly={readOnly}
          />
        ) : type === 'indivisualstepsteams' ? (
          <IndivisualStepsTeamsReport
            columns={columns}
            setValidationErrors={setValidationErrors}
            validationErrors={validationErrors}
            statusData={statusData}
            unique={unique}
            enableEditing={enableEditing}
            readOnly={readOnly}
          />
        ) : (
          <Report
            reportType={type}
            columns={columns}
            setValidationErrors={setValidationErrors}
            validationErrors={validationErrors}
            statusData={statusData}
            unique={unique}
            enableEditing={enableEditing}
          />
        )}
      </QueryClientProvider>
    </div>
  );
};

export default ReportCustomComp;
