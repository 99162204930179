import { Table } from '@mantine/core';

const split = (data) => {
  let splitData = data?.split(', ');
  return splitData?.map((d, k) => (
    <p key={k} style={{ marginBottom: '2px' }}>
      {d}
    </p>
  ));
};

export const ModalTable = ({ data, rfsNumber }) => {
  return (
    <>
      {/* {!data[0]?.rfs_number.includes("VEAM") && data?.map((k) => (
        
      ))} */}
      <Table captionSide='top' verticalSpacing='xl' striped highlightOnHover style={{}}>
        <caption className='caption'>
          Rfs Number: {data[0] ? data[0]?.rfs_number : rfsNumber}
        </caption>
        <thead>
          <tr>
            <th
              className='tablee'
              style={{
                minWidth: '2rem',
                fontSize: '1rem',
              }}
            >
              Type of Involvement
            </th>
            <th
              className='tablee'
              style={{
                minWidth: '2rem',
                fontSize: '1rem',
              }}
            >
              Scope Selection
            </th>
            <th
              className='tablee'
              style={{
                minWidth: '2rem',
                fontSize: '1rem',
              }}
            >
              Plant
            </th>
            <th
              className='tablee'
              style={{
                minWidth: '2rem',
                fontSize: '1rem',
              }}
            >
              Other RFS
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((element, index) => (
            <tr key={`${index}`}>
              <td className='tableee'>{element.TypeOfInvolvement}</td>
              <td className='tableee'>{element.ScopeSelection}</td>
              <td className='tableee'>{element.Plant}</td>
              <td className='tableee'>{split(element.OtherRFSs)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
