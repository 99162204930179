import React, { useContext, useEffect } from 'react';
import { Modal, Button } from '@mantine/core';

import { RfsContext } from '../../contexts/RfsContext';
import { SkuDetailsTable } from '../tables/SKUDetailsTable/SkuDetailsTable';
import styled from 'styled-components';
import { AppLoader } from '../common/loader';

const Footer = styled.div`
  padding: 0.5rem;
  z-index: 999;
  position: sticky;
  width: 100%;
  bottom: 0px;
  text-align: right;
  background: #fff;
`;

export default function SkuSelectedMaterialModal({
  material,
  selectedMaterialModal,
  setSelectedMaterialModal,
}) {
  const { fetchAffectedSkus, affectedSkus, loading, form, setForm } = useContext(RfsContext);

  useEffect(() => {
    fetchAffectedSkus(material);
  }, []);

  const columns = [
    {
      Header: 'SKUs',
      accessor: 'SKUs',
      sticky: 'left',
      minWidth: 110,
      maxWidth: 110,
      width: 110,
    },
    {
      Header: 'SKU Description',
      accessor: 'SKUDescription',
      sticky: 'left',
    },
    {
      Header: 'Alternative BOM',
      accessor: 'AlternativeBOM',
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
    {
      Header: 'Plant',
      accessor: 'Plant',
      minWidth: 110,
      maxWidth: 110,
      width: 110,
    },
    {
      Header: 'Component Qty',
      accessor: 'ComponentQty',
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
    {
      Header: 'UoM',
      accessor: 'UoM',
      minWidth: 75,
      maxWidth: 75,
      width: 75,
    },
    {
      Header: 'Markets',
      accessor: 'Markets',
    },
    {
      Header: 'EAN ',
      accessor: 'EANNumbers',
    },
  ];

  const handleRowsSelected = (data, type) => {
    const identifier = `${material.material_code}_${material.plant}`;

    data = data.map((val) => {
      return {
        ...val.original,
        identifier,
      };
    });

    let currentValues = { ...form };
    const existingFilteredValues = currentValues.selectedAffectedSkus.filter(
      (val) => val.identifier !== identifier,
    );
    currentValues.selectedAffectedSkus = [...existingFilteredValues, ...data];

    setForm(currentValues);
  };

  return (
    <Modal
      closeOnClickOutside={false}
      size='80%'
      opened={selectedMaterialModal}
      onClose={() => setSelectedMaterialModal()}
      style={{ overflowX: 'hidden' }}
      overflow='inside'
    >
      {loading ? (
        <div className='loader-container'>
          <AppLoader size={'md'} />
        </div>
      ) : (
        <>
          <div className='table-container'>
            <SkuDetailsTable
              columns={columns}
              data={affectedSkus}
              handleSelect={(data, type) => handleRowsSelected(data, type)}
              type={'selectedAffectedSkus'}
              handleExport={null}
              loading={false}
            />
          </div>
          <Footer>
            <Button variant='gradient' onClick={() => setSelectedMaterialModal()}>
              Confirm Choices
            </Button>
          </Footer>
        </>
      )}{' '}
    </Modal>
  );
}
