import React, { useContext } from 'react';

import AttachmentCompPd from '../../components/production-site attachment/attachmentComp-pd';
import AttachmentComp from '../../components/attachmentComp/attachmentComp';
import AttachmentCompDLST from '../../pages/DLST/attachmentPageDLST/attachmentPageDLST';
import RfsLayout from '../../components/layouts/RfsLayout';
import { RfsContext } from '../../contexts/RfsContext';
import { AppLoader } from '../../components/common/loader';
import SupplierChangeAttachment from '../../pages/supplierChange/attachments';

import './index.css';

export default function AttachmentPage() {
  const { form } = useContext(RfsContext);
  const { rfs_type } = form.rfsInformation;

  if (rfs_type.length === 0) {
    return (
      <RfsLayout>
        <AppLoader size='lg' center />
      </RfsLayout>
    );
  }

  return (
    <>
      {rfs_type.toLowerCase() === 'production site' ? (
        <AttachmentCompPd withProjectName initialItem={0} fromOverview={true}/>
      ) : rfs_type.toLowerCase() === 'delisting' ? (
        <AttachmentCompDLST withProjectName initialItem={0} fromOverview={true} />
      ) : rfs_type.toLowerCase() === 'supplier change' ? (
        <SupplierChangeAttachment withProjectName initialItem={0} fromOverview={true}/>
      ) : (
        <AttachmentComp withProjectName initialItem={0} fromOverview={true}/>
      )}
    </>
  );
}
