import { Accordion, Button, Box, TextInput } from '@mantine/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RfsContext } from '../../../../contexts/RfsContext';
import { useParams } from 'react-router-dom';
import { axios } from '../../../../utils/axios';
import { AppLoader } from '../../../../components/common/loader';
import { StatusZzAllMarketsTable } from './table_all_markets';
import { StatusZzTable } from './table';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { TableExport } from 'tabler-icons-react';
import { dlstImplPermissions } from '../../../../constants';
import { AuthContext } from '../../../../contexts/AuthContext';
import { AccfComment } from '../../../../common/accf';

export const StatusChangeZZ = ({ activeTab }) => {
  const { userRole } = useContext(AuthContext);
  const { rfsNumber } = useParams();
  const { form } = useContext(RfsContext);
  const { rfs_type, rfs_number } = form.rfsInformation;
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [manualClosureLoading, setManualClosureLoading] = useState(false);
  const [slaId, setSlaId] = useState(0);
  const [disable, setDisable] = useState(false);
  const [statusAccf, setStatusAccf] = useState(true);

  useEffect(() => {
    if (activeTab === 'scZZ') fetch();
  }, [activeTab]);

  useEffect(() => {
    setDisable(!permissions['status_zz'].includes(userRole));
  }, []);

  const fetch = useCallback((loading = true) => {
    setLoading(loading);
    axios
      .get(`/delisting/stock_volume_zz/fetch/${rfsNumber}`)
      .then(
        (res) => {
          const data = res.data.data.map((s) => {
            s.status = s.status === 'Not Stared' ? 'Not Started' : s.status;
            return s;
          });

          setStatus(data);
          if (res.data.data.length > 0) setSlaId(res.data.data[0].sla_weeks_id);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    let arr = [];

    const MAX_ITERATIONS = 1000;
    if (status.length < MAX_ITERATIONS) {
      status.forEach((item) => {
        arr.push(item.status);
      });
    }

    if (arr) {
      const setArray = [...new Set(arr)];
      if (
        (setArray.length === 1 && setArray.includes('Closed')) ||
        (setArray.length === 1 && setArray.includes('Not Started'))
      ) {
        setStatusAccf(true);
      } else {
        setStatusAccf(false);
      }
    }
  }, [status]);

  const save = (payload, id) => {
    setManualClosureLoading(true);

    axios
      .post(`/delisting/manual_closure/status_zz`, {
        status_id: payload.status_ids,
        comment: payload.remark,
      })
      .then(() => {
        fetch(false);
        setActionModal({ open: false, id: null });
      })
      .finally(() => setManualClosureLoading(false));
  };

  const getCsvData = () => {
    const data = [];

    [...status].forEach((info) => {
      data.push({
        'SKU number': info.int_rfs_scope_selection?.selection_number,
        Description: info.int_rfs_scope_selection?.selection_name,
        Plant: info.int_rfs_dlst_scope_plant?.plant,
        Delist: info.int_rfs_dlst_sku?.allMarkets ? 'Full' : 'Partial',
        Markets: info.int_rfs_dlst_sku?.allMarkets
          ? ''
          : info.int_rfs_dlst_scope_market?.market_name,
        'Stock Volume': info.int_rfs_dlst_sku?.allMarkets ? info.stock_volume : '',
        'ZZ ready to kill': info.int_rfs_dlst_sku?.allMarkets
          ? info.ready_to_kill
            ? 'Yes'
            : 'No'
          : '',
        Status: info.status,
        Action:
          info.status === 'Closed'
            ? info.manually_closed
              ? 'Manually Closed'
              : 'Automatic Closure'
            : 'NA',
      });
    });

    return data;
  };
  const permissions = dlstImplPermissions[rfs_type];

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <CSVLink
          data={getCsvData()}
          filename={'Status Change to ZZ_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button
            leftIcon={<TableExport />}
            color='primary'
            style={{
              marginRight: '30px ',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      <Accordion style={{ maxWidth: '100vw' }}>
        {Object.keys(form?.selectedScope || {}).map((name) =>
          form?.selectedScope[name].map((item, index) => {
            const accordionKey = `accordion_${index}`;
            const allMarkets = status.filter(
              (s) => s.int_rfs_scope_selection.id === item.id && s.int_rfs_dlst_sku.allMarkets,
            );
            const selectedMarkets = status.filter(
              (s) => s.int_rfs_scope_selection.id === item.id && !s.int_rfs_dlst_sku.allMarkets,
            );
            const accordion = status.filter(
              (s) => s.int_rfs_scope_selection.selection_number === item['selection_number'],
            );
            if (accordion.length > 0)
              return (
                <Accordion.Item key={accordionKey} value={accordionKey}>
                  <Accordion.Control>
                    {`${name.substring(0, 3).toUpperCase()} - ${item['selection_number']} - ${
                      item['selection_name']
                    }`}
                  </Accordion.Control>
                  <Accordion.Panel>
                    {allMarkets.length > 0 && (
                      <StatusZzAllMarketsTable
                        data={allMarkets}
                        actionModal={actionModal}
                        setActionModal={setActionModal}
                        loading={manualClosureLoading}
                        save={save}
                        readOnly={!permissions['status_zz'].includes(userRole)}
                      />
                    )}

                    {allMarkets.length > 0 && selectedMarkets.length > 0 && (
                      <div style={{ height: '16px' }}></div>
                    )}

                    {selectedMarkets.length > 0 && (
                      <StatusZzTable
                        data={selectedMarkets}
                        actionModal={actionModal}
                        setActionModal={setActionModal}
                        loading={manualClosureLoading}
                        tableLoading={loading}
                        save={save}
                        readOnly={!permissions['status_zz'].includes(userRole)}
                      />
                    )}
                  </Accordion.Panel>
                </Accordion.Item>
              );
          }),
        )}
      </Accordion>
      {!disable && <AccfComment slaId={slaId} status={statusAccf} rfs_number={rfs_number} />}
    </>
  );
};
