import React, { useState, useMemo, useRef, useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { InfoCircle } from 'tabler-icons-react';
import { Text, Badge, Button, TextInput, Tooltip, Select } from '@mantine/core';
import { useForm } from '@mantine/hooks';
import { AppLoader } from '../../../../components/common/loader';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import ProductionLog from '../../productDevelopment/productionSiteLogModal';
import { ActionModal } from '../../productDevelopment/actionModal';
import PricingModal from './pricingModal';
import { RfsContext } from '../../../../contexts/RfsContext';

export const MaterialPricingTable = ({
  materialData,
  onChange,
  submit,
  currency,
  refresh,
  disable,
}) => {
  const [filterLoading, setFilterLoading] = useState(false);
  const [imp_id, setImp_id] = useState(0);
  const [pricingId, setPricingId] = useState();
  const [submitted, setSubmitted] = useState({});
  const data = materialData;
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [pricingModal, setPricingModal] = useState({ open: false, id: '' });
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [otherStatus, setotherStatus] = useState({});
  const [details, setDetails] = useState({});
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;

  const detailsRef = useRef(null);
  detailsRef.current = details;

  const handleChange = (value = '', name, id) => {
    const numberRegex = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
    const hasInvalidCharacters = (value) => {
      return !numberRegex.test(value);
    };

    if (detailsRef.current.hasOwnProperty(id)) {
      let currentDetails = { ...detailsRef.current };
      currentDetails = {
        ...currentDetails,
        [id]: {
          ...currentDetails[id],
          [name]: value,
          [name + '_error']: hasInvalidCharacters(value) || value.length === 0,
        },
      };
      detailsRef.current = currentDetails;
      setDetails(currentDetails);
    } else {
      let currentDetails = { ...detailsRef.current };
      currentDetails[id] = {
        [name]: value,
        [name + '_error']: hasInvalidCharacters(value) || value.length === 0,
      };
      detailsRef.current = currentDetails;
      setDetails(currentDetails);
    }
  };

  const handleSubmitted = (id, rfs_number) => {
    if (submitted.hasOwnProperty(id)) {
      let currentDetails = { ...submitted };
      currentDetails = {
        ...currentDetails,
        [id]: true,
      };
      setSubmitted(currentDetails);
    } else {
      let currentDetails = { ...submitted };
      currentDetails[id] = true;
      setSubmitted(currentDetails);
    }
    setDetails({});
  };

  const isDisabled = (id, data) => {
    if (data[id]) {
      const boolean =
        Object.keys(data[id]).length === 6 && Object.values(data[id]).length > 0 ? false : true;

      const error = data[id].price_error || data[id].unit_error;

      return boolean || error;
    } else return true;
  };

  function getLogs(imp_id) {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  }

  const getPricing = (id) => {
    setPricingModal({ open: true, id: id });
    setPricingId(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Pack Mat Code',
        accessor: 'packmatcode',
        Cell: ({ row }) => {
          return <Text>{row.original.material_number}</Text>;
        },
      },
      {
        Header: 'Pack Mat Description',
        accessor: 'packmatdesc',
        Cell: ({ row }) => {
          return <Text>{row.original.material_description}</Text>;
        },
      },
      {
        Header: 'Material Group',
        accessor: 'matgroup',
        Cell: ({ row }) => {
          return <Text>{row.original.material_group}</Text>;
        },
      },
      {
        Header: 'Materials Pricing',
        accessor: 'matpricing',
        Cell: ({ row }) => {
          return (
            <Button
              rightIcon={<InfoCircle />}
              radius='xl'
              style={{ background: 'black', color: 'white' }}
              variant='gradient'
              onClick={() => getPricing(row.original.id)}
            >
              Show Pricing
            </Button>
          );
        },
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row }) => {
          const price = row.original.int_rfs_impl_material_price[0]['recalculated_price'];

          return price ? (
            <Text>{price}</Text>
          ) : submitted[row.original.id] ? (
            <Text>{detailsRef.current[row.original.id]?.price}</Text>
          ) : (
            <div>
              <TextInput
                disabled={
                  submitted[row.original.id] || row.original.status !== 'In Progress' || disable
                }
                placeholder='Enter Price'
                name='price'
                error={
                  detailsRef?.current[row.original.id]?.price_error
                    ? 'Please enter a numerical value'
                    : null
                }
                onChange={(event) => {
                  handleChange(event.target.value, event.target.name, row.original.id);
                }}
                style={{ marginBottom: '0.5rem', minWidth: 'max-content' }}
              />
            </div>
          );
        },
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        Cell: ({ row }) => {
          const unit = row.original.int_rfs_impl_material_price[0]['recalculated_price_units'];

          return unit ? (
            <Text>{unit}</Text>
          ) : submitted[row.original.id] ? (
            <Text>{detailsRef.current[row.original.id]?.unit}</Text>
          ) : (
            <div>
              <TextInput
                disabled={
                  submitted[row.original.id] || row.original.status !== 'In Progress' || disable
                }
                error={
                  detailsRef?.current[row.original.id]?.unit_error
                    ? 'Please enter a numerical value'
                    : null
                }
                placeholder='Enter Unit'
                name='unit'
                onChange={(event) => {
                  handleChange(event.target.value, event.target.name, row.original.id);
                }}
                style={{ marginBottom: '0.5rem', minWidth: 'max-content' }}
              />
            </div>
          );
        },
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        Cell: ({ row }) => {
          const currencyData =
            row.original.int_rfs_impl_material_price[0]['recalculated_price_currency'];
          return currencyData ? (
            <Text>{currencyData}</Text>
          ) : submitted[row.original.id] ? (
            <Text>{detailsRef.current[row.original.id]?.currency}</Text>
          ) : (
            <div>
              <Select
                disabled={
                  submitted[row.original.id] || row.original.status !== 'In Progress' || disable
                }
                name='currency'
                placeholder='Currency'
                value={detailsRef.current[row.original.id]?.currency}
                onChange={(value) => {
                  handleChange(value, 'currency', row.original.id);
                }}
                data={currency}
                style={{ marginBottom: '0.5rem', minWidth: 'max-content' }}
              />
            </div>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          return (
            <div>
              <Tooltip
                disabled={!isDisabled(row.original.id, detailsRef.current) || disable}
                wrapLines
                width={220}
                withArrow
                transitionProps={{ transition: 'fade', duration: 200 }}
                label='Please enter a value for Price, Unit and Currency in order to enable the Submit button.'
              >
                <Button
                  disabled={
                    isDisabled(row.original.id, detailsRef.current) || submitted[row.original.id]
                  }
                  size='md'
                  radius='sm'
                  color='dark'
                  style={{
                    height: '2rem',
                  }}
                  variant='gradient'
                  onClick={() => {
                    submit(
                      rfs_number,
                      detailsRef.current[row.original.id]['price'],
                      detailsRef.current[row.original.id]['unit'],
                      detailsRef.current[row.original.id]['currency'],
                      row.original.other_materials,
                    ).then(
                      (res) => {
                        handleSubmitted(row.original.id, rfs_number);
                        refresh(rfs_number);
                      },
                      (err) => {
                        console.log(err);
                      },
                    );
                  }}
                >
                  Submit
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.status === 'In Progress' &&
                !row.original.finished_date &&
                moment(new Date()).format('YYYY-MM-DD') <=
                  moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'yellow'
                  : (row.original.status === 'In Progress' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.status === 'In Progress' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : row.original.status === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') <=
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'green'
                  : row.original.status === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'red'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.status}
            </Badge>
          );
        },
      },
      {
        Header: 'Log',
        accessor: 'log',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => getLogs(row.original.id)}
              style={{ background: 'black', color: 'white' }}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step deadline',
        accessor: 'step_deadline',
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
        },
      },
      {
        Header: 'Step finished',
        accessor: 'step_finished',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('YYYY-MM-DD')
                : row.original.status}
            </Text>
          );
        },
      },
    ],
    [submitted],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            {filterLoading && (
              <tr>
                <td colSpan={13}>
                  <div className='loader-container'>
                    <AppLoader size={'md'} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          otherStatus={otherStatus}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Material Pricing'
        />
      )}

      {pricingModal.open && (
        <PricingModal
          logModal={pricingModal}
          setLogModal={setPricingModal}
          id={pricingId}
          data={materialData}
        />
      )}
    </Styles>
  );
};
