import React from 'react';
import { Modal, Grid, Group, Text } from '@mantine/core';

import { InfoCircle } from 'tabler-icons-react';

export const SubmissionTrueModal = ({
  type = 'success',
  open,
  setOpen,
  text = 'You have successfully submitted.',
}) => {
  return (
    <>
      <Modal
        className='Invalid-modal'
        opened={open}
        onClose={() => setOpen(false)}
        title={type === 'success' ? 'Success' : 'Error'}
      >
        <Grid>
          <Grid.Col span={12}>
            <Group
              noWrap
              className={type === 'success' ? 'confirm-modal-group' : 'invalid-modal-group'}
            >
              <InfoCircle color={type === 'success' ? 'green' : 'red'} size='5rem' />
              <Text size='sm'>{text}</Text>
            </Group>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
