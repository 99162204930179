// import React from 'react';
// import { useTable } from 'react-table';
// import { Checkbox, Select, Textarea } from '@mantine/core';
// import ContentPaper from '../layouts/rfs/Content';
// import { Styles } from '../scopeComp/tableStyles';

// function DecomplexityTable() {
// 	const data = React.useMemo(
// 		() => [
// 			{
// 				col1: '',
// 				col3: '',
// 				col4: '',
// 				col5: '',
// 			},
// 			{
// 				col1: '',
// 				col3: '',
// 				col4: '',
// 				col5: '',
// 			},
// 			{
// 				col1: '',
// 				col3: '',
// 				col4: '',
// 				col5: '',
// 			},
// 			{
// 				col1: '',
// 				col3: '',
// 				col4: '',
// 				col5: '',
// 			},
// 			{
// 				col1: '',
// 				col3: '',
// 				col4: '',
// 				col5: '',
// 			},
// 			{
// 				col1: '',
// 				col3: '',
// 				col4: '',
// 				col5: '',
// 			},
// 			{
// 				col1: '',
// 				col3: '',
// 				col4: '',
// 				col5: '',
// 			},
// 			{
// 				col1: '',
// 				col3: '',
// 				col4: '',
// 				col5: '',
// 			},
// 			{
// 				col1: '',
// 				col3: '',
// 				col4: '',
// 				col5: '',
// 			},
// 		],
// 		[]
// 	);

// 	const columns = React.useMemo(
// 		() => [
// 			{
// 				Header: 'SKU Number',
// 				accessor: 'col1',
// 			},
// 			{
// 				Header: 'SKU Description',
// 				accessor: 'col2',
// 			},
// 			{
// 				Header: 'Decomplexity Status',
// 				accessor: 'col3',
// 				Cell: ({ row }) => (
// 					<Select
// 						data={[
// 							{ value: 'Keep', label: 'Keep' },
// 							{ value: 'Last_prod', label: 'Last_prod' },
// 							{ value: 'temp', label: 'Temp' },
// 							{ value: 'inno_keep', label: 'Inno_keep' },
// 							{ value: 'inno_temp', label: 'Inno_temp' },
// 							{ value: 'Seasonal', label: 'Seasonal' },
// 							{ value: 'Promo', label: 'Promo' },
// 							{ value: 'Comp', label: 'Comp' },
// 							{ value: 'Sell_out', label: 'Sell out' },
// 							{ value: 'Kill', label: 'Kill ' },
// 							{ value: 'Admin', label: 'Admin' },
// 							{ value: 'Budget', label: 'Budget' },
// 						]}
// 					/>
// 				),
// 			},
// 			{
// 				Header: 'Decomplexity Last Modified',
// 				accessor: 'col4',
// 			},
// 			{
// 				Header: 'Decomplexity User name',
// 				accessor: 'col5',
// 			},
// 			{
// 				Header: 'Decomplexity RFS ID',
// 				accessor: 'col6',
// 			},
// 			{
// 				Header: 'Decomplexity Change Type',
// 				accessor: 'col7',
// 			},
// 			{
// 				Header: 'SKU Status',
// 				accessor: 'col8',
// 			},
// 			{
// 				Header: 'Forecast (Next 52 weeks)',
// 				accessor: 'col9',
// 			},
// 			{
// 				Header: 'Production Planned',
// 				accessor: 'col10',
// 			},
// 			{
// 				Header: 'Prodction Volume',
// 				accessor: 'col11',
// 			},
// 			{
// 				Header: 'Stock O9',
// 				accessor: 'col12',
// 			},
// 			{
// 				Header: 'Stock ERP',
// 				accessor: 'col13',
// 			},
// 		],
// 		[]
// 	);

// 	const tableInstance = useTable({ columns, data });

// 	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
// 		tableInstance;

// 	return (
// 		<Styles>
// 			<ContentPaper page={'Decomplexity'}>
// 				<div style={{ overflowX: 'scroll' }}>
// 					<table
// 						style={{ minWidth: 'calc(100vw - 22rem)' }}
// 						{...getTableProps()}>
// 						<thead>
// 							{headerGroups.map((headerGroup) => (
// 								<tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
// 									{headerGroup.headers.map((column) => (
// 										<th {...column.getHeaderProps()}>
// 											{column.render('Header')}
// 										</th>
// 									))}
// 								</tr>
// 							))}
// 						</thead>
// 						<tbody {...getTableBodyProps()}>
// 							{rows.map((row) => {
// 								prepareRow(row);
// 								return (
// 									<tr {...row.getRowProps()}>
// 										{row.cells.map((cell) => {
// 											return (
// 												<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
// 											);
// 										})}
// 									</tr>
// 								);
// 							})}
// 						</tbody>
// 					</table>
// 				</div>
// 			</ContentPaper>
// 		</Styles>
// 	);
// }

// export default DecomplexityTable;

import React, { useContext, useState, useEffect } from 'react';
import { RfsContext } from '../../contexts/RfsContext';

import { AppLoader } from '../common/loader';
import { Select } from '@mantine/core';
import { axios } from '../../utils/axios';

import { SkuDetailsTable } from '../tables/SKUDetailsTable/SkuDetailsTable';

import ContentPaper from '../layouts/rfs/Content';

export default function DecomplexityTable() {
  const { toggle, fullscreen } = useContext(RfsContext);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getExportData = (items) => {
    let data = [];
    data = items.map((item) => item.original);

    return data;
  };

  const fetchData = () => {
    axios
      .get('/reports/decomplexity')
      .then(
        (res) => {
          let response = res.data.data;
          setTableData(response);
        },
        (err) => {
          console.log(err);
        },
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleExport = (rows) => {
    // if (form.scope.liquids.length > 0) {
    // 	return getExportData(form.scope.liquids);
    // } else {
    return getExportData(rows);
  };

  const Product_Development_Table = [
    {
      Header: 'SKU Number',
      accessor: 'MaterialNumber',
      sticky: 'left',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      Header: 'SKU Description',
      accessor: 'MaterialDescription',
      sticky: 'left',
      minWidth: 280,
      width: 280,
      maxWidth: 280,
    },
    {
      Header: 'Decomplexity Status',
      accessor: 'Status',
      Cell: ({ row }) => (
        <Select
          data={[
            { value: 'Keep', label: 'Keep' },
            { value: 'Last_prod', label: 'Last_prod' },
            { value: 'temp', label: 'Temp' },
            { value: 'inno_keep', label: 'Inno_keep' },
            { value: 'inno_temp', label: 'Inno_temp' },
            { value: 'Seasonal', label: 'Seasonal' },
            { value: 'Promo', label: 'Promo' },
            { value: 'Comp', label: 'Comp' },
            { value: 'Sell_out', label: 'Sell out' },
            { value: 'Kill', label: 'Kill ' },
            { value: 'Admin', label: 'Admin' },
            { value: 'Budget', label: 'Budget' },
          ]}
        />
      ),
      minWidth: 175,
      width: 175,
      maxWidth: 175,
    },
    {
      Header: 'Decomplexity last modified',
      accessor: 'LastModified',
      minWidth: 225,
      width: 225,
      maxWidth: 225,
    },

    {
      Header: 'Decomplexity user name',
      accessor: 'Username',
      minWidth: 200,
      width: 200,
      maxWidth: 200,
    },

    {
      Header: 'Decomplexity rfs id',
      accessor: 'ProjectID',
      minWidth: 175,
      width: 175,
      maxWidth: 175,
    },
    {
      Header: 'Decomplexity change type',
      accessor: 'Changetype',
      minWidth: 225,
      width: 225,
      maxWidth: 225,
    },
    {
      Header: 'SKU Status',
      accessor: 'SKUStatus',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      Header: 'Forecast (Next 52 weeks)',
      accessor: 'CumulativeForecast',
      minWidth: 200,
      width: 200,
      maxWidth: 200,
    },
    {
      Header: 'Production Planned',
      accessor: 'production_planned',
      minWidth: 175,
      width: 175,
      maxWidth: 175,
    },
    {
      Header: 'Prodction Volume',
      accessor: 'ProductVolume',
      minWidth: 150,
      width: 150,
      maxWidth: 150,
    },
    {
      Header: 'Stock O9',
      accessor: 'stock_o9 ',
      minWidth: 150,
      width: 150,
      maxWidth: 150,
    },
    {
      Header: 'Stock ERP',
      accessor: 'stock_erp',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
  ];

  return (
    <ContentPaper page={'Decomplexity'}>
      <div className='table-container'>
        {!loading ? (
          <SkuDetailsTable
            columns={Product_Development_Table}
            data={tableData}
            handleExport={handleExport}
            fullScreenToggle={toggle}
            fullscreen={fullscreen}
            type={'rfs'}
          />
        ) : (
          <AppLoader center size='lg' />
        )}
      </div>
    </ContentPaper>
  );
}
