export const filterCommonSkus = (recommended, reference) => {
  // Create a Set of recommended items with a unique key based on sku and plant
  const referenceSet = new Set(recommended.map((item) => `${item.sku}-${item.plant}`));

  // Filter reference items based on existence in referenceSet
  const filteredReference = reference.filter(
    (recItem) => !referenceSet.has(`${recItem.sku}-${recItem.plant}`),
  );

  // Keep only the common elements in recommended
  const commonRecommended = recommended.filter((refItem) =>
    referenceSet.has(`${refItem.sku}-${refItem.plant}`),
  );

  return {
    recommended: commonRecommended,
    reference: filteredReference,
  };
};

export const getAccordionTitle = (data, plant) => {
  if (!data) return 'N/A';
  return `${data.sku} | ${data.sku_description} | ${plant}`;
};

export const filterUniqueSkus = (referenceSkus) => {
  const uniqueSet = new Set();
  return referenceSkus.filter(({ plant, sku }) => {
    const combination = `${plant}_${sku}`;
    if (uniqueSet.has(combination)) {
      return false;
    }
    uniqueSet.add(combination);
    return true;
  });
};

export const getSelectedPlants = (data) => {
  const result = {};

  data.forEach((item) => {
    const {
      production_plant_id,
      secondary_reference_sku,
      secondary_reference_sku_plant,
      primary_reference_sku,
      primary_reference_sku_plant,
    } = item;

    // Create the `selectedData` object
    const selectedData = {};

    // Add recommended SKU if both secondary_reference_sku and secondary_reference_sku_plant are present
    if (secondary_reference_sku && secondary_reference_sku_plant) {
      selectedData[`${secondary_reference_sku}_${secondary_reference_sku_plant}`] = true;
    }

    // Add reference SKU if both primary_reference_sku and primary_reference_sku_plant are present
    if (primary_reference_sku && primary_reference_sku_plant) {
      selectedData[`${primary_reference_sku}_${primary_reference_sku_plant}`] = true;
    }

    // Add or update the result object with the production plant ID as the key
    result[production_plant_id] = {
      selectedData,
    };
  });

  return result;
};

export const makePayload = (inputData) => {
  return Object.values(inputData)
    .filter((item) => item?.sku_id)
    .map((entry) => {
      if (!entry?.sku_id) return;

      let primaryReferenceSku = null;
      let primaryReferenceSkuPlant = null;
      let secondaryReferenceSku = null;
      let secondaryReferenceSkuPlant = null;

      // Iterate over selectedData keys to find reference and recommended entries
      Object.keys(entry.selectedData || {}).forEach((key, index) => {
        const parts = key.split('_');
        if (index === 0) {
          primaryReferenceSku = parts[0]; // Extract SKU from reference
          primaryReferenceSkuPlant = parts[1]; // Extract plant from reference
        } else {
          secondaryReferenceSku = parts[0];
          secondaryReferenceSkuPlant = parts[1]; // Extract plant from reference
        }
      });

      // Return the converted object
      return {
        sku_id: entry.sku_id,
        primary_reference_sku: primaryReferenceSku,
        primary_reference_sku_plant: primaryReferenceSkuPlant,
        secondary_reference_sku: secondaryReferenceSku,
        secondary_reference_sku_plant: secondaryReferenceSkuPlant,
        production_plant_id: entry.production_plant_id,
      };
    });
};

export const getPlants = (plants) =>
  plants.reduce((acc, plant) => ({ ...acc, [plant.id]: {} }), {});
