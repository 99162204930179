import React, { useState, useContext, useMemo } from 'react';
import ContentPaper from '../../components/layouts/rfs/Content';
import { RfsContext } from '../../contexts/RfsContext';
import { AppLoader } from '../../components/common/loader';
import FtrReportComp from './ftrReportComp';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function FtrTeamReport() {
  const [validationErrors, setValidationErrors] = useState({}); //for Report
  const [queryClient] = React.useState(() => new QueryClient());

  //Report Data for New UI
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Team_Name',
        header: 'Team Name',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'Total_Rfs',
        header: 'Total RFS',
      },
      {
        accessorKey: 'Number_Of_Rfs_Okay',
        header: 'Number of RFS Okay',
      },
      {
        accessorKey: 'Number_Of_Rfs_Unlocked',
        header: 'Number of RFS Unlocked',
      },
      {
        accessorKey: 'Percentage_Of_Ftr',
        header: 'Percentage of FTR',
      },
    ],
    [validationErrors],
  );

  return (
    <ContentPaper page='ftr-report'>
      <div id='customReport'>
        {
          <QueryClientProvider client={queryClient}>
            <FtrReportComp
              columns={columns}
              setValidationErrors={setValidationErrors}
              validationErrors={validationErrors}
              unique={'Team_Name'}
              enableEditing={false}
              type={'ftrTeamLevel'}
            />
          </QueryClientProvider>
        }
      </div>
    </ContentPaper>
  );
}

export default FtrTeamReport;
