import { Button } from '@mantine/core';
import { useContext, useMemo, useState } from 'react';
import MantineTable from '../../../../common/table/mantine';
import { ImpactedSkus } from './impactedSkus';
import AltBoms from './altBoms';
import { RfsContext } from '../../../../contexts/RfsContext';

export const MaterialTable = ({ readOnly }) => {
  const {
    form: { d2pFeedback },
  } = useContext(RfsContext);
  const [openSkusModal, setSkusModal] = useState({ open: false, material: null });
  const [altBoms, showAltBoms] = useState({ open: false, boms: null });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'material_code',
        header: 'Material Code',
      },
      {
        accessorKey: 'material_description',
        header: 'Material Description',
      },
      {
        header: 'View Impacted SKUs',
        id: 'previous_zitec_response',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() =>
                setSkusModal({
                  open: true,
                  material: row.original.material_code,
                  material_id: row.original.material_id,
                })
              }
              color='dark'
              fullWidth
              size='sm'
              radius='sm'
              className='add-supplier-button'
            >
              Impacted SKUs
            </Button>
          );
        },
      },
    ],
    [],
  );
  return (
    <>
      <MantineTable
        columns={columns}
        initialData={d2pFeedback.filter(
          ({ artwork_adaption_actually_needed }) => artwork_adaption_actually_needed,
        )}
        enablePagination={false}
        enableSorting={true}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        loading={false}
        enableColumnFilters={false}
        enableColumnActions={false}
        mantineTableHeadProps={{
          sx: {
            '& tr': {
              backgroundColor: '#D8D8D8',

              '& .mantine-TableHeadCell-Content': {
                color: '#1E1E1E',
              },
            },
          },
        }}
      />
      {openSkusModal.open && (
        <ImpactedSkus
          close={() => setSkusModal({ open: false, material: null, material_id: null })}
          altBoms={altBoms}
          showAltBoms={showAltBoms}
          readOnly={readOnly}
          {...openSkusModal}
        />
      )}
      {altBoms.open && (
        <AltBoms
          close={() => {
            showAltBoms({ open: false, boms: null });
          }}
          {...altBoms}
        />
      )}
    </>
  );
};
