import React from 'react';
import { Modal, Button, Grid, Group, Text } from '@mantine/core';

import { InfoCircle } from 'tabler-icons-react';

export const MixoverChangeAlert = ({ mixChangeOverAlert, setMixChangeOverAlert }) => {
  const handleClose = () => {
    setMixChangeOverAlert(false);
  };

  const handleOk = () => {
    handleClose();
  };

  return (
    <>
      <Modal
        className='Invalid-modal'
        opened={mixChangeOverAlert}
        onClose={() => handleClose()}
        title='Warning'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='invalid-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <Text size='sm'>
                For Mix Changeover at least two materials are required. Please select one more
                material to proceed with Mix Changeover.
              </Text>
            </Group>
          </Grid.Col>

          <Grid.Col span={4} offset={8}>
            <Button
              onClick={() => handleOk()}
              variant='gradient'
              color='primary'
              fullWidth
              style={{
                background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
                color: 'black',
              }}
            >
              OK
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
