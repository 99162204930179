import React, { useContext, useEffect, useState } from 'react';
import { MultiSelect, Text, TextInput, Textarea, Select } from '@mantine/core';
import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import { Link, useParams } from 'react-router-dom';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';
import { CommonContext } from '../../../contexts/common';
import { axios } from '../../../utils/axios';
import { AppLoader } from '../../../components/common/loader';
import { MaterialTable } from './material';
import { LiquidsTable } from './liquids';
import { ViewModal } from './modal';
import { routes } from '../../../routes';
import { RfsContext } from '../../../contexts/RfsContext';
import { liquidBomChanges } from '../../../constants';

const processTypes = [
  {
    value: '1',
    label: 'Scope Unlock',
  },
  {
    value: '0',
    label: 'Cancellation',
  },
];

export const VeamScopeUnlock = ({
  form,
  footer,
  userName,
  values,
  setValues,
  readOnly = false,
}) => {
  const params = useParams();
  const [teams, setTeams] = useState([]);
  const { rfsNumber } = params;
  const { veamScopeUnlockInfo } = useContext(ProjectOverviewContext);
  const { systemTeams } = useContext(CommonContext);
  const { fetchStatusVeam } = useContext(RfsContext);
  const [teamsLoading, setTeamsLoading] = useState(true);
  const [viewModal, setViewModal] = useState({
    scope_id: null,
    type: null,
    open: false,
    material_id: null,
  });
  const [materialStatus, setMaterialStatus] = useState({});
  const [disabledMaterials, setDisabledMaterials] = useState({});

  useEffect(() => {
    fetchStatusVeam(params.rfsNumber).then(
      (res) => {
        let data = res.data.data || [];
        data = data.filter((info) => [237, 218, 214, 230].includes(info.sla_weeks_id));
        const status = {};
        console.log(data);
        data.forEach((info) => {
          if (info.selection_type === 'materials') {
            if (
              info.material_type === 'Remove Material' &&
              [214, 230].includes(info.sla_weeks_id)
            ) {
              status[info.material_id] = info.status === 'Closed';
            } else if ([237, 218].includes(info.sla_weeks_id)) {
              status[info.material_id] = info.status === 'Closed';
            }
          }

          if (info.selection_type === 'liquids') {
            if (!status[info.scope_id]) {
              const index = data.findIndex((item) => {
                if (
                  info.bom_changes === liquidBomChanges.removal &&
                  [214, 230].includes(info.sla_weeks_id)
                ) {
                  return (
                    item.status === 'Closed' &&
                    item.scope_id === info.scope_id &&
                    info.selection_type === 'liquids' &&
                    [214, 230].includes(item.sla_weeks_id)
                  );
                } else if ([237, 218].includes(info.sla_weeks_id)) {
                  return (
                    item.status === 'Closed' &&
                    item.scope_id === info.scope_id &&
                    info.selection_type === 'liquids' &&
                    item.bom_changes !== liquidBomChanges.removal &&
                    [237, 218].includes(item.sla_weeks_id)
                  );
                }
              });

              status[info.scope_id] = index > -1 ? true : false;
            }
          }
        });

        setDisabledMaterials(status);
      },
      (error) => {
        console.log(error);
      },
    );
  }, [params.rfsNumber]);

  useEffect(() => {
    function getScopeUnlockTeams() {
      axios
        .get(`/veam/teams/${rfsNumber}`)
        .then(
          (teamsInfo) => {
            const teams = [
              ...new Set([...values.teams_involved, ...teamsInfo.data.teams_responsible]),
            ];
            setTeams(teamsInfo.data.teams_responsible);
            setValues({ teams_involved: teams }, null, 'veam');
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setTeamsLoading(false));
    }
    getScopeUnlockTeams();
  }, []);

  useEffect(() => {
    axios.get(`/veam/trials-info/${rfsNumber}`).then(
      (res) => {
        setMaterialStatus(res.data.data);
      },
      (error) => {
        console.log(error);
      },
    );

    return () => setMaterialStatus({});
  }, []);

  const isDisabled = () => {
    return values.id;
  };

  const StatusMessage = () => {
    const {
      rfs_reversal_closure_date,
      child_rfs_number,
      childRfsLink = null,
      scope_unlock_flag,
    } = veamScopeUnlockInfo;
    return rfs_reversal_closure_date ? (
      <div className='scope-unlock-status'>
        {scope_unlock_flag === '0' ? (
          'Scope unlock is complete'
        ) : (
          <>
            {`Scope unlock is complete.`} <br />
            {childRfsLink ? (
              <Link className='white rfs-link' to={`${routes.project(child_rfs_number).root}`}>
                Click here to view child RFS <span className='blue'>{`${child_rfs_number}`}</span>
              </Link>
            ) : (
              <>
                <span>
                  <span>Click here to view child RFS </span>{' '}
                  <span className='red'>{`${child_rfs_number} (Deleted)`}</span>
                </span>
              </>
            )}
          </>
        )}
      </div>
    ) : (
      <div className='scope-unlock-status'>Reversal is in progress.</div>
    );
  };

  if (teamsLoading) {
    return (
      <ContentPaper page={'ScopeUnlock'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <ContentPaper page={'ScopeUnlock'} footer={footer}>
      <ProjectName />

      <div className='form-container'>
        <h5 className='sectionHeadings'>Scope Unlock</h5>

        {isDisabled() && <StatusMessage />}

        <TextInput
          className='linear-input'
          value={values.initiated_by ? values.initiated_by : userName}
          label='Scope Unlock By'
          disabled={true}
        />
        <MultiSelect
          className='linear-input'
          placeholder='Pick the ones that apply'
          name='teams_involved'
          label={<Text size='xl'>Teams Involved</Text>}
          searchable
          clearable
          required
          nothingFound='No options'
          data={systemTeams}
          value={values.teams_involved}
          onChange={(value) => {
            value = value.concat(teams);
            value = [...new Set(value)];
            setValues({ teams_involved: value }, null, 'veam');
          }}
          disabled={isDisabled() || readOnly}
        />

        <Select
          className='linear-input'
          placeholder='Team Responsible'
          name='teams_responsible'
          label={<Text size='xl'>Team Responsible</Text>}
          required
          searchable
          nothingFound='No options'
          data={systemTeams}
          value={values?.teams_responsible}
          onChange={(value) => setValues({ teams_responsible: value }, null, 'veam')}
          disabled={isDisabled() || readOnly}
        />

        <Select
          className='linear-input'
          placeholder='Scope Unlock / Cancellation'
          name='scope_unlock_flag'
          label={<Text size='xl'>Select Process</Text>}
          required
          nothingFound='No options'
          data={processTypes}
          value={values.scope_unlock_flag}
          onChange={(value) => setValues({ scope_unlock_flag: value }, null, 'veam')}
          disabled={isDisabled() || readOnly}
        />
        <Textarea
          className='linear-input'
          label='Comments'
          placeholder='State your reason for scope unlock/cancellation here (255 Character limit)'
          required
          name='comment'
          autosize
          value={values.comment?.length > 255 ? values.comment.substring(0, 255) : values.comment}
          minRows={4}
          onChange={(event) =>
            setValues(
              {
                comment:
                  event.target.value.length > 255
                    ? event.target.value.substring(0, 255)
                    : event.target.value,
              },
              null,
              'veam',
            )
          }
          disabled={isDisabled() || readOnly}
        />
        <div style={{ display: 'flex' }}>
          <div style={{ width: '30rem', paddingLeft: '3rem' }}></div>
          <div>
            {values.comment?.length > 254 && (
              <div className='custom-mantine-Textarea-error'>
                255 Character limit reached, exceeding character would be truncated on saving.
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='sku-table'>
        <h5 className='sectionHeadings'>Select Material/liquids for Scope Unlock/Cancellation</h5>

        {form.scopeSelectedMaterials.length > 0 && (
          <MaterialTable
            materials={[...form.scopeSelectedMaterials].filter((val) => !materialStatus[val.id])}
            setViewModal={setViewModal}
            values={values}
            setValues={setValues}
            disabled={isDisabled() || readOnly}
            status
            disabledMaterials={disabledMaterials}
          />
        )}
        <br />
        {form.selectedScope.liquids.length > 0 && (
          <LiquidsTable
            liquids={[...form.selectedScope.liquids].filter(
              (val) => !materialStatus[val.selection_number],
            )}
            setViewModal={setViewModal}
            values={values}
            setValues={setValues}
            disabled={isDisabled() || readOnly}
            disabledMaterials={disabledMaterials}
          />
        )}
      </div>

      {viewModal.open && (
        <ViewModal form={form} viewModal={viewModal} setViewModal={setViewModal} />
      )}
    </ContentPaper>
  );
};
