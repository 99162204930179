import { Grid, Select, TextInput, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useState } from 'react';

export const TableSearch = ({
  columns,
  toBeSearched,
  setToBeSearched,
  filterMultiSearch,
  data,
}) => {
  const [search, setSearch] = useState(null);
  const [debounced] = useDebouncedValue(search, 500);

  useEffect(() => {
    filter(debounced || '');
  }, [debounced]);

  const filter = (value) => {
    const input = value.length > 0 ? value.split(' ') : [];

    const myArrayFiltered = data.filter((el) => {
      return input.some((f) => {
        if (['N/A', 'n/a'].includes(value)) {
          return !el[toBeSearched] || ['N/A', 'n/a'].includes(el[toBeSearched]);
        } else {
          if (!el[toBeSearched] || !f) return false;
          return el[toBeSearched].toString().toLowerCase().includes(f.toString().toLowerCase());
        }
      });
    });

    filterMultiSearch(input, myArrayFiltered);
  };

  return (
    <Grid gutter='md'>
      <Grid.Col span={6}>
        <Select
          placeholder='Select The Column'
          onChange={(value) => {
            setToBeSearched(value);

            if ((search || '').length > 0) {
              setSearch('');
              filterMultiSearch({ target: { value: '' } });
            }
          }}
          data={columns
            .filter(
              (c) => !['title', 'data_id'].includes(c.accessorKey || c.id) && !c.hideSearchAll,
            )
            .map((column) => {
              return { value: column.accessorKey || column.id, label: column.header };
            })}
          style={{ position: 'relative', zIndex: '1' }}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Tooltip
          arrowOffset={10}
          arrowSize={4}
          label='To search for multiple values, please enter space-separated values e.g 10023 12309 11287'
          withArrow
          position='top-start'
          multiline
          width={220}
        >
          <TextInput
            value={search}
            placeholder='Search'
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            style={{ position: 'relative', zIndex: '0' }}
            disabled={toBeSearched === ''}
          />
        </Tooltip>
      </Grid.Col>
    </Grid>
  );
};
