import React, { useContext, useMemo } from 'react';
import { Text, Aside, Center } from '@mantine/core';
import { RfsContext } from '../../contexts/RfsContext';
import DLSTWorkflow from './DLSTWorkflow';
import VEAMWorkflow from './VEAMWorkflow';
import PSWorkflow from './PSworkflow';
import OSTMWorkflow from './ostmWorkflow';
import { RfsTypes } from '../../constants';

import './Workflow.css';
import SupplierChangeWorkflow from './supplierChangeWorkflow';
import { useParams } from 'react-router-dom';

const Workflow = ({ leftNav, setLeftNav }) => {
  const { rfsNumber } = useParams();
  const { form } = useContext(RfsContext);
  const { rfs_type } = form.rfsInformation;

  const components = (type) => {
    const list = {
      [RfsTypes.osku]: <OSTMWorkflow />,
      [RfsTypes.veam]: <VEAMWorkflow />,
      [RfsTypes.productionSite]: <PSWorkflow />,
      [RfsTypes.delisting]: <DLSTWorkflow />,
      [RfsTypes.supplierChange]: <SupplierChangeWorkflow />,
    };
    return list[type];
  };

  const WorkflowComponent = useMemo(() => {
    return components(rfs_type);
  }, [rfsNumber]);

  return (
    <Aside
      p='md'
      style={{
        backgroundColor: '#2d2f34',
        position: 'relative',
        height: '100%',
        bottom: '0.8px',
        padding: '0',
      }}
      hiddenBreakpoint='sm'
      width={{ lg: '15rem' }}
    >
      <div className={`nav-close-button-workflow`}>
        <span onClick={() => setLeftNav(!leftNav)} className='vertical-text'>
          Close
        </span>
      </div>
      <Center>
        <Text weight={700} size='lg' color='#f5e003'>
          WORKFLOW
        </Text>
      </Center>
      {WorkflowComponent}
    </Aside>
  );
};

export default Workflow;
