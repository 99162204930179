import Info from './info';
import { Col, Row } from 'react-bootstrap';
import { Text, SimpleGrid, Textarea } from '@mantine/core';
import { useContext, useEffect } from 'react';
import { RfsContext } from '../../../../contexts/RfsContext';
import { useParams } from 'react-router-dom';
import { axios } from '../../../../utils/axios';
import { AuthContext } from '../../../../contexts/AuthContext';
import { NpsIds, rfsStatus } from '../../../../constants';
import SubmitProjectModal from '../../../../components/modal/SubmitProjectModal';
import RejectDraftModal from '../../../../components/modal/RejectDraftModal';
import { NpsComponent } from '../../../../common/nps';
import TechFeasibilityModal from '../../../../components/modal/techFeasibilityModal/modal';
import { SupplierContext } from '../../../../contexts/SupplierContext';

export const PMO = (props) => {
  const {
    tfModal,
    setTfModal,
    npsOpened,
    setNpsOpened,
    techFeasibility,
    setTechFeasibility,
    validate,
    submitProjectModal,
    setSubmitProjectModal,
    rejectDraftModal,
    setRejectDraftModalModal,
    pmo_comment_length,
    handleTechChange,
    readOnly,
  } = props;

  const { rfsNumber } = useParams();
  const { form, initiator_email } = useContext(RfsContext);
  const { userEmail } = useContext(AuthContext);
  const { plantsLoading } = useContext(SupplierContext);
  const { submission_date, status } = form.rfsInformation;

  useEffect(() => {
    fetchTechFeasibility(rfsNumber);
  }, [rfsNumber]);

  const fetchTechFeasibility = (rfsNumber) => {
    axios.get(`/tech-feasibility/fetch/${rfsNumber}`).then(
      (res) => {
        const response = res.data.fetchedData;
        setTechFeasibility(response);
      },
      (error) => {},
    );
  };

  const editScope = () => {
    let access = false;
    if (
      ((initiator_email === userEmail && status === rfsStatus.draft) || !readOnly) &&
      [
        rfsStatus.tech_feasible,
        rfsStatus.tech_feasible2,
        rfsStatus.supplier_pmo1_active,
        rfsStatus.supplier_zitec_active,
        rfsStatus.supplier_pmo2_active,
      ].includes(status)
    ) {
      access = true;
    }

    return access;
  };

  return (
    <div>
      <Info
        isSubmissionReview
        technicalFeasibility
        initialItems={0}
        readOnly={editScope()}
        {...props}
      />

      <div>
        <Row>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col
            md={1}
            style={{
              width: '2.0833%',
              flex: '0 0 2.0833%',
              maxWidth: ' 2.0833%',
            }}
          ></Col>
          <Col
            md={{ span: 3 }}
            style={{
              marginTop: '1rem',
            }}
          >
            <h5 className='sectionHeadings'>PMO Section</h5>
          </Col>
        </Row>
        <Row style={{ marginBottom: '0.5rem' }}>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>

          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2}>
              <Text size='sm' weight={600}>
                Project Submission Date:
              </Text>
              <Text size='sm' weight={400}>
                {submission_date}
              </Text>
            </SimpleGrid>
          </Col>
        </Row>

        <Row style={{ marginTop: '0.5rem' }}>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>

          <Col md={{ span: 2 }}>
            <Text size='sm' weight={600}>
              PMO Comments: <span style={{ color: 'red', fontWeight: '400' }}>*</span>
            </Text>
          </Col>
          <Col
            md={1}
            style={{
              width: '3.8%',
              flex: '0 0 3.8%',
              maxWidth: ' 3.8%',
            }}
          ></Col>

          <Col md={{ span: 8 }} style={{ marginBottom: '1rem' }}>
            {status === rfsStatus.supplier_pmo1_active &&
            status !== rfsStatus.draft &&
            !readOnly ? (
              <>
                <Textarea
                  error={pmo_comment_length === 0 || (validate && !techFeasibility.pmo_comments)}
                  placeholder='PMO Comments (Character limit 500)'
                  disabled={readOnly || plantsLoading}
                  name='pmo_comments'
                  value={techFeasibility.pmo_comments}
                  autosize
                  minRows={4}
                  onChange={(event) => handleTechChange(event)}
                />
                {techFeasibility.pmo_comments?.length > 499 && (
                  <div className='custom-mantine-Textarea-error'>
                    500 Character limit reached, exceeding character would be truncated on saving.
                  </div>
                )}
              </>
            ) : (
              <Text size='sm'>{techFeasibility.pmo_comments}</Text>
            )}
          </Col>
        </Row>
      </div>

      {tfModal && <TechFeasibilityModal opened={tfModal} setOpened={setTfModal} />}

      {submitProjectModal && (
        <SubmitProjectModal opened={submitProjectModal} setOpened={setSubmitProjectModal} />
      )}
      {rejectDraftModal && (
        <RejectDraftModal opened={rejectDraftModal} setOpened={setRejectDraftModalModal} />
      )}

      {npsOpened && !readOnly && (
        <NpsComponent
          rfs_number={rfsNumber}
          opened={npsOpened}
          setOpened={setNpsOpened}
          callback={() => {
            setNpsOpened(false);
          }}
          id={NpsIds().techFeasibility}
        />
      )}
    </div>
  );
};
