import { Button, Modal, Input, NumberInput, Alert } from '@mantine/core';
import { useState } from 'react';
import styled from 'styled-components';
import { IconAlertCircle } from '@tabler/icons-react';

const CustomInput = styled(Input)`
  width: 100%;
  margin-bottom: 16px;
`;

const ButtonContainer = styled('div')`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: end;
`;

const Content = styled('div')`
  margin-top: 16px;
`;

export const AddNew = ({ status, setStatus, onChange }) => {
  return (
    <>
      <Modal
        opened={status.open}
        onClose={() => setStatus({ open: false, id: null })}
        title='Select Existing ABI Suppliers/Indicate New'
      >
        {status.error && (
          <Alert icon={<IconAlertCircle size='1rem' />} title='Error' color='red'>
            {status.error}
          </Alert>
        )}
        <ModalContent onChange={onChange} />
      </Modal>
    </>
  );
};

const ModalContent = ({ onChange }) => {
  const [name, setName] = useState(null);
  const [number, setNumber] = useState(null);

  const handleConfirm = () => {
    onChange({
      label: `${number} - ${name.trim()}`,
      value: `${number} - ${name.trim()}`,
      selected: true,
      isNew: true,
    });
  };

  return (
    <Content>
      <Input.Wrapper id='add-new-suppplier-name' withAsterisk label='New ABI Supplier Name'>
        <CustomInput
          label='New ABI Supplier Name'
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
      </Input.Wrapper>

      <Input.Wrapper id='add-new-suppplier-number' withAsterisk label='New ABI Supplier Number'>
        <NumberInput
          hideControls
          name='duration'
          onChange={(value) => {
            setNumber(value);
          }}
          min={1}
        />
      </Input.Wrapper>
      <ButtonContainer>
        <Button
          disabled={!name || name.trim().length === 0 || !number}
          onClick={handleConfirm}
          color='primary'
          variant='gradient'
          gradient={{ from: '#e3af32', to: '#f4e00f' }}
          radius='sm'
        >
          Confirm
        </Button>
      </ButtonContainer>
    </Content>
  );
};
