import React from 'react';
import RfsLayout from '../../components/layouts/RfsLayout';
import ProductDevlopmentComp from './ProductDevelopment';

export default function ProductDevelopmentPage() {
  return (
    <div>
      <RfsLayout>
        <ProductDevlopmentComp />
      </RfsLayout>
    </div>
  );
}
