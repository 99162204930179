import React from 'react';

const Dot = ({dotStatus}) => {
  let Result = <></>;

  Result = () => (dotStatus == 1) ? <div title={"No Step has Started"} className={"dot dot1"}></div> : 
           (dotStatus == 2) ? <div title={"in progress"} className={"dot dot2"}></div> :
           <div title={"All Steps are Closed"} className={"dot dot3"}></div>;

  return <Result />
}

export default Dot;