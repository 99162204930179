import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { usePagination } from '@mantine/hooks';

import { Styles } from '../../../../../components/tables/skutable/production-site/styles';
import { Button, Tooltip, Loader, Text, Pagination } from '@mantine/core';
import { InfoSquare } from 'tabler-icons-react';
import { axios } from '../../../../../utils/axios';
import { AppLoader } from '../../../../../components/common/loader';
import moment from 'moment';
import { InfoCircle } from 'tabler-icons-react';
import { SkuDetailsTable } from '../../../../../components/tables/SKUDetailsTable/SkuDetailsTable';

const addUnderscore = (string) => {
  let numberCount = 0;

  const result = string
    .split('') // Split the string into an array of individual characters
    .map((char) => {
      if (!isNaN(char)) {
        numberCount++;
        if (numberCount === 4) {
          numberCount = 0;
          return char + '_';
        }
      }
      return char;
    })
    .join(''); // Join the modified array back into a string

  return result;
};

const renameKeysAndOmitFields = (array, renameMap, fieldsToOmit) => {
  if (array) {
    return array.map((obj) => {
      const renamedObj = {};
      for (const key in obj) {
        if (fieldsToOmit.includes(key)) {
          continue; // Skip fields to omit
        }
        const newKey = renameMap[key] || key; // Use the renamed key if available
        renamedObj[newKey] = obj[key];
      }
      return renamedObj;
    });
  } else {
    return [];
  }
};

const ShowObsoletesTable = ({ data = null, scope_id = null }) => {
  const [tableData, setTableData] = useState([
    {
      Material_code: '0',
      Material_description: 'No BOM Available',
      submitted_obsolete_risk_min: '0',
      submitted_obsolete_risk_max: '0',
      current_obsolete_risk_min: '0',
      current_obsolete_risk_max: '0',
      stock_covered_till_week: '0',
    },
  ]);
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (scope_id) {
      setLoading(true);

      axios
        .get(`delisting/current_obsoletes/fetch/${scope_id}`)
        .then(
          (res) => {
            setTableData(res.data.data.obsoletes);
          },
          (err) => {
            console.log(err);
          },
        )
        .finally(() => setLoading(false));
    }
  }, [scope_id]);

  const columns = useMemo(
    () => [
      {
        Header: 'Material Code',
        accessor: 'Material_code',
      },
      {
        Header: 'Material description',
        accessor: 'Material_description',
      },
      {
        Header: (
          <Tooltip
            label='Stock on hand + Stock on Supplier'
            placement='start'
            withArrow
            className='data'
          >
            <div>
              Submitted Obsoletes Risk (Min) ($)
              <InfoSquare size={'1rem'} color={'rgb(229, 182, 17)'} />
            </div>
          </Tooltip>
        ),
        accessor: 'submitted_obsolete_risk_min',
        Cell: ({ row }) => {
          return row.original.submitted_obsolete_risk_min === 'No Contract Found'
            ? 'No Contract Found'
            : parseInt(row.original.submitted_obsolete_risk_min)?.toLocaleString();
        },
      },
      {
        Header: (
          <Tooltip
            label='Stock on hand + Stock on Supplier + Planned Production'
            placement='start'
            withArrow
            className='data'
          >
            <div>
              Submitted Obsoletes Risk (Max) ($)
              <InfoSquare size={'1rem'} color={'rgb(229, 182, 17)'} />
            </div>
          </Tooltip>
        ),
        accessor: 'submitted_obsolete_risk_max',
        Cell: ({ row }) => {
          return row.original.submitted_obsolete_risk_max === 'No Contract Found'
            ? 'No Contract Found'
            : parseInt(row.original.submitted_obsolete_risk_max)?.toLocaleString();
        },
      },
      {
        Header: (
          <Tooltip
            label='Stock on hand + Stock on Supplier + Planned Production'
            placement='start'
            withArrow
            className='data'
          >
            <div>
              Current Obsoletes Risk (Min) ($)
              <InfoSquare size={'1rem'} color={'rgb(229, 182, 17)'} />
            </div>
          </Tooltip>
        ),
        accessor: 'current_obsolete_risk_min',
        Cell: ({ row }) => {
          return row.original.current_obsolete_risk_min === 'No Contract Found'
            ? 'No Contract Found'
            : parseInt(row.original.current_obsolete_risk_min)?.toLocaleString();
        },
      },
      {
        Header: (
          <Tooltip
            label='Stock on hand + Stock on Supplier + Planned Production'
            placement='start'
            withArrow
            className='data'
          >
            <div>
              Current Obsoletes Risk (Max) ($)
              <InfoSquare size={'1rem'} color={'rgb(229, 182, 17)'} />
            </div>
          </Tooltip>
        ),
        accessor: 'current_obsolete_risk_max',
        Cell: ({ row }) => {
          return row.original.current_obsolete_risk_max === 'No Contract Found'
            ? 'No Contract Found'
            : parseInt(row.original.current_obsolete_risk_max)?.toLocaleString();
        },
      },
      {
        Header: (
          <Tooltip
            label='Dependent on forecasted depletion of SKU etc.'
            placement='start'
            withArrow
            radius='md'
            className='data'
          >
            <div>
              Submitted Obsoletes Stock Coverage until (YEAR_WEEK)
              <InfoSquare size={'1rem'} color={'rgb(229, 182, 17)'} />
            </div>
          </Tooltip>
        ),
        accessor: 'submitted_stock_covered_till_week',
        Cell: ({ row }) => {
          const stock_covered_till_week = String(row.original.stock_covered_till_week);
          const modifiedString = addUnderscore(stock_covered_till_week);
          return modifiedString;
        },
      },
      {
        Header: (
          <Tooltip
            label='Dependent on forecasted depletion of SKU etc.'
            placement='start'
            withArrow
            radius='md'
            className='data'
          >
            <div>
              Current Obsoletes Stock Coverage until (YEAR_WEEK)
              <InfoSquare size={'1rem'} color={'rgb(229, 182, 17)'} />
            </div>
          </Tooltip>
        ),
        accessor: 'current_stock_covered_till_week',
        Cell: ({ row }) => {
          const stock_covered_till_week = String(row.original.stock_covered_till_week);
          const modifiedString = addUnderscore(stock_covered_till_week);
          return modifiedString;
        },
      },
    ],
    [],
  );

  const renameMapView = {
    material_code: 'Material_code',
    material_desc: 'Material_description',
    obsoletes_risk_max: 'Obsolete_risk_max',
    obsoletes_risk_min: 'Obsolete_risk_min',
    stock_coverage_till_week: 'stock_covered_till_week',
  };

  const fieldsToOmitView = ['createdAt', 'id', 'rfs_number', 'scope_id', 'updatedAt'];

  if (loading) return <AppLoader center size='md' />;

  return (
    <Styles>
      <div style={{ overflowX: 'auto', marginBottom: '2rem' }}>
        <div style={{ fontSize: '1rem', fontWeight: '500', marginBottom: '1rem' }}>
          Obsoletes values confirmed by initiator on:{' '}
          {moment(tableData[0]?.createdAt).format('DD-MM-YYYY')}
          <Text color='red' weight={500} size='xs'>
            <InfoCircle style={{ marginRight: '0.5rem' }} size={'0.8rem'} color={'red'} />
            Disclaimer: Estimate figures are based on today's value
          </Text>
        </div>
        <SkuDetailsTable
          columns={columns}
          data={data ? renameKeysAndOmitFields(data, renameMapView, fieldsToOmitView) : tableData}
          handleExport={false}
          fullScreenToggle={false}
          fullscreen={false}
          type={'users'}
          customPageIndex={pageIndex}
          setCustomPageIndex={setPageIndex}
          hideRowOptions
        />
      </div>
    </Styles>
  );
};

export default ShowObsoletesTable;
