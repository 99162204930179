import React, { useState, useEffect, useContext } from 'react';
import { Tabs } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import MaterialChangeCode from './materialStatusChange/materialChange';
import StandardPricingCode from './standardPricing/standardPricing';
import { SkuPlanning } from './skuPlanning/skuPlanning';
import PackMats from './packMatsProduction/packMats';
import MaterialPricing from './materialPricing/materialPricing';
import { Status99 } from './status99/status99';
import ShipmentCode from './Filling&Shipment/shipment';
import MaterialCreationCode from '../productDevelopment/Material Code Creation/MaterialCreation';
import PlantExtensionCode from '../productDevelopment/Product Plant Extension/PlantExtension';
import SupplierNameCode from '../productDevelopment/Supplier Name/supplierName';
import ArtworkDevelopmentCode from '../productDevelopment/Artwork Development/ArtworkDevelopment';
import EanApprovalCode from '../productDevelopment/Ean Approval/EanApproval';
import MaterialContractCode from '../productDevelopment/Material Contract/materialContract';
import { tabStyles } from '../../../utils/helpers';
import { routes } from '../../../routes';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';
import { RfsContext } from '../../../contexts/RfsContext';

export default function ImplementationProdSite({ trials, tabs }) {
  const [tab, setTab] = useState('msc');
  const navigate = useNavigate();
  const params = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const { getSLAWeeksIdsForRFS } = useContext(ProjectOverviewContext);
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [slaWeeksIds, setSlaWeeksIds] = useState([]);

  const isTabsVisible = !trials
    ? tabs?.includes('Material Code Creation') ||
      tabs?.includes('Production Plant Extension') ||
      tabs?.includes('Supplier Name') ||
      tabs?.includes('Artwork Development') ||
      tabs?.includes('EAN Approval') ||
      tabs?.includes('Material Contracts') ||
      tabs?.includes('Material Status Change') ||
      tabs?.includes('SKU Planning') ||
      tabs?.includes('Material Pricing') ||
      tabs?.includes('Pack Mats Production') ||
      tabs?.includes('Standard pricing') ||
      tabs?.includes('Status 99') ||
      tabs?.includes('Fillings/Shipment')
    : tabs?.includes('Material Status Change') ||
      tabs?.includes('SKU Planning') ||
      tabs?.includes('Material Pricing') ||
      tabs?.includes('Pack Mats Production') ||
      tabs?.includes('Standard pricing') ||
      tabs?.includes('Status 99') ||
      tabs?.includes('Fillings/Shipment');

  useEffect(() => {
    const sub = urlParams.get('sub');
    if (sub) {
      setTab(sub);
    }
  }, [urlParams]);

  useEffect(() => {
    setLoading(true);
    getSLAWeeksIdsForRFS(rfs_number).then((res) => {
      setLoading(false);
      let sla_weeks_ids = [];
      res.data.data.map(
        (item) =>
          sla_weeks_ids.includes(item.sla_weeks_id) === false &&
          sla_weeks_ids.push(item.sla_weeks_id),
      );
      setSlaWeeksIds(sla_weeks_ids);
      const defaultTab = getDefaultTab(sla_weeks_ids, trials);
      setTab(defaultTab);
    });
  }, [rfs_number]);

  const getDefaultTab = (ids, trials) => {
    let defaultValue;

    if (trials) {
      ids.includes(96) ? (defaultValue = 'msc') : (defaultValue = 'sku');
    } else {
      ids.includes(113) ? (defaultValue = 'mcc') : (defaultValue = 'ppe');
    }

    return defaultValue;
  };

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <ProjectName />
      <br />
      {isTabsVisible && (
        <Tabs
          onTabChange={(value) =>
            navigate(
              `${routes.project(params.rfsNumber).root}?tab=${urlParams.get('tab')}&sub=${value}`,
            )
          }
          grow
          variant='unstyled'
          styles={tabStyles}
          color='primary'
          defaultValue={tabs?.includes('Material Status Change') ? 'msc' : 'sku'}
          value={tab}
          keepMounted={false}
        >
          <Tabs.List>
            {!trials && tabs?.includes('Material Code Creation') && (
              <Tabs.Tab className='topTabs' value='mcc'>
                Materials Code Creation
              </Tabs.Tab>
            )}
            {!trials && tabs?.includes('Production Plant Extension') && (
              <Tabs.Tab className='topTabs' value='ppe'>
                Production Plant Extension
              </Tabs.Tab>
            )}
            {!trials && tabs?.includes('Supplier Name') && (
              <Tabs.Tab className='topTabs' value='sn'>
                Supplier Name
              </Tabs.Tab>
            )}
            {!trials && tabs?.includes('Artwork Development') && (
              <Tabs.Tab className='topTabs' value='ad'>
                Artwork Development
              </Tabs.Tab>
            )}
            {!trials && tabs?.includes('EAN Approval') && (
              <Tabs.Tab className='topTabs' value='ean'>
                EAN Approval
              </Tabs.Tab>
            )}
            {!trials && tabs?.includes('Material Contracts') && (
              <Tabs.Tab className='topTabs' value='mc'>
                Materials Contract
              </Tabs.Tab>
            )}
            {tabs.includes('Material Status Change') && (
              <Tabs.Tab className='topTabs' value='msc'>
                Materials Status Change
              </Tabs.Tab>
            )}
            {tabs?.includes('SKU Planning') && (
              <Tabs.Tab className='topTabs' value='sku'>
                SKU Planning
              </Tabs.Tab>
            )}
            {tabs?.includes('Material Pricing') && (
              <Tabs.Tab className='topTabs' value='mp'>
                Material Pricing
              </Tabs.Tab>
            )}
            {tabs?.includes('Pack Mats Production') && (
              <Tabs.Tab className='topTabs' value='pmp'>
                Pack Mats Production
              </Tabs.Tab>
            )}
            {tabs?.includes('Standard pricing') && (
              <Tabs.Tab className='topTabs' value='sp'>
                Standard Pricing
              </Tabs.Tab>
            )}
            {tabs?.includes('Status 99') && (
              <Tabs.Tab className='topTabs' value='s99'>
                Status 99
              </Tabs.Tab>
            )}
            {tabs?.includes('Fillings/Shipment') && (
              <Tabs.Tab className='topTabs' value='fs'>
                Filling and Shipment
              </Tabs.Tab>
            )}
          </Tabs.List>

          {!trials && tabs?.includes('Material Code Creation') && (
            <Tabs.Panel value='mcc'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <MaterialCreationCode />
              </div>
            </Tabs.Panel>
          )}

          {!trials && tabs?.includes('Production Plant Extension') && (
            <Tabs.Panel value='ppe'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <PlantExtensionCode />
              </div>
            </Tabs.Panel>
          )}

          {!trials && tabs?.includes('Supplier Name') && (
            <Tabs.Panel value='sn'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <SupplierNameCode />
              </div>
            </Tabs.Panel>
          )}

          {!trials && tabs?.includes('Artwork Development') && (
            <Tabs.Panel value='ad'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <ArtworkDevelopmentCode />
              </div>
            </Tabs.Panel>
          )}

          {!trials && tabs?.includes('EAN Approval') && (
            <Tabs.Panel value='ean'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <EanApprovalCode />
              </div>
            </Tabs.Panel>
          )}

          {!trials && tabs?.includes('Material Contracts') && (
            <Tabs.Panel value='mc'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <MaterialContractCode />
              </div>
            </Tabs.Panel>
          )}
          {tabs.includes('Material Status Change') && (
            <Tabs.Panel value='msc'>
              <div className='table-container'>
                <MaterialChangeCode />
              </div>
            </Tabs.Panel>
          )}
          {tabs?.includes('SKU Planning') && (
            <Tabs.Panel value='sku'>
              <div className='table-container'>
                <SkuPlanning />
              </div>
            </Tabs.Panel>
          )}

          {tabs?.includes('Material Pricing') && (
            <Tabs.Panel value='mp'>
              <div className='table-container'>
                <MaterialPricing />
              </div>
            </Tabs.Panel>
          )}

          {tabs?.includes('Pack Mats Production') && (
            <Tabs.Panel value='pmp'>
              <div className='table-container'>
                <PackMats />{' '}
              </div>
            </Tabs.Panel>
          )}

          {tabs?.includes('Standard pricing') && (
            <Tabs.Panel value='sp'>
              <div className='table-container'>
                <StandardPricingCode />
              </div>
            </Tabs.Panel>
          )}

          {tabs?.includes('Status 99') && (
            <Tabs.Panel value='s99'>
              <div className='table-container'>
                <Status99 />
              </div>
            </Tabs.Panel>
          )}

          {tabs?.includes('Fillings/Shipment') && (
            <Tabs.Panel value='fs'>
              <div className='table-container'>
                <ShipmentCode />
              </div>
            </Tabs.Panel>
          )}
        </Tabs>
      )}
    </ContentPaper>
  );
}
