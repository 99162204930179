import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { Text, Badge, Button, TextInput, Checkbox } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { useRef } from 'react';
import { RfsContext } from '../../../../contexts/RfsContext';
import { ProductionLog } from '../productionSiteLogModal';
import { ActionModal } from '../actionModal';

export function MaterialCodeTable({ materialData, save, refresh, rfs, disable }) {
  const [filterLoading, setFilterLoading] = useState(false);
  const data = materialData;
  const { submitMaterialCreation } = useContext(RfsContext);
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState('');
  let hasInvalidCharacters = null;
  const [form, setForm] = useState({
    rfs_number: rfs,
    material_id: 0,
    new_material_code: '',
    new_material_desc: '',
  });
  const formRef = useRef(null);
  formRef.current = form;

  const handleNumberChange = (name, event, id) => {
    var numberRegex = /^\d+$/;
    const val = event.target.value;

    let currentValues = { ...form };
    hasInvalidCharacters = numberRegex.test(val);
    if (hasInvalidCharacters) {
      currentValues[name] = event.target.value;
      currentValues['material_id'] = id;
      setForm({ [id]: currentValues });
    } else {
      currentValues[name] = '';
      setForm({ [id]: currentValues });
    }
  };

  const handleChange = (name, event, id) => {
    let currentValues = { ...form };
    currentValues[name] = event;
    currentValues['material_id'] = id;
    setForm({ [id]: currentValues });
  };

  function postMaterialCodeData(id) {
    submitMaterialCreation(formRef.current[id], refresh);
  }

  function postMaterialDescData(id) {
    submitMaterialCreation(formRef.current[id], refresh);
  }

  function getLogs(imp_id) {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Old Material Code',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_ps_bom.bom_component}</Text>;
        },
      },
      {
        Header: 'Old Material Name',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_ps_bom.component_description}</Text>;
        },
      },
      {
        Header: 'New Plant',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_ps_sku_reference.selected_plant}</Text>;
        },
      },

      {
        Header: 'Technical Pack Change',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_ps_bom.technical_pack_change}</Text>;
        },
      },
      {
        Header: 'Technical Quantity Change',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_ps_bom.technical_quality_change}</Text>;
        },
      },
      {
        Header: 'Artwork Management',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.int_rfs_ps_bom.artwork_change
                ? row.original.int_rfs_ps_bom.artwork_change
                : 'N/A'}
            </Text>
          );
        },
      },
      {
        Header: 'New Tech. Spec.',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.int_rfs_ps_bom.tech_spec
                ? row.original.int_rfs_ps_bom.tech_spec
                : 'Spec not in Data Base'}
            </Text>
          );
        },
      },
      {
        Header: 'Comments',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_ps_bom.comment}</Text>;
        },
      },
      {
        Header: 'New Material Code',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_material_creation.new_material_code;
          const showSubmit =
            formRef.current[row.original.int_rfs_ps_bom.id]?.new_material_code?.length > 0;
          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_material_creation.new_material_code}</Text>
          ) : (
            <>
              <TextInput
                placeholder='New Material Code'
                disabled={disable}
                error={
                  formRef?.current[row.original.int_rfs_ps_bom.id]?.new_material_code?.toString()
                    .length > 10
                    ? 'Invalid Number (Max Length: 10)'
                    : !hasInvalidCharacters
                }
                radius='md'
                hideControls
                onChange={(event) =>
                  handleNumberChange('new_material_code', event, row.original.int_rfs_ps_bom.id)
                }
              />
              {showSubmit && (
                <Button
                  disabled={
                    !formRef.current ||
                    formRef.current.length === 0 ||
                    formRef?.current[row.original.int_rfs_ps_bom.id]?.new_material_code?.toString()
                      .length > 10
                  }
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  onClick={() => postMaterialCodeData(row.original.int_rfs_ps_bom.id)}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'New Material Description',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_material_creation.new_material_desc;

          const showSubmit =
            formRef.current[row.original.int_rfs_ps_bom.id]?.new_material_desc?.length > 0;
          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_material_creation.new_material_desc}</Text>
          ) : (
            <>
              <TextInput
                placeholder='New Material Description'
                radius='md'
                disabled={disable}
                error={
                  formRef?.current[row.original.int_rfs_ps_bom.id]?.new_material_desc?.toString()
                    .length > 100
                    ? 'Length exceeded (Max Length: 100)'
                    : false
                }
                value={row.original.int_rfs_impl_material_creation.new_material_desc}
                onChange={(event) =>
                  handleChange(
                    'new_material_desc',
                    event.target.value,
                    row.original.int_rfs_ps_bom.id,
                  )
                }
              />
              {showSubmit && (
                <Button
                  disable={
                    formRef?.current[row.original.int_rfs_ps_bom.id]?.new_material_desc?.toString()
                      .length > 100
                  }
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  onClick={() => postMaterialDescData(row.original.int_rfs_ps_bom.id)}
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Status',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.status === 'In Progress' &&
                !row.original.finished_date &&
                moment(new Date()).format('YYYY-MM-DD') <=
                  moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'yellow'
                  : (row.original.status === 'In Progress' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.status === 'In Progress' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : row.original.status === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') <=
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'green'
                  : row.original.status === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'red'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.status}
            </Badge>
          );
        },
      },
      {
        Header: 'Action',
        Cell: ({ row }) => {
          return row.original.status === 'In Progress' || row.original.status === 'Not Started' ? (
            <Checkbox
              checked={checked}
              disabled={
                row.original.int_rfs_impl_material_creation.new_material_code &&
                row.original.int_rfs_impl_material_creation.new_material_desc &&
                row.original.status === 'In Progress' &&
                !disable
                  ? false
                  : true
              }
              onChange={(event) => {
                setChecked(event.target.checked);
                setStatus(row.original.id);
                setActionModal({ open: true, id: row.original.id });
              }}
              label='Confirm and Close'
            />
          ) : row.original.manually_closed && row.original.status === 'Closed' ? (
            <Text>Manual Closure</Text>
          ) : (
            <Text>Automatic Closure</Text>
          );
        },
      },
      {
        Header: 'Log',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => getLogs(row.original.id)}
              style={{ background: 'black', color: 'white' }}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('YYYY-MM-DD')
                : row.original.status}
            </Text>
          );
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            {filterLoading && (
              <tr>
                <td colSpan={13}>
                  <div className='loader-container'>
                    <AppLoader size={'md'} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={status}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Material Code Creation'
        />
      )}
    </Styles>
  );
}
