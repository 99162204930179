import { Button } from '@mantine/core';
import { liquidBomChanges } from '../../constants';

const FileList = ({ files = [], rfs_number, downloadFile }) => {
  rfs_number = rfs_number.replace(/[^a-zA-Z0-9]/g, '');

  return (files || []).length > 0 ? (
    <ul style={{ fontSize: '0.8rem' }}>
      {files.map((file, index) => (
        <li
          style={{
            color: '#e5b611',
            cursor: 'pointer',
          }}
          key={index}
          onClick={() => downloadFile(file.name, rfs_number.toLowerCase())}
        >
          {file.name}
        </li>
      ))}
    </ul>
  ) : (
    <p style={{ fontSize: '0.8rem' }}> No Files Attached </p>
  );
};

const materialRows = [
  {
    name: <b>Old Material Code</b>,
    render: (item) => item[`material_code`],
  },
  {
    name: <b>New Selected Material Code</b>,
    render: (item) => (item[`material_number`] ? item[`material_number`] : 'New Material Selected'),
  },
  {
    name: <b>Plant</b>,
    render: (item) => item.plant,
  },
  {
    name: <b>Technical Pack Change</b>,
    render: (item) =>
      item.technical_pack_change ? item.technical_pack_change : 'No Information Selected',
  },
  {
    name: <b>Technical Quality Change</b>,
    render: (item) =>
      item.technical_quality_change ? item.technical_quality_change : 'No Information Selected',
  },
  {
    name: <b>Artwork Change</b>,
    render: (item) => (item.artwork_change ? item.artwork_change : 'No Information Selected'),
  },
  {
    name: <b>Tech Spec Matrix</b>,
    render: (item) => (item.tech_spec_matrix ? item.tech_spec_matrix : 'No Information Selected'),
  },
  { name: <b>Changeover Type</b>, render: (item) => item.changeover_type },
  {
    name: <b>Mix Changeover</b>,
    render: (item) => (item.mix_changeover ? item.mix_changeover : 'No Information Selected'),
  },
  {
    name: <b>Changeover Date</b>,
    render: (item) => (item.changeover_date ? item.changeover_date : 'No Information Selected'),
  },
  {
    name: <b>Comment</b>,
    render: (item) => {
      return item.comment;
    },
  },
  {
    name: <b> SKUs </b>,
    render: (item, files, downloadFile, handleViewSkus) => {
      return (
        <Button
          variant='gradient'
          color='primary'
          fullWidth
          style={{ background: 'black', color: 'white' }}
          size='sm'
          radius='sm'
          onClick={() => handleViewSkus(item)}
        >
          View SKUs
        </Button>
      );
    },
  },
  {
    name: <b>Artwork Briefing</b>,
    render: (item, files, downloadFile) => {
      return <FileList files={files} downloadFile={downloadFile} rfs_number={item.rfs_number} />;
    },
  },
];

const liquidRows = [
  {
    name: <b>Old Liquid Code</b>,
    render: (item) => item[`liquid_code`],
  },
  {
    name: <b>New Selected Liquid Code</b>,
    render: (item) =>
      item[`liquid_type`] === 'Same Liquid' ||
      item.bom_changes === liquidBomChanges.removal ||
      [liquidBomChanges.drawing].includes(item.bom_changes)
        ? 'Same Liquid'
        : item[`liquid_number`]
        ? item[`liquid_number`]
        : 'New Liquid Selected',
  },
  {
    name: <b>Plant</b>,
    render: (item) => item.ComponentPlant,
  },
  {
    name: <b>BOM</b>,

    render: (item) => (item.bom ? item.bom : 'No Information Selected'),
  },
  {
    name: <b>Current Quantity</b>,
    render: (item) => (item.quantity ? item.quantity : 'No Information Selected'),
  },
  {
    name: <b>Type of change</b>,
    render: (item) => (item.bom_changes ? item.bom_changes : 'No Information Selected'),
  },
  {
    name: <b>New Quantity</b>,
    render: (item) => {
      var qty;
      var qtywUnit;
      if (item.quantity) {
        qty = item.quantity.split(/ (.*)/, 2);
      }
      if (qty && item.new_quantity) {
        const q = item.new_quantity;
        const arr = [q, qty[1]];
        qtywUnit = arr.join('');
      }
      return qtywUnit
        ? qtywUnit
        : item.new_quantity
        ? item.new_quantity
        : 'No Information Selected';
    },
  },

  {
    name: <b>Tech Spec Reference</b>,
    render: (item) => (item.tech_spec_matrix ? item.tech_spec_matrix : 'No Information Selected'),
  },
  { name: <b>Changeover Type</b>, render: (item) => item.changeover_type },
  { name: <b>Changeover Date</b>, render: (item) => item.changeover_date },
  { name: <b>Comment</b>, render: (item) => item.comment },
];

export const getBody = (
  data,
  type = 'material',
  files = [],
  downloadFile = () => {},
  handleViewSkus = () => {},
) => {
  const body = (type === 'material' ? materialRows : liquidRows).map(({ name, render }, index) => (
    <tr key={index}>
      <td className='prod-dev-table-header'>{name}</td>
      {data.map((item, i) =>
        name.props.children === 'Artwork Briefing' ? (
          i === 0 && (
            <td className='prod-dev-table-data' key={i} colSpan={data.length}>
              {render(item, files, downloadFile)}
            </td>
          )
        ) : (
          <td className='prod-dev-table-data' key={i}>
            {render(item, files, downloadFile, handleViewSkus)}
          </td>
        ),
      )}
    </tr>
  ));

  return body;
};
