import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ContentPaper from '../../layouts/rfs/Content';
import ProjectName from '../../projectName/projectName';
import { MarketExtension } from './marketExtension';
import { DemandPlanning } from './demandPlanning';
import { Status99 } from './status99';
import { TransferPricing } from './transferPricing';
import { tabStyles } from '../../../utils/helpers';
import { routes } from '../../../routes';
import { useSearchParams } from 'react-router-dom';
import { Tabs, Accordion, Button, Text } from '@mantine/core';

const ImplementationOSKU = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('me');
  const urlParams = new URLSearchParams(window.location.search);
  const [tab, setTab] = useState('me');
  const navigate = useNavigate();
  const params = useParams();
  const subTab = searchParams.get('sub') ? searchParams.get('sub') : 'me';

  useEffect(() => {
    setActiveTab(subTab);
  }, [subTab]);

  useEffect(() => {
    const sub = urlParams.get('sub');
    if (sub) {
      setTab(sub);
    }
  }, [urlParams]);
  return (
    <ContentPaper page={'ProductDevelopment'}>
      <ProjectName initialItem={0} />
      <br />
      <Tabs
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
        onTabChange={(value) =>
          navigate(
            `${routes.project(params.rfsNumber).root}?tab=${urlParams.get('tab')}&sub=${value}`,
          )
        }
        value={tab}
        defaultValue={'me'}
        keepMounted={false}
      >
        <Tabs.List>
          <Tabs.Tab className='topTabs' value='me'>
            Market Extension
          </Tabs.Tab>
          <Tabs.Tab className='topTabs' value='dp'>
            Demand Planning
          </Tabs.Tab>
          <Tabs.Tab className='topTabs' value='tp'>
            Transfer Pricing
          </Tabs.Tab>
          <Tabs.Tab className='topTabs' value='s99'>
            Status 99
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='me'>
          <MarketExtension activeTab={activeTab} />
        </Tabs.Panel>

        <Tabs.Panel value='dp'>
          <DemandPlanning activeTab={activeTab} />
        </Tabs.Panel>

        <Tabs.Panel value='tp'>
          <TransferPricing activeTab={activeTab} />
        </Tabs.Panel>

        <Tabs.Panel value='s99'>
          <Status99 activeTab={activeTab} />
        </Tabs.Panel>
      </Tabs>
    </ContentPaper>
  );
};

export default ImplementationOSKU;
