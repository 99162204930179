import { Modal } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import MantineTable from '../../../common/table/mantine';
import { transformSkuData } from '../../../pages/supplierChange/techFeasibility/pmoFinal/helper';
import { axios } from '../../../utils/axios';

export const ImpactedSkusPreview = ({ open, close, skus = [], material_code }) => {
  const [impactedSkus, setImpactedSkus] = useState([]);
  const [activeSkus, setActiveSkus] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    const getActiveSkus = () => {
      const active = {};
      skus.forEach((sku) => {
        if (sku.selected) active[`${sku.sku_number}`] = true;
      });

      return active;
    };

    if (skus.length > 0) {
      const active = getActiveSkus();
      setActiveSkus(active);
    }
  }, [skus]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'SKUs',
        header: 'SKU Number',
      },
      {
        accessorKey: 'SKUDescription',
        header: 'SKU Description',
      },
      {
        accessorKey: 'Markets',
        header: 'Markets',
      },
      {
        accessorKey: 'EANNumbers',
        header: 'EAN Number',
      },
      {
        accessorKey: 'ChangeoverDate',
        header: 'First Changeover',
      },
    ],
    [],
  );

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/supplier-changes/pmo_section/impacted_skus/${material_code}`,
      );
      setImpactedSkus(transformSkuData(response.data.data));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal size={'70%'} opened={open} title='Impacted SKUs' onClose={() => close()}>
        <MantineTable
          unique={'uniqueId'}
          columns={columns}
          initialData={impactedSkus.filter((sku) => activeSkus[sku.SKUs])}
          hideSelectColumn={true}
          enableSorting={true}
          enableRowSelection={false}
          enableTopToolbar={false}
          loading={loading}
          enableColumnActions={false}
          mantineTableHeadProps={{
            sx: {
              '& tr': {
                backgroundColor: '#D8D8D8',

                '& .mantine-TableHeadCell-Content': {
                  color: '#1E1E1E',
                },
              },
            },
          }}
          mantinePaperProps={{
            sx: {
              border: 'none !important',
              boxShadow: 'none',
            },
          }}
        />
      </Modal>
    </>
  );
};
