import React, { useState } from 'react';
import styled from 'styled-components';
import { Select, Button, NumberInput } from '@mantine/core';

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 10rem;
      padding: 0.8rem;
      text-align: center;
      width: 22rem;

      :last-child {
        border-right: 0;
      }
    }

    #header-row {
      background-color: #d8d8d8;
    }
    tr:nth-child(even) {
      background-color: #cdcdcd;
    }
    tr:nth-child(odd) {
      background-color: #fff;
    }
  }
  input {
    background-color: #fff;
  }
`;

export function MarketTable({ availableMarkets, handleAddMarket, existingVolumes }) {
  const [value, setValue] = useState('');
  const [value1, setValue1] = useState(null);

  const onlyNumbers = (str) => {
    return /\d/.test(str);
  };

  const isDisabled = () => {
    return !value || value === '' || !value1 || !onlyNumbers(value1) || validateVolume();
  };

  const getOptions = () => {
    const options = [];
    availableMarkets.map((item) => {
      const isExist = existingVolumes.findIndex((val) => val.market_region === item.market_region);
      if (isExist === -1)
        options.push({
          value: JSON.stringify(item),
          label: item.market_region,
        });
      return item;
    });
    return options;
  };

  const validateVolume = () => {
    if (isNaN(value1)) {
      return 'Volume must be a number';
    } else if (parseFloat(value1) <= 0) {
      return 'Please enter a number greater than 0';
    } else if (value1 > 1000000) {
      return 'Maximum limit 1000000';
    } else {
      return false;
    }
  };

  return (
    <div>
      <Styles>
        <div style={{ overflowX: 'auto' }}>
          <Select
            searchable
            placeholder='Enter Market'
            label='Markets'
            data={getOptions()}
            value={value}
            onChange={setValue}
          />
          <br></br>

          <NumberInput
            placeholder='0.01 (min) to 10,000,000 (max)'
            label='Volume (in HL)'
            required
            value={value1 || ''}
            onChange={(event) => {
              setValue1(event);
            }}
            min={0.01}
            max={10000000}
            precision={2}
            error={validateVolume()}
          />
        </div>
        <br></br>
        <Button
          onClick={() =>
            handleAddMarket({
              market_region: JSON.parse(value).market_region,
              market: JSON.parse(value).market,
              volume: value1,
            })
          }
          color='primary'
          variant='gradient'
          gradient={{ from: '#e3af32', to: '#f4e00f' }}
          radius='xs'
          size='lg'
          disabled={isDisabled()}
          paddingTop='200'
          styles={(theme) => ({
            root: {
              border: '2',
              height: 42,
              paddingLeft: 30,
              paddingRight: 30,
            },
          })}
        >
          {' '}
          Confirm{' '}
        </Button>
      </Styles>
    </div>
  );
}
