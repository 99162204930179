import { Button, Grid, Space, Tabs } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Table } from './table';
import { AppLoader } from '../../../../components/common/loader';
import { ViewBom } from './viewBom';
import { Col, Row } from 'react-bootstrap';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { ProjectOverview } from '../projectOverview';
import { CostingRequestsContext, useCrStatusCancelled } from '../../../../contexts/CostingRequest';
import {
  filterCommonSkus,
  filterUniqueSkus,
  getPlants,
  getSelectedPlants,
  makePayload,
} from './helper';
import { showAlert } from '../../../../utils/alerts';

export const ReferenceSKU = ({ activeTab }) => {
  const isCancelled = useCrStatusCancelled();
  const params = useParams();
  const { info } = useContext(CostingRequestsContext);
  const [loading, setLoading] = useState(false);
  const [bomModal, setBomsModal] = useState({
    open: false,
    sku: null,
    plant: null,
  });
  const [selectedSkus, setSelectSkus] = useState({});
  const [selectedSkusPayload, setSkusPayload] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [infoLoading, setInfoLoading] = useState(false);
  const [activePlant, setActivePlant] = useState();
  const [readOnly, setReadOnly] = useState(false);
  const [referenceSkus, setReferenceSkus] = useState({});
  const [allowedCountries, setAllowedCountries] = useState([]);

  const { producing_plant_data = [], sku_data = [] } = info;

  useEffect(() => {
    if (activeTab === 'referenceSKUSelection' && sku_data.length && producing_plant_data.length) {
      getStandardPricingCountries();
      setSkusPayload(getPlants(producing_plant_data));
      fetchSavedSkus();
      fetch();
      setActivePlant(producing_plant_data[0]?.id);
    }

    return () => {
      setSelectSkus({});
    };
  }, [activeTab, producing_plant_data, sku_data]);

  const fetchSavedSkus = async (loading = true) => {
    try {
      setInfoLoading(loading);
      const { data } = await axios.get(
        `/costing-request/step/selected-ref-sku-selection/${params.crNumber}`,
      );
      const plants = getSelectedPlants(data);
      const selectedPlantsLength = Object.keys(plants).length;
      if (selectedPlantsLength > 0) {
        setReadOnly(selectedPlantsLength);
        setSkusPayload({
          ...plants,
          allSelected: selectedPlantsLength > 1,
        });
      }
    } catch (e) {
    } finally {
      setInfoLoading(false);
    }
  };

  const getStandardPricingCountries = async () => {
    try {
      const response = await axios.get('user/v2/fetch/');
      const info = response.data.data[0];

      let countries = info.int_user_role_mappings
        .filter((role) => role.country && role.is_active && role.int_role?.name === 'GCC FP&A')
        .map((role) => role.country);

      countries = [...new Set(countries)];

      setAllowedCountries(countries);
    } catch (e) {
      console.log(e);
    }
  };

  const validate = () => {
    const error = {};
    Object.keys(selectedSkusPayload || {}).forEach((key) => {
      const item = selectedSkusPayload[key];
      if (Object.keys(item.selectedData || {}).length === 0) error[key] = true;
    });

    return error;
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const validationErrors = validate();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(errors);
        showAlert({ message: 'Request Submitted Successfully' }, 'error');
      } else {
        const payload = makePayload(selectedSkusPayload);
        await axios.post(`/costing-request/step/ref-sku-selection/${params.crNumber}`, payload);
        showAlert({ message: 'Request Submitted Successfully' }, 'success');
        fetchSavedSkus(false);
      }
    } catch (e) {
      showAlert(e, 'error');
    } finally {
      fetchSavedSkus(false);
      setSubmitLoading(false);
    }
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/costing-request/step/get-ref-sku-selection/${params.crNumber}`,
      );
      const { StepStarted, recommended, PS, pegasus, allActiveSkus } = data.data;
      setReadOnly(!StepStarted);
      const filteredSKus = filterUniqueSkus(
        [
          ...(PS ? [{ ...PS, plant: PS.producing_plant }] : []),
          ...(pegasus
            ? (pegasus?.combinations || []).map((sku) => {
                return { ...sku, plant: sku.producing_plant };
              })
            : []),
          ...(allActiveSkus[0] || []),
        ] || [],
      );

      const skus = filterCommonSkus(recommended[0] || [], filteredSKus);
      setReferenceSkus(skus);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleRowSelection = (data, plantId, sku_id) => {
    let currentValues = { ...selectedSkus };
    currentValues = { ...data };

    setSkusPayload({
      ...selectedSkusPayload,
      [plantId]: {
        ...selectedSkusPayload[plantId],
        sku_id: sku_id,
        production_plant_id: plantId,
        selectedData: currentValues,
      },
    });

    setSelectSkus(currentValues);
  };

  const isDisabled = () => {
    return (
      isCancelled ||
      loading ||
      submitLoading ||
      Object.keys(validate() || {}).length > 0 ||
      selectedSkusPayload?.allSelected ||
      readOnly
    );
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        <Button
          className='next'
          onClick={handleSubmit}
          variant='gradient'
          radius='md'
          size='md'
          fullWidth
          disabled={isDisabled()}
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
        >
          Submit
        </Button>
      </Col>
    </Row>
  );

  if (loading || infoLoading)
    return (
      <ContentPaper footer={<Footer />}>
        <ProjectOverview />
        <Space h='sm' />
        <AppLoader center size='md' />
      </ContentPaper>
    );

  return (
    <ContentPaper footer={<Footer />}>
      <ProjectOverview />
      <Space h='sm' />

      <div>
        <Tabs
          keepMounted={false}
          value={activePlant}
          onTabChange={(value) => setActivePlant(value)}
          color='yellow'
        >
          <Tabs.List>
            {producing_plant_data.map(({ producing_plant, id }, index) => (
              <Tabs.Tab
                value={id}
                className='menu-tabs'
                color={errors[id] ? 'red' : ''}
                key={index}
              >
                <span className={`${errors[id] ? 'red' : ''}`}>{producing_plant}</span>
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {producing_plant_data.map(({ producing_plant, id, producing_country }, index) => {
            const sku = sku_data[0] || null;
            if (!sku) return null;

            const recommendedSkus = (referenceSkus.recommended || []).map((item) => ({
              ...item,
              unique: `${item.sku}_${item.plant}`,
              type: '',
            }));

            const referenceSkusList = (referenceSkus.reference || []).map((item) => ({
              ...item,
              unique: `${item.sku}_${item.plant}`,
              type: 'RFS',
            }));

            let skus = [...recommendedSkus, ...referenceSkusList];

            skus = skus.map((item) => {
              const isRecommened =
                item.plant === producing_plant &&
                String(item.brand).toLowerCase().trim() ===
                  String(sku.brand).toLowerCase().trim() &&
                String(item.container).toLowerCase().trim() ===
                  String(sku.container).toLowerCase().trim();

              return {
                ...item,
                unique: `${item.sku}_${item.plant}`,
                type: isRecommened
                  ? item.type === 'RFS'
                    ? 'Recommended (RFS)'
                    : 'Recommended'
                  : '',
              };
            });

            return (
              <Tabs.Panel value={id} key={index}>
                <Grid>
                  <Grid.Col span={12}>
                    <Table
                      {...sku}
                      skuId={sku.id}
                      plantId={id}
                      skus={skus}
                      setBomsModal={setBomsModal}
                      handleRowSelection={handleRowSelection}
                      selectedSkus={selectedSkus}
                      selectedSkusDetails={selectedSkusPayload[id] || {}}
                      allSelected={selectedSkusPayload?.allSelected}
                      readOnly={
                        readOnly || !allowedCountries.includes(producing_country) || isCancelled
                      }
                    />
                  </Grid.Col>
                </Grid>
              </Tabs.Panel>
            );
          })}
        </Tabs>
      </div>

      {bomModal.open && (
        <ViewBom
          bomModal={bomModal}
          handleClose={() => setBomsModal({ open: false, sku: null, plant: null })}
        />
      )}
    </ContentPaper>
  );
};
