
// keeping this code for future 
// const makeUniqueByComponent = (array) => {
//   const seen = new Set();
//   return array.filter(item => {
//     const isDuplicate = seen.has(item.component);
//     seen.add(item.component);
//     return !isDuplicate;
//   });
// };

export const checkIfFlagged = (requested, reference) => {
  if (!reference){return false}
  return (
    parseFloat(requested?.quantity ?? '') !== parseFloat(reference?.quantity ?? '') ||
    parseFloat(requested?.scrap  ?? '') !== parseFloat(reference?.scrap  ?? '') ||
    parseFloat(requested?.RequestedSkuCostEstimation?.total_value ?? '') !==
     parseFloat(reference?.PrimaryRefSkuCostEstimation?.total_value ?? '')
  );
};

const getVariance = (main = 0, ref = 0) => {
  const variance = parseFloat(main) - parseFloat(ref);
  return (variance < 0 ? -variance : variance).toFixed(3);
};

const getFinalValue = (main, ref, checkVar) => {
  const parseMain = main ?? 'N/A';
  const parsedRef = ref ?? 'N/A';
  
  if (checkVar) {
    const mainCost = parseMain !== 'N/A' ? parseFloat(main).toFixed(3) : 'N/A';
    const refCost = parsedRef !== 'N/A' ? parseFloat(ref).toFixed(3) : 'N/A';
    const variance = mainCost !== 'N/A' && refCost !== 'N/A' ? getVariance(main, ref) : 'N/A';
    return [`Main: ${mainCost}`, `Ref: ${refCost}`, `Var: ${variance}`].join(',');
  } else {
    const mainCost = parseMain !== 'N/A' ? main : 'N/A';
    const refCost = parsedRef !== 'N/A' ? ref : 'N/A';
    return [`Main: ${mainCost}`, `Ref: ${refCost}`].join(',');
  }
};
const displayValue = (main) => {
  if (main === true) return 'Yes';
  if (main === false) return 'No';
  return 'N/A';
};
const costingRelHandler = (main, ref) => {
  const mainCost = displayValue(main);
  const refCost = displayValue(ref);
  return [`Main: ${mainCost}`, `Ref: ${refCost}`].join(',');
}

export const formatSkus = (uniqueRequestedSkus, uniqueReferenceSkus) => {
  let referenceSkusCopy = [...uniqueReferenceSkus];
  const updatedData = uniqueRequestedSkus.map((ele, index) => {
    const referenceIndex = referenceSkusCopy.findIndex(ref => ref.component === ele.component);
    const matchingReference = referenceIndex > -1 ? referenceSkusCopy[referenceIndex] : null;
    if (referenceIndex > -1) {
      referenceSkusCopy.splice(referenceIndex, 1);
    }
    return {
      ...ele,
      indexNo: index,
      componentNumber: String(parseInt(ele.component)),
      isReference: false,
      flagged: checkIfFlagged(ele, matchingReference),
      hasReference: !!matchingReference,
      reference: matchingReference,
      isChanged: false,
      qty_excel: getFinalValue(ele?.quantity, matchingReference?.quantity, true),
      scrap_excel: getFinalValue(ele?.scrap, matchingReference?.scrap, true),
      costing_relevancy_excel: costingRelHandler(ele?.costing_relevancy, matchingReference?.costing_relevancy),
      total_value_excel: getFinalValue(ele?.RequestedSkuCostEstimation?.total_value, 
        matchingReference?.PrimaryRefSkuCostEstimation?.total_value, true),
      curr_excel: getFinalValue(ele?.currency, matchingReference?.currency),
      price_excel : getFinalValue(
        ele?.RequestedSkuCostEstimation?.price,
        matchingReference?.PrimaryRefSkuCostEstimation?.price,
        true
      ),
      price_unit_excel: getFinalValue(
        ele?.RequestedSkuCostEstimation?.un,
        matchingReference?.PrimaryRefSkuCostEstimation?.un,
        false
      ),
      price_per_unit_excel: getFinalValue(
        ele?.RequestedSkuCostEstimation?.price_unit,
        matchingReference?.PrimaryRefSkuCostEstimation?.price_unit,
        false
      )
    };
  });

  const remainingReferenceData = referenceSkusCopy.map((ref, index) => ({
    ...ref,
    indexNo: 10000 + index,
    componentNumber: String(parseInt(ref.component)),
    isReference: true,
    hasReference: false,
    isChanged: false,
    flagged:false,
    costing_relevancy_excel: displayValue(ref?.costing_relevancy),
    qty_excel: getFinalValue('', ref?.quantity, false), 
    scrap_excel: getFinalValue('', ref?.scrap, false),
    total_value_excel: getFinalValue('', ref?.PrimaryRefSkuCostEstimation?.total_value, false),
    curr_excel: ref?.currency,
    price_excel : getFinalValue(
      '',
      ref?.PrimaryRefSkuCostEstimation?.price,
      false
    ),
    price_unit_excel: getFinalValue(
      '',
      ref?.PrimaryRefSkuCostEstimation?.un,
      false
    ),
    price_per_unit_excel: getFinalValue(
      '',
      ref?.PrimaryRefSkuCostEstimation?.price_unit,
      false
    )
  }));
  return [...updatedData, ...remainingReferenceData];
};

export const checkZeroQty = (arr) => {
  if (arr.length === 0) return false;
  arr.forEach(element => {
    if (element.quantity === 0){
      return true;
    }
  });
  return false;
}