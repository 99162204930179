import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { RfsContext } from '../../../contexts/RfsContext';
import { Row, Col } from 'react-bootstrap';
import {
  Center,
  Select,
  SimpleGrid,
  Text,
  Button,
  Textarea,
  Accordion,
  Group,
  CloseButton,
} from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment';
import { axios } from '../../../utils/axios';
import ContentPaper from '../../../components/layouts/rfs/Content';
// import ImpFeasTable from '../../../components/tables/impFeasibiltyTable/impFeasibiltyTable';
import { AppLoader } from '../../../components/common/loader';
import ImpFeasiblityModal from '../../impFeasibility/modal';
import SubmitProjectModal from '../../../components/modal/SubmitProjectModal';
import { DelistingContext, DelistingProvider } from '../../../contexts/DelistingContext';

import '../../impFeasibility/impFeasibility.css';
import { object } from 'yup';
import { rfsStatus } from '../../../constants';
import ProjectName from '../../../components/projectName/projectName';
import { Tabs } from '@mantine/core';
import PackMatTable from './PackMatTable';
import MarketsVolumeTable from './MarketsVolumeTable';
// import { ImpFeasTable } from './MarketsVolumeTable';
import { ImpFeasTable } from '../../productionSite/ImpFeasibilityProdSite/ImpFeasibilityTable';

const dropzoneChildren = (status) => (
  <Group position='center' spacing='xl' style={{ minHeight: 220, pointerEvents: 'none' }}>
    <div>
      <Text size='xl' inline>
        Drag images here or click to select files
      </Text>
      <Text size='sm' color='dimmed' inline mt={7}>
        Attach as many files as you like, each file should not exceed 5mb
      </Text>
    </div>
  </Group>
);

export default function ImpFeasiblityDLST(props) {
  const openRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const { form, downloadFile, downloadLocalFile, updateRfsStatus, info } = useContext(RfsContext);
  const {
    project_name,
    first_shipment,
    trials_needed,
    submission_date,
    fast_track,
    status,
    rfs_type,
  } = form.rfsInformation;
  const [firstShipmentPmo, setFirstShipmentPmo] = useState(
    moment(new Date()).add(11, 'week').toDate(),
  );
  const [fastTrackPmo, setFastTrackPmo] = useState(fast_track ? 'Yes' : 'No');
  const [attachment, setAttachment] = useState({});
  const [impFeasibility, setImpFeasibility] = useState({});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState({});
  const [validate, setValidate] = useState(false);
  const [validateFeilds, setValidateFeilds] = useState(false);
  const [submitProjectModal, setSubmitProjectModal] = useState(false);
  const [allData, setAllData] = useState([]);
  const [attachmentCheck, setAttachmentCheck] = useState({ status: false });
  const [impLoading, setImpLoading] = useState(false);
  const [accordionState, setAccordionState] = useState({});
  const [error, setError] = useState({ state: false, message: null });

  const accessors = {
    materials: 'selection_number',
    liquids: 'selection_number',
    skus: 'selection_number',
  };

  const editable = () => {
    return status === rfsStatus.imp_feasible;
  };

  const handleFileSelect = (files) => {
    const currentValues = { ...attachment };
    currentValues['files'] = files;
    setAttachment(currentValues);
  };

  const fetchImpFeasibility = (rfsNumber) => {
    setImpLoading(true);
    axios
      .get(`/implementation/${rfsNumber}`)
      .then(
        (res) => {
          const response = res.data.fetchedData[0];
          let saveResponse = response === undefined ? {} : response;
          setFetched(saveResponse);
          if (Object.keys(saveResponse).length > 0 && rfs_type === 'Open SKU to market') {
            setFirstShipmentPmo(
              moment(first_shipment).isValid() ? moment(first_shipment).toDate() : firstShipmentPmo,
            );
          }
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setImpLoading(false));
  };

  const createImpFeasibility = (payload, callback) => {
    let formData = new FormData();
    if (payload.attachment.files !== undefined) {
      if (payload.attachment.files.length > 0) {
        for (let k in payload.attachment.files) {
          if (false) {
          } else {
            formData.append('attachments', payload.attachment.files[k]);
            formData.append('bus_unit', payload.prod_bus_unit);
            formData.append('rfs', payload.rfs_number);
            formData.append('status', payload.tf_status);
          }
        }
      } else {
        formData.append('attachmentId[]', []);
        formData.append('bus_unit', payload.prod_bus_unit);
        formData.append('rfs', payload.rfs_number);
        formData.append('status', payload.tf_status);
        formData.append('first_shipment', payload.first_shipment);
        formData.append('fast_track', payload.fast_track);
      }

      axios
        .put(`/implementation`, formData, {
          prod_bus: payload.prod_bus_unit,
          rfs: payload.rfs_number,
          tf: payload.tf_status,
          first_shipment: payload.first_shipment,
          fast_track: payload.fast_track,
        })
        .then(
          (res) => {
            callback();
          },
          (err) => {
            console.log(err);
          },
        );
    } else {
      formData.append('attachments', []);
      formData.append('bus_unit', payload.prod_bus_unit);
      formData.append('rfs', payload.rfs_number);
      formData.append('status', payload.tf_status);
      formData.append('first_shipment', payload.first_shipment);
      formData.append('fast_track', payload.fast_track);
      axios
        .put(`/implementation`, formData, {
          prod_bus: payload.prod_bus_unit,
          rfs: payload.rfs_number,
          tf: payload.tf_status,
          first_shipment: payload.first_shipment,
          fast_track: payload.fast_track,
        })
        .then(
          (res) => {
            callback();
          },
          (err) => {
            console.log(err);
          },
        );
    }
  };

  const updateImpFeasibility = (payload, callback) => {
    axios.put(`/implementation/${params.rfsNumber}`, payload).then(
      (res) => {
        callback();
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const fetchImpFeasibilityTable = (rfsNumber) => {
    setLoading(true);
    axios
      .get(`/implementation/fetch/${rfsNumber}`)
      .then(
        (res) => {
          let response = res.data.data;
          let materials = [];

          response = response.map((sku) => {
            const index = materials.findIndex(
              (material) => material.mat === sku.material_code && material.plant === sku.plant,
            );
            const mappedMaterial = {
              skus: sku.skus,
              desc: sku.sku_description,
              mat: sku.material_code,
              plant: sku.plant,
              matdesc: sku.material_description,
              markets_concat: sku.MarketsVolumeUnit,
            };
            if (index === -1) materials.push(mappedMaterial);

            return mappedMaterial;
          });

          setImpFeasibility(materials);
          setAllData(response);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const isDisabled = () => {
    const VEAM = form.rfsInformation.rfs_type === 'Article Modification/Value Engineering';

    return (VEAM && !fetched.prod_bus_unit) || (fast_track && !fastTrackPmo);
  };

  const handleNext = (type) => {
    setValidate(true);
    if (isDisabled()) {
      setSubmitProjectModal(true);
      setValidateFeilds(true);
    } else {
      if (fetched.id) {
        updateRfsStatus(params.rfsNumber, type);
        updateImpFeasibility(
          {
            prod_bus_unit: fetched.prod_bus_unit,
            tf_status: type,
            rfs_number: params.rfsNumber,
          },
          //Reload after success - Ank1008 - start
          () => window.location.reload(),
          //Reload after success - Ank1008 - end
        );
      } else {
        updateRfsStatus(params.rfsNumber, type);
        createImpFeasibility(
          {
            prod_bus_unit: fetched.prod_bus_unit,
            attachment: attachment,
            tf_status: type,
            rfs_number: params.rfsNumber,
            first_shipment: moment(firstShipmentPmo).format('YYYY-MM-DD'),
            fast_track: fastTrackPmo,
          },
          //Reload after success - Ank1008 - start
          () => window.location.reload(),
          //Reload after success - Ank1008 - end
        );
      }
    }
  };

  useEffect(() => {
    info(params.rfsNumber);
  }, []);

  useEffect(() => {
    if (rfs_type.length > 0) {
      setFastTrackPmo(fast_track ? 'Yes' : 'No');

      fetchImpFeasibility(params.rfsNumber);
      fetchImpFeasibilityTable(params.rfsNumber);
      attachments(params.rfsNumber);
    }
  }, [rfs_type.length]);

  const FileList = ({ type, files = [] }) => {
    let { rfsNumber } = props;
    rfsNumber = params.rfsNumber?.replace(/[^a-zA-Z0-9]/g, '');

    return (files || []).length > 0 ? (
      <ul style={{ fontSize: '0.8rem' }}>
        {files.map((file, index) => (
          // <li
          //   style={{
          //     color: '#e5b611',
          //     cursor: 'pointer',
          //   }}
          //   key={index}
          //   onClick={() => downloadFile(file.name, rfsNumber?.toLowerCase())}
          // >
          //   {file.name}
          // </li>

          <li
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {attachmentCheck ? (
              <span
                style={{
                  color: '#e5b611',
                  cursor: 'pointer',
                }}
                onClick={() => downloadFile(file.name, rfsNumber.toLowerCase())}
              >
                {file.name}{' '}
              </span>
            ) : (
              <span
                style={{
                  color: '#e5b611',
                  cursor: 'pointer',
                }}
                onClick={() => downloadLocalFile(URL.createObjectURL(file), file.name)}
              >
                {file.name}
              </span>
            )}
            {/* {!props.withEdit && (
              <CloseButton
                style={{
                  marginTop: '2px',
                }}
                aria-label='Close modal'
                color='red'
                onClick={() => handleDelete(index, type)}
              />
            )} */}
          </li>
        ))}
      </ul>
    ) : (
      ''
    );
  };

  const attachments = (values) => {
    axios.post(`/implementation/attachment`, { rfs_number: values }).then(
      (res) => {
        const response = res.data;
        setAttachmentCheck(response);
      },
      (error) => {
        console.log(error);
      },
    );
  };
  const handleSelect = (value) => {
    const currentValues = { ...fetched };
    currentValues['prod_bus_unit'] = value;
    setFetched(currentValues);
  };

  const getMaterials = () => {
    let data = [];
    impFeasibility.map((item) => {
      const index = data.findIndex((val) => val.mat === item.mat);
      if (index === -1) data.push(item);
    });

    return data;
  };

  const Footer = () => (
    <Row>
      <Col md={{ span: 2, offset: 8 }}>
        <Button
          color='red'
          size='sm'
          radius='xs'
          fullWidth
          onClick={() => handleNext(props.trials === true ? 'Impl Feas Rejected' : 'rejected')}
        >
          {/* onClick={() => handleNext('rejected')}> */}
          Reject Project
        </Button>
      </Col>
      <Col md={{ span: 2 }}>
        <Button
          color='green'
          size='sm'
          radius='xs'
          fullWidth
          onClick={() => handleNext('Implementation')}
        >
          {/* onClick={() => handleNext('approved')}> */}
          Approve Project
        </Button>
      </Col>
    </Row>
  );

  if (loading || rfs_type.length === 0 || impLoading)
    return (
      <ContentPaper>
        <AppLoader center size='lg' />
      </ContentPaper>
    );

  return (
    <ContentPaper page='imp-feasibility' footer={editable() ? <Footer /> : null}>
      <ProjectName initialItem={0} />
      <Dropzone
        multiple={false}
        className='dropzone-comp'
        openRef={openRef}
        onDrop={(files) => handleFileSelect(files)}
        onReject={(files) => setError({ state: true, message: files[0].errors[0].message })}
        maxSize={5 * 1024 ** 2}
      >
        {(status) => dropzoneChildren(status)}
      </Dropzone>
      {error.state && <div className='red-custom-mantine-Textarea-error'>{error.message}</div>}
      <Row>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col
          md={{ span: 6 }}
          style={{
            marginTop: '1rem',
          }}
        >
          <h5 className='sectionHeadings'>Implementation Feasibility</h5>
        </Col>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600}>
              Project Name:
            </Text>
            <Text size='sm' weight={400}>
              {project_name}
            </Text>
          </SimpleGrid>
        </Col>

        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600}>
              Project Submission Date:
            </Text>
            <Text size='sm' weight={400}>
              {submission_date}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>
      {/* <Row style={{ marginTop: '1rem' }}>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600}>
              First Shipment Date:
            </Text>
            <Text size='sm' weight={400}>
              {first_shipment}
            </Text>
          </SimpleGrid>
        </Col>

        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            {isDisabled() &&
            rfs_type === 'Article Modification/Value Engineering' ? (
              <Text size='sm' weight={600} color='red'>
                Production Business Unit*:
              </Text>
            ) : (
              <Text size='sm' weight={600}>
                Production Business Unit:
              </Text>
            )}
            <Select
              disabled={!editable() || rfs_type === 'Open SKU to market'}
              required
              // error={validate && !fetched.prod_bus_unit}
              className='impfeasibility-input'
              searchable
              clearable={editable()}
              nothingFound='No such Production Business Unit'
              radius='md'
              onChange={(value) => handleSelect(value)}
              data={[
                'BE',
                'NL',
                'FR',
                'LU',
                'DE',
                'UK',
                'IT',
                'ES',
                'RUK',
                'Expansion South',
                'Expansion North',
                'EXTREME',
              ]}
              name='prodBusinessUnit'
              value={fetched?.prod_bus_unit}
            />
          </SimpleGrid>
        </Col>
      </Row> */}
      {/* {trials_needed ? null : (
        <Row style={{ marginTop: '1rem' }}>
          <Col
            md={1}
            style={{
              width: '2.0833%',
              flex: '0 0 2.0833%',
              maxWidth: ' 2.0833%',
            }}></Col>
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2}>
              <Text size='sm' weight={600}>
                Fast Track Approval:
              </Text>
              <Select
                disabled={!editable()}
                required
                className='impfeasibility-input'
                clearable
                radius='md'
                data={[
                  { value: 'approved', label: 'Approve' },
                  { value: 'rejected', label: 'Reject' },
                ]}
                name='ft_approval'
                value={ft_approval}
              />
            </SimpleGrid>
          </Col>
          <Col
            md={1}
            style={{
              width: '2.0833%',
              flex: '0 0 2.0833%',
              maxWidth: ' 2.0833%',
            }}></Col>
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2}>
              <Text size='sm' weight={600}>
                RFS Approval:
              </Text>
              <Select
                disabled={!editable()}
                required
                className='impfeasibility-input'
                clearable
                radius='md'
                data={[
                  { value: 'approved', label: 'Approve' },
                  { value: 'rejected', label: 'Reject' },
                ]}
                name='rfs_approval'
                value={rfs_approval}
              />
            </SimpleGrid>
          </Col>
        </Row>
      )} */}
      <Row style={{ padding: '1rem' }}>
        <Accordion
          style={props.withEdit ? { maxWidth: '100vw' } : { maxWidth: '100vw', minHeight: '100vh' }}
          onChange={(event) => setAccordionState(event)}
        >
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              const accordionKey = `accordion_${index}`;

              return (
                <Accordion.Item key={accordionKey} value={accordionKey}>
                  <Accordion.Control>{`${name.substring(0, 3).toUpperCase()} - ${
                    item['selection_number']
                  } - ${item['selection_name']}`}</Accordion.Control>
                  <Accordion.Panel>
                    <div
                      style={{
                        display: 'flex',
                        alignContent: 'flex-start',
                        flexDirection: 'column',
                      }}
                    >
                      <PackMatTable scopeId={item['id']} />
                      <ImpFeasTable name={item[accessors[name]]} scope={item} isSubmissionReview />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              );
            }),
          )}
        </Accordion>
      </Row>
      {(Object.keys(impFeasibility).length > 0 ||
        rfs_type === 'Open SKU to market' ||
        rfs_type === 'Production Site') && (
        <>
          <Row>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              <SimpleGrid cols={2} style={{ marginTop: '0.5rem' }}>
                <Text size='sm' weight={600}>
                  Obsoletes Calculation:
                </Text>
                <Button
                  color='dark'
                  onClick={() => setModal(true)}
                  disabled={rfs_type === 'Open SKU to market' || rfs_type === 'Production Site'}
                >
                  Obsoletes Calculation
                </Button>
              </SimpleGrid>
            </Col>

            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              <SimpleGrid cols={2} style={{ marginTop: '0.5rem' }}>
                <Text size='sm' weight={600}>
                  Obsoletes Attachment:
                </Text>
                {attachmentCheck.status ? (
                  <FileList files={attachmentCheck.files} type={'Implementation'} />
                ) : attachment.files == undefined ? (
                  <Button
                    color='dark'
                    onClick={() => openRef.current()}
                    disabled={!editable() || rfs_type === 'Open SKU to market'}
                  >
                    Obsoletes Attachment
                  </Button>
                ) : (
                  <FileList files={attachment.files} type={'Implementation'} />
                )}
              </SimpleGrid>
            </Col>
          </Row>

          {(fast_track && trials_needed == 'No') ||
          (rfs_type === 'Open SKU to market' && fast_track) ? (
            <Row style={{ marginTop: '1rem' }}>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2}>
                  {isDisabled() ? (
                    <Text size='sm' weight={600} color='red'>
                      Fast Track:
                    </Text>
                  ) : (
                    <Text size='sm' weight={600}>
                      Fast Track:
                    </Text>
                  )}
                  <Select
                    disabled={!editable() || (rfs_type === 'Open SKU to market' && !fast_track)}
                    error={isDisabled()}
                    required
                    radius='md'
                    data={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                    name='fastTrackPmo'
                    value={fastTrackPmo}
                    onChange={(value) => setFastTrackPmo(value)}
                  />
                </SimpleGrid>
              </Col>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
              {fastTrackPmo === 'Yes' ? null : (
                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2}>
                    <Text size='sm' weight={600}>
                      First Shipment Date:
                    </Text>
                    <DatePickerInput
                      disabled={!editable() || (rfs_type === 'Open SKU to market' && !fast_track)}
                      name='firstShipmentPmo'
                      value={firstShipmentPmo || new Date()}
                      onChange={(e) => {
                        setFirstShipmentPmo(e);
                      }}
                      placeholder='Pick date'
                      minDate={moment(new Date()).add(11, 'days').toDate()}
                    />
                  </SimpleGrid>
                </Col>
              )}
            </Row>
          ) : null}
        </>
      )}

      <Row style={{ marginTop: '0.5rem' }}>
        {Object.keys(impFeasibility)?.map((k) => (
          <Accordion>
            <Accordion.Item
              className='imp-feas-accordion'
              label={`${impFeasibility[k].mat} - ${impFeasibility[k].matdesc} - ${impFeasibility[k].plant}`}
            >
              <ImpFeasTable
                data={allData.filter(
                  (item) =>
                    item.mat === impFeasibility[k].mat && item.plant === impFeasibility[k].plant,
                )}
              />
            </Accordion.Item>
          </Accordion>
        ))}
      </Row>
      {modal && (
        <ImpFeasiblityModal
          opened={modal}
          setOpened={setModal}
          fsd={first_shipment}
          material={impFeasibility[0]?.mat}
          materials={getMaterials()}
        />
      )}
      {submitProjectModal && (
        <SubmitProjectModal opened={submitProjectModal} setOpened={setSubmitProjectModal} />
      )}
    </ContentPaper>
  );
}
