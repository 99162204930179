import React from 'react';
import { CSVLink } from 'react-csv';
import { TableExport } from 'tabler-icons-react';

const ExportButton = ({ data }) => {
  return (
    <CSVLink
      filename='stm-data'
      style={{
        border: '1px solid transparent',
        backgroundColor: 'black',
        color: '#fff',
        height: '36px',
        padding: '0 18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        borderRadius: '4px',
        fontWeight: 600,
        lineHeight: 1,
        fontSize: '14px',
        cursor: 'pointer',
        marginRight: '12px',
        textDecoration: 'none',
      }}
      data={data}
    >
      <TableExport style={{ marginRight: '10px' }} />
      Export to CSV
    </CSVLink>
  );
};

export default ExportButton;
