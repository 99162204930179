import { BrowserRouter as Router } from 'react-router-dom';
import LoggedInRoutes from './routes/loggedIn-routes';
import LoggedOutRoutes from './routes/loggedOut-routes';
import './App.css';
import { useContext } from 'react';
import { AuthContext } from './contexts/AuthContext';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { AppLoader } from './components/common/loader';

const Clarity = ({ userRole }) => {
  return (
    <>
      <Helmet>
        <meta name='referrer' content='no-referrer' />
        <script type='text/javascript'>
          {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_KEY}");
    window.clarity('set', 'userRole', '${userRole}')`}
        </script>
      </Helmet>
    </>
  );
};

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

function App() {
  const { loggedIn, userRole, loading, user, tokenLoader, userName, crInfoLoader } =
    useContext(AuthContext);

  if (
    (!user ||
      tokenLoader ||
      crInfoLoader ||
      !userName ||
      loading ||
      ((!userRole || userRole.length === 0) && user?.rfs_registered)) &&
    loggedIn
  ) {
    return (
      <LoadingContainer>
        <AppLoader size='lg' center />
      </LoadingContainer>
    );
  }

  return (
    <div className={`App `} style={{ overflow: 'hidden' }}>
      <Clarity userRole={userRole} />
      <Router>{loggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />}</Router>
    </div>
  );
}

export default App;
