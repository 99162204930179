import {
  scopeRestatment,
  technicalSpecificationNumberChange,
} from '../../../constants/product-development';

export const trialMaterialCodeStatus = (data) => {
  let status;
  if (data.material_number) {
    status = 'closed';
  } else {
    status = 'inprogress';
  }

  return status;
};

export const trialMaterialDescriptionStatus = (data) => {
  let status;
  if (data.PackMatDesc ? data.PackMatDesc : data.PlantDataDesc) {
    status = 'closed';
  } else {
    if (data.material_number || data.liquid_number) {
      status = 'inprogress';
    } else {
      status = 'open';
    }
  }

  return status;
};

export const materialSupplierNumberNameStatus = (data) => {
  let status;

  if (data.sup_num && data.sup_name) {
    status = 'closed';
  } else {
    if (
      trialMaterialCodeStatus(data) === 'closed' &&
      trialMaterialDescriptionStatus(data) === 'closed'
    ) {
      status = 'inprogress';
    } else {
      status = 'open';
    }
  }

  return status;
};

export const artworkDevelopmentStatus = (data) => {
  let status;
  let condition =
    trialMaterialCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    materialSupplierNumberNameStatus(data) === 'closed';

  if (data.good_for_print && condition) {
    status = 'closed';
  } else if (
    data.material_type === 'Existing Material' ||
    ['96', '99'].includes(data.packMatsPlantStatus)
  ) {
    status = 'notapplicable';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const materialChangeoverStatus = (data, changeoverStatus) => {
  let status;
  const condition =
    trialMaterialCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    materialSupplierNumberNameStatus(data) === 'closed';

  if (changeoverStatus && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const eanApprovalStatus = (data, allMaterials) => {
  let status;
  const condition =
    trialMaterialCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    materialSupplierNumberNameStatus(data) === 'closed' &&
    ['notapplicable', 'closed'].includes(artworkDevelopmentStatus(data)) &&
    materialChangeoverStatus(data, allMaterials) === 'closed';

  if (['96', '99'].includes(data.packMatsPlantStatus) && data.good_for_print && condition) {
    status = 'closed';
  } else if (
    data.material_type === 'Existing Material' ||
    ['96', '99'].includes(data.packMatsPlantStatus)
  ) {
    status = 'notapplicable';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const materialContractStatus = (data, allMaterials) => {
  let status;
  let condition =
    trialMaterialCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    materialSupplierNumberNameStatus(data) === 'closed' &&
    ['notapplicable', 'closed'].includes(artworkDevelopmentStatus(data)) &&
    ['notapplicable', 'closed'].includes(eanApprovalStatus(data));

  if (allMaterials)
    condition = condition && materialChangeoverStatus(data, allMaterials) === 'closed';

  if (data.material_contract && data.material_contract > 0 && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const materialTrialStatus = (data) => {
  let status;
  const condition =
    trialMaterialCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    materialSupplierNumberNameStatus(data) === 'closed' &&
    ['notapplicable', 'closed'].includes(artworkDevelopmentStatus(data)) &&
    ['notapplicable', 'closed'].includes(eanApprovalStatus(data)) &&
    materialContractStatus(data) === 'closed';

  if (data.trial_status && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const materialTrialsFailedStatus = (data) => {
  let status;
  const condition =
    trialMaterialCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    materialSupplierNumberNameStatus(data) === 'closed' &&
    ['notapplicable', 'closed'].includes(artworkDevelopmentStatus(data)) &&
    ['notapplicable', 'closed'].includes(eanApprovalStatus(data)) &&
    materialContractStatus(data) === 'closed' &&
    materialTrialStatus(data) === 'closed';

  if (data.trial_status !== 'Fail') {
    status = 'notapplicable';
  } else if (data.trial_fail && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const materialFailureReasonStatus = (data) => {
  let status;
  const condition =
    trialMaterialCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    materialSupplierNumberNameStatus(data) === 'closed' &&
    ['notapplicable', 'closed'].includes(artworkDevelopmentStatus(data)) &&
    ['notapplicable', 'closed'].includes(eanApprovalStatus(data)) &&
    materialContractStatus(data) === 'closed' &&
    materialTrialStatus(data) === 'closed' &&
    materialTrialsFailedStatus(data) === 'closed';

  if (data.trial_status !== 'Fail') {
    status = 'notapplicable';
  } else if (data.fail_reason && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const materialTechSpecNumberStatus = (data) => {
  let status;
  let condition =
    trialMaterialCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    materialSupplierNumberNameStatus(data) === 'closed' &&
    ['notapplicable', 'closed'].includes(artworkDevelopmentStatus(data)) &&
    ['notapplicable', 'closed'].includes(eanApprovalStatus(data)) &&
    materialContractStatus(data) === 'closed' &&
    materialTrialStatus(data) === 'closed' &&
    materialTrialsFailedStatus(data) === 'closed';

  if (data.trial_status !== 'Fail' || data.trial_fail !== technicalSpecificationNumberChange) {
    status = 'notapplicable';
  } else if (data.tech_spec_matrix && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const materialRemovalOfChangeoverStatus = (data) => {
  let status;
  const condition =
    trialMaterialCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    materialSupplierNumberNameStatus(data) === 'closed' &&
    ['notapplicable', 'closed'].includes(artworkDevelopmentStatus(data)) &&
    ['notapplicable', 'closed'].includes(eanApprovalStatus(data)) &&
    materialContractStatus(data) === 'closed' &&
    materialTrialStatus(data) === 'closed' &&
    materialTrialsFailedStatus(data) === 'closed';
  // &&
  // materialFailureReasonStatus(data) === 'closed' &&
  // ['notapplicable', 'closed'].includes(materialTechSpecNumberStatus(data))

  if (data.trial_status !== 'Fail') {
    status = 'notapplicable';
  } else if (data.changeoverAppliedStatusRemoval > 0 && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const materialMaterialStatusChangeStatus = (data) => {
  let status;
  const condition =
    trialMaterialCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    materialSupplierNumberNameStatus(data) === 'closed' &&
    ['notapplicable', 'closed'].includes(artworkDevelopmentStatus(data)) &&
    ['notapplicable', 'closed'].includes(eanApprovalStatus(data)) &&
    materialContractStatus(data) === 'closed' &&
    materialTrialStatus(data) === 'closed' &&
    materialTrialsFailedStatus(data) === 'closed';
  // &&
  // materialFailureReasonStatus(data) === 'closed' &&
  // ['notapplicable', 'closed'].includes(materialTechSpecNumberStatus(data))

  if (data.trial_status !== 'Fail') {
    status = 'notapplicable';
  } else if (data.packMatsPlantStatus === '42' && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const trialLiquidCodeStatus = (data) => {
  let status;
  if (data.new_liquid_code || data.liquid_number) {
    status = 'closed';
  } else {
    status = 'inprogress';
  }

  return status;
};

export const liquidSupplierNumberNameStatus = (data) => {
  let status;

  if (data.sup_number && data.sup_name) {
    status = 'closed';
  } else {
    if (
      trialLiquidCodeStatus(data) === 'closed' &&
      trialMaterialDescriptionStatus(data) === 'closed'
    ) {
      status = 'inprogress';
    } else {
      status = 'open';
    }
  }

  return status;
};

export const liquidChangeoverStatus = (data, changeoverStatus) => {
  let status;
  const condition =
    trialLiquidCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    liquidSupplierNumberNameStatus(data) === 'closed';

  if (changeoverStatus && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const liquidTrialStatus = (data, changeoverCondition) => {
  let status;
  const condition =
    trialLiquidCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    liquidSupplierNumberNameStatus(data) === 'closed' &&
    liquidChangeoverStatus(data, changeoverCondition) === 'closed';

  if (data.trial_status && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const liquidTrialsFailedStatus = (data) => {
  let status;
  const condition =
    trialLiquidCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    liquidSupplierNumberNameStatus(data) === 'closed' &&
    liquidTrialStatus(data) === 'closed';

  if (data.trial_status !== 'Fail') {
    status = 'notapplicable';
  } else if (data.trial_fail && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const liquidFailureReasonStatus = (data) => {
  let status;
  const condition =
    trialLiquidCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    liquidSupplierNumberNameStatus(data) === 'closed' &&
    liquidTrialStatus(data) === 'closed' &&
    liquidTrialsFailedStatus(data) === 'closed';

  if (data.trial_status !== 'Fail') {
    status = 'notapplicable';
  } else if (data.fail_reason && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const liquidTechSpecNumberStatus = (data) => {
  let status;
  const condition =
    trialLiquidCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    liquidSupplierNumberNameStatus(data) === 'closed' &&
    liquidTrialStatus(data) === 'closed' &&
    liquidTrialsFailedStatus(data) === 'closed';
  // &&
  // liquidFailureReasonStatus(data) === 'closed'

  if (data.trial_status !== 'Fail' || data.trial_fail !== technicalSpecificationNumberChange) {
    status = 'notapplicable';
  } else if (data.tech_spec_matrix && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const liquidQuantityChangeStatus = (data) => {
  let status;
  const condition =
    trialLiquidCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    liquidSupplierNumberNameStatus(data) === 'closed' &&
    liquidTrialStatus(data) === 'closed';
  // &&
  // liquidTrialsFailedStatus(data) === 'closed' &&
  // liquidFailureReasonStatus(data) === 'closed' &&
  // ['notapplicable', 'closed'].includes(liquidTechSpecNumberStatus(data))

  if (data.trial_status !== 'Fail' || data.trial_fail !== scopeRestatment) {
    status = 'notapplicable';
  } else if (data.new_quantity && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};

export const liquidRemovalOfChangeoverStatus = (data) => {
  let status;
  const condition =
    trialLiquidCodeStatus(data) === 'closed' &&
    trialMaterialDescriptionStatus(data) === 'closed' &&
    liquidSupplierNumberNameStatus(data) === 'closed' &&
    liquidTrialStatus(data) === 'closed' &&
    liquidTrialsFailedStatus(data) === 'closed';
  // &&
  // liquidFailureReasonStatus(data) === 'closed' &&
  // ['notapplicable', 'closed'].includes(liquidQuantityChangeStatus(data)) &&
  // ['notapplicable', 'closed'].includes(liquidTechSpecNumberStatus(data))

  if (data.trial_status !== 'Fail') {
    status = 'notapplicable';
  } else if (data.changeoverAppliedStatusRemoval > 0 && condition) {
    status = 'closed';
  } else if (condition) {
    status = 'inprogress';
  } else {
    status = 'open';
  }

  return status;
};
