import React, { useContext, useEffect, useState, useMemo } from 'react';
import moment from 'moment';

import { SkuDetailsTable } from '../../components/tables/SKUDetailsTable/SkuDetailsTable';
import { RfsContext } from '../../contexts/RfsContext';
import SkuSelectedMaterialModal from '../../components/modal/skuSelectedMaterialModal';

export const Materials = ({ handleSelect }) => {
  const { form, materials, materialsLoading, toggle, fullscreen, materialsCount } =
    useContext(RfsContext);
  const [showOnlySelected, setOnlyShowSelected] = useState(false);
  const [selectedMaterialModal, setSelectedMaterialModal] = useState(false);
  const [selectedAffectedMaterials, setSelectedAffectedMaterials] = useState([]);

  const { rfs_type = '' } = form.rfsInformation;

  let columns = [
    {
      Header: 'Material code',
      accessor: 'material_code',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
      sticky: 'left',
    },
    {
      Header: 'Material description',
      accessor: 'material_description',
      sticky: 'left',
    },

    {
      Header: 'Material group',
      accessor: 'material_group',
      minWidth: 150,
      width: 150,
      maxWidth: 150,
    },
    {
      Header: 'Material group description',
      accessor: 'material_group_description',
      minWidth: 220,
      width: 220,
      maxWidth: 220,
    },
  ];

  if (rfs_type === 'Supplier Change') {
    columns = columns.concat([
      {
        Header: 'Production Requirement(HL)',
        accessor: 'total_plannedQty',
      },
      {
        Header: 'First Changeover Date',
        accessor: 'first_changeover_date',
      },
      {
        Header: 'Existing Suppliers',
        accessor: 'current_suppliers',
      },
    ]);
  }

  const getExportData = (items) => {
    let data = [];
    data = items.map((item) => item.original);

    return data;
  };

  const handleExport = (rows) => {
    if (form.scope.materials.length > 0) {
      return getExportData(form.scope.materials);
    } else {
      return materials;
    }
  };

  const handleRowsSelected = (data, type, openAffectedSkus, selectedIds) => {
    // if (
    //   data.length > 0 &&
    //   form.scope.materials.length < data.length &&
    //   openAffectedSkus
    // ) {
    //   setSelectedAffectedMaterials(openAffectedSkus);
    //   setSelectedMaterialModal(true);
    // }
    handleSelect(data, type, null, selectedIds);
  };

  const closeAffectedSkuModal = () => {
    // const value = { ...form };
    // value.selectedMaterials['10998_BE23'] = false;
    // setForm(value);

    setSelectedAffectedMaterials([]);
    setSelectedMaterialModal(false);
  };

  const handleToggle = (data) => {
    setOnlyShowSelected(data);
  };

  const filterSelectedRows = useMemo(() => {
    let data = [...materials];
    data = data.filter((res) => {
      // const code = `${res.material_code}_${res.plant}`;
      if (form.selectedMaterials[res.material_code]) return res;
    });
    return data;
  }, [Object.keys(form.selectedMaterials).length, showOnlySelected]);

  return (
    <div className='table-container'>
      <SkuDetailsTable
        columns={columns}
        data={showOnlySelected ? filterSelectedRows : materials}
        handleSelect={handleRowsSelected}
        type={'materials'}
        handleExport={handleExport}
        loading={materialsLoading}
        fullScreenToggle={toggle}
        fullscreen={fullscreen}
        selectedIds={form.selectedMaterials}
        noSelectAll
        handleToggle={handleToggle}
        showOnlySelected={showOnlySelected}
        toggleSelected={true}
        count={materialsCount}
      />{' '}
      {selectedMaterialModal && Object.keys(selectedAffectedMaterials).length > 0 && (
        <SkuSelectedMaterialModal
          selectedMaterialModal={selectedMaterialModal}
          setSelectedMaterialModal={closeAffectedSkuModal}
          material={selectedAffectedMaterials}
        />
      )}
    </div>
  );
};
