import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { CalendarIcon, ExternalLinkIcon, Pencil1Icon } from '@modulz/radix-icons';
import {
  Checkbox,
  TextInput,
  Textarea,
  Button,
  MultiSelect,
  Select,
  Text,
  Grid,
} from '@mantine/core';
import dayjs from 'dayjs';
import { DatePickerInput } from '@mantine/dates';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import ProjectName from '../../../components/projectName/projectName';

import ModalUsers from '../../../components/modal/modal';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../components/forms/rfsInitiationForm/rfsInitiationForm.css';

import FastTrackModal from '../../../components/modal/FastTrackModal';
import CCModal from '../../../components/modal/CCModal';
import { RfsContext } from '../../../contexts/RfsContext';
import { InvalidModal } from '../../../components/modal/invalidModal';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { FileIcon } from '@radix-ui/react-icons';
import { AppLoader } from '../../../components/common/loader';
import { routes } from '../../../routes';
import { roles } from '../../../constants';
import { AuthContext } from '../../../contexts/AuthContext';
import ModalAccf from '../../accf/ModalAccf';

export const minWordsValidation = (text = '', wordsLength = 9) => {
  let processedText = text && text.length > 0 ? text.replace(/\n/g, ' ') : '';
  const textArray = processedText.split(' ');

  return textArray.length <= wordsLength;
};

export default function RfsInitiationFormDLST(props) {
  const navigate = useNavigate();
  const params = useParams();
  const {
    form,
    setForm,
    costCenters = [],
    update,
    loading,
    saveAsDraft,
    downloadFile,
    changeLog,
    infoLoading,
    costCentersObject = {},
  } = useContext(RfsContext);
  const { userRole } = useContext(AuthContext);
  const [invalidModal, setInvalidModal] = useState({
    open: false,
    callback: false,
  });
  const {
    project_name,
    initiator_position,
    initiator_team,
    backupUsers,

    sales_business_unit,

    gatekeeper,
    cost_center,
    project_purpose,
    project_details,
    comments,
    impactedPlants = [],
  } = form.rfsInformation;

  const [ccModal, setCcModal] = useState({
    open: false,
    callback: null,
    query: null,
  });
  const [validate, setValidate] = useState(false);
  const { state } = useLocation();
  const [costCenterSearch, setCostCenterSearch] = useState('');
  const [Gatekeeper, setGatekeeper] = useState(false);
  const [gateName, setGateName] = useState('');
  const [TechLead, setTechLead] = useState(false);
  const [TechName, setTechName] = useState('');
  const [BackUps, setBackUps] = useState(false);
  const [AllowUsers, setAllowUsers] = useState(false);
  const [vis1, setVis1] = useState(true);

  const { users, userEmail } = useContext(AuthContext);
  var Gname = '';
  var Tname = '';
  var Bname = '';
  var Aname = '';

  if (
    !!form.rfsInformation &&
    !!form.rfsInformation.gatekeeper &&
    !!form.rfsInformation.gatekeeper[0] &&
    !!form.rfsInformation.gatekeeper[0].name
  ) {
    Gname = form.rfsInformation.gatekeeper[0].name;
  }
  if (
    !!form.rfsInformation &&
    !!form.rfsInformation.technicalLead &&
    !!form.rfsInformation.technicalLead[0] &&
    !!form.rfsInformation.technicalLead[0].name
  ) {
    Tname = form.rfsInformation.technicalLead[0].name;
  }
  if (
    !!form.rfsInformation &&
    !!form.rfsInformation.backupUsers &&
    !!form.rfsInformation.backupUsers[0] &&
    !!form.rfsInformation.backupUsers[0].name
  ) {
    Bname = form.rfsInformation.backupUsers[0].name;
  }
  if (
    !!form.rfsInformation &&
    !!form.rfsInformation.allowedUsers &&
    !!form.rfsInformation.allowedUsers[0] &&
    !!form.rfsInformation.allowedUsers[0].name
  ) {
    Aname = form.rfsInformation.allowedUsers[0].name;
  }
  useEffect(() => {
    setGateName(Gname);
    if (Gname.length === 0) setGatekeeper(false);
    else setGatekeeper(true);
  }, [Gname]);
  useEffect(() => {
    setTechName(Tname);
    if (Tname.length === 0) setTechLead(false);
    else setTechLead(true);
  }, [Tname]);
  useEffect(() => {
    if (Bname.length === 0) setBackUps(false);
    else setBackUps(true);
  }, [Bname]);
  useEffect(() => {
    if (Aname.length === 0) setAllowUsers(false);
    else setAllowUsers(true);
  }, [Aname]);
  useEffect(() => {
    if (
      form.rfsInformation &&
      form.rfsInformation.rfs_type &&
      form.rfsInformation.rfs_type === 'delisting'
    )
      setVis1(false);
  }, [form.rfsInformation.rfs_type]);

  const handleChange = (event, type = null) => {
    const currentValues = { ...form };

    if (type) {
      currentValues.rfsInformation[type][event.target.name] =
        event.target.type === 'checkbox'
          ? event.target.checked
          : (event.target.value || '').trim().length === 0
          ? event.target.value.trim()
          : event.target.value;
    } else {
      currentValues.rfsInformation[event.target.name] =
        event.target.type === 'checkbox'
          ? event.target.checked
          : (event.target.value || '').trim().length === 0
          ? event.target.value.trim()
          : event.target.value;

      //word limit - ank 1008
      if (event.target.name === 'initiator_position' && event.target.value.length > 100)
        currentValues.rfsInformation[event.target.name] = event.target.value.substring(0, 100);

      if (
        (event.target.name === 'project_purpose' && event.target.value.length > 1000) ||
        (event.target.name === 'project_details' && event.target.value.length > 1000) ||
        (event.target.name === 'comments' && event.target.value.length > 1000)
      )
        currentValues.rfsInformation[event.target.name] = event.target.value.substring(0, 1000);
    }

    setForm(currentValues);
  };

  const validateTextArea = (name) => {
    let response = '';

    if (validate && !form.rfsInformation[name]) {
      response = 'Required Field';
    } else if (validate && minWordsValidation(form.rfsInformation[name])) {
      //response = 'Minimum 10 words required';
      response = 'Please provide more details'; //Response Updated - Ank1008
    }

    return response;
  };

  const handleSelect = (type, value, subType = null) => {
    const currentValues = { ...form };

    if (subType) {
      currentValues.rfsInformation[subType][type] = value;
    } else {
      currentValues.rfsInformation[type] =
        type === 'first_shipment'
          ? moment(value).isValid()
            ? moment(value).format('DD-MM-YYYY')
            : null
          : value;
    }

    setForm(currentValues);
  };

  const handleCostCenterCreate = (query) => {
    handleSelect('cost_center', query);
  };

  const handleUserSelect = (type, users) => {
    const currentValues = { ...form };
    currentValues.rfsInformation[type] = users;
    setForm(currentValues);
  };

  const handleNext = (callback = null) => {
    if (!callback) {
      update(callback);
      return;
    }

    setValidate(true);
    if (isDisabled(form.rfsInformation.rfs_type)) {
      setInvalidModal({
        open: true,
        callback: callback ? callback : () => setInvalidModal(false),
      });
    } else {
      update(callback);
      if (userRole === roles.pmo) changeLog(callback);
    }
  };

  const isDisabled = (type) => {
    return (
      !initiator_position ||
      !initiator_team ||
      backupUsers.length === 0 ||
      sales_business_unit.length === 0 ||
      gatekeeper?.length === 0 ||
      minWordsValidation(project_purpose) ||
      minWordsValidation(project_details)
    );
  };

  const getCostCenter = () => {
    let filteredData = [...costCenters];

    if (costCenterSearch.length === 0) {
      filteredData = filteredData.splice(0, 100);
    } else {
      let data = filteredData.filter(
        (item) =>
          item?.value?.length > 0 &&
          item.label.toLowerCase().startsWith(costCenterSearch.toLowerCase()),
      );

      filteredData = data.splice(0, 100);
    }

    const selectedValueIndex = filteredData.findIndex((data) => data.val === cost_center);

    if (selectedValueIndex === -1 && cost_center) {
      if (costCentersObject[cost_center]) {
        filteredData.push({
          label: costCentersObject[cost_center],
          value: cost_center,
        });
      } else {
        filteredData.push({ label: cost_center, value: cost_center });
      }
    }

    return filteredData;
  };

  const getSpocOptions = () => {
    const options = [];
    users.map((user) => {
      if (userEmail !== user.email)
        options.push({
          label: `${user.name} (${user.email})`,
          value: JSON.stringify(user),
        });
    });

    return options;
  };

  const handleAccfSelect = (type, data) => {
    const currentValues = { ...form };
    const fields = {
      markets: 'impactedMarkets',
      plants: 'impactedPlants',
    };
    if (currentValues.rfsInformation[fields[type]]) currentValues.rfsInformation[fields[type]] = [];

    currentValues.rfsInformation[fields[type]] = data;
    setForm(currentValues);
  };

  const Footer = () =>
    state?.routeFromRfsOverview ? (
      <Row>
        <Col md={{ span: 2, offset: 10 }}>
          <Button
            disabled={loading && !invalidModal.open}
            leftIcon={<FileIcon />}
            color='primary'
            variant='gradient'
            fullWidth
            radius='sm'
            onClick={() =>
              handleNext(() => {
                navigate(-1);
              })
            }
          >
            {loading && !invalidModal.open ? <AppLoader /> : 'Save Edits'}
          </Button>
        </Col>
      </Row>
    ) : (
      <Row>
        {props.disableButtons ? null : (
          <Row className='justify-content-md-end' md={4}>
            <Col md='auto'>
              <Button
                disabled={saveAsDraft}
                className='save'
                color='dark'
                radius='md'
                variant='gradient'
                size='md'
                fullWidth
                style={{
                  marginRight: '1rem',
                  width: '200px',
                }}
                onClick={() => handleNext()}
              >
                {saveAsDraft && !invalidModal.open ? <AppLoader /> : 'Save'}
              </Button>
            </Col>
            <Col md='auto'>
              <Button
                disabled={loading && !invalidModal.open}
                className='back'
                color='primary'
                radius='md'
                variant='gradient'
                size='md'
                fullWidth
                style={{
                  marginRight: '1rem',
                  width: '200px',
                }}
                onClick={() =>
                  handleNext(() => {
                    navigate(routes.submission(params.rfsNumber).scopeSelect);
                  })
                }
              >
                {loading && !invalidModal.open ? <AppLoader /> : 'Next'}
              </Button>
            </Col>
          </Row>
        )}
      </Row>
    );

  if (infoLoading)
    return (
      <ContentPaper page={'rfs-inintiation'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );

  return (
    <ContentPaper page={'rfs-inintiation'} footer={<Footer />}>
      {props.withProjectName ? (
        <Row className='ProjectName'>
          <Col md={{ span: 12 }}>
            <ProjectName Pnumber={project_name} initialItem={props.initialItems} />
          </Col>
        </Row>
      ) : null}

      <Row className='projectDetailsForm'>
        <Container className='ProjectDetails'>
          <form>
            {' '}
            {/*//start of project details form */}
            <Row>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
              <Col style={{ marginTop: '1rem' }}>
                <h5 className='sectionHeadings'>GENERAL DETAILS</h5>
              </Col>
              {props.withEdit ? (
                <Col md={{ span: 2 }} className='editButton'>
                  <Button
                    style={{ marginTop: '1rem' }}
                    color='primary'
                    radius='sm'
                    size='sm'
                    leftIcon={<ExternalLinkIcon />}
                    fullWidth
                    onClick={() =>
                      navigate('/RfsInititationPage', {
                        state: {
                          routeFromRfsOverview: true,
                        },
                      })
                    }
                  >
                    Edit
                  </Button>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <TextInput
                  error={validate && !initiator_position ? 'Required Field' : ''}
                  required
                  className='InputField'
                  radius='md'
                  label='Initiator Position (Character limit 100)'
                  name='initiator_position'
                  value={
                    initiator_position?.length > 100
                      ? initiator_position.substring(0, 100)
                      : initiator_position
                  }
                  disabled={props.disabled}
                  onChange={(event) => handleChange(event)}
                />
                {initiator_position?.length > 99 && (
                  <div className='custom-mantine-Textarea-error'>
                    100 Character limit reached, exceeding character would be truncated on saving.
                  </div>
                )}
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <Select
                  error={validate && !initiator_team ? 'Required Field' : ''}
                  required
                  className='InputField'
                  searchable
                  clearable
                  creatable
                  nothingFound='Nothing found'
                  radius='md'
                  label='Initiator Team'
                  name='initiator_team'
                  value={initiator_team}
                  onChange={(value) => handleSelect('initiator_team', value)}
                  data={[
                    'ZITEC',
                    'Brewery',
                    'Quality',
                    'Marketing/Brand Team',
                    'Sales/Revenue Management',
                    'Demand Planning',
                    'Commercial Planning',
                    'SNP/DP',
                    'Logistics Transp/WHS',
                    'Innovation',
                    'Procurement/MRP',
                    'Acceleration Managers',
                    'Trade Marketing',
                  ]}
                  disabled={props.disabled}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '20px', marginTop: '30px' }}>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 2, offset: 0 }}>
                {validate && gatekeeper?.length === 0 ? (
                  <div>
                    {' '}
                    <p
                      className='fixedLeft'
                      style={{
                        paddingBottom: '0px',
                        marginBottom: '0px',
                      }}
                    >
                      Indicate Gatekeeper <span style={{ color: 'red' }}>*</span>
                    </p>{' '}
                    <span
                      style={{
                        color: '#f03e3e',
                        fontSize: '14px',
                        marginBottom: '1rem',
                      }}
                    >
                      Required Field
                    </span>
                  </div>
                ) : (
                  <div>
                    <p className='fixedLeft' style={{ marginBottom: '0px' }}>
                      Gatekeeper <span style={{ color: 'red' }}>*</span>
                    </p>
                  </div>
                )}
              </Col>
              <Col className='FixedRight' md={{ span: 2, offset: 1 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  {Gatekeeper && (
                    <Text
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '450',
                        color: '#212529',
                        paddingRight: 'unset',
                      }}
                    >
                      {gateName}
                    </Text>
                  )}
                  <ModalUsers
                    style={{ paddingLeft: 'unset' }}
                    title={!Gatekeeper ? 'Indicate Gatekeeper' : <Pencil1Icon />}
                    type={'gatekeeper'}
                    selected={gatekeeper}
                    handleUserSelect={handleUserSelect}
                    variant={'singleSelect'}
                  />
                </div>
              </Col>

              <Col md={{ span: 2, offset: 1 }}>
                {validate && backupUsers.length === 0 ? (
                  <div>
                    {' '}
                    <p className='fixedLeft' style={{ marginBottom: '0px', paddingBottom: '0px' }}>
                      Initiator Backup <span style={{ color: '#f03e3e' }}>*</span>
                    </p>{' '}
                    <span
                      style={{
                        color: '#f03e3e',
                        fontSize: '14px',
                        marginBottom: '1rem',
                      }}
                    >
                      Required Field
                    </span>
                  </div>
                ) : (
                  <div>
                    <p className='fixedLeft' style={{ marginBottom: '0px' }}>
                      Initiator Backup <span style={{ color: 'red' }}>*</span>
                    </p>
                  </div>
                )}
              </Col>

              <Col className='FixedRight' md={{ span: 2, offset: 1 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  {BackUps && (
                    <Text
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '450',
                        color: '#212529',
                        paddingRight: 'unset',
                      }}
                    >
                      Check and Change Backup Users
                    </Text>
                  )}
                  <ModalUsers
                    required
                    title={!BackUps ? 'Indicate Backup User' : <Pencil1Icon />}
                    type={'backupUsers'}
                    selected={backupUsers}
                    handleUserSelect={handleUserSelect}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666s%',
                }}
              ></Col>
              {/* <Col md={{ span: 5, offset: 0 }}></Col> */}

              <Col md={{ span: 5, offset: 0 }}>
                <MultiSelect
                  error={validate && sales_business_unit?.length === 0 ? 'Required Field' : ''}
                  required
                  disabled={props.disabled}
                  className='InputField'
                  searchable
                  clearable
                  nothingFound='Nothing found'
                  radius='md'
                  label='Sales Business Unit'
                  data={[
                    'BE',
                    'NL',
                    'FR',
                    'LU',
                    'DE',
                    'UK',
                    'IT',
                    'ES',
                    'RUK',
                    'Expansion South',
                    'Expansion North',
                    'EXTREME',
                  ]}
                  name='sales_business_unit'
                  value={sales_business_unit}
                  onChange={(value) => handleSelect('sales_business_unit', value)}
                />
              </Col>
            </Row>
            {vis1 && (
              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666s%',
                  }}
                ></Col>
                <Col md={{ span: 3 }}>
                  {ccModal.open ? (
                    <CCModal
                      data={ccModal}
                      setCcModal={setCcModal}
                      value={cost_center}
                      disabled={props.disabled}
                    />
                  ) : (
                    <div />
                  )}
                </Col>
              </Row>
            )}
            {vis1 && (
              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666s%',
                    marginTop: '-20px',
                  }}
                ></Col>

                <Col className='FixedRight' md={{ span: 2, offset: 1 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    {TechLead && (
                      <Text
                        style={{
                          fontSize: '0.875rem',
                          fontWeight: '450',
                          color: '#212529',
                          paddingRight: 'unset',
                        }}
                      >
                        {TechName}
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>
            )}
            {vis1 && (
              <Row style={{ alignItems: 'end' }}>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666s%',
                  }}
                ></Col>

                <Col md={{ span: 5 }}>
                  {/* {capex_needed ? (
                    <TextInput
                      error={validate && !capex_amount ? 'Required Field' : ''}
                      disabled={props.disabled}
                      className='InputField'
                      radius='md'
                      label='Capex Amount (USD Only)'
                      name='capex_amount'
                      value={capex_amount}
                      onChange={handleChange}
                      placeholder={'USD Only'}
                    />
                  ) : (
                    <div></div>
                  )} */}
                </Col>
              </Row>
            )}
            {!vis1 && (
              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666s%',
                  }}
                ></Col>

                <Col md={{ span: 5, offset: 0 }}></Col>
              </Row>
            )}
            {/* <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 2, offset: 0 }}>
                <div>
                  {' '}
                  <p
                    className='fixedLeft'
                    style={{
                      paddingBottom: '0px',
                      marginBottom: '0px',
                    }}
                  >
                    Impacted Plants <span style={{ color: 'red' }}>*</span>
                  </p>{' '}
                  {validate && impactedPlants?.length === 0 && (
                    <span
                      style={{
                        color: '#f03e3e',
                        fontSize: '14px',
                        marginBottom: '1rem',
                      }}
                    >
                      Required Field
                    </span>
                  )}
                </div>
              </Col>
              <Col className='FixedRight' md={{ span: 2, offset: 1 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  {impactedPlants.length > 0 && (
                    <Text
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '450',
                        color: '#212529',
                        paddingRight: 'unset',
                      }}
                    >
                      Check and Change Impacted Plants
                    </Text>
                  )}

                  <ModalAccf
                    type={'plants'}
                    title={impactedPlants.length > 0 ? <Pencil1Icon /> : 'Indicate Impacted Plants'}
                    selected={
                      form.rfsInformation['impactedPlants']
                        ? form.rfsInformation['impactedPlants']
                        : []
                    }
                    handleSelect={handleAccfSelect}
                    submitted={false}
                    disabled={false}
                  />
                </div>
              </Col>
            </Row> */}
          </form>{' '}
          {/*//end of project details form */}
        </Container>
      </Row>
      <Row>
        <hr
          style={{
            color: '#E5B611',
            width: '93%',
            marginLeft: 'auto',
            marginTop: '30px',
            marginRight: 'auto',
            height: '3px',
          }}
        />
      </Row>

      <Row>
        {' '}
        {/*Start of Rfs details row */}
        <Container className='RFSDetailsForm'>
          <form>
            <Row>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
              <Col>
                <h5 className='sectionHeadings'> PROJECT DETAILS</h5>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666s%',
                }}
              ></Col>
              <Col md={5}>
                <Textarea
                  error={validateTextArea('project_purpose')}
                  required
                  style={{ paddingBottom: '1em' }}
                  className='rfs-form-textarea'
                  radius='md'
                  placeholder='Why is this project needed?'
                  label='Project Purpose (Character limit 1000)'
                  disabled={props.disabled}
                  name='project_purpose'
                  value={
                    project_purpose?.length > 1000
                      ? project_purpose.substring(0, 1000)
                      : project_purpose
                  }
                  onChange={(event) => handleChange(event)}
                />
                {project_purpose?.length > 999 && (
                  <div className='custom-mantine-Textarea-error'>
                    1000 Character limit reached, exceeding character would be truncated on saving.
                  </div>
                )}
              </Col>

              <Col md={{ span: 5, offset: 1 }}>
                <Textarea
                  error={validateTextArea('project_details')}
                  required
                  style={{ paddingBottom: '1rem' }}
                  className='rfs-form-textarea'
                  radius='md'
                  placeholder='How and Where the changes are needed?'
                  label='Project Explanation (Character limit 1000)'
                  disabled={props.disabled}
                  name='project_details'
                  value={
                    project_details?.length > 999
                      ? project_details.substring(0, 999)
                      : project_details
                  }
                  onChange={(event) => handleChange(event)}
                />
                {project_details?.length > 999 && (
                  <div className='custom-mantine-Textarea-error'>
                    1000 Character limit reached, exceeding character would be truncated on saving.
                  </div>
                )}
              </Col>
            </Row>
          </form>
        </Container>
      </Row>
      <Row>
        <hr
          style={{
            color: '#E5B611',
            width: '93%',
            marginLeft: 'auto',
            marginTop: '30px',
            marginRight: 'auto',
            height: '3px',
          }}
        />
      </Row>

      <Row className='Artworksection'>
        <Container className='artworkDetails'>
          <form>
            <Row>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666s%',
                }}
              ></Col>
              <Col md={{ span: 11 }}>
                <Textarea
                  style={{ paddingTop: '1rem' }}
                  className='rfs-form-textarea'
                  radius='md'
                  placeholder='Placeholder text'
                  label='Any Additional Comments (Character limit 1000)'
                  disabled={props.disabled}
                  name='comments'
                  value={comments?.length > 999 ? comments.substring(0, 999) : comments}
                  onChange={(event) => handleChange(event)}
                />
                {comments?.length > 999 && (
                  <div className='custom-mantine-Textarea-error'>
                    1000 Character limit reached, exceeding character would be truncated on saving.
                  </div>
                )}
              </Col>
            </Row>
          </form>
        </Container>
      </Row>

      {invalidModal && (
        <InvalidModal
          invalidModal={invalidModal}
          setInvalidModal={setInvalidModal}
          nextClick={update}
        />
      )}
    </ContentPaper>
  );
}

const FastTrackDatePickerInput = ({
  validate,
  first_shipment,
  disabled,
  fast_track,
  handleSelect,
}) => {
  const [initialMonth, setIntialMonth] = useState(moment().add('days', 1).toDate());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      if (fast_track) {
        setIntialMonth(moment().add('days', 1).toDate());
      } else {
        setIntialMonth(moment().add('days', 77).toDate());
      }

      setLoading(false);
    }, [50]);
  }, [fast_track]);

  if (loading)
    return (
      <TextInput
        icon={<CalendarIcon color='#e5b611' />}
        required
        className='InputField'
        radius='md'
        label='First Shipment'
        placeholder={fast_track ? '' : 'Select dates after 11 weeks from today.'}
      />
    );

  return (
    <DatePickerInput
      error={validate && !first_shipment ? 'Required Field' : ''}
      required
      placeholder={fast_track ? '' : 'Select dates after 11 weeks from today.'}
      numberOfColumns={2}
      minDate={initialMonth}
      initialMonth={initialMonth}
      className='InputField'
      radius='md'
      icon={<CalendarIcon color='#e5b611' />}
      label='First Shipment'
      disabled={disabled}
      value={first_shipment ? moment(first_shipment, 'DD-MM-YYYY').toDate() : null}
      onChange={(value) => handleSelect('first_shipment', value)}
    />
  );
};
