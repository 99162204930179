import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const useActiveModule = () => {
  const location = useLocation();

  const activeModule = useMemo(() => {
    const { pathname } = location;
    return pathname.includes('costingRequests') ? 'costingRequests' : 'rfs';
  }, [location]);

  return activeModule;
};

export default useActiveModule;
