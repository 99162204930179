import * as yup from 'yup';
import { materialchangeOverOptions, liquidBomChanges, additionLiquid } from '../../../constants';

export const LiquidsSchema = yup.object().shape({
  bom_changes: yup.array().min(1, 'bom_changes').required('bom_changes'),
  liquid_number: yup
    .string()
    .nullable()
    .when('bom_changes', ([bom_changes]) => {
      if (
        bom_changes &&
        (bom_changes.includes(liquidBomChanges.thickness) ||
          bom_changes.includes(additionLiquid.addition))
      )
        return yup.string().nullable().required('liquid_number');
    }),
  new_quantity: yup
    .string()
    .nullable()
    .when(
      ['bom_changes', 'liquid_type', 'liquid_number'],
      (bom_changes, liquid_type, liquid_number) => {
        const type = liquid_number ? liquid_number : liquid_type;
        if (
          (bom_changes &&
            (bom_changes.includes(liquidBomChanges.drawing) ||
              bom_changes.includes(additionLiquid.addition))) ||
          (type == 'New Liquid' && bom_changes.includes(liquidBomChanges.thickness))
        )
          return yup.string().nullable().required('new_quantity');
      },
    ),
  new_quantity_check: yup
    .number()
    .nullable()
    .when(
      ['bom_changes', 'liquid_type', 'liquid_number'],
      (bom_changes, liquid_type, liquid_number) => {
        const type = liquid_number ? liquid_number : liquid_type;
        if (
          (bom_changes &&
            (bom_changes.includes(liquidBomChanges.drawing) ||
              bom_changes.includes(additionLiquid.addition))) ||
          (type == 'New Liquid' && bom_changes.includes(liquidBomChanges.thickness))
        )
          return yup
            .number()
            .integer()
            .max(100000, 'max_value_exceeded')
            .nullable()
            .required('new_quantity_check');
      },
    ),
  changeover_type: yup.string().nullable().required('changeover_type'),
  changeover_date: yup
    .string()
    .nullable()
    .when('changeover_type', ([changeover_type]) => {
      if (
        [
          materialchangeOverOptions.hardChange,
          materialchangeOverOptions.notSoonerThan,
          materialchangeOverOptions.notLaterThan,
        ].includes(changeover_type)
      )
        return yup.string().nullable().required('changeover_date');
    }),
  comment: yup
    .string()
    .nullable()
    .when('liquid_number', {
      is: 'New Liquid',
      then: () => yup.string().nullable().required('comment'),
    }),
  unit: yup
    .string()
    .nullable()
    .when(['liquid_number', 'bom_changes'], ([liquid_number, bom_changes]) => {
      if (
        bom_changes &&
        liquid_number &&
        (bom_changes.includes(liquidBomChanges.thickness) ||
          bom_changes.includes(additionLiquid.addition)) &&
        liquid_number === 'New Liquid'
      )
        return yup.string().nullable().required('unit');

      return yup.string().nullable();
    }),
  tech_specification: yup
    .string()
    .nullable()
    .max(20, 'max_tech_spec_value_exceeded')
    .when('liquid_number', {
      is: 'New Liquid',
      then: () =>
        yup
          .string()
          .nullable()
          .max(20, 'max_tech_spec_value_exceeded')
          .required('tech_specification'),
    }),
});
