import React from 'react';
import TechFeasibility from './techfeasibility';
import RfsLayout from '../../components/layouts/RfsLayout';

function TechFeasibilityPage() {
  return (
    <RfsLayout>
      <TechFeasibility initialItems={0} />
    </RfsLayout>
  );
}

export default TechFeasibilityPage;
