import React, { useMemo } from 'react';
import { Modal } from '@mantine/core';
import MantineTable from '../../../../common/table/mantine';
import moment from 'moment';

const AltBoms = ({ open, boms = [], close }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'AlternativeBOM',
        header: 'Alt Bom',
      },
      {
        accessorKey: 'Plant',
        header: 'Plant',
      },
      {
        accessorKey: 'ValidTill',
        header: 'Valid Till',
        Cell: ({ row }) => moment(row.original.ValidTill, 'YYYYMMDD').format('YYYY-MM-DD'),
      },
    ],
    [],
  );

  return (
    <Modal size={'50%'} opened={open} title='' onClose={() => close()}>
      <MantineTable
        columns={columns}
        initialData={boms}
        hideSelectColumn={true}
        enableRowSelection={false}
        enableSorting={false}
        enableTopToolbar={false}
        loading={false}
        enableColumnActions={false}
        mantineTableHeadProps={{
          sx: {
            '& tr': {
              backgroundColor: '#D8D8D8',

              '& .mantine-TableHeadCell-Content': {
                color: '#1E1E1E',
              },
            },
          },
        }}
      />
    </Modal>
  );
};

export default AltBoms;
