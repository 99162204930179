import React, { useState } from 'react';
import moment from 'moment';
import { Table, Badge, Text, Checkbox, Button } from '@mantine/core';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { ActionModal } from '../../productDevelopment/actionModal';
import { ProductionLog } from '../../productDevelopment/productionSiteLogModal';

const PackMatsProductionTableVEAM = ({ materialDataList, type, save, readOnly, trials }) => {
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const [status, setStatus] = useState('');
  const [checked, setChecked] = useState(false);

  const Action = (item) => {
    return item.status === 'In Progress' || item.status === 'Not Started' ? (
      <Checkbox
        disabled={(item.status === 'In Progress' ? false : true) || readOnly}
        onChange={(event) => {
          setChecked(event.target.checked);
          setStatus(item.id);
          setActionModal({ open: true, id: item.id });
        }}
        label='Confirm and Close'
      />
    ) : item.manually_closed && item.status === 'Closed' ? (
      <Text>Manually Closed</Text>
    ) : (
      <Text>Automatic Closure</Text>
    );
  };

  const getLogs = (imp_id) => {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  };

  const Logs = (id) => {
    return (
      <Button onClick={() => getLogs(id)} style={{ background: 'black', color:'white' }}>
        View Logs
      </Button>
    );
  };

  const filteredData = (data) => {
    return data.map((item) => {
      let Code = 'N/A',
        Description = 'N/A';

      if (type === 'Materials') {
        if (
          item.material_type === 'Existing Material' ||
          item.material_type === 'Remove Material'
        ) {
          Code = item.int_rfs_material_number ? item.int_rfs_material_number : 'N/A';

          Description = item.pack_mat_material_text ? item.pack_mat_material_text : 'N/A';
        } else if (item.material_type === 'New Material') {
          Code = item.new_material_code ? item.new_material_code : 'N/A';

          Description = item.new_material_description_intrfsml
            ? item.new_material_description_intrfsml
            : 'N/A';
        }
      }

      let sla_weeks_id_arr = [239, 240, 241];
      if (trials === 'false' || trials === false) sla_weeks_id_arr = [220, 221, 222];

      return {
        'Pack Mat Code': Code ? Code : 'N/A',
        'Pack Mat Description': Description ? Description : 'N/A',
        Plant: item.plant,
        SupplierProduction: (
          <Badge
            color={
              item.AllStatus
                ? item.AllStatus[sla_weeks_id_arr[0]] === 'In Progress'
                  ? 'yellow'
                  : item.AllStatus[sla_weeks_id_arr[0]] === 'Closed'
                  ? 'green'
                  : 'gray'
                : ''
            }
            size='lg'
          >
            {' '}
            {item.AllStatus ? item.AllStatus[sla_weeks_id_arr[0]] : ''}{' '}
          </Badge>
        ),
        MaterialSupplier: (
          <Badge
            color={
              item.AllStatus
                ? item.AllStatus[sla_weeks_id_arr[1]] === 'In Progress'
                  ? 'yellow'
                  : item.AllStatus[sla_weeks_id_arr[1]] === 'Closed'
                  ? 'green'
                  : 'gray'
                : ''
            }
            size='lg'
          >
            {' '}
            {item.AllStatus ? item.AllStatus[sla_weeks_id_arr[1]] : ''}{' '}
          </Badge>
        ),
        MaterialABI: (
          <Badge
            color={
              item.AllStatus
                ? item.AllStatus[sla_weeks_id_arr[2]] === 'In Progress'
                  ? 'yellow'
                  : item.AllStatus[sla_weeks_id_arr[2]] === 'Closed'
                  ? 'green'
                  : 'gray'
                : ''
            }
            size='lg'
          >
            {' '}
            {item.AllStatus ? item.AllStatus[sla_weeks_id_arr[2]] : ''}{' '}
          </Badge>
        ),
        // SupplierProduction: '',
        // MaterialSupplier: '',
        // MaterialABI: '',
        //MatID: item.material_id,
        Action: Action(item),
        Logs: Logs(item.id),
        'SLA Date': moment(item.sla_date).format('YYYY-MM-DD'),
        'Step Finished': item.finished_date
          ? moment(item.finished_date).format('YYYY-MM-DD')
          : item.status,
        scope_selection_code: item.material_code + ' - ' + item.material_description,
        scope_id: item.scope_id,
      };
    });
  };

  const filteredDataRes = filteredData(materialDataList);

  const HtmlRenderedData = ({ type }) => {
    const heads = (
      <thead>
        <tr id='header-row'>
          <th>Pack Mat Code</th>
          <th>Pack Mat Description</th>
          <th>Plant</th>
          <th>Supplier Production</th>
          <th>Material On Stock at Supplier</th>
          <th>Material On Stock at ABI</th>
          <th>Action</th>
          <th>Log</th>
          <th>Step Deadline</th>
          <th>Step Finished</th>
        </tr>
      </thead>
    );

    const Materials = () => {
      const rowsData = filteredDataRes.map((item, index) => (
        <tr key={index}>
          {Object.values(item).map((it, i) => {
            return (
              i < 10 && (
                <td key={i} style={{ padding: '0.8rem !important' }}>
                  {it}
                </td>
              )
            );
          })}
        </tr>
      ));

      return (
        <Table>
          {heads}
          <tbody>{rowsData}</tbody>
        </Table>
      );
    };

    return <Materials />;
  };

  return (
    <Styles style={{ paddingRight: '0' }}>
      <div style={{ overflowX: 'auto' }}>
        <HtmlRenderedData type={type} />
      </div>

      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={status}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Pack Mats Production'
        />
      )}
    </Styles>
  );
};

export default PackMatsProductionTableVEAM;
