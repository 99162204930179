import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import style from './navItem.module.css';
import NavItemHeader from './NavItemHeader';
import { Tooltip } from '@mantine/core';

const NavItem = ({ item, onlyIcons }) => {
  const location = useLocation();
  const { label, Icon, to, children, onClick, tooltip } = item;

  // Check if nav item is active
  const isActive = (data) => (location.pathname.includes(data) ? style.activeNavItem : '');

  // Render children if present
  if (children) return <NavItemHeader item={item} onlyIcons={onlyIcons} />;

  return (
    <NavLink to={to} className={`${style.navItem} ${isActive(to)}`} onClick={onClick || null}>
      <Icon className={style.navIcon} />
      {!onlyIcons && <span className={style.navLabel}>{label}</span>}
    </NavLink>
  );
};

export default NavItem;
