import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { Accordion, Grid, Table, Badge, Text, Checkbox, Button } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { ActionModal } from '../actionModal';
import { ProductionLog } from '../productionSiteLogModal';
import { materialTypes, liquidTypes, liquidBomChanges } from '../../../../constants/index';

const StatusCheckTableVEAM = ({
  statusDataList,
  type,
  save,
  accState,
  actionModal,
  setActionModal,
}) => {
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const [htmlData, setHtmlData] = useState('');
  const [status, setStatus] = useState('');
  const [checked, setChecked] = useState(false);

  const [value, setValue] = useState(0);
  const [active, setActive] = useState('');

  useEffect(() => {
    setValue(accState);
  }, [accState]);

  const Action = (item) => {
    return item.status === 'In Progress' || item.status === 'Not Started' ? (
      <Checkbox
        disabled={item.status === 'In Progress' ? false : true}
        onChange={(event) => {
          setChecked(event.target.checked);
          setStatus(item.id);
          setActionModal({ open: true, id: item.id });
        }}
        label='Confirm and Close'
      />
    ) : item.manually_closed && item.status === 'Closed' ? (
      <Text>Manually Closed</Text>
    ) : (
      <Text>Automatic Closure</Text>
    );
  };

  const getLogs = (imp_id) => {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  };

  const Logs = (id) => {
    return (
      <Button onClick={() => getLogs(id)} style={{ background: 'black', color:'white' }}>
        View Logs
      </Button>
    );
  };

  const filteredData = (data) => {
    return data.map((item) => {
      let scope_selection_code = 'LIQ - ' + item.selection_number + ' - ' + item.selection_name;
      let Code = 'N/A',
        Description = 'N/A';

      if (type === 'Materials') {
        Code = item.int_rfs_material_number;
        Description = item.material_description_or;
        if (
          item.material_type === materialTypes.existing ||
          item.material_type === materialTypes.remove
        ) {
          Description = item.pack_mat_material_text ? item.pack_mat_material_text : 'N/A';
        }
        if (item.material_type === materialTypes.new) {
          Code = item.new_material_code ? item.new_material_code : 'N/A';
          Description = item.new_material_description_intrfsml
            ? item.new_material_description_intrfsml
            : 'N/A';
        }
      }

      if (type === 'Liquids') {
        if ([liquidTypes.add, liquidTypes.new].includes(item.liquid_type) && !item.liquid_number) {
          Code = item.new_material_code;
          Description = item.new_material_description_intrfsml;
        } else {
          Code =
            [liquidTypes.add].includes(item.liquid_type) && item.liquid_number
              ? item.liquid_number
              : item.liquid_code;
          Description =
            [liquidTypes.add].includes(item.liquid_type) && item.liquid_number
              ? item.material_description
              : item.liquid_description;
        }
      }

      return {
        Code: Code ? Code : 'N/A',
        Description: Description ? Description : 'N/A',
        Plant: item.plant,
        Status: (
          <Badge
            color={
              item.status === 'In Progress' ? 'yellow' : item.status === 'Closed' ? 'green' : 'gray'
            }
            size='lg'
          >
            {' '}
            {item.status}{' '}
          </Badge>
        ),
        Action: Action(item),
        Logs: Logs(item.id),
        'SLA Date': moment(item.sla_date).format('YYYY-MM-DD'),
        'Step Finished': item.finished_date
          ? moment(item.finished_date).format('YYYY-MM-DD')
          : item.status,
        scope_selection_code: scope_selection_code,
        scope_id: item.scope_id,
      };
    });
  };

  const filteredDataRes = filteredData(statusDataList);

  const HtmlRenderedData = ({ type }) => {
    let Title = type.substring(0, type.length - 1);
    const heads = (
      <thead>
        <tr id='header-row'>
          <th>{Title} Code</th>
          <th>{Title} Description</th>
          <th>Plant</th>
          <th>Status</th>
          <th>Action</th>
          <th>Logs</th>
          <th>Step Deadline</th>
          <th>Step Finished</th>
        </tr>
      </thead>
    );

    const Materials = () => {
      const rowsData = filteredDataRes.map((item, index) => (
        <tr key={index}>
          {Object.values(item).map((it, i) => {
            return (
              i < 8 && (
                <td key={i} style={{ padding: '0.8rem !important' }}>
                  {it}
                </td>
              )
            );
          })}
        </tr>
      ));

      return (
        <Table>
          {heads}
          <tbody>{rowsData}</tbody>
        </Table>
      );
    };

    const Liquids = () => {
      let arr = [];
      filteredDataRes.map((item) =>
        arr.includes(item.scope_id) === false ? arr.push(item.scope_id) : '',
      );

      const a = arr.map((arr_item) => {
        return filteredDataRes.filter((item) => {
          return item.scope_id == arr_item;
        });
      });

      return (
        <Accordion value={value} onChange={setValue}>
          {a.map((item, index) => {
            return (
              <Accordion.Item
                className='scope-label'
                key={`key_sub_${index}`}
                value={`key_sub_${index}`}
              >
                <Accordion.Control>{`${item[0].scope_selection_code}`}</Accordion.Control>
                <Accordion.Panel>
                  <Grid>
                    <Grid.Col span={12}>
                      <Styles>
                        <div>
                          <Table>
                            {heads}
                            <tbody>
                              {item.map((item, index) => (
                                <tr key={index}>
                                  {Object.values(item).map((it, i) => {
                                    return (
                                      i < 8 && (
                                        <td
                                          key={i}
                                          style={{
                                            padding: '0.8rem !important',
                                          }}
                                        >
                                          {it}
                                        </td>
                                      )
                                    );
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Styles>
                    </Grid.Col>
                  </Grid>
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      );
    };

    return type === 'Liquids' ? <Liquids /> : <Materials />;
  };

  return (
    <Styles>
      <div>
        <HtmlRenderedData type={type} />
      </div>
      {actionModal && status && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={status}
          state={value}
          id={'mod-' + status}
          active={active}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Status Check'
        />
      )}
    </Styles>
  );
};

export default StatusCheckTableVEAM;
