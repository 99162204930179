import React, { useEffect, useState } from 'react';
import { Tabs } from '@mantine/core';

import ContentPaper from '../../../../components/layouts/rfs/Content';

import BlockingVEAM from './blocking/blocking';
import StatusReversalVEAM from './statusReversal/statusReversal';
import ChangeOverRemovalVEAM from './changeover/changeOverRemoval';
import ProjectName from '../../../../components/projectName/projectName';
import { tabStyles } from '../../../../utils/helpers';

export default function VeamScopeUnlockReversal({ veamScopeUnlockInfo }) {
  const [activeTab, setActiveTab] = useState('');
  const [slaIds, setSlaIds] = useState([]);

  useEffect(() => {
    if (veamScopeUnlockInfo.logs.length > 0) {
      const ids = [];
      veamScopeUnlockInfo.logs.map((info) => {
        ids.push(info.sla_weeks_id);
      });

      setActiveTab(
        ids.includes(258)
          ? 'bnmc'
          : ids.includes(256)
          ? 'sr'
          : ids.includes(257) || ids.includes(259)
          ? 'cr'
          : '',
      );

      setSlaIds(ids);
    }
  }, []);

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <div style={{ marginBottom: '20px' }}>
        <ProjectName />
      </div>
      {slaIds.length > 0 && (
        <Tabs
          value={activeTab}
          onTabChange={setActiveTab}
          variant='unstyled'
          styles={tabStyles}
          className='prod-dev-tabs'
          color='primary'
          defaultValue={'sr'}
          keepMounted={false}
        >
          <Tabs.List>
            {slaIds.includes(258) && (
              <Tabs.Tab className='topTabs' value='bnmc'>
                Blocking of New Material Code
              </Tabs.Tab>
            )}
            {slaIds.includes(256) && (
              <Tabs.Tab className='topTabs' value='sr'>
                Status Reversal
              </Tabs.Tab>
            )}
            {(slaIds.includes(257) || slaIds.includes(259)) && (
              <Tabs.Tab className='topTabs' value='cr'>
                Changeover Removal
              </Tabs.Tab>
            )}
          </Tabs.List>

          {slaIds.includes(258) && (
            <Tabs.Panel value='bnmc'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  marginRight: '4rem',
                }}
              ></div>
              <BlockingVEAM />
            </Tabs.Panel>
          )}

          {slaIds.includes(256) && (
            <Tabs.Panel value='sr'>
              <StatusReversalVEAM />
            </Tabs.Panel>
          )}

          {(slaIds.includes(257) || slaIds.includes(259)) && (
            <Tabs.Panel value='cr'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  marginRight: '4rem',
                }}
              ></div>
              <ChangeOverRemovalVEAM />
            </Tabs.Panel>
          )}
        </Tabs>
      )}
    </ContentPaper>
  );
}
