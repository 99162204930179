import React, { useState, useContext, useEffect } from 'react';
import { Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
//import { MaterialCodeTableVeam } from './MaterialCodeTableVeam';
import StatusCheckTableVEAM from './statusCheckTableVEAM';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import csvDownload from 'json-to-csv-export';
import { TableExport } from 'tabler-icons-react';
import moment from 'moment';
import checkClosedOrInProgress from '../../veamHelper';
import { materialTypes, liquidTypes, liquidBomChanges } from '../../../../constants/index';

const StatusCheckVEAM = ({ checkClosuresStatus }) => {
  const { form, fetchStatusVeam, StoreManualClosureVEAM } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [statusData, setstatusData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [value, setValue] = useState(0);

  const [accState, setAccState] = useState('');
  const [statusDataN, setstatusDataN] = useState([]);

  const save = (payload, id, state) => {
    StoreManualClosureVEAM(payload, id).then(() => {
      getStatusDetails(rfs_number);
      setAccState(state);
      setActionModal({ open: false, id: null });
    });
  };

  useEffect(() => {
    checkClosuresStatus(checkClosedOrInProgress(statusDataN));
  }, [statusDataN]);

  useEffect(() => {
    getStatusDetails(rfs_number);
  }, [rfs_number]);

  const getStatusDetails = (rfs_number) => {
    setLoading(true);
    fetchStatusVeam(rfs_number).then((res) => {
      const statusCheckFilter = res.data.data.filter(
        (item) => item.sla_weeks_id === 212 || item.sla_weeks_id === 228,
      );

      const result = [];

      setstatusDataN(statusCheckFilter);

      result['Materials'] = statusCheckFilter
        .filter((item) => item.selection_type === 'materials')
        .sort((a, b) => a.id - b.id);
      result['Liquids'] = statusCheckFilter
        .filter((item) => item.selection_type === 'liquids')
        .sort((a, b) => a.id - b.id);

      setstatusData(result);
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const liquidHeaders = [
      'Liquid Code',
      'Liquid Description',
      'Plant',
      'Status',
      'Action',
      'Step Deadline',
      'Step finished',
    ];
    const materialHeaders = [
      'Material Code',
      'Material Description',
      'Plant',
      'Status',
      'Action',
      'Step Deadline',
      'Step finished',
    ];

    const liquids = statusData.Liquids;
    const materials = statusData.Materials;

    if ((liquids || []).length > 0) {
      dataToExport.data = getDataToExport(liquids);
      dataToExport.filename = 'Status_check_liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = liquidHeaders;
      csvDownload(dataToExport);
    }

    if ((materials || []).length > 0) {
      materialsToExport.data = getDataToExport(materials);
      materialsToExport.filename =
        'Status_check_materials' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = materialHeaders;
      csvDownload(materialsToExport);
    }
  };

  const getDataToExport = (data) => {
    const csvArray = data.map(dataToExport);

    return csvArray;
  };

  const dataToExport = ({
    material_code,
    material_description,
    plant,
    sla_date,
    status,
    manually_closed,
    finished_date,
    selection_type,
    int_rfs_material_number,
    material_description_or,
    material_type,
    pack_mat_material_text,
    new_material_code,
    new_material_description_intrfsml,
    bom_changes,
    liquid_type,
    liquid_code,
    liquid_description,
    liquid_number,
  }) => {
    let Code = 'N/A',
      Description = 'N/A';

    if (selection_type === 'materials') {
      Code = int_rfs_material_number;
      Description = material_description_or;
      if (material_type === materialTypes.existing || material_type === materialTypes.remove) {
        Description = pack_mat_material_text ? pack_mat_material_text : 'N/A';
      }
      if (material_type === materialTypes.new) {
        Code = new_material_code ? new_material_code : 'N/A';
        Description = new_material_description_intrfsml ? new_material_description_intrfsml : 'N/A';
      }
    }

    if (selection_type === 'liquids') {
      if ([liquidTypes.add, liquidTypes.new].includes(liquid_type) && !liquid_number) {
        Code = new_material_code;
        Description = new_material_description_intrfsml;
      } else {
        Code =
          [liquidTypes.add].includes(liquid_type) && liquid_number ? liquid_number : liquid_code;
        Description =
          [liquidTypes.add].includes(liquid_type) && liquid_number
            ? material_description
            : liquid_description;
      }
    }

    return {
      'Material Code': Code,
      'Material Description': Description,
      Plant: plant,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step Deadline': sla_date,
      'Step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  return (
    <Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='primary'
          style={{
            marginBottom: '1rem',
            marginTop: '2rem',
            background: 'black',
          }}
        >
          Export to CSV
        </Button>
      </div>
      <Accordion value={value} onChange={setValue}>
        {Object.keys(statusData) &&
          Object.keys(statusData).map((name, index) => {
            return (
              Object.values(statusData)[index].length > 0 && (
                <Accordion.Item
                  className='scope-label'
                  key={`key_${index}_${name}`}
                  value={`key_${index}_${name}`}
                >
                  <Accordion.Control>{`${name}`}</Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col span={12}>
                        <StatusCheckTableVEAM
                          statusDataList={Object.values(statusData)[index]}
                          type={name}
                          save={save}
                          accState={accState}
                          setActionModal={setActionModal}
                          actionModal={actionModal}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
              )
            );
          })}
      </Accordion>
    </Row>
  );
};

export default StatusCheckVEAM;
