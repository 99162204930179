import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import { Text } from '@mantine/core';
import { AppLoader } from '../../../components/common/loader';
import { Styles } from '../../../components/tables/skutable/production-site/styles';
import { RfsContext } from '../../../contexts/RfsContext';

export function ImpFeasTable({ name, scope, isSubmissionReview = false }) {
  const [filterLoading, setFilterLoading] = useState(false);
  const { fetchImpFeasTable } = useContext(RfsContext);
  const [impData, setImpData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getImplDetails(name);
  }, []);

  const getImplDetails = (id) => {
    setLoading(true);
    fetchImpFeasTable(id).then((res) => {
      setImpData(res.data.data);
      setLoading(false);
    });
  };
  const data = impData;
  const columns = useMemo(
    () => [
      {
        Header: 'Markets',
        Cell: ({ row }) => {
          return <Text>{row.original.market}</Text>;
        },
      },
      {
        Header: 'Volume (in HL)',
        Cell: ({ row }) => {
          return <Text>{row.original.volume?.toLocaleString()}</Text>;
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {data.length === 0 ? (
              <td>No existing market found for this SKU. </td>
            ) : (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })
            )}
            {filterLoading && (
              <tr>
                <td colSpan={13}>
                  <div className='loader-container'>
                    <AppLoader size={'md'} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Styles>
  );
}
