import React, { useEffect, useState, useContext } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { ActionIcon, Flex, Tooltip, MantineProvider, useMantineTheme } from '@mantine/core';
import { IconEdit, IconTrash, IconUserCheck } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Text } from '@mantine/core';
import { AdminContext } from '../../../contexts/AdminContext';
import { modals } from '@mantine/modals';
import ContentPaper from '../../../components/layouts/rfs/Content';

const AdminUsers = ({
  columns,
  type,
  unique,
  validationErrors,
  setValidationErrors,
  enableEditing = true,
}) => {
  const globalTheme = useMantineTheme;

  const [data, setData] = useState([]);

  const { fetchNewUsers, fetchExistingUsers, updateTable, deleteEntry } = useContext(AdminContext);

  //READ hook (get data in api)
  function useGet() {
    const queryKey = type === 'new' ? ['fetchNewUsers'] : ['fetchExistingUsers'];
    const queryFn = type === 'new' ? fetchNewUsers : fetchExistingUsers;
    return useQuery({
      queryKey: queryKey,
      queryFn: queryFn,
      refetchOnWindowFocus: false,
    });
  }

  //UPDATE hook (put data in api)
  function useUpdate() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: updateTable,
      //client side optimistic update
      onMutate: async (newData) => {
        await queryClient.setQueryData(['updateTable'], (prevData) =>
          prevData?.map((oldData) => (oldData[unique] === newData[unique] ? newData : oldData)),
        );
      },
      onSuccess: () => {
        const queryKey = type === 'new' ? ['fetchNewUsers'] : ['fetchExistingUsers'];
        queryClient.invalidateQueries({ queryKey: queryKey });
      },
    });
  }

  //DELETE hook (delete user from db)
  function useDeleteUser() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: deleteEntry,
      //client-side optimistic update
      onMutate: (userId) => {
        queryClient.setQueryData(['deleteEntry'], (prevUsers) =>
          prevUsers?.filter((user) => user.id !== userId),
        );
      },
      onSuccess: () => {
        const queryKey = type === 'new' ? ['fetchNewUsers'] : ['fetchExistingUsers'];
        queryClient.invalidateQueries({ queryKey: queryKey });
      },
    });
  }

  //call READ hook
  const { data: fetchedData = [], isError, isFetching, isLoading, error, status } = useGet();

  useEffect(() => {
    if (status === 'success' && !isFetching) {
      let data = fetchedData.data.data[0];
      setData(data);
    }
  }, [status, isFetching]);

  //call UPDATE hook
  const { mutateAsync: updateUser, isLoading: isUpdating } = useUpdate();

  //call DELETE hook
  const { mutateAsync: deleteUser, isLoading: isDeletingUser } = useDeleteUser();

  //DELETE action
  const openDeleteConfirmModal = (row) =>
    modals.openConfirmModal({
      title: <Text fw={700}>Are you sure you want to delete this user?</Text>,
      children: (
        <Text>
          Are you sure you want to delete {row.original.name}? This action cannot be undone.
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => deleteUser({ email: row.original.email }),
    });

  const validateRequired = (value) => !!value?.length;

  function validateUser(items) {
    const accessorKey = type === 'new' ? 'role_approved' : 'newRole';
    return {
      role: !validateRequired(items[accessorKey]),
    };
  }

  //UPDATE action
  const handleSave = async ({ values, table }) => {
    const accessorKey = type === 'new' ? 'role_approved' : 'newRole';
    const payload = {
      role_approved: values[accessorKey],
      email: values.email,
    };

    const newValidationErrors = validateUser(values);

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(payload);
    if (type !== 'new') table.setEditingRow(null); //exit editing mode
  };

  const table = useMantineReactTable({
    columns,
    data: data,
    enableColumnFilters: true,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: enableEditing,
    getRowId: (row) => row['id'],
    enableRowSelection: false,
    enableGlobalFilter: false,
    initialState: {
      showColumnFilters: true,
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSave,
    renderRowActions: ({ row, table }) =>
      type === 'new' ? (
        <Flex gap='md'>
          <Tooltip label='Approve'>
            <ActionIcon
              color='green'
              onClick={() => handleSave({ values: row.original, table: table })}
            >
              <IconUserCheck />
            </ActionIcon>
          </Tooltip>
          <Tooltip label='Reject'>
            <ActionIcon color='red' onClick={() => openDeleteConfirmModal(row)}>
              <IconTrash />
            </ActionIcon>
          </Tooltip>
        </Flex>
      ) : (
        <Flex gap='md'>
          <Tooltip label='Edit'>
            <ActionIcon onClick={() => table.setEditingRow(row)}>
              <IconEdit />
            </ActionIcon>
          </Tooltip>
          <Tooltip label='Delete'>
            <ActionIcon color='red' onClick={() => openDeleteConfirmModal(row)}>
              <IconTrash />
            </ActionIcon>
          </Tooltip>
        </Flex>
      ),
    state: {
      isLoading: isLoading,
      isSaving: isUpdating || isDeletingUser,
      showAlertBanner: isError,
      showProgressBars: isFetching || isUpdating || isDeletingUser,
    },
  });

  if (isError) {
    return (
      <ContentPaper page={'Admin'}>
        <span>{error.message}</span>
      </ContentPaper>
    );
  }

  return (
    <>
      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 7 }}>
        <MantineReactTable table={table} />
      </MantineProvider>
    </>
  );
};

export default AdminUsers;
