import React from 'react';
import AdminConsole from './adminConsole';
import RfsLayout from '../../../components/layouts/RfsLayout';

function AdminConsolePage() {
  return (
    <RfsLayout>
      <AdminConsole />
    </RfsLayout>
  );
}

export default AdminConsolePage;
