import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Modal, Container, Text, Anchor } from '@mantine/core';
import { axios } from '../../utils/axios';
import { AppLoader } from '../../components/common/loader';
import ReportCustomComp from '../../components/report/reportCustomComp';
import { AuthContext } from '../../contexts/AuthContext';
import { reportPermissions } from '../../constants';

const IndivisualStepsteamsModal = ({
  type = 'material',
  individualStepModal,
  setIndivisualstepsModal,
  selecteddata,
}) => {
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const { userRole } = useContext(AuthContext);
  const [selectedYear, setYears] = useState();

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getClassName = (column, color, title) => {
    let className = '';
    if (color == 'green') {
      className = 'row-option-2';
    }
    if (color == 'red') {
      className = 'row-option-3';
    }

    if (color == 'maroon') {
      className = 'row-option-5';
    }

    if (color == 'pink') {
      className = 'row-option-4';
    }

    if (color == 'gray') {
      className = 'row-option-1';
    }

    if (title && color == 'gray') {
      className = 'row-option-title';
    }

    if (title && color == 'red') {
      className = 'row-option-title-red';
    }

    return className;
  };

  let statusData = selecteddata;

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'title',
        size: 0,
        enableExpanding: true,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? getClassName(cell.getValue(), row.original.color, row.original.title)
            : 'hide-column',
          onClick: () => row.toggleExpanded(),
        }),
        Cell: ({ renderedCellValue, cell, row }) =>
          row.original.title ? (
            <div>
              <Text style={{ fontWeight: 'bold' }}>{row.original.title}</Text>
            </div>
          ) : (
            ''
          ),
      },
      {
        accessorKey: 'rfs_id',
        header: `ID`,
        size: 5,
        enableHiding: true,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'rfs_title',
        header: `RFS Name`,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
          onClick: row.getToggleExpandedHandler(),
        }),
        Cell: ({ renderedCellValue, cell, row }) => (
          <div>
            {renderedCellValue != null ? (
              <a href='#' style={{ fontSize: '14px', fontWeight: 'bold', color: '#3078a5' }}>
                {renderedCellValue}
              </a>
            ) : row.original.title ? (
              row.original.title
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        accessorKey: 'rfs_type',
        header: 'RFS Type',
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
      },
      {
        accessorKey: 'level',
        header: 'Level',
        enableEditing: false,
        size: 20,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'sku_number',
        header: 'SKU#',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Description',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'reference_sku_number',
        header: 'Ref SKU#',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'reference_sku_description',
        header: 'Ref SKU Description',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'new_material_code',
        header: 'Mat#',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'new_material_description',
        header: 'Mat Description',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'old_material_code',
        header: 'Old Mat#',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'old_material_description',
        header: 'Old Mat description',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'start_date',
        header: 'Start Date',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'end_date',
        header: 'End Date',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'planned_end_date',
        header: 'Planned',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'sla_in_days',
        header: 'SLA',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'real',
        header: 'Real',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'late',
        header: 'Late',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'assignee',
        header: 'Assignee',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : ''}</Text>
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: row.original.title
            ? 'hide-column'
            : getClassName(cell.getValue(), row.original.color),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? renderedCellValue : row.original.title ? '' : 'N/A'}
          </Text>
        ),
      },
    ],
    [validationErrors],
  );

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    setLoading(true);
    axios
      .get('/reports/submissionDate')
      .then(
        (res) => {
          let response = res.data.data;
          setYears(response);
        },
        (err) => {},
      )
      .finally(() => setLoading(false));
  }, []);

  return loading ? (
    <div>
      <Modal
        opened={individualStepModal.open}
        onClose={() => setIndivisualstepsModal({ open: false, id: null })}
        title={selecteddata.selectedStepname}
        className='indivisual-steps-view-modal'
      >
        <AppLoader size='md' center />
      </Modal>
    </div>
  ) : (
    <div>
      <Modal
        opened={individualStepModal.open}
        onClose={() => setIndivisualstepsModal({ open: false, id: null })}
        title={`${selecteddata.selectedStepname} ${
          monthNames[selecteddata.selectedStepmonth - 1]
        } ${selecteddata.selectedYear}`}
        className='individual-steps-view-modal'
      >
        <Container>
          <div>
            {/* selectedStepname, selectedFastrack, selectedYear, selectedStepmonth, selectedGatekeeper
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {capitalizeFirstLetter(type) + ` ${individualStepModal.id}`}{' '}
              </span>
            </Text> */}
            <div id='customReport' class='customIndividualStepReport'>
              <ReportCustomComp
                columns={columns}
                setValidationErrors={setValidationErrors}
                validationErrors={validationErrors}
                statusData={statusData}
                unique={'data_id'}
                enableEditing={false}
                type={'indivisualstepsteams'}
                readOnly={!reportPermissions['stepsteams'].includes(userRole)}
              />
            </div>
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default IndivisualStepsteamsModal;
