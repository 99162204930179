import React, { useContext, useState } from 'react';
import { Modal, Button, Grid, Group, Text, Textarea } from '@mantine/core';
import { Checkbox } from '@mantine/core';
import { RfsContext } from '../../../contexts/RfsContext';
import { InfoCircle } from 'tabler-icons-react';
import { AppLoader } from '../../../components/common/loader';
import { AuthContext } from '../../../contexts/AuthContext';

export const ActionModal = ({
  actionModal,
  setActionModal,
  text = 'You have selected the manual closure option. This action cannot be undone once confirmed. Please check the checkbox and click on OK if you wish to proceed ',
  save,
  status_id,
}) => {
  const { loading, saveAsDraft } = useContext(RfsContext);
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState('');

  const { userEmail } = useContext(AuthContext);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setActionModal(false);
  };

  const handleNext = () => {
    const payload = {
      remark: value,
      user: userEmail,
      status_ids: status_id,
      id: actionModal.id,
    };
    save(payload, actionModal.id, handleClose);
  };

  return (
    <>
      <Modal
        className='Invalid-modal'
        opened={actionModal?.open}
        onClose={() => handleClose()}
        title='Disclaimer'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='alert-modal-group'>
              <InfoCircle color='black' size='7rem' />
              <Text size='sm'>{text}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              label='Enter Comments'
              value={value}
              onChange={(event) => setValue(event.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox
              label='I accept the terms and conditions above'
              name='confirmed'
              onChange={handleChange}
            />
          </Grid.Col>
          <Grid.Col span={4} offset={8}>
            <Button
              onClick={() => handleNext()}
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              color='primary'
              fullWidth
              disabled={!checked || loading || saveAsDraft}
            >
              {!loading && !saveAsDraft ? 'OK' : <AppLoader />}
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
