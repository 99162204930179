import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Table from './table';

const queryClient = new QueryClient();

const MantineTable = (props) => (
  <div className='stm-table'>
    <QueryClientProvider client={queryClient}>
      <Table {...props} />
    </QueryClientProvider>
  </div>
);

export default MantineTable;
