import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { Styles } from '../../../../../components/tables/skutable/production-site/styles';
import { Text, Badge, Checkbox, Button } from '@mantine/core';
import { capitalizeFirstLetter, calculateColor } from '../../helpers';
import { AppLoader } from '../../../../../components/common/loader';
import { ManualClosureModal } from '../../skuPlanning/skuPlanningTable/manualClosureModal';

import moment from 'moment';
import LogModal from '../../obsoletesApproval/obsoletesMrpTable/logModal';

const DemandPlanningTable = ({ data, loading, manualClosureLoading, save, readOnly }) => {
  const [checked, setChecked] = useState({ checked: false, id: null });
  const [logModal, setLogModal] = useState({ open: false, id: null });

  const [manualClosure, setManualClosure] = useState({
    open: false,
    id: null,
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Markets',
        accessor: 'market_name',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_dlst_scope_market?.market_name}</Text>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          const { status, sla_date } = row.original;
          const color = calculateColor(status, sla_date);
          return (
            <Badge style={{ padding: '0.8rem' }} size='md' color={color}>
              {status}
            </Badge>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          return row.original.status === 'In Progress' || row.original.status === 'Not Started' ? (
            <Checkbox
              value={checked.checked}
              disabled={row.original.status === 'In Progress' ? false : true || readOnly}
              onClick={(event) => {
                setChecked({
                  checked: event.target.checked,
                  id: row.original.id,
                });
                event.target.checked && setManualClosure({ open: true, id: row.original.id });
              }}
              label='Confirm and Close'
            />
          ) : row.original.manually_closed && row.original.status === 'Closed' ? (
            <Text>Manual Closure</Text>
          ) : (
            <Text>Automatic Closure</Text>
          );
        },
      },
      {
        Header: 'Logs',
        accessor: 'view_logs',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          return (
            <Button
              variant='gradient'
              color='primary'
              style={{ background: 'black', color: 'white' }}
              onClick={() => setLogModal({ open: true, id: row.original.id })}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        accessor: 'sla_date',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('DD-MM-YYYY')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        accessor: 'finished_date',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('DD-MM-YYYY')
                : 'Not Finished'}
            </Text>
          );
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns,
    data: data,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (loading) return <AppLoader center size='md' />;

  return (
    <Styles>
      <div style={{ overflowX: 'auto', marginBottom: '2rem' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{ backgroundColor: '#FAF9F6' }}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} style={cell.column.style}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {logModal.open && (
          <LogModal type='Demand Planning' logModal={logModal} setLogModal={setLogModal} />
        )}
        {manualClosure.open && (
          <ManualClosureModal
            setTableChecked={setChecked}
            loading={manualClosureLoading}
            manualClosure={manualClosure}
            setManualClosure={setManualClosure}
            save={save}
          />
        )}
      </div>
    </Styles>
  );
};

export default DemandPlanningTable;
