import React, { useState, useMemo, useContext } from 'react';
import { useTable } from 'react-table';
import { Checkbox, Select, Button, TextInput } from '@mantine/core';
import { AppLoader } from '../../../common/loader';
import { Styles } from './styles';

export function ProductionTable({
  data,
  skuCode,
  referencePlants,
  repackingMaterials,
  onlineMaterials,
  onChange,
  setBomModal,
  errors,
  isSubmissionReview,
}) {
  const [filterLoading, setFilterLoading] = useState(false);
  const columns = useMemo(
    () => [
      {
        Header: 'Selected Plant',
        id: 'checkbox',
        Cell: ({ row }) => {
          return (
            <Checkbox
              checked={row.original.active}
              label={row.original.selected_plant}
              size='xs'
              onChange={(event) => onChange(event.target.checked, row.original.id, 'active')}
              disabled={isSubmissionReview}
            />
          );
        },
      },
      {
        Header: 'Reference Plant',
        Cell: ({ row }) => {
          return (
            <Select
              data={
                referencePlants[skuCode] && referencePlants[skuCode][row.original.selected_plant]
                  ? referencePlants[skuCode][row.original.selected_plant].plants
                  : []
              }
              value={row.original.reference_plant}
              onChange={(event) => {
                onChange(event, row.original.id, 'reference_plant', row.original.reference_plant);
              }}
              placeholder='Select'
              searchable
              nothingFound='Nothing found'
              error={errors[row.original.id] && errors[row.original.id]['reference_plant']}
              disabled={isSubmissionReview}
            />
          );
        },
      },
      {
        Header: 'BOM Action',
        Cell: ({ row }) => {
          return (
            <Select
              error={errors[row.original.id] && errors[row.original.id]['bom_action']}
              data={[
                { label: 'Existing BOM', value: 'Existing BOM' },
                { label: 'Modify BOM', value: 'Modify BOM' },
              ]}
              value={row.original.bom_action}
              onChange={(event) => onChange(event, row.original.id, 'bom_action')}
              disabled={isSubmissionReview}
            />
          );
        },
      },
      {
        Header: 'Select BOM',
        Cell: ({ row }) => {
          return (
            <Select
              error={errors[row.original.id] && errors[row.original.id]['reference_alt_bom']}
              data={
                referencePlants[skuCode] && referencePlants[skuCode][row.original.selected_plant]
                  ? referencePlants[skuCode][row.original.selected_plant]?.altBoms
                  : []
              }
              value={row.original.reference_alt_bom}
              onChange={(event) => {
                onChange(
                  event,
                  row.original.id,
                  'reference_alt_bom',
                  row.original.reference_alt_bom,
                );
              }}
              searchable
              nothingFound='Nothing found'
              disabled={isSubmissionReview}
            />
          );
        },
      },
      {
        Header: 'View/Modify BOM',
        id: 'hyperlink',
        Cell: ({ row }) => (
          <Button
            className={
              errors[row.original.id] && errors[row.original.id]['bomErrors'] ? 'error-button' : ''
            }
            variant='subtle'
            disabled={!row.original.reference_plant || !row.original.reference_alt_bom}
            style={{background:'black', color: 'white' }}
            onClick={() =>
              setBomModal({
                open: true,
                selection_number: skuCode,
                scope_id: row.original.scope_id,
                sku_id: row.original.id,
                editable: row.original.bom_action !== 'Existing BOM',
              })
            }
          >
            {row.original.bom_action == 'Existing BOM' ? 'View BOM' : 'Edit BOM'}
          </Button>
        ),
      },
      {
        Header: 'Production',
        Cell: ({ row }) => {
          return (
            <Select
              error={errors[row.original.id] && errors[row.original.id]['production']}
              style={{ width: '150px' }}
              data={[
                { label: 'Repacking', value: 'Repacking' },
                { label: 'On Line', value: 'On Line' },
              ]}
              placeholder='Repacking/On Line'
              value={row.original.production}
              onChange={(event) => onChange(event, row.original.id, 'production')}
              disabled={isSubmissionReview}
            />
          );
        },
      },

      {
        Header: 'Mother Product/ Dating Reference',
        Cell: ({ row }) => {
          let options = [];
          if (row.original.production === 'Repacking') {
            options =
              repackingMaterials[skuCode] &&
              repackingMaterials[skuCode][row.original.selected_plant]
                ? [...repackingMaterials[skuCode][row.original.selected_plant].material]
                : [];
          } else {
            options =
              onlineMaterials[skuCode] && onlineMaterials[skuCode][row.original.selected_plant]
                ? [...onlineMaterials[skuCode][row.original.selected_plant].material]
                : [];
            options.unshift({ label: `Others`, value: 'Others' });
          }
          return (
            <Select
              error={
                errors[row.original.id] && errors[row.original.id]['mother_product_date_formatting']
              }
              data={options}
              searchable
              disabled={row.original.production && !isSubmissionReview ? false : true}
              placeholder='Select SKU'
              value={row.original.mother_product_date_formatting}
              onChange={(event) =>
                onChange(event, row.original.id, 'mother_product_date_formatting')
              }
            />
          );
        },
      },
      {
        Header: 'Comments',
        Cell: ({ row }) => {
          return (
            <TextInput
              error={errors[row.original.id] && errors[row.original.id]['comment']}
              disabled={row.original.production && !isSubmissionReview ? false : true}
              placeholder='Comments'
              value={row.original.comment || ''}
              onChange={(event) => onChange(event.target.value, row.original.id, 'comment')}
            />
          );
        },
      },
    ],
    [data.length, referencePlants, repackingMaterials, onlineMaterials, errors],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            {filterLoading && (
              <tr>
                <td colSpan={13}>
                  <div className='loader-container'>
                    <AppLoader size={'md'} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <b>
          <font color='red'>
            {'* Dating Reference must have same number of pack levels (Primary/Secondary/Tertiary)'}
          </font>
        </b>
      </div>
    </Styles>
  );
}
