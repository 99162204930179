import React from 'react';
import DecomplexityTable from '../../components/decomplexityModule/decomplexityComp';

import RfsLayout from '../../components/layouts/RfsLayout';

export default function DecomplexityPage() {
  return (
    <div>
      <RfsLayout>
        <DecomplexityTable />
      </RfsLayout>
    </div>
  );
}
