import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { Checkbox } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { axios } from '../../../utils/axios';

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 10rem;
      padding: 0.8rem;
      text-align: center;
      width: 22rem;

      :last-child {
        border-right: 0;
      }
    }

    #header-row {
      background-color: #D8D8D8;
    }
    tr:nth-child(even) {
      background-color: #cdcdcd;
    }
    tr:nth-child(odd) {
      background-color: #fff;
    }
  }
  input {
    background-color: #fff;
  }

  .checkbox-column {
    width: 5rem;
  }
`;

export function DLSTSkuTable({ values, setValues, disabled }) {
  const params = useParams();
  const { rfsNumber } = params;
  const [scopeUnlockSkus, setscopeUnlockskus] = useState([]);

  useEffect(() => {
    axios.get(`/delisting/scope_unlock/${rfsNumber}`).then(
      (res) => {
        setscopeUnlockskus(res.data.int_rfs_scope_selection);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  const handleSelect = (event) => {
    const currentScopes = [...values.scope_ids];
    if (event.target.checked) {
      currentScopes.push(event.target.name);
    } else {
      const index = currentScopes.findIndex((i) => i === event.target.name);
      if (index > -1) currentScopes.splice(index, 1);
    }

    setValues({ scope_ids: currentScopes }, null, 'delisting');
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'checkbox',
        // width: '50',
        Cell: ({ row }) => {
          return (
            <div>
              <Checkbox
                name={row.original.id}
                onChange={handleSelect}
                checked={
                  (values.scope_ids && values.scope_ids.includes(row.original.id.toString())) ||
                  row.original.selected
                }
                disabled={disabled || row.original.invalid}
              />
            </div>
          );
        },
      },
      {
        Header: 'SKU Code',
        accessor: 'selection_number',
      },
      {
        Header: 'SKU Description',
        accessor: 'selection_name',
      },
    ],
    [values],
  );

  const tableInstance = useTable({
    columns,
    data: scopeUnlockSkus,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Styles>
  );
}
