import { Table } from '@mantine/core';
import moment from 'moment';

const mapping = (data) => {
  const skuData = data.data.filter((item) => data.sku.includes(item.sku_id));
  const bomData = data.unlockData.filter((item) => item.sku_id === data.sku);
  const name = {
    skuBom: <BomComponent data={skuData} bomData={bomData} />,
  };

  return name[data.type];
};

const BomColumns = (
  <tr>
    <th style={{ minWidth: '5rem' }}>Existing Material Code</th>
    <th style={{ minWidth: '20rem' }}>Existing Material Desc</th>
    <th style={{ minWidth: '5rem' }}>New Material Code</th>
    <th style={{ minWidth: '5rem' }}>New Material Description</th>
    <th style={{ minWidth: '2rem' }}>Type of Change</th>
    <th style={{ minWidth: '2rem' }}>Unit</th>
    <th style={{ minWidth: '10rem' }}>Component Quantity</th>
  </tr>
);

const BomComponent = ({ data, bomData }) => {
  return (
    <Table
      captionSide='top'
      verticalSpacing='xs'
      striped
      highlightOnHover
      style={{ width: '100%' }}
    >
      <caption>{`${bomData[0].selected_plant}`}</caption>
      <thead>{BomColumns}</thead>
      <tbody>
        {data.map((element, index) => (
          <tr key={`${index}_${element.bom_component}`}>
            <td>{element.bom_component}</td>
            <td>{element.component_description}</td>
            <td>
              {element.material_list === 'New Material'
                ? element.int_rfs_impl_material_creation[0].new_material_code
                : element.material_list
                ? element.material_list.split('-', 1)[0]
                : 'N/A'}
            </td>
            <td>
              {element.material_list === 'New Material'
                ? element.int_rfs_impl_material_creation[0].new_material_desc
                : element.material_list
                ? element.material_list.split('-', 2)[1]
                : 'N/A'}
            </td>
            <td>{element.type_of_change}</td>
            <td>{element.unit}</td>
            <td>{element.component_qty}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const List = (data) => {
  return mapping(data);
};
