import React from 'react';
import UnauthorisedModal from '../../components/modal/unauthorisedModal';
import RfsLayout from '../../components/layouts/RfsLayout';
import { Text } from '@mantine/core';

function UnauthorisedPage() {
  return (
    <RfsLayout>
      <Text className='padded-error'>Access Denied</Text>
      <UnauthorisedModal />
    </RfsLayout>
  );
}

export default UnauthorisedPage;
