import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { Modal } from '@mantine/core';

import { Styles } from '../../../components/scopeComp/tableStyles';
import moment from 'moment/moment';

export const ViewModal = ({ form, viewModal, setViewModal }) => {
  const data =
    viewModal.type === 'liquids'
      ? form.scopeSelectedLiquids.filter(
          (item) => parseInt(item.scope_id) === parseInt(viewModal.scope_id),
        )
      : form.selectedAffectedSkus.filter(
          (item) =>
            parseInt(item.scope_id) === parseInt(viewModal.scope_id) &&
            parseInt(item.material_id) === parseInt(viewModal.material_id),
        );

  return (
    <Modal
      opened={viewModal.open}
      onClose={() => setViewModal({ open: false, scope_id: null, material_id: null })}
      size='50%'
      title='Scope Selection'
    >
      <DetailTable data={data} type={viewModal.type} />
    </Modal>
  );
};

const DetailTable = ({ data, type }) => {
  const liquidColumns = useMemo(
    () => [
      {
        Header: type === 'liquids' ? 'Liquid Code' : 'Material Code',
        accessor: type === 'liquids' ? 'liquid_code' : 'material_code',
      },
      {
        Header: type === 'liquids' ? 'Liquid Description' : 'Material Description',
        accessor: type === 'liquids' ? 'liquid_description' : 'material_description',
      },
      {
        Header: 'Plant',
        accessor: 'plant',
      },
      {
        Header: 'Liquid Number',
        accessor: 'liquid_number',
      },
      {
        Header: 'Bom Changes',
        accessor: 'bom_changes',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'New Quantity',
        accessor: 'new_quantity',
      },
      {
        Header: 'Changeover Type',
        accessor: 'changeover_type',
      },
    ],
    [],
  );

  const materialColumns = useMemo(
    () => [
      {
        Header: 'Sku Code',
        accessor: 'skus',
      },
      {
        Header: 'Sku Description',
        accessor: 'sku_description',
      },

      {
        Header: 'Plant',
        accessor: 'plant',
      },
      {
        Header: 'Material Type',
        accessor: 'material_type',
      },
      {
        Header: 'Markets',
        accessor: 'markets',
      },
      {
        Header: 'Alt BOM',
        accessor: 'alt_bom',
      },
      {
        Header: 'UOM',
        accessor: 'uom',
      },
      {
        Header: 'Valid Till',
        accessor: 'ValidTill',
        Cell: ({ row }) => {
          return moment(row.original.ValidTill, 'YYYYMMDD').format('YYYY-MM-DD');
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns: type === 'liquids' ? liquidColumns : materialColumns,
    data,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Styles>
        <div style={{ overflowX: 'auto' }}>
          <table style={{ width: 'fitContent' }} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Styles>
    </div>
  );
};
