import { SupplierTable } from './table';
import withConfirm from '../../../components/common/confirm-dialog';

import React, { useContext, useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Grid } from '@mantine/core';

import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import { RfsContext } from '../../../contexts/RfsContext';
import { InvalidModal } from '../../../components/modal/invalidModal';
import { AppLoader } from '../../../components/common/loader';
import { routes } from '../../../routes';
import { SetFirstShipment } from '../../../components/modal/SetFirstShipment';
import { rfsStatus } from '../../../constants';

import '../../../components/scopeComp/scopeComp.css';
import { axios } from '../../../utils/axios';
import { SupplierContext } from '../../../contexts/SupplierContext';

const SupplierScope = (props) => {
  const { confirm, validationErrors = {} } = props;
  const params = useParams();
  const navigate = useNavigate();
  const { form, infoLoading, updateRfsStatus, info } = useContext(RfsContext);
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    fieldErrors: {},
  });
  const [invalidModal, setInvalidModal] = useState({
    open: false,
    callback: false,
  });
  const [firstShipment, setFirstShipment] = useState(false);
  const [vendors, setVendors] = useState([]);
  const { status = '', rfs_type } = form.rfsInformation;
  const { supplierForm, validateScope, getPlantsInfo } = useContext(SupplierContext);
  const [loading, setLoading] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);

  useEffect(() => {
    if (Object.keys(validationErrors).length > 0) {
      setScopeErrors(validationErrors);
    }
  }, [validationErrors]);

  useEffect(() => {
    if (supplierForm.length === 0) {
      getVendors();
      if (form.selectedScope.materials.length > 0) getPlantsInfo(params.rfsNumber);
    }
  }, [form.selectedScope.materials, params.rfsNumber]);

  const getVendors = () => {
    if (vendors.length === 0) {
      axios.get(`/productDevelopment/vendors`).then(
        (res) => {
          const data = res.data.vendors.map((item) => {
            return {
              label: `${item.vendor.trim()} - ${item.vendor_description.trim()}`,
              value: `${item.vendor.trim()} - ${item.vendor_description.trim()}`,
              selected: false,
            };
          });
          setVendors(data);
        },
        (error) => [console.log(error)],
      );
    }
  };

  const handleNext = (callback = null) => {
    const errors = validateScope(supplierForm, form?.selectedScope.materials);
    setScopeErrors(errors);
    setTimeout(() => {
      if (Object.keys(errors.errors).length > 0) {
        setInvalidModal({
          open: true,
          callback: callback ? callback : () => setInvalidModal(false),
        });
      } else {
        submit(callback);
      }
    }, 1000);
  };

  const updateTechFeasibilityRecords = () => {
    axios.post(`/supplier-changes/scope-selection/submission/${params.rfsNumber}`).then((res) => {
      if (rfsStatus.tech_feasible2 !== status) {
        updateRfsStatus(params.rfsNumber, rfsStatus.supplier_zitec_active);
        info(params.rfsNumber, false);
      }
    });
  };

  const submit = (callback = null) => {
    if (callback) setLoading(true);
    else setSaveAsDraft(true);

    let payload = [];

    supplierForm.forEach((item) => {
      payload.push({
        ...item,
        to_be_supplier: item.to_be_supplier ? item.to_be_supplier.join(',') : null,
      });
    });

    axios
      .post(`/supplier-changes/scope-selection/materials/create/${params.rfsNumber}`, payload)
      .then(
        (res) => {
          if (rfsStatus.draft !== status) updateTechFeasibilityRecords();
          if (callback) callback();
        },
        (error) => {},
      )
      .finally(() => {
        if (callback) setLoading(false);
        else setSaveAsDraft(false);
      });
  };

  const saveDataAsDraft = () => {
    submit();
  };

  const Footer = () =>
    props.disableButtons ? null : (
      <Grid>
        <Grid.Col span={2} offset={6}>
          {' '}
          <Link to={routes.submission(params.rfsNumber).scopeSelect}>
            <Button
              variant='gradient'
              color='primary'
              fullWidth
              style={{ background: 'white', color: 'black', border: ' 0.25px solid gray' }}
              size='md'
              radius='sm'
            >
              Back
            </Button>
          </Link>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            disabled={saveAsDraft}
            onClick={() => saveDataAsDraft()}
            variant='gradient'
            color='primary'
            style={{ background: 'black', color: 'white' }}
            fullWidth
            size='md'
            radius='sm'
          >
            {!saveAsDraft ? 'Save' : <AppLoader />}
          </Button>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            onClick={() =>
              handleNext(() =>
                status === rfsStatus.draft
                  ? navigate(routes.submission(params.rfsNumber).attachment)
                  : navigate(-2),
              )
            }
            disabled={loading}
            variant='gradient'
            color='primary'
            size='md'
            fullWidth
          >
            {!loading ? 'Next' : <AppLoader />}
          </Button>
        </Grid.Col>
      </Grid>
    );

  if (infoLoading)
    return (
      <ContentPaper page={'sku-2'}>
        <AppLoader size='md' center />
      </ContentPaper>
    );

  return (
    <ContentPaper
      page={'sku-2'}
      footer={props.isSubmissionReview ? null : <Footer />}
      submissionReview={props.isSubmissionReview}
    >
      {props.withProjectName ? (
        <Row style={{ padding: '1rem' }}>
          <ProjectName Pnumber='AM001' initialItem={props.initialItem} />
        </Row>
      ) : null}

      <Grid>
        <Grid.Col span={12}>
          <SupplierTable
            confirm={confirm}
            vendors={vendors}
            errors={scopeErrors.fieldErrors}
            {...props}
          />
        </Grid.Col>
      </Grid>

      {invalidModal && (
        <InvalidModal
          callback={() =>
            status === rfsStatus.draft
              ? navigate(routes.submission(params.rfsNumber).attachment)
              : navigate(-2)
          }
          invalidModal={invalidModal}
          setInvalidModal={setInvalidModal}
          nextClick={(callback) => submit(callback)}
        />
      )}

      {setFirstShipment && <SetFirstShipment open={firstShipment} setOpen={setFirstShipment} />}
    </ContentPaper>
  );
};

export default withConfirm(SupplierScope);
