import React, { useContext, useEffect, useState } from 'react';
import { MultiSelect, Text, TextInput, Textarea, Select } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';
import { AppLoader } from '../../../components/common/loader';

import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import { rfsStatus, teams } from '../../../constants';
import { PsSkuTable } from './ScopeUnlockTable';
import { axios } from '../../../utils/axios';
import { RfsContext } from '../../../contexts/RfsContext';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';
import { routes } from '../../../routes';
import { CommonContext } from '../../../contexts/common';

const teamsOptions = teams.map((team) => {
  return {
    label: team,
    value: team,
  };
});

const processTypes = [
  {
    value: '1',
    label: 'Scope Unlock',
  },
  {
    value: '0',
    label: 'Cancellation',
  },
];

function PsScopeUnlock({ form, footer, userName, values, setValues, readOnly = false }) {
  const params = useParams();
  const [teams, setTeams] = useState([]);
  const { rfsNumber } = params;
  const { scopeUnlockInfo } = useContext(ProjectOverviewContext);
  const { systemTeams } = useContext(CommonContext);
  const [data, setData] = useState([]);
  const { fetchPsBomData } = useContext(RfsContext);
  const skus = [];
  const [viewData, setViewData] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(true);

  useEffect(() => {
    function getScopeUnlockTeams() {
      axios
        .get(`/production-site/scope_unlock/fetch/${rfsNumber}`)
        .then(
          (teamsInfo) => {
            setData(teamsInfo.data.teams.int_rfs_scope_selection);
            const teams = teamsInfo.data.teams.manual_teams_involved.split(',');
            const team_responsible = teamsInfo.data.teams?.manual_teams_responsible;
            setTeams(teams);
            setValues({
              teams_involved: teams,
              teams_responsible: team_responsible,
            });
          },
          (error) => {},
        )
        .finally(() => setTeamsLoading(false));
    }
    getScopeUnlockTeams();
  }, []);

  useEffect(() => {
    data.forEach((item) => {
      skus.push(item.sku_id);
    });
    if (skus.length > 0) {
      fetchPsBomData(skus, rfsNumber).then((res) => {
        setViewData(res.data.data);
      });
    }
  }, [data]);

  const isDisabled = () => {
    return (
      values.id ||
      form?.rfsInformation?.status === 'Cancelled' ||
      form?.rfsInformation?.status === 'Implemented' ||
      form?.rfsInformation?.status === 'Cancellation' ||
      form?.rfsInformation?.status === 'Scope Unlock' ||
      form?.rfsInformation?.status === 'Scope Unlocked'
    );
  };

  const StatusMessage = () => {
    const {
      rfs_reversal_closure_date,
      child_rfs_number,
      childRfsLink = null,
      scope_unlock_flag,
    } = values;

    return rfs_reversal_closure_date ? (
      <div className='scope-unlock-status'>
        {scope_unlock_flag === '0' ? (
          'Scope unlock is complete'
        ) : (
          <>
            {`Scope unlock is complete.`} <br />
            <Link className='white rfs-link' to={`${routes.project(child_rfs_number).root}?tab=projectOverview`}>
              Click here to view child RFS <span className='blue'>{`${child_rfs_number}`}</span>
            </Link>
          </>
        )}
      </div>
    ) : (
      <div className='scope-unlock-status'>Reversal is in progress.</div>
    );
  };

  if (teamsLoading) {
    return (
      <ContentPaper page={'ScopeUnlock'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <>
      <ContentPaper page={'ScopeUnlock'} footer={footer}>
        <ProjectName />

        <div className='form-container'>
          <h5 className='sectionHeadings'>Scope Unlock</h5>

          {values.id && <StatusMessage />}

          <TextInput
            className='linear-input'
            value={values.initiated_by ? values.initiated_by : userName}
            label='Scope Unlock By'
            disabled={true}
          />
          <MultiSelect
            className='linear-input'
            placeholder='Pick the ones that apply'
            name='teams_involved'
            label={<Text size='xl'>Teams Involved</Text>}
            searchable
            clearable
            required
            nothingFound='No options'
            data={systemTeams}
            value={values?.teams_involved}
            onChange={(value) => {
              value = value.concat(teams);
              value = [...new Set(value)];
              setValues({ teams_involved: value });
            }}
            disabled={isDisabled() || readOnly}
          />
          <Select
            className='linear-input'
            placeholder='Team Responsible'
            name='teams_responsible'
            label={<Text size='xl'>Team Responsible</Text>}
            required
            searchable
            nothingFound='No options'
            data={systemTeams}
            value={values?.teams_responsible}
            onChange={(value) => setValues({ teams_responsible: value })}
            disabled={isDisabled() || readOnly}
          />

          <Select
            className='linear-input'
            placeholder='Scope Unlock / Cancellation'
            name='scope_unlock_flag'
            label={<Text size='xl'>Select Process</Text>}
            required
            nothingFound='No options'
            data={processTypes}
            value={values?.scope_unlock_flag}
            onChange={(value) => setValues({ scope_unlock_flag: value })}
            disabled={isDisabled() || readOnly}
          />
          <Textarea
            className='linear-input'
            label='Comments'
            placeholder='State your reason for scope restatemnt/cancellation here'
            required
            name='comment'
            autosize
            value={values?.comment}
            minRows={4}
            onChange={(event) => setValues({ comment: event.target.value })}
            disabled={isDisabled() || readOnly}
          />
        </div>

        <div className='sku-table'>
          <h5 className='sectionHeadings'>Select SKUs for Scope Restatement/Cancellation</h5>
          <PsSkuTable
            form={form}
            values={values}
            setValues={setValues}
            disabled={isDisabled() || readOnly}
            unlockData={data}
            viewData={viewData}
          />
        </div>
      </ContentPaper>
    </>
  );
}

export default PsScopeUnlock;
