import React, { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { Styles } from '../../../components/tables/skutable/production-site/styles';
import { Button, Modal, Text } from '@mantine/core';
import { useEffect } from 'react';
import { axios } from '../../../utils/axios';
import { useParams } from 'react-router-dom';
import ImpactedSkusTable from './ImpactedSkusTable';
import { AppLoader } from '../../../components/common/loader';

const Table = styled.table`
  width: fit-content;
`;

const PackMatTable = ({scopeId}) => {
  const [packMats, setPackMats] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const { rfsNumber } = params;

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/delisting/packmats/${scopeId}`)
      .then(
        (res) => {
          setPackMats(res.data.data);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [isModalOpen, setIsModalOpen] = useState({ open: false, data: null });

  const handleOpenModal = (data) => {
    setIsModalOpen({ open: true, data: data });
  };

  const handleCloseModal = () => {
    setIsModalOpen({ open: false, data: null });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Material Code',
        accessor: 'material_code',
      },
      {
        Header: 'Material Description',
        accessor: 'material_desc',
      },
      {
        Header: 'Impacted SKUs',
        accessor: 'impacted_materials',
        Cell: ({ row }) => (
          <Button
            // variant="outline"
            color='dark'
            radius='xl'
            style={{ background: 'black', color:'white' }}
            onClick={() => handleOpenModal(row.original.impacted_materials)}
          >
            Show SKUs
          </Button>
        ),
      },
    ],
    [],
  );

  const tableInstance = useTable({ columns, data: packMats });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (loading) return <AppLoader size='sm' center />;

  return (
    <Styles>
      <div style={{ overflowX: 'auto', marginBottom: '2rem' }}>
        <Table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{ backgroundColor: '#FAF9F6' }}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} style={cell.column.style}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {isModalOpen.open && (
          <Modal
            size='75%'
            title={
              <Text weight={700} size='xl'>
                SKUs
              </Text>
            }
            opened={isModalOpen.open}
            onClose={handleCloseModal}
          >
            <ImpactedSkusTable data={isModalOpen.data} />
          </Modal>
        )}
      </div>
    </Styles>
  );
};

export default PackMatTable;
