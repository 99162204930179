import { Modal, Group, Text, Table } from '@mantine/core';
import { AppLoader } from '../../common/loader';
import moment from 'moment';
import SkuSelectedMaterialTable from '../../tables/SkuSelectedMaterialTable/SkuSelectedMaterialTable';

export default function ViewSkus({ data, skuModel, setSkuModel }) {
  return (
    <>
      <Modal
        className='bom-modal'
        overflow='inside'
        opened={skuModel.open}
        onClose={() => setSkuModel({ open: false, plant: null })}
        title='SKUs'
      >
        <SkuSelectedMaterialTable data={data} readOnly={true} />
      </Modal>
    </>
  );
}
