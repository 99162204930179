import React, { useState, useEffect, useContext } from 'react';
import ContentPaper from '../layouts/rfs/Content';
import { Tabs, Text } from '@mantine/core';
import { axios } from '../../utils/axios';
import { tabStyles } from '../../utils/helpers';
import moment from 'moment'; 
import {AllRfsPageTable} from './allrfs'
const tabTypes = {
    all: 'all',
    backup: 'backup',
    my: 'my',
  };

export const AllRfsTable = ({ type = 'all' }) => {  
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(tabTypes[type]);
    const [nestedTab, setNestedTab] = useState(0);
    const [allRfs, setAllRfs] = useState([]);
    const [allImplementedRfs, setAllImplementedRfs] = useState([]);
    const [myRfs, setMyRfs] = useState([]);
    const [myImplementedRfs, setMyImplementedRfs] = useState([]);
    const [backupRfs, setBackupRfs] = useState([]);
    const [backupImplementedRfs, setBackupImplementedRfs] = useState([]);
  
    const fetchRfs = (type) => {
      setLoading(true);
  
      axios
        .get('/rfs', { params: { type: `${type}` } })
        .then(
          (res) => {
            const response = res.data.data;
  
            if (type === 'all') setAllRfs(response);
            else if (type === 'allImplemented') setAllImplementedRfs(response);
            else if (type === 'my') setMyRfs(response);
            else if (type === 'myImplemented') setMyImplementedRfs(response);
            else if (type === 'backup') setBackupRfs(response);
            else if (type === 'backupImplemented') setBackupImplementedRfs(response);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoading(false));
    };
  
    useEffect(() => {
        fetchRfs(activeTab);
      }, [activeTab]);
  
    const capitalizeSnakeCase = (str) => {
      return str.replaceAll('_', ' ').replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
      });
    };

  const handleTabChange = (activeTab, tabKey) => {
    setActiveTab(activeTab);  
  };

  const handleNestedTabChange = (nestedTab, tabKey) => {
    setNestedTab(nestedTab);
    fetchRfs(nestedTab);
  };

  return (
      <ContentPaper className='mainpage' page={'all-rfs'}>
        <Tabs
          variant='unstyled'
          styles={tabStyles}
          className='all-rfs-tabs'
          value={activeTab}
          onTabChange={handleTabChange}
          defaultValue='all'
          keepMounted={false}
        >
          <Tabs.List>
            <Tabs.Tab value='all'>All RFS</Tabs.Tab>
            <Tabs.Tab value='backup'>Backup RFS</Tabs.Tab>
            <Tabs.Tab value='my'>My RFS</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='all'>
            <Tabs
              className='in-progress-tabs'
              active={nestedTab}
              onTabChange={handleNestedTabChange}
              color='dark'
              defaultValue='all'
              keepMounted={false}
            >
              <Tabs.List>
                <Tabs.Tab className='subTab' value='all' style={{ color: '#000' }}>
                  In Progress
                </Tabs.Tab>
                <Tabs.Tab className='subTab' value='allImplemented' style={{ color: 'black' }}>
                  Fully Implemented
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value='all'>
                <AllRfsPageTable
                  loading={loading}
                  data={allRfs}
                  csvFilename={`all_projects_inprogress_${moment().format('YYYY-MM-DD HH:mm:ss')}`}
                />
              </Tabs.Panel>
              <Tabs.Panel value='allImplemented'>
                <AllRfsPageTable
                  loading={loading}
                  data={allImplementedRfs}
                  csvFilename={`all_projects_implemented_${moment().format('YYYY-MM-DD HH:mm:ss')}`}
                />
              </Tabs.Panel>
            </Tabs>
          </Tabs.Panel>

          <Tabs.Panel value='backup'>
            <Tabs
              className='in-progress-tabs'
              active={nestedTab}
              onTabChange={handleNestedTabChange}
              color='dark'
              defaultValue='backup'
              keepMounted={false}
            >
              <Tabs.List>
                <Tabs.Tab className='subTab' value='backup' style={{ color: '#000' }}>
                  In Progress
                </Tabs.Tab>
                <Tabs.Tab className='subTab' value='fullyImplemented' style={{ color: 'black' }}>
                  Fully Implemented
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value='backup'>
                <AllRfsPageTable
                  loading={loading}
                  data={backupRfs}
                  csvFilename={`my_projects_inprogress_${moment().format('YYYY-MM-DD HH:mm:ss')}`}
                />
              </Tabs.Panel>
              <Tabs.Panel value='backupImplemented'>
                <div className='table-container'>
                  <AllRfsPageTable
                    loading={loading}
                    data={backupImplementedRfs}
                    csvFilename={`my_projects_implemented_${moment().format(
                      'YYYY-MM-DD HH:mm:ss',
                    )}`}
                  />
                </div>
              </Tabs.Panel>
            </Tabs>
          </Tabs.Panel>

          <Tabs.Panel value='my'>
            <Tabs
              className='in-progress-tabs'
              active={nestedTab}
              onTabChange={handleNestedTabChange}
              color='dark'
              defaultValue='my'
              keepMounted={false}
            >
              <Tabs.List>
                <Tabs.Tab className='subTab' value='my' style={{ color: '#000' }}>
                  In Progress
                </Tabs.Tab>
                <Tabs.Tab className='subTab' value='myImplemented' style={{ color: 'black' }}>
                  Fully Implemented
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value='my'>
                <AllRfsPageTable
                  loading={loading}
                  data={myRfs}
                  csvFilename={`my_projects_inprogress_${moment().format('YYYY-MM-DD HH:mm:ss')}`}
                />
              </Tabs.Panel>
              <Tabs.Panel value='myImplemented'>
                <div className='table-container'>
                  <AllRfsPageTable
                    loading={loading}
                    data={myImplementedRfs}
                    csvFilename={`my_projects_implemented_${moment().format(
                      'YYYY-MM-DD HH:mm:ss',
                    )}`}
                  />
                </div>
              </Tabs.Panel>
            </Tabs>
          </Tabs.Panel>
        </Tabs>
      </ContentPaper>
    );

}
