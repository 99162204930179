import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, Box, rem } from '@mantine/core';
import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';

import MaterialsStatusChange from './MaterialsStatusChange/MaterialsStatusChange';
import PackMatsProduction from './PackMatsProduction/PackMatsProduction';
import FillingAndShipment from './FillingAndShipment/FillingAndShipment';

import MaterialCreationCodeVeam from '../productDevelopment/Material Code Creation/MaterialCreation';
import Supplier from '../productDevelopment/Supplier';
import StatusCheckVEAM from '../productDevelopment/StatusCheck/Statuscheck';
import ChangeOverVEAM from '../productDevelopment/ChangeOver/ChangeOver';
import MaterialsContract from '../productDevelopment/MaterialsContract/MaterialsContract';
import TrialsVEAM from '../productDevelopment/Trials/TrialsVEAM';
import ArtworkDevelopmentCode from '../productDevelopment/ArtworkDevelopment/ArtworkDevelopment';
import EanApprovalCode from '../productDevelopment/Ean/Ean';

import { RfsContext } from '../../../contexts/RfsContext';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';
import { axios } from '../../../utils/axios';

import Dot from '../productDevelopment/Dot';
import { prodDevPermissions } from '../../../constants';
import { AuthContext } from '../../../contexts/AuthContext';
import { tabStyles } from '../../../utils/helpers';
import { routes } from '../../../routes';
import { AppLoader } from '../../../components/common/loader';

const ImplementationVEAM = ({ trials, tabs }) => {
  const { form } = useContext(RfsContext);
  const { rfs_number, rfs_type } = form.rfsInformation;
  const { userRole } = useContext(AuthContext);

  const [tabStatus, setTabStatus] = useState({});
  const [slaWeeksIds, setSlaWeeksIds] = useState([]);

  const { getSLAWeeksIdsForRFS } = useContext(ProjectOverviewContext);
  const [tab, setTab] = useState('mcc');
  const urlParams = new URLSearchParams(window.location.search);
  const checkClosuresMat = (d) => setTabStatus({ ...tabStatus, material_creation: d });
  const checkClosuresStatus = (d) => setTabStatus({ ...tabStatus, status_check: d });
  const checkClosuresSupplier = (d) => setTabStatus({ ...tabStatus, supplier_name: d });
  const checkClosuresChangeOver = (d) => setTabStatus({ ...tabStatus, changeover: d });
  const checkClosuresArtWork = (d) => setTabStatus({ ...tabStatus, artwork_development: d });
  const checkClosuresEAN = (d) => setTabStatus({ ...tabStatus, ean_approval: d });
  const checkClosuresMatConract = (d) => setTabStatus({ ...tabStatus, material_contracts: d });
  const checkClosuresTrials = (d) => setTabStatus({ ...tabStatus, trials: d });
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [tabsLoading, setTabsLoading] = useState(true);

  const fetchAllSteps = (rfs_number) => {
    setTabsLoading(true);
    axios
      .get(`veam/get_all_step_status/${rfs_number}`)
      .then((res) => {
        let obj = {};
        let fetchedData = res.data.data;
        obj['material_creation'] = fetchedData.material_creation.difference;
        obj['status_check'] = fetchedData.status_check.difference;
        obj['supplier_name'] = fetchedData.supplier_name.difference;
        obj['changeover'] = fetchedData.changeover.difference;
        obj['artwork_development'] = fetchedData.artwork_development.difference;
        obj['ean_approval'] = fetchedData.ean_approval.difference;
        obj['material_contracts'] = fetchedData.material_contracts.difference;
        obj['trials'] = fetchedData.trials.difference;

        setTabStatus(obj);
      })
      .finally(() => setTabsLoading(false));
  };

  useEffect(() => {
    const sub = urlParams.get('sub');
    if (sub) {
      setTab(sub);
    }
  }, [urlParams]);

  useEffect(() => {
    setLoading(true);
    fetchAllSteps(rfs_number);
    getSLAWeeksIdsForRFS(rfs_number)
      .then((res) => {
        let sla_weeks_ids = [];
        res.data.data.map(
          (item) =>
            sla_weeks_ids.includes(item.sla_weeks_id) === false &&
            sla_weeks_ids.push(item.sla_weeks_id),
        );
        setSlaWeeksIds(sla_weeks_ids);
      })
      .finally(() => setLoading(false));
  }, [rfs_number]);

  useEffect(() => {
    if (slaWeeksIds.length > 0) {
      const defaultTab = getDefaultTab(slaWeeksIds, trials);
      setTab(defaultTab);
    }
  }, [trials, rfs_number, slaWeeksIds]);

  const getDefaultTab = (ids, trials) => {
    let defaultValue;

    if (trials) {
      if (ids.includes(237)) {
        defaultValue = 'msc';
      } else if (ids.includes(238)) {
        defaultValue = 'pmm';
      } else if (ids.includes(242)) {
        defaultValue = 'fs';
      } else {
        defaultValue = 'nt';
      }
    } else {
      ids.includes(211) ? (defaultValue = 'mcc') : (defaultValue = 'sc');
    }

    return defaultValue;
  };

  const permissions = prodDevPermissions[rfs_type];

  if (loading || tabsLoading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader center size='lg' />
      </ContentPaper>
    );
  }

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <ProjectName />
      <br />

      {trials && (
        <Tabs
          onTabChange={(value) =>
            navigate(
              `${routes.project(params.rfsNumber).root}?tab=${urlParams.get('tab')}&sub=${value}`,
            )
          }
          grow
          variant='unstyled'
          styles={tabStyles}
          className='prod-dev-tabs'
          color='primary'
          value={tab}
          keepMounted={false}
        >
          <Tabs.List>
            {slaWeeksIds.includes(237) && (
              <Tabs.Tab className='topTabs' value='msc'>
                Materials Status Change
              </Tabs.Tab>
            )}

            {slaWeeksIds.includes(238) && (
              <Tabs.Tab className='topTabs' value='pmm'>
                Pack Mats Production
              </Tabs.Tab>
            )}

            {slaWeeksIds.includes(242) && (
              <Tabs.Tab className='topTabs' value='fs'>
                Filling and Shipment
              </Tabs.Tab>
            )}
          </Tabs.List>

          {slaWeeksIds.includes(237) && (
            <Tabs.Panel value='msc'>
              <div>
                <MaterialsStatusChange
                  readOnly={!permissions['material_status_change'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {slaWeeksIds.includes(238) && (
            <Tabs.Panel value='pmm'>
              <div>
                <PackMatsProduction
                  trials={trials}
                  readOnly={!permissions['pack_mats'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {slaWeeksIds.includes(242) && (
            <Tabs.Panel value='fs'>
              <div>
                <FillingAndShipment
                  readOnly={!permissions['filings_shipment'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {!slaWeeksIds.includes(237) &&
            !slaWeeksIds.includes(238) &&
            !slaWeeksIds.includes(242) && <Tabs.Panel value='nt'>...</Tabs.Panel>}
        </Tabs>
      )}

      {!trials && (
        <Tabs
          onTabChange={(value) =>
            navigate(
              `${routes.project(params.rfsNumber).root}?tab=${urlParams.get('tab')}&sub=${value}`,
            )
          }
          grow
          variant='unstyled'
          styles={tabStyles}
          className='prod-dev-tabs prod-dev-tabs-veam'
          color='primary'
          value={tab}
          keepMounted={false}
        >
          <Tabs.List>
            {tabs.includes('Material Code Creation') && (
              <Tabs.Tab value='mcc'>
                Materials Code Creation <Dot dotStatus={tabStatus.material_creation} />
              </Tabs.Tab>
            )}
            {tabs.includes('Status Check') && (
              <Tabs.Tab value='sc'>
                Status Check <Dot dotStatus={tabStatus.status_check} />
              </Tabs.Tab>
            )}
            {tabs.includes('Supplier Name') && (
              <Tabs.Tab value='sn'>
                Supplier Name <Dot dotStatus={tabStatus.supplier_name} />
              </Tabs.Tab>
            )}
            {tabs.includes('Changeover') && (
              <Tabs.Tab value='co'>
                Changeover <Dot dotStatus={tabStatus.changeover} />
              </Tabs.Tab>
            )}
            {tabs.includes('Artwork Development') && (
              <Tabs.Tab value='ad'>
                Artwork Development <Dot dotStatus={tabStatus.artwork_development} />
              </Tabs.Tab>
            )}
            {tabs.includes('EAN Approval') && (
              <Tabs.Tab value='ean'>
                EAN Approval <Dot dotStatus={tabStatus.ean_approval} />
              </Tabs.Tab>
            )}
            {tabs.includes('Material Contracts') && (
              <Tabs.Tab value='mc'>
                Materials Contract <Dot dotStatus={tabStatus.material_contracts} />
              </Tabs.Tab>
            )}
            {tabs.includes('Trials Conclusion') && (
              <Tabs.Tab value='tc'>
                Trials Conclusion <Dot dotStatus={tabStatus.trials} />
              </Tabs.Tab>
            )}

            {slaWeeksIds.includes(218) && <Tabs.Tab value='msc'>Materials Status Change</Tabs.Tab>}
            {slaWeeksIds.includes(219) && <Tabs.Tab value='pmm'>Pack Mats Production</Tabs.Tab>}
            {slaWeeksIds.includes(223) && <Tabs.Tab value='fs'>Filling and Shipment</Tabs.Tab>}
          </Tabs.List>

          {tabs.includes('Material Code Creation') && (
            <Tabs.Panel value='mcc'>
              <div>
                <MaterialCreationCodeVeam checkClosuresMat={checkClosuresMat} />
              </div>
            </Tabs.Panel>
          )}
          {tabs.includes('Status Check') && (
            <Tabs.Panel value='sc'>
              <div>
                <StatusCheckVEAM checkClosuresStatus={checkClosuresStatus} />
              </div>
            </Tabs.Panel>
          )}
          {tabs.includes('Supplier Name') && (
            <Tabs.Panel value='sn'>
              <div>
                <Supplier checkClosuresSupplier={checkClosuresSupplier} />
              </div>
            </Tabs.Panel>
          )}
          {tabs.includes('Changeover') && (
            <Tabs.Panel value='co'>
              <div>
                <ChangeOverVEAM checkClosuresChangeOver={checkClosuresChangeOver} />
              </div>
            </Tabs.Panel>
          )}
          {tabs.includes('Artwork Development') && (
            <Tabs.Panel value='ad'>
              <div>
                <ArtworkDevelopmentCode checkClosuresArtWork={checkClosuresArtWork} />
              </div>
            </Tabs.Panel>
          )}
          {tabs.includes('EAN Approval') && (
            <Tabs.Panel value='ean'>
              <div>
                <EanApprovalCode checkClosuresEAN={checkClosuresEAN} />
              </div>
            </Tabs.Panel>
          )}
          {tabs.includes('Material Contracts') && (
            <Tabs.Panel value='mc'>
              <Box
                sx={(theme) => ({
                  backgroundColor: 'black',
                  textAlign: 'center',
                  padding: theme.spacing.sm,
                  borderRadius: theme.radius.md,
                  left: '1rem',
                  color: '#e5b611',
                  fontSize: '1.5em',
                  fontWeight: '700',
                  '&:hover': {
                    backgroundColor: 'black',
                  },
                })}
              >
                Material code
              </Box>
              <div>
                <MaterialsContract
                  checkClosuresMatConract={checkClosuresMatConract}
                  readOnly={!permissions['material_contracts'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}
          {tabs.includes('Trials Conclusion') && (
            <Tabs.Panel value='tc'>
              <div>
                <TrialsVEAM
                  checkClosuresTrials={checkClosuresTrials}
                  readOnly={!permissions['trials'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {slaWeeksIds.includes(218) && (
            <Tabs.Panel value={'msc'}>
              <div>
                <MaterialsStatusChange
                  trials={trials}
                  readOnly={!permissions['material_status_change'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {slaWeeksIds.includes(219) && (
            <Tabs.Panel value={'pmm'}>
              <div>
                <PackMatsProduction
                  trials={trials}
                  readOnly={!permissions['pack_mats'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}

          {slaWeeksIds.includes(223) && (
            <Tabs.Panel value={'fs'}>
              <div>
                <FillingAndShipment
                  trials={trials}
                  readOnly={!permissions['filings_shipment'].includes(userRole)}
                />
              </div>
            </Tabs.Panel>
          )}
        </Tabs>
      )}
    </ContentPaper>
  );
};

export default ImplementationVEAM;
