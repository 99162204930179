import styled from 'styled-components';

export const RightItems = styled.div`
  display: flex;
  justify-content: right;
  gap: 12px;
  button {
    margin: 0;
  }
`;

export const HeaderLeftPanel = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TableStyles = styled.div`
  :first-child :nth-child(2) {
    min-height: unset;
  }
`;

export const HeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftItems = styled.div`
  display: flex;
  justify-content: start;
  gap: 12px;
  button {
    margin: 0;
  }
`;
