import React, { useContext, useEffect, useState } from 'react';
import { Form } from './form';
import RfsLayout from '../../../../components/layouts/RfsLayout';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Button, Grid, Modal } from '@mantine/core';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import { AttachmentsModal } from '../../../../common/attachments';
import { attachmentChunks } from '../../../../utils/helpers';
import { SkuTable } from '../table/skusTable';
import PlantsTable from '../table/plantsTable';
import { FormSchema } from './validator';
import { axios } from '../../../../utils/axios';
import { AppLoader } from '../../../../components/common/loader';
import { routes } from '../../../../routes';
import { titleTypeMap } from './static';

const initialFormState = {
  requestorName: null,
  user_id: null,
  status: null,
  urgency: null,
  type_of_request: null,
  fsd_date: null,
  types_of_good: null,
  types_of_flow: null,
  types_of_sku: null,
  sales_district_of_buying_country: null,
  stock_taking_order_type: null,
  sku_data: [],
  producing_plant_data: [],
  selling_plant_data: [],
  buying_plant_data: [],
  comment: null,
};

const plantSkusInitial = {
  open: false,
  handler: null,
  selections: [],
  type: null,
  title: null,
};

const SelectionLimit = {
  'Select Production Plant': 5,
  'Select Buying Plant': 15,
  'Select Selling Plant': 5,
};

const MAX_ATTACHMENTS = 5;

export const Create = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { crNumber = null } = params;
  const { userName, user } = useContext(AuthContext);
  const { getRequestDetails, getSkus, getPlants, skus, plants, info, loaders } =
    useContext(CostingRequestsContext);
  const [form, setForm] = useState(initialFormState);
  const [attachmentModal, setAttachmentModal] = useState({ open: false, attachments: [] });
  const [plantSkuModal, setPlantSkuModal] = useState(plantSkusInitial);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState({});

  useEffect(() => {
    getRequestDetails(crNumber);
    getSkus();
    getPlants();
  }, [crNumber]);

  useEffect(() => {
    if (crNumber) {
      setForm({ ...form, ...info, requestorName: info?.user?.name });
    } else {
      setForm({ ...form, ...initialFormState, requestorName: userName, user_id: user.user });
    }
  }, [info, crNumber]);

  const fetchDistricts = (countries) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/costing-request/buying-district-code', { countries })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          resolve([]);
        });
    });
  };

  const handleFileSelect = (files) => {
    const currentValues = { ...attachmentModal };
    const attachments = currentValues.attachments || [];
    const filesLength = attachments.length + files.length;

    if (filesLength <= MAX_ATTACHMENTS) {
      files.forEach((file) => attachments.push(file));
      currentValues.attachments = attachments;
      setAttachmentModal(currentValues);
    }
  };

  const handleFileRemove = (index) => {
    const currentValues = { ...attachmentModal };
    currentValues.attachments = currentValues.attachments.filter((i, k) => k !== index);

    setAttachmentModal(currentValues);
  };
  const handleAttachmentSubmit = async () => {
    const { attachments } = attachmentModal;
    const formData = new FormData();

    for (const attachment of attachments) {
      if (attachment.id) {
        formData.append('attachmentId[]', attachment.id);
      }
    }

    const chunks = attachmentChunks(attachments.filter((file) => file.size));
    if (Object.keys(chunks).length > 0) {
      for (const chunk of Object.values(chunks)) {
        for (const file of chunk) {
          formData.append('attachments', file);
        }
        // await uploadAttachment(formData);
      }
    } else {
      // await uploadAttachment(formData);
    }

    setAttachmentModal({ ...attachmentModal, open: false });
    // fetch(false);
  };

  const handleSubmit = async (action) => {
    try {
      if (action !== 'draft') {
        await FormSchema.validate(form, { abortEarly: false });
        setErrors({});
      }

      submitRequest(action);
    } catch (err) {
      const fieldErrors = err.errors;
      const errInfo = {};

      fieldErrors.forEach((error) => {
        errInfo[error] = true;
      });
      setErrors(errInfo);
    }
  };

  const submitRequest = async (action) => {
    setLoading({ [action]: true });
    try {
      const response = await axios.post(`/costing-request/${action}`, {
        ...form,
      });

      if (action === 'draft') {
        navigate(routes.costingRequests(response.data.costing_request_id).request);
      } else {
        navigate(routes.costingRequests().root);
      }
    } catch (e) {
    } finally {
      setLoading({ [action]: false });
    }
  };

  const Footer = () => (
    <Grid justify='flex-end'>
      <Grid.Col span={2}>
        <Button
          color='dark'
          disabled={loading.submit || loading.draft}
          fullWidth
          onClick={() => handleSubmit('draft')}
        >
          {loading?.draft ? <AppLoader variant='dark' size='sm' /> : 'Save as draft'}
        </Button>
      </Grid.Col>
      <Grid.Col span={2}>
        <Button
          variant='gradient'
          color='primary'
          fullWidth
          disabled={loading.submit || loading.draft}
          onClick={() => handleSubmit('submit')}
        >
          {loading?.submit ? <AppLoader size='sm' /> : 'Submit'}
        </Button>
      </Grid.Col>
    </Grid>
  );

  const getActive = (data) => {
    const active = {};
    const volume = {};

    data.forEach((item) => {
      const plant = item['producing_plant'] || item['buying_plant'] || item['selling_plant'];
      if (plant) active[plant] = true;
      if (item['vol_hl']) volume[plant] = item['vol_hl'];
    });

    const {
      Country = null,
      producing_country = null,
      selling_country = null,
      buying_country = null,
    } = data[0] || {};

    const country = Country || producing_country || selling_country || buying_country || null;
    return { active, volume, country };
  };

  const ModalWrapper = () => {
    const onSkuSubmit = (selected) => {
      const info = [];
      Object.keys(selected).forEach((sku) => {
        const index = skus.findIndex((i) => i.sku === sku);
        if (index > -1) info.push(skus[index]);
      });
      setForm({ ...form, sku_data: info });
      setPlantSkuModal(plantSkusInitial);
    };

    const onPlantSubmit = async (selected, title, volume) => {
      setLoading({ ['plantSubmit']: true });
      const isVolumeApplicable = Object.keys(volume).length > 0;
      const selectedCountries = [];
      const info = Object.keys(selected).reduce((acc, sku) => {
        const plant = plants.find((i) => i.Plant === sku);
        if (plant) {
          const keyPrefix = titleTypeMap[title].split('_')[0];
          const item = {
            [`${keyPrefix}_plant`]: plant.Plant,
            [`${keyPrefix}_country`]: plant.Country,
            [`${keyPrefix}_zone`]: plant.Zone,
          };
          if (isVolumeApplicable) item['vol_hl'] = volume[plant.Plant];
          selectedCountries.push(plant.Country);
          acc.push(item);
        }
        return acc;
      }, []);
      if (title === 'Select Buying Plant') {
        const districts = await fetchDistricts([...new Set(selectedCountries)]);
        setForm((prevForm) => ({
          ...prevForm,
          [titleTypeMap[title]]: info,
          sales_district_of_buying_country: districts.join(','),
        }));
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          [titleTypeMap[title]]: info,
        }));
      }

      setPlantSkuModal(plantSkusInitial);
      setLoading({ ['plantSubmit']: false });
    };

    return (
      <Modal
        opened={plantSkuModal.open}
        onClose={() => setPlantSkuModal({ open: false })}
        size={'70%'}
        title={`${plantSkuModal.title} ${
          SelectionLimit[plantSkuModal.title]
            ? `(Selection limit ${SelectionLimit[plantSkuModal.title]})`
            : ''
        }`}
      >
        {plantSkuModal.type === 'sku' && (
          <SkuTable
            onSubmit={onSkuSubmit}
            active={form.sku_data[0] ? { [form.sku_data[0].sku]: true } : {}}
          />
        )}
        {plantSkuModal.type === 'plant' && (
          <PlantsTable
            {...plantSkuModal}
            onSubmit={onPlantSubmit}
            info={getActive(form[titleTypeMap[plantSkuModal.title]])}
            loading={loading.plantSubmit || false}
            limit={SelectionLimit[plantSkuModal.title]}
          />
        )}
      </Modal>
    );
  };

  if (Object.values(loaders).includes(true)) {
    return (
      <RfsLayout>
        <ContentPaper page='imp-feasibility' footer={<Footer />}>
          <AppLoader center size='md' />
        </ContentPaper>
      </RfsLayout>
    );
  }

  return (
    <RfsLayout>
      <ContentPaper page='imp-feasibility' footer={<Footer />}>
        <Form
          values={form}
          onChange={setForm}
          attachmentModal={attachmentModal}
          setAttachmentModal={setAttachmentModal}
          setPlantSkuModal={setPlantSkuModal}
          errors={errors}
        />
      </ContentPaper>
      {attachmentModal.open && (
        <AttachmentsModal
          modal={attachmentModal}
          setModal={setAttachmentModal}
          onChange={handleFileSelect}
          onSubmit={handleAttachmentSubmit}
          onRemove={handleFileRemove}
        />
      )}

      {plantSkuModal.open && <ModalWrapper />}
    </RfsLayout>
  );
};
