import { Loader } from '@mantine/core';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const AppLoader = ({ size = 'sm', center = false, variant = 'light' }) => {
  return center ? (
    <Container>
      <Loader variant='dots' size={size} color='#e5b611' />
    </Container>
  ) : (
    <Loader variant='dots' size={size} color={variant === 'light' ? 'black' : '#e5b611'} />
  );
};
