import React from 'react';
import styled from '@emotion/styled';
import { Group, ScrollArea, Button, Skeleton } from '@mantine/core';
import { IconDownload, IconX } from '@tabler/icons-react';

const Container = styled.div`
  display: flex;
  align-items: center; /* Center vertically */
  background: #ffffff;
`;

const Heading = styled.h4`
  font-size: 12px;
  font-weight: bold;
  margin-right: 20px;
  min-width: 150px;
  text-align: center;
  margin-top: 8px;
`;

const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ededed;
  width: 187.11px;
  height: 39.47px;
  border-radius: 4px;
  padding: 0 8px;
`;

const AttachmentName = styled.span`
  font-size: 11.05px;
  font-weight: 600;
  flex-grow: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
`;

const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  padding: 0;

  background-color: transparent;
  &:hover {
    background-color: transparent; /* Keep transparent on hover */
  }
`;

const StyledScrollArea = styled(ScrollArea)`
  background: #ffffff;
  flex-grow: 1;
`;

const ParentContainer = styled.div`
  background: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #dedede;
`;

export const AttachmentsList = ({
  attachments,
  onRemove,
  onDownload,
  loading = false,
  readOnly = false,
  title = 'Uploaded Attachments',
}) => {
  if (attachments.length === 0 && !loading) return <></>;
  return (
    <ParentContainer>
      <Skeleton visible={loading}>
        <Container>
          <Heading>{title}</Heading>

          <StyledScrollArea type='auto'>
            <Group spacing='sm' noWrap>
              {attachments.map((attachment, index) => (
                <AttachmentContainer key={index}>
                  <CustomButton
                    color='yellow'
                    ariant='subtle'
                    size='xs'
                    compact
                    onClick={() => onDownload(attachment)}
                  >
                    <IconDownload size={16} />
                  </CustomButton>

                  {/* Attachment Name */}
                  <AttachmentName>{attachment.name}</AttachmentName>

                  {/* Remove Button */}
                  {!readOnly && (
                    <CustomButton
                      variant='subtle'
                      size='xs'
                      compact
                      onClick={() => onRemove(attachment)}
                    >
                      <IconX size={16} />
                    </CustomButton>
                  )}
                </AttachmentContainer>
              ))}
            </Group>
          </StyledScrollArea>
        </Container>
      </Skeleton>
    </ParentContainer>
  );
};
