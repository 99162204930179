import React, { useContext } from 'react';
import { RfsContext } from '../../contexts/RfsContext';
import { CloseButton, Text } from '@mantine/core';
import styled from '@emotion/styled';

const CloseButtonStyle = styled(CloseButton)`
  margin-top: 2px;
`;
const List = styled.ul`
  margin-top: 8px;
  font-size: 0.8rem;
  padding-left: 0px;
`;
const ListItem = styled.li`
  display: flex;
  align-item: center;
`;
const Content = styled.span`
  color: #e5b611;
  cursor: pointer;
`;
const CustomText = styled(Text)`
  margin: 8px 0;
  font-size: 0.8rem;
`;

export const FileList = ({ files = [], type, error, onRemove = false, readOnly }) => {
  const { downloadFile, downloadLocalFile, form, setForm } = useContext(RfsContext);

  const handleDelete = (index) => {
    const currentValues = { ...form };
    currentValues.attachments[type].splice(index, 1);

    setForm(currentValues);
  };

  return files.length > 0 ? (
    <List>
      {files.map((file, index) => (
        <ListItem key={index}>
          {file.id ? (
            <Content
              onClick={() => {
                let { rfs_number } = { ...form.rfsInformation };
                rfs_number = (rfs_number || '').replace(/[^a-zA-Z0-9]/g, '');
                downloadFile(file.name, rfs_number.toLowerCase());
              }}
            >
              {file.name}{' '}
            </Content>
          ) : (
            <Content onClick={() => downloadLocalFile(URL.createObjectURL(file), file.name)}>
              {file.name}
            </Content>
          )}
          {!readOnly && (
            <CloseButtonStyle
              aria-label='Close modal'
              color='red'
              onClick={() => {
                if (onRemove) {
                  onRemove(index);
                } else {
                  handleDelete(index);
                }
              }}
            />
          )}
        </ListItem>
      ))}
    </List>
  ) : (
    <CustomText color={error ? 'red' : ''}> No Files Attached </CustomText>
  );
};
