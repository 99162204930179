import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { Text, Badge, Button, Checkbox } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import ProductionLog from '../../productDevelopment/productionSiteLogModal';
import { ActionModal } from '../../productDevelopment/actionModal';

export function StandardPricingTable({ pricingData, save, disable }) {
  const [filterLoading, setFilterLoading] = useState(false);
  const [imp_id, setImp_id] = useState(0);
  const data = pricingData;
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [checked, setChecked] = useState(false);
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [status, setStatus] = useState([]);

  function getLogs(imp_id) {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Plant',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_ps_sku_reference.selected_plant}</Text>;
        },
      },
      {
        Header: 'Mother SKU',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.mother_sku.length > 0 ? row.original.mother_sku.join(',') : 'N/A'}
            </Text>
          );
        },
      },
      {
        Header: 'BOM Availability',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.other_status['BOM Availability'] === 'In Progress' &&
                !row.original.finished_date &&
                moment(new Date()).format('YYYY-MM-DD') <=
                  moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'yellow'
                  : (row.original.other_status['BOM Availability'] === 'In Progress' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.other_status['BOM Availability'] === 'In Progress' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : row.original.other_status['BOM Availability'] === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') <=
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'green'
                  : row.original.other_status['BOM Availability'] === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'red'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.other_status['BOM Availability']}
            </Badge>
          );
        },
      },
      {
        Header: 'Production Version',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.other_status['Production Version'] === 'In Progress' &&
                !row.original.finished_date &&
                moment(new Date()).format('YYYY-MM-DD') <=
                  moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'yellow'
                  : (row.original.other_status['Production Version'] === 'In Progress' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.other_status['Production Version'] === 'In Progress' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : row.original.other_status['Production Version'] === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') <=
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'green'
                  : row.original.other_status['Production Version'] === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'red'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.other_status['Production Version']}
            </Badge>
          );
        },
      },
      {
        Header: 'Material At Status 99',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.other_status['Material At Status 99'] === 'In Progress' &&
                !row.original.finished_date &&
                moment(new Date()).format('YYYY-MM-DD') <=
                  moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'yellow'
                  : (row.original.other_status['Material At Status 99'] === 'In Progress' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.other_status['Material At Status 99'] === 'In Progress' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : row.original.other_status['Material At Status 99'] === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') <=
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'green'
                  : row.original.other_status['Material At Status 99'] === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'red'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.other_status['Material At Status 99']}
            </Badge>
          );
        },
      },
      {
        Header: 'Cost Collector',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.other_status['Cost Collector'] === 'In Progress' &&
                !row.original.finished_date &&
                moment(new Date()).format('YYYY-MM-DD') <=
                  moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'yellow'
                  : (row.original.other_status['Cost Collector'] === 'In Progress' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.other_status['Cost Collector'] === 'In Progress' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : row.original.other_status['Cost Collector'] === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') <=
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'green'
                  : row.original.other_status['Cost Collector'] === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'red'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.other_status['Cost Collector']}
            </Badge>
          );
        },
      },
      {
        Header: 'Procurement Price',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.other_status['Procurement Price'] === 'In Progress' &&
                !row.original.finished_date &&
                moment(new Date()).format('YYYY-MM-DD') <=
                  moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'yellow'
                  : (row.original.other_status['Procurement Price'] === 'In Progress' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.other_status['Procurement Price'] === 'In Progress' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : row.original.other_status['Procurement Price'] === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') <=
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'green'
                  : row.original.other_status['Procurement Price'] === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'red'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.other_status['Procurement Price']}
            </Badge>
          );
        },
      },
      {
        Header: 'Repack Fee',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.other_status['Repacking Fee'] === 'In Progress' &&
                !row.original.finished_date &&
                moment(new Date()).format('YYYY-MM-DD') <=
                  moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'yellow'
                  : (row.original.other_status['Repacking Fee'] === 'In Progress' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.other_status['Repacking Fee'] === 'In Progress' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : (row.original.other_status['Repacking Fee'] === 'Closed' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') <=
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.other_status['Repacking Fee'] === 'Closed' &&
                      moment(new Date()).format('YYYY-MM-DD') <=
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'green'
                  : (row.original.other_status['Repacking Fee'] === 'Closed' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.other_status['Repacking Fee'] === 'Closed' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.other_status['Repacking Fee']}
            </Badge>
          );
        },
      },
      {
        Header: 'Action',
        Cell: ({ row }) => {
          return row.original.status === 'In Progress' || row.original.status === 'Not Started' ? (
            <Checkbox
              checked={checked}
              disabled={row.original.status != 'In Progress' || disable}
              onChange={(event) => {
                setChecked(event.target.checked);
                setStatus(row.original.status_ids);
                setActionModal({ open: true, id: row.original.id });
              }}
              label='Confirm and Close'
            />
          ) : row.original.manually_closed && row.original.status === 'Closed' ? (
            <Text>Manual Closure</Text>
          ) : (
            <Text>Automatic Closure</Text>
          );
        },
      },
      {
        Header: 'Logs',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => getLogs(row.original.status_ids.join('_'))}
              style={{background:'black',color:'white'}}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('YYYY-MM-DD')
                : row.original.status}
            </Text>
          );
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            {filterLoading && (
              <tr>
                <td colSpan={13}>
                  <div className='loader-container'>
                    <AppLoader size={'md'} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={status}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Standard Pricing'
        />
      )}
    </Styles>
  );
}
