import React from 'react';
import { Modal, Button, Grid, Group, Text } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';

export const ChangeModal = ({ changeModal, setChangeModal, confirm }) => {
  const { newValue, fieldName, identifier } = changeModal;

  const handleClose = () => {
    setChangeModal({ open: false });
  };

  return (
    <>
      <Modal
        className='confirm-modal'
        opened={changeModal.open}
        onClose={() => handleClose()}
        title='Warning'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='invalid-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <Text size='sm'>
                Are you sure you want to make this change. All the changes made till now will be
                lost.
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={3} offset={9}>
            <Button
              variant='gradient'
              onClick={() => {
                confirm(newValue, identifier, fieldName, 'overright');
                handleClose();
              }}
              fullWidth
            >
              Confirm
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
