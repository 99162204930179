import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Group,
  UnstyledButton,
  Text,
  Input,
  Container,
  Avatar,
  Tabs,
  Grid,
  Checkbox,
} from '@mantine/core';
import { MagnifyingGlassIcon } from '@modulz/radix-icons';
import { CircleCheck } from 'tabler-icons-react';

import '../../../components/modal/modal.css';
import { axios } from '../../../utils/axios';
import { AppLoader } from '../../../components/common/loader';

function MarketModal({
  rfsNumber,
  title,
  scope_id,
  sku_id,
  selectedMarkets,
  setSelectedMarkets,
  variant = 'multiSelect',
  error = false,
}) {
  const [opened, setOpened] = useState(false);
  const [searchStr, setSearchStr] = useState(null);
  const [markets, setMarkets] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (opened) {
      setSearchStr(null);
      setSelected(selectedMarkets ? [...selectedMarkets] : []);
    }
  }, [opened]);

  useEffect(() => {
    if (sku_id && opened) {
      setLoading(true);
      axios.get(`delisting/markets/${sku_id}`).then(
        (res) => {
          setMarkets(res.data.markets);
        },
        (error) => {
          console.log(error);
        },
      );

      axios
        .get(`delisting/selectedMarkets/${rfsNumber}/${scope_id}`)
        .then(
          (res) => {
            setSelected(res.data.markets);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoading(false));
    }
  }, [sku_id, opened]);

  const handleSelectAll = () => {
    if (selected.length > 0 && selected.length === markets.length) {
      setSelected([]);
    } else {
      setSelected([...markets]);
    }
  };

  const updateMarkets = (payload) => {
    return axios
      .put(`delisting/selectedMarkets/${rfsNumber}/${scope_id}`, payload)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  const isAllSelected = selected.length === markets.length;

  const toggleMarket = (market) => {
    const index = selected.findIndex((val) => val.market_name === market.market_name);

    let updatedSelectedMarkets;
    if (index > -1) {
      updatedSelectedMarkets = [...selected];
      updatedSelectedMarkets.splice(index, 1);
    } else {
      updatedSelectedMarkets = [...selected, market];
    }

    setSelected(updatedSelectedMarkets);
  };

  const confirmed = () => {
    setOpened(false);
    setSelectedMarkets(selected, isAllSelected);
    updateMarkets(selected);
  };

  const modalClosed = () => {
    setOpened(false);
  };

  const ModalOpened = () => {
    setOpened(true);
  };

  return (
    <div>
      <Modal opened={opened} onClose={() => modalClosed()} title={title} className='users-modal'>
        {loading ? (
          <AppLoader center size='md' />
        ) : (
          <Container>
            <Grid>
              <Grid.Col span={12}>
                <Input
                  className='search'
                  radius='md'
                  onChange={(event) => setSearchStr(event.target.value)}
                  value={searchStr}
                  icon={<MagnifyingGlassIcon />}
                  style={{ margin: '0 4rem' }}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <Tabs style={{ marginTop: 10 }} defaultValue={'All'}>
                  <Tabs.List>
                    <Tabs.Tab value='All' color='primary'>
                      All
                    </Tabs.Tab>
                    <Tabs.Tab value='Selected' color='primary'>
                      Selected
                    </Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panel value='All' color='primary'>
                    <Markets
                      markets={markets}
                      toggleMarket={toggleMarket}
                      type='all'
                      selected={selected}
                      variant={variant}
                      searchStr={searchStr}
                    />
                  </Tabs.Panel>
                  <Tabs.Panel value='Selected' color='primary'>
                    <Markets
                      markets={markets}
                      toggleMarket={toggleMarket}
                      type='selected'
                      selected={selected}
                      variant={variant}
                      searchStr={searchStr}
                    />
                  </Tabs.Panel>
                </Tabs>
                <Checkbox
                  label='Select all Markets (Full Delist)'
                  className='users-modal'
                  color='orange'
                  name='Select all Markets'
                  value={isAllSelected}
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  style={{
                    position: 'absolute',
                    bottom: '1rem',
                    left: '1rem',
                    padding: '0.5rem',
                  }}
                />
              </Grid.Col>

              <Grid.Col span={3} offset={9}>
                <Button
                  fullWidth
                  style={{
                    marginTop: '1rem',
                  }}
                  variant='gradient'
                  gradient={{ from: '#e3af32', to: '#f4e00f' }}
                  onClick={() => confirmed()}
                  color='primary'
                  className='modal-button'
                >
                  Confirm
                </Button>
              </Grid.Col>
            </Grid>
          </Container>
        )}
      </Modal>

      <Group position='center'>
        <UnstyledButton onClick={() => ModalOpened()}>
          <Text
            style={{
              textDecoration: 'underline',
            }}
            color={error ? 'red !important' : '#E5B611'}
            className='modalText'
          >
            {title}
          </Text>
        </UnstyledButton>
      </Group>
    </div>
  );
}

const Markets = ({ markets, toggleMarket, type, selected, searchStr }) => {
  return (
    <Grid.Col span={12}>
      <Container style={{ height: '18rem', overflowY: 'auto' }}>
        {markets && markets.length > 0 ? (
          markets
            .filter(
              (market) =>
                !searchStr ||
                market.market_label.toLowerCase().includes(searchStr.toLowerCase()) ||
                market.market_name.toLowerCase().includes(searchStr.toLowerCase()),
            )
            .map((item, index) =>
              (type === 'selected' &&
                selected.findIndex((m) => m.market_name === item.market_name) > -1) ||
              type === 'all' ? (
                <UnstyledButton
                  style={{ display: 'inherit', width: '100%' }}
                  onClick={(event) => toggleMarket(item, event)}
                  color=''
                >
                  <Group
                    style={{
                      marginTop: '1rem',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Group>
                      <Avatar color='dark' radius='xl'>
                        {index + 1}
                      </Avatar>
                      <div>
                        <Text>{item?.market_label}</Text>
                        <Text size='sm' color='dimmed' weight={400}>
                          {item?.market_name}
                        </Text>
                      </div>
                    </Group>
                    {(selected || []).findIndex((m) => m.market_name === item.market_name) > -1 && (
                      <CircleCheck color='green' size='1.5rem' />
                    )}
                  </Group>
                </UnstyledButton>
              ) : null,
            )
        ) : (
          <div style={{ marginTop: '1rem' }}>No results Found</div>
        )}
      </Container>
    </Grid.Col>
  );
};

export default MarketModal;
