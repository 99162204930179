import styled from 'styled-components';

const Styles = styled.div`
  .table {
    tr,
    td {
      z-index: 1 !important;
      background-color: #ffffff;
    }
    tbody {
      td {
        padding-left: 20px;
      }
    }

    td {
      min-width: 35px;
    }

    &.sticky {
      overflow: auto;

      thead {
        position: relative;
        z-index: 0;
      }

      thead {
        top: 0;
        font-weight: bold;
      }

      .tbody {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky !important;
        z-index: 9 !important;
        background: #e0e0e0 !important;
        left: 0;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }

  #fname {
    width: 100%;
    max-height: 2rem;
    font-size: 0.8rem;
    padding: 1rem 1rem;
  }

  input[type='checkbox'] {
    color: #fff;
    margintop: 1rem;
  }

  span[role='columnheader'] {
    display: flex;
    box-shadow: none;
    align-items: center;
    justify-content: space-between;
  }

  #view-bom-column {
    padding-left: 1.5rem;
    margin-bottom: 2.2rem;
  }

  .my-selections input[type='checkbox']:checked {
    border-color: none;
  }

  .my-selections .mantine-Switch-label {
    font-weight: 600;
  }
`;

const TableButtonsConatiner = styled.div`
  position: relative;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableRightButtons = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  .sort-icon {
    color: white;
    background-color: black ;
    border-radius: 0.15rem:
    margin: 1rem;
    height: 0.8rem;
    width: 0.8rem;
  }
`;

const PaginationContainer = styled.div`
  position: relative;
  height: 50px;
`;

export { Styles, TableButtonsConatiner, TableRightButtons, TableContainer, PaginationContainer };
