import * as yup from 'yup';

export const ObsoletesMrpSchema = yup.object().shape({
  aligned_with_obsoletes: yup.boolean().required('aligned_with_obsoletes'),
  attachment: yup
    .mixed()
    .nullable()
    .when('aligned_with_obsoletes', {
      is: false,
      then: () => yup.mixed().required('attachment'),
    }),
  mrp_comments: yup
    .string()
    .max(100, 'mrp_comments')
    .nullable()
    .when('aligned_with_obsoletes', {
      is: false,
      then: () => yup.string().required('mrp_comments'),
    }),
});
