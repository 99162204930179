import React, { useState, useEffect, useContext } from 'react';
import { Tabs } from '@mantine/core';

import ContentPaper from '../../../../components/layouts/rfs/Content';

import BlockingVEAM from './blocking/blocking';
import StatusReversalVEAM from './statusReversal/statusReversal';
import ChangeOverRemovalVEAM from './changeOverRemoval/changeOverRemoval';

import Dot from '../Dot';
import { VeamContext } from '../../veamContext';
import { tabStyles } from '../../../../utils/helpers';

export default function ReversalVEAM({ slaWeeksIds, readOnly, rfs_number }) {
  const [activeTab, setActiveTab] = useState(
    slaWeeksIds.includes(254)
      ? 'bnmc'
      : slaWeeksIds.includes(252)
      ? 'sr'
      : slaWeeksIds.includes(253) || slaWeeksIds.includes(255)
      ? 'cr'
      : '',
  );
  const [tabStatus, setTabStatus] = useState({});
  const { fetchAllSteps } = useContext(VeamContext);

  const checkClosuresBlocking = (d) => setTabStatus({ ...tabStatus, blockingRev: d });
  const checkClosuresStatusRev = (d) => setTabStatus({ ...tabStatus, stausRev: d });
  const checkClosuresChangeOverRem = (d) => setTabStatus({ ...tabStatus, changeOverRemRev: d });

  useEffect(() => {
    fetchAllStepsFn(rfs_number);
  }, [rfs_number]);

  const fetchAllStepsFn = (rfs_number) => {
    fetchAllSteps(rfs_number).then((res) => {
      let obj = {};
      obj['blockingRev'] = res.data.data.trialReversal.blockingRev.difference;
      obj['stausRev'] = res.data.data.trialReversal.stausRev.difference;
      obj['changeOverRemRev'] = res.data.data.trialReversal.changeOverRemRev.difference;
      setTabStatus(obj);
    });
  };

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <Tabs
        value={activeTab}
        onTabChange={setActiveTab}
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='primary'
        keepMounted={false}
      >
        <Tabs.List>
          {slaWeeksIds.includes(254) && (
            <Tabs.Tab className='topTabs' value='bnmc'>
              Blocking of New Material Code <Dot dotStatus={tabStatus.blockingRev} />
            </Tabs.Tab>
          )}
          {slaWeeksIds.includes(252) && (
            <Tabs.Tab className='topTabs' value='sr'>
              Status Reversal <Dot dotStatus={tabStatus.stausRev} />
            </Tabs.Tab>
          )}
          {(slaWeeksIds.includes(253) || slaWeeksIds.includes(255)) && (
            <Tabs.Tab className='topTabs' value='cr'>
              Changeover Removal <Dot dotStatus={tabStatus.changeOverRemRev} />
            </Tabs.Tab>
          )}
        </Tabs.List>

        {slaWeeksIds.includes(254) && (
          <Tabs.Panel className='topTabs' value='bnmc'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                marginRight: '4rem',
              }}
            ></div>
            <BlockingVEAM readOnly={readOnly} checkClosuresBlocking={checkClosuresBlocking} />
          </Tabs.Panel>
        )}

        {slaWeeksIds.includes(252) && (
          <Tabs.Panel className='topTabs' value='sr'>
            <StatusReversalVEAM
              readOnly={readOnly}
              checkClosuresStatusRev={checkClosuresStatusRev}
            />
          </Tabs.Panel>
        )}

        {(slaWeeksIds.includes(253) || slaWeeksIds.includes(255)) && (
          <Tabs.Panel className='topTabs' value='cr'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                marginRight: '4rem',
              }}
            ></div>
            <ChangeOverRemovalVEAM
              readOnly={readOnly}
              checkClosuresChangeOverRem={checkClosuresChangeOverRem}
            />
          </Tabs.Panel>
        )}
      </Tabs>

      {/* <ProductionLog
        logModal={logModal}
        setLogModal={setLogModal}
        type='Blocking of New Material Code'
      />

      {manualClosureModal.open && (
        <ManualClosureModal
          manualClosureModal={manualClosureModal}
          setManualClosureModal={setManualClosureModal}
        />
      )} */}
    </ContentPaper>
  );
}
