import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, Select } from '@mantine/core';
import { ChangeModal } from '../../../modal/changeModal';
import { RfsContext } from '../../../../contexts/RfsContext';
import { ProductionTable } from './table';
import { BomTable } from './bom_table.js';
import { DuplicatePlantModal } from '../../../modal/DuplicatePlantModal';

const skuModel = {
  id: null,
  scope_id: null,
  active: true,
  selected_plant: null,
  reference_plant: null,
  bom_action: null,
  reference_alt_bom: null,
  production: null,
  mother_product_date_formatting: null,
  in_status: null,
  comment: null,
  boms: [],
  repacking: [],
};

export function ProductionSite({ scope, isSubmissionReview = false, errors, setBomErrors }) {
  const [duplicatePlantModal, setDuplicatePlantModal] = useState({
    open: false,
    callback: null,
  });
  const [changeModal, setChangeModal] = useState({
    open: false,
  });
  const [bomModal, setBomModal] = useState({
    open: false,
    scope_id: null,
    sku_id: null,
    editable: false,
    selection_number: null,
  });
  const {
    form,
    productionPlants,
    fetchProductionPlants,
    setForm,
    fetchReferencePlants,
    fetchAltBoms,
    fetchBoms,
    referencePlantsAndBoms,
    bomMaterials,
    fetchBomMaterials,
    bomMaterialsLoading,
    fetchRepackingMaterials,
    repackingOnlineMaterials,
  } = useContext(RfsContext);
  const [referencePlants, setReferencePlants] = useState({});
  const [repackingMaterials, setRepackingMaterials] = useState({});
  const [onlineMaterials, setOnlineMaterials] = useState({});

  const referencePlantsRef = useRef(null);
  referencePlantsRef.current = referencePlants;

  const formRef = useRef(null);
  formRef.current = form;

  useEffect(() => {
    if (productionPlants.length === 0) fetchProductionPlants();
  }, []);

  useEffect(() => {
    if (Object.keys(referencePlantsAndBoms).length > 0) {
      setReferencePlants(referencePlantsAndBoms);
    }

    if (Object.keys(repackingOnlineMaterials).length > 0) {
      setRepackingMaterials(repackingOnlineMaterials);
      setOnlineMaterials(repackingOnlineMaterials);
    }
  }, [referencePlantsAndBoms, repackingOnlineMaterials]);

  const handlePlantChange = (event, allowedDuplicate = false) => {
    const currentValues = { ...form };
    const skuLength = Object.keys(currentValues.scopeSelectedSkus).length;
    const plantList = Object.values(currentValues.scopeSelectedSkus);

    if (!allowedDuplicate) {
      const plantPresent = plantList.find(
        (item) => item.selected_plant === event && item.scope_id === scope.id,
      );

      if (plantPresent) {
        setDuplicatePlantModal({
          open: true,
          callback: () => {
            handlePlantChange(event, true);
            setDuplicatePlantModal({
              open: false,
              callback: null,
            });
          },
        });
        return;
      }
    }

    getReferencePlants(scope.selection_number, event);
    currentValues.scopeSelectedSkus = {
      ...currentValues.scopeSelectedSkus,
      [`${scope.id}_${skuLength}`]: {
        ...skuModel,
        id: `${scope.id}_${skuLength}`,
        selected_plant: event,
        scope_id: scope.id,
      },
    };

    setForm(currentValues);
  };

  const getReferencePlants = async (skuCode, plant) => {
    fetchReferencePlants(skuCode).then((res) => {
      const currentPlants = { ...referencePlantsRef.current };
      currentPlants[skuCode] = {
        ...currentPlants[skuCode],
        [plant]: {
          plants: [...res.data.data.plants.map((p) => p.plant)],
          altBoms: [],
        },
      };

      setReferencePlants(currentPlants);
    });
  };

  const getAltBoms = async (skuCode, plant, referencePlant) => {
    fetchAltBoms(skuCode, referencePlant).then((res) => {
      const currentPlants = { ...referencePlantsRef.current };
      currentPlants[skuCode] = {
        ...currentPlants[skuCode],
        [plant]: {
          ...currentPlants[skuCode][plant],
          altBoms: res.data.data.boms.map((bom) => bom.alternative_bom),
        },
      };

      setReferencePlants(currentPlants);
    });
  };

  const handleChange = (newValue, identifier, fieldName, oldValue) => {
    if (oldValue && oldValue !== newValue && oldValue !== 'overright') {
      setChangeModal({ open: true, newValue, fieldName, identifier });
      return;
    }
    const currentValues = { ...formRef.current };
    const selectedValue = { ...currentValues.scopeSelectedSkus[identifier] };
    if (currentValues.scopeSelectedSkus[identifier]) {
      currentValues.scopeSelectedSkus[identifier][fieldName] = newValue;
    } else {
      currentValues.scopeSelectedSkus[`${scope.id}_${identifier}`][fieldName] = newValue;
    }

    if (fieldName === 'bom_action' && currentValues.scopeSelectedSkus[identifier].boms) {
      currentValues.scopeSelectedSkus[identifier].boms = currentValues.scopeSelectedSkus[
        identifier
      ].boms.map((bom) => {
        return {
          ...bom,
          active: newValue === 'Existing BOM' ? true : false,
          material_list: null,
          technical_pack_change: null,
          technical_quality_change: null,
          artwork_change: null,
          comment: null,
        };
      });
    }

    if (oldValue === 'overright') {
      currentValues.scopeSelectedSkus[identifier]['boms'] = [];

      if (fieldName === 'reference_plant') {
        currentValues.scopeSelectedSkus[identifier]['bom_action'] = null;
        currentValues.scopeSelectedSkus[identifier]['reference_alt_bom'] = null;
      }
    }
    if (fieldName === 'reference_plant') {
      getAltBoms(
        scope.selection_number,
        currentValues.scopeSelectedSkus[identifier]['selected_plant'],
        newValue,
      );
    }

    if (fieldName === 'reference_alt_bom') {
      getBomMaterials(
        scope.selection_number,
        selectedValue.reference_plant,
        newValue,
        selectedValue.bom_action !== 'Existing BOM',
        selectedValue.scope_id,
        selectedValue.id,
      );
    }

    if (fieldName === 'production') {
      getRepackingMaterials(
        newValue,
        selectedValue.selected_plant,
        selectedValue.scope_id,
        selectedValue.id,
        scope.selection_number,
        scope.selection_name,
      );
    }

    setForm(currentValues);
  };

  const getBomMaterials = (
    selection_number,
    reference_plant,
    reference_alt_bom,
    editable,
    scope_id,
    sku_id,
  ) => {
    const id = form.scopeSelectedSkus[`${selection_number}_${sku_id}`]
      ? `${scope_id}_${sku_id}`
      : sku_id;
    fetchBoms(selection_number, reference_plant, reference_alt_bom).then(
      (res) => {
        const currentValues = { ...form };
        const boms = editable
          ? res.data.data.boms
          : res.data.data.boms.map((b) => {
              return {
                ...b,
                active: true,
              };
            });
        currentValues.scopeSelectedSkus[id]['boms'] = boms;
        setForm(currentValues);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const getRepackingMaterials = (
    type,
    plant,
    scope_id,
    sku_id,
    selection_number,
    selection_name,
  ) => {
    const id = form.scopeSelectedSkus[`${selection_number}_${sku_id}`]
      ? `${scope_id}_${sku_id}`
      : sku_id;
    fetchRepackingMaterials(type, plant).then(
      (res) => {
        if (type == 'Repacking') {
          const currentMaterials = { ...repackingMaterials };
          const repacking_material = res.data.data.recordset.map((obj) => {
            return {
              label: `${obj.material} - ${obj.material_text}`,
              value: obj.material,
            };
          });
          currentMaterials[selection_number] = {
            ...currentMaterials[selection_number],
            [plant]: {
              material: repacking_material,
            },
          };
          setRepackingMaterials(currentMaterials);
        } else {
          const currentMaterials = { ...onlineMaterials };
          let online_material = res.data.data.recordset.map((obj) => {
            return {
              label: `${obj.material} - ${obj.material_text}`,
              value: obj.material,
            };
          });
          let online_list = [...online_material];
          // online_list.unshift({ label: 'Others', value: 'Others' });

          currentMaterials[selection_number] = {
            ...currentMaterials[selection_number],
            [plant]: {
              material: online_list,
            },
          };

          setOnlineMaterials(currentMaterials);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  return (
    <div>
      {!isSubmissionReview && (
        <div className='production-select-plant'>
          <Select
            required
            label='Select Plants'
            placeholder='Select plants (multi select)'
            data={productionPlants}
            className='production-select-plant'
            searchable
            onChange={handlePlantChange}
          />
        </div>
      )}
      <ProductionTable
        data={Object.values(form.scopeSelectedSkus).filter((item) => item.scope_id === scope.id)}
        referencePlants={referencePlants}
        repackingMaterials={repackingMaterials}
        onlineMaterials={onlineMaterials}
        skuCode={scope.selection_number}
        onChange={handleChange}
        getAltBoms={getAltBoms}
        setBomModal={setBomModal}
        errors={errors}
        isSubmissionReview={isSubmissionReview}
      />
      {duplicatePlantModal.open && (
        <DuplicatePlantModal
          duplicatePlantModal={duplicatePlantModal}
          setDuplicatePlantModal={setDuplicatePlantModal}
        />
      )}
      {changeModal.open && (
        <ChangeModal
          changeModal={changeModal}
          setChangeModal={setChangeModal}
          confirm={handleChange}
        />
      )}
      {bomModal.open && (
        <Modal
          size='70%'
          opened={bomModal.open}
          onClose={() => {
            setBomModal({
              open: false,
              scope_id: null,
              sku_id: null,
              editable: false,
              selection_number: null,
            });
          }}
        >
          <BomTable
            bomModal={bomModal}
            scopeSelectedSkus={form.scopeSelectedSkus}
            form={form}
            setForm={setForm}
            errors={errors}
            isSubmissionReview={isSubmissionReview}
            bomMaterials={bomMaterials}
            bomMaterialsLoading={bomMaterialsLoading}
            setBomModal={setBomModal}
            setBomErrors={setBomErrors}
          />{' '}
        </Modal>
      )}
    </div>
  );
}
