import React, { useState, useEffect } from 'react';
import { Tabs } from '@mantine/core';
import { axios } from '../../../utils/axios';
import { tabStyles } from '../../../utils/helpers';
import RfsLayout from '../../../components/layouts/RfsLayout';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { RfsTable } from './rfsTable';
import moment from 'moment';

const tabTypes = {
  all: 'all',
  backup: 'backup',
  my: 'my',
};

export const Home = ({ type = 'all' }) => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(tabTypes[type]);
  const [nestedTab, setNestedTab] = useState(0);
  const [allRfs, setAllRfs] = useState([]);

  const fetchRfs = async (tabType = 'all') => {
    setLoading(true);
    try {
      const endpoint =
        tabType === 'my' ? '/costing-request/my-costing-request' : '/costing-request';
      const response = await axios.get(endpoint);
      const transformedData = response.data.map((record) => {
        const allSteps = record.IntCrStepStatuses.map((step) => ({
          stepName: step.IntCrSteps[0]?.name || '',
          costing_request_assigned_to: step.costing_ticket_assigned?.name || '',
          stepStatus: step.status || '',
        }));

        const inProgressSteps = [
          ...new Set(
            allSteps
              .filter((step) => step.stepStatus.toLowerCase() === 'in progress')
              .map((step) => step.stepName),
          ),
        ].join(', ');

        const uniqueUserNames = [
          ...new Set(allSteps.map((step) => step.costing_request_assigned_to)),
        ].join(', ');

        const uniqueStepStatuses = [...new Set(allSteps.map((step) => step.stepStatus))].join(', ');
        const status = record.IntCrStatus?.status || '';

        return {
          costing_request_id: record.costing_request_id,
          urgency: record.urgency,
          rfs_number: record.rfs_number,
          fsd_date: moment(record.fsd_date).isValid()
            ? moment(record.fsd_date).format('DD/MM/YYYY')
            : 'N/A',
          type_of_request: record.type_of_request,
          producing_plant: record.producing_plant_data
            ? [...new Set((record.producing_plant_data || []).map((res) => res.producing_plant))].join(',')
            : '',
          selling_country: record.selling_plant_data
            ? [...new Set((record.selling_plant_data || []).map((res) => res.selling_country))].join(',')
            : '',
          buying_country: record.buying_plant_data
            ? [...new Set ((record.buying_plant_data || []).map((res) => res.buying_country))].join(',')
            : '',
          sku: record.sku_data ? (record.sku_data || []).map((res) => res.sku).join(',') : '',
          sku_description: record.sku_data
            ? (record.sku_data || []).map((res) => res.sku_description).join(',')
            : '',
          status: record.IntCrStatus ? record.IntCrStatus.status : '',
          steps_in_progress: inProgressSteps,
          spoc_pricing_team: uniqueUserNames,
          unique_step_statuses: uniqueStepStatuses,
          userName: record.user?.name || 'N/A',
          date : status === 'Draft'
          ? 'Not submitted yet'
          : moment(record.IntCrStatus?.createdAt).isValid()
          ? moment(record.IntCrStatus.createdAt).format('DD/MM/YYYY')
          : 'N/A'
        
        };
      });
      setAllRfs(transformedData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRfs(activeTab);
  }, [activeTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleNestedTabChange = (nestedTab) => {
    setNestedTab(nestedTab);
    fetchRfs();
  };

  return (
    <RfsLayout>
      <ContentPaper className='mainpage' page={'all-rfs'}>
        <Tabs
          variant='unstyled'
          styles={tabStyles}
          className='all-rfs-tabs'
          value={activeTab}
          onTabChange={handleTabChange}
          defaultValue='all'
          keepMounted={false}
        >
          <Tabs.List>
            <Tabs.Tab value='all'>ALL PROJECTS</Tabs.Tab>
            <Tabs.Tab value='my'>MY PROJECTS</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='all'>
            <Tabs
              className='in-progress-tabs'
              active={nestedTab}
              onTabChange={handleNestedTabChange}
              color='dark'
              defaultValue='all'
              keepMounted={false}
            >
              <Tabs.List>
                <Tabs.Tab className='subTab' value='all' style={{ color: '#000' }}>
                  In Progress
                </Tabs.Tab>
                <Tabs.Tab className='subTab' value='allImplemented' style={{ color: 'black' }}>
                  Fully Implemented
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value='all'>
                <RfsTable
                  loading={loading}
                  data={allRfs}
                  csvFilename={`all_projects_inprogress_${moment().format('YYYY-MM-DD HH:mm:ss')}`}
                />
              </Tabs.Panel>
              <Tabs.Panel value='allImplemented'>
                <RfsTable
                  loading={loading}
                  data={allRfs}
                  csvFilename={`all_projects_implemented_${moment().format('YYYY-MM-DD HH:mm:ss')}`}
                />
              </Tabs.Panel>
            </Tabs>
          </Tabs.Panel>

          <Tabs.Panel value='my'>
            <Tabs
              className='in-progress-tabs'
              active={nestedTab}
              onTabChange={handleNestedTabChange}
              color='dark'
              defaultValue='my'
              keepMounted={false}
            >
              <Tabs.List>
                <Tabs.Tab className='subTab' value='my' style={{ color: '#000' }}>
                  In Progress
                </Tabs.Tab>
                <Tabs.Tab className='subTab' value='fullyImplemented' style={{ color: 'black' }}>
                  Fully Implemented
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value='my'>
                <RfsTable
                  loading={loading}
                  data={allRfs}
                  csvFilename={`my_projects_inprogress_${moment().format('YYYY-MM-DD HH:mm:ss')}`}
                />
              </Tabs.Panel>
              <Tabs.Panel value='fullyImplemented'>
                <div className='table-container'>
                  <RfsTable
                    loading={loading}
                    data={allRfs}
                    csvFilename={`my_projects_implemented_${moment().format(
                      'YYYY-MM-DD HH:mm:ss',
                    )}`}
                  />
                </div>
              </Tabs.Panel>
            </Tabs>
          </Tabs.Panel>
        </Tabs>
      </ContentPaper>
    </RfsLayout>
  );
};
