import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  Modal,
  Button,
  Group,
  UnstyledButton,
  Text,
  Input,
  Container,
  Tabs,
  Grid,
  Checkbox,
} from '@mantine/core';
import { MagnifyingGlassIcon } from '@modulz/radix-icons';
import { CircleCheck } from 'tabler-icons-react';
import { axios } from '../../utils/axios';
import './accf.css';

function ModalAccf({
  title,
  type,
  selected = [],
  handleSelect,
  variant = 'multiSelect',
  submitted = false,
  disabled = false,
}) {
  const [opened, setOpened] = useState(false);
  const [foundData, setFoundData] = useState([]);
  const [searchStr, setSearchStr] = useState(null);

  const [selectedData_1, setSelectedData_1] = useState([]);

  const [dataAtFirst, setDataAtFirst] = useState([]);

  const [allMarkets, setAllMarkets] = useState([]);
  const [allPlants, setAllPlants] = useState([]);

  const [checkeds, setCheckeds] = useState({ plants: false, markets: false });

  //-----------Useeffects----====//
  useEffect(() => {
    fetchAllPlants();
    fetchAllMarkets();
  }, []);

  //Search function
  useEffect(() => {
    if (opened) setSearchStr(null);
  }, [opened]);

  //Fetch Plants and Markets Data
  useEffect(() => {
    if (foundData.length === 0 && type === 'plants') setFoundData(allPlants);
    else if (foundData.length === 0 && type === 'markets') {
      const newMarkets = allMarkets.map((item) => item.market_region + ' - ' + item.market);
      setFoundData(newMarkets);
    }

    setDataAtFirst(selected);
  }, [allPlants, allMarkets]);

  //after submission change selected data
  useEffect(() => {
    setDataAtFirst(selected);
  }, [submitted]);

  //Fix positions of the selected options on modal
  useEffect(() => {
    scrollGoto();
  }, [selected]);
  //Useeffects-----

  //------------Functions---------//

  //Fetch Markets
  const fetchAllMarkets = () => {
    axios.get(`/rfs/fetchAllMarkets/`).then(
      (res) => {
        if (res.data.data) setAllMarkets(res.data.data);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  // Fetch plants
  const fetchAllPlants = () => {
    axios.get(`/rfs/fetchAllPlants/`).then(
      (res) => {
        if (res.data.data)
          setAllPlants(res.data.data.map((item) => item.plant + ' - ' + item.plant_text));
      },
      (error) => {
        console.log(error);
      },
    );
  };

  //Selection function
  const updateSelected = (data, type) => {
    let selectedData = selected ? [...selected] : [];

    const index = (selectedData || []).findIndex((val) => val === data);

    if (variant !== 'multiSelect') {
      selectedData = index < 0 ? [data] : [];
    } else {
      if (index > -1) {
        selectedData.splice(index, 1);
        setCheckeds({ ...checkeds, [type]: false });
      } else {
        selectedData.push(data);
      }
    }

    handleSelect(type, selectedData);
    setSelectedData_1(selectedData);
  };

  //On confirmation
  const confirmed = () => {
    setOpened(false);
    handleSelect(type, selectedData_1);
  };

  const ModalClosed = () => {
    handleSelect(type, dataAtFirst);
    setOpened(false);
  };

  const ModalOpened = () => {
    setOpened(true);
  };

  //Select All Function
  const selectAll = (type, event) => {
    let data = [];

    if (event.target.checked) {
      if (type === 'plants') data = allPlants;
      else data = allMarkets.map((item) => item.market_region + ' - ' + item.market);
      setCheckeds({ ...checkeds, [type]: true });
    } else setCheckeds({ ...checkeds, [type]: false });

    handleSelect(type, data);
    setSelectedData_1(data);
  };

  //Get the scroll position of an element
  const getScrollPositionDiv = (event, crtrgt) => {
    createScrollStopListener(event.target, () => {
      let scrollValues = {
        scrollVal: crtrgt,
      };
      localStorage.setItem('scrollValuesA', JSON.stringify(scrollValues));
    });
  };

  //Trigger scroll
  const scrollGoto = () => {
    let scrollValues = localStorage.getItem('scrollValuesA')
      ? JSON.parse(localStorage.getItem('scrollValuesA'))
      : '';
    const { scrollVal } = scrollValues;
    if (document.querySelector('.as')) document.querySelector('.as').scrollTo(0, scrollVal);
  };

  //Scroll function - after completing a scroll
  const createScrollStopListener = (element, callback) => {
    var handle = null;
    var onScroll = function () {
      if (handle) {
        clearTimeout(handle);
      }
      handle = setTimeout(callback, 100);
    };
    element.addEventListener('scroll', onScroll);
    return () => element.removeEventListener('scroll', onScroll);
  };

  const ModalContent = ({
    data,
    updateSelected,
    type,
    selectedData,
    variant,
    searchStr,
    modalType,
    disabled,
  }) => (
    <Grid.Col span={12}>
      <div
        style={{ height: '18rem', overflowY: 'auto' }}
        className={'as'}
        onScroll={(event) => getScrollPositionDiv(event, event.currentTarget.scrollTop)}
      >
        {data && data.length > 0 ? (
          data
            .filter(
              (item) =>
                !searchStr ||
                item.toLowerCase().includes(searchStr.toLowerCase()) ||
                item.toLowerCase().includes(searchStr.toLowerCase()),
            )
            .map((item) =>
              (type === 'selected' && selectedData.findIndex((i) => i === item) > -1) ||
              type === 'all' ? (
                <UnstyledButton
                  onClick={() => (disabled ? <></> : updateSelected(item, modalType))}
                  className={
                    (selectedData || []).findIndex((i) => i === item) > -1
                      ? 'lists selected'
                      : 'lists'
                  }
                >
                  <Group style={{ justifyContent: 'space-between' }}>
                    <div>
                      <Text>{item}</Text>
                    </div>
                    {(selectedData || []).findIndex((i) => i === item) > -1 && (
                      <CircleCheck color='green' size='1.5rem' />
                    )}
                  </Group>
                </UnstyledButton>
              ) : (
                ''
              ),
            )
        ) : (
          <div style={{ marginTop: '1rem' }}>No results Found</div>
        )}
      </div>
    </Grid.Col>
  );
  //------------Functions---------//

  return (
    <div>
      <Modal opened={opened} onClose={() => ModalClosed()} title={title} className='accf-modal'>
        <Container>
          <Grid>
            <Grid.Col span={12}>
              <Input
                className='search'
                radius='md'
                onChange={(event) => setSearchStr(event.target.value)}
                value={searchStr}
                icon={<MagnifyingGlassIcon />}
                style={{ margin: '0 4rem' }}
              />
            </Grid.Col>

            <Grid.Col span={12} style={{ position: 'relative' }}>
              <div
                style={{
                  position: 'absolute',
                  top: '30px',
                  zIndex: '3',
                  width: 'max-content',
                  right: '0',
                }}
              >
                {!disabled && (
                  <Checkbox
                    id={type}
                    label={`Select All ${type.charAt(0).toUpperCase() + type.slice(1)}`}
                    onChange={(event) => selectAll(type, event)}
                    checked={checkeds[type] || selected.length === foundData.length}
                  />
                )}
              </div>
              <Tabs
                defaultValue={!disabled ? 'all' : 'selected'}
                style={{ marginTop: 10, position: 'relative', zIndex: 2 }}
                keepMounted={false}
              >
                <Tabs.List>
                  {!disabled && <Tabs.Tab value={`all`}>{`All (${foundData.length})`}</Tabs.Tab>}
                  <Tabs.Tab value={`selected`}>{`Selected (${selected.length})`}</Tabs.Tab>
                </Tabs.List>

                {!disabled && (
                  <Tabs.Panel value={`all`} color='primary'>
                    <ModalContent
                      data={foundData}
                      updateSelected={updateSelected}
                      type='all'
                      modalType={type}
                      selectedData={selected}
                      variant={variant}
                      searchStr={searchStr}
                      disabled={disabled}
                    />
                  </Tabs.Panel>
                )}
                <Tabs.Panel value={`selected`} color='primary'>
                  <ModalContent
                    data={foundData}
                    updateSelected={updateSelected}
                    type='selected'
                    modalType={type}
                    selectedData={selected}
                    variant={variant}
                    searchStr={searchStr}
                    disabled={disabled}
                  />
                </Tabs.Panel>
              </Tabs>
            </Grid.Col>

            <Grid.Col style={{ display: 'flex', justifyContent: 'end' }}>
              {!disabled && (
                <Button
                  style={{
                    marginTop: '1rem',
                  }}
                  variant='gradient'
                  gradient={{ from: '#e3af32', to: '#f4e00f' }}
                  onClick={() => confirmed()}
                  color='primary'
                  className='modal-button'
                >
                  Confirm
                </Button>
              )}
            </Grid.Col>
          </Grid>
        </Container>
      </Modal>

      <Group position='center'>
        <UnstyledButton onClick={() => ModalOpened()}>
          <Text
            style={{
              textDecoration: 'underline',
            }}
            className='modalText'
            color={'#E5B611'}
          >
            {title}
          </Text>
        </UnstyledButton>
      </Group>
    </div>
  );
}

export default ModalAccf;
