import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import MaterialsContractTableVEAM from './MaterialsContractTableVEAM';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import csvDownload from 'json-to-csv-export';
import checkClosedOrInProgress from '../../veamHelper';
import {
  materialTypes,
  liquidTypes,
  liquidBomChanges,
  additionLiquid,
} from '../../../../constants/index';

const MaterialsContract = ({ checkClosuresMatConract, readOnly }) => {
  const { form, info, fetchStatusVeam, StoreManualClosureVEAM } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [materialData, setData] = useState([]);
  const [materialDataN, setmaterialDataN] = useState([]);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(0);

  useEffect(() => {
    checkClosuresMatConract(checkClosedOrInProgress(materialDataN));
  }, [materialDataN]);

  const save = (payload, id, callback) => {
    setLoading(true);
    StoreManualClosureVEAM(payload, id).then(() => {
      getStatusDetails(rfs_number);
      //setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getStatusDetails(rfs_number);
  }, [rfs_number]);

  const getStatusDetails = (rfs_number) => {
    //setLoading(true);
    fetchStatusVeam(rfs_number).then((res) => {
      const dataFilter = res.data.data.filter((item) => [233, 217].includes(item.sla_weeks_id));
      setmaterialDataN(dataFilter);

      const result = [];

      result['Materials'] = dataFilter
        .filter((item) => item.selection_type === 'materials')
        .sort((a, b) => a.id - b.id);
      result['Liquids'] = dataFilter
        .filter((item) => item.selection_type === 'liquids')
        .sort((a, b) => a.id - b.id);

      setData(result);
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  const downloadExcel = (type) => {
    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const Headers = [
      'Pack Mat Code',
      'Pack Mat Description',
      'Plant',
      'Status',
      'Action',
      'Step Deadline',
      'Step finished',
    ];
    const materials = materialData.Materials;
    const liquids = materialData.Liquids;

    if ((liquids || []).length > 0) {
      materialsToExport.data = getDataToExport(liquids);
      materialsToExport.filename =
        'Materials_Contracts_Liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = Headers;
      csvDownload(materialsToExport);
    }

    if ((materials || []).length > 0) {
      materialsToExport.data = getDataToExport(liquids);
      materialsToExport.filename =
        'Materials_Contracts_Liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = Headers;
      csvDownload(materialsToExport);
    }
  };

  const getDataToExport = (data) => {
    const csvArray = data.map(dataToExport);

    return csvArray;
  };

  const dataToExport = ({
    plant,
    status,
    manually_closed,
    finished_date,
    sla_date,
    liquid_changeover_type,
    liquid_changeover_date,
    selection_type,
    liquid_comment,
    material_type,
    material_code_or,
    int_rfs_material_number,
    material_description_or,
    pack_mat_material_text,
    new_material_code,
    new_material_description_intrfsml,
    material_changeover_type,
    material_changeover_date,
    material_comment,
    liquid_type,
    liquid_code,
    liquid_description,
    bom_changes,
    liquid_number,
  }) => {
    let Code = 'N/A',
      new_num = 'N/A',
      new_name = 'N/A',
      type_of_change = 'N/A',
      changeovertype = 'N/A',
      changeoverdate = 'N/A',
      comment = 'N/A',
      Desc;

    if (selection_type === 'materials') {
      if (material_type === materialTypes.existing || material_type === materialTypes.remove) {
        Code = material_code_or ? material_code_or : 'N/A';
        new_num = int_rfs_material_number ? int_rfs_material_number : 'N/A';

        Desc = material_description_or;
        new_name = pack_mat_material_text ? pack_mat_material_text : 'N/A';
      } else if (material_type === materialTypes.new) {
        Code = material_code_or ? material_code_or : 'N/A';
        new_num = new_material_code ? new_material_code : 'N/A';

        Desc = material_description_or;
        new_name = new_material_description_intrfsml ? new_material_description_intrfsml : 'N/A';
      }

      type_of_change = material_type ? material_type : 'N/A';
      changeovertype = material_changeover_type ? material_changeover_type : 'N/a';
      changeoverdate = material_changeover_date ? material_changeover_date : 'N/A';
      comment = material_comment ? material_comment : 'N/A';
    }

    if (selection_type === 'liquids') {
      if (
        [liquidTypes.new, liquidTypes.add].includes(liquid_type) &&
        (bom_changes.includes(liquidBomChanges.thickness) ||
          (bom_changes.includes(additionLiquid.addition) && !liquid_number))
      ) {
        Code = liquid_code ? liquid_code : 'N/A';
        new_num = new_material_code ? new_material_code : 'N/A';

        Desc = liquid_description;
        new_name = new_material_description_intrfsml ? new_material_description_intrfsml : 'N/A';
      } else {
        Code = liquid_code ? liquid_code : 'N/A';
        new_num = Code;

        Desc = liquid_description;
        new_name = Desc;
      }

      type_of_change = liquid_type ? liquid_type : 'N/A';
      changeovertype = liquid_changeover_type ? liquid_changeover_type : 'N/a';
      changeoverdate = liquid_changeover_date ? liquid_changeover_date : 'N/A';
      comment = liquid_comment ? liquid_comment : 'N/A';
    }

    return {
      'Pack Mat Code': new_num ? new_num : 'N/A',
      'Pack Mat Description': new_name ? new_name : 'N/A',
      Plant: plant,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step Deadline': sla_date,
      'Step Finished': finished_date === null ? status : finished_date,
    };
  };

  return (
    <Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='primary'
          style={{
            marginTop: '1rem',
            background: 'black',
          }}
        >
          Export to CSV
        </Button>
      </div>
      {Object.keys(materialData) && (
        <Accordion value={value} onChange={setValue}>
          {Object.keys(materialData).map((name, index) => {
            return (
              Object.values(materialData)[index].length > 0 && (
                <Accordion.Item label={name} key={`key_${index}`} value={`key_${index}`}>
                  <Accordion.Control>{`${name}`}</Accordion.Control>
                  <Accordion.Panel>
                    <Grid key={index}>
                      <Grid.Col span={12}>
                        <MaterialsContractTableVEAM
                          materialDataList={Object.values(materialData)[index]}
                          type={name}
                          save={save}
                          readOnly={readOnly}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
              )
            );
          })}
        </Accordion>
      )}
    </Row>
  );
};

export default MaterialsContract;
