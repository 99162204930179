import React, { useContext, useEffect } from 'react';
import { AppLoader } from '../common/loader';
import { AuthContext } from '../../contexts/AuthContext';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const CrRefreshToken = () => {
  const navigate = useNavigate();
  const { user, refreshToken, crInfoLoader } = useContext(AuthContext);
  const { cr_registered } = user;

  useEffect(() => {
    if (!cr_registered && !crInfoLoader) refreshToken((path) => navigate(path));
  }, [cr_registered, crInfoLoader]);

  return (
    <LoadingContainer>
      <AppLoader size='lg' center />
    </LoadingContainer>
  );
};
