import React, { createContext, useCallback, useState } from 'react';
import { axios } from '../utils/axios';

export const CommonContext = createContext();

export const CommonProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [systemTeams, setSystemTeams] = useState([]);
  const [units, setUnits] = useState([]);

  const fetchSystemTeams = useCallback(() => {
    if (systemTeams.length === 0) {
      setLoading(true);
      axios
        .get('/system/teams')
        .then(
          (res) => {
            setSystemTeams(res.data.teams);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoading(false));
    }
  }, [setSystemTeams]);

  const fetchUnits = useCallback(() => {
    if (systemTeams.length === 0) {
      setLoading(true);
      axios
        .get('/veam/units')
        .then(
          (res) => {
            setUnits(res.data.units);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoading(false));
    }
  }, [units]);

  return (
    <CommonContext.Provider
      value={{
        loading,
        setLoading,
        fetchSystemTeams,
        systemTeams,
        fetchUnits,
        units,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};
