import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import MantineTable from '../../../../common/table/mantine';
import { Select, TextInput, Button, Skeleton } from '@mantine/core';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { RfsContext } from '../../../../contexts/RfsContext';
import { NpsIds, rfsStatus, techFeasibilityPermissions } from '../../../../constants';
import { NpsComponent } from '../../../../common/nps';
import { AuthContext } from '../../../../contexts/AuthContext';
import { CustomTextArea } from '../../../../common/inputs/CustomTextArea';
import { PreviewModal } from '../zitec/previewModal';

const InitialPreviewModal = {
  open: false,
  content: null,
  type: null,
};

export const D2P = () => {
  const { rfsNumber } = useParams();
  const { userRole } = useContext(AuthContext);
  const { form: rfsInfo, info: fetchRfsInfo, updateRfsStatus } = useContext(RfsContext);
  const [initialData, setInitialData] = useState([]);
  const [form, setForm] = useState({});
  const [activePlants, setActivePlants] = useState({});
  const [loading, setLoading] = useState(false);
  const [npsOpened, setNpsOpened] = useState(false);
  const [previewModal, setPreviewModal] = useState(InitialPreviewModal);
  const [loaders, setLoaders] = useState({});

  const permissions = techFeasibilityPermissions['Supplier Change'];
  const readOnly = !permissions.d2p_approval.includes(userRole);

  const status = rfsInfo.rfsInformation.status;
  const formRef = useRef(null);
  formRef.current = form;

  useEffect(() => {
    if (rfsInfo.d2pFeedback.length > 0) {
      const currentValues = mapFeedbackToForm(rfsInfo.d2pFeedback);
      setInitialData(rfsInfo.d2pFeedback);
      setForm(currentValues);

      if (isStatusUpdateRequired(rfsInfo.d2pFeedback, rfsInfo.rfsInformation.status)) {
        updateRfsStatus(rfsNumber, 'Final Approval Pending from PMO');
        fetchRfsInfo(rfsNumber);
      }
    }
  }, [rfsInfo, loading]);

  const mapFeedbackToForm = (feedback) => {
    return feedback.reduce((acc, item) => {
      acc[item.id] = formRef.current[item.id]
        ? { ...item, ...formRef.current[item.id] }
        : { ...item };
      return acc;
    }, {});
  };

  const isStatusUpdateRequired = (feedback, status) => {
    return feedback.every((item) => item.updated_by) && status === rfsStatus.supplier_zitec_active;
  };

  const resetTableData = () => {
    const updatedForm = { ...formRef.current };
    Object.keys(updatedForm).forEach((key) => {
      if (!updatedForm[key].updated_by) {
        updatedForm[key] = resetFormFields(updatedForm[key]);
      }
    });
    setForm(updatedForm);
  };

  const resetFormFields = (fields) => {
    return {
      ...fields,
      artwork_actually_needed: null,
      artwork_adaption_actually_needed: null,
      color_target_setting_done: null,
      comment: '',
    };
  };

  const onChange = (value, type, row) => {
    if (row.getIsSelected()) {
      const updatedForm = { ...formRef.current };
      updatedForm[row.original.id] = {
        ...updatedForm[row.original.id],
        [type]: value,
      };
      if (type === 'artwork_actually_needed' && value === 0) {
        updatedForm[row.original.id].artwork_adaption_actually_needed = '';
      }
      setForm(updatedForm);
    }
  };

  const handleSubmit = (info) => {
    const payload = { ...form[info.id], material_id: info.material_id };

    setLoaders({ ...loaders, [info.id]: true });

    axios
      .put(
        `/supplier-changes/d2p/${info.updated_by ? 'pmo-submit' : 'submit'}/${rfsNumber}`,
        payload,
      )
      .then(() => {
        setNpsOpened(true);
        fetchRfsInfo(rfsNumber);

        const plants = { ...activePlants, [info.id]: false };
        const filteredPlants = Object.keys(plants).reduce((acc, key) => {
          if (plants[key]) {
            acc[key] = true;
          }
          return acc;
        }, {});

        setActivePlants(filteredPlants);
      })
      .catch(console.log)
      .finally(() => {
        setLoaders({ ...loaders, [info.id]: false });
      });
  };

  const handleRowSelection = (data) => setActivePlants({ ...data });

  const handleDefaultSet = (value, name) => {
    const updatedForm = { ...formRef.current };
    value = name === 'comment' ? value : parseInt(value);

    Object.keys(activePlants).forEach((material) => {
      if (activePlants[material]) updatedForm[material][name] = value;
    });

    setForm(updatedForm);
  };

  const isDisabled = (id) => {
    const info = formRef.current[id];
    return !info.artwork_actually_needed && info.artwork_actually_needed !== 0
      ? true
      : (info.artwork_actually_needed === 1 &&
          ![0, 1].includes(info.artwork_adaption_actually_needed)) ||
          ![0, 1].includes(info.color_target_setting_done);
  };

  const isPmoFieldsDisabled = () =>
    (!permissions.pmo_approval.includes(userRole) && readOnly) ||
    status !== rfsStatus.supplier_pmo2_active;

  const editableByPmo = ({ updated_by_pmo }) =>
    !permissions.pmo_approval.includes(userRole) && readOnly;

  const renderSelect = (row, type, data) => (
    <Select
      size='sm'
      radius='md'
      onChange={(value) => onChange(value, type, row)}
      clearable
      data={data}
      disabled={isSelectDisabled(row, type)}
      value={formRef.current[row.original.id]?.[type]}
      withinPortal
    />
  );

  const isSelectDisabled = (row, type) => {
    const selected = row.getIsSelected();
    const artworkNeeded = formRef.current[row.original.id]?.artwork_actually_needed;
    return (
      !selected ||
      !row.original.zitec_all_supplier_updated ||
      editableByPmo(row.original) ||
      (!artworkNeeded && type === 'artwork_adaption_actually_needed') ||
      readOnly
    );
  };

  const columns = useMemo(
    () => [
      { accessorKey: 'material_code', header: 'Material Code' },
      { accessorKey: 'material_description', header: 'Material Description' },
      {
        header: 'Is Artwork Actually needed?',
        accessorKey: 'artwork_actually_needed',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            {renderSelect(row, 'artwork_actually_needed', [
              { label: 'Yes', value: 1 },
              { label: 'No', value: 0 },
            ])}
          </Skeleton>
        ),
      },
      {
        header: 'Is Artwork Adaptation Actually needed?',
        accessorKey: 'artwork_adaption_actually_needed',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            {renderSelect(row, 'artwork_adaption_actually_needed', [
              { label: 'Yes', value: 1 },
              { label: 'No', value: 0 },
            ])}
          </Skeleton>
        ),
      },
      {
        header: 'Is Colour Target Setting Done?',
        accessorKey: 'color_target_setting_done',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            {renderSelect(row, 'color_target_setting_done', [
              { label: 'Yes', value: 1 },
              { label: 'No', value: 0 },
            ])}
          </Skeleton>
        ),
      },
      {
        header: 'Comment',
        accessorKey: 'comment',
        enableEditing: true,
        editVariant: 'text',
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            <CustomTextArea
              radius='md'
              name='comment'
              onChange={(value) => onChange(value, 'comment', row)}
              disabled={!row.getIsSelected() || row.original.updated_by || readOnly}
              value={formRef.current[row.original.id]?.comment}
            />
          </Skeleton>
        ),
      },
      {
        header: 'Updated by PMO',
        id: 'updated_by_pmo',
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            <TextInput
              radius='md'
              disabled
              value={formRef.current[row.original.id]?.updated_by_pmo || ''}
            />
          </Skeleton>
        ),
      },
      {
        header: 'PMO comments',
        id: 'pmo_comments',
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            <CustomTextArea
              radius='md'
              onChange={(value) => onChange(value, 'pmo_comments', row)}
              value={formRef.current[row.original.id]?.pmo_comments || ''}
              maxLength={500}
              disabled={!row.getIsSelected() || isPmoFieldsDisabled()}
            />
          </Skeleton>
        ),
      },
      {
        header: 'Previous D2P response',
        id: 'previous_zitec_response',
        Cell: ({ row }) => {
          const {
            prev_artwork_actually_needed,
            prev_artwork_adaptation_actually_needed,
            prev_color_target_setting_done,
          } = row.original;

          const validate = (data) => {
            return ['Yes', 'No'].includes(data);
          };

          const getContent = () => {
            return [
              {
                prev_artwork_actually_needed,
                prev_artwork_adaptation_actually_needed,
                prev_color_target_setting_done,
              },
            ].filter(
              (val) =>
                validate(val.prev_artwork_actually_needed) ||
                validate(val.prev_artwork_adaptation_actually_needed) ||
                validate(val.prev_color_target_setting_done),
            );
          };

          return (
            <Skeleton visible={loaders[row.original.id] || false}>
              <Button
                onClick={() =>
                  setPreviewModal({
                    open: true,
                    content: getContent(),
                    type: 'd2p',
                  })
                }
                color='dark'
                size='sm'
                radius='sm'
                fullWidth
                disabled={isPmoFieldsDisabled()}
                className='add-supplier-button'
              >
                View
              </Button>
            </Skeleton>
          );
        },
      },
      {
        header: 'Action',
        id: 'action',
        Cell: ({ row }) => (
          <Skeleton visible={loaders[row.original.id] || false}>
            <Button
              onClick={() => handleSubmit(row.original)}
              color='dark'
              size='sm'
              radius='sm'
              fullWidth
              disabled={
                !row.getIsSelected() ||
                editableByPmo(row.original) ||
                isDisabled(row.original.id) ||
                readOnly
              }
              className='add-supplier-button'
            >
              Submit
            </Button>
          </Skeleton>
        ),
      },
    ],
    [form, activePlants, loading, rfsInfo, loaders],
  );

  return (
    <div>
      <MantineTable
        columns={columns}
        initialData={initialData}
        unique={'id'}
        handleRowSelection={handleRowSelection}
        activeRows={activePlants}
        enableRowSelection={true}
        loading={loading}
        resetTableData={resetTableData}
        editAll={true}
        applyToAll={handleDefaultSet}
        showSelectedToggle={true}
        showResetAll={true}
        hideSelectColumn={false}
        enablePinning={true}
        initialState={{
          sorting: [{ id: 'material_code', desc: false }],
          showColumnFilters: true,
          density: 'xs',
          columnPinning: {
            left: ['mrt-row-select'],
            right: [''],
          },
        }}
      />

      {npsOpened && (
        <NpsComponent
          rfs_number={rfsNumber}
          opened={npsOpened}
          setOpened={setNpsOpened}
          callback={() => {
            setNpsOpened(false);
          }}
          id={NpsIds().technically_feasible}
        />
      )}

      {previewModal.open && (
        <PreviewModal close={() => setPreviewModal(InitialPreviewModal)} {...previewModal} />
      )}
    </div>
  );
};
