import { Modal, Button, Container, Grid, Text } from '@mantine/core';
import { CustomTextArea } from '../../../../../common/inputs/CustomTextArea';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { showAlert } from '../../../../../utils/alerts';
import { axios } from '../../../../../utils/axios';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';

export const RejectionModal = ({
  activePlant,
  setBtnDisable,
  modal,
  setModal,
  handleRefresh,
  setStepStatus,
}) => {
  const { getStepStatus } = useContext(CostingRequestsContext);
  const { crNumber } = useParams();
  const [rejectionComment, setRejectionComment] = useState('');
  const [rejectBtn, setRejectBtn] = useState(true);

  const handleCancel = () => setModal(false);

  const handleComment = (val) => {
    setRejectionComment(val ?? '');
    setRejectBtn(rejectionComment.length <= 10);
  };

  const handleReject = async () => {
    const payload = {
      status: 'Rejected',
      comment: rejectionComment,
      plant: activePlant.producing_plant,
    };

    try {
      const res = await axios.post(`costing-request/step/vic-approval/${crNumber}`, payload);
      if (res?.data?.success) {
        getStepStatus();
        showAlert({ message: 'Request Successful' }, 'success');
        setBtnDisable(true);
      } else {
        showAlert('', 'error');
      }
    } catch {
    } finally {
      handleCancel();
      handleRefresh();
      setStepStatus('Closed');
    }
  };

  return (
    <Modal
      opened={modal}
      onClose={handleCancel}
      title='Reject Request'
      size='lg'
      closeOnClickOutside={false}
      styles={{
        title: { fontWeight: 600 },
        body: { minHeight: '200px' },
      }}
    >
      <Text color='dimmed' size='sm' mb='sm'>
        Please explain briefly the reason for rejection (min 10 characters).
      </Text>
      <CustomTextArea
        placeholder='Enter your comments here'
        value={rejectionComment}
        onChange={(val) => handleComment(val)}
        style={{
          width: '100%',
          marginBottom: '1rem',
        }}
      />
      <div style={{ marginTop: '12px' }}>
        <Grid gutter='md'>
          <Grid.Col span={4}>
            <Button color='gray' radius='md' fullWidth onClick={handleCancel}>
              Cancel
            </Button>
          </Grid.Col>
          <Grid.Col span={4}>
            <Button color='red' radius='md' fullWidth onClick={handleReject} disabled={rejectBtn}>
              Reject
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    </Modal>
  );
};
