import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, Accordion } from '@mantine/core';
import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import MaterialCreationCode from './Material Code Creation/MaterialCreation';
import PlantExtensionCode from './Product Plant Extension/PlantExtension';
import SupplierNameCode from './Supplier Name/supplierName';
import ArtworkDevelopmentCode from './Artwork Development/ArtworkDevelopment';
import EanApprovalCode from './Ean Approval/EanApproval';
import MaterialContractCode from './Material Contract/materialContract';
import TrialsCode from './Trials/trials';
import ProductionReversal from '../Reversal';
import { RfsContext } from '../../../contexts/RfsContext';
import { tabStyles } from '../../../utils/helpers';
import { routes } from '../../../routes';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';

export default function ProductDevelopmentProdSite({ trials, tabs }) {
  const navigate = useNavigate();
  const params = useParams();
  const { getSLAWeeksIdsForRFS } = useContext(ProjectOverviewContext);
  const [tab, setTab] = useState(tabs?.includes('Material Code Creation') ? 'mcc' : 'ppe');
  const [slaWeekIds, setSlaWeekIds] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const sub = urlParams.get('sub');
    if (sub) {
      setTab(sub);
    }
  }, [urlParams]);

  useEffect(() => {
    getSLAWeeksIdsForRFS(params.rfsNumber).then((res) => {
      let sla_weeks_ids = [];
      res.data.data.map(
        (item) =>
          sla_weeks_ids.includes(item.sla_weeks_id) === false &&
          sla_weeks_ids.push(item.sla_weeks_id),
      );
      setSlaWeekIds(sla_weeks_ids);
    });
  }, []);

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <ProjectName />
      <br />
      {(slaWeekIds?.includes(24) ||
        slaWeekIds?.includes(25) ||
        slaWeekIds?.includes(26) ||
        slaWeekIds?.includes(27) ||
        slaWeekIds?.includes(28) ||
        slaWeekIds?.includes(29) ||
        slaWeekIds?.includes(30)) && (
        <Tabs
          onTabChange={(value) =>
            navigate(
              `${routes.project(params.rfsNumber).root}?tab=${urlParams.get('tab')}&sub=${value}`,
            )
          }
          grow
          variant='unstyled'
          styles={tabStyles}
          className='prod-dev-tabs'
          color='primary'
          value={tab}
          defaultValue={tabs?.includes('Material Code Creation') ? 'mcc' : 'ppe'}
          keepMounted={false}
        >
          <Tabs.List>
            {slaWeekIds?.includes(24) && (
              <Tabs.Tab className='topTabs' value='mcc'>
                Materials Code Creation
              </Tabs.Tab>
            )}
            {slaWeekIds?.includes(25) && (
              <Tabs.Tab className='topTabs' value='ppe'>
                Production Plant Extension
              </Tabs.Tab>
            )}
            {slaWeekIds?.includes(26) && (
              <Tabs.Tab className='topTabs' value='sn'>
                Supplier Name
              </Tabs.Tab>
            )}
            {slaWeekIds?.includes(27) && (
              <Tabs.Tab className='topTabs' value='ad'>
                Artwork Development
              </Tabs.Tab>
            )}
            {slaWeekIds?.includes(28) && (
              <Tabs.Tab className='topTabs' value='ean'>
                EAN Approval
              </Tabs.Tab>
            )}
            {slaWeekIds?.includes(29) && (
              <Tabs.Tab className='topTabs' value='mc'>
                Materials Contracts
              </Tabs.Tab>
            )}
            {slaWeekIds?.includes(30) && (
              <Tabs.Tab className='topTabs' value='trls'>
                Trials
              </Tabs.Tab>
            )}
            {trials &&
              (slaWeekIds?.includes(160) ||
                slaWeekIds?.includes(161) ||
                slaWeekIds?.includes(162) ||
                slaWeekIds?.includes(163) ||
                slaWeekIds?.includes(164) ||
                slaWeekIds?.includes(165)) && (
                <Tabs.Tab className='topTabs' value='tr'>
                  Trials Reversal
                </Tabs.Tab>
              )}
          </Tabs.List>

          {slaWeekIds?.includes(24) && (
            <Tabs.Panel className='topTabs' value='mcc'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <MaterialCreationCode />
              </div>
            </Tabs.Panel>
          )}
          {slaWeekIds?.includes(25) && (
            <Tabs.Panel className='topTabs' value='ppe'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <PlantExtensionCode />
              </div>
            </Tabs.Panel>
          )}

          {slaWeekIds?.includes(26) && (
            <Tabs.Panel className='topTabs' value='sn'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <SupplierNameCode />
              </div>
            </Tabs.Panel>
          )}

          {slaWeekIds?.includes(27) && (
            <Tabs.Panel className='topTabs' value='ad'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <ArtworkDevelopmentCode />
              </div>
            </Tabs.Panel>
          )}

          {slaWeekIds?.includes(28) && (
            <Tabs.Panel className='topTabs' value='ean'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <EanApprovalCode />
              </div>
            </Tabs.Panel>
          )}

          {slaWeekIds?.includes(29) && (
            <Tabs.Panel className='topTabs' value='mc'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <MaterialContractCode />
              </div>
            </Tabs.Panel>
          )}

          {slaWeekIds?.includes(30) && (
            <Tabs.Panel className='topTabs' value='trls'>
              <div className='table-container' style={{ overflowY: 'auto' }}>
                <TrialsCode />
              </div>
            </Tabs.Panel>
          )}

          {trials &&
            (slaWeekIds?.includes(160) ||
              slaWeekIds?.includes(161) ||
              slaWeekIds?.includes(162) ||
              slaWeekIds?.includes(163) ||
              slaWeekIds?.includes(164) ||
              slaWeekIds?.includes(165)) && (
              <Tabs.Panel className='topTabs' label='tr' value='tr'>
                <div className='table-container' style={{ overflowY: 'auto' }}>
                  <ProductionReversal
                    new_material_code={slaWeekIds.includes(163)}
                    removal_of_bom={slaWeekIds.includes(161) || slaWeekIds.includes(164)}
                    procurement_type={slaWeekIds.includes(162) || slaWeekIds.includes(165)}
                    status_reversal={slaWeekIds.includes(160)}
                  />
                </div>
              </Tabs.Panel>
            )}
        </Tabs>
      )}
    </ContentPaper>
  );
}
