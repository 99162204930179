import React, { useState, useMemo, useContext } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { Text, Button, Select, Textarea } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { ProductionLog } from '../productionSiteLogModal';
import { RfsContext } from '../../../../contexts/RfsContext';
import { useRef } from 'react';

export function TrialsTable({ trialsData, onChange, save, disable }) {
  const [filterLoading, setFilterLoading] = useState(false);
  const data = trialsData;
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const { submitTrials, submitTrialsComments, form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;

  const [forms, setForm] = useState({
    sku_ids: 0,
    reason: '',
    comment: '',
  });
  const formRef = useRef(null);
  formRef.current = forms;

  const [results, setResults] = useState('');
  const resultRef = useRef(null);
  resultRef.current = results;

  const handleChangeResult = (value, id) => {
    setResults({ [id]: value });
  };

  const handleChange = (name, value, id) => {
    const currentValues = { ...forms };
    currentValues[name] = value;
    currentValues['sku_ids'] = id;
    setForm({ [id]: currentValues });
  };

  function getLogs(imp_id) {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  }

  function postTrialsComments(id, ids, name, value) {
    let payload = {
      sku_ids: ids,
      // result: resultRef.current[id] ? resultRef.current[id] : '',
      // reason: formRef.current[id]?.reason ? formRef.current[id]?.reason : '',
      comment: formRef.current[id]?.comment,
    };
    submitTrialsComments(payload, rfs_number).then(() => save());
    onChange(id, name, value);
  }

  function postTrials(id, ids, name, value) {
    let payload = {
      sku_ids: ids,
      result: resultRef.current[id] ? resultRef.current[id] : '',
      reason: formRef.current[id]?.reason ? formRef.current[id]?.reason : '',
    };
    submitTrials(payload, rfs_number).then(() => save());
    onChange(id, name, value);
    onChange(id, 'trials_result', resultRef.current[id]);
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Trials',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_ps_sku_reference.selected_plant}</Text>;
        },
      },
      {
        Header: 'Result',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_trial?.trials_result;
          const showSubmit =
            resultRef.current[row.original.sku_id] &&
            resultRef.current[row.original.sku_id] === 'Success';
          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_trial?.trials_result}</Text>
          ) : (
            <>
              <Select
                placeholder='Success/Fail'
                data={[
                  { value: 'Success', label: 'Success' },
                  { value: 'Fail', label: 'Fail' },
                ]}
                onChange={(event) => handleChangeResult(event, row.original.sku_id)}
                disabled={row.original.status === 'Not Started' || disable}
                clearable
              />
              {showSubmit && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  onClick={() => {
                    postTrials(
                      row.original.sku_id,
                      row.original.sku_ids,
                      'trials_result',
                      resultRef.current[row.original.sku_id],
                    );
                  }}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Reason of Failure',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_trial?.trials_failure_reason;
          const showSubmit =
            formRef.current[row.original.sku_id]?.reason &&
            formRef.current[row.original.sku_id]?.reason?.length > 0;
          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_trial?.trials_failure_reason}</Text>
          ) : (
            <>
              <Select
                placeholder='Cancellation/Scope Restatement'
                data={[
                  { value: 'Cancellation', label: 'Cancellation' },
                  {
                    value: 'Scope Restatement',
                    label: 'Scope Restatement',
                  },
                ]}
                clearable
                disabled={resultRef.current[row.original.sku_id] != 'Fail' || disable}
                onChange={(event) => handleChange('reason', event, row.original.sku_id)}
              />
              {showSubmit && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  onClick={() =>
                    postTrials(
                      row.original.sku_id,
                      row.original.sku_ids,
                      'trials_failure_reason',
                      formRef.current[row.original.sku_id]?.reason,
                    )
                  }
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Comments',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_trial?.trials_comment;
          const showSubmit =
            formRef.current[row.original.sku_id]?.comment &&
            formRef.current[row.original.sku_id]?.comment?.length > 0;
          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_trial?.trials_comment}</Text>
          ) : (
            <>
              <Textarea
                disabled={
                  row.original.int_rfs_impl_trial?.trials_result === 'Success' ||
                  !row.original.int_rfs_impl_trial?.trials_failure_reason ||
                  disable
                }
                onChange={(event) =>
                  handleChange('comment', event.target.value, row.original.sku_id)
                }
                placeholder='Your comment'
              />
              {showSubmit && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  onClick={() =>
                    postTrialsComments(
                      row.original.sku_id,
                      row.original.sku_ids,
                      'trials_comment',
                      formRef.current[row.original.sku_id]?.comment,
                    )
                  }
                  color='primary'
                  radius='xs'
                  fullWidth
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Logs',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => getLogs(row.original.id)}
              style={{ background: 'black', color: 'white' }}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('YYYY-MM-DD')
                : row.original.status}
            </Text>
          );
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            {filterLoading && (
              <tr>
                <td colSpan={13}>
                  <div className='loader-container'>
                    <AppLoader size={'md'} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {logModal && (
        <ProductionLog logModal={logModal} id={imp_id} setLogModal={setLogModal} type='Trials' />
      )}
    </Styles>
  );
}
