import React, { useContext, useEffect, useState } from 'react';
import { Modal, Accordion, Container, Grid, Group, Text } from '@mantine/core';
import { X, Check } from 'tabler-icons-react';
import moment from 'moment';
import ShowObsoletesTable from './showObsoletesTable';

export const ObsoletesModal = ({ data, obsoletesModal, setObsoletesModal }) => {
  const [logs, setLogs] = useState([]);

  return (
    <div>
      <Modal
        size='75%'
        opened={obsoletesModal.open}
        onClose={() => setObsoletesModal({ open: false, id: null })}
        title={
          <Text weight={700} size='xl'>
            Obsoletes
          </Text>
        }
      >
        <ShowObsoletesTable scope_id={obsoletesModal.scope_id} />
      </Modal>
    </div>
  );
};

export default ObsoletesModal;
