import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import AddRfsForm from '../../components/forms/addRfsForm/addRfsForm';
import RfsLayout from '../../components/layouts/RfsLayout';
import { RfsContext } from '../../contexts/RfsContext';

export default function AddRfsPage() {
  const { info, reset } = useContext(RfsContext);
  const params = useParams();

  useEffect(() => {
    if (params.rfsNumber) info(params.rfsNumber);

    return () => reset();
  }, [params]);

  return (
    <RfsLayout>
      <AddRfsForm />
    </RfsLayout>
  );
}
