import React, { useState, useContext, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Text, SimpleGrid } from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ConfirmModal from '../../../../components/modal/ConfirmModal';
import SubmitProjectModal from '../../../../components/modal/SubmitProjectModal';
import { AppLoader } from '../../../../components/common/loader/index';

import { minWordsValidation } from '../../../../components/forms/rfsInitiationForm/rfsInitiationForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import { routes } from '../../../../routes/index';
import { RfsContext } from '../../../../contexts/RfsContext';
import { Pencil } from 'tabler-icons-react';
import { AuthContext } from '../../../../contexts/AuthContext';
import { NpsIds, rfsStatus, roles } from '../../../../constants';
import withConfirm from '../../../../components/common/confirm-dialog';
import TechFeasibilityModal from '../../../../components/modal/techFeasibilityModal/modal';
import { NpsComponent } from '../../../../common/nps';
import SupplierScope from '../../scope';

function Info(props) {
  const { pmoApproval = false } = props;
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitProjectModal, setSubmitProjectModal] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [tfModal, setTfModal] = useState(false);
  const [artworkRequired, setArtworkRequired] = useState(false);

  const [npsOpened, setNpsOpened] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { form, downloadFile, attachmentLoading, infoLoading } = useContext(RfsContext);
  const { userRole } = useContext(AuthContext);

  const {
    project_name = '',
    initiator_position = '',
    initiator_team = '',
    backupUsers = [],
    allowedUsers = [],
    first_shipment = '',
    technicalLead = [],
    fast_track = '',
    capex_needed = '',
    capex_amount,
    gatekeeper = [],
    cost_center = '',
    project_purpose = '',
    project_details = '',
    comments = '',
    trials_needed = '',
    rfs_type,
    impactedMarkets = [],
    status,
  } = form.rfsInformation;
  const { artworkBriefing, fastTrackApproval, emailAttachment } = form.attachments;

  const { scopeSelectedMaterials = [] } = form;

  const artworkRequiredRef = useRef(null);
  artworkRequiredRef.current = artworkRequired;

  useEffect(() => {
    if (scopeSelectedMaterials.length > 0) {
      const index = scopeSelectedMaterials.findIndex((material) => material.artwork_change);
      setArtworkRequired(index > -1);
    }
  }, [scopeSelectedMaterials]);

  useEffect(() => {
    if (submitted && !npsOpened && userRole === roles.initiator) navigate('/');
    if (submitted && userRole !== roles.initiator) navigate('/');
  }, [submitted, npsOpened]);

  const getUsers = (type) => {
    if (type === 'allowed') {
      let users = (allowedUsers || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'backup') {
      let users = (backupUsers || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'gatekeeper') {
      let users = (gatekeeper || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'technical') {
      let users = (technicalLead || []).map((item) => item.name);
      return users?.join(', ');
    } else {
      return null;
    }
  };

  const getImpactedMarkets = () => {
    let markets = (impactedMarkets || []).map((item) => item);
    return markets?.join(', ');
  };

  const FileList = ({ type, files = [] }) => {
    let { rfsNumber } = props;
    rfsNumber = rfsNumber.replace(/[^a-zA-Z0-9]/g, '');

    return (files || []).length > 0 ? (
      <ul style={{ fontSize: '0.8rem' }}>
        {files.map((file, index) => (
          <li
            style={{
              color: '#e5b611',
              cursor: 'pointer',
            }}
            key={index}
            onClick={() => downloadFile(file.name, rfsNumber.toLowerCase())}
          >
            {file.name}
          </li>
        ))}
      </ul>
    ) : (
      <p
        style={
          type === 'fastTrackApproval' && Boolean(fast_track) && fastTrackApproval.length === 0
            ? { fontSize: '0.8rem', color: 'red' }
            : { fontSize: '0.8rem' }
        }
      >
        {' '}
        No Files Attached{' '}
      </p>
    );
  };

  const editScope = () => {
    let access = false;
    if (
      [roles.superAdmin, roles.pmo].includes(userRole) &&
      [
        rfsStatus.tech_feasible,
        rfsStatus.tech_feasible2,
        rfsStatus.supplier_pmo1_active,
        rfsStatus.supplier_pmo2_active,
      ].includes(status)
    ) {
      access = true;
    }

    return access;
  };

  return (
    <>
      {infoLoading ? (
        <AppLoader size='lg' center />
      ) : (
        <div>
          <Row>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                GENERAL DETAILS{' '}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).info, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
            </Col>
          </Row>

          <>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2}>
                  {!initiator_position ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
                        Initiator Position:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
                      Initiator Position:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {initiator_position}
                  </Text>
                </SimpleGrid>
              </Col>

              <Col md={{ span: 5 }}>
                <SimpleGrid cols={2}>
                  {!initiator_team ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
                        Initiator Team:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
                      Initiator Team:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {initiator_team}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {(backupUsers || []).length === 0 ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={(backupUsers || []).length === 0 ? 'red' : ''}
                      >
                        Selected Backup Users:
                      </Text>
                    </Link>
                  ) : (
                    <Text
                      size='sm'
                      weight={600}
                      color={(backupUsers || []).length === 0 ? 'red' : ''}
                    >
                      Selected Backup Users:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {getUsers('backup') === '' ? 'No users selected' : getUsers('backup')}
                  </Text>
                </SimpleGrid>
              </Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  <Text size='sm' weight={600}>
                    Request for Fast Track:
                  </Text>
                  <Text size='sm' weight={400}>
                    {fast_track ? 'Yes' : 'No'}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {(technicalLead || []).length === 0 && trials_needed === 'Yes' ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={(technicalLead || []).length === 0 ? 'red' : ''}
                      >
                        Selected Technical Lead:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600}>
                      Selected Technical Lead:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {getUsers('technical') === '' ? 'No users selected' : getUsers('technical')}
                  </Text>
                </SimpleGrid>
              </Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  <Text size='sm' weight={600}>
                    Selected Allowed Users:
                  </Text>
                  <Text size='sm' weight={400}>
                    {getUsers('allowed') === '' ? 'No users selected' : getUsers('allowed')}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {!first_shipment ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!first_shipment ? 'red' : ''}>
                        First Shipment Date:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!first_shipment ? 'red' : ''}>
                      First Shipment Date:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {first_shipment}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {!cost_center ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!cost_center ? 'red' : ''}>
                        Cost Center:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!cost_center ? 'red' : ''}>
                      Cost Center:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {cost_center}
                  </Text>
                </SimpleGrid>
              </Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {gatekeeper?.length === 0 ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
                        Selected Gatekeeper:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
                      Selected Gatekeeper:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {getUsers('gatekeeper') === '' ? 'No users selected' : getUsers('gatekeeper')}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {!trials_needed ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!trials_needed ? 'red' : ''}>
                        Are Trials needed:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!trials_needed ? 'red' : ''}>
                      Are Trials needed:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {trials_needed}
                  </Text>
                </SimpleGrid>
              </Col>

              {capex_needed ? (
                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                    {!capex_amount ? (
                      <Link to={routes.submission(params.rfsNumber).info}>
                        <Text size='sm' weight={600} color={!capex_amount ? 'red' : ''}>
                          Capex Amount:
                        </Text>
                      </Link>
                    ) : (
                      <Text size='sm' weight={600} color={!capex_amount ? 'red' : ''}>
                        Capex Amount:
                      </Text>
                    )}
                    <Text size='sm' weight={400}>
                      {`${capex_amount ? `$${capex_amount}` : ''}`}
                    </Text>
                  </SimpleGrid>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  <Text size='sm' weight={600} color={impactedMarkets?.length === 0 ? 'red' : ''}>
                    Impacted Markets
                  </Text>
                  <Text size='sm' weight={400}>
                    {['Production Site', 'Article Modification/Value Engineering'].includes(
                      rfs_type,
                    ) && getImpactedMarkets() === ''
                      ? 'No markets selected'
                      : getImpactedMarkets()}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Row style={{ marginTop: '0.5rem' }}>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                <Col md={{ span: 2 }}>
                  {!project_purpose || minWordsValidation(project_purpose) ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
                      >
                        Project Purpose:
                      </Text>
                    </Link>
                  ) : (
                    <Text
                      size='sm'
                      weight={600}
                      color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
                    >
                      Project Purpose:
                    </Text>
                  )}
                </Col>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>
                <Col md={{ span: 8 }}>
                  <Text size='sm' weight={400}>
                    {project_purpose}
                  </Text>
                </Col>
              </Row>

              <Row style={{ marginTop: '0.5rem' }}>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                <Col md={{ span: 2 }}>
                  {!project_details || minWordsValidation(project_details) ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
                      >
                        Project Explanation:
                      </Text>
                    </Link>
                  ) : (
                    <Text
                      size='sm'
                      weight={600}
                      color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
                    >
                      Project Explanation:
                    </Text>
                  )}
                </Col>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>
                <Col md={{ span: 8 }}>
                  <Text size='sm' weight={400}>
                    {project_details}
                  </Text>
                </Col>
              </Row>
            </Row>

            <Row style={{ marginTop: '0.5rem' }}>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 2 }}>
                <Text size='sm' weight={600}>
                  Any Additional Comments:
                </Text>
              </Col>
              <Col
                md={1}
                style={{
                  width: '3.8%',
                  flex: '0 0 3.8%',
                  maxWidth: ' 3.8%',
                }}
              ></Col>
              <Col md={{ span: 8 }}>
                <Text size='sm' weight={400}>
                  {comments}
                </Text>
              </Col>
            </Row>
          </>
          <Row style={{ marginTop: '1rem' }}>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                SCOPE{' '}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).scopeSelect, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
              {form.selectedScope.materials.length === 0 && (
                <span style={{ color: 'red', fontWeight: '400', fontSize: '0.8rem' }}>
                  Required Field
                </span>
              )}
            </Col>

            <Col
              md={{ span: 2, offset: 6 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <Button
                color='primary'
                style={{ background: 'black', color: 'white' }}
                onClick={() => setTfModal(true)}
              >
                Other Projects in scope
              </Button>
            </Col>
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>
            <Col
              md={11}
              style={{
                marginTop: '0.5rem',
              }}
            >
              <SupplierScope {...props} />
            </Col>
          </Row>

          <Row style={{ marginTop: '1rem' }}>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                ATTACHMENTS {}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).attachment, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
            </Col>
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              {emailAttachment.length === 0 ? (
                <Link to={routes.submission(params.rfsNumber).attachment}>
                  <Text size='sm' weight={600} color={'dark'}>
                    Additional Email Attachment:
                  </Text>
                </Link>
              ) : (
                <Text size='sm' weight={600}>
                  Additional Email Attachment:
                </Text>
              )}
              <Text size='sm' weight={400}>
                {attachmentLoading ? (
                  <AppLoader />
                ) : (
                  <FileList files={emailAttachment} type={'emailAttachment'} />
                )}
              </Text>
            </Col>
            <Col md={{ span: 5, offset: 0 }}>
              {fastTrackApproval.length === 0 ? (
                <Link to={routes.submission(params.rfsNumber).attachment}>
                  <Text size='sm' weight={600} color={'dark'}>
                    Fast Track Approval:
                  </Text>
                </Link>
              ) : (
                <Text size='sm' weight={600}>
                  Fast Track Approval:
                </Text>
              )}
              <Text size='sm' weight={400}>
                {attachmentLoading ? (
                  <AppLoader />
                ) : (
                  <FileList files={fastTrackApproval} type={'fastTrackApproval'} />
                )}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              {artworkBriefing.length === 0 ? (
                <Link to={routes.submission(params.rfsNumber).attachment}>
                  <Text size='sm' weight={600} color={'dark'}>
                    Artwork Briefing:
                  </Text>
                </Link>
              ) : (
                <Text size='sm' weight={600}>
                  Artwork Briefing:
                </Text>
              )}
              <Text size='sm' weight={400}>
                {attachmentLoading ? (
                  <AppLoader />
                ) : (
                  <FileList files={artworkBriefing} type={'artworkBriefing'} />
                )}
              </Text>
            </Col>
          </Row>

          {tfModal && <TechFeasibilityModal opened={tfModal} setOpened={setTfModal} />}

          {submitProjectModal && (
            <SubmitProjectModal opened={submitProjectModal} setOpened={setSubmitProjectModal} />
          )}

          {confirmModal && (
            <ConfirmModal
              opened={confirmModal}
              setOpened={setConfirmModal}
              pname={project_name}
              npsOpened={npsOpened}
              setNpsOpened={setNpsOpened}
              setSubmitted={setSubmitted}
            />
          )}

          {npsOpened && (
            <NpsComponent
              rfs_number={params.rfsNumber}
              opened={npsOpened}
              setOpened={setNpsOpened}
              callback={() => {
                navigate('/');
              }}
              id={NpsIds().submission}
            />
          )}
        </div>
      )}
    </>
  );
}

export default withConfirm(Info);
