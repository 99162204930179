import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Grid, Group, Text } from '@mantine/core';
import { Checkbox } from '@mantine/core';
import './index.css';

import { RfsContext } from '../../../../contexts/RfsContext';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { InfoCircle } from 'tabler-icons-react';
import { AppLoader } from '../../../common/loader';
import { routes } from '../../../../routes';

export const DisclaimerModal = ({ disclaimerModal, setDisclaimerModal }) => {
  const navigate = useNavigate();
  const { form, setForm, create, loading } = useContext(RfsContext);

  const handleChange = (event) => {
    const currentValues = { ...form };
    currentValues.rfsInformation[event.target.name] = event.target.checked;

    setForm(currentValues);
  };

  const handleClose = () => {
    setDisclaimerModal(false);
  };

  const handleNext = () => {
    create(form.rfsInformation, (number) => {
      setDisclaimerModal(false);
      navigate(routes.submission(number).info);
    });
  };

  return (
    <>
      <Modal
        className='disclaimer-modal'
        opened={disclaimerModal}
        onClose={() => {
          handleChange({ target: { name: 'confirmed', checked: false } });
          handleClose();
        }}
        title='Disclaimer'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='disclaimer-modal-group'>
              <InfoCircle color='black' size='5rem' />
              <Text size='sm'>
                An SKU change, new product, new SKU or market - change request - should not be
                driven or motivated by a requirement to restrict cross-border sales
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox
              label='I have read and agreed on the above mentioned terms and conditions'
              name='confirmed'
              onChange={handleChange}
            />
          </Grid.Col>
          <Grid.Col span={4} offset={8}>
            <Button
              onClick={() => handleNext()}
              variant='gradient'
              color='primary'
              fullWidth
              disabled={!form.rfsInformation.confirmed || loading}
              style={{
                background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
                color: 'black',
              }}
            >
              {!loading ? 'Next' : <AppLoader />}
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
