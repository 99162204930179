import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { Checkbox, Button } from '@mantine/core';
import { Styles } from '../../../components/scopeComp/tableStyles';

export function MaterialTable({
  materials,
  setViewModal,
  values,
  setValues,
  disabled,
  disabledMaterials,
}) {
  const handleSelect = (event) => {
    const currentMaterials = [...values.reversal_scope];
    const value = event.target.name.split('-');
    if (event.target.checked) {
      currentMaterials.push({
        scope_id: parseInt(value[0]),
        material_id: parseInt(value[1]),
      });
    } else {
      const index = currentMaterials.findIndex(
        (i) => i.scope_id === parseInt(value[0]) && i.material_id === parseInt(value[1]),
      );
      if (index > -1) currentMaterials.splice(index, 1);
    }

    setValues({ reversal_scope: currentMaterials }, values, 'veam');
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'checkbox',
        Cell: ({ row }) => {
          const checked = (values?.reversal_scope || []).findIndex(
            (val) =>
              parseInt(val.scope_id) === parseInt(row.original.scope_id) &&
              parseInt(val.material_id) === parseInt(row.original.id),
          );

          return (
            <Checkbox
              name={`${row.original.scope_id}-${row.original.id}`}
              onChange={handleSelect}
              checked={checked > -1}
              disabled={disabled || disabledMaterials[row.original.id]}
            />
          );
        },
      },
      {
        Header: 'Material Code',
        accessor: 'material_code',
      },
      {
        Header: 'Plant',
        accessor: 'plant',
      },
      {
        Header: 'Material Description',
        accessor: 'material_description',
      },
      {
        Header: 'Impacted SKUs',
        id: 'impacted_skus',
        Cell: ({ row }) => (
          <div>
            {' '}
            <Button
              color='dark'
              radius='xl'
              onClick={() =>
                setViewModal({
                  material_id: row.original.id,
                  open: true,
                  type: 'materials',
                  scope_id: row.original.scope_id,
                })
              }
            >
              View
            </Button>
          </div>
        ),
      },
    ],
    [values, disabledMaterials],
  );

  const tableInstance = useTable({
    columns,
    data: materials,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Styles>
        <div style={{ overflowX: 'auto' }}>
          <table style={{ width: 'fitContent' }} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Styles>
    </div>
  );
}
