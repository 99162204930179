import { useParams, useSearchParams } from 'react-router-dom';
import { tabStyles } from '../../../../utils/helpers';
import { Tabs } from '@mantine/core';
import { Packmat } from './packmat';
import { Mother } from './mother';
import { useContext, useEffect, useState } from 'react';
import { axios } from '../../../../utils/axios';
import moment from 'moment';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';

const tabs = [
  {
    name: 'PACKMAT',
    value: 'packmat',
    component: (props) => <Packmat {...props} />,
  },

  {
    name: 'MOTHER SKU',
    value: 'mother',
    component: (props) => <Mother {...props} />,
  },
];

export const BomComponentPrice = ({ activeTab, stepStatusKey }) => {
  const { crNumber } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState();
  const [skus, setSkus] = useState();
  const { info } = useContext(CostingRequestsContext);
  const [activePlant, setActivePlant] = useState();

  const activeSubTab = searchParams.get('subtab') || 'packmat';
  const { producing_plant_data = [], sku_data = [] } = info;

  useEffect(() => {
    if (activeTab === 'bomComponentPrice' && sku_data.length && producing_plant_data.length) {
      fetch();
      setActivePlant(producing_plant_data[0]?.producing_plant);
    }
  }, [activeTab, producing_plant_data, sku_data]);

  const fetch = async (refresh = false) => {
    try {
      setLoading(true);
      if (refresh) await axios.get(`costing-request/step/bom-component-refresh/${crNumber}`);
      const response = await axios.get(`/costing-request/step/bom-price/${crNumber}`);
      const data = response.data.data.map((item) => {
        const material = item.IntCrSKUPlantBOMs[0] || {};
        const stepNames =
          item?.GeneralDetails?.checkstepstatus
            ?.flatMap((step) => step.IntCrSteps?.map((intCrStep) => intCrStep.name))
            .join(', ') || 'N/A';

        return {
          id: item.id,
          manual_closed: item.manual_closed || false,
          component_id: item.component_id,
          sku_number: item.int_cr_sku_selector.sku,
          sku_description: item.int_cr_sku_selector.sku_description,
          material_number: material.component,
          material_description: material.component_description,
          material_status: material.plant_specific_status,
          price_updated: material.price_submitted_on
            ? moment(material.price_submitted_on).format('DD/MM/YYYY')
            : 'N/A',
          not_procurement_material: material.not_procurement_material,
          urgency: item?.int_cr_record?.urgency || 'N/A',
          producing_plant: item.int_cr_producing_plant.producing_plant,
          base_unit: material.price_unit,
          uom: material.uom,
          currency: material.currency,
          cost_value: material.planned_price_1,
          fpna_analyst_responsible: item?.costing_ticket_assigned?.name || 'N/A',
          fpna_analyst_responsible_id: item?.costing_ticket_assigned?.id || null,
          asm: material.asm,
          costing_request_id: item.costing_request_id,
          start_date: item.start_date,
          procurement_spoc: item?.step_assigned?.name || 'N/A',
          procurement_spoc_id: item?.step_assigned?.id || null,
          mother_costing_request_id: item?.GeneralDetails?.costing_request_id || 'N/A',
          mother_costing_request_created_on: item?.GeneralDetails
            ? moment(item?.GeneralDetails?.costing_request_id.createdAt).format('DD/MM/YYYY')
            : 'N/A',
          pack_mat_price_requesting_date: item.start_date
            ? moment(item.start_date).format('DD/MM/YYYY')
            : 'N/A',
          pack_mat_price_requesting_date_plain: item.start_date,
          monther_cr_status: item?.GeneralDetails?.IntCrStatus?.status || 'N/A',
          step_names: stepNames,
          comment: material.comment,
          production_plant: item?.int_cr_producing_plant?.producing_plant,
          planned_price_date_1: material.planned_price_date_1,
        };
      });
      setSkus(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tabs
        keepMounted={false}
        value={activeSubTab}
        onTabChange={(value) => setSearchParams({ tab: activeTab, subtab: value })}
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
      >
        <Tabs.List>
          {tabs.map((tab, index) => (
            <Tabs.Tab value={tab.value} key={`bom_componnet_price_${index}`} className='menu-tabs'>
              {tab.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabs.map((tab, index) => (
          <Tabs.Panel value={tab.value} key={`bom_componnet_price_content_${index}`}>
            {tab.component({
              activeSubTab,
              loading,
              skus,
              fetch,
              activePlant,
              setActivePlant,
              stepStatusKey,
            })}
          </Tabs.Panel>
        ))}
      </Tabs>
    </>
  );
};
