/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState, useContext } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  Button,
  Select,
  Tabs,
  MantineProvider,
  useMantineTheme,
  rem,
  Tooltip,
  TextInput,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { RfsContext } from '../../contexts/RfsContext';
import { ChevronDown } from 'tabler-icons-react';
import { IconSearch, IconSearchOff, IconRefresh, ChevronsDown } from '@tabler/icons-react';

/* import IndivisualStepsteamsModal from '../../pages/reports/indivisualStepModal'; */
import { axios } from '../../utils/axios';

//CSV Download
import csvDownload from 'json-to-csv-export';
import moment from 'moment';
import PercentReport from './teamsAndStepsPercent';
import { DownloadExcel } from '../../common/excel';
import { TableSearch } from '../../common/table/mantine/search';

const TeamsStepsDaysReport = ({ columns, setValidationErrors, unique, enableEditing }) => {
  const [activeTab, setActiveTab] = useState('percent');
  const [rowSelection, setRowSelection] = useState({});
  const [filteredRows, setFilteredRows] = useState([]);
  const [successData, setSuccessData] = useState([]);
  const globalTheme = useMantineTheme;

  //Custom search
  const [selectedGatekeeper, setGatekeeper] = useState('Nothing Selected');

  const [selectedYear, setYear] = useState(moment().year().toString());
  const [selectedFastrack, setFastrack] = useState('All');
  const [gatekeepers, setGatekeepers] = useState([]);
  const [yeardropdown, setYearfilter] = useState([]);

  const [datas, setData] = useState([]);
  // const [successData, setSuccessData] = useState([]);
  const { fetchStepsTeamsdata } = useContext(RfsContext);
  const [enabled, setEnabled] = useState(false);
  const [searchOn, setSearchOn] = useState(false);
  const [toBeSearched, setToBeSearched] = useState('');

  //READ hook (get data in api)
  function useGet() {
    let gatekeper = selectedGatekeeper == 'Nothing Selected' ? '' : selectedGatekeeper;
    let fastrack = selectedFastrack == 'All' ? 2 : selectedFastrack == 'Fast track' ? 1 : 0;
    let payload = { gatekeeper: gatekeper, year: parseInt(selectedYear), fastrack: fastrack };

    return useQuery({
      queryKey: ['fetchStepsTeamsdata', payload],
      queryFn: () => fetchStepsTeamsdata(payload),
      refetchOnWindowFocus: false,
      enabled: enabled,
    });
  }

  //call READ hook
  const {
    data: fetchedData = [],
    isError,
    isFetching,
    isLoading,
    error,
    status,
    refetch,
  } = useGet();

  function fetchData() {
    axios
      .get('/reports/gatekeepers')
      .then(
        (res) => {
          let response = res.data.data;
          response.push({
            id: 0,
            rfs_number: '',
            name: 'Nothing Selected',
            email: 'Nothing Selected',
          });

          const unique = response.filter((obj, index) => {
            return index === response.findIndex((o) => obj.name === o.name);
          });
          setGatekeepers(unique);
        },
        (err) => {},
      )
      .finally(() => {});
  }

  function fetchSubmissionDate() {
    axios
      .get('/reports/submissionDate')
      .then(
        (res) => {
          let response = res.data.data;
          setYearfilter(response.reverse());
          setYear(response[0]['year'].toString());
        },
        (err) => {},
      )
      .finally(() => {});
  }

  useEffect(() => {
    if (status == 'success' && !isFetching) {
      let data = fetchedData.data.data;
      setData(data);
      setFilteredRows(data);
      setSuccessData(data);
      fetchData();
      setEnabled(false);
    }
  }, [status, isFetching]);

  useEffect(() => {
    fetchSubmissionDate();
    setEnabled(true);
  }, []);

  const clearFilters = () => {
    setGatekeeper('Nothing Selected');
    //setYear(moment().year().toString());
    fetchSubmissionDate();
    setFastrack('All');
    setEnabled(true);
  };

  const applayFilters = () => {
    refetch();
    setEnabled(false);
  };

  //Excel Download------------
  const readyExport = () => {
    let exportdata = removeFirstElement(datas);

    exportdata = exportdata.map((item) => ({
      Steps: item.Steps,
      Teams: item.Teams,
      Duration: item.Duration,
      January: item.January,
      February: item.February,
      March: item.March,
      April: item.April,
      May: item.May,
      June: item.June,
      July: item.July,
      August: item.August,
      September: item.September,
      October: item.October,
      November: item.November,
      December: item.December,
    }));

    return exportdata;
  };

  const removeFirstElement = (data) => {
    data = data.map((item, index) => {
      Object.keys(item).forEach(function (field) {
        if (item[field] == null) {
          item[field] = '';
        }
      });

      if (item['data_id']) delete item['data_id'];
      return item;
    });
    return data;
  };

  const filterMultiSearch = (input, filteredData) => {
    setData(input.length > 0 ? filteredData : successData);
    setFilteredRows(input.length > 0 ? filteredData : successData);
  };

  const resetAll = () => {
    setSearchOn(false);
    //setAllSelected(false);
    setRowSelection({});
    // setAllEditOn(false);
    // setSwitchChecked(false);
    setData(successData);
    setFilteredRows(successData);
  };

  const table = useMantineReactTable({
    columns,
    data: datas,
    enableGrouping: true,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: '', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: enableEditing,
    getRowId: (row) => row[unique],
    enableRowSelection: false,
    selectDisplayMode: '',
    enableGlobalFilter: false,
    enableSelectAll: false,
    initialState: {
      showColumnFilters: true,
      columnPinning: {
        left: ['Steps', 'Teams'],
      },
      columnVisibility: { data_id: false },
    },
    selectAllMode: '',
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Tooltip label='Reset All'>
              <IconRefresh onClick={resetAll} />
            </Tooltip>

            <div
              onClick={() => {
                if (searchOn) setFilteredRows(successData);
                setSearchOn(searchOn ? false : true);
              }}
            >
              <Tooltip label='Search All'>{searchOn ? <IconSearchOff /> : <IconSearch />}</Tooltip>
            </div>
          </div>

          {searchOn && (
            <TableSearch
              unique={unique}
              columns={columns}
              toBeSearched={toBeSearched}
              setToBeSearched={setToBeSearched}
              filterMultiSearch={filterMultiSearch}
              data={successData}
            />
          )}
        </>
      );
    },
    state: {
      showAlertBanner: isError,
      showProgressBars: isFetching,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  const icon = <ChevronDown style={{ width: rem(16), height: rem(16) }} />;

  return (
    <>
      <Tabs
        color='primary'
        style={{
          marginBottom: '1rem',
          marginTop: '1rem',
          fontSize: '18px',
          FontWeight: 'bold',
        }}
        value={activeTab}
        onTabChange={setActiveTab}
      >
        <Tabs.List>
          <Tabs.Tab value='percent'>
            <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#403b3b' }}>
              Teams and Steps (%)
            </div>
          </Tabs.Tab>
          <Tabs.Tab value='days'>
            <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#403b3b' }}>
              Teams and Steps (Days)
            </div>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='percent'>{activeTab === 'percent' && <PercentReport />}</Tabs.Panel>

        <Tabs.Panel value='days'>
          <div
            className='downLoadBtn'
            style={{
              marginBottom: '1rem',
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                marginTop: '1.8rem',
              }}
            >
              <DownloadExcel
                fetch={() => readyExport()}
                filename={'Download_Report_' + moment().format('YYYY-MM-DD HH:mm:ss')}
              />
            </div>

            <Select
              size='md'
              color='primary'
              w={200}
              label='Select Gatekeeper'
              placeholder='Select Gatekeeper'
              onChange={(value) => {
                setEnabled(false);
                setGatekeeper(value);
              }}
              data={gatekeepers.map((column, index, arr) => {
                return { value: column.email, label: column.name };
              })}
              value={selectedGatekeeper}
              sx={{
                '.mantine-Select-dropdown': {
                  backgroundColor: '#ffffff !important',
                  zIndex: '1',
                },
              }}
              searchable={true}
              style={{ height: '3.6rem !important', marginLeft: '1rem' }}
            />

            <Select
              size='md'
              color='primary'
              w={160}
              label='Select Year'
              placeholder='Select Year'
              onChange={(value) => {
                setEnabled(false);
                setYear(value);
              }}
              data={yeardropdown.map((column, index, arr) => {
                return { value: column.year.toString(), label: column.year.toString() };
              })}
              value={selectedYear}
              sx={{
                '.mantine-Select-dropdown': {
                  backgroundColor: '#ffffff !important',
                  zIndex: '1',
                },
              }}
              searchable={true}
              style={{ height: '3.6rem', marginLeft: '1rem' }}
            />

            <Select
              size='md'
              color='primary'
              w={160}
              label='Fastrack'
              placeholder='Fastrack'
              onChange={(value) => {
                setEnabled(false);
                setFastrack(value);
              }}
              data={['All', 'Fast track', 'Not Fast track']}
              value={selectedFastrack}
              sx={{
                '.mantine-Select-dropdown': {
                  backgroundColor: '#ffffff !important',
                  zIndex: '1',
                },
              }}
              searchable={true}
              style={{
                height: '3.6rem',
                color: 'rgb(33 37 41 / 66%)',
                marginLeft: '1rem',
                marginRight: '1rem',
              }}
            />

            <Button
              onClick={() => {
                applayFilters();
              }}
              color='primary'
              style={{
                marginTop: '1.8rem',
                height: '2.5rem',
                background: 'linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%)',
                color: 'black',
              }}
            >
              Apply
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              color='default'
              size='md'
              style={{
                marginTop: '1.8rem',
                height: '2.5rem',
                marginLeft: '1rem',
                background: 'rgb(251 250 248)',
                color: 'black',
              }}
            >
              Reset
            </Button>
          </div>
          <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 8 }}>
            <MantineReactTable table={table} enablePinning />
          </MantineProvider>
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default TeamsStepsDaysReport;
