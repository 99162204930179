export const checkDiff = (main, reference) => {
  const diff =
    parseFloat(main) && parseFloat(reference) ? parseFloat(main) !== parseFloat(reference) : false;
  const difference =
    parseFloat(main) && parseFloat(reference) ? parseFloat(main) - parseFloat(reference) : 0;
  return { diff: diff, variance: parseFloat(difference).toFixed(2) };
};

export const checkIfFlagged = (requested, primary) => {
  if (!primary) return false;
  return (
    parseFloat(requested?.quantity ?? '') !== parseFloat(primary?.quantity ?? '') &&
    parseFloat(requested?.charge_quantity ?? '') !== parseFloat(primary?.charge_quantity ?? '') &&
    parseFloat(requested?.operation_quantity ?? '') !==
      parseFloat(primary?.operation_quantity ?? '')
  );
};

const getVariance = (main = 0, ref = 0) => {
  const variance = parseFloat(main) - parseFloat(ref);
  return (variance < 0 ? -variance : variance).toFixed(3);
};

const getFinalValue = (main, ref, checkVar) => {
  const parseMain = main ?? 'N/A';
  const parsedRef = ref ?? 'N/A';
  
  if (checkVar) {
    const mainCost = parseMain !== 'N/A' ? parseFloat(main).toFixed(3) : 'N/A';
    const refCost = parsedRef !== 'N/A' ? parseFloat(ref).toFixed(3) : 'N/A';
    const variance = mainCost !== 'N/A' && refCost !== 'N/A' ? getVariance(main, ref) : 'N/A';
    return [`Main: ${mainCost}`, `Ref: ${refCost}`, `Var: ${variance}`].join(',');
  } else {
    const mainCost = parseMain !== 'N/A' ? main : 'N/A';
    const refCost = parsedRef !== 'N/A' ? ref : 'N/A';
    return [`Main: ${mainCost}`, `Ref: ${refCost}`].join(',');
  }
};

export const transformData = (apiData) => {
  return apiData.map((ele, index) => {
    const isFlagged = checkIfFlagged(ele, ele.reference_bom?.[0]);
    const hasReference = ele.reference_bom.length > 0 ? true : false;
   
    return {
      ...ele,
      flagged: isFlagged,
      hasReference: hasReference,
      itemModified: false,
      indexId: index,
      duration_excel: getFinalValue(ele?.quantity, ele?.reference_bom?.[0]?.quantity, true),
      unit_excel: getFinalValue(
        ele?.component_unit,
        ele?.reference_bom?.[0]?.component_unit,
        false,
      ),
      activity_type_excel: getFinalValue(
        ele?.activity_type,
        ele?.reference_bom?.[0]?.activity_type,
        false,
      ),
      charge_qty_excel: getFinalValue(
        ele?.charge_quantity,
        ele?.reference_bom?.[0]?.charge_quantity,
        true,
      ),
      charge_qty_unit_excel: getFinalValue(
        ele?.charge_quantity_unit,
        ele?.reference_bom?.[0]?.charge_quantity_unit,
        false,
      ),
      operation_qty_excel: getFinalValue(
        ele?.operation_quantity,
        ele?.reference_bom?.[0]?.operation_quantity,
        true,
      ),
      operation_qty_unit_excel: getFinalValue(
        ele?.operation_quantity_unit,
        ele?.reference_bom?.[0]?.operation_quantity_unit,
        false,
      ),
      total_value_excel: getFinalValue(
        ele?.total_value,
        ele?.reference_bom?.[0]?.total_value,
        true,
      ),
      price_excel : getFinalValue(
        ele?.RequestedSkuCostEstimation?.price,
        ele?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation?.price,
        false
      ),
      price_unit_excel: getFinalValue(
        ele?.RequestedSkuCostEstimation?.un,
        ele?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation?.un,
        false
      ),
      price_per_unit_excel: getFinalValue(
        ele?.RequestedSkuCostEstimation?.price_unit,
        ele?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation?.price_unit,
        false
      )
    };
  });
};
