import React, { useContext, useState, useEffect } from 'react';
import { Accordion, Button } from '@mantine/core';
import { Row, Col, Modal } from 'react-bootstrap';
import { CheckIcon } from '@modulz/radix-icons';
import { useNavigate, useParams } from 'react-router-dom';

import { Liquids } from './Liquids';
import { Materials } from './Materials';
import { Skus } from './Skus';
import { RfsContext } from '../../contexts/RfsContext';
import RfsLayout from '../../components/layouts/RfsLayout';
import ContentPaper from '../../components/layouts/rfs/Content';
import { AppLoader } from '../../components/common/loader';
import { routes } from '../../routes';
import { rfsStatus } from '../../constants';

const onlySkuRfs = ['Open SKU to market', 'Delisting', 'Production Site'];

const SelectSku = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    form,
    setForm,
    storeScope,
    loading,
    info,
    infoLoading,
    fetchLiquids,
    fetchMaterials,
    fetchSkus,
    fetchMarkets,
    reset,
  } = useContext(RfsContext);
  const [modal, setModal] = useState(false);
  const [osku, setOsku] = useState(null);
  const [state, handlers] = useState({
    total: 3,
    initialItem: -1,
  });
  const { rfs_type } = form.rfsInformation;

  useEffect(() => {
    if (form.rfsInformation.rfs_type) {
      if (
        ['Open SKU to market', 'Delisting', 'Production Site'].includes(
          form.rfsInformation.rfs_type,
        )
      )
        setOsku(true);
      else setOsku(false);
    }
  }, [form?.rfsInformation?.rfs_type]);

  useEffect(() => {
    if (!Object.is(osku, null) && rfs_type && rfs_type.length > 0) {
      osku && fetchSkus();
      osku && fetchMarkets();
      !osku && !['Supplier Change'].includes(rfs_type) && fetchLiquids();
      !osku && fetchMaterials();
    }
  }, [osku, rfs_type]);

  useEffect(() => {
    if (params.rfsNumber) {
      info(params.rfsNumber);
    }

    return () => reset();
  }, [params.rfsNumber]);

  const handleClose = () => {
    setModal(false);
  };

  const handleSelect = (data, type, checkbox, selectedIds) => {
    const values = { ...form };
    values.scope[type] = data;

    let liquids = {};
    let materials = {};
    let skus = {};

    if (type === 'liquids') {
      materials = { ...values.selectedMaterials };
      skus = { ...values.selectedSkus };
      liquids = { ...selectedIds };
    }

    if (type === 'materials') {
      liquids = { ...values.selectedLiquids };
      skus = { ...values.selectedSkus };
      materials = { ...selectedIds };
    }
    if (type === 'skus') {
      materials = { ...values.selectedMaterials };
      liquids = { ...values.selectedLiquids };
      skus = { ...selectedIds };
    }

    setForm({
      ...form,
      selectedLiquids: {
        ...liquids,
      },
      selectedMaterials: {
        ...materials,
      },
      selectedSkus: {
        ...skus,
      },
    });
  };

  const isSkuValid = () => {
    return Object.keys(form.selectedSkus).length < 20;
  };

  const handleClick = () => {
    if (
      (Object.keys(form.selectedLiquids).length > 0 ||
        Object.keys(form.selectedMaterials).length > 0 ||
        Object.keys(form.selectedSkus).length > 0) &&
      isSkuValid()
    ) {
      setModal(false);
      storeScope(
        {
          liquids: form.selectedLiquids,
          materials: form.selectedMaterials,
          skus: form.selectedSkus,
        },
        () => navigate(routes.submission(params.rfsNumber).scopeDefine),
      );
    } else {
      setModal(true);
    }
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        <Button
          disabled={loading}
          className='confirm'
          color='primary'
          variant='gradient'
          gradient={{ from: '#e3af32', to: '#f4e00f' }}
          radius='md'
          size='md'
          fullWidth
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
          onClick={() => handleClick()}
        >
          {!loading ? 'Confirm Choices' : <AppLoader />}
        </Button>
      </Col>
    </Row>
  );

  if (
    form.rfsInformation.status &&
    ![
      rfsStatus.draft,
      rfsStatus.tech_feasible,
      rfsStatus.tech_feasible2,
      rfsStatus.supplier_pmo2_active,
    ].includes(form.rfsInformation.status)
  ) {
    navigate(routes.submission(params.rfsNumber).overView);
  }

  return (
    <RfsLayout>
      <ContentPaper page={'select-sku'} footer={<Footer />}>
        {!infoLoading ? (
          <Accordion
            state={state}
            style={{
              minHeight: '100vh',
            }}
          >
            <Accordion.Item className={!osku && 'disabled-accordion'} value='0'>
              <Accordion.Control disabled={!osku}>{'SKU Selector'}</Accordion.Control>
              <Accordion.Panel>
                <Skus handleSelect={handleSelect} />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={osku && 'disabled-accordion'} value='1'>
              <Accordion.Control disabled={osku || ['Supplier Change'].includes(rfs_type)}>
                {'Liquids Selector'}
              </Accordion.Control>
              <Accordion.Panel>
                <Liquids handleSelect={handleSelect} />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={osku && 'disabled-accordion'} value='2'>
              <Accordion.Control disabled={osku}>{'Materials Selector'}</Accordion.Control>
              <Accordion.Panel>
                <Materials handleSelect={handleSelect} />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        ) : (
          <AppLoader size={'lg'} center={true} />
        )}

        <Modal show={modal} onHide={handleClose} backdrop='static' keyboard={false}>
          <Modal.Header>
            <Modal.Title>
              {isSkuValid() ? 'Please select some rows' : 'Please remove some SKUs'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {' '}
            {isSkuValid()
              ? 'You have currently not selected any rows to be edited. Please select atleast one row to continue to the next page.'
              : 'You have currently selected over 20 SKUs to be edited. Please remove some SKUs to continue to the next page.'}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='gradient' color='red' onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </ContentPaper>
    </RfsLayout>
  );
};

export default SelectSku;
