import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { Modal, Container } from '@mantine/core';

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 10rem;
      padding: 0.8rem;
      text-align: center;
      width: 22rem;

      :last-child {
        border-right: 0;
      }
    }

    #header-row {
      background-color: #d8d8d8;
    }
    tr:nth-child(even) {
      background-color: #cdcdcd;
    }
    tr:nth-child(odd) {
      background-color: #fff;
    }
  }
  input {
    background-color: #fff;
  }
`;

const SkuDetailTable = ({ data = [] }) => {
  const getFormattedValue = (data) => {
    if (data) {
      data = data.toString();
      data = data.indexOf('.') > -1 ? parseFloat(data).toFixed(2) : data;
    }
    return data;
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Market',
        accessor: 'market',
      },
      {
        Header: 'Volume',
        accessor: 'volume',
        Cell: ({ row }) => {
          return <div>{getFormattedValue(row.original.volume)}</div>;
        },
      },
      {
        Header: 'Type',
        id: 'type',
        Cell: ({ row }) => (row.original.new_market ? 'New' : 'Existing'),
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        {rows.length === 0 ? (
          <div className='text-center'>No data found</div>
        ) : (
          <table style={{ width: 'fitContent' }} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Styles>
  );
};

export const SkuDetailModal = ({ form, viewModal, setViewModal }) => {
  return (
    <Modal
      opened={viewModal.open}
      onClose={() => setViewModal({ open: false, scope_id: null })}
      title='Scope Selection'
      className='confirm-modal'
    >
      <Container>
        <SkuDetailTable
          data={form.scopeSelectedSkus.filter((item) => item.scope_id === viewModal.scope_id)}
        />
      </Container>
    </Modal>
  );
};
