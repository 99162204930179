import React, { useState, useContext, useCallback, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Text, SimpleGrid } from '@mantine/core';

import { Link, useNavigate, useParams } from 'react-router-dom';
import TechFeasibilityModal from '../../../components/modal/techFeasibilityModal/modal';

import ConfirmModal from '../../../components/modal/ConfirmModal';
import SubmitProjectModal from '../../../components/modal/SubmitProjectModal';
import { AppLoader } from '../../../components/common/loader';

import ProjectName from '../../../components/projectName/projectName';

import { minWordsValidation } from '../rfsInitiationFormDLST/rfsInitiationFormDLST';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './rfsInitiationForm.css';
import '../../../components/forms/rfsInitiationForm/rfsInitiationForm.css';
import { routes } from '../../../routes';
import { RfsContext } from '../../../contexts/RfsContext';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { Pencil } from 'tabler-icons-react';
import { AuthContext } from '../../../contexts/AuthContext';
import { rfsStatus, roles } from '../../../constants';
import withConfirm from '../../../components/common/confirm-dialog';
import { NpsComponent } from '../../../common/nps';
import { NpsIds } from '../../../constants';

import { axios } from '../../../utils/axios';

import ScopeCompReview from './ScopeCompReview';
import { DelistingContext, DelistingProvider } from '../../../contexts/DelistingContext';
import { useRef } from 'react';

function Rfsinitiation2DLST(props) {
  const { confirm, setConfirmLoading } = props;
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitProjectModal, setSubmitProjectModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [tfModal, setTfModal] = useState(false);
  const [npsOpened, setNpsOpened] = useState(false);

  const [vis1, setVis1] = useState(true);
  const [market, setMarket] = useState(true);

  const { form, downloadFile, attachmentLoading, infoLoading, deleteRfs } = useContext(RfsContext);

  const { userRole, userEmail } = useContext(AuthContext);

  const {
    initiator_email = null,
    project_name = '',
    initiator_position = '',
    initiator_team = '',
    backupUsers = [],
    allowedUsers = [],
    first_shipment = '',
    sales_business_unit = [],
    technicalLead = [],
    gatekeeper = [],
    project_purpose = '',
    project_details = '',
    comments = '',
    country_legal_spoc = '',
    status,
    rfs_type,
    impactedPlants,
  } = form.rfsInformation;

  const { emailAttachment } = form.attachments;

  const {
    delistingForm,
    marketLoading,
    selectedMarkets,
    setScopeErrors,
    fetchDelisting,
    validateDelisting,
    fetchSelectedMarkets,
  } = useContext(DelistingContext);

  const scopeSelectedSkus = form.scopeSelectedSkus;
  const delistingFormRef = useRef(null);
  delistingFormRef.current = delistingForm;

  useEffect(() => {
    fetchDelisting(params.rfsNumber);
    setTimeout(() => {
      setMarket(false);
    }, 2000);
  }, [params.rfsNumber, fetchDelisting]);

  useEffect(() => {
    fetchSelectedMarkets(params.rfsNumber, delistingForm);
  }, [params.rfsNumber, delistingForm]);

  useEffect(() => {
    for (const key in delistingForm) {
      if (delistingForm.hasOwnProperty(key) && selectedMarkets.hasOwnProperty(key)) {
        const impactedMarketsArray = selectedMarkets[key];
        delistingForm[key].impacted_markets = impactedMarketsArray;
      }
    }
  }, [selectedMarkets]);

  const getUsers = (type) => {
    if (type === 'allowed') {
      let users = (allowedUsers || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'backup') {
      let users = (backupUsers || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'gatekeeper') {
      let users = (gatekeeper || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'technical') {
      let users = (technicalLead || []).map((item) => item.name);
      return users?.join(', ');
    } else {
      return null;
    }
  };

  const FileList = ({ type, files = [] }) => {
    let { rfsNumber } = props;
    rfsNumber = rfsNumber.replace(/[^a-zA-Z0-9]/g, '');

    return (files || []).length > 0 ? (
      <ul style={{ fontSize: '0.8rem' }}>
        {files.map((file, index) => (
          <li
            style={{
              color: '#e5b611',
              cursor: 'pointer',
            }}
            key={index}
            onClick={() => downloadFile(file.name, rfsNumber.toLowerCase())}
          >
            {file.name}
          </li>
        ))}
      </ul>
    ) : (
      <p style={{ fontSize: '0.8rem' }}> No Files Attached </p>
    );
  };

  const isDisabled = () => {
    return (
      Object.keys(delistingFormRef.current).length === 0 ||
      !initiator_position ||
      !initiator_team ||
      backupUsers.length === 0 ||
      gatekeeper.length === 0 ||
      !sales_business_unit ||
      (sales_business_unit || []).length === 0 ||
      minWordsValidation(project_purpose) ||
      minWordsValidation(project_details)
    );
  };

  const setSubmissionDateAndStatus = () => {
    setConfirmLoading(true);

    axios
      .put(
        `/rfs/submit/${params.rfsNumber}`,
        {},
        {
          headers: {
            rfsId: form.rfsInformation.id,
          },
        },
      )
      .then(
        (res) => {
          setConfirmLoading(false);
          setConfirmModal(true);
        },
        (error) => {
          setConfirmLoading(false);
        },
      );
  };

  const handleSubmit = useCallback(() => {
    setSubmitted(true);
    handleSubmit1();
  }, [confirm]);

  const handleSubmit1 = () => {
    const errors = validateDelisting(delistingFormRef.current);

    setScopeErrors({
      errors: errors.errors,
      delistingErrors: errors.delistingErrors,
    });

    setTimeout(() => {
      if (Object.keys(errors.errors).length > 0) {
        return setSubmitProjectModal(true);
      } else if (isDisabled()) {
        return setSubmitProjectModal(true);
      } else {
        return confirm(
          () => {
            setSubmissionDateAndStatus();
          },
          {
            title: 'Confirm!',
            description:
              'Are you sure you want to submit this project? Once submitted you will not be able to access it again!',
            confirmationText: 'Submit',
          },
        );
      }
    }, 500);
  };

  const editScope = () => {
    let access = false;
    if (!props.disableButtons) {
      if (
        (initiator_email === userEmail && status === rfsStatus.draft) ||
        userRole === roles.superAdmin
      ) {
        access = true;
      }
    }

    return access;
  };

  const handleDelete = useCallback(() => {
    confirm(() => deleteRfs(() => navigate(routes.rfs.my)), {
      title: 'Confirm!',
      description:
        'Are you sure you want to delete this project? Once deleted you will not be able to access it again!',
      confirmationText: 'Delete',
    });
  }, [confirm]);

  const canDelete = () => {
    return userEmail?.toLowerCase() === initiator_email?.toLowerCase();
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        {canDelete() && !infoLoading && (
          <Button
            className='delete'
            color='red'
            radius='md'
            size='md'
            fullWidth
            style={{ marginRight: '1rem', color: 'white', width: '200px' }}
            onClick={() => handleDelete()}
          >
            Delete Project
          </Button>
        )}
      </Col>
      <Col md='auto'>
        <Button
          disabled={market}
          className='submit'
          color='primary'
          radius='md'
          variant='gradient'
          gradient={{ from: '#e3af32', to: '#f4e00f' }}
          size='md'
          fullWidth
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
          onClick={handleSubmit}
        >
          {marketLoading ? <AppLoader /> : 'Submit Project'}
        </Button>
      </Col>
    </Row>
  );

  const getImpactedPlants = () => {
    let plants = (impactedPlants || []).map((item) => item);
    return plants?.join(', ');
  };

  const OpenSkuDetails = () => (
    <>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        {/* <Col className="Empty" md={1} style={{width:'50%'}}></Col> */}

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
              Initiator Position:
            </Text>
            <Text size='sm' weight={400}>
              {initiator_position}
            </Text>
          </SimpleGrid>
        </Col>

        <Col md={{ span: 5 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
              Initiator Team:
            </Text>
            <Text size='sm' weight={400}>
              {initiator_team}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>

      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={(backupUsers || []).length === 0 ? 'red' : ''}>
              Selected Backup Users:
            </Text>
            <Text size='sm' weight={400}>
              {getUsers('backup') === '' ? 'No users selected' : getUsers('backup')}
            </Text>
          </SimpleGrid>
        </Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
              Selected Gatekeeper:
            </Text>
            <Text size='sm' weight={400}>
              {getUsers('gatekeeper') === '' ? 'No users selected' : getUsers('gatekeeper')}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>

      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={!first_shipment ? 'red' : ''}>
              First Shipment Date:
            </Text>
            <Text size='sm' weight={400}>
              {first_shipment}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>

      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={sales_business_unit.length === 0 ? 'red' : ''}>
              Sales Business Unit:
            </Text>
            <Text size='sm' weight={400}>
              {sales_business_unit?.join(', ')}
            </Text>
          </SimpleGrid>
        </Col>

        {!vis1 && (
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
              <Text size='sm' weight={600} color={!country_legal_spoc ? 'red' : ''}>
                Country/ Legal SPOC:
              </Text>
              <Text size='sm' weight={400}>
                {country_legal_spoc === null
                  ? 'No User Selected'
                  : JSON.parse(country_legal_spoc).name}
              </Text>
            </SimpleGrid>
          </Col>
        )}
      </Row>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Row style={{ marginTop: '0.5rem' }}>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>

          <Col md={{ span: 2 }}>
            {!project_purpose || minWordsValidation(project_purpose) ? (
              <Link to={routes.submission(params.rfsNumber).info}>
                <Text
                  size='sm'
                  weight={600}
                  color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
                >
                  Project Purpose:
                </Text>
              </Link>
            ) : (
              <Text
                size='sm'
                weight={600}
                color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
              >
                Project Purpose:
              </Text>
            )}
          </Col>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>
          <Col md={{ span: 8 }}>
            <Text size='sm' weight={400}>
              {project_purpose}
            </Text>
          </Col>
        </Row>

        <Row style={{ marginTop: '0.5rem' }}>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>

          <Col md={{ span: 2 }}>
            {!project_details || minWordsValidation(project_details) ? (
              <Link to={routes.submission(params.rfsNumber).info}>
                <Text
                  size='sm'
                  weight={600}
                  color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
                >
                  Project Explanation:
                </Text>
              </Link>
            ) : (
              <Text
                size='sm'
                weight={600}
                color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
              >
                Project Explanation:
              </Text>
            )}
          </Col>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>
          <Col md={{ span: 8 }}>
            <Text size='sm' weight={400}>
              {project_details}
            </Text>
          </Col>
        </Row>
      </Row>

      <Row style={{ marginTop: '0.5rem' }}>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 2 }}>
          <Text size='sm' weight={600}>
            Any Additional Comments:
          </Text>
        </Col>
        <Col
          md={1}
          style={{
            width: '3.8%',
            flex: '0 0 3.8%',
            maxWidth: ' 3.8%',
          }}
        ></Col>
        <Col md={{ span: 8 }}>
          <Text size='sm' weight={400}>
            {comments}
          </Text>
        </Col>
      </Row>
    </>
  );

  return (
    <ContentPaper page='submission-review' footer={editScope() ? <Footer /> : null}>
      {infoLoading || marketLoading ? (
        <AppLoader size='lg' center />
      ) : (
        <div>
          <ProjectName Pnumber={project_name} initialItem={props.initialItems} />

          <Row>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                GENERAL DETAILS{' '}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).info, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
            </Col>
          </Row>

          <>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              {/* <Col className="Empty" md={1} style={{width:'50%'}}></Col> */}

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2}>
                  {!initiator_position ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
                        Initiator Position:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
                      Initiator Position:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {initiator_position}
                  </Text>
                </SimpleGrid>
              </Col>

              <Col md={{ span: 5 }}>
                <SimpleGrid cols={2}>
                  {!initiator_team ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
                        Initiator Team:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
                      Initiator Team:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {initiator_team}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {(backupUsers || []).length === 0 ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={(backupUsers || []).length === 0 ? 'red' : ''}
                      >
                        Selected Backup Users:
                      </Text>
                    </Link>
                  ) : (
                    <Text
                      size='sm'
                      weight={600}
                      color={(backupUsers || []).length === 0 ? 'red' : ''}
                    >
                      Selected Backup Users:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {getUsers('backup') === '' ? 'No users selected' : getUsers('backup')}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              {vis1 && (
                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                    <Text size='sm' weight={600}>
                      Selected Allowed Users:
                    </Text>
                    <Text size='sm' weight={400}>
                      {getUsers('allowed') === '' ? 'No users selected' : getUsers('allowed')}
                    </Text>
                  </SimpleGrid>
                </Col>
              )}
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              {rfs_type.toLowerCase() !== 'production site' && (
                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                    {sales_business_unit.length === 0 ? (
                      <Link to={routes.submission(params.rfsNumber).info}>
                        <Text
                          size='sm'
                          weight={600}
                          color={sales_business_unit.length === 0 ? 'red' : ''}
                        >
                          Sales Business Unit:
                        </Text>
                      </Link>
                    ) : (
                      <Text
                        size='sm'
                        weight={600}
                        color={sales_business_unit.length === 0 ? 'red' : ''}
                      >
                        Sales Business Unit:
                      </Text>
                    )}
                    <Text size='sm' weight={400}>
                      {sales_business_unit?.join(', ')}
                    </Text>
                  </SimpleGrid>
                </Col>
              )}
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {gatekeeper?.length === 0 ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
                        Selected Gatekeeper:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
                      Selected Gatekeeper:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {getUsers('gatekeeper') === '' ? 'No users selected' : getUsers('gatekeeper')}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            {/* <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  <Text size='sm' weight={600} color={impactedPlants.length === 0 ? 'red' : ''}>
                    Impacted Plants
                  </Text>
                  <Text size='sm' weight={400}>
                    {getImpactedPlants() === '' ? 'No plants selected' : getImpactedPlants()}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row> */}

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Row style={{ marginTop: '0.5rem' }}>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                <Col md={{ span: 2 }}>
                  {!project_purpose || minWordsValidation(project_purpose) ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
                      >
                        Project Purpose:
                      </Text>
                    </Link>
                  ) : (
                    <Text
                      size='sm'
                      weight={600}
                      color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
                    >
                      Project Purpose:
                    </Text>
                  )}
                </Col>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>
                <Col md={{ span: 8 }}>
                  <Text size='sm' weight={400}>
                    {project_purpose}
                  </Text>
                </Col>
              </Row>

              <Row style={{ marginTop: '0.5rem' }}>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                <Col md={{ span: 2 }}>
                  {!project_details || minWordsValidation(project_details) ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
                      >
                        Project Explanation:
                      </Text>
                    </Link>
                  ) : (
                    <Text
                      size='sm'
                      weight={600}
                      color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
                    >
                      Project Explanation:
                    </Text>
                  )}
                </Col>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>
                <Col md={{ span: 8 }}>
                  <Text size='sm' weight={400}>
                    {project_details}
                  </Text>
                </Col>
              </Row>
            </Row>

            <Row style={{ marginTop: '0.5rem' }}>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 2 }}>
                <Text size='sm' weight={600}>
                  Any Additional Comments:
                </Text>
              </Col>
              <Col
                md={1}
                style={{
                  width: '3.8%',
                  flex: '0 0 3.8%',
                  maxWidth: ' 3.8%',
                }}
              ></Col>
              <Col md={{ span: 8 }}>
                <Text size='sm' weight={400}>
                  {comments}
                </Text>
              </Col>
            </Row>
          </>

          <Row style={{ marginTop: '1rem' }}>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                SCOPE{' '}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).scopeSelect, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
              {submitted && Object.keys(delistingFormRef.current).length === 0 && (
                <span style={{ color: 'red', fontWeight: '400', fontSize: '0.8rem' }}>
                  Required Field
                </span>
              )}
            </Col>

            <Col
              md={{ span: 2, offset: 6 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <Button
                color='primary'
                style={{ background: 'black', color: 'white' }}
                onClick={() => setTfModal(true)}
              >
                Other Projects in scope
              </Button>
            </Col>

            <Row>
              <ScopeCompReview
                disableButtons
                delistingForm={delistingForm}
                selectedMarkets={selectedMarkets}
              />
            </Row>
          </Row>

          <Row style={{ marginTop: '1rem' }}>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                ATTACHMENTS {}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).attachment, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
            </Col>
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              {emailAttachment.length === 0 ? (
                <Link to={routes.submission(params.rfsNumber).attachment}>
                  <Text size='sm' weight={600} color={'dark'}>
                    Additional Email Attachment:
                  </Text>
                </Link>
              ) : (
                <Text size='sm' weight={600}>
                  Additional Email Attachment:
                </Text>
              )}
              <Text size='sm' weight={400}>
                {attachmentLoading ? (
                  <AppLoader />
                ) : (
                  <FileList files={emailAttachment} type={'emailAttachment'} />
                )}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>
          </Row>
          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>
          </Row>

          {tfModal && <TechFeasibilityModal opened={tfModal} setOpened={setTfModal} />}

          {submitProjectModal && (
            <SubmitProjectModal opened={submitProjectModal} setOpened={setSubmitProjectModal} />
          )}

          {confirmModal && (
            <ConfirmModal
              opened={confirmModal}
              setOpened={setConfirmModal}
              pname={project_name}
              npsOpened={npsOpened}
              setNpsOpened={setNpsOpened}
              setSubmitted={setSubmitted}
            />
          )}

          {npsOpened && (
            <NpsComponent
              rfs_number={params.rfsNumber}
              opened={npsOpened}
              setOpened={setNpsOpened}
              callback={() => {
                navigate('/');
              }}
              id={NpsIds().submission}
            />
          )}
        </div>
      )}
    </ContentPaper>
  );
}

export default withConfirm(Rfsinitiation2DLST);
