const checkClosedOrInProgress = (data) => {
  let count = 0;
  data.forEach((item) => (item.status === 'Closed' ? count++ : count));

  let result = '';
  if (count === 0 && data.findIndex((item) => item.status === 'In Progress') === -1) result = 1;
  else if (count < data.length || data.findIndex((item) => item.status === 'In Progress') > -1)
    result = 2;
  else result = 3;

  return result;
};

export default checkClosedOrInProgress;
