import React, { useEffect, useState } from 'react';
import { Modal, Button, Select, NumberInput } from '@mantine/core';
import moment from 'moment';

import { axios } from '../../utils/axios';
import { AppLoader } from '../common/loader';

const options = [
  'Project Submission',
  'Technical Feasibility',
  'Trial Material Codes',
  'Supplier Name',
  'Changeover',
  'Artwork Development',
  'EAN Approval',
  'Material Contracts',
  'CQV',
  'Implementation Feasibility',
  'Material Release (From 96 to 99)',
  'Pack Mats Production',
  'Fillings/Shipment',
];

export default function GanttModal({ editModal, setEditModal, rfs_number, type, steps }) {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [optionsPS, setOptionsPS] = useState();

  useEffect(() => {
    if (steps !== undefined) {
      let stepsPS = steps.map((item) => {
        return item.child_step;
      });
      setOptionsPS(stepsPS);
    }
  }, []);

  const handleDateChange = (value, name) => {
    const data = { ...form };
    data['start_date'] = moment(value).format('YYYY-MM-DD');
    setForm(data);
  };

  const handleNameChange = (value) => {
    const data = { ...form };
    data['step_name'] = value;
    if (['ps', 'veam', 'delisting', 'osku', 'sc'].includes(type)) {
      data['sla_weeks_id'] = steps.filter((item) => {
        return item.child_step === value;
      })[0].id;
    }
    setForm(data);
  };

  const handleChange = (event) => {
    const data = { ...form };
    data['sla'] = event;

    setForm(data);
  };

  const canSubmit = () => {
    return !(form.step_name && (form.start_date || form.sla));
  };

  const handleSubmit = () => {
    setLoading(true);
    const url = {
      ps: '/production-site/gantt/',
      veam: '/veam/gantt/',
      delisting: '/delisting/gantt/',
      osku: '/openSku/sku/gantt/',
      sc: '/supplier-changes/gantt/',
    };
    const defaultUrl = '/gantt/update/';
    const data = { ...form };
    data['rfs_number'] = rfs_number;

    axios
      .put(`${url[type] ? url[type] : defaultUrl}${rfs_number}`, form)
      .then(
        (res) => {
          setEditModal(false);
        },
        (err) => {
          console.log(err);
        },
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Modal
        size='sm'
        centered
        opened={editModal}
        onClose={() => setEditModal(false)}
        title='Edit Planned Date'
      >
        <Select
          name='step_name'
          label='Step Name'
          data={
            ['ps', 'delisting'].includes(type)
              ? optionsPS
              : ['veam', 'osku', 'sc'].includes(type)
              ? steps.map((s) => s.child_step)
              : options
          }
          value={form?.step_name}
          style={{ marginBottom: '16px' }}
          radius='md'
          onChange={handleNameChange}
        />

        <NumberInput
          hideControls
          name='duration'
          label='Edit SLA (In weeks)'
          onChange={handleChange}
          max={52}
          min={1}
        />

        <Button
          variant='gradient'
          style={{ marginTop: '1rem' }}
          fullWidth
          disabled={canSubmit() || loading}
          onClick={handleSubmit}
        >
          {loading ? <AppLoader /> : 'Save'}
        </Button>
      </Modal>
    </>
  );
}
