import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import PackMatsProductionTableVEAM from './PackMatsProductionTableVEAM';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import csvDownload from 'json-to-csv-export';
import { AuthContext } from '../../../../contexts/AuthContext';
import { prodDevPermissions } from '../../../../constants';

const PackMatsProduction = ({ trials = null }) => {
  const { form, fetchPackMatsProdDataIMPL, StoreManualClosureVEAM } = useContext(RfsContext);
  const { userRole } = useContext(AuthContext);
  const { rfs_number, rfs_type = '' } = form.rfsInformation;
  const [materialData, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const save = (payload, id, callback) => {
    setLoading(true);
    StoreManualClosureVEAM(payload, id).then(() => {
      getStatusDetails(rfs_number);
      setLoading(false);
    });
  };

  useEffect(() => {
    getStatusDetails(rfs_number);
  }, [rfs_number]);

  const checkAndFilterDuplicate = (dataArr) => {
    let arr = [],
      arr2 = [];
    let newRows = dataArr.filter((item) => {
      if (arr.includes(item.id) === false) {
        arr.push(item.id);
        return item;
      }
    });

    newRows = newRows.filter((item) => {
      if (arr2.includes(item.id) === false) {
        arr2.push(item.id);
        return item;
      }
    });

    return newRows;
  };

  const getStatusDetails = (rfs_number) => {
    setLoading(true);
    fetchPackMatsProdDataIMPL(rfs_number, trials).then((res) => {
      let dataFilter = res.data.data.filter(
        (item) => item.sla_weeks_id === 219 || item.sla_weeks_id === 238,
      );

      const result = [];
      result['Materials'] = dataFilter
        .filter((item) => item.selection_type === 'materials')
        .sort((a, b) => a.id - b.id);

      setData(result);
      dataFilter.forEach((element) => {
        if ('AllStatus' in element === false) {
          setLoading(true);
          getStatusDetails(rfs_number);
        }
      });
      setLoading(false);
    });
  };

  if (loading || !rfs_type || rfs_type.length === 0) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  const permissions = prodDevPermissions[rfs_type];

  const downloadExcel = (type) => {
    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const materialHeaders = [
      'Pack Mat Code',
      'Pack Mat Description',
      'Plant',
      'Supplier Production',
      'Material On Stock at Supplier',
      'Material On Stock at ABI',
      'Action',
      'Step Deadline',
      'Step finished',
    ];
    const materials = materialData.Materials;

    materialsToExport.data = getDataToExport(materials);
    materialsToExport.filename = 'Pack_mats_production' + moment().format('YYYY-MM-DD HH:mm:ss');
    materialsToExport.headers = materialHeaders;
    csvDownload(materialsToExport);
  };

  const getDataToExport = (data) => {
    const csvArray = data.map(dataToExport);

    return csvArray;
  };

  const dataToExport = ({
    plant,
    manually_closed,
    finished_date,
    sla_date,
    status,
    AllStatus,
    material_type,
    int_rfs_material_number,
    pack_mat_material_text,
    new_material_code,
    new_material_description_intrfsml,
  }) => {
    let Code = 'N/A',
      Desc = 'N/A';

    if (material_type === 'Existing Material' || material_type === 'Remove Material') {
      Code = int_rfs_material_number ? int_rfs_material_number : 'N/A';

      Desc = pack_mat_material_text ? pack_mat_material_text : 'N/A';
    } else if (material_type === 'New Material') {
      Code = new_material_code ? new_material_code : 'N/A';

      Desc = new_material_description_intrfsml ? new_material_description_intrfsml : 'N/A';
    }
    return {
      'Pack Mat Code': Code,
      'Pack Mat Description': Desc,
      Plant: plant,
      'Supplier Production': AllStatus[239],
      'Material On Stock at Supplier': AllStatus[240],
      'Material On Stock at ABI': AllStatus[241],
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step Deadline': sla_date,
      'Step Finished': finished_date === null ? status : finished_date,
    };
  };

  return (
    <Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='primary'
          style={{
            marginTop: '1rem',
            background: 'black',
          }}
        >
          Export to CSV
        </Button>
      </div>
      {Object.keys(materialData) &&
        Object.keys(materialData).map((name, index) => {
          return (
            <Grid key={index}>
              <Grid.Col span={12}>
                <PackMatsProductionTableVEAM
                  materialDataList={Object.values(materialData)[index]}
                  type={name}
                  save={save}
                  readOnly={!permissions['pack_mats'].includes(userRole)}
                  trials={trials}
                />
              </Grid.Col>
            </Grid>
          );
        })}
    </Row>
  );
};

export default PackMatsProduction;
