import * as yup from 'yup';
import {
  materialArtworkChangeOptions,
  materialchangeOverOptions,
  materialTechnicalPackChangeOptions,
} from '../../../constants';

export const MaterialSchema = yup.object().shape({
  material_number: yup.string().nullable(),
  technical_pack_change: yup
    .array()
    .nullable()
    .when('material_number', {
      is: 'New Material',
      then: () => yup.array().nullable().required('technical_pack_change'),
    }),
  technical_quality_change: yup
    .array()
    .nullable()
    .when('material_number', {
      is: 'New Material',
      then: () => yup.array().nullable().required('technical_quality_change'),
    }),
  selected_material_number: yup
    .string()
    .nullable()
    .when('technical_pack_change', ([technical_pack_change]) => {
      if (!(technical_pack_change || []).includes(materialTechnicalPackChangeOptions.removal))
        return yup.string().nullable().required('material_number');
    }),
  artwork_change: yup.string().nullable(),
  changeover_type: yup.string().nullable().nullable().required('changeover_type'),
  changeover_date: yup
    .string()
    .nullable()
    .when('changeover_type', ([changeover_type]) => {
      if (
        [
          materialchangeOverOptions.hardChange,
          materialchangeOverOptions.notSoonerThan,
          materialchangeOverOptions.notLaterThan,
        ].includes(changeover_type)
      )
        return yup.string().nullable().required('changeover_date');
    }),
  comment: yup
    .string()
    .nullable()
    .when(['material_number', 'artwork_change'], ([material_number, artwork_change]) => {
      if (
        artwork_change === materialArtworkChangeOptions.others ||
        material_number === 'New Material'
      ) {
        return yup.string().nullable().required('comment');
      }
    }),
  selectedAffectedSkus: yup.array().min(1, 'affected_skus'),
  tech_spec_matrix: yup
    .string()
    .nullable()
    .max(20, 'max_tech_spec_value_exceeded')
    .when('material_number', {
      is: 'New Material',
      then: () =>
        yup
          .string()
          .nullable()
          .max(20, 'max_tech_spec_value_exceeded')
          .required('tech_spec_matrix'),
    }),
});
