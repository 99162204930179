import { useState } from 'react';
import {
  Group,
  Text,
  Checkbox,
  Space,
  TextInput,
  Switch,
  ActionIcon,
  Tooltip,
} from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  max-height: 260px;
  min-height: 250px;
  overflow-y: auto;
  margin-top: 16px;
`;

const RoleName = styled(Text)`
  font-weight: 500;
`;

const RoleDetails = styled(Text)`
  font-size: 12px;
  color: dimmed;
  font-weight: 400;
`;

const GroupContainer = styled(Group)`
  margin-top: 16px;
`;

const renderLabelValue = (label, value) => {
  if (!value) return null;
  return (
    <span key={label}>
      <strong>{label}:</strong> {value}
    </span>
  );
};

export const ListComponent = ({ data, selectedRoles, setSelectedRoles }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  // Function to handle checkbox toggle
  const handleRoleToggle = (roleId) => {
    setSelectedRoles((prevSelectedRoles) =>
      prevSelectedRoles.includes(roleId)
        ? prevSelectedRoles.filter((id) => id !== roleId)
        : [...prevSelectedRoles, roleId],
    );
  };

  const resetSelection = () => {
    setSelectedRoles([]);
  };

  const filteredData = data.filter(({ int_role }) =>
    int_role.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const displayedData = showSelectedOnly
    ? filteredData.filter(({ id }) => selectedRoles.includes(id))
    : filteredData;

  return (
    <div>
      <TextInput
        placeholder='Search by role name'
        label='Search'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.currentTarget.value)}
        withAsterisk
      />

      <GroupContainer position='apart'>
        <Switch
          checked={showSelectedOnly}
          onChange={(event) => setShowSelectedOnly(event.currentTarget.checked)}
          label={'Show Only Selected'}
        />

        <Tooltip label='Unselect All'>
          <ActionIcon onClick={resetSelection} title='Unselect All'>
            <IconX size={18} />
          </ActionIcon>
        </Tooltip>
      </GroupContainer>

      <Wrapper>
        {displayedData.length > 0 ? (
          displayedData.map(
            ({ int_role, country, plant, type_of_request, type_of_sku, zone, id }, index) => {
              const detailsArray = [
                renderLabelValue('Plant', plant),
                renderLabelValue('Country', country),
                renderLabelValue('Type of Request', type_of_request),
                renderLabelValue('Type of SKU', type_of_sku),
                renderLabelValue('Zone', zone),
              ].filter(Boolean);

              return (
                <div key={index}>
                  <Group noWrap>
                    <div style={{ flex: 1 }}>
                      <RoleName size='sm'>{int_role.name}</RoleName>
                      <RoleDetails>
                        {detailsArray.map((detail, idx) => (
                          <span key={idx}>
                            {detail}
                            {idx < detailsArray.length - 1 && ' | '}
                          </span>
                        ))}
                      </RoleDetails>
                    </div>
                    <Checkbox
                      checked={selectedRoles.includes(id)}
                      onChange={() => handleRoleToggle(id)}
                    />
                  </Group>
                  <Space h='md' />
                </div>
              );
            },
          )
        ) : (
          <Text size='sm' color='dimmed'>
            No results found
          </Text>
        )}
      </Wrapper>
    </div>
  );
};
