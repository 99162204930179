import { Modal, Button } from '@mantine/core';
import { useMemo, useState, useContext, useEffect } from 'react';
import MantineTable from '../../../../../common/table/mantine';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';
import { axios } from '../../../../../utils/axios';
import { useParams } from 'react-router-dom';

export const OpenPlantModal = ({ plantModal, setPlantModal }) => {
  const { info } = useContext(CostingRequestsContext);
  const [openPlants, setOpenPlants] = useState([]);
  const { crNumber } = useParams();
  const fetch = async () => {
    try {
      const res = await axios.get(`costing-request/step/vic-approval/plants/${crNumber}`);
      setOpenPlants(res?.data?.data);
    } catch (e) {}
  };

  useEffect(() => {
    fetch();
  }, [crNumber]);

  const columns = useMemo(() => [
    {
      accessorKey: 'plant',
      header: 'VaIA',
      size: 20,
    },
    {
      accessorKey: 'standard_price_per_HL',
      header: 'Standard Price',
      size: 20,
    },
    {
      id: 'denominator',
      header: 'per',
      size: 20,
      Cell: () => 1,
    },
    {
      accessorKey: 'weight_unit',
      header: 'UOM',
      size: 20,
      Cell: () => 'HL',
    },
    {
      accessorKey: 'currency',
      header: 'Curr',
      size: 20,
    },
  ]);

  return (
    <Modal
      closeOnClickOutside={false}
      opened={plantModal}
      onClose={() => setPlantModal(false)}
      title='Open Plants'
      className='toggle-modal1'
      size='lg'
      styles={{
        body: { minHeight: '500px' },
      }}
    >
      <MantineTable
        columns={columns}
        initialData={openPlants}
        enableRowSelection={false}
        loading={false}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        pageSize={5}
        enableColumnFilter={false}
        enableFilters={false}
        columnFilterDisplayMode={false}
      />
      <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className='next'
          onClick={() => setPlantModal(false)}
          color='gray'
          radius='sm'
          size='sm'
          fullWidth
          style={{
            width: '200px',
          }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
