import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button, TextInput } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import { Status99Table } from './status99Table';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { roles } from '../../../../constants';
import { AuthContext } from '../../../../contexts/AuthContext';
import { sCodeStyles } from '../../../../utils/helpers';
import { AccfComment } from '../../../../common/accf';

export const Status99 = () => {
  const { userRole } = useContext(AuthContext);
  const { form, info } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const accessors = {
    materials: 'selection_number',
    liquids: 'selection_number',
    skus: 'selection_number',
  };
  const secondaryAccessor = {
    materials: 'selection_name',
    liquids: 'selection_name',
    skus: 'selection_name',
  };
  const tertiaryAccessor = {
    materials: null,
    liquids: null,
    skus: null,
  };
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);

  const [status99, setStatus99] = useState([]);

  const { fetchStatus99, StoreManualClosure } = useContext(RfsContext);

  const [slaId, setSlaId] = useState(0);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    getData(rfs_number);
  }, []);

  const getData = (rfs_number) => {
    setLoading(true);
    fetchStatus99(rfs_number).then((res) => {
      setStatus99(res.data.data);
      setSlaId(res.data.data[0].sla_weeks_id);
      setLoading(false);
    });
  };

  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo, roles.masterDataTeam].includes(userRole))
      setDisable(false);
  }, [userRole]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < status99.length; i++) {
      arr.push(status99[i].status);
    }
    if (arr) {
      const setArray = [...new Set(arr)];
      if (
        (setArray.length === 1 && setArray.includes('Closed')) ||
        (setArray.length === 1 && setArray.includes('Not Started'))
      ) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    }
  }, [status99]);

  const save = (payload, id, callback) => {
    StoreManualClosure(payload, id).then(() => {
      refresh(rfs_number);
      info(rfs_number);
      callback();
    });
  };

  const refresh = (rfs_number) => {
    fetchStatus99(rfs_number).then((res) => {
      setStatus99(res.data.data);
    });
  };

  const sort_acctoSKUs = [...status99].sort((a, b) => a.scope_id - b.scope_id);

  const selectedScopeFn = (data) => {
    let selectedScopeList = {};
    for (let i = 0; i < data.length; i++) {
      selectedScopeList[data[i].id] = {
        selection_number: data[i].selection_number,
        selection_name: data[i].selection_name,
      };
    }
    return selectedScopeList;
  };

  var selectedScopeList = selectedScopeFn(form.selectedScope.skus);

  const dataToExport = ({
    mat_code,
    mat_desc,
    int_rfs_ps_bom,
    int_rfs_impl_supplier,
    status,
    sla_date,
    scope_id,
    finished_date,
    int_rfs_ps_sku_reference,
    other_status,
    manually_closed,
    rfs_004_plant_data,
  }) => {
    return {
      'SKU no.': selectedScopeList[scope_id]?.selection_number,
      'SKU DESC': selectedScopeList[scope_id]?.selection_name,
      Plant: int_rfs_ps_sku_reference.selected_plant,
      'Standard Price': rfs_004_plant_data[0]?.standard_price,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step Deadline': sla_date,
      'Step Finish': status === 'Closed' ? finished_date : status,
    };
  };
  const csvArray = sort_acctoSKUs.map(dataToExport);

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <Box sx={sCodeStyles}>SKU CODES</Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <CSVLink data={csvArray} filename={'Status99_' + moment().format('YYYY-MM-DD HH:mm:ss')}>
          <Button
            leftIcon={<TableExport />}
            color='primary'
            style={{
              marginBottom: '0rem',
              marginTop: '2rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>

      <Row>
        <Accordion>
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              const status99data = status99?.filter((m) => m.scope_id === item.id);

              return (
                status99data.length > 0 && (
                  <Accordion.Item
                    className='scope-label'
                    key={`key_${name}_${index}`}
                    value={`key_${name}_${index}`}
                  >
                    <Accordion.Control className={scopeErrors.errors[item.id] ? 'error-label' : ''}>
                      {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                        item[secondaryAccessor[name]]
                      }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                    </Accordion.Control>
                    <Accordion.Panel>
                      {
                        <Grid>
                          <Grid.Col span={12}>
                            <Status99Table
                              materialData={status99data}
                              save={save}
                              disable={disable}
                            />
                          </Grid.Col>
                        </Grid>
                      }
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            }),
          )}
        </Accordion>
      </Row>
      {!disable && <AccfComment slaId={slaId} status={status} rfs_number={rfs_number} />}
    </ContentPaper>
  );
};
