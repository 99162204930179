import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Accordion } from '@mantine/core';
import { useParams } from 'react-router-dom';

import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import { RfsContext } from '../../../contexts/RfsContext';
import { ReversalTable } from './table';
import { axios } from '../../../utils/axios';
import { AppLoader } from '../../../components/common/loader';
import { tabStyles } from '../../../utils/helpers';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';

const steps = {
  demandPlanning: 'Demand Planning',
  marketBlockage: 'Market Blockage',
};

export default function Reversal() {
  const params = useParams();
  const [activeTab, setActiveTab] = useState('mc');
  const [demandPlanningStatus, setDemandPlanningStatus] = useState({});
  const [marketBlockageStatus, setMarketBlockageStatus] = useState({});
  const { form } = useContext(RfsContext);
  const { scopeUnlockInfo } = useContext(ProjectOverviewContext);
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [manualClosureLoading, setManualClosureLoading] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [slaIds, setSlaIds] = useState([]);

  useEffect(() => {
    if (scopeUnlockInfo.logs.length > 0) {
      const ids = [];
      scopeUnlockInfo.logs.map((info) => {
        ids.push(info.sla_weeks_id);
      });

      setSlaIds(ids);

      if (slaIds.includes(282) || slaIds.includes(284)) setActiveTab('dp');
    }
  }, []);

  useEffect(() => {
    getData();
  }, [activeTab]);

  const getData = (loading = true) => {
    if (activeTab === 'mc') {
      getMarketBlocking(loading);
    } else {
      getDemandPlanning(loading);
    }
  };

  const getDemandPlanning = (loading = true) => {
    setInfoLoading(loading);
    axios
      .get(`/openSku/scope-unlock/reversal-dp/fetch/${params.rfsNumber}`)
      .then(
        (res) => {
          const scopeObject = {};
          res.data.data.forEach((item) => {
            if (!scopeObject[item.int_rfs_scope_selection.id]) {
              scopeObject[item.int_rfs_scope_selection.id] = [];
            }

            scopeObject[item.int_rfs_scope_selection.id].push(item);
          });

          setDemandPlanningStatus(scopeObject);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setInfoLoading(false));
  };

  const getMarketBlocking = (loading = true) => {
    setInfoLoading(loading);
    axios
      .get(`/openSku/scope-unlock/reversal-mb/fetch/${params.rfsNumber}`)
      .then(
        (res) => {
          const scopeObject = {};
          res.data.data.forEach((item) => {
            if (!scopeObject[item.int_rfs_scope_selection.id]) {
              scopeObject[item.int_rfs_scope_selection.id] = [];
            }

            scopeObject[item.int_rfs_scope_selection.id].push(item);
          });
          setMarketBlockageStatus(scopeObject);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setInfoLoading(false));
  };

  const save = (payload, id, callback) => {
    setManualClosureLoading(true);
    //updating payload to support
    payload.status_id = payload.status_ids;
    payload.comment = payload.remark;
    delete payload.status_ids;
    delete payload.remark;

    axios.post(`/manualClosure/create`, payload).then(
      () => {
        getData(false);
        setActionModal({ open: false, id: '' });
        setManualClosureLoading(false);
        if (callback) callback();
      },
      (error) => {},
    );
  };

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <ProjectName />

      <br />
      <Tabs
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='primary'
        keepMounted={false}
        value={activeTab}
        onTabChange={setActiveTab}
      >
        <Tabs.List>
          {(slaIds.includes(281) || slaIds.includes(283)) && (
            <Tabs.Tab className='topTabs' value='mc'>
              Market Blockage
            </Tabs.Tab>
          )}

          {(slaIds.includes(282) || slaIds.includes(284)) && (
            <Tabs.Tab className='topTabs' value='dp'>
              Demand Planning
            </Tabs.Tab>
          )}
        </Tabs.List>
        {(slaIds.includes(281) || slaIds.includes(283)) && (
          <Tabs.Panel value='mc'>
            {infoLoading ? (
              <AppLoader size='lg' center />
            ) : (
              <div className='table-container' style={{ overflowY: 'auto' }}>
                {' '}
                <Accordion
                  iconPosition='right'
                  classNames={{
                    label: 'accordion-label',
                    contentInner: 'accordion-innerContent',
                    control: 'accordion-control',
                  }}
                >
                  {Object.keys(marketBlockageStatus).map((key, index) => (
                    <Accordion.Item
                      className='scope-label'
                      key={`key_${index}`}
                      value={`key_${index}`}
                    >
                      <Accordion.Control>{`${marketBlockageStatus[key][0].int_rfs_scope_selection.selection_number} ${marketBlockageStatus[key][0].int_rfs_scope_selection.selection_name}`}</Accordion.Control>
                      <Accordion.Panel>
                        <ReversalTable
                          data={marketBlockageStatus[key]}
                          save={save}
                          actionModal={actionModal}
                          setActionModal={setActionModal}
                          loading={manualClosureLoading}
                          type={steps.marketBlockage}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            )}
          </Tabs.Panel>
        )}
        {(slaIds.includes(282) || slaIds.includes(284)) && (
          <Tabs.Panel value='dp'>
            {infoLoading ? (
              <AppLoader size='lg' center />
            ) : (
              <div className='table-container' style={{ overflowY: 'auto' }}>
                {' '}
                <Accordion
                  iconPosition='right'
                  classNames={{
                    label: 'accordion-label',
                    contentInner: 'accordion-innerContent',
                    control: 'accordion-control',
                  }}
                >
                  {Object.keys(demandPlanningStatus).map((key, index) => (
                    <Accordion.Item
                      className='scope-label'
                      key={`key_${index}`}
                      value={`key_${index}`}
                    >
                      <Accordion.Control>{`${demandPlanningStatus[key][0].int_rfs_scope_selection.selection_number} ${demandPlanningStatus[key][0].int_rfs_scope_selection.selection_name}`}</Accordion.Control>
                      <Accordion.Panel>
                        <ReversalTable
                          data={demandPlanningStatus[key]}
                          save={save}
                          actionModal={actionModal}
                          setActionModal={setActionModal}
                          loading={manualClosureLoading}
                          type={steps.marketBlockage}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            )}
          </Tabs.Panel>
        )}
      </Tabs>
    </ContentPaper>
  );
}
