import { Table } from '@mantine/core';
import moment from 'moment';

const mapping = (data) => {
  const name = {
    skuBom: <BomComponent data={data} />,
    liquid: <BomComponent data={data} />,
    skuUom: <UomComponent data={data} />,
    skuEan: <EanComponent data={data} />,
  };

  return name[data.type];
};

const BomColumns = (
  <tr>
    <th style={{ minWidth: '5rem' }}>Material Number</th>
    <th style={{ minWidth: '20rem' }}>Material Info</th>
    <th style={{ minWidth: '5rem' }}>Valid From</th>
    <th style={{ minWidth: '5rem' }}>Valid To</th>
    <th style={{ minWidth: '2rem' }}>Unit</th>
    <th style={{ minWidth: '10rem' }}>Component Quantity</th>
  </tr>
);

const UomColumns = (
  <tr>
    <th style={{ minWidth: '2rem' }}>UOM - A</th>
    <th style={{ minWidth: '2rem' }}>Measure</th>
    <th style={{ minWidth: '2rem' }}>UOM - A</th>
    <th style={{ minWidth: '2rem' }}>Measure</th>
    <th style={{ minWidth: '2rem' }}>Conversion</th>
    <th style={{ minWidth: '2rem' }}>Length</th>
    <th style={{ minWidth: '2rem' }}>Width</th>
    <th style={{ minWidth: '2rem' }}>Height</th>
    <th style={{ minWidth: '2rem' }}>Gross Weight</th>
  </tr>
);

const EanColumns = (
  <tr>
    <th style={{ minWidth: '2rem' }}>EAN Name</th>
    <th style={{ minWidth: '2rem' }}>EAN Code</th>
  </tr>
);

const BomComponent = ({ data }) => {
  return Object.keys(data.data).map((k, i) => (
    <Table
      key={i}
      captionSide='top'
      verticalSpacing='xs'
      striped
      highlightOnHover
      style={{ width: '100%' }}
    >
      <caption>{`${k}`}</caption>
      <thead>{BomColumns}</thead>
      <tbody>
        {data.data[k].map((element, index) => (
          <tr key={`${index}_${element.bom_component}`}>
            <td>{element.bom_component}</td>
            <td>{element.MaterialInfo}</td>
            <td>{moment(element.valid_from, 'YYYYMMDD').format('DD-MM-YYYY')}</td>
            <td>{moment(element.valid_to_date, 'YYYYMMDD').format('DD-MM-YYYY')}</td>
            <td>{element.unit}</td>
            <td>{element.component_qty}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ));
};

const UomComponent = ({ data }) => {
  return (
    <Table
      captionSide='top'
      verticalSpacing='xs'
      striped
      highlightOnHover
      style={{ width: '100%' }}
    >
      <thead>{UomColumns}</thead>
      <tbody>
        {Object.keys(data.data).map((k) =>
          data.data[k].map((element, index) => (
            <tr key={`${index}`}>
              <td>{element.UOM_A}</td>
              <td>{element.Measure_A}</td>
              <td>{element.UOM_B}</td>
              <td>{element.Measure_B}</td>
              <td>{element.length}</td>
              <td>{element.width}</td>
              <td>{element.height}</td>
              <td>{element.unit}</td>
              <td>{element.gross_weight}</td>
            </tr>
          )),
        )}
      </tbody>
    </Table>
  );
};
const EanComponent = ({ data }) => {
  const splitData = data.data.split('; ');
  return (
    <Table
      captionSide='top'
      verticalSpacing='xs'
      striped
      highlightOnHover
      style={{ width: '100%' }}
    >
      <thead>{EanColumns}</thead>
      <tbody>
        {splitData.map((element, index) => (
          <tr key={`${index}`}>
            <td>{element.split(':')[0]}</td>
            <td>{element.split(':')[1]}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const List = (data) => {
  return <>{mapping(data)}</>;
};
