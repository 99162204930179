import React, { useContext, useState, useMemo } from 'react';
import RfsLayout from '../../components/layouts/RfsLayout';
import { Button, Text } from '@mantine/core';

import { Row, Col } from 'react-bootstrap';
import ContentPaper from '../../components/layouts/rfs/Content';
import { AuthContext } from '../../contexts/AuthContext';

import { useNavigate } from 'react-router-dom';
import './reports.css';
import ReportCustomComp from '../../components/report/reportCustomComp';
import { reportPermissions } from '../../constants';

function TeamsStepsDayReport() {
  const [validationErrors, setValidationErrors] = useState({}); //for Report
  const { userRole } = useContext(AuthContext);

  const navigate = useNavigate();

  //Report Data for New UI
  let statusData = [];

  const getClassName = (column, duration) => {
    let className = '';
    if (duration > column) {
      className = 'column-option-2';
    }
    if (duration < column) {
      className = 'column-option-3';
    }
    if (!column) {
      className = 'column-option-1';
    }

    if (column == 0) {
      className = 'column-option-2';
    }

    return className;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'data_id',
        header: 'data_id',
      },
      {
        accessorKey: 'Steps',
        header: `Steps`,
        sticky: true,
        style: { textAlign: 'center' },
      },
      {
        accessorKey: 'Teams',
        header: 'Teams',
        sticky: true,
      },
      {
        accessorKey: 'Duration',
        header: 'Duration',
        enableEditing: false,
        size: 20,
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ textAlign: 'center', padding: '0', fontSize: '16px', fontweight: 'bold' }}>
            {renderedCellValue}
          </div>
        ),
      },
      {
        accessorKey: 'January',
        header: 'January',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'February',
        header: 'February',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'March',
        header: 'March',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'April',
        header: 'April',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'May',
        header: 'May',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'June',
        header: 'June',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'July',
        header: 'July',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'August',
        header: 'August',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'September',
        header: 'September',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'October',
        header: 'October',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'November',
        header: 'November',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
      {
        accessorKey: 'December',
        header: 'December',
        enableEditing: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row.original.Duration),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>{renderedCellValue != null ? renderedCellValue : 'N/A'}</Text>
        ),
      },
    ],
    [validationErrors],
  );

  return (
    <RfsLayout>
      <ContentPaper page='stepsteams-report'>
        <Row>
          <Col
            md={{ span: 6 }}
            style={{
              marginTop: '1rem',
              display: 'inline-flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              size='sm'
              color='dark'
              width='2rem'
              style={{ paddingLeft: '20', paddingRight: '20' }}
              onClick={() => {
                navigate('/reports');
              }}
            >
              Back{' '}
            </Button>
            <h1
              className='sectionHeadings'
              style={{ paddingLeft: '10px', fontSize: '28px', color: 'black' }}
            >
              {' '}
              Teams and Steps Report{' '}
            </h1>
          </Col>
        </Row>
        <div id='customReport'>
          {
            <ReportCustomComp
              columns={columns}
              setValidationErrors={setValidationErrors}
              validationErrors={validationErrors}
              statusData={statusData}
              unique={'data_id'}
              enableEditing={false}
              type={'teamssteps'}
              readOnly={!reportPermissions['stepsteams'].includes(userRole)}
            />
          }
        </div>
      </ContentPaper>
    </RfsLayout>
  );
}

export default TeamsStepsDayReport;
