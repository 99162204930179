import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { Accordion, Grid, Table, Badge, Text, Checkbox, Button } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { ActionModal } from '../actionModal';
import { ProductionLog } from '../productionSiteLogModal';
import { VeamAffectedSKUs } from '../veamaffectedSKUsModal';
import { getScrollPositions } from 'jsgantt-improved/dist/src/utils/general_utils';
import { materialTypes, liquidTypes, liquidBomChanges } from '../../../../constants/index';

const ChangeOverMessage = () => (
  <b>
    <font color='red'>
      {'* If Changeover has already taken place, step to be manually closed.'}
    </font>
  </b>
);

const ChangeOverTableVEAM = ({
  statusDataList,
  type,
  save,
  accState,
  actionModal,
  setActionModal,
  scrollVal,
  readOnly,
}) => {
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const [htmlData, setHtmlData] = useState('');
  const [status, setStatus] = useState('');
  const [checked, setChecked] = useState(false);

  const [value, setValue] = useState(0);

  const [SKUModal, setSKUModal] = useState({ open: false, id: '' });
  const [MaterialID, setMaterialID] = useState(0);

  const Action = (item) => {
    return item.status === 'In Progress' || item.status === 'Not Started' ? (
      <Checkbox
        disabled={(item.status === 'In Progress' ? false : true) || readOnly}
        onChange={(event) => {
          setChecked(event.target.checked);
          setStatus(item.id);
          setActionModal({ open: true, id: item.id });
        }}
        label='Confirm and Close'
      />
    ) : item.manually_closed && item.status === 'Closed' ? (
      <Text>Manually Closed</Text>
    ) : (
      <Text>Automatic Closure</Text>
    );
  };

  const getScrollPositionDiv = (event, crtrgt) => {
    createScrollStopListener(event.target, () => {
      let scrollValues = {
        scrollVal: crtrgt,
        divName: event.target.className,
      };
      localStorage.setItem('scrollValues', JSON.stringify(scrollValues));
    });
  };

  const createScrollStopListener = (element, callback) => {
    var handle = null;
    var onScroll = function () {
      if (handle) {
        clearTimeout(handle);
      }
      handle = setTimeout(callback, 1000);
    };
    element.addEventListener('scroll', onScroll);
    return () => element.removeEventListener('scroll', onScroll);
  };

  const scrollGoto = () => {
    let scrollValues = localStorage.getItem('scrollValues')
      ? JSON.parse(localStorage.getItem('scrollValues'))
      : '';
    const { divName, scrollVal } = scrollValues;
    if (document.querySelector('.' + divName))
      document.querySelector('.' + divName).scrollTo(scrollVal, 0);
  };

  useEffect(() => {
    scrollGoto();
  }, [actionModal, statusDataList, accState]);

  const Logs = (id) => {
    return (
      <Button
        onClick={(event) => getLogs(id, event)}
        style={{ background: 'black', color: 'white' }}
      >
        View Logs
      </Button>
    );
  };

  const getLogs = (imp_id, event) => {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  };

  useEffect(() => {
    setValue(accState);
  }, [accState]);

  const ImpactedSKUs = (id) => {
    return (
      <Button onClick={() => getImpactedSKUs(id)} style={{ background: 'black', color: 'white' }}>
        View SKUs
      </Button>
    );
  };

  const getImpactedSKUs = (id) => {
    setSKUModal({ open: true, id: id });
    setMaterialID(id);
  };

  const filteredData = (data, type) => {
    return data.map((item) => {
      let Code = 'N/A',
        new_num = 'N/A',
        new_name = 'N/A',
        type_of_change = 'N/A',
        changeovertype = 'N/A',
        changeoverdate = 'N/A',
        comment = 'N/A',
        scope_selection_code = '',
        Desc;

      if (type === 'Materials') {
        if (
          item.material_type === materialTypes.existing ||
          item.material_type === materialTypes.remove
        ) {
          Code = item.material_code_or ? item.material_code_or : 'N/A';
          new_num = item.int_rfs_material_number ? item.int_rfs_material_number : 'N/A';

          Desc = item.material_description_or;
          new_name = item.pack_mat_material_text ? item.pack_mat_material_text : 'N/A';
        } else if (item.material_type === materialTypes.new) {
          Code = item.material_code_or ? item.material_code_or : 'N/A';
          new_num = item.new_material_code ? item.new_material_code : 'N/A';

          Desc = item.material_description_or;
          new_name = item.new_material_description_intrfsml
            ? item.new_material_description_intrfsml
            : 'N/A';
        }

        type_of_change = item.material_type ? item.material_type : 'N/A';
        changeovertype = item.material_changeover_type ? item.material_changeover_type : 'N/a';
        changeoverdate = item.material_changeover_date
          ? moment(item.material_changeover_date).format('YYYY-MM-DD')
          : 'N/A';
        comment = item.material_comment ? item.material_comment : 'N/A';
      } else if (type === 'Liquids') {
        if ([liquidTypes.add, liquidTypes.new].includes(item.liquid_type) && !item.liquid_number) {
          Code = item.liquid_code ? item.liquid_code : 'N/A';
          new_num = item.new_material_code ? item.new_material_code : 'N/A';

          Desc = item.liquid_description ? item.liquid_description : 'N/A';
          new_name = item.new_material_description_intrfsml
            ? item.new_material_description_intrfsml
            : 'N/A';
        } else {
          Code = item.liquid_code ? item.liquid_code : 'N/A';
          new_num = item.liquid_number ? item.liquid_number : 'N/A';

          Desc = item.liquid_description ? item.liquid_description : 'N/A';
          new_name = item.material_text ? item.material_text : 'N/A';
        }

        changeovertype = item.liquid_changeover_type ? item.liquid_changeover_type : 'N/A';
        type_of_change = item.bom_changes ? item.bom_changes : 'N/a';
        changeoverdate = item.liquid_changeover_date
          ? moment(item.liquid_changeover_date).format('YYYY-MM-DD')
          : 'N/A';
        comment = item.liquid_comment ? item.liquid_comment : 'N/A';
      }

      scope_selection_code = 'LIQ - ' + item.selection_number + ' - ' + item.selection_name;

      return {
        Code: Code,
        Description: Desc,
        Plant: item.plant,
        'Type of change': type_of_change,
        'New Number': new_num,
        'New Name': new_name,
        'Current Quantity': item.quantity ? item.quantity : 'N/A',
        'New Quantity': item.new_quantity ? item.new_quantity : 'N/A',
        Unit: item.unit ? item.unit : 'N/A',
        'Changeover type': changeovertype,
        'Changeover Date': changeoverdate,
        Comment: comment,
        'Impacted SKUs': ImpactedSKUs(item.material_id),
        Status: (
          <Badge
            color={
              item.status === 'In Progress' ? 'yellow' : item.status === 'Closed' ? 'green' : 'gray'
            }
            size='lg'
          >
            {' '}
            {item.status}{' '}
          </Badge>
        ),
        Action: Action(item),
        Logs: Logs(item.id),
        'SLA Date': moment(item.sla_date).format('YYYY-MM-DD'),
        'Step Finished': item.finished_date
          ? moment(item.finished_date).format('YYYY-MM-DD')
          : item.status,
        scope_selection_code: scope_selection_code,
        scope_id: item.scope_id,
      };
    });
  };

  const filteredDataRes = filteredData(statusDataList, type);

  const HtmlRenderedData = ({ type }) => {
    let Title = type.substring(0, type.length - 1);
    const heads = (
      <thead>
        <tr id='header-row'>
          <th>{Title} Code</th>
          <th>{Title} Description</th>
          <th>Plant</th>
          <th>Type of change</th>
          <th>New {Title} Code</th>
          <th>New {Title} Description</th>
          {type === 'Liquids' && <th>Current Quantity</th>}
          {type === 'Liquids' && <th>New Quantity</th>}
          {type === 'Liquids' && <th>Unit</th>}
          <th>Changeover type</th>
          <th>Changeover Date</th>
          <th>Comment</th>
          {type === 'Materials' && <th>Impacted SKUs</th>}
          <th>Status</th>
          <th>Action</th>
          <th>Log</th>
          <th>Step Deadline</th>
          <th>Step Finished</th>
        </tr>
      </thead>
    );

    const Materials = () => {
      const rowsData = filteredDataRes.map((item, index) => (
        <tr key={index}>
          {Object.values(item).map((it, i) => {
            return (
              i < 18 &&
              i !== 6 &&
              i !== 7 &&
              i !== 8 && (
                <td key={i} style={{ padding: '0.8rem !important' }}>
                  {it}
                </td>
              )
            );
          })}
        </tr>
      ));

      return (
        <>
          <Table>
            {heads}
            <tbody>{rowsData}</tbody>
          </Table>
          <ChangeOverMessage />
        </>
      );
    };

    const Liquids = () => {
      let arr = [];
      filteredDataRes.map((item) =>
        arr.includes(item.scope_id) === false ? arr.push(item.scope_id) : '',
      );

      const a = arr.map((arr_item) => {
        return filteredDataRes.filter((item) => {
          return item.scope_id == arr_item;
        });
      });

      return (
        <Accordion value={value} onChange={setValue}>
          {a.map((item, index) => {
            return (
              <Accordion.Item
                className='scope-label'
                key={`key_sub_${index}`}
                value={`key_sub_${index}`}
              >
                <Accordion.Control>{`${item[0].scope_selection_code}`}</Accordion.Control>
                <div
                  style={{ overflowX: 'auto', overflowY: 'hidden' }}
                  className={'divsc' + index}
                  onScroll={(event) => getScrollPositionDiv(event, event.currentTarget.scrollLeft)}
                >
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col span={12}>
                        <Styles>
                          <div>
                            <Table>
                              {heads}
                              <tbody>
                                {item.map((item, i) => (
                                  <tr key={i}>
                                    {Object.values(item).map((it, i) => {
                                      return (
                                        i < 18 &&
                                        i !== 12 && (
                                          <td
                                            key={i}
                                            style={{
                                              padding: '0.8rem !important',
                                            }}
                                          >
                                            {it}
                                          </td>
                                        )
                                      );
                                    })}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <ChangeOverMessage />
                          </div>
                        </Styles>
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </div>
              </Accordion.Item>
            );
          })}
        </Accordion>
      );
    };

    return type === 'Liquids' ? <Liquids /> : <Materials />;
  };

  return (
    <Styles>
      <div
        className={type === 'Liquids' ? 'divscL' : 'divscM'}
        onScroll={(event) => getScrollPositionDiv(event, event.currentTarget.scrollLeft)}
        style={type === 'Materials' ? { overflowX: 'auto' } : {}}
      >
        <HtmlRenderedData type={type} />
      </div>

      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={status}
          state={value}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Changeover'
        />
      )}

      {logModal && (
        <VeamAffectedSKUs SKUModal={SKUModal} setSKUModal={setSKUModal} MaterialID={MaterialID} />
      )}
    </Styles>
  );
};

export default ChangeOverTableVEAM;
