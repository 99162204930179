import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from '@mantine/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rfsinitiation2 from '../../components/forms/rfsInitiationForm/rfsinitiation2';
import TechFeasibility from '../techfeasibility/techfeasibility';
import ProductDevlopmentComp from '../productDevelopment/ProductDevelopment';
import ProductDevlopmentCompVEAM from '../veam/productDevelopment/productDevelopment';
import ImpFeasiblity from '../impFeasibility/impFeasibility';
import ImplementationTrialComp from '../../components/implementation/implementationTrial/implemetationTrial';
import { axios } from '../../utils/axios';
import { AuthContext } from '../../contexts/AuthContext';
import ScopeUnlock from '../ScopeUnlock/scopeUnlockPage';
import { ProjectOverviewContext } from '../../contexts/projectOverviewContext';
import FeedbackForm from '../../components/forms/feedbackForm/feedbackForm';
import FeedbackFormVEAM from '../../components/forms/feedbackForm/feedbackFormVEAM';
import { RfsContext } from '../../contexts/RfsContext';
import { rfsStatus, roles } from '../../constants';
import { routes } from '../../routes';
import ContentPaper from '../../components/layouts/rfs/Content';
import GanttChart from '../projectGantt';
import ProductDevelopmentProdSite from '../productionSite/productDevelopment/productDevelopment';
import Reversal from '../ScopeUnlock/reversal';
import PsReversal from '../productionSite/ScopeUnlockReversal';
// import ProductionReversal from '../productionSite/Reversal';
import ImplementationProdSite from '../productionSite/Implementation/implementation';
import ImpFeasiblityProdSite from '../productionSite/ImpFeasibilityProdSite/impFeasibilityProdSite';
import Rfsinitiation2DLST from '../DLST/ValidationPage/rfsinitiation2DLST';
import { DelistingProvider } from '../../contexts/DelistingContext';
import TechFeasibilityDLST from '../DLST/techfeasibility/techfeasibilityDLST';
import DlstImplementation from '../DLST/implementation';
import ImpFeasiblityDLST from '../DLST/ImpFeasibilityDLST/impFeasibilityDLST';

import ImplementationVEAM from '../veam/Implementation/implementation';
import VeamScopeUnlockReversal from '../veam/productDevelopment/ScopeReversal';
import { VEAMProvider } from '../veam/veamContext';
import ImplementationOSKU from '../../components/OSKU/Implementation';
import { tabStyles } from '../../utils/helpers';
import { SupplierProvider } from '../../contexts/SupplierContext';
import SupplierChangeValidate from '../supplierChange/validate';
import SupplierTechFeasibility from '../supplierChange/techFeasibility';
import ImplementationSupplierChange from '../supplierChange/implementation';
import AttachmentPage from '../../components/attachments/index';

export default function ProjectOverview(props) {
  const { userRole } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const { veamScopeUnlockInfo } = useContext(ProjectOverviewContext);
  const { form } = useContext(RfsContext);
  const { fetch, setFetched, scopeUnlockInfo, tabs } = props;
  const [admin, setAdmin] = useState(false);
  const [OSKU, setOSKU] = useState(false);
  const { status, rfs_type = '' } = form.rfsInformation;
  const [activeTab, setActiveTab] = useState('projectOverview');

  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo].includes(userRole)) setAdmin(true);
  }, [userRole]);

  const fetchTechFeasibility = (rfsNumber) => {
    axios.get(`/tech-feasibility/fetch/${rfsNumber}`).then(
      (res) => {
        const response = res.data.fetchedData;
        // response.are_trials_actually_needed = 'No';
        setFetched(response);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  useEffect(() => {
    if (userRole === 'admin' || userRole === 'superAdmin') setAdmin(true);
  }, [userRole]);

  useEffect(() => {
    if (params.rfsNumber) fetchTechFeasibility(params.rfsNumber);
  }, [params.rfsNumber]);

  const getTechfeasibilityStatus = () => {
    return [rfsStatus.draft].includes(status) || rfs_type === 'Supplier Change'
      ? ![roles.pmo, roles.superAdmin, roles.d2p, roles.zitec].includes(userRole)
      : ![roles.pmo, roles.superAdmin].includes(userRole);
  };

  const getProdDevStatus = () => {
    return [rfsStatus.draft, rfsStatus.tech_feasible2, rfsStatus.tech_feasible].includes(status);
  };

  const getImpFeasiblityStatus = () => {
    return (
      [
        rfsStatus.draft,
        rfsStatus.tech_feasible2,
        rfsStatus.product_development,
        rfsStatus.tech_feasible,
        rfsStatus.reversal,
      ].includes(status) || ![roles.pmo, roles.superAdmin].includes(userRole)
    );
  };
  useEffect(() => {
    if (
      !!form.rfsInformation &&
      !!form.rfsInformation.rfs_type &&
      form.rfsInformation.rfs_type === 'Open SKU to market'
    )
      setOSKU(true);
  }, [form.rfsInformation.rfs_type]);
  const getImplementationStatus = () => {
    return [
      rfsStatus.draft,
      rfsStatus.tech_feasible2,
      rfsStatus.product_development,
      rfsStatus.imp_feasible,
      rfsStatus.tech_feasible,
      rfsStatus.supplier_zitec_active,
      rfsStatus.supplier_pmo1_active,
      rfsStatus.supplier_pmo2_active,
      rfsStatus.rejected,
      'Rejected',
    ].includes(status);
  };

  const getFeedbackForFtrStatus = () => {
    return (
      [rfsStatus.draft, rfsStatus.rejected, 'Rejected'].includes(status) ||
      roles.initiator === userRole
    );
  };

  const getScopeUnlockStatus = () => {
    return (
      ![
        rfsStatus.imp_feasible,
        rfsStatus.implementation,
        rfsStatus.scope_unlock,
        //rfsStatus.scope_restated,
        rfsStatus.cancelled,
        rfsStatus.reversal,
        rfsStatus.scope_unlocked,
        rfsStatus.cancellation,
        rfsStatus.implemented,
      ].includes(status) || ![roles.pmo, roles.superAdmin].includes(userRole)
    );
  };

  const getScopeUnlockReversalStatus = () => {
    return ![
      rfsStatus.imp_feasible,
      rfsStatus.implementation,
      rfsStatus.scope_unlock,
      //rfsStatus.scope_restated,
      rfsStatus.reversal,
      rfsStatus.cancelled,
      rfsStatus.scope_unlocked,
      rfsStatus.reversed,
      rfsStatus.implemented,
      rfsStatus.cancellation,
    ].includes(status);
  };

  const trials = fetch?.are_trials_actually_needed === 'Yes';

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const tab = urlParams.get('tab');

    if (tab) setActiveTab(tab);
  }, [urlParams]);

  return (
    <>
      <Tabs
        onTabChange={(value) => navigate(`${routes.project(params.rfsNumber).root}?tab=${value}`)}
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
        defaultValue={'projectOverview'}
        value={activeTab}
        keepMounted={false}
      >
        <Tabs.List>
          <Tabs.Tab value='projectOverview' className='menu-tabs'>
            Project Overview
          </Tabs.Tab>

          <Tabs.Tab value='attachments' className='menu-tabs'>
            Attachments
          </Tabs.Tab>

          <Tabs.Tab value='tf' disabled={getTechfeasibilityStatus()} className='menu-tabs'>
            Technical Feasibility
          </Tabs.Tab>

          {trials && (
            <Tabs.Tab disabled={getProdDevStatus()} className='menu-tabs' value='pd'>
              Product Development
            </Tabs.Tab>
          )}

          {!['Supplier Change'].includes(rfs_type) && (
            <Tabs.Tab disabled={getImpFeasiblityStatus()} className='menu-tabs' value='if'>
              Imp. Feasibility
            </Tabs.Tab>
          )}

          <Tabs.Tab disabled={getImplementationStatus()} className='menu-tabs' value='imp'>
            Implementation
          </Tabs.Tab>

          <Tabs.Tab className='menu-tabs' value='pg'>
            Project Gantt
          </Tabs.Tab>

          <Tabs.Tab disabled={getFeedbackForFtrStatus()} className='menu-tabs' value='fftr'>
            Feedback for FTR
          </Tabs.Tab>

          {admin ? (
            <Tabs.Tab disabled={getScopeUnlockStatus()} className='menu-tabs' value='su'>
              Scope Unlock
            </Tabs.Tab>
          ) : null}

          {form.rfsInformation.rfs_type === 'Production Site' &&
            Object.keys(scopeUnlockInfo).length > 0 &&
            scopeUnlockInfo.logs &&
            scopeUnlockInfo.logs.length > 0 && (
              <Tabs.Tab
                disabled={getScopeUnlockReversalStatus()}
                className='menu-tabs'
                value='surs'
              >
                Scope Unlock Reversal
              </Tabs.Tab>
            )}

          {((Object.keys(scopeUnlockInfo).length > 0 &&
            scopeUnlockInfo.logs &&
            scopeUnlockInfo.logs.length > 0) ||
            (Object.keys(veamScopeUnlockInfo).length > 0 &&
              veamScopeUnlockInfo.logs &&
              veamScopeUnlockInfo.logs.length > 0)) &&
            form.rfsInformation.rfs_type !== 'Production Site' && (
              <Tabs.Tab className='menu-tabs' value='sur'>
                Scope Unlock Reversal
              </Tabs.Tab>
            )}
        </Tabs.List>

        <Tabs.Panel value='projectOverview'>
          <>
            {form?.rfsInformation.rfs_type === 'Delisting' ? (
              <DelistingProvider>
                <Rfsinitiation2DLST isSubmissionReview initialItems={0} {...props} disableButtons />
              </DelistingProvider>
            ) : form?.rfsInformation.rfs_type === 'Supplier Change' ? (
              <SupplierProvider>
                <SupplierChangeValidate
                  isSubmissionReview
                  initialItems={0}
                  disableButtons
                  {...props}
                />
              </SupplierProvider>
            ) : (
              <Rfsinitiation2 isSubmissionReview initialItems={0} {...props} disableButtons />
            )}
          </>
        </Tabs.Panel>

        <Tabs.Panel value="attachments">
          <AttachmentPage /> 
        </Tabs.Panel>

        <Tabs.Panel value='tf'>
          <>
            {getTechfeasibilityStatus() ? (
              <PermissionDenied />
            ) : rfs_type.toLowerCase() === 'delisting' ? (
              <DelistingProvider>
                <TechFeasibilityDLST isSubmissionReview initialItems={0} />
              </DelistingProvider>
            ) : rfs_type.toLowerCase() === 'supplier change' ? (
              <SupplierProvider>
                <SupplierTechFeasibility isSubmissionReview initialItems={0} {...props} />
              </SupplierProvider>
            ) : (
              <TechFeasibility isSubmissionReview initialItems={0} />
            )}
          </>
        </Tabs.Panel>

        {trials && (
          <Tabs.Panel value='pd'>
            <>
              {getProdDevStatus() ? (
                <PermissionDenied />
              ) : rfs_type.toLowerCase() === 'production site' ? (
                <ProductDevelopmentProdSite trials={trials} tabs={tabs} />
              ) : rfs_type === 'Article Modification/Value Engineering' ? (
                <VEAMProvider>
                  <ProductDevlopmentCompVEAM
                    trials={trials}
                    editable={status === rfsStatus.product_development}
                  />
                </VEAMProvider>
              ) : (
                <ProductDevlopmentComp
                  trials={trials}
                  editable={status === rfsStatus.product_development}
                />
              )}
            </>
          </Tabs.Panel>
        )}

        {!['Supplier Change'].includes(rfs_type) && (
          <Tabs.Panel value='if'>
            <>
              {getImpFeasiblityStatus() ? (
                <PermissionDenied />
              ) : (
                (() => {
                  switch (rfs_type) {
                    case 'Production Site':
                      return <ImpFeasiblityProdSite trials={trials} />;
                    case 'Delisting':
                      return <ImpFeasiblityDLST />;
                    default:
                      return <ImpFeasiblity trials={trials} />;
                  }
                })()
              )}
            </>
          </Tabs.Panel>
        )}

        <Tabs.Panel value='imp'>
          <>
            {getImplementationStatus() ? (
              <PermissionDenied />
            ) : rfs_type === 'Supplier Change' ? (
              <ImplementationSupplierChange />
            ) : rfs_type.toLowerCase() === 'delisting' ? (
              <DlstImplementation trials={trials} />
            ) : !OSKU ? (
              rfs_type === 'Production Site' ? (
                <ImplementationProdSite trials={trials} tabs={tabs} />
              ) : (
                <ImplementationVEAM
                  editable={status === rfsStatus.implementation}
                  trials={trials}
                  tabs={tabs}
                />
              )
            ) : (
              <ImplementationOSKU editable={status === rfsStatus.implementation} />
            )}
          </>
        </Tabs.Panel>

        <Tabs.Panel value='pg'>
          <>
            <GanttChart rfsNumber={params.rfsNumber} trials={trials} />
          </>
        </Tabs.Panel>

        <Tabs.Panel value='fftr'>
          <>
            {getFeedbackForFtrStatus() ? (
              <PermissionDenied />
            ) : rfs_type === 'Article Modification/Value Engineering' ? (
              <FeedbackFormVEAM initialItems={0} />
            ) : (
              <FeedbackForm initialItems={0} />
            )}
          </>
        </Tabs.Panel>

        {admin ? (
          <Tabs.Panel value='su'>
            <>
              {getScopeUnlockStatus() ? (
                <PermissionDenied />
              ) : (
                <ScopeUnlock initialItems={0} trials={trials} scopeUnlockInfo={scopeUnlockInfo} />
              )}
            </>
          </Tabs.Panel>
        ) : null}

        {form.rfsInformation.rfs_type === 'Production Site' &&
          Object.keys(scopeUnlockInfo).length > 0 &&
          scopeUnlockInfo.logs &&
          scopeUnlockInfo.logs.length > 0 && (
            <Tabs.Panel value='surs'>
              <>{getScopeUnlockReversalStatus() ? <PermissionDenied /> : <PsReversal />}</>
            </Tabs.Panel>
          )}

        {((Object.keys(scopeUnlockInfo).length > 0 &&
          scopeUnlockInfo.logs &&
          scopeUnlockInfo.logs.length > 0) ||
          (Object.keys(veamScopeUnlockInfo).length > 0 &&
            veamScopeUnlockInfo.logs &&
            veamScopeUnlockInfo.logs.length > 0)) &&
          form.rfsInformation.rfs_type != 'Production Site' && (
            <Tabs.Panel value='sur'>
              <>
                {getScopeUnlockStatus() ||
                (Object.keys(scopeUnlockInfo).length === 0 &&
                  Object.keys(veamScopeUnlockInfo).length === 0) ? (
                  <PermissionDenied />
                ) : form.rfsInformation.rfs_type === 'Article Modification/Value Engineering' ? (
                  <VeamScopeUnlockReversal veamScopeUnlockInfo={veamScopeUnlockInfo} />
                ) : (
                  <Reversal scopeUnlockInfo={scopeUnlockInfo} />
                )}
              </>
            </Tabs.Panel>
          )}
      </Tabs>
    </>
  );
}

const PermissionDenied = () => (
  <ContentPaper page='permission-denied'>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      You don't have access
    </div>
  </ContentPaper>
);
