import React, { useContext, useEffect, useState } from 'react';
import { Modal, Accordion, Container, Grid, Group, Text } from '@mantine/core';
import { X, Check } from 'tabler-icons-react';
import { AppLoader } from '../../../../../components/common/loader';
import { axios } from '../../../../../utils/axios';
import moment from 'moment';

export const LogModal = ({ type = null, data = null, logModal, setLogModal }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`delisting/logs/${logModal.id}`)
      .then(
        (res) => {
          setLogs(res.data.data[0].int_rfs_implementation_status_logs);
        },
        (err) => {
          throw err;
        },
      )
      .finally(() => setLoading(false));
  }, [logModal.id]);

  return loading ? (
    <div>
      <Modal
        opened={logModal.open}
        onClose={() => setLogModal({ open: false, id: null })}
        title='Logs'
        className='confirm-modal'
      >
        <AppLoader size='md' center />
      </Modal>
    </div>
  ) : (
    <div>
      <Modal
        opened={logModal.open}
        onClose={() => setLogModal({ open: false, id: null })}
        title='Logs'
        className='confirm-modal'
      >
        <Container>
          <Accordion
            defaultValue={0}
            overflow='inside'
            iconPosition='right'
            classNames={{
              label: 'accordion-label',
              contentInner: 'accordion-innerContent',
              control: 'accordion-control',
            }}
          >
            <Accordion.Item
              className='scope-implementation-label'
              label={`${capitalizeFirstLetter(type)}`}
            >
              {logs.map((log, index) => {
                return (
                  <div key={index}>
                    <Text>
                      <span style={{ fontWeight: 'bold' }}>Step: </span>
                      {capitalizeFirstLetter(type)}
                      <span style={{ paddingLeft: '0.5rem' }}>
                        {!log.status ? (
                          <X color='red' size='1rem' />
                        ) : (
                          <Check color='green' size='1rem' />
                        )}
                      </span>
                    </Text>
                    <Text>
                      <span style={{ fontWeight: 'bold' }}>Timestamp: </span>
                      {log.manually_closed_at
                        ? moment(log.manually_closed_at).format('DD-MM-YYYY')
                        : log.createdAt
                        ? moment(log.createdAt).format('DD-MM-YYYY')
                        : 'Not Started'}
                    </Text>
                    <Text>
                      <span style={{ fontWeight: 'bold' }}>Remark: </span>
                      {log.remark ? log.remark : 'None'}
                    </Text>
                    {log.manually_closed_by ? (
                      <div>
                        <Text>
                          <span style={{ fontWeight: 'bold' }}>Manually closed by: </span>
                          {capitalizeFirstLetter(log.manually_closed_by)}
                        </Text>
                        {log.manually_closed_comment && (
                          <Text>
                            <span style={{ fontWeight: 'bold' }}>Manually closed comment: </span>
                            {log.manually_closed_comment
                              ? capitalizeFirstLetter(log.manually_closed_comment)
                              : 'None'}
                          </Text>
                        )}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <br />
                  </div>
                );
              })}
            </Accordion.Item>
          </Accordion>
        </Container>
      </Modal>
    </div>
  );
};

export default LogModal;
