import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Accordion, Grid, Table, Badge, Text, Checkbox, Button } from '@mantine/core';
import { Styles } from '../../../../../components/tables/skutable/production-site/styles';
import { ActionModal } from '../../actionModal';
import { ProductionLog } from '../../productionSiteLogModal';

const StatusReversalTable = ({ statusDataList, type, save, accState, readOnly }) => {
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const [htmlData, setHtmlData] = useState('');
  const [status, setStatus] = useState('');
  const [checked, setChecked] = useState(false);

  const [state, handlers] = useState(0);
  const [active, setActive] = useState(0);

  const [SKUModal, setSKUModal] = useState({ open: false, id: '' });
  const [MaterialID, setMaterialID] = useState(0);

  useEffect(() => {
    let handlerObj = Object.values(accState).length > 0 ? accState : 0;
    handlers(handlerObj);
  }, [accState]);

  const getScrollPositionDiv = (event, crtrgt) => {
    createScrollStopListener(event.target, () => {
      let scrollValues = {
        scrollVal: crtrgt,
        divName: event.target.className,
      };
      localStorage.setItem('scrollValues', JSON.stringify(scrollValues));
    });
  };

  const createScrollStopListener = (element, callback) => {
    var handle = null;
    var onScroll = function () {
      if (handle) {
        clearTimeout(handle);
      }
      handle = setTimeout(callback, 1000);
    };
    element.addEventListener('scroll', onScroll);
    return () => element.removeEventListener('scroll', onScroll);
  };

  const scrollGoto = () => {
    let scrollValues = localStorage.getItem('scrollValues')
      ? JSON.parse(localStorage.getItem('scrollValues'))
      : '';
    const { divName, scrollVal } = scrollValues;
    if (document.querySelector('.' + divName))
      document.querySelector('.' + divName).scrollTo(scrollVal, 0);
  };

  useEffect(() => {
    scrollGoto();
  }, [actionModal, statusDataList]);

  const Action = (item) => {
    return item.status === 'In Progress' || item.status === 'Not Started' ? (
      <Checkbox
        disabled={(item.status === 'In Progress' ? false : true) || readOnly}
        onChange={(event) => {
          setChecked(event.target.checked);
          setStatus(item.id);
          setActionModal({ open: true, id: item.id });
        }}
        label='Confirm and Close'
      />
    ) : item.manually_closed && item.status === 'Closed' ? (
      <Text>Manually Closed</Text>
    ) : (
      <Text>Automatic Closure</Text>
    );
  };

  const Logs = (id) => {
    return (
      <Button
        onClick={(event) => getLogs(id, event)}
        style={{ background: 'black', color: 'white' }}
      >
        View Logs
      </Button>
    );
  };

  const getLogs = (imp_id, event) => {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  };

  const filteredData = (data, type) => {
    return data.map((item) => {
      if (type === 'Materials') {
        return {
          'New Material Code': item.int_rfs_impl_material_creation.new_material_code
            ? item.int_rfs_impl_material_creation.new_material_code
            : 'N/A',
          'New Material Description': item.int_rfs_impl_material_creation.new_material_desc
            ? item.int_rfs_impl_material_creation.new_material_desc
            : 'N/A',
          Plant: item.int_rfs_material.plant,
          Status: (
            <Badge
              color={
                item.status === 'In Progress'
                  ? 'yellow'
                  : item.status === 'Closed'
                  ? 'green'
                  : 'gray'
              }
              size='lg'
            >
              {' '}
              {item.status}{' '}
            </Badge>
          ),
          Action: Action(item),
          Logs: Logs(item.id),
          'Step Deadline': moment(item.sla_date).format('YYYY-MM-DD'),
          'Step Finished': item.finished_date
            ? moment(item.finished_date).format('YYYY-MM-DD')
            : item.status,
          scope_id: item.scope_id,
        };
      } else if (type === 'Liquids') {
        let scope_selection_code =
          'LIQ - ' +
          item.int_rfs_liquid.int_rfs_scope_selection.selection_number +
          ' - ' +
          item.int_rfs_liquid.int_rfs_scope_selection.selection_name;
        return {
          'New Material Code': item.int_rfs_impl_material_creation.new_material_code
            ? item.int_rfs_impl_material_creation.new_material_code
            : 'N/A',
          'New Material Description': item.int_rfs_impl_material_creation.new_material_desc
            ? item.int_rfs_impl_material_creation.new_material_desc
            : 'N/A',
          Plant: item.int_rfs_liquid.plant,
          Status: (
            <Badge
              color={
                item.status === 'In Progress'
                  ? 'yellow'
                  : item.status === 'Closed'
                  ? 'green'
                  : 'gray'
              }
              size='lg'
            >
              {' '}
              {item.status}{' '}
            </Badge>
          ),
          Action: Action(item),
          Logs: Logs(item.id),
          'Step Deadline': moment(item.sla_date).format('YYYY-MM-DD'),
          'Step Finished': item.finished_date
            ? moment(item.finished_date).format('YYYY-MM-DD')
            : item.status,
          scope_id: item.scope_id,
          scope_selection_code: scope_selection_code,
        };
      }
    });
  };

  const filteredDataRes = filteredData(statusDataList, type);

  const HtmlRenderedData = ({ type }) => {
    const MaterialHeads = (
      <thead>
        <tr id='header-row'>
          <th>New Material Code</th>
          <th>New Material Description</th>
          <th>Plant</th>
          <th>Status</th>
          <th>Action</th>
          <th>Logs</th>
          <th>Step Deadline</th>
          <th>Step Finished</th>
        </tr>
      </thead>
    );

    const Materials = () => {
      const rowsData = filteredDataRes.map((item, index) => (
        <tr key={index}>
          {Object.values(item).map((it, i) => {
            return (
              i < 8 && (
                <td key={i} style={{ padding: '0.8rem !important' }}>
                  {it}
                </td>
              )
            );
          })}
        </tr>
      ));

      return (
        <Table>
          {MaterialHeads}
          <tbody>{rowsData}</tbody>
        </Table>
      );
    };

    const LiquidHeads = (
      <thead>
        <tr id='header-row'>
          <th>New Material Code</th>
          <th>New Material Description</th>
          <th>Plant</th>
          <th>Status</th>
          <th>Action</th>
          <th>Logs</th>
          <th>Step Deadline</th>
          <th>Step Finished</th>
        </tr>
      </thead>
    );

    const Liquids = () => {
      let arr = [];
      filteredDataRes.map((item) =>
        arr.includes(item.scope_id) === false ? arr.push(item.scope_id) : '',
      );

      const a = arr.map((arr_item) => {
        return filteredDataRes.filter((item) => {
          return item.scope_id == arr_item;
        });
      });

      return (
        <Accordion value={state} onChange={handlers}>
          {a.map((item, index) => {
            return (
              <Accordion.Item
                className='scope-label'
                label={`${item[0].scope_selection_code || item[0].scope_id}`}
                key={`key_${index}`}
              >
                <Grid>
                  <Grid.Col span={12}>
                    <Styles>
                      <div
                        className={'divsc' + index}
                        style={{ overflow: 'auto' }}
                        onScroll={(event) =>
                          getScrollPositionDiv(event, event.currentTarget.scrollLeft)
                        }
                      >
                        <Table>
                          {LiquidHeads}
                          <tbody>
                            {item.map((item, i) => (
                              <tr key={i}>
                                {Object.values(item).map((it, i) => {
                                  return (
                                    i < 8 && (
                                      <td
                                        key={i}
                                        style={{
                                          padding: '0.8rem !important',
                                        }}
                                      >
                                        {it}
                                      </td>
                                    )
                                  );
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Styles>
                  </Grid.Col>
                </Grid>
              </Accordion.Item>
            );
          })}
        </Accordion>
      );
    };

    return type === 'Liquids' ? <Liquids /> : <Materials />;
  };

  return (
    <Styles>
      <div
        className={'divscM'}
        style={{ overflow: 'auto' }}
        onScroll={(event) => getScrollPositionDiv(event, event.currentTarget.scrollLeft)}
      >
        <HtmlRenderedData type={type} />
      </div>

      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={status}
          state={state}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Status Reversal'
        />
      )}
    </Styles>
  );
};

export default StatusReversalTable;
