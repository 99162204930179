import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button, TextInput } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import { PlantExtensionTable } from './PlantExtensionTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { roles } from '../../../../constants';
import { AuthContext } from '../../../../contexts/AuthContext';
import { sCodeStyles } from '../../../../utils/helpers';
import { AccfComment } from '../../../../common/accf';

const accessors = {
  materials: 'selection_number',
  liquids: 'selection_number',
  skus: 'selection_number',
};
const secondaryAccessor = {
  materials: 'selection_name',
  liquids: 'selection_name',
  skus: 'selection_name',
};
const tertiaryAccessor = {
  materials: null,
  liquids: null,
  skus: null,
};

export default function PlantExtensionCode() {
  const { userRole } = useContext(AuthContext);
  const { form } = useContext(RfsContext);
  const { fetchProductionPlantExtension, StoreManualClosure } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });
  const [loading, setLoading] = useState(false);
  const [productPlantExtension, setProductPLantExtension] = useState([]);
  const [disable, setDisable] = useState(true);
  const [slaId, setSlaId] = useState(0);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    getData(rfs_number);
  }, []);

  const getData = (rfs_number) => {
    setLoading(true);
    fetchProductionPlantExtension(rfs_number).then((res) => {
      setProductPLantExtension(res.data.data);
      setSlaId(res.data.data[0].sla_weeks_id);
      setLoading(false);
    });
  };

  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo, roles.masterDataTeam].includes(userRole))
      setDisable(false);
  }, [userRole]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < productPlantExtension.length; i++) {
      arr.push(productPlantExtension[i].status);
    }
    if (arr) {
      const setArray = [...new Set(arr)];
      if (
        (setArray.length === 1 && setArray.includes('Closed')) ||
        (setArray.length === 1 && setArray.includes('Not Started'))
      ) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    }
  }, [productPlantExtension]);

  const save = (payload, id, callback) => {
    StoreManualClosure(payload, id).then(() => {
      refresh(rfs_number);
      callback();
    });
  };

  const refresh = (rfs_number) => {
    fetchProductionPlantExtension(rfs_number).then((res) => {
      setProductPLantExtension(res.data.data);
    });
  };

  const sort_ppe_acctoSKUs = [...productPlantExtension].sort((a, b) => a.scope_id - b.scope_id);

  const selectedScopeFn = (data) => {
    let selectedScopeList = {};
    for (let i = 0; i < data.length; i++) {
      selectedScopeList[data[i].id] = {
        selection_number: data[i].selection_number,
        selection_name: data[i].selection_name,
      };
    }
    return selectedScopeList;
  };

  var selectedScopeList = selectedScopeFn(form.selectedScope.skus);

  const dataToExport = ({
    int_rfs_ps_sku_reference,
    manually_closed,
    sla_date,
    status,
    type_of_change,
    scope_id,
    new_material_name,
    new_material_desc,
    int_rfs_ps_bom,
    finished_date,
  }) => {
    return {
      'SKU no.': selectedScopeList[scope_id]?.selection_number,
      'SKU Desc.': selectedScopeList[scope_id]?.selection_name,
      'Reference Plant': int_rfs_ps_sku_reference?.reference_plant,
      Aternative: int_rfs_ps_sku_reference?.reference_alt_bom,
      'New Plant': int_rfs_ps_sku_reference?.selected_plant,
      'Types of Change': type_of_change,
      'Old material number': int_rfs_ps_bom?.bom_component,
      'Old material name': int_rfs_ps_bom?.component_description,
      'new material number': new_material_name,
      'new material name': new_material_desc,
      Production: int_rfs_ps_sku_reference?.production,
      'MP/Dating Reference': int_rfs_ps_sku_reference?.mother_product_date_formatting,
      Comments: int_rfs_ps_bom?.comment,
      'New Alternative Number': int_rfs_ps_sku_reference?.new_alt_bom,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step Deadline': sla_date,
      'Step Finished': status === 'Closed' ? finished_date : status,
    };
  };
  const csvArray = sort_ppe_acctoSKUs.map(dataToExport);

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }
  return (
    <ContentPaper page={'ProductDevelopment'}>
      <Box sx={sCodeStyles}>SKU CODES</Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <CSVLink
          data={csvArray}
          filename={'Production Plant Extension_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button leftIcon={<TableExport />} variant='gradient' radius='md' color='green'>
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      <Row>
        <Accordion defaultValue={'key_0'}>
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              const extensionList = productPlantExtension.findIndex((m) => m.scope_id === item.id);
              const plants = Object.values(form.scopeSelectedSkus);

              return (
                extensionList > -1 && (
                  <Accordion.Item
                    className='scope-label'
                    key={`key_${name}_${index}`}
                    value={`key_${name}_${index}`}
                  >
                    <Accordion.Control>
                      {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                        item[secondaryAccessor[name]]
                      }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                    </Accordion.Control>
                    <Accordion.Panel>
                      {
                        <Grid>
                          <Grid.Col span={12}>
                            {plants.map(
                              (plant, index) =>
                                plant.scope_id === item.id &&
                                plant.active && (
                                  <PlantExtensionTable
                                    extensionData={productPlantExtension.filter(
                                      (d) => d.sku_id === plant.id,
                                    )}
                                    save={save}
                                    refresh={refresh}
                                    disable={disable}
                                  />
                                ),
                            )}
                          </Grid.Col>
                        </Grid>
                      }
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            }),
          )}
        </Accordion>
      </Row>
      {!disable && <AccfComment slaId={slaId} status={status} rfs_number={rfs_number} />}
    </ContentPaper>
  );
}
