import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import RfsInitiationForm from '../../components/forms/rfsInitiationForm/rfsInitiationForm';
import RfsLayout from '../../components/layouts/RfsLayout';
import { RfsContext } from '../../contexts/RfsContext';

import './rfsInitiationPage.css';
import SupplierChangeInformation from '../supplierChange/generalInformation';

export default function RfsInitiationPage() {
  const { info, fetchCostCenters, reset, form } = useContext(RfsContext);
  const params = useParams();
  const { rfs_type } = form.rfsInformation;

  useEffect(() => {
    if (params.rfsNumber) {
      fetchCostCenters();
      info(params.rfsNumber);
    }
    return () => reset();
  }, [params.rfsNumber]);

  return (
    <RfsLayout>
      {rfs_type === 'Supplier Change' ? (
        <SupplierChangeInformation withProjectName initialItems={0} />
      ) : (
        <RfsInitiationForm withProjectName initialItems={0} />
      )}
    </RfsLayout>
  );
}
