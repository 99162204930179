import React, { createContext, useCallback, useContext, useState } from 'react';

import { axios } from '../utils/axios';
import supplierFormSchema from '../pages/supplierChange/scope/validationSchema';
import { RfsContext } from './RfsContext';

export const SupplierContext = createContext({});

export const SupplierProvider = ({ children }) => {
  const { form } = useContext(RfsContext);
  const [loading, setLoading] = useState(false);
  const [supplierForm, setSupplierForm] = useState([]);
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    supplierErrors: {},
  });
  const [plantsLoading, setPlantLoading] = useState(false);
  const [activePlants, setActivePlants] = useState({});

  const validateScope = (data) => {
    const errors = {};
    const supplierFieldErrors = {};

    if (data.length === 0) {
      errors['no_material'] = 'Please select at least 1 material';
    } else {
      data.forEach((item) => {
        supplierFormSchema
          .validate(
            { ...item, to_be_supplier: item.to_be_supplier ? item.to_be_supplier : [] },
            { abortEarly: false },
          )
          .then(() => {})
          .catch((err) => {
            const fieldErrors = err.errors;
            fieldErrors.forEach((error) => {
              let [err] = error.split(' ');
              errors[item.scope_id] = true;

              if (!supplierFieldErrors[`${item.scope_id}_${item.plant}`])
                supplierFieldErrors[`${item.scope_id}_${item.plant}`] = {};

              supplierFieldErrors[`${item.scope_id}_${item.plant}`][err] = true;
            });
          });
      });
    }

    return { errors, fieldErrors: supplierFieldErrors };
  };

  const fetchSavedMaterials = (rfs_number) => {
    return new Promise((resolve) => {
      axios.get(`/supplier-changes/scope-selection/materials/fetch/${rfs_number}`).then(
        (res) => {
          resolve(res.data.data.materials);
        },
        (error) => {
          resolve([]);
        },
      );
    });
  };

  const trimSupplierTrailingSpaces = (data) => {
    const trimmedData = data.map((item) => item.trim());
    return trimmedData;
  };

  const getPlantsInfo = (rfs_number) => {
    setPlantLoading(true);
    axios
      .get(`/supplier-changes/scope-selection/materials/${rfs_number}`)
      .then(
        (scopeMaterials) => {
          fetchSavedMaterials(rfs_number).then(
            (savedMaterials) => {
              scopeMaterials = scopeMaterials.data.data.materials;
              const currentSupplierForm = [];
              scopeMaterials.forEach((item) => {
                const savedIndex = savedMaterials.findIndex(
                  (mat) => parseInt(mat.material_code) === parseInt(item.material_code),
                );
                if (savedIndex > -1) {
                  currentSupplierForm.push({
                    ...savedMaterials[savedIndex],
                    current_suppliers: item.current_suppliers,
                    scope_id: parseInt(savedMaterials[savedIndex].scope_id),
                    to_be_supplier: savedMaterials[savedIndex].to_be_supplier
                      ? trimSupplierTrailingSpaces(
                          savedMaterials[savedIndex].to_be_supplier.split(','),
                        )
                      : [],
                  });
                } else {
                  const scopeIndex = form.selectedScope.materials.findIndex(
                    (scope) => scope.selection_number === item.material_code,
                  );
                  currentSupplierForm.push({
                    ...item,
                    scope_id:
                      scopeIndex > -1
                        ? parseInt(form.selectedScope.materials[scopeIndex].id)
                        : null,
                  });
                }
              });
              setSupplierForm(currentSupplierForm);
            },
            (err) => {
              console.log(err);
            },
          );
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setPlantLoading(false));
  };

  return (
    <SupplierContext.Provider
      value={{
        loading,
        supplierForm,
        setSupplierForm,
        scopeErrors,
        setScopeErrors,
        validateScope,
        plantsLoading,
        setPlantLoading,
        activePlants,
        setActivePlants,
        getPlantsInfo,
        fetchSavedMaterials,
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};
