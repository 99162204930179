import React, { Component } from 'react';
import * as JSGantt from 'jsgantt-improved';
import './gantt.css';

const getRandomIntInclusive = (min, max) => {
  const randomBuffer = new Uint32Array(1);

  window.crypto.getRandomValues(randomBuffer);

  let randomNumber = randomBuffer[0] / (0xffffffff + 1);

  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(randomNumber * (max - min + 1)) + min;
}


export default class CustomGanttChart extends Component {
  //id = 'reactgantteditor' + Math.floor(Math.random() * 1000000);
  id = 'reactgantteditor' + getRandomIntInclusive(0, 1000000);
  editor;
  options;
  optionsChanged = false;

  componentDidMount() {
    this.makeChart();
  }
  componentDidUpdate() {
    this.makeChart();
  }

  makeChart() {
    const jsantt = JSGantt;
    const GanttChart = jsantt.GanttChart;
    const g = (this.editor = new GanttChart(document.getElementById(this.id), 'week'));
    let optionsBefore = this.options || this.props.options;

    if (!this.optionsChanged && this.editor && this.editor.options) {
      optionsBefore = this.editor.options;
    }

    if (g.getDivId() != null) {
      g.setOptions({
        vCaptionType: 'Complete', // Set to Show Caption : None,Caption,Resource,Duration,Complete,
        vQuarterColWidth: 36,
        vDateTaskDisplayFormat: 'day dd month yyyy', // Shown in tool tip box
        vDayMajorDateDisplayFormat: 'mon yyyy - Week ww', // Set format to display dates in the "Major" header of the "Day" view
        vWeekMinorDateDisplayFormat: 'dd mon', // Set format to display dates in the "Minor" header of the "Week" view
        vShowTaskInfoLink: 1, // Show link in tool tip (0/1)
        vShowEndWeekDate: 0, // Show/Hide the date for the last day of the week in header for
        vUseSingleCell: 10000,
        //vDebug: true,
        // Even with setUseSingleCell using Hour format on such a large chart can cause issues in some browsers
        vFormatArr: ['Day', 'Week', 'Month', 'Quarter'],
        ...optionsBefore,
      });
      const { data } = this.props;
      if (data && data.forEach) {
        data.forEach((row) => {
          row.pGantt = g;
          g.AddTaskItemObject(row);
        });
      }
      g.Draw();
    }
  }

  render() {
    return <div id={this.id} className='gantt'></div>;
  }
}
