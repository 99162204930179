import React, { useContext, useEffect, useState } from 'react';
import { Modal, Accordion, Container, Grid, Group, Text } from '@mantine/core';
import { X, Check } from 'tabler-icons-react';
import moment from 'moment';
import { RfsContext } from '../../../contexts/RfsContext';

export const ProductionLog = ({ logModal, setLogModal, id, type }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const [data, setData] = useState([]);
  const { fetchLogs } = useContext(RfsContext);

  function getLogData() {
    fetchLogs(id).then((res) => {
      setData(res.data.data);
    });
  }
  useEffect(() => {
    if (logModal.open) {
      getLogData();
    }
  }, [logModal]);

  return (
    <div>
      <Modal
        opened={logModal.open}
        onClose={() => setLogModal({ open: false, id: logModal.id })}
        title='Logs'
        className='confirm-modal'
      >
        <Container>
          <Accordion
            defaultValue={'key_0'}
            overflow='inside'
            iconPosition='right'
            classNames={{
              label: 'accordion-label fw-bold',
              contentInner: 'accordion-innerContent',
              control: 'p-0',
            }}
          >
            <Accordion.Item className='scope-implementation-label' value='key_0'>
              <Accordion.Control>{`${capitalizeFirstLetter(type)}`}</Accordion.Control>
              <Accordion.Panel>
                {
                  //data.int_rfs_implementation_status_logs?.length > 0 ? data.int_rfs_implementation_status_logs?.map((log, index) => {
                  data[0] && data[0].int_rfs_implementation_status_logs.length > 0 ? (
                    data[0].int_rfs_implementation_status_logs.map((log, index) => {
                      return (
                        <div key={index}>
                          <Text>
                            <span style={{ fontWeight: 'bold' }}>Step: </span>
                            {capitalizeFirstLetter(type)}
                            <span style={{ paddingLeft: '0.5rem' }}>
                              {log.status === null ? (
                                <X color='red' size='1rem' />
                              ) : (
                                <Check color='green' size='1rem' />
                              )}
                            </span>
                          </Text>
                          <Text>
                            <span style={{ fontWeight: 'bold' }}>Timestamp: </span>
                            {log.manually_closed_at
                              ? moment(log.manually_closed_at).format('DD-MM-YYYY')
                              : log.createdAt
                              ? moment(log.createdAt).format('DD-MM-YYYY')
                              : 'Not Started'}
                          </Text>
                          <Text>
                            <span style={{ fontWeight: 'bold' }}>Remark: </span>
                            {log.status === null ? log.remark : log.status}
                          </Text>
                          {log.manually_closed_by ? (
                            <div>
                              <Text>
                                <span style={{ fontWeight: 'bold' }}>Manually closed by: </span>
                                {log.manually_closed_by}
                              </Text>
                              {log.manually_closed_comment && (
                                <Text>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Manually closed comment:{' '}
                                  </span>
                                  {log.manually_closed_comment}
                                </Text>
                              )}
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <br />
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}> No Logs Found </span>
                      </Text>
                    </div>
                  )
                }
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Modal>
    </div>
  );
};

export default ProductionLog;
