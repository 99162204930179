import React from 'react';
import { crRoles } from '../../constants';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

const AdminRoute = ({ children }) => {
    const {crUserRoles,} = useContext(AuthContext);

    if (crUserRoles.length === 1 && crUserRoles.includes(crRoles.requestor)) {
    window.location.href = '/unauth'; 
    return null; 
  }

  return children; 
};

export default AdminRoute;
