import React, { useState } from 'react';
import { Button, Text, Modal, Grid, Group } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
function UnauthorisedModal() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  return (
    <div>
      <Modal
        opened={open}
        onClose={() => navigate('/home')}
        centered='true'
        withCloseButton={false}
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='disclaimer-modal-group'>
              <InfoCircle color='black' size='5rem' />
              <Text align='center'>
                You do not have access to this page. Contact the administrator to obtain permission.
              </Text>
            </Group>
          </Grid.Col>
        </Grid>

        {
          <div style={{ display: 'flex' }}>
            <Button
              variant='gradient'
              onClick={() => {
                navigate('/home');
              }}
              style={{
                marginTop: '0.5rem',
                marginLeft: 'auto',
              }}
            >
              OK
            </Button>
          </div>
        }
      </Modal>
    </div>
  );
}
export default UnauthorisedModal;
