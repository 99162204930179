import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AttachmentCompPd from '../../components/production-site attachment/attachmentComp-pd';
import AttachmentComp from '../../components/attachmentComp/attachmentComp';
import AttachmentCompDLST from '../DLST/attachmentPageDLST/attachmentPageDLST';
import RfsLayout from '../../components/layouts/RfsLayout';
import { RfsContext } from '../../contexts/RfsContext';

import './AttachmentPage.css';
import { AppLoader } from '../../components/common/loader';
import { routes } from '../../routes';
import { rfsStatus } from '../../constants';
import SupplierChangeAttachment from '../supplierChange/attachments';

export default function AttachmentPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { info, form } = useContext(RfsContext);
  const { rfs_type } = form.rfsInformation;

  useEffect(() => {
    if (params.rfsNumber) info(params.rfsNumber);
  }, [params.rfsNumber]);

  if (rfs_type.length === 0) {
    return (
      <RfsLayout>
        <AppLoader size='lg' center />
      </RfsLayout>
    );
  }

  if (
    form.rfsInformation.status &&
    ![
      rfsStatus.draft,
      rfsStatus.tech_feasible,
      rfsStatus.tech_feasible2,
      rfsStatus.supplier_pmo2_active,
    ].includes(form.rfsInformation.status)
  ) {
    navigate(routes.submission(params.rfsNumber).overView);
  }

  return (
    <RfsLayout>
      {rfs_type.toLowerCase() === 'production site' ? (
        <AttachmentCompPd withProjectName initialItem={0} />
      ) : rfs_type.toLowerCase() === 'delisting' ? (
        <AttachmentCompDLST withProjectName initialItem={0} />
      ) : rfs_type.toLowerCase() === 'supplier change' ? (
        <SupplierChangeAttachment withProjectName initialItem={0} />
      ) : (
        <AttachmentComp withProjectName initialItem={0} />
      )}
    </RfsLayout>
  );
}
