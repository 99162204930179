import React, { useState, useMemo, useContext, useRef, useEffect } from 'react';
import { useTable } from 'react-table';
import { Text, Badge, Button, Checkbox, NumberInput, Select } from '@mantine/core';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { AuthContext } from '../../../../contexts/AuthContext';
import moment from 'moment';
import { RfsContext } from '../../../../contexts/RfsContext';
import ProductionLog from '../productionSiteLogModal';

export function ArtworkDevelopmentTable({
  data,
  save,
  details,
  setDetails,
  setAttachmentModal,
  readOnly,
}) {
  const { users } = useContext(AuthContext);
  const { form } = useContext(RfsContext);
  const { initiator_email } = form.rfsInformation;
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const dataRef = useRef(null);
  dataRef.current = data;

  const detailsRef = useRef(null);
  detailsRef.current = details;

  const handleChange = (value, name, id) => {
    if (value?.target?.type === 'checkbox') {
      setDetails({ [id]: { [name]: value.target.checked } });
    } else {
      setDetails({ [id]: { [name]: value } });
    }
  };

  const getLogs = (imp_id) => {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'New Material Code',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_impl_material_creation.new_material_code}</Text>;
        },
      },
      {
        Header: 'New Material Description',
        Cell: ({ row }) => {
          return <Text> {row.original.int_rfs_impl_material_creation.new_material_desc}</Text>;
        },
      },
      {
        Header: 'Plant',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_material.plant}</Text>;
        },
      },
      {
        Header: 'PO Number Provider for this artwork',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !detailsRef.current[info.id] ||
            !detailsRef.current[info.id]['artwork_po_provider'] ||
            info.gfp_provided ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info?.artwork_po_provider ? (
            <Text>{info.artwork_po_provider}</Text>
          ) : (
            <>
              <Select
                placeholder='PO Number Provider'
                searchable
                nothingFound='No options'
                onChange={(event) => handleChange(event, 'artwork_po_provider', info.id)}
                value={
                  detailsRef.current[info.id]
                    ? detailsRef.current[info.id]['artwork_po_provider']
                    : null
                }
                disabled={
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  info.gfp_provided ||
                  readOnly
                }
                clearable
                data={users.map((u) => {
                  return {
                    label: u.name,
                    value: u.email,
                  };
                })}
              />

              {!isDisabled && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                  }}
                  position='Right'
                  size='sm'
                  variant='gradient'
                  gradient={{ from: '#e3af32', to: '#f4e00f' }}
                  color='primary'
                  radius='xs'
                  fullWidth
                  onClick={() => save(detailsRef.current, info.id, row.original.id)}
                  disabled={isDisabled || readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'PO Number for Artwork',
        Cell: ({ row }) => {
          const { artwork_po_number } = detailsRef.current;
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !detailsRef.current[info.id] ||
            !detailsRef.current[info.id]['artwork_po_number'] ||
            !info.artwork_po_provider ||
            info.gfp_provided ||
            ['Closed', 'Not Started'].includes(row.original.status);

          let a = detailsRef.current[info.id]
            ? detailsRef.current[info.id]['artwork_po_number']
            : '';

          return info.artwork_po_number ? (
            <Text>{info.artwork_po_number}</Text>
          ) : (
            <>
              <NumberInput
                placeholder='PO Number'
                hideControls
                radius='md'
                max={9999999999}
                onChange={(event) => handleChange(event, 'artwork_po_number', info.id)}
                value={
                  detailsRef.current[info.id]
                    ? detailsRef.current[info.id]['artwork_po_number']
                    : ''
                }
                disabled={
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  info.gfp_provided ||
                  !info.artwork_po_provider ||
                  readOnly
                }
                error={info.artwork_po_provider && !a}
              />

              {!isDisabled && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  onClick={() => save(detailsRef.current, info.id, row.original.id)}
                  disabled={isDisabled || readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Artwork Briefed to Agency',
        Cell: ({ row }) => {
          // return <Checkbox label='Completed' />;
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !detailsRef.current[info.id] ||
            !detailsRef.current[info.id]['artwork_briefed'] ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.artwork_briefed ? (
            <Checkbox label='Completed' disabled={true} defaultChecked={true} />
          ) : (
            <>
              <Checkbox
                checked={
                  detailsRef.current[info.id]
                    ? detailsRef.current[info.id]['artwork_briefed']
                    : false
                }
                label='Completed'
                disabled={['Closed', 'Not Started'].includes(row.original.status) || readOnly}
                onChange={(event) => handleChange(event, 'artwork_briefed', info.id)}
              />

              {!isDisabled && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  onClick={() => save(detailsRef.current, info.id, row.original.id)}
                  disabled={isDisabled || readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Colour target setting finalized',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !detailsRef.current[info.id] ||
            !detailsRef.current[info.id]['color_target_setting_finalized'] ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.color_target_setting_finalized ? (
            <Checkbox label='Completed' disabled={true} defaultChecked={true} />
          ) : (
            <>
              <Checkbox
                label='Completed'
                checked={
                  detailsRef.current[info.id]
                    ? detailsRef.current[info.id]['color_target_setting_finalized']
                    : false
                }
                disabled={
                  info.color_target_setting_finalized ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                onChange={(event) => handleChange(event, 'color_target_setting_finalized', info.id)}
              />

              {!isDisabled && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  onClick={() => save(detailsRef.current, info.id, row.original.id)}
                  disabled={isDisabled || readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Artwork Approved/ Artwork sent to supplier',
        Cell: ({ row }) => {
          // return <Checkbox label='Confirm and Close' />;
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !detailsRef.current[info.id] ||
            !detailsRef.current[info.id]['artwork_approved'] ||
            info.gfp_provided ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.artwork_approved ? (
            <Checkbox label='Completed' disabled={true} defaultChecked={true} />
          ) : (
            <>
              <Checkbox
                label='Completed'
                checked={
                  detailsRef.current[info.id]
                    ? detailsRef.current[info.id]['artwork_approved']
                    : false
                }
                disabled={
                  info.artwork_approved ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                onChange={(event) => handleChange(event, 'artwork_approved', info.id)}
              />

              {!isDisabled && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  onClick={() => save(detailsRef.current, info.id, row.original.id)}
                  disabled={isDisabled || readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'PO Number Provider for Supplier',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !detailsRef.current[info.id] ||
            !detailsRef.current[info.id]['supplier_po_provider'] ||
            info.gfp_provided ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.supplier_po_provider ? (
            <Text>{info.supplier_po_provider}</Text>
          ) : (
            <>
              <Select
                placeholder='PO Number Provider'
                searchable
                nothingFound='No options'
                disabled={
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  info.gfp_provided ||
                  readOnly
                }
                value={
                  detailsRef.current[info.id]
                    ? detailsRef.current[info.id]['supplier_po_provider']
                    : null
                }
                onChange={(event) => handleChange(event, 'supplier_po_provider', info.id)}
                data={users.map((u) => {
                  return {
                    label: u.name,
                    value: u.email,
                  };
                })}
              />
              {!isDisabled && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  onClick={() => save(detailsRef.current, info.id, row.original.id)}
                  disabled={isDisabled || readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'PO Number for Supplier',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !detailsRef.current[info.id] ||
            !detailsRef.current[info.id]['supplier_po_number'] ||
            !info.supplier_po_provider ||
            info.gfp_provided ||
            ['Closed', 'Not Started'].includes(row.original.status);

          let b = detailsRef.current[info.id]
            ? detailsRef.current[info.id]['supplier_po_number']
            : '';

          return info.supplier_po_number ? (
            <Text>{info.supplier_po_number}</Text>
          ) : (
            <>
              <NumberInput
                radius='md'
                placeholder='PO Number'
                hideControls
                max={9999999999}
                onChange={(event) => handleChange(event, 'supplier_po_number', info.id)}
                value={
                  detailsRef.current[info.id]
                    ? detailsRef.current[info.id]['supplier_po_number']
                    : ''
                }
                disabled={
                  !info.supplier_po_provider ||
                  info.gfp_provided ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                error={info.supplier_po_provider && !b}
              />

              {!isDisabled && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  onClick={() => save(detailsRef.current, info.id, row.original.id)}
                  disabled={isDisabled || readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'GFP Attachment',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const attachments =
            (info?.gfp_attachment || '').length > 0
              ? info.gfp_attachment.split(',').map((name, index) => {
                  return { id: index + 1, name: name };
                })
              : [];
          return (
            <Button
              disabled={['Closed', 'Not Started'].includes(row.original.status) || readOnly}
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              onClick={() =>
                setAttachmentModal({
                  open: true,
                  artwork_dev: row.original.int_rfs_impl_artwork_dev,
                  status_id: row.original.id,
                  attachments: attachments,
                })
              }
            >
              Attachment
            </Button>
          );
        },
      },
      {
        Header: 'GFP Provided',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !detailsRef.current[info.id] ||
            !detailsRef.current[info.id]['gfp_provided'] ||
            ['Closed', 'Not Started'].includes(row.original.status);

          return info.gfp_provided ? (
            <Checkbox label='Completed' disabled={true} defaultChecked={true} />
          ) : (
            <>
              <Checkbox
                label='Completed'
                disabled={
                  info.gfp_provided ||
                  (info.supplier_po_provider && !info.supplier_po_number) ||
                  (!info.artwork_po_number && info.artwork_po_provider) ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                onChange={(event) => handleChange(event, 'gfp_provided', info.id)}
              />

              {!isDisabled && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  onClick={() => save(detailsRef.current, info.id, row.original.id)}
                  disabled={isDisabled || readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'GFP Approved',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !detailsRef.current[info.id] ||
            !detailsRef.current[info.id]['gfp_approved'] ||
            ['Closed', 'Not Started'].includes(row.original.status);
          return info.gfp_approved ? (
            <Text>{info.gfp_approved}</Text>
          ) : (
            <>
              <Select
                disabled={
                  !info.gfp_provided ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                onChange={(event) => handleChange(event, 'gfp_approved', info.id)}
                placeholder='Approved/Not Approved'
                searchable
                nothingFound='No options'
                data={['Approved', 'Not Approved']}
              />
              {!isDisabled && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  onClick={() => save(detailsRef.current, info.id, row.original.id)}
                  disabled={isDisabled || readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Changes Made',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          const isDisabled =
            !detailsRef.current[info.id] ||
            !detailsRef.current[info.id]['changes_made'] ||
            ['Closed', 'Not Started'].includes(row.original.status);
          return info.changes_made ? (
            <Text>{info.changes_made}</Text>
          ) : (
            <>
              <Select
                disabled={
                  !info.gfp_approved ||
                  info.gfp_approved === 'Approved' ||
                  ['Closed', 'Not Started'].includes(row.original.status) ||
                  readOnly
                }
                onChange={(event) => handleChange(event, 'changes_made', info.id)}
                placeholder='Changed in ERP/ Changed the Artwork'
                searchable
                nothingFound='No options'
                data={['Changed in ERP', 'Changed the Artwork']}
              />
              {!isDisabled && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  onClick={() => save(detailsRef.current, info.id, row.original.id)}
                  disabled={isDisabled || readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },

      {
        Header: 'Status',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.status === 'In Progress'
                  ? 'yellow'
                  : row.original.status === 'Closed'
                  ? 'green'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.status}
            </Badge>
          );
        },
      },
      {
        Header: 'Log',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          return (
            <Button
              disabled={['Not Started'].includes(row.original.status)}
              style={{ background: 'black', color: 'white' }}
              onClick={() => getLogs(row.original.id)}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          const date = row.original.sla_date;
          return <Text>{date ? moment(date).format('YYYY-MM-DD') : 'N/A'}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          const date = row.original.finished_date;
          const status = row.original.status;
          return <Text>{date ? moment(date).format('YYYY-MM-DD') : status}</Text>;
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns,
    data: dataRef.current,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Artwork Development'
        />
      )}
    </Styles>
  );
}
