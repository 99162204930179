import { Select } from '@mantine/core';
import { useContext, useState } from 'react';
import { RfsContext } from '../../contexts/RfsContext';

export const CostCenter = ({ onCreate, onChange, value, error = false, name = 'cost_center' }) => {
  const { costCentersObject = {}, costCenters = [] } = useContext(RfsContext);
  const [search, setSearch] = useState('');

  const getCostCenter = () => {
    let filteredData = [...costCenters];

    if (search.length === 0) {
      filteredData = filteredData.splice(0, 100);
    } else {
      let data = filteredData.filter(
        (item) =>
          item?.value?.length > 0 && item.label.toLowerCase().startsWith(search.toLowerCase()),
      );

      filteredData = data.splice(0, 100);
    }

    const selectedValueIndex = filteredData.findIndex((data) => data.value === value);

    if (selectedValueIndex === -1 && value) {
      if (costCentersObject[value]) {
        filteredData.push({
          label: costCentersObject[value],
          value: value,
        });
      } else {
        filteredData.push({ label: value, value: value });
      }
    }

    return filteredData;
  };

  const handleCreate = (query) => {
    const queryKey = query.split(' ')[0];

    onCreate(
      costCentersObject[queryKey] && (costCentersObject[queryKey] || '').trim() === query.trim()
        ? queryKey
        : query,
    );
  };

  return (
    <Select
      label='Cost Center'
      data={getCostCenter()}
      onSearchChange={(query) => setSearch(query)}
      nothingFound='Nothing found'
      searchable
      clearable
      getCreateLabel={(query) => `+ Select ${query}`}
      onCreate={handleCreate}
      name={name}
      value={value}
      onChange={onChange}
      error={error ? 'Required Field' : ''}
      radius='md'
      className='InputField'
      required
      placeholder='Max 50 characters'
      creatable={search.trim().length > 0 && search.trim().length < 50 && search !== 'N/A'}
    />
  );
};
