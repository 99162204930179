import React, { createContext, useRef, useState, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  artworkDevelopmentStatus,
  eanApprovalStatus,
  liquidChangeoverStatus,
  liquidFailureReasonStatus,
  liquidQuantityChangeStatus,
  liquidRemovalOfChangeoverStatus,
  liquidSupplierNumberNameStatus,
  liquidTechSpecNumberStatus,
  liquidTrialsFailedStatus,
  liquidTrialStatus,
  materialChangeoverStatus,
  materialContractStatus,
  materialFailureReasonStatus,
  materialMaterialStatusChangeStatus,
  materialRemovalOfChangeoverStatus,
  materialSupplierNumberNameStatus,
  materialTechSpecNumberStatus,
  materialTrialsFailedStatus,
  materialTrialStatus,
  trialLiquidCodeStatus,
  trialMaterialCodeStatus,
  trialMaterialDescriptionStatus,
} from '../components/tables/ProductDevelopmentTable/status';
import { roles } from '../constants';
import { liquidInterface, materialInterface } from '../constants/product-development';
import { axios } from '../utils/axios';

const steps = {
  trial_material_code: true,
  plant: true,
  material_description: true,
  supplier_number_and_name: true,
  artwork_developement: true,
  changeover: true,
  changeover_number: true,
  ean_approval: true,
  material_contract: true,
  trial: true,
  reason_of_failure: true,
  trials_failed: true,
  tech_spec_number_changed: true,
  removal_of_changeover: true,
  material_status_change: true,
};

const stepEigibleRoles = {
  trial_material_code: [roles.superAdmin, roles.masterDataTeam],
  trial_liquid_code: [roles.superAdmin, roles.masterDataTeam],
  material_description: [roles.superAdmin],
  supplier_number_and_name: [roles.superAdmin, roles.procurement],
  artwork_developement: [roles.superAdmin, roles.d2p],
  changeover: [roles.superAdmin],
  ean_approval: [roles.superAdmin, roles.mrp],
  material_contract: [roles.superAdmin],
  trial: [roles.superAdmin, roles.zitec],
  reason_of_failure: [roles.superAdmin, roles.zitec],
  quantities_changed: [roles.superAdmin, roles.zitec],
  trials_failed: [roles.superAdmin, roles.zitec],
  tech_spec_number_changed: [roles.superAdmin, roles.zitec],
  removal_of_changeover: [roles.superAdmin],
  material_status_change: [roles.superAdmin],
};

export const ProjectOverviewContext = createContext();

export const ProjectOverviewProvider = ({ children }) => {
  const params = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [scopeLiquids, setScopeLiquids] = useState([]);
  const [liquids, setLiquids] = useState([]);
  const [materialStepStatus, setMaterialStepStatus] = useState({});
  const [liquidStepStatus, setLiquidStepStatus] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [scopeUnlockInfo, setScopeUnlockInfo] = useState({});
  const [veamScopeUnlockInfo, setVeamScopeUnlockInfo] = useState({});
  const [PsScopeUnlockInfo, setPsScopeUnlockInfo] = useState({});
  const [delistingScopeUnlockInfo, setDelistingScopeUnlockInfo] = useState({});
  const [scScopeUnlockInfo, setScScopeUnlockInfo] = useState({});
  const [skus, setSkus] = useState([]); // only for osku scope unlock

  const materialStepRef = useRef(null);
  materialStepRef.current = materialStepStatus;

  const liquidStepRef = useRef(null);
  liquidStepRef.current = liquidStepStatus;

  useEffect(() => {
    if (location.search) {
      let tab = new URLSearchParams(location.search).get('tab');
      tab = isNaN(tab) ? 0 : parseInt(tab);
      setActiveTab(tab);
    }
  }, [location.search]);

  const reset = () => {
    setMaterials([]);
    setScopeLiquids([]);
    setLiquids([]);
    setMaterialStepStatus({});
    setLiquidStepStatus({});
    materialStepRef.current = {};
    liquidStepRef.current = {};
  };

  const createProductDevelopmentMaterial = (data, callback, trials) => {
    const attachments = data.attachments;
    let formData = new FormData();

    if (attachments.length > 0) {
      for (let k in attachments) {
        if (attachments[k].id) {
          formData.append('attachmentId[]', attachments[k].id);
        } else {
          formData.append('attachments', attachments[k]);
        }
      }
    } else {
      formData.append('attachmentId[]', []);
    }

    Object.keys(data).forEach((key) => {
      if (key !== attachments) formData.append(key, data[key]);
    });

    const queryParams = trials ? { trials } : {};

    axios
      .post(`/productDevelopment/materials/create/${params.rfsNumber}`, formData, {
        params: queryParams,
      })
      .then(
        (res) => {
          setRfsMaterials(
            res.data.prodDevInfo,
            res.data.row,
            res.data.attachments,
            res.data.implementation,
          );
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => callback());
  };

  const saveLiquids = (data, callback, trials) => {
    const queryParams = trials ? { trials } : {};

    axios
      .post(`/productDevelopment/liquids/save/${params.rfsNumber}`, data, {
        params: queryParams,
      })
      .then(
        (res) => {
          setRfsLiquids(res.data.prodDevInfo, res.data.productDevelopment);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => callback());
  };

  const createMaterialStatus = (data) => {
    setLoading(true);

    axios
      .post(`/productDevelopment/materials/status/${params.rfsNumber}`, data)
      .then(
        (res) => {},
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const fetchMaterialStatus = (data) => {
    axios.get(`/productDevelopment/materials/status/fetch/${params.rfsNumber}`).then((res) => {
      const response = res.data;
      return response;
    });
  };

  const isDescriptionAvailable = (material) => {
    if (material.PackMatDesc ? material.PackMatDesc : material.PlantDataDesc) return false;
    else return true;
  };

  const getChangeOverStatus = (items) => {
    if (items[0]?.material_type === 'Remove Material') {
      const index = items.findIndex((material) => material.changeoverAppliedStatusRemoval > 0);
      return index < 0;
    } else {
      // changeover applied if all items have status > 0
      const index = items.findIndex(
        (material) => !material.changeoverAppliedStatus || material.changeoverAppliedStatus <= 0,
      );

      return index === -1;
    }
  };

  const getLiquidChangeOverStatus = (liquid) => {
    let status = false;

    if (liquid.custom_added || liquid.liquid_type === 'Add Liquid') {
      status = liquid.changoverAppliedStatusNewLiquid > 0;
    } else if (liquid.new_quantity || liquid.liquid_type === 'Same Liquid') {
      status = liquid.changoverAppliedStatusQuantity > 0;
    } else if (liquid.liquid_type === 'Remove Liquid') {
      status = liquid.changoverAppliedStatusRemoveLiquid === 0;
    } else {
      status = liquid.changoverAppliedStatus > 0;
    }

    return status;
  };

  const setMaterialStatus = (material, allMaterials = []) => {
    const currentStatus = { ...materialStepRef.current };
    const key = `${material.material_code}_${material.plant}`;
    const filteredMaterials = allMaterials.filter(
      (mat) => mat.material_code === material.material_code && material.plant === mat.plant,
    );

    if (!currentStatus[key]) currentStatus[key] = {};

    const changeOverStatus = getChangeOverStatus(filteredMaterials);

    currentStatus[key]['trial_material_code'] = trialMaterialCodeStatus(material);
    currentStatus[key]['material_description'] = trialMaterialDescriptionStatus(material);
    currentStatus[key]['supplier_number_and_name'] = materialSupplierNumberNameStatus(material);
    currentStatus[key]['artwork_developement'] = artworkDevelopmentStatus(material);
    currentStatus[key]['changeover'] = materialChangeoverStatus(material, changeOverStatus);
    currentStatus[key]['ean_approval'] = eanApprovalStatus(material, changeOverStatus);
    currentStatus[key]['material_contract'] = materialContractStatus(material, changeOverStatus);
    currentStatus[key]['trial'] = materialTrialStatus(material);

    currentStatus[key]['reason_of_failure'] = materialFailureReasonStatus(material);

    currentStatus[key]['trials_failed'] = materialTrialsFailedStatus(material);

    currentStatus[key]['tech_spec_number_changed'] = materialTechSpecNumberStatus(material);

    currentStatus[key]['removal_of_changeover'] = materialRemovalOfChangeoverStatus(material);
    currentStatus[key]['material_status_change'] = materialMaterialStatusChangeStatus(material);

    setMaterialStepStatus(currentStatus);
  };

  const fetchProductdevelopmentMaterials = (trials) => {
    setLoading(true);

    const queryParams = trials ? { trials } : {};

    axios
      .get(`/productDevelopment/materials/fetch/${params.rfsNumber}`, {
        params: queryParams,
      })
      .then((res) => {
        setRfsMaterials(
          res.data.prodDevInfo,
          res.data.row,
          res.data.attachments,
          res.data.implementation,
        );
      })
      .finally(() => setLoading(false));
  };

  const setRfsMaterials = (devInfo, allMaterials, attachments, implementation = []) => {
    const materialsList = [];
    const prodDevInfo = {};

    devInfo.forEach((item) => {
      prodDevInfo[item.id] = item;
    });

    allMaterials.forEach((data) => {
      let index = materialsList.findIndex(
        (material) =>
          material.material_code === data.material_code && material.plant === data.plant,
      );

      let implementationIndex = implementation.findIndex(
        (material) =>
          material.material_code === data.material_code && material.plant === data.plant,
      );

      if (index < 0) {
        const model = { ...materialInterface };

        Object.keys(model).forEach((key) => {
          model[key] = data[key]
            ? data[key]
            : prodDevInfo[data.id]
            ? prodDevInfo[data.id][key]
              ? prodDevInfo[data.id][key]
              : model[key]
            : model[key];
        });

        if (implementationIndex > -1) {
          model['ABICount'] = implementation[implementationIndex]['ABICount'];
          model['StockAtSupplier'] = implementation[implementationIndex]['StockAtSupplier'];
          model['StockAtSupplierProduction'] =
            implementation[implementationIndex]['StockAtSupplierProduction'];
          model['fillingStatus'] = implementation[implementationIndex]['fillingStatus'];
        }

        if (model.vendor && model.vendor_description) {
          model.sup_name = model.vendor_description;
          model.sup_num = model.vendor;
        }

        if (data.id) {
          model.attachments = attachments.filter(
            (attachment) => parseInt(attachment.prod_dev_id) === data.id,
          );
        }

        //set Status
        setMaterialStatus(model, allMaterials);

        materialsList.push({
          ...model,
        });
      }
    });

    getMaterialContracts(
      materialsList.map((material) => {
        return {
          material_number: material.material_number,
          packMatsMaterialType: material.packMatsMaterialType,
          plant: material.plant,
          sup_num: material.sup_num,
        };
      }),
      materialsList,
    );

    setMaterials(materialsList);
  };

  const stepLogs = (step = 'Supplier Name') => {
    axios.get(`/productDevelopment/logs/${params.rfsNumber}/${step}`).then((error) => {
      console.log(error);
    });
  };

  const materialActiveStep = () => {
    axios.get(`/productDevelopment/materials/step/active/${params.rfsNumber}`).then((error) => {
      console.log(error);
    });
  };

  const liquidActiveStep = () => {
    axios.get(`/productDevelopment/liquids/step/active/${params.rfsNumber}`).then((error) => {
      console.log(error);
    });
  };

  const getVendors = () => {
    if (vendors.length === 0) {
      axios.get(`/productDevelopment/vendors`).then(
        (res) => {
          const data = res.data.vendors.map((item) => {
            return {
              label: `${item.vendor} - ${item.vendor_description}`,
              value: `${item.vendor} - ${item.vendor_description}`,
            };
          });
          setVendors(data);
        },
        (error) => [console.log(error)],
      );
    }
  };

  const setLiquidStatus = (liquid) => {
    const currentStatus = { ...liquidStepRef.current };
    const key = `${liquid.selection_number}_${liquid.liquid_code}`;

    if (!currentStatus[key]) currentStatus[key] = {};

    currentStatus[key]['trial_liquid_code'] = trialLiquidCodeStatus(liquid);
    currentStatus[key]['liquid_description'] = trialMaterialDescriptionStatus(liquid);
    currentStatus[key]['supplier_number_and_name'] = liquidSupplierNumberNameStatus(liquid);

    currentStatus[key]['changeover'] = liquidChangeoverStatus(
      liquid,
      getLiquidChangeOverStatus(liquid),
    );
    currentStatus[key]['trial'] = liquidTrialStatus(liquid, getLiquidChangeOverStatus(liquid));
    currentStatus[key]['reason_of_failure'] = liquidFailureReasonStatus(liquid);
    currentStatus[key]['trials_failed'] = liquidTrialsFailedStatus(liquid);
    currentStatus[key]['tech_spec_number_changed'] = liquidTechSpecNumberStatus(liquid);
    currentStatus[key]['quantities_changed'] = liquidQuantityChangeStatus(liquid);
    currentStatus[key]['removal_of_changeover'] = liquidRemovalOfChangeoverStatus(liquid);

    setLiquidStepStatus(currentStatus);
  };

  const setRfsLiquids = (devInfo, allLiquids) => {
    const liquidsList = [];
    const prodDevInfo = {};

    devInfo.forEach((item) => {
      prodDevInfo[`${item.selection_number}_${item.old_liquid_code}_${item.plant}`] = item;
    });

    allLiquids.forEach((data) => {
      let index = liquidsList.findIndex(
        (material) =>
          material.selection_number === data.selection_number &&
          material.ComponentPlant === data.ComponentPlant &&
          material.plant === data.plant,
      );

      if (index < 0) {
        const model = { ...liquidInterface };

        Object.keys(model).forEach((key) => {
          const identifier = `${data.selection_number}_${data.liquid_code}_${data.ComponentPlant}`;

          model[key] = data[key]
            ? data[key]
            : prodDevInfo[identifier]
            ? prodDevInfo[identifier][key]
              ? prodDevInfo[identifier][key]
              : model[key]
            : model[key];

          if (
            key === 'liquid_number' &&
            !model[key] &&
            prodDevInfo[identifier] &&
            prodDevInfo[identifier]['new_liquid_code']
          )
            model['liquid_number'] = prodDevInfo[identifier]
              ? prodDevInfo[identifier]['new_liquid_code']
              : null;

          if (key === 'plant' && !model[key]) model[key] = data['ComponentPlant'];
        });

        if (model.vendor && model.vendor_description) {
          model.sup_name = model.vendor_description;
          model.sup_number = model.vendor;
        }

        //setLiquidStatus(model, allLiquids);
        setLiquidStatus(model);

        liquidsList.push({
          ...model,
        });
      }
    });

    setLiquids(liquidsList);
  };

  const getLiquids = (trials) => {
    const queryParams = trials ? { trials } : {};
    axios
      .get(`/productDevelopment/liquids/${params.rfsNumber}`, {
        params: queryParams,
      })
      .then(
        (res) => {
          const response = res.data.data;

          // getLiquidsImplementation(
          //   response.productDevelopment.map((liquid) => {
          //     return {
          //       liquid_number: liquid.liquid_number,
          //       plant: liquid.plant,
          //       sup_number: liquid.sup_number,
          //       selection_number: liquid.selection_number,
          //       packMatsMaterialType: liquid.packMatsMaterialType,
          //     };
          //   })
          // );

          setRfsLiquids(response.prodDevInfo, response.productDevelopment);
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const getScopeLiquids = () => {
    axios.get(`/productDevelopment/scopeLiquids/${params.rfsNumber}`).then(
      (res) => {
        setScopeLiquids(res.data.data.productDevelopment);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const getWorkflowStatus = () => {
    axios.get(`/productDevelopment/workflow/status/${params.rfsNumber}`).then(
      (res) => {},
      (error) => {
        console.log(error);
      },
    );
  };

  const updateMaterialContracts = (data, materialsList) => {
    const currentValues = materialsList.length > 0 ? [...materialsList] : [...materials];

    data.forEach((material, index) => {
      currentValues[index]['material_contract'] = material.material_contract;

      setMaterialStatus(currentValues[index], currentValues);
    });

    setMaterials(currentValues);
  };

  const getMaterialContracts = (payload, materialsList = []) => {
    axios.post(`/productDevelopment/fetch/contracts/`, payload).then(
      (res) => {
        const response = res.data.contracts;

        updateMaterialContracts(response, materialsList);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const updateLiquidsWithImplementation = (implementationLiquids) => {
    const currentLiquids = [...liquids];

    implementationLiquids.map((liquid, index) => {
      currentLiquids[index]['ABICount'] = liquid['abiCount'];
      currentLiquids[index]['StockAtSupplierProduction'] = liquid['supplierProduction'];
      currentLiquids[index]['StockAtSupplier'] = liquid['supplierStock'];
      currentLiquids[index]['fillingStatus'] = liquid['filling'];

      setLiquidStatus(currentLiquids[index]);
    });

    setLiquids(currentLiquids);
  };

  const getLiquidsImplementation = (payload) => {
    axios.post(`/productDevelopment/liquid/implementation`, payload).then(
      (res) => {
        const response = res.data.implementation;
        updateLiquidsWithImplementation(response);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const getScopeUnlockInfo = useCallback(
    (rfsNumber) => {
      axios.get(`/openSku/scope-unlock/${rfsNumber}`).then(
        (res) => {
          let data = res.data.int_rfs_reversal;
          const selections = res.data.int_rfs_scope_selection;
          if (data && Object.keys(data).length > 0) {
            setScopeUnlockInfo({
              ...data,
              scope_unlock_flag: data.scope_unlock_flag ? '1' : '0',
              teams_involved: data.teams_involved ? data.teams_involved.split(',') : [],
              logs: res.data.logs,
            });
          }
          setSkus(selections);
        },
        (error) => {
          console.log(error);
        },
      );
    },
    [setScopeUnlockInfo],
  );

  const getPsScopeUnlockInfo = useCallback(
    (rfsNumber) => {
      axios.get(`/production-site/scope_unlock/fetch/${rfsNumber}`).then(
        (res) => {
          let data = res.data.data[0];
          if (data) {
            data = data ? data : {};
            const team_responsible = res.data.teams?.manual_teams_responsible;
            setPsScopeUnlockInfo({
              ...data,
              logs: res.data.logs,
              scope_unlock_flag: data.scope_unlock_flag ? '1' : '0',
              teams_involved: data.teams_involved.split(','),
              teams_responsible: team_responsible,
              scope_ids: (data.int_rfs_reversal_scopes || []).map((s) => s.scope_id.toString()),
            });
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },
    [setPsScopeUnlockInfo],
  );

  const getVeamScopeUnlockInfo = useCallback(
    (rfsNumber) => {
      axios.get(`/veam/info/scope_unlock/${rfsNumber}`).then(
        (res) => {
          let data = res.data.data;
          if (data) {
            data = data ? data : {};
            setVeamScopeUnlockInfo({
              ...data,
              scope_unlock_flag: data.scope_unlock_flag ? '1' : '0',
            });
          } else {
            setVeamScopeUnlockInfo({});
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },
    [setVeamScopeUnlockInfo],
  );

  const getDelistingScopeUnlockInfo = useCallback(
    (rfsNumber) => {
      axios.get(`/delisting/scope_unlock/${rfsNumber}`).then(
        (res) => {
          let data = res.data;
          if (data.int_rfs_reversal && Object.keys(data.int_rfs_reversal).length > 0) {
            const reversalInfo = data.int_rfs_reversal;
            const scopeIds = reversalInfo.int_rfs_reversal_scopes.map((scope) =>
              scope.scope_id.toString(),
            );
            setDelistingScopeUnlockInfo({
              ...reversalInfo,
              scope_ids: scopeIds ? scopeIds : [],
              scope_unlock_flag: reversalInfo.scope_unlock_flag ? '1' : '0',
              teams_involved: reversalInfo.teams_involved.split(','),
              teams_responsible: reversalInfo.teams_responsible.trim(),
            });
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },
    [setDelistingScopeUnlockInfo],
  );

  const getScScopeUnlockInfo = useCallback(
    (rfsNumber) => {
      axios.get(`/supplier-changes/scope-unlock/${rfsNumber}`).then(
        async (res) => {
          let data = res.data;
          if (data.int_rfs_reversal && Object.keys(data.int_rfs_reversal).length > 0) {
            const reversalInfo = data.int_rfs_reversal;
            const scopeIds = reversalInfo.int_rfs_reversal_scopes.map((scope) =>
              scope.scope_id.toString(),
            );
            setScScopeUnlockInfo({
              ...reversalInfo,
              scope_ids: scopeIds ? scopeIds : [],
              scope_unlock_flag: reversalInfo.scope_unlock_flag ? '1' : '0',
              teams_involved: reversalInfo.teams_involved.split(','),
              teams_responsible: reversalInfo.teams_responsible.trim(),
            });
          } else {
            setScScopeUnlockInfo({});
          }

          if (data.int_rfs_scope_selection.length > 0) setMaterials(data.int_rfs_scope_selection);
        },
        (error) => {
          console.log(error);
        },
      );
    },
    [setScScopeUnlockInfo],
  );

  const getSLAWeeksIdsForRFS = (rfs_number) => {
    return axios.get(`rfs/get_sla_weeks_ids/fetch/${rfs_number}`);
  };

  return (
    <ProjectOverviewContext.Provider
      value={{
        createProductDevelopmentMaterial,
        fetchProductdevelopmentMaterials,
        createMaterialStatus,
        fetchMaterialStatus,
        materials,
        setMaterials,
        steps,
        stepEigibleRoles,
        saveLiquids,
        stepLogs,
        materialActiveStep,
        liquidActiveStep,
        getVendors,
        vendors,
        getLiquids,
        getScopeLiquids,
        liquids,
        scopeLiquids,
        setLiquids,
        getWorkflowStatus,
        materialStepStatus,
        getMaterialContracts,
        reset,
        liquidStepStatus,
        activeTab,
        setActiveTab,
        getLiquidsImplementation,
        scopeUnlockInfo,
        getScopeUnlockInfo,
        getSLAWeeksIdsForRFS,
        getVeamScopeUnlockInfo,
        setVeamScopeUnlockInfo,
        veamScopeUnlockInfo,
        getPsScopeUnlockInfo,
        setPsScopeUnlockInfo,
        PsScopeUnlockInfo,
        delistingScopeUnlockInfo,
        setDelistingScopeUnlockInfo,
        getDelistingScopeUnlockInfo,
        getScScopeUnlockInfo,
        setScScopeUnlockInfo,
        scScopeUnlockInfo,
        setLoading,
        loading,
        setScopeUnlockInfo,
        skus,
        setSkus,
      }}
    >
      {children}
    </ProjectOverviewContext.Provider>
  );
};
