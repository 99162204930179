import React, { useMemo } from 'react';
import { Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { routes } from '../../routes';
import { rfsStatus } from '../../constants';
import MantineTable from '../../common/table/mantine';

export const AllRfsPageTable = ({ loading, data,csvFilename }) => {
  let columns = useMemo(
    () => [

    {
      header: 'ID',
      accessorKey: 'rfs_number',
      size: 150,
      sticky: 'left',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      header: 'RFS Name',
      accessorKey: 'project_name',
      sticky: 'left',
      size: 250,
      minWidth: 280,
      width: 280,
      maxWidth: 280,
      Cell: ({ row }) => {
        const { rfs_number, status } = row.original;
        return (
          <Link
            to={
              [
                rfsStatus.submitted,
                rfsStatus.rejected,
                rfsStatus.tech_feasible,
                rfsStatus.tech_feasible2,
                rfsStatus.product_development,
                rfsStatus.trial_material_code_completed,
                rfsStatus.supplier_name,
                rfsStatus.artwork_development_not_needed,
                rfsStatus.artwork_deelopment_completed,
                rfsStatus.changeover_applied,
                rfsStatus.ean_approved,
                rfsStatus.contracts_closed,
                rfsStatus.product_development,
                rfsStatus.imp_feasible,
                rfsStatus.implementation,
                rfsStatus.imp_feasible_rejected,
                rfsStatus.implemented,
                rfsStatus.scope_unlock,
                rfsStatus.reversal,
                rfsStatus.scope_restated,
                rfsStatus.reversed,
                rfsStatus.cancelled,
                rfsStatus.scope_unlocked,
                'Rejected',
              ].includes(status)
                ? routes.project(rfs_number).root
                : routes.submission(rfs_number).review
            }
          >
            <Text>{row.original?.project_name}</Text>
          </Link>
        );
      },
    },
    {
      header: 'Confidential',
      accessorKey: 'confidential',
      sticky: 'left',
      size: 100,
      minWidth: 125,
      width: 125,
      maxWidth: 125,
      Cell: (row) => {
        return row.cell.row.original?.confidential === 'true' ? 'True' : 'False';
      },
    },
    {
      header: 'Fast Track',
      accessorKey: 'fast_track',
      sticky: 'left',
      minWidth: 125,
      size: 250,
      width: 125,
      maxWidth: 125,
      // filter: filterStartsWith,
      Cell: (row) => {
        return row.cell.row.original?.fast_track === 'true' ? 'Applied' : 'Not Applied';
      },
    },

    {
      header: 'Submission Date',
      accessorKey: 'submission_date',
      minWidth: 175,
      width: 175,
      maxWidth: 175,
      Cell: (row) => {
        return row.cell.row.original?.submission_date
          ? row.cell.row.original?.submission_date
          : 'Not Submitted yet';
      },
    },

    {
      header: 'RFS Status',
      accessorKey: 'status',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
      Cell: (row) => {
        const status = row.cell.row.original?.status;
        return <div className='capatilize'>{status}</div>;
      },
    },
    {
      header: 'Production Business Unit',
      accessorKey: 'prodbusunit',
      minWidth: 200,
      width: 200,
      maxWidth: 200,
    },
    {
      header: 'Sales Business Unit',
      accessorKey: 'sales_business_unit',
      minWidth: 175,
      width: 175,
      maxWidth: 175,
      Cell: (row) => {
        let data = row.cell.row.original?.sales_business_unit;
        const spaced = data?.replace(/,/g, ', ');
        return spaced ? (spaced === 'null' ? 'Not Selected' : spaced) : 'Not Selected';
      },
    },
    {
      header: 'RFS Type',
      accessorKey: 'rfs_type',
    },
    {
      header: 'First Shipment Date',
      accessorKey: 'first_shipment',
      minWidth: 175,
      width: 175,
      maxWidth: 175,
    },
    {
      header: 'Gatekeeper',
      accessorKey: 'GateKeeper',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      header: 'RFS Initiator',
      accessorKey: 'initiator_name',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      header: 'Steps in Progress',
      accessorKey: 'sip',
      minWidth: 250,
      width: 250,
      maxWidth: 250,
      Cell: ({ row }) => {
        let data = row.original.sip?.split(', ');

        return (
          <div>
            {data?.map((d, k) => (
              <p key={k} style={{ margin: '0rem 1rem' }}>
                {d}
              </p>
            ))}
          </div>
        );
      },
    },
    {
      header: 'PMO Comments',
      accessorKey: 'pmo_comment',
      minWidth: 250,
      width: 250,
      maxWidth: 250,
    },
  ],
  [data],
  );

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={data}
        unique={'rfs_number'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        enableExport={true}
        enableZoom = {true}
        csvFilename={csvFilename}
        pageSize={10}
        enablePinning={true}
        initialState={{
          columnPinning: {
            left: ['rfs_number', 'project_name', 'confidential', 'fast_track'],
            right: [' ']
          },
        }}
      />
    </>
  );
};

