import React, { useContext, useState, useEffect, useMemo } from 'react';
import { RfsContext } from '../../contexts/RfsContext';
import { SkuDetailsTable } from '../../components/tables/SKUDetailsTable/SkuDetailsTable';
import { Button, Checkbox, Modal } from '@mantine/core';
import { List } from '../../components/rfs/liquid-bom/List';
import SkuRelationsModal from '../../components/modal/SkuRelationsModal';
import { AppLoader } from '../../components/common/loader';

export const Skus = ({ handleSelect }) => {
  const { skus, form, setBomModal, fetchSkuBom, fetchSkuUom, skusLoading, toggle, fullscreen } =
    useContext(RfsContext);

  const [skuRelationsModal, setSkuRelationshipModal] = useState(false);
  const [materialCode, setMaterialCode] = useState('');
  const [showOnlySelected, setOnlyShowSelected] = useState(false);
  const [EAN, setEAN] = useState([]);
  const [EANM, setEANM] = useState({ state: false, type: 'skuEan' });
  const [vis1, setVis1] = useState(true);
  useEffect(() => {
    if (
      form.rfsInformation &&
      form.rfsInformation.rfs_type &&
      form.rfsInformation.rfs_type === 'Production Site'
    )
      setVis1(false);
  }, [form.rfsInformation.rfs_type]);

  const columns = [
    {
      Header: 'SKU Name',
      accessor: 'material',
      sticky: 'left',
      minWidth: 120,
      width: 120,
      maxWidth: 120,
    },
    {
      Header: 'SKU Description',
      accessor: 'material_text',
      sticky: 'left',
      minWidth: 220,
      width: 220,
      maxWidth: 220,
    },
    // {
    //   Header: 'Reference SKU Number',
    //   accessor: 'refskunumber',
    //   minWidth: 220,
    //   width: 220,
    //   maxWidth: 220,
    // },
    // {
    //   Header: 'Reference SKU Destinations',
    //   accessor: 'refskudest',
    //   minWidth: 220,
    //   width: 220,
    //   maxWidth: 220,
    // },
    {
      Header: 'Brand',
      accessor: 'Brand',
    },

    {
      Header: 'SubBrand',
      accessor: 'SubBrand',
    },
    {
      Header: 'Returnability',
      accessor: 'Returnability',
    },
    {
      Header: 'Container',
      accessor: 'Container',
    },
    {
      Header: 'Container Size',
      accessor: 'ContainerSize',
    },
    {
      Header: 'Plants',
      accessor: 'plants',
      minWidth: 110,
      width: 110,
      maxWidth: 110,
    },
    {
      Header: 'Markets',
      accessor: 'Markets',
      minWidth: 110,
      width: 110,
      maxWidth: 110,
    },
    {
      Header: () => (
        <div style={{ textAlign: 'right' }} id='view-bom-column'>
          View BOM
        </div>
      ),
      id: 'bom',
      disableSortBy: true,
      Cell: ({ row }) =>
        row.original.activeStatus?.toLowerCase() === 'active' ? (
          <Button
            color='primary'
            style={{ background: 'black', color: 'white' }}
            onClick={() => {
              fetchSkuBom(parseInt(row.original.material));
              setBomModal({ state: true, type: 'skuBom' });
            }}
          >
            View BOM
          </Button>
        ) : (
          <div />
        ),
    },
    {
      Header: () => (
        <div style={{ textAlign: 'right' }} id='view-bom-column'>
          View UOM
        </div>
      ),
      id: 'uom',
      disableSortBy: true,
      Cell: ({ row }) =>
        row.original.activeStatus?.toLowerCase() === 'active' ? (
          <Button
            color='primary'
            style={{ background: 'black', color: 'white' }}
            onClick={() => {
              fetchSkuUom(parseInt(row.original.material));
              setBomModal({ state: true, type: 'skuUom' });
            }}
          >
            View UOM
          </Button>
        ) : (
          <div />
        ),
    },
    {
      Header: () => (
        <div style={{ textAlign: 'right' }} id='view-bom-column'>
          View EAN
        </div>
      ),
      id: 'EAN',
      disableSortBy: true,
      Cell: ({ row }) => {
        let ean = row.original.EAN;
        return ean ? (
          <Button
            color='primary'
            style={{ background: 'black', color: 'white' }}
            onClick={() => {
              setEAN(ean);
              setEANM({ ...EANM, state: true });
            }}
          >
            View EAN
          </Button>
        ) : (
          <div />
        );
      },
    },
    {
      Header: () => <div id='view-bom-column'>View SKU Relationships</div>,
      id: 'relationships',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Button
          color='primary'
          style={{ background: 'black', color: 'white' }}
          onClick={() => {
            setSkuRelationshipModal(true);
            setMaterialCode(row.original.material);
          }}
        >
          View SKU Relationships
        </Button>
      ),
    },
  ];

  const getExportData = (items) => {
    let data = [];
    data = items.map((item) => item.original);
    return data;
  };

  const handleExport = (rows) => {
    if (form.scope.skus.length > 0) {
      return getExportData(form.scope.skus);
    } else {
      return skus;
    }
  };

  const filterSelectedRows = useMemo(() => {
    let data = [...skus];
    data = data.filter((res) => {
      if (form.selectedSkus[res.material]) return res;
    });
    return data;
  }, [Object.keys(form.selectedSkus).length, showOnlySelected]);

  const handleToggle = (data) => {
    setOnlyShowSelected(data);
  };

  return (
    <div className='table-container'>
      <SkuDetailsTable
        columns={columns}
        data={showOnlySelected ? filterSelectedRows : skus}
        handleSelect={handleSelect}
        type={'skus'}
        handleExport={handleExport}
        loading={skusLoading}
        fullScreenToggle={toggle}
        fullscreen={fullscreen}
        selectedIds={form.selectedSkus}
        noSelectAll
        handleToggle={handleToggle}
        showOnlySelected={showOnlySelected}
        toggleSelected={true}
      />

      {EANM && (
        <Modal
          className='bom-modal'
          overflow='inside'
          opened={EANM.state}
          onClose={() => {
            setEANM({ ...EANM, state: false });
          }}
          title='EAN'
        >
          {Object.keys(EAN).length > 0 && <List data={EAN} type={EANM.type} />}

          {skusLoading && (
            <div className='loader-container'>
              <AppLoader size={'md'} />
            </div>
          )}
        </Modal>
      )}
      {skuRelationsModal && (
        <SkuRelationsModal
          opened={skuRelationsModal}
          setOpened={setSkuRelationshipModal}
          liquidCode={materialCode}
          selectionType={'selectedSkus'}
        />
      )}
    </div>
  );
};
