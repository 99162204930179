import React, { createContext } from 'react';
import { axios } from '../utils/axios';

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const fetchNewUsers = () => {
    return axios.get(`/register/fetch`, { params: { type: 'new' } });
  };

  const fetchExistingUsers = (type = 'existing') => {
    return axios.get(`/register/fetch`, { params: { type: 'existing' } });
  };

  const fetchUsersFromBackend = async () => {
    const response = await axios.get('user/v2');

    const transformedData = response.data.data
      .filter((user) => user.is_active)
      .map((record) => {
        const activeMappings = record.int_user_role_mappings.filter(
          (mapping) => mapping.is_active === true,
        );

        const countries = [
          ...new Set(activeMappings.map((mapping) => mapping.country).filter((country) => country)),
        ].join(', ');

        const roles = Array.from(
          new Set(activeMappings.map((mapping) => mapping?.int_role?.name)),
        ).join(', ');

        const approvedBy = Array.from(
          new Set(
            activeMappings.map((mapping) => mapping.actioned_user?.name).filter((name) => name),
          ),
        ).join(', ');

        const formattedCreatedOn = record.createdAt
          ? new Date(record.createdAt).toISOString().split('T')[0]
          : '';

        return {
          id: record.id,
          name: record.name || '',
          email: record.email || '',
          created_on: formattedCreatedOn,
          countries: countries || 'N/A',
          roles: roles,
          createdBy: record.name || '',
          approvedBy: approvedBy,
          int_user_role_mappings: activeMappings,
        };
      });

    return transformedData;
  };

  const updateTable = (payload) => {
    return axios.put(`/register/update`, payload);
  };

  const deleteEntry = (payload) => {
    return axios.delete(`/register/delete`, { data: payload });
  };

  return (
    <AdminContext.Provider
      value={{
        fetchNewUsers,
        fetchExistingUsers,
        updateTable,
        deleteEntry,
        fetchUsersFromBackend,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
