import React, { useContext, useEffect, useState } from 'react';
import { Modal, Accordion, Container, Text, Table } from '@mantine/core';
import { axios } from '../../utils/axios';
import { AppLoader } from '../../components/common/loader';

const DecomplexityLogModal = ({ type = 'material', logModal, setLogModal }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`reports/decomplexity/logs/${logModal.id}`)
      .then(
        (res) => {
          setLogs(res.data.data);
        },
        (err) => {
          throw err;
        },
      )
      .finally(() => setLoading(false));
  }, [logModal.id]);

  return loading ? (
    <div>
      <Modal
        opened={logModal.open}
        onClose={() => setLogModal({ open: false, id: null })}
        title='Logs'
        className='confirm-modal'
      >
        <AppLoader size='md' center />
      </Modal>
    </div>
  ) : (
    <div>
      <Modal
        opened={logModal.open}
        onClose={() => setLogModal({ open: false, id: null })}
        title='Decomplexity Logs'
        className='confirm-modal'
      >
        <Container>
          <div>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {capitalizeFirstLetter(type) + ` ${logModal.id}`}{' '}
              </span>
            </Text>
            <Table>
              <thead>
                <tr>
                  <th>Last Modified</th>
                  <th>Status</th>
                  <th>Change Type</th>
                  <th>Username</th>
                  <th>Rfs number</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log, index) => {
                  return (
                    <tr key={index}>
                      <td>{log.LastModified}</td>
                      <td>{log.Status}</td>
                      <td>{log.Changetype}</td>
                      <td>{log.Username}</td>
                      <td>{log.rfs_number}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <br />
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default DecomplexityLogModal;
