import { Pagination } from '@mantine/core';
import { Form } from 'react-bootstrap';

export const TablePagination = ({
  total,
  gotoPage,
  pageIndex,
  setPageSize,
  pageSize,
  hideRowOptions = false,
  displaySize = false,
}) => {
  total = Math.ceil(total / pageSize);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'absolute',
      }}
    >
      <Pagination
        style={{ paddingTop: '0.5rem' 
        }}
        color='dark'
        page={pageIndex + 1}
        total={total > 0 ? total : 1}
        boundaries={1}
        onChange={(page) => {
          gotoPage(page - 1);
        }}
      />

      {displaySize && !hideRowOptions && (
        <Form.Select
          style={{ width: '10%' }}
          value={pageSize}
          onChange={(event) => setPageSize(Number(event.target.value))}
        >
          {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Form.Select>
      )}
    </div>
  );
};
