export const staticData = {
  urgency: ['Critical', 'Non Critical'],
  typeOfGoods: ['Finished Goods', 'Empties', 'Road Tanker'],
  typeOfSku: ['Filling', 'Repack'],
  typeOfFlow: ['Domestic', 'ICO'],
  typeOfRequest: [
    { label: 'Costing', value: 'Costing' },
    { label: 'Transfer Pricing', value: 'Transfer Pricing' },
    { label: 'Both', value: 'Both' },
  ],
  orderType: ['ZNB - Normal Flow', 'ZRB - Return Flow'],
};

export const titleTypeMap = {
  'Select Production Plant': 'producing_plant_data',
  'Select Selling Plant': 'selling_plant_data',
  'Select Buying Plant': 'buying_plant_data',
};
