import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import MantineTable from '../../../../common/table/mantine';
import { implementationColumns } from '../../../../common/table/template/implementation';
import { CommonColumnsContext } from '../../../../contexts/CommonColumns';
import { DownloadExcel } from '../../../../common/excel';

export const MaterialsContract = ({ permissions, userRole }) => {
  const { rfsNumber } = useParams();
  const { setLogsModal, setActionModal } = useContext(CommonColumnsContext);
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeMaterials, setActiveMaterials] = useState({});

  const fetch = useCallback(
    (loading = true) => {
      setLoading(loading);
      axios
        .get(`/supplier-changes/material_contract/${rfsNumber}`)
        .then(
          (res) => {
            setMaterials(res.data.data);
          },
          (err) => {
            console.log(err);
          },
        )
        .finally(() => setLoading(false));
    },
    [rfsNumber],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleRowSelection = (data) => {
    let currentValues = { ...activeMaterials };
    currentValues = { ...data };

    setActiveMaterials(currentValues);
  };

  const handleDefaultSet = (event, type, selectedRows) => {};

  let columns = useMemo(
    () => [
      {
        accessorKey: 'int_rfs_sc_material.material_code',
        header: 'Material Code',
      },
      {
        accessorKey: 'int_rfs_sc_material.material_description',
        header: 'Material Description',
      },
      {
        accessorKey: 'int_rfs_sc_material.plant',
        header: 'Plants',
      },
      {
        accessorKey: 'int_rfs_sc_material.current_suppliers',
        header: 'Current Suppliers',
      },
      {
        accessorKey: 'int_rfs_sc_supplier.supplier_name',
        header: 'To Be Supplier',
        Cell: ({ row }) => {
          let suppliers = row.original.int_rfs_sc_supplier;
          suppliers = `${suppliers.supplier_number} ${suppliers.supplier_name}`;

          return <div>{suppliers}</div>;
        },
      },
    ],
    [],
  );

  columns = columns.concat(
    implementationColumns(
      setLogsModal,
      setActionModal,
      {
        title: 'Materials Contract',
        fetchInfo: fetch,
      },
      !permissions.includes(userRole),
    ),
  );

  return (
    <div>
      <DownloadExcel
        data={materials.map(
          ({
            int_rfs_sc_material,
            int_rfs_sc_supplier,
            status,
            sla_date,
            finished_date,
            manually_closed,
          }) => {
            return {
              'Material Code': int_rfs_sc_material.material_code,
              'Material Description': int_rfs_sc_material.material_description,
              Plants: int_rfs_sc_material.plant,
              'Current Suppliers': int_rfs_sc_material?.current_suppliers,
              'To Be Supplier': `${int_rfs_sc_supplier?.supplier_number} ${int_rfs_sc_supplier?.supplier_name}`,
              Status: status,
              Action:
                status !== 'Closed' ? '' : manually_closed ? 'Manual Closure' : 'Automatic Closure',
              'Step Deadline': moment(sla_date).format('DD-MM-YYYY'),
              'Step Finished': finished_date
                ? moment(finished_date).format('DD-MM-YYYY')
                : 'Not Finished',
            };
          },
        )}
        filename={'Materials_Contract'}
      />
      <MantineTable
        columns={columns}
        initialData={materials}
        unique={'id'}
        handleRowSelection={handleRowSelection}
        activeRows={activeMaterials}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
      />
    </div>
  );
};
