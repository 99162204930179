import React, { useEffect, useState, useContext } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  ActionIcon,
  Button,
  Flex,
  Tooltip,
  Switch,
  Select,
  MantineProvider,
  useMantineTheme,
} from '@mantine/core';
import { IconEdit, IconRefresh } from '@tabler/icons-react';
import { axios } from '../../utils/axios';
import { useQuery } from '@tanstack/react-query';

import { AuthContext } from '../../contexts/AuthContext';
import ContentPaper from '../../components/layouts/rfs/Content';

//CSV Download
import csvDownload from 'json-to-csv-export';
import moment from 'moment';
import { CalendarIcon } from '@radix-ui/react-icons';
import { DownloadExcel } from '../../common/excel';

const FtrReportComp = ({
  columns,
  setValidationErrors,
  validationErrors,
  statusData,
  unique,
  enableEditing,
  readOnly,
  type,
}) => {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [selected, setSelected] = useState([{}]);
  const [rowSelection, setRowSelection] = useState({});
  const [filteredRows, setFilteredRows] = useState([]);
  const globalTheme = useMantineTheme;

  //Search Icon
  const [searchOn, setSearchOn] = useState(false);

  //All Select
  const [allSelected, setAllSelected] = useState(false);

  const [data, setData] = useState([]);
  const [successData, setSuccessData] = useState([]);

  //Custom search
  const [enabled, setEnabled] = useState(false);

  const [selectedYear, setYear] = useState(moment().year().toString());
  const [selectedMonth, setMonth] = useState(moment().month() + 1);
  const [yeardropdown, setYearfilter] = useState([]);

  const monthDropDown = [
    { month: 'January', value: 1 },
    { month: 'February', value: 2 },
    { month: 'March', value: 3 },
    { month: 'April', value: 4 },
    { month: 'May', value: 5 },
    { month: 'June', value: 6 },
    { month: 'July', value: 7 },
    { month: 'August', value: 8 },
    { month: 'September', value: 9 },
    { month: 'October', value: 10 },
    { month: 'November', value: 11 },
    { month: 'December', value: 12 },
  ];

  //READ hook (get data in api)
  function useGet() {
    let payload = { year: parseInt(selectedYear), month: parseInt(selectedMonth) };

    const queryKey = {
      ftrRfs: ['fetchFtrRfs', payload],
      ftrInitiator: ['fetchFtrInitiator', payload],
      ftrTeamLevel: ['fetchFtrTeam', payload],
    };

    const queryFn = {
      ftrRfs: () => fetchFtrRfs(payload),
      ftrInitiator: () => fetchFtrInitiator(payload),
      ftrTeamLevel: () => fetchFtrTeam(payload),
    };

    return useQuery({
      queryKey: queryKey[type],
      queryFn: queryFn[type],
      refetchOnWindowFocus: false,
      enabled: enabled,
    });
  }

  //call READ hook
  const {
    data: fetchedData = [],
    isError,
    isFetching,
    isLoading,
    error,
    status,
    refetch,
  } = useGet();

  const fetchFtrRfs = (data) => {
    return axios.get(`/reports/ftr/rfs`, {
      params: {
        year: data.year,
        month: data.month,
      },
    });
  };

  const fetchFtrInitiator = (data) => {
    return axios.get(`/reports/ftr/initiator`, {
      params: {
        year: data.year,
        month: data.month,
      },
    });
  };

  const fetchFtrTeam = (data) => {
    return axios.get(`/reports/ftr/team`, {
      params: {
        year: data.year,
        month: data.month,
      },
    });
  };

  function fetchSubmissionDate() {
    axios.get('/reports/submissionDate').then(
      (res) => {
        let response = res.data.data;
        setYearfilter(response);
      },
      (err) => {},
    );
  }

  useEffect(() => {
    if (status == 'success' && !isFetching) {
      let data = fetchedData.data.data;
      setData(data);
      setSuccessData(data);
      setFilteredRows(data);
      setEnabled(false);
    }
  }, [status, isFetching]);

  useEffect(() => {
    fetchSubmissionDate();
    setEnabled(true);
  }, []);

  const clearFilters = () => {
    setYear(moment().year().toString());
    setMonth(moment().month() + 1);
    fetchSubmissionDate();
    setEnabled(true);
  };

  const applyFilters = () => {
    refetch();
    setEnabled(false);
  };

  const readyExport = () => {
    const reports = {
      ftrRfs: ftrRfsReport,
      ftrInitiator: ftrInitiatorReport,
      ftrTeamLevel: ftrTeamReport,
    };

    let reportData = [...data];
    reportData = reportData.map((info) => reports[type](info));

    return reportData;
  };

  const ftrRfsReport = ({
    gatekeeper_name,
    name,
    rfsId,
    changedFields,
    totalFields,
    ftrPercent,
  }) => ({
    'RFS ID': rfsId,
    Gatekeeper: gatekeeper_name,
    'Initiator Name': name,
    'Number of fields PMO modified': changedFields,
    'Total Fields': totalFields,
    'Percentage of FTR': ftrPercent,
  });

  const ftrInitiatorReport = ({ name, rfsId, changedFields, totalFields, ftrPercent }) => ({
    'Initiator Name': name,
    'RFS ID': rfsId,
    'Number of fields PMO modified': changedFields,
    'Total Fields': totalFields,
    'Percentage of FTR': ftrPercent,
  });

  const ftrTeamReport = ({
    Team_Name,
    Total_Rfs,
    Number_Of_Rfs_Okay,
    Number_Of_Rfs_Unlocked,
    Percentage_Of_Ftr,
  }) => ({
    'Team Name': Team_Name,
    'Total RFS': Total_Rfs,
    'Number of RFS Okay': Number_Of_Rfs_Okay,
    'Number of RFS Unlocked': Number_Of_Rfs_Unlocked,
    'Percentage of FTR': Percentage_Of_Ftr,
  });

  const getDataToExport = (data) =>
    type === 'ftrInitiator'
      ? data.map((obj) => Object.fromEntries(Object.entries(obj).slice(1)))
      : data.map(dataToExport);

  const dataToExport = (data) => {
    return Object.values(data).map((i, index) => i);
  };

  const resetAll = () => {
    setSearchOn(false);
    setAllSelected(false);
    setRowSelection({});
    setSwitchChecked(false);
    setData(successData);
    setFilteredRows(successData);
  };

  const table = useMantineReactTable({
    columns,
    data: data,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: enableEditing,
    getRowId: (row) => row[unique],
    enableRowSelection: true,
    selectDisplayMode: 'checkbox',
    enableGlobalFilter: false,
    enableSelectAll: true,
    selectAllMode: 'all',
    initialState: {
      showColumnFilters: true,
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Tooltip label='Show All Selected'>
              <Switch
                disabled={selected.length === 0 && !switchChecked}
                checked={switchChecked}
                onChange={(event) => setSwitchChecked(event.currentTarget.checked)}
              />
            </Tooltip>

            <Tooltip label='Reset All'>
              <IconRefresh onClick={resetAll} />
            </Tooltip>
          </div>
        </>
      );
    },
    state: {
      isLoading: isFetching,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  //Selection Toggle
  const updateSelected = () => {
    var filtered = filteredRows.filter((item, index) => {
      return Object.keys(rowSelection).indexOf(item[unique]) !== -1;
    });

    setSelected(filtered);
  };

  useEffect(() => {
    if (switchChecked || allSelected) setData(selected);
    else if ((!switchChecked || !allSelected) && !searchOn) setData(successData);
    else if ((!switchChecked || !allSelected) && searchOn) setData(filteredRows);
    else setData(successData);
  }, [switchChecked, selected, data, searchOn]);

  useEffect(() => {
    updateSelected();
  }, [rowSelection]);

  if (isError) {
    return (
      <ContentPaper page={'Decomplexity'}>
        <span>{error.message}</span>
      </ContentPaper>
    );
  }

  return (
    <>
      <div
        className='downLoadBtn'
        style={{
          marginBottom: '1rem',
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            marginTop: '1.6rem',
          }}
        >
          <DownloadExcel
            fetch={() => readyExport()}
            filename={`Ftr_Report_${moment().format('YYYY-MM-DD HH:mm:ss')}`}
          />
        </div>

        <Select
          rightSection={<CalendarIcon />}
          size='md'
          color='primary'
          w={160}
          label='Select Year'
          placeholder='Select Year'
          onChange={(value) => {
            setEnabled(false);
            setYear(value);
          }}
          data={yeardropdown.map((column, index, arr) => {
            return { value: column.year.toString(), label: column.year.toString() };
          })}
          value={selectedYear}
          sx={{
            '.mantine-Select-dropdown': {
              backgroundColor: '#ffffff !important',
              zIndex: '1',
            },
          }}
          searchable={false}
          style={{ height: '3.6rem', marginLeft: '1rem' }}
        />

        <Select
          rightSection={<CalendarIcon />}
          size='md'
          color='primary'
          w={160}
          label='Select Month'
          placeholder='Select Month'
          onChange={(value) => {
            setEnabled(false);
            setMonth(value);
          }}
          data={monthDropDown.map((column) => {
            return { value: column.value, label: column.month };
          })}
          value={selectedMonth}
          sx={{
            '.mantine-Select-dropdown': {
              backgroundColor: '#ffffff !important',
              zIndex: '1',
            },
          }}
          searchable={false}
          style={{ height: '3.6rem', marginLeft: '1rem', marginRight: '1rem' }}
        />

        <Button
          onClick={() => {
            applyFilters();
          }}
          color='primary'
          style={{
            marginTop: '1.8rem',
            height: '2.5rem',
            background: 'linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%)',
            color: 'black',
          }}
        >
          Apply
        </Button>
        <Button
          onClick={() => {
            clearFilters();
          }}
          color='default'
          size='md'
          style={{
            marginTop: '1.8rem',
            height: '2.5rem',
            marginLeft: '1rem',
            background: 'rgb(251 250 248)',
            color: 'black',
          }}
        >
          Clear
        </Button>
      </div>
      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 7 }}>
        <MantineReactTable table={table} />
      </MantineProvider>
    </>
  );
};

export default FtrReportComp;
