export const tableToArray = (tbl, opt_cellValueGetter, index) => {
  if (tbl) {
    opt_cellValueGetter =
      opt_cellValueGetter ||
      function (td) {
        return td.textContent || td.innerText;
      };
    var twoD = [];
    for (var rowCount = tbl.rows.length, rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      twoD.push([]);
    }
    for (var rowIndex = 0, tr; rowIndex < rowCount; rowIndex++) {
      var tr = tbl.rows[rowIndex];
      for (
        var colIndex = 0, colCount = tr.cells.length, offset = 0;
        colIndex < colCount;
        colIndex++
      ) {
        var td = tr.cells[colIndex],
          text = opt_cellValueGetter(td, colIndex, rowIndex, tbl);
        while (twoD[rowIndex].hasOwnProperty(colIndex + offset)) {
          offset++;
        }
        for (var i = 0, colSpan = parseInt(td.colSpan, 10) || 1; i < colSpan; i++) {
          for (var j = 0, rowSpan = parseInt(td.rowSpan, 10) || 1; j < rowSpan; j++) {
            twoD[rowIndex + j][colIndex + offset + i] = text;
          }
        }
      }
    }

    return twoD;
  } else return [];
};

export const capitalizeFirstLetter = (sentence) => {
  const lowerCaseSentence = sentence.toLowerCase();
  return lowerCaseSentence.charAt(0).toUpperCase() + lowerCaseSentence.slice(1);
};

export const capitalize = (sentence) => {
  return sentence.toUpperCase();
};

export const calculateColor = (status, sla_date) => {
  const currentDate = new Date(); // Get the current date

  if (currentDate > sla_date) {
    return 'red'; // Color is red if the current date is past the SLA date
  } else if (status === 'Not Started') {
    return 'gray'; // Default color for 'not started'
  } else if (status === 'In Progress') {
    return 'yellow'; // Default color for 'In progress'
  } else if (status === 'Closed') {
    return 'green'; // Default color for 'closed'
  } else {
    return ''; // Return an empty string for unknown status
  }
};

export const addNewLines = (text) => {
  const maxLength = 35; // Maximum characters before a new line
  const maxWords = 5; // Maximum words before a new line
  const words = text.split(/\s+/); // Split the text into words

  let result = '';
  let countCharacters = 0;
  let countWords = 0;

  for (const word of words) {
    // Check if adding the next word exceeds the character or word limit
    if (countCharacters + word.length > maxLength || countWords >= maxWords) {
      result += '<br />'; // Add a new line
      countCharacters = 0;
      countWords = 0;
    }

    // Add the word to the result string
    result += word + ' ';
    countCharacters += word.length + 1; // +1 for the space
    countWords++;
  }

  return result.trim(); // Remove trailing space
};

export const limitWord = (word, maxLength) => {
  if (word.length > maxLength) {
    return word.substring(0, maxLength) + '...';
  }
  return word;
};
