import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { Accordion, Grid, Table, Badge, Text, Checkbox, Button } from '@mantine/core';
import { AppLoader } from '../../../../../components/common/loader';
import { Styles } from '../../../../../components/tables/skutable/production-site/styles';
import { ActionModal } from '../../actionModal';
import { ProductionLog } from '../../productionSiteLogModal';
import { VeamAffectedSKUs } from '../../veamaffectedSKUsModal';

const ChangeOverRemovalTableVEAM = ({ statusDataList, type, save, accState, readOnly }) => {
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const [htmlData, setHtmlData] = useState('');
  const [status, setStatus] = useState('');
  const [checked, setChecked] = useState(false);

  const [state, handlers] = useState({ initialItem: 0 });
  const [active, setActive] = useState(0);

  const [SKUModal, setSKUModal] = useState({ open: false, id: '' });
  const [MaterialID, setMaterialID] = useState(0);

  useEffect(() => {
    let handlerObj = Object.values(accState).length > 0 ? accState : { initialItem: 0 };
    handlers(handlerObj);
  }, [accState]);

  const Action = (item) => {
    return item.status === 'In Progress' || item.status === 'Not Started' ? (
      <Checkbox
        disabled={(item.status === 'In Progress' ? false : true) || readOnly}
        onChange={(event) => {
          setChecked(event.target.checked);
          setStatus(item.id);
          setActionModal({ open: true, id: item.id });
        }}
        label='Confirm and Close'
      />
    ) : item.manually_closed && item.status === 'Closed' ? (
      <Text>Manually Closed</Text>
    ) : (
      <Text>Automatic Closure</Text>
    );
  };

  const Logs = (id) => {
    return (
      <Button
        onClick={(event) => getLogs(id, event)}
        style={{ background: 'black', color: 'white' }}
      >
        View Logs
      </Button>
    );
  };

  const getLogs = (imp_id, event) => {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  };

  const ImpactedSKUs = (id) => {
    return (
      <Button onClick={() => getImpactedSKUs(id)} style={{ background: 'black', color: 'white' }}>
        View SKUs
      </Button>
    );
  };

  const getImpactedSKUs = (id) => {
    setSKUModal({ open: true, id: id });
    setMaterialID(id);
  };

  const filteredData = (data, type) => {
    return data.map((item) => {
      let Code = 'N/A',
        new_num = 'N/A',
        new_name = 'N/A',
        type_of_change = 'N/A',
        changeovertype = 'N/A',
        changeoverdate = 'N/A',
        comment = 'N/A',
        Desc;

      let scope_selection_code = 'LIQ - ' + item.selection_number + ' - ' + item.selection_name;

      if (type === 'Materials') {
        if (
          item.material_type === 'Existing Material' ||
          item.material_type === 'Remove Material'
        ) {
          Code = item.material_code_or ? item.material_code_or : 'N/A';
          new_num = item.int_rfs_material_number ? item.int_rfs_material_number : 'N/A';

          Desc = item.material_description_or;
          new_name = item.pack_mat_material_text ? item.pack_mat_material_text : 'N/A';
        } else if (item.material_type === 'New Material') {
          Code = item.material_code_or ? item.material_code_or : 'N/A';
          new_num = item.new_material_code ? item.new_material_code : 'N/A';

          Desc = item.material_description_or;
          new_name = item.new_material_description_intrfsml
            ? item.new_material_description_intrfsml
            : 'N/A';
        }

        type_of_change = item.material_type ? item.material_type : 'N/A';
        changeovertype = item.material_changeover_type ? item.material_changeover_type : 'N/a';
        changeoverdate = item.material_changeover_date ? item.material_changeover_date : 'N/A';
        comment = item.material_comment ? item.material_comment : 'N/A';

        return {
          Code: Code,
          Description: Desc,
          Plant: item.plant,
          'Type Of Changeover': type_of_change,
          'New Number': new_num,
          'New Name': new_name,
          'Impacted SKUs': ImpactedSKUs(item.material_id),
          Status: (
            <Badge
              color={
                item.status === 'In Progress'
                  ? 'yellow'
                  : item.status === 'Closed'
                  ? 'green'
                  : 'gray'
              }
              size='lg'
            >
              {' '}
              {item.status}{' '}
            </Badge>
          ),
          Action: Action(item),
          Log: Logs(item.id),
          'SLA Date': moment(item.sla_date).format('YYYY-MM-DD'),
          'Step Finished': item.finished_date
            ? moment(item.finished_date).format('YYYY-MM-DD')
            : item.status,
          scope_selection_code: scope_selection_code,
          scope_id: item.scope_id,
        };
      } else if (type === 'Liquids') {
        Code = item.liquid_code ? item.liquid_code : 'N/A';
        new_num = item.liquid_number ? item.liquid_number : 'N/A';

        Desc = item.liquid_description ? item.liquid_description : 'N/A';
        new_name = item.material_text ? item.material_text : 'N/A';

        changeovertype = item.liquid_changeover_type ? item.liquid_changeover_type : 'N/A';
        type_of_change = item.bom_changes ? item.bom_changes : 'N/a';
        changeoverdate = item.liquid_changeover_date ? item.liquid_changeover_date : 'N/A';
        comment = item.liquid_comment ? item.liquid_comment : 'N/A';

        return {
          Code: Code,
          Description: Desc,
          Plant: item.plant,
          'Liquid BOM Material Change': type_of_change,
          'New Number': new_num,
          'New Name': new_name,
          'Current Quantity': item.quantity ? item.quantity : 'N/A',
          'New Quantity': item.new_quantity ? item.new_quantity : 'N/A',
          Status: (
            <Badge
              color={
                item.status === 'In Progress'
                  ? 'yellow'
                  : item.status === 'Closed'
                  ? 'green'
                  : 'gray'
              }
              size='lg'
            >
              {' '}
              {item.status}{' '}
            </Badge>
          ),
          Action: Action(item),
          Logs: Logs(item.id),
          'SLA Date': moment(item.sla_date).format('YYYY-MM-DD'),
          'Step Finished': item.finished_date
            ? moment(item.finished_date).format('YYYY-MM-DD')
            : item.status,
          scope_selection_code: scope_selection_code,
          scope_id: item.scope_id,
        };
      }
    });
  };

  const filteredDataRes = filteredData(statusDataList, type);

  const getScrollPositionDiv = (event, crtrgt) => {
    createScrollStopListener(event.target, () => {
      let scrollValues = {
        scrollVal: crtrgt,
        divName: event.target.className,
      };
      localStorage.setItem('scrollValues', JSON.stringify(scrollValues));
    });
  };

  const createScrollStopListener = (element, callback) => {
    var handle = null;
    var onScroll = function () {
      if (handle) {
        clearTimeout(handle);
      }
      handle = setTimeout(callback, 1000);
    };
    element.addEventListener('scroll', onScroll);
    return () => element.removeEventListener('scroll', onScroll);
  };

  const scrollGoto = () => {
    let scrollValues = localStorage.getItem('scrollValues')
      ? JSON.parse(localStorage.getItem('scrollValues'))
      : '';
    const { divName, scrollVal } = scrollValues;
    if (document.querySelector('.' + divName))
      document.querySelector('.' + divName).scrollTo(scrollVal, 0);
  };

  useEffect(() => {
    scrollGoto();
  }, [actionModal, statusDataList]);

  const HtmlRenderedData = ({ type }) => {
    const MaterialHeads = (
      <thead>
        <tr id='header-row'>
          <th>Material Code</th>
          <th>Material Description</th>
          <th>Plant</th>
          <th>Type of change</th>
          <th>New Material Number</th>
          <th>New Material Name</th>
          <th>Impacted SKUs</th>
          <th>Status</th>
          <th>Action</th>
          <th>Logs</th>
          <th>Step Deadline</th>
          <th>Step Finished</th>
        </tr>
      </thead>
    );

    const Materials = () => {
      const rowsData = filteredDataRes.map((item, index) => (
        <tr key={index}>
          {Object.values(item).map((it, i) => {
            return (
              i < 12 && (
                <td key={i} style={{ padding: '0.8rem !important' }}>
                  {it}
                </td>
              )
            );
          })}
        </tr>
      ));

      return (
        <Table>
          {MaterialHeads}
          <tbody>{rowsData}</tbody>
        </Table>
      );
    };

    const LiquidHeads = (
      <thead>
        <tr id='header-row'>
          <th>Liquid Code</th>
          <th>Liquid Description</th>
          <th>Plant</th>
          <th>Liquid BOM Material Change</th>
          <th>New Liquid Number</th>
          <th>New Liquid Name</th>
          <th>Current Quantity</th>
          <th>New Quantity</th>
          <th>Status</th>
          <th>Action</th>
          <th>Logs</th>
          <th>Step Deadline</th>
          <th>Step Finished</th>
        </tr>
      </thead>
    );

    const Liquids = () => {
      let arr = [];
      filteredDataRes.map((item) =>
        arr.includes(item.scope_id) === false ? arr.push(item.scope_id) : '',
      );

      const a = arr.map((arr_item) => {
        return filteredDataRes.filter((item) => {
          return item.scope_id == arr_item;
        });
      });

      return (
        <Accordion value={state} onChange={handlers}>
          {a.map((item, index) => {
            return (
              <Accordion.Item className='scope-label' key={`key_${index}`} value={`key_${index}`}>
                <Accordion.Control>{`${item[0].scope_selection_code}`}</Accordion.Control>
                <Accordion.Panel>
                  <Grid>
                    <Grid.Col span={12}>
                      <Styles>
                        <div
                          className={'divsc' + index}
                          style={{ overflow: 'auto' }}
                          onScroll={(event) =>
                            getScrollPositionDiv(event, event.currentTarget.scrollLeft)
                          }
                        >
                          <Table>
                            {LiquidHeads}
                            <tbody>
                              {item.map((item, i) => (
                                <tr key={i}>
                                  {Object.values(item).map((it, i) => {
                                    return (
                                      i < 13 && (
                                        <td
                                          key={i}
                                          style={{
                                            padding: '0.8rem !important',
                                          }}
                                        >
                                          {it}
                                        </td>
                                      )
                                    );
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Styles>
                    </Grid.Col>
                  </Grid>
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      );
    };

    return type === 'Liquids' ? <Liquids /> : <Materials />;
  };

  return (
    <Styles>
      <div
        className={'divscM'}
        style={{ overflow: 'auto' }}
        onScroll={(event) => getScrollPositionDiv(event, event.currentTarget.scrollLeft)}
      >
        <HtmlRenderedData type={type} />
      </div>

      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={status}
          state={state}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Changeover Removal'
        />
      )}

      {logModal && (
        <VeamAffectedSKUs SKUModal={SKUModal} setSKUModal={setSKUModal} MaterialID={MaterialID} />
      )}
    </Styles>
  );
};

export default ChangeOverRemovalTableVEAM;
