import React, { useState, useContext, useMemo } from 'react';
import ContentPaper from '../../components/layouts/rfs/Content';
import RfsLayout from '../../components/layouts/RfsLayout';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import { AuthContext } from '../../contexts/AuthContext';
import ReportCustomComp from '../../components/report/reportCustomComp';
import moment from 'moment';
import { reportPermissions } from '../../constants';

function RawDataReports() {
  const navigate = useNavigate();

  const [validationErrors, setValidationErrors] = useState({});
  const { userRole } = useContext(AuthContext);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'data_id',
        header: 'data_id',
      },
      {
        accessorKey: 'submission_date',
        header: `Submission Date`,
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'rfs_id',
        header: 'RFS ID',
      },
      {
        accessorKey: 'rfs_name',
        header: 'RFS name',
      },
      {
        accessorKey: 'approval_date',
        header: 'Approval Date',
      },
      {
        accessorKey: 'Implementation_date',
        header: 'Implementation date',
      },
      {
        accessorKey: 'rfs_type',
        header: 'RFS Type',
      },
      {
        accessorKey: 'gatekeeper',
        header: 'Gatekeeper',
      },
      {
        accessorKey: 'initiator_name',
        header: 'Initiator',
      },
      {
        accessorKey: 'rfs_status',
        header: 'RFS Status',
      },
      {
        accessorKey: 'Confidential',
        header: 'Confidential',
      },
      {
        accessorKey: 'fast_track',
        header: 'Fast Track',
      },
      {
        accessorKey: 'sku_number',
        header: 'SKU number',
        Cell: ({ cell }) => (!cell.getValue() ? 'Not created' : cell.getValue()),
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Description',
        Cell: ({ cell }) => (!cell.getValue() ? 'Not created' : cell.getValue()),
      },
      {
        accessorKey: 'brand',
        header: 'Brand',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'SubBrand',
        header: 'Subbrand',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'Container',
        header: 'Container',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'Markets',
        header: 'Markets',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'MACO',
        header: 'Maco',
        Cell: ({ cell }) => (!cell.getValue() ? 'N/A' : cell.getValue()),
      },
      {
        accessorKey: 'first_forcast',
        header: 'First forecast',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'first_forcast_week',
        header: 'First forecast week',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'additional_markets',
        header: 'Additional Markets',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'plant',
        header: 'Plants',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'additional_plants',
        header: 'Additional plants',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'first_filling_plan_vol',
        header: 'First filling plan volume',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'first_filling_plan_week',
        header: 'First filing plan week',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'FSD',
        header: 'FSD',
      },
      {
        accessorKey: 'material_number',
        header: 'Material number',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'material_description',
        header: 'Material description',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'SupplierName',
        header: 'Supplier name',
        Cell: ({ renderedCellValue, row, cell }) =>
          renderedCellValue ? renderedCellValue.split(';').join('\r\n') : '--',
      },
      {
        accessorKey: 'GFP_Confirmation',
        header: 'GFP confirmation',
        Cell: ({ cell }) => (!cell.getValue() ? '--' : cell.getValue()),
      },
      {
        accessorKey: 'obsoletes',
        header: 'Obsoletes',
        Cell: ({ cell }) => (!cell.getValue() ? 'No Data' : cell.getValue()),
      },
      {
        accessorKey: 'reference_sku_number',
        header: 'Reference SKU number',
        Cell: ({ cell }) => (!cell.getValue() ? 'N/A' : cell.getValue()),
      },
      {
        accessorKey: 'reference_sku_description',
        header: 'Reference SKU description',
        Cell: ({ cell }) => (!cell.getValue() ? 'N/A' : cell.getValue()),
      },
      {
        accessorKey: 'Production_Buisness_Unit',
        header: 'Production Business Unit',
      },
      {
        accessorKey: 'sales_business_unit',
        header: 'Sales Business Unit',
      },
      {
        accessorKey: 'cost_center',
        header: 'Cost Center',
      },
    ],
    [validationErrors],
  );

  return (
    <RfsLayout>
      <ContentPaper page='raw-data-report'>
        <Row>
          <Col
            md={{ span: 6 }}
            style={{
              marginTop: '1rem',
              display: 'inline-flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              size='sm'
              color='dark'
              width='2rem'
              style={{ paddingLeft: '20', paddingRight: '20' }}
              onClick={() => {
                navigate('/reports');
              }}
            >
              Back{' '}
            </Button>
            <h1
              className='sectionHeadings'
              style={{ paddingLeft: '10px', fontSize: '28px', color: 'black' }}
            >
              {' '}
              Raw Data Report{' '}
            </h1>
          </Col>
        </Row>

        <div id='customReport'>
          {
            <ReportCustomComp
              columns={columns}
              setValidationErrors={setValidationErrors}
              validationErrors={validationErrors}
              unique={'data_id'}
              enableEditing={false}
              type={'rawdata'}
              readOnly={!reportPermissions['rawDataReport'].includes(userRole)}
            />
          }
        </div>
      </ContentPaper>
    </RfsLayout>
  );
}

export default RawDataReports;
