import React, { useContext } from 'react';
import { AppLoader } from '../../components/common/loader';

import ContentPaper from '../../components/layouts/rfs/Content';
import ProjectName from '../../components/projectName/projectName';
import { RfsContext } from '../../contexts/RfsContext';
import { OskuGantt } from './osku';
import { VeamGantt } from './veam';
import { ProductionSiteGantt } from './production-site';
import { DelistingGantt } from './delisting';
import { SupplierChangeGantt } from './supplier-change';

const editorOptions = {
  vCaptionType: 'Caption',
  vUseToolTip: 1,
  vQuarterColWidth: 36,
  vDateTaskDisplayFormat: 'day dd month yyyy',
  vDayMajorDateDisplayFormat: 'mon yyyy - Week ww',
  vWeekMinorDateDisplayFormat: 'dd mon',
  vLang: 'en',
  vUseSingleCell: 1000000,
  vShowRes: 0,
  vShowTaskInfoRes: 1,
  vShowCost: 0,
  vShowComp: 0,
  vShowDur: 0,
  vShowStartDate: 1,
  vShowEndDate: 1,
  vShowPlanStartDate: 1,
  vShowPlanEndDate: 1,
  vShowEndWeekDate: 0,
  vEditable: 0,
  vWeekColWidth: 50,
  vAdditionalHeaders: {
    sla: {
      title: 'SLA (Weeks)',
    },
    status: {
      title: 'Status',
    },
  },
  vTooltipDelay: 300,

  vFormatArr: ['Day', 'Week', 'Month', 'Quarter'],
};

const components = (type, props) => {
  const list = {
    'Open SKU to market': <OskuGantt {...props} />,
    'Article Modification/Value Engineering': <VeamGantt {...props} />,
    'Production Site': <ProductionSiteGantt {...props} />,
    Delisting: <DelistingGantt {...props} />,
    'Supplier Change': <SupplierChangeGantt {...props} />,
  };
  return list[type];
};

export default function GanttChart({ rfsNumber, trials }) {
  const { form } = useContext(RfsContext);
  const { rfs_type } = { ...form.rfsInformation };

  if (rfs_type.length === 0) {
    return (
      <ContentPaper title={'gantt'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  const GanttComponent = (props) => {
    return components(rfs_type, props);
  };

  return (
    <ContentPaper title={'gantt'} page={rfs_type.split(' ').join('_')}>
      <ProjectName />
      <GanttComponent rfsNumber={rfsNumber} trials={trials} editorOptions={editorOptions} />
    </ContentPaper>
  );
}
