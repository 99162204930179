import { Accordion, Grid } from '@mantine/core';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useContext, useState } from 'react';
import './projectName.css';
import { RfsContext } from '../../contexts/RfsContext';
import { useEffect } from 'react';
import ModalAccf from '../../pages/accf/ModalAccf';

export default function ProjectName(props) {
  const { form } = useContext(RfsContext);
  const {
    project_name = '',
    rfs_number = '',
    initiator_name,
    rfs_type = '',
    first_shipment,
    fast_track,
    gatekeeper,
    technicalLead,
    status,
    impactedMarkets = [],
  } = form.rfsInformation;

  const getProjectType = () => {
    if (!rfs_type) return 'Not Specified';
    let val = rfs_type ? rfs_type.split(' ') : [];
    return val.length > 1
      ? rfs_type === 'Article Modification/Value Engineering'
        ? 'VEAM'
        : `${val[0][0]}${val[1][0]}`
      : val;
  };

  return (
    <div className='Mainparent'>
      <Accordion iconPosition='right' defaultValue={props?.initialItem || '0'}>
        <Accordion.Item className='project-name' value={props?.initialItem || '0'}>
          <Accordion.Control>{`${rfs_number}: ${project_name}`}</Accordion.Control>
          <Accordion.Panel style={{ padding: '5px 16px 16px' }}>
            <Row>
              <Col md={3}>
                <p className='column1'>
                  <b>Project Type: </b>
                  {getProjectType()}
                </p>
              </Col>
              <Col md={3}>
                <p className='column2'>
                  <b>Gatekeeper: </b>{' '}
                  {gatekeeper?.length > 0 ? gatekeeper[0].name : 'Not Specified'}
                </p>
              </Col>
              <Col md={3}>
                <p className='column3' style={{ display: 'flex' }}>
                  <b style={{ marginRight: '5px' }}>Markets: </b>{' '}
                  {impactedMarkets.length > 0 ? (
                    <ModalAccf
                      type={'markets'}
                      title={'Impacted Markets'}
                      selected={impactedMarkets}
                      handleSelect={() => {}}
                      submitted={false}
                      disabled={true}
                    />
                  ) : (
                    'Not Specified'
                  )}
                </p>
              </Col>
              <Col md={3}>
                <p className='column4'>
                  {' '}
                  <b>Fast Track: </b> {fast_track ? 'Yes' : 'No'}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <p className='column1 capitalised'>
                  <b>Project Status: </b> {status}
                </p>
              </Col>
              <Col md={3}>
                <p className='column2'>
                  <b>Initiator: </b> {initiator_name}
                </p>
              </Col>
              <Col md={3}>
                <p className='column3'>
                  <b>Technical Lead: </b>{' '}
                  {technicalLead?.length > 0 ? technicalLead[0].name : 'Not Specified'}
                </p>
              </Col>
              <Col md={3}>
                <p className='column4'>
                  <b>First Shipment: </b> {first_shipment ? first_shipment : 'No'}
                </p>
              </Col>
            </Row>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
