import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import { ArtworkDevelopmentTable } from './ArtworkDevelopmentTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import { axios } from '../../../../utils/axios';
import { AppLoader } from '../../../../components/common/loader';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import checkClosedOrInProgress from '../../veamHelper';
import { AttachmentsModal } from '../../../../common/attachments';
import { attachmentChunks } from '../../../../utils/helpers';

const MAX_ATTACHMENTS = 5;

export default function ArtworkDevelopmentCode({ checkClosuresArtWork, readOnly }) {
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [materials, setMaterials] = useState([]);
  const [details, setDetails] = useState({});
  const [attachmentModal, setAttachmentModal] = useState({
    open: false,
    artwork_dev: null,
    status_id: null,
    attachments: [],
    deletedAttachments: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkClosuresArtWork(checkClosedOrInProgress(materials));
  }, [materials]);

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = (initialLoading = true) => {
    setLoading(initialLoading);
    axios
      .get(`/rfs/artwork/info/${rfs_number}`)
      .then(
        (res) => {
          const data = res.data.data;
          setMaterials(res.data.data);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const save = (data, id, status_id) => {
    axios.put(`/rfs/artwork/update/${id}/${status_id}`, data[id]).then(() => {
      fetchInfo(false);
    });
  };

  const handleFileSelect = (files) => {
    const currentValues = { ...attachmentModal };
    const attachments = currentValues.attachments || [];
    const filesLength = attachments.length + files.length;

    if (filesLength <= MAX_ATTACHMENTS) {
      files.forEach((file) => attachments.push(file));
      currentValues.attachments = attachments;
      setAttachmentModal(currentValues);
    }
  };

  const handleFileRemove = (index) => {
    const currentValues = { ...attachmentModal };
    currentValues.attachments = currentValues.attachments.filter((i, k) => k !== index);

    setAttachmentModal(currentValues);
  };

  const handleAttachmentSubmit = async () => {
    const { artwork_dev, attachments } = attachmentModal;
    const oldAttachments =
      (artwork_dev.gfp_attachment || '').length > 0 ? artwork_dev.gfp_attachment.split(',') : [];

    const attachmentNames = new Set(attachments.map((file) => file.name));
    const deletedAttachments = oldAttachments.filter((name) => !attachmentNames.has(name));

    const formData = new FormData();
    formData.append('rfs_number', rfs_number);
    formData.append('id', attachmentModal.artwork_dev.id);

    for (const item of deletedAttachments) {
      formData.append('deletedAttachments[]', item);
    }

    const chunks = attachmentChunks(attachments.filter((file) => file.size));
    if (Object.keys(chunks).length > 0) {
      for (const chunk of Object.values(chunks)) {
        for (const file of chunk) {
          formData.append('attachments', file);
        }
        await uploadAttachment(formData);
      }
    } else {
      await uploadAttachment(formData);
    }

    setAttachmentModal({ ...attachmentModal, open: false });
    fetchInfo(false);
  };

  const uploadAttachment = async (formData) => {
    try {
      const res = await axios.post(`/rfs/artwork/attachment`, formData);
      return res;
    } catch (error) {
      console.error('Error while handling attachment submission:', error);
    }
  };

  const dataToExport = ({
    int_rfs_impl_material_creation,
    int_rfs_material,
    int_rfs_impl_artwork_dev,
    sla_date,
    status,
    finished_date,
  }) => {
    return {
      'New Material Code': int_rfs_impl_material_creation?.new_material_code,
      'New Material Description': int_rfs_impl_material_creation?.new_material_desc,
      Plant: int_rfs_material?.plant,
      'PO Number Provider for this artwork': int_rfs_impl_artwork_dev?.artwork_po_provider,
      'PO Number for Artwork': int_rfs_impl_artwork_dev?.artwork_po_number,
      'Artwork Briefed to Agency': int_rfs_impl_artwork_dev?.artwork_briefed,
      'Colour target setting finalized': int_rfs_impl_artwork_dev?.color_target_setting_finalized,
      'Artwork Approved/ Artwork sent to supplier': int_rfs_impl_artwork_dev?.artwork_approved,
      'PO Number Provider for Supplier': int_rfs_impl_artwork_dev?.supplier_po_provider,
      'PO Number for Supplier': int_rfs_impl_artwork_dev?.supplier_po_number,
      'GFP Attachment': int_rfs_impl_artwork_dev?.gfp_attachment,
      'GFP Provided': int_rfs_impl_artwork_dev?.gfp_provided,
      'GFP Approved': int_rfs_impl_artwork_dev?.gfp_approved,
      'Changes Made ': int_rfs_impl_artwork_dev?.changes_made,
      Status: status,
      'Step Deadline': sla_date,
      'Step Finished': finished_date === null ? status : finished_date,
    };
  };
  const csvArray = materials.map(dataToExport);

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader center size='lg' />
      </ContentPaper>
    );
  }

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <Box
        sx={(theme) => ({
          backgroundColor: 'black',
          textAlign: 'center',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.md,
          cursor: 'pointer',

          color: theme.colors.yellow[5],
          fontSize: theme.fontSizes.lg,
          fontWeight: 1000,
          '&:hover': {
            backgroundColor: 'black',
          },
        })}
      >
        Material Codes
      </Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <CSVLink
          data={csvArray}
          filename={'Artwork Development_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button
            leftIcon={<TableExport />}
            color='primary'
            style={{
              marginBottom: '0rem',
              marginTop: '1rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      <Row>
        <Grid>
          <Grid.Col span={12}>
            <ArtworkDevelopmentTable
              data={materials}
              save={save}
              details={details}
              setDetails={setDetails}
              setAttachmentModal={setAttachmentModal}
              readOnly={readOnly}
            />
          </Grid.Col>
        </Grid>
      </Row>

      {attachmentModal.open && (
        <AttachmentsModal
          modal={attachmentModal}
          setModal={setAttachmentModal}
          onChange={handleFileSelect}
          onSubmit={handleAttachmentSubmit}
          onRemove={handleFileRemove}
          readOnly={attachmentModal.artwork_dev.gfp_approved === 'Approved'}
        />
      )}
    </ContentPaper>
  );
}
