import * as yup from 'yup';

const delistingFormSchema = yup.object().shape({
  cost_centre: yup
    .string()
    .max(50)
    .matches(/^(?! )(?=.*[A-Za-z0-9])[A-Za-z0-9\s]{1,50}$/)
    .required(),
  delisting_date: yup.date().required(),
  approved_by_sales: yup.boolean().oneOf([true], 'approved_by_sales field must be true'),
  confirm_obsoletes: yup.boolean().oneOf([true], 'confirm_obsoletes field must be true'),
  impacted_markets: yup.array().min(1).required(),
});

export default delistingFormSchema;
