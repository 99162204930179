import React, { useState, useEffect, useContext, useMemo } from 'react';
import ContentPaper from '../../components/layouts/rfs/Content';
import RfsLayout from '../../components/layouts/RfsLayout';
import { axios } from '../../utils/axios';
import { Row, Col } from 'react-bootstrap';
import { AppLoader } from '../../components/common/loader';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import ReportCustomComp from '../../components/report/report';
import moment from 'moment';

function SmdReport() {
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({}); //for Report
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get('/reports/smd')
      .then(
        (res) => {
          let response = res.data.data.recordset;
          const smdData = [];

          response.forEach((item, index) => {
            response[index]['TrialApprovalDate'] = item.TrialApprovalDate
              ? item.TrialApprovalDate.replace(/\s\d{1,2}:\d{2}[APM]{2}$/,'')
              :null;
          
            response[index]['TrialApprovalDate'] = moment(
              response[index]['TrialApprovalDate'],
            ).isValid()
              ? moment(response[index]['TrialApprovalDate']).format('DD/MM/YYYY')
              : '';

              response[index]['RFSApprovalDate'] = item.RFSApprovalDate
              ? item.RFSApprovalDate.replace(/\s\d{1,2}:\d{2}[APM]{2}$/, '')
              : null;  
            
            response[index]['RFSApprovalDate'] = moment(
              response[index]['RFSApprovalDate'],
            ).isValid()
              ? moment(response[index]['RFSApprovalDate']).format('DD/MM/YYYY')
              : '';

              response[index]['CancellationDate'] = item.CancellationDate
              ? item.CancellationDate.replace(/\s\d{1,2}:\d{2}[APM]{2}$/, '')
              : null;  
            
            
            response[index]['CancellationDate'] = moment(
              response[index]['CancellationDate'],
            ).isValid()
              ? moment(response[index]['CancellationDate']).format('DD/MM/YYYY')
              : '';

            response[index]['LastApprovalChangeDate'] = moment(
              response[index]['LastApprovalChangeDate'],
              'MMM DD YYYY hh:mmA',
            ).isValid()
              ? moment(response[index]['LastApprovalChangeDate'], 'MMM DD YYYY hh:mmA').format(
                  'DD/MM/YYYY',
                )
              : '';

            response[index]['submission_date'] = moment(
              response[index]['submission_date'],
            ).isValid()
              ? moment(response[index]['submission_date']).format('DD/MM/YYYY')
              : '';

            smdData.push({
              'RFS ID': response[index]['ProjectID'],
              'RFS Name': response[index]['ProjectName'],
              'Request Type': response[index]['ProjectType'],
              'Request Status': response[index]['ProjectStatus'],
              'Sales Business Unit': response[index]['sales_business_unit'],
              'Production Business Unit':
                response[index]['prod_bus_unit'] &&
                response[index]['prod_bus_unit'].length > 0 &&
                response[index]['prod_bus_unit'] !== 'N/A'
                  ? response[index]['prod_bus_unit']
                  : '',
              Initiator: response[index]['initiator_name'],
              Gatekeeper: response[index]['GateKeeper'],
              'Steps in Progress': response[index]['status_step']
                ? response[index]['status_step']
                : '',
              'Submission Date': response[index]['submission_date'],
              'Last approval change date': response[index]['LastApprovalChangeDate'],
              'Fast Track': response[index]['fast_track'],
              'Technical Feasibility Approval Date': response[index]['TrialApprovalDate'],
              'Implementation Feasibility Approval Date': response[index]['RFSApprovalDate'],
              'Cancellation Date': response[index]['CancellationDate'],
            });
          });

          setTableData(smdData);
        },
        (err) => {},
      )
      .finally(() => setLoading(false));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'RFS ID',
        header: `RFS ID`,
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'RFS Name',
        header: 'RFS Name',
      },
      {
        accessorKey: 'Request Type',
        header: 'Request Type',
      },
      {
        accessorKey: 'Request Status',
        header: 'Request Status',
      },
      {
        accessorKey: 'Sales Business Unit',
        header: 'Sales Business Unit',
      },
      {
        accessorKey: 'Production Business Unit',
        header: 'Production Business Unit',
      },
      {
        accessorKey: 'Initiator',
        header: 'Initiator',
      },
      {
        accessorKey: 'Gatekeeper',
        header: 'Gatekeeper',
      },
      {
        accessorKey: 'Steps in Progress',
        header: 'Steps in Progress',
        filterFn: 'contains',
        Cell: ({ renderedCellValue, row }) => {
          let stepsInProgress = (
            row.original['Steps in Progress'] ? row.original['Steps in Progress'] : ''
          ).split(',');
          stepsInProgress = stepsInProgress.join('<br />');
          return <div dangerouslySetInnerHTML={{ __html: stepsInProgress }} />;
        },
      },
      {
        accessorKey: 'Submission Date',
        header: 'Submission Date',
      },
      {
        accessorKey: 'Last approval change date',
        header: 'Last approval change date',
      },
      {
        accessorKey: 'Fast Track',
        header: 'Fast Track',
      },
      {
        accessorKey: 'Technical Feasibility Approval Date',
        header: 'Technical Feasibility Approval Date',
      },
      {
        accessorKey: 'Implementation Feasibility Approval Date',
        header: 'Implementation Feasibility Approval Date',
      },
      {
        accessorKey: 'Cancellation Date',
        header: 'Cancellation Date',
      },
    ],
    [validationErrors],
  );

  if (loading)
    return (
      <RfsLayout>
        <ContentPaper page='smd-report'>
          <AppLoader center size='md' />
        </ContentPaper>
      </RfsLayout>
    );

  return (
    <RfsLayout>
      <ContentPaper page='smd-report'>
        <Row>
          <Col
            md={{ span: 6 }}
            style={{
              marginTop: '1rem',
              display: 'inline-flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              size='sm'
              color='dark'
              width='2rem'
              style={{ paddingLeft: '20', paddingRight: '20' }}
              onClick={() => {
                navigate('/reports');
              }}
            >
              Back{' '}
            </Button>
            <h1
              className='sectionHeadings'
              style={{ paddingLeft: '10px', fontSize: '28px', color: 'black' }}
            >
              {' '}
              SMD Workload Report{' '}
            </h1>
          </Col>
        </Row>

        <div id='customReport'>
          {
            <ReportCustomComp
              reportType='smd'
              data={tableData}
              columns={columns}
              setValidationErrors={setValidationErrors}
              validationErrors={validationErrors}
              unique={'RFS ID'}
              enableEditing={false}
            />
          }
        </div>
      </ContentPaper>
    </RfsLayout>
  );
}

export default SmdReport;
