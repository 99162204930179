export const transformSkuData = (data) => {
  const skuMap = new Map();

  data.forEach((item) => {
    if (skuMap.has(item.SKUs)) {
      // If SKU exists, just push the AlternativeBOM to the existing array
      skuMap.get(item.SKUs).AlternativeBOMs.push({
        AlternativeBOM: item.AlternativeBOM,
        ValidTill: item.ValidTill,
        Plant: item.Plant,
      });
    } else {
      // If SKU does not exist, create a new entry in the map
      skuMap.set(item.SKUs, {
        uniqueId: `${item.SKUs}$${item.ChangeoverDate}`,
        SKUs: item.SKUs,
        SKUDescription: item.SKUDescription,
        EANNumbers: item.EANNumbers,
        Markets: item.Markets,
        ChangeoverDate: item.ChangeoverDate,
        AlternativeBOMs: [
          {
            AlternativeBOM: item.AlternativeBOM,
            ValidTill: item.ValidTill,
            Plant: item.Plant,
          },
        ],
      });
    }
  });

  // Convert the map values to an array to return
  return Array.from(skuMap.values());
};
