import React, { useState, useContext } from 'react';
import ContentPaper from '../../components/layouts/rfs/Content';
import { Tabs } from '@mantine/core';
import { RfsContext } from '../../contexts/RfsContext';
import RfsLayout from '../../components/layouts/RfsLayout';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import { Row, Col } from 'react-bootstrap';
import { tabStyles } from '../../utils/helpers';
import FtrInitiatorReport from './ftrInitiator';
import FtrTeamReport from './ftrTeam';
import FtrRFSreport from './ftrRFS';

const tabTypes = {
  all: 0,
  backup: 1,
  my: 2,
};

function FtrReport({ type = 'rfs' }) {
  const [activeTab, setActiveTab] = useState(tabTypes[type]);
  const [nestedTab, setNestedTab] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (activeTab, tabKey) => {
    setActiveTab(activeTab);
  };

  const handleNestedTabChange = (nestedTab, tabKey) => {
    setNestedTab(nestedTab);
  };

  return (
    <RfsLayout>
      <ContentPaper page='all-rfs'>
        <Row>
          <Col
            style={{
              marginTop: '1rem',
              display: 'inline-flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              size='sm'
              color='dark'
              width='2rem'
              style={{ paddingLeft: '20', paddingRight: '20' }}
              onClick={() => {
                navigate('/reports');
              }}
            >
              Back{' '}
            </Button>
            <h1
              className='sectionHeadings'
              style={{ paddingLeft: '10px', fontSize: '28px', color: 'black' }}
            >
              {' '}
              First Time Right Report{' '}
            </h1>
          </Col>
        </Row>
        <Tabs
          variant='unstyled'
          styles={tabStyles}
          className='all-rfs-tabs'
          active={activeTab}
          onTabChange={handleTabChange}
          color='dark'
          keepMounted={false}
          defaultValue={'ffr'}
        >
          <Tabs.List>
            <Tabs.Tab className='topTabs' value='ffr' tabKey='rfs'>
              FTR for Initiator
            </Tabs.Tab>
            <Tabs.Tab className='topTabs' value='ffo' tabKey='team'>
              FTR for Teams
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='ffr'>
            <Tabs
              className='in-progress-tabs'
              active={nestedTab}
              onTabChange={handleNestedTabChange}
              color='dark'
              keepMounted={false}
              defaultValue={'rfs-level'}
            >
              <Tabs.List>
                <Tabs.Tab value='rfs-level'>RFS Level</Tabs.Tab>
                <Tabs.Tab value='initiator-level'>Initiator Level</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value='rfs-level'>
                <FtrRFSreport />
              </Tabs.Panel>
              <Tabs.Panel value='initiator-level'>
                <FtrInitiatorReport />
              </Tabs.Panel>
            </Tabs>
          </Tabs.Panel>
          <Tabs.Panel value='ffo'>
            <Tabs
              className='in-progress-tabs'
              active={nestedTab}
              onTabChange={handleNestedTabChange}
              color='dark'
              keepMounted={false}
              defaultValue={'tlr'}
            >
              <Tabs.List>
                <Tabs.Tab value='tlr' tabKey='team'>
                  Team Level Report
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value='tlr'>
                <FtrTeamReport />
              </Tabs.Panel>
            </Tabs>
          </Tabs.Panel>
        </Tabs>
      </ContentPaper>
    </RfsLayout>
  );
}

export default FtrReport;
