import React, { useState, useContext, useEffect } from 'react';
import { Button, Text } from '@mantine/core';
import { AuthContext } from '../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { RfsContext } from '../../contexts/RfsContext';
import { axios } from '../../utils/axios';
import { rfsStatus } from '../../constants/index';
import ContentPaper from '../../components/layouts/rfs/Content';
import { Row, Col } from 'react-bootstrap';
import ProjectName from '../../components/projectName/projectName';
import OskuScopeUnlock from './osku_scope_unlock';
import { AppLoader } from '../../components/common/loader';
import { ProjectOverviewContext } from '../../contexts/projectOverviewContext';
import { CommonContext } from '../../contexts/common';
import PsScopeUnlock from '../productionSite/ScopeUnlock/ScopeUnlock';
import DLSTScopeUnlock from '../DLST/ScopeUnlock/ScopeUnlockDLST';
import { VeamScopeUnlock } from './veam';
import ScopeUnlockSupplierChange from '../supplierChange/scopeUnlock';
import { SupplierProvider } from '../../contexts/SupplierContext';

const initialFormState = {
  teams_involved: [],
  scope_unlock_flag: null,
  comment: '',
  scope_ids: [],
  teams_responsible: [],
};

const PsInitialFormState = {
  teams_involved: [],
  scope_unlock_flag: null,
  comment: '',
  scope_ids: [],
  sku_ids: [],
};

const VeamInitialFormState = {
  teams_involved: [],
  scope_unlock_flag: null,
  comment: '',
  reversal_scope: [],
  teams_responsible: null,
};

const DlstInitialFormState = {
  teams_involved: [],
  teams_responsible: [],
  scope_unlock_flag: null,
  comment: '',
  scope_ids: [],
};

const ScInitialFormState = {
  teams_involved: [],
  teams_responsible: [],
  scope_unlock_flag: null,
  comment: '',
  scope_ids: [],
};

function ScopeUnlock(props) {
  const { rfsNumber } = useParams();
  const { userName, userEmail } = useContext(AuthContext);
  const { info, form } = useContext(RfsContext);
  const {
    scopeUnlockInfo,
    getScopeUnlockInfo,
    getPsScopeUnlockInfo,
    PsScopeUnlockInfo,
    getVeamScopeUnlockInfo,
    veamScopeUnlockInfo,
    delistingScopeUnlockInfo,
    getDelistingScopeUnlockInfo,
    scScopeUnlockInfo,
    getScScopeUnlockInfo,
  } = useContext(ProjectOverviewContext);
  const { fetchSystemTeams } = useContext(CommonContext);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [scopeUnlockForm, setScopeUnlockForm] = useState({
    ...initialFormState,
    ...scopeUnlockInfo,
  });

  const [PsScopeUnlockForm, setPsScopeUnlockForm] = useState({
    ...PsInitialFormState,
    ...PsScopeUnlockInfo,
  });
  const [veamForm, setVeamForm] = useState({
    ...VeamInitialFormState,
    ...veamScopeUnlockInfo,
  });

  const [dlstScopeUnlockForm, setDlstScopeUnlockForm] = useState({
    ...DlstInitialFormState,
    ...delistingScopeUnlockInfo,
  });

  const [scScopeUnlockForm, setScScopeUnlockForm] = useState({
    ...ScInitialFormState,
    ...scScopeUnlockInfo,
  });

  const { rfs_type, status } = form.rfsInformation;

  useEffect(() => {
    fetchSystemTeams();
  }, [fetchSystemTeams]);

  useEffect(() => {
    if (rfsNumber) info(rfsNumber);
  }, [rfsNumber]);

  useEffect(() => {
    if (veamScopeUnlockInfo.id) setVeamForm(veamScopeUnlockInfo);
    if (delistingScopeUnlockInfo.id) setDlstScopeUnlockForm(delistingScopeUnlockInfo);
    if (PsScopeUnlockInfo.id) setPsScopeUnlockForm(PsScopeUnlockInfo);
    if (scopeUnlockInfo.id) setScopeUnlockForm(scopeUnlockInfo);
    if (scScopeUnlockInfo.id) setScScopeUnlockForm(scScopeUnlockInfo);
  }, [
    veamScopeUnlockInfo,
    delistingScopeUnlockInfo,
    PsScopeUnlockInfo,
    scopeUnlockInfo,
    scScopeUnlockInfo,
  ]);

  useEffect(() => {
    if (rfs_type) {
      const fetchScopeUnlockInfo = {
        'Article Modification/Value Engineering': () => getVeamScopeUnlockInfo(rfsNumber),
        'Production Site': () => getPsScopeUnlockInfo(rfsNumber),
        Delisting: () => getDelistingScopeUnlockInfo(rfsNumber),
        'Open SKU to market': () => getScopeUnlockInfo(rfsNumber),
        'Supplier Change': () => getScScopeUnlockInfo(rfsNumber),
      };

      if (fetchScopeUnlockInfo[rfs_type]) fetchScopeUnlockInfo[rfs_type]();
    }
  }, [rfsNumber, rfs_type]);

  const invalidStatus = () => {
    return [rfsStatus.implemented].includes(status);
  };

  const isDisabled = () => {
    if (form.rfsInformation.rfs_type === 'Production Site') {
      const { teams_involved, scope_unlock_flag, comment, sku_ids, teams_responsible, scope_ids } =
        PsScopeUnlockForm;
      return (
        teams_involved?.length === 0 ||
        comment?.length === 0 ||
        !scope_unlock_flag ||
        !teams_responsible ||
        scope_ids?.length === 0 ||
        submitLoading ||
        PsScopeUnlockForm.id ||
        invalidStatus()
      );
    } else if (form.rfsInformation.rfs_type === 'Article Modification/Value Engineering') {
      const { teams_involved, scope_unlock_flag, comment, reversal_scope, teams_responsible } =
        veamForm;
      return (
        teams_involved.length === 0 ||
        !scope_unlock_flag ||
        comment?.length === 0 ||
        reversal_scope.length === 0 ||
        submitLoading ||
        veamForm.id ||
        !teams_responsible ||
        invalidStatus()
      );
    } else if (form.rfsInformation.rfs_type === 'Delisting') {
      const { teams_involved, scope_unlock_flag, comment, teams_responsible, scope_ids } =
        dlstScopeUnlockForm;

      return (
        teams_involved?.length === 0 ||
        comment?.length === 0 ||
        !scope_unlock_flag ||
        teams_responsible?.length === 0 ||
        scope_ids?.length === 0 ||
        submitLoading ||
        dlstScopeUnlockForm.id ||
        invalidStatus()
      );
    } else if (form.rfsInformation.rfs_type === 'Supplier Change') {
      const { teams_involved, scope_unlock_flag, comment, teams_responsible, scope_ids } =
        scScopeUnlockForm;

      return (
        teams_involved?.length === 0 ||
        comment?.length === 0 ||
        !scope_unlock_flag ||
        teams_responsible?.length === 0 ||
        submitLoading ||
        scope_ids?.length === 0 ||
        scScopeUnlockForm.id ||
        invalidStatus()
      );
    } else {
      const { teams_involved, scope_unlock_flag, comment, scope_ids = [] } = scopeUnlockForm;
      return (
        teams_involved.length === 0 ||
        !scope_unlock_flag ||
        comment?.length === 0 ||
        submitLoading ||
        scopeUnlockForm.id ||
        scope_ids.length === 0 ||
        invalidStatus()
      );
    }
  };

  const setFormValues = (newValue, existingValues = null, type = 'osku') => {
    const types = {
      osku: { values: scopeUnlockForm, setValues: setScopeUnlockForm },
      veam: { values: veamForm, setValues: setVeamForm },
      delisting: { values: dlstScopeUnlockForm, setValues: setDlstScopeUnlockForm },
      sc: { values: scScopeUnlockForm, setValues: setScScopeUnlockForm },
    };
    const currentData = existingValues ? existingValues : types[type].values;

    types[type].setValues({
      ...currentData,
      ...newValue,
    });
  };

  const setPsFormValues = (newValue, existingValues = null) => {
    const currentData = existingValues ? existingValues : PsScopeUnlockForm;

    setPsScopeUnlockForm({
      ...currentData,
      ...newValue,
    });
  };

  const handleSubmit = () => {
    setSubmitLoading(true);
    const submitActions = {
      'Production Site': () => productionSiteSubmit(),
      'Article Modification/Value Engineering': () => veamSubmit(),
      Delisting: () => delistingSubmit(),
      'Open SKU to market': () => oskuSubmit(),
      'Supplier Change': () => scSubmit(),
    };

    if (submitActions[rfs_type]) submitActions[rfs_type]();
  };

  const veamSubmit = () => {
    axios
      .post(`/veam/create/scope_unlock/${rfsNumber}`, veamForm)
      .then(
        (res) => {
          info(rfsNumber);
          getVeamScopeUnlockInfo(rfsNumber);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setSubmitLoading(false));
  };

  const delistingSubmit = () => {
    axios
      .post(`/delisting/scope_unlock`, {
        ...dlstScopeUnlockForm,
        teams_involved: dlstScopeUnlockForm.teams_involved.toString(),
        scope_unlock_flag: parseInt(dlstScopeUnlockForm.scope_unlock_flag),
        rfs_number: rfsNumber,
        initiated_by: userEmail,
      })
      .then(
        (res) => {
          info(rfsNumber);
          getDelistingScopeUnlockInfo(rfsNumber);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const scSubmit = () => {
    axios
      .post(`/supplier-changes/scope-unlock/${rfsNumber}`, {
        ...scScopeUnlockForm,
        teams_involved: scScopeUnlockForm.teams_involved.toString(),
        scope_unlock_flag: parseInt(scScopeUnlockForm.scope_unlock_flag),
        rfs_number: rfsNumber,
        initiated_by: userEmail,
      })
      .then(
        (res) => {
          info(rfsNumber);
          getScScopeUnlockInfo(rfsNumber);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const productionSiteSubmit = () => {
    axios
      .post(`production-site/scope_unlock/update/${rfsNumber}`, {
        ...PsScopeUnlockForm,
        teams_involved: PsScopeUnlockForm.teams_involved.toString(),
        scope_unlock_flag: parseInt(PsScopeUnlockForm.scope_unlock_flag),
      })
      .then(
        (res) => {
          info(rfsNumber);
          getPsScopeUnlockInfo(rfsNumber);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const oskuSubmit = () => {
    axios
      .post(`/openSku/scope-unlock/${rfsNumber}`, scopeUnlockForm)
      .then(
        (res) => {
          info(rfsNumber);
          getScopeUnlockInfo(rfsNumber);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setSubmitLoading(false));
  };

  const Footer = () => (
    <Row>
      <Col md={{ span: '2', offset: 6 }}></Col>
      <Col md={{ span: '2' }}></Col>
      <Col md={{ span: '2' }}>
        <Button
          onClick={handleSubmit}
          color='primary'
          radius='xs'
          size='lg'
          variant='gradient'
          gradient={{ from: '#e3af32', to: '#f4e00f' }}
          disabled={isDisabled()}
          paddingTop='200'
          styles={(theme) => ({
            root: {
              border: '2',
              height: 42,
              paddingLeft: 30,
              paddingRight: 30,
            },
          })}
        >
          {submitLoading ? <AppLoader /> : 'Scope Unlock'}
        </Button>
      </Col>
    </Row>
  );

  if (form.rfsInformation.rfs_type === 'Open SKU to market')
    return (
      <OskuScopeUnlock
        form={form}
        footer={<Footer />}
        userName={userName}
        values={scopeUnlockForm}
        setValues={setFormValues}
        readOnly={invalidStatus()}
      />
    );

  if (form.rfsInformation.rfs_type === 'Production Site')
    return (
      <PsScopeUnlock
        form={form}
        footer={<Footer />}
        userName={userName}
        values={PsScopeUnlockForm}
        setValues={setPsFormValues}
        readOnly={invalidStatus()}
      />
    );

  if (form.rfsInformation.rfs_type === 'Delisting')
    return (
      <DLSTScopeUnlock
        form={form}
        footer={<Footer />}
        userName={userName}
        values={dlstScopeUnlockForm}
        setValues={setFormValues}
        readOnly={invalidStatus()}
      />
    );

  if (form.rfsInformation.rfs_type === 'Article Modification/Value Engineering')
    return (
      <VeamScopeUnlock
        form={form}
        footer={<Footer />}
        userName={userName}
        values={veamForm}
        setValues={setFormValues}
        readOnly={invalidStatus()}
      />
    );

  if (form.rfsInformation.rfs_type === 'Supplier Change')
    return (
      <SupplierProvider>
        <ScopeUnlockSupplierChange
          form={form}
          footer={<Footer />}
          userName={userName}
          values={scScopeUnlockForm}
          setValues={setFormValues}
          readOnly={invalidStatus()}
        />
      </SupplierProvider>
    );

  return (
    <>
      <ContentPaper
        page={'ScopeUnlock'}
        footer={form.rfsInformation.status !== rfsStatus.scope_unlock ? <Footer /> : null}
      >
        <ProjectName />
        <div>
          <Text className='padded-error'>Scope unlock not available for this flow</Text>
        </div>
      </ContentPaper>
    </>
  );
}

export default ScopeUnlock;
