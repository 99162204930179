import { useState } from 'react';
import { Checkbox } from '@mantine/core';
import { ActionIcon } from '@mantine/core';
import { ChevronRightIcon, ChevronDownIcon } from '@radix-ui/react-icons';
import { AppLoader } from '../loader';
import { Text } from '@mantine/core';

export const Tree = ({ entries, onExpand, handleSkuSelect }) => {
  const [expanded, setExpanded] = useState([]);
  const [checked, setChecked] = useState([]);

  const toggle = (data) => {
    const { value } = data;
    const values = [...expanded];
    let index = values.findIndex((val) => val === value);

    if (index > -1) {
      values.splice(index, 1);
    } else {
      onExpand(data);
      values.push(value);
    }

    setExpanded(values);
  };

  const Element = ({ entries }) => (
    <div>
      {entries.map((val) => {
        const isExpanded = expanded.length > 0 && expanded.includes(val.value);
        const isLoading = !val.children && isExpanded;

        return (
          <>
            <div style={{ display: 'flex' }} key={`key_${val.value}`}>
              {val.child < 3 ? (
                <ActionIcon variant='transparent' onClick={() => toggle(val)}>
                  {isLoading ? (
                    <AppLoader size={14} />
                  ) : isExpanded ? (
                    <ChevronDownIcon size={16} />
                  ) : (
                    <ChevronRightIcon size={16} />
                  )}
                </ActionIcon>
              ) : (
                <div style={{ height: 28, width: 28 }} />
              )}

              <Checkbox
                label={val.name}
                value={val.value}
                checked={checked.includes(val.name)}
                onChange={(event) => {
                  const state = event.target.checked;
                  event.stopPropagation();

                  const values = [...checked];
                  if (values.includes(val.name)) {
                    let index = values.indexOf(val.name);
                    values.splice(index, 1);
                  } else {
                    values.push(val.name);
                  }
                  setChecked(values);

                  handleSkuSelect(state, val);
                }}
              />
            </div>
            {isExpanded &&
              (val?.children?.length > 0 ? (
                <div style={{ marginLeft: '16px' }}>
                  <Element entries={val.children} />
                </div>
              ) : (
                !isLoading && (
                  <div style={{ marginLeft: '3.8rem', fontSize: '0.8rem', color: 'red' }}>
                    No Child SKU found
                  </div>
                )
              ))}
          </>
        );
      })}
    </div>
  );

  return <Element entries={entries} />;
};
