import { Accordion, Badge, Grid } from '@mantine/core';
import { useContext } from 'react';
import styled from '@emotion/styled';
import { CostingRequestsContext, useCrStatusCancelled } from '../../../../contexts/CostingRequest';
import { useAccordionStyles } from '../../styles';
import { Link } from 'react-router-dom';
import { routes } from '../../../../routes';

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: #fa5252;
  padding: 8px;
  justify-content: center;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 18px;
`;

export const ProjectOverview = () => {
  const isCancelled = useCrStatusCancelled();
  const { classes } = useAccordionStyles();
  const { info } = useContext(CostingRequestsContext);

  return (
    <Accordion
      iconPosition='right'
      defaultValue={'0'}
      classNames={classes}
      className={classes.root}
    >
      <Accordion.Item className='project-name' value={'0'}>
        <Accordion.Control>{`${info?.costing_request_id}`} </Accordion.Control>
        <Accordion.Panel>
          {isCancelled && <Header>This CR is Cancelled</Header>}
          <Grid style={{ padding: '5px 16px 16px' }}>
            <Grid.Col span={2}>
              <b>RFS Name:</b>
              <span style={{ marginLeft: '5px' }}>
                {info?.int_rfs_overviews && info.int_rfs_overviews.length > 0
                  ? info.int_rfs_overviews.map((overview) => overview.project_name).join(', ')
                  : 'N/A'}
              </span>
              <div style={{ marginBottom: '10px' }} />
              <b>RFS No.:</b>{' '}
              <span style={{ marginLeft: '5px' }}>
                {info?.rfs_number ? (
                  <Link to={routes.project(info.rfs_number).root}>{info.rfs_number}</Link>
                ) : (
                  'N/A'
                )}
              </span>
            </Grid.Col>

            <Grid.Col span={2}>
              <b>SKU No.:</b>
              <span style={{ marginLeft: '5px' }}>
                {info?.sku_data && info.sku_data.length > 0 ? info.sku_data[0].sku : 'N/A'}
              </span>

              <div style={{ marginBottom: '10px' }} />
              <b>SKU Desc.:</b>
              <span style={{ marginLeft: '5px' }}>
                {info?.sku_data && info.sku_data.length > 0
                  ? info.sku_data[0].sku_description
                  : 'N/A'}
              </span>
            </Grid.Col>

            <Grid.Col span={2}>
              <b>Plants:</b>
              <span style={{ marginLeft: '5px' }}>
                {info?.producing_plant_data && info.producing_plant_data.length > 0
                  ? info.producing_plant_data.map((plant) => plant.producing_plant).join(', ')
                  : 'N/A'}
              </span>

              <div style={{ marginBottom: '10px' }} />
              <b>Sales Business Unit:</b>
              <span style={{ marginLeft: '5px' }}>
                {info?.int_rfs_overviews && info.int_rfs_overviews.length > 0
                  ? info.int_rfs_overviews
                      .map((overview) => overview.sales_business_unit)
                      .join(', ')
                  : 'N/A'}
              </span>
            </Grid.Col>

            <Grid.Col span={2}>
              <b>Type of Goods:</b>
              <span style={{ marginLeft: '5px' }}>{info?.types_of_good || 'N/A'}</span>
              <div style={{ marginBottom: '10px' }} />
              <b>Type of SKU:</b>
              <span style={{ marginLeft: '5px' }}>{info?.types_of_sku || 'N/A'}</span>
            </Grid.Col>

            <Grid.Col span={2}>
              <b>Urgency:</b>
              <span style={{ marginLeft: '5px' }}>{info?.urgency || 'N/A'}</span>
              <div style={{ marginBottom: '10px' }} />
              <b>Requester Name:</b>
              <span style={{ marginLeft: '5px' }}>{info?.user?.name || 'N/A'}</span>
            </Grid.Col>
          </Grid>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
