import * as yup from 'yup';
import {
  materialArtworkChangeOptions,
  materialTechnicalPackChangeOptions,
} from '../../../../constants';

export const ProductionSiteSchema = yup.object().shape({
  reference_plant: yup.string().nullable().required('reference_plant'),
  bom_action: yup.string().nullable().required('bom_action'),
  reference_alt_bom: yup.string().nullable().required('reference_alt_bom'),
  production: yup.string().nullable().required('production'),
  mother_product_date_formatting: yup
    .string()
    .nullable()
    .required('mother_product_date_formatting'),
  comment: yup
    .string()
    .nullable()
    .when('mother_product_date_formatting', {
      is: 'Others',
      then: () => yup.string().nullable().required('comment'),
    }),
  boms: yup.array().when('bom_action', {
    is: 'Modify BOM',
    then: () => yup.array().min(1, 'bomErrors'),
  }),
});

export const ProductionBomSchema = yup.object().shape({
  active: yup.boolean().nullable(),
  material_list: yup.string().nullable(),
  technical_pack_change: yup
    .array()
    .nullable()
    .when(['material_list', 'active'], (material_list, active) => {
      if (material_list == 'New Material' && active)
        return yup
          .array()
          .nullable()
          .min(1, 'technical_pack_change')
          .required('technical_pack_change');
    }),
  // technical_quality_change: yup
  //   .array()
  //   .nullable()
  //   .when(['material_list', 'active'], (material_list, active) => {
  //     if (material_list === 'New Material' && active)
  //       return yup
  //         .array()
  //         .nullable()
  //         .min(1, 'technical_quality_change')
  //         .required('technical_quality_change');
  //   }),
  selected_material_number: yup
    .string()
    .nullable()
    .when(['technical_pack_change', 'active'], ([technical_pack_change, active]) => {
      if (
        !(technical_pack_change || []).includes(materialTechnicalPackChangeOptions.removal) &&
        active
      )
        return yup.string().nullable().required('material_list');
    }),
  artwork_change: yup.string().nullable(),
  comment: yup
    .string()
    .nullable()
    .when(
      ['material_list', 'artwork_change', 'active'],
      ([material_list, artwork_change, active]) => {
        if (
          (artwork_change === materialArtworkChangeOptions.others ||
            material_list == 'New Material') &&
          active
        ) {
          return yup.string().nullable().required('comment');
        }
      },
    ),
});
