import React from 'react';
import { Modal, Container, Grid, Group, Text } from '@mantine/core';
import moment from 'moment';
import { Table } from '@mantine/core';

const PricingColumns = (
  <tr>
    <th style={{ minWidth: '3rem' }}>Vendor</th>
    <th style={{ minWidth: '3rem' }}>Price</th>
    <th style={{ minWidth: '3rem' }}>Unit</th>
    <th style={{ minWidth: '3rem' }}>Currency</th>
    <th style={{ minWidth: '3rem' }}>Valid End Date</th>
  </tr>
);

const PricingComponent = ({ pricingData, data, index }) => {
  return (
    <tr key={index}>
      <td>
        {(data.vendor ? data.vendor : null) +
          ' - ' +
          (data.vendor_name_1 ? data.vendor_name_1 : null) +
          ' - ' +
          (data.vendor_name_2 ? data.vendor_name_2 : null)}
      </td>
      <td>{data.net_price ? data.net_price : null}</td>
      <td>{data.price_unit ? data.price_unit : null}</td>
      <td>{data.currency ? data.currency : null}</td>
      <td>
        {pricingData.sla_date ? moment(pricingData.sla_date).utc().format('MM/DD/YYYY') : null}
      </td>
    </tr>
  );
};

export const PricingModal = ({ logModal, setLogModal, id, data }) => {
  const pricingData = data.find((obj) => {
    return obj.id === id;
  });

  return (
    <div>
      <Modal
        overflow='inside'
        opened={logModal.open}
        onClose={() => setLogModal({ open: false, id: id })}
        title='Pricing Information'
        className='pricing-modal'
      >
        <Container>
          <Grid>
            <Grid.Col span={12}>
              {pricingData.rfs_010_contracts.length > 0 ? (
                <Table
                  captionSide='top'
                  verticalSpacing='lg'
                  striped
                  highlightOnHover
                  style={{ width: '100%' }}
                >
                  <thead>{PricingColumns}</thead>
                  <tbody>
                    {pricingData.rfs_010_contracts.map((data, index) => {
                      return (
                        <PricingComponent data={data} pricingData={pricingData} index={index} />
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <Text weight={500}>No Vendors Found</Text>
              )}
            </Grid.Col>
          </Grid>
        </Container>
      </Modal>
    </div>
  );
};

export default PricingModal;
