import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import { ArtworkDevelopmentTable } from './ArtworkDevelopmentTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import { axios } from '../../../../utils/axios';
import { AppLoader } from '../../../../components/common/loader';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { roles } from '../../../../constants';
import { AuthContext } from '../../../../contexts/AuthContext';
import { attachmentChunks, sCodeStyles } from '../../../../utils/helpers';
import { AttachmentsModal } from '../../../../common/attachments';

const MAX_ATTACHMENTS = 5;

const accessors = {
  materials: 'selection_number',
  liquids: 'selection_number',
  skus: 'selection_number',
};
const secondaryAccessor = {
  materials: 'selection_name',
  liquids: 'selection_name',
  skus: 'selection_name',
};
const tertiaryAccessor = {
  materials: null,
  liquids: null,
  skus: null,
};

export default function ArtworkDevelopmentCode() {
  const { userRole } = useContext(AuthContext);
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [materials, setMaterials] = useState([]);
  const [details, setDetails] = useState({});
  const [disable, setDisable] = useState(true);
  const [attachmentModal, setAttachmentModal] = useState({
    open: false,
    artwork_dev: null,
    status_id: null,
    attachments: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInfo();
  }, []);

  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo, roles.d2p].includes(userRole)) setDisable(false);
  }, [userRole]);

  const fetchInfo = (initialLoading = true) => {
    setLoading(initialLoading);
    axios
      .get(`/production-site/artwork/info/${rfs_number}`)
      .then(
        (res) => {
          const data = res.data.data;
          // const artworkDev = Object.keys(data.int_rfs_impl_artwork_dev).map(k => {
          //   if (data.int_rfs_impl_artwork_dev[k] === 0 && [''])
          // })
          setMaterials(res.data.data);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const save = (data, id, status_id) => {
    axios.put(`/production-site/artwork/update/${id}/${status_id}`, data[id]).then(() => {
      fetchInfo(false);
    });
  };

  const handleFileSelect = (files) => {
    const currentValues = { ...attachmentModal };
    const attachments = currentValues.attachments || [];
    const filesLength = attachments.length + files.length;

    if (filesLength <= MAX_ATTACHMENTS) {
      files.forEach((file) => attachments.push(file));
      currentValues.attachments = attachments;
      setAttachmentModal(currentValues);
    }
  };

  const handleFileRemove = (index) => {
    const currentValues = { ...attachmentModal };
    currentValues.attachments = currentValues.attachments.filter((i, k) => k !== index);

    setAttachmentModal(currentValues);
  };

  const handleAttachmentSubmit = async () => {
    const { artwork_dev, attachments } = attachmentModal;
    const oldAttachments =
      (artwork_dev.gfp_attachment || '').length > 0 ? artwork_dev.gfp_attachment.split(',') : [];

    const attachmentNames = new Set(attachments.map((file) => file.name));
    const deletedAttachments = oldAttachments.filter((name) => !attachmentNames.has(name));

    const formData = new FormData();
    formData.append('rfs_number', rfs_number);
    formData.append('id', attachmentModal.artwork_dev.id);

    for (const item of deletedAttachments) {
      formData.append('deletedAttachments[]', item);
    }

    const chunks = attachmentChunks(attachments.filter((file) => file.size));
    if (Object.keys(chunks).length > 0) {
      for (const chunk of Object.values(chunks)) {
        for (const file of chunk) {
          formData.append('attachments', file);
        }
        await uploadAttachment(formData);
      }
    } else {
      await uploadAttachment(formData);
    }

    setAttachmentModal({ ...attachmentModal, open: false });
    fetchInfo(false);
  };

  const uploadAttachment = async (formData) => {
    try {
      const res = await axios.post(`/rfs/artwork/attachment`, formData);
      return res;
    } catch (error) {
      console.error('Error while handling attachment submission:', error);
    }
  };

  const sort_Materials_acctoSKUs = [...materials].sort((a, b) => a.scope_id - b.scope_id);

  const selectedScopeFn = (data) => {
    let selectedScopeList = {};
    for (let i = 0; i < data.length; i++) {
      selectedScopeList[data[i].id] = {
        selection_number: data[i].selection_number,
        selection_name: data[i].selection_name,
      };
    }
    return selectedScopeList;
  };

  var selectedScopeList = selectedScopeFn(form.selectedScope.skus);

  const dataToExport = ({
    int_rfs_impl_material_creation,
    int_rfs_impl_artwork_dev,
    int_rfs_ps_sku_reference,
    sla_date,
    status,
    scope_id,
    finished_date,
  }) => {
    return {
      'SKU no.': selectedScopeList[scope_id]?.selection_number,
      'SKU Desc': selectedScopeList[scope_id]?.selection_name,
      'New Material Code': int_rfs_impl_material_creation?.new_material_code,
      'New Material Description': int_rfs_impl_material_creation?.new_material_desc,
      Plant: int_rfs_ps_sku_reference?.selected_plant,
      'PO Number Provider for this artwork': int_rfs_impl_artwork_dev?.artwork_po_provider,
      'PO Number for Artwork': int_rfs_impl_artwork_dev?.artwork_po_number,
      'Artwork Briefed to Agency':
        int_rfs_impl_artwork_dev?.artwork_briefed === true ? 'Completed' : 'NA',
      'Colour target setting finalized':
        int_rfs_impl_artwork_dev?.color_target_setting_finalized === true ? 'Completed' : 'NA',
      'Artwork Approved/ Artwork sent to supplier':
        int_rfs_impl_artwork_dev?.artwork_approved === true ? 'Completed' : 'NA',
      'PO Number Provider for Supplier': int_rfs_impl_artwork_dev?.supplier_po_provider,
      'PO Number for Supplier': int_rfs_impl_artwork_dev?.supplier_po_number,
      'GFP Provided': int_rfs_impl_artwork_dev?.gfp_provided === true ? 'Completed' : 'NA',
      'GFP Approved': int_rfs_impl_artwork_dev?.gfp_approved,
      'Changes Made ': int_rfs_impl_artwork_dev?.changes_made,
      Status: status,
      'Step Deadline': sla_date,
      'Step Finished': status === 'Closed' ? finished_date : status,
    };
  };
  const csvArray = sort_Materials_acctoSKUs.map(dataToExport);

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader center size='lg' />
      </ContentPaper>
    );
  }

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <Box sx={sCodeStyles}>SKU CODES</Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <CSVLink
          data={csvArray}
          filename={'Artwork Development_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button leftIcon={<TableExport />} variant='gradient' radius='md' color='green'>
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      <Row>
        <Accordion defaultValue={'key_0'}>
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              const data = materials.filter((m) => m.scope_id === item.id);

              return (
                data.length > 0 && (
                  <Accordion.Item
                    className='scope-label'
                    key={`key_${name}_${index}`}
                    value={`key_${name}_${index}`}
                  >
                    <Accordion.Control>
                      {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                        item[secondaryAccessor[name]]
                      }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                    </Accordion.Control>
                    <Accordion.Panel>
                      {
                        <Grid>
                          <Grid.Col span={12}>
                            <ArtworkDevelopmentTable
                              data={data}
                              save={save}
                              details={details}
                              setDetails={setDetails}
                              setAttachmentModal={setAttachmentModal}
                              disable={disable}
                            />
                          </Grid.Col>
                        </Grid>
                      }
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            }),
          )}
        </Accordion>
      </Row>

      {attachmentModal.open && (
        <AttachmentsModal
          modal={attachmentModal}
          setModal={setAttachmentModal}
          onChange={handleFileSelect}
          onSubmit={handleAttachmentSubmit}
          onRemove={handleFileRemove}
          readOnly={attachmentModal.artwork_dev.gfp_approved === 'Approved'}
        />
      )}
    </ContentPaper>
  );
}
