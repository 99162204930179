import React, { createContext, useCallback, useState } from 'react';
import { axios } from '../utils/axios';

export const LiquidsContext = createContext();

export const LiquidsProvider = ({ children }) => {
  const [liquids, setLiquids] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(() => {
    axios.get('/liquids').then(
      (res) => {
        const response = res.data.data.liquids;

        setLiquids(response);
      },
      (error) => {},
    );
  }, [setLiquids]);

  return (
    <LiquidsContext.Provider
      value={{
        loading,
        setLoading,
        fetch,
        liquids,
      }}
    >
      {children}
    </LiquidsContext.Provider>
  );
};
