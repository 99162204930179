import React, { useContext, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Grid, Accordion, Switch, TextInput, Button, Text, Radio, Group } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { RfsContext } from '../../../contexts/RfsContext';
import './addRfsForm.css';
import { DisclaimerModal } from '../../rfs/liquid-bom/disclaimer';
import ArtworkModal from '../../modal/ArtworkModal';
import TechSpecModal from '../../modal/TechSpecModal';
import ContentPaper from '../../layouts/rfs/Content';
import { AuthContext } from '../../../contexts/AuthContext';

const accordionItemNames = {
  Innovation: 0,
  'Artwork Modification': 1,
  'New SKU (incl. promo SKU)': 2,
  'Open SKU to market': 3,
  'Article Modification/Value Engineering': 4,
  Delisting: 5,
  'Production Site': 6,
  'Re-activation (incl. promo SKU)': 7,
};

const Heading = ({ content }) => <h5 className='sectionHeadings'>{content}</h5>;

const AddRfsForm = () => {
  const { userName } = useContext(AuthContext);
  const { form, setForm, reset } = useContext(RfsContext);
  const [init, setInit] = useState(true);
  const [artModal, setArtModal] = useState(false);
  const [techModal, setTechModal] = useState(false);
  const [disclaimerModal, setDisclaimerModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [vis1, setVis1] = useState(true);
  const [vis2, setVis2] = useState(false);
  const { project_name, rfs_type, artwork_change, tech_change } = form.rfsInformation;

  const form1 = useForm({
    initialErrors: {
      project_name: 'Cannot be empty',
      rfs_type: 'Must be specified',
    },
    initialValues: {
      project_name: '',
      rfs_type: '',
    },
    validate: {
      project_name: (value) =>
        value.length === 0
          ? 'Required'
          : value.length > 100
          ? '100 characters limited exceeded'
          : null,
      rfs_type: (value) => (value.length === 0 ? 'Required' : null),
    },
  });

  useEffect(() => {
    reset();
  }, []);
  useEffect(() => {
    if (
      form?.rfsInformation.rfs_type === 'Open SKU to market' ||
      form?.rfsInformation.rfs_type === 'Delisting'
    ) {
      setVis1(false);
    } else {
      setVis1(true);
    }
  }, [form]);

  const handleNext = () => {
    setInit(false);
    form1.validate();
    if (
      Object.keys(form1.errors).length === 0 &&
      form1.values.project_name != '' &&
      form1.values.rfs_type != '' &&
      form1.values.project_name.length < 100
    )
      setDisclaimerModal(true);
  };

  const handleChange = (event) => {
    const currentValues = { ...form };
    currentValues.rfsInformation[event.target.name] =
      event.target.type === 'checkbox'
        ? event.target.checked
        : (event.target.value || '').trim().length === 0
        ? event.target.value.trim()
        : event.target.value;

    setForm(currentValues);
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) event.preventDefault();
    if (!event.target.checked) handleChange(event);

    if (['artwork_change'].includes(event.target.name) && event.target.checked && !artwork_change) {
      setArtModal(true);
    } else if (
      ['tech_change'].includes(event.target.name) &&
      event.target.checked &&
      !tech_change
    ) {
      setTechModal(true);
    }
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        <Link to='/'>
          <Button
            variant='gradient'
            color='dark'
            radius='md'
            size='md'
            fullWidth
            style={{ marginRight: '1rem', width: '200px' }}
          >
            {' '}
            Back{' '}
          </Button>
        </Link>
      </Col>

      <Col md='auto'>
        <Button
          className='next'
          onClick={handleNext}
          variant='gradient'
          radius='md'
          size='md'
          fullWidth
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
        >
          Next
        </Button>
      </Col>
    </Row>
  );

  return (
    <ContentPaper page={'add-new-rfs'} footer={<Footer />}>
      <Grid>
        <Grid.Col span={12}>
          <Heading className='page-add-newRfs-headings' content={'ADD PROJECT'} />
        </Grid.Col>

        <Grid.Col sm={12} md={5} span={5} offset={0.2}>
          <TextInput
            required
            error={!init && form1.errors.project_name}
            placeholder='Enter Project Name'
            label='Project Name'
            name='project_name'
            onChange={(event) => {
              form1.setFieldValue(
                'project_name',
                (event.currentTarget.value || '').trim().length === 0
                  ? event.currentTarget.value.trim()
                  : event.currentTarget.value,
              );
              handleChange(event);
            }}
          />
        </Grid.Col>

        <Grid.Col sm={12} md={5} offset={0.9}>
          <div className='disabledField'>
            <TextInput
              placeholder='Enter Name and Surname'
              label='Initiator Name '
              disabled
              value={userName}
            />
          </div>
        </Grid.Col>

        <Grid.Col span={12}>
          <RadioAccordions
            // value={rfs_type}
            init={init}
            errors={form1.errors.rfs_type}
            setForm={handleChange}
            form1={form1}
          />
        </Grid.Col>
      </Grid>

      {disclaimerModal && (
        <DisclaimerModal
          disclaimerModal={disclaimerModal}
          setDisclaimerModal={setDisclaimerModal}
        />
      )}
      {artModal && (
        <ArtworkModal opened={artModal} setOpened={setArtModal} confirm={handleChange} />
      )}
      {techModal && (
        <TechSpecModal opened={techModal} setOpened={setTechModal} confirm={handleChange} />
      )}
    </ContentPaper>
  );
};

const AccordionLabel = ({ value, setForm, selectedValue, form1 }) => {
  const isDisabled = ![
    'Article Modification/Value Engineering',
    'Open SKU to market',
    'Production Site',
    'Delisting',
    'Supplier Change',
  ].includes(value);

  return (
    <Group noWrap>
      <Radio
        className={`default-rfs-type  ${isDisabled ? 'rfs-type-disabled' : ''}`}
        value={value}
        name='rfs_type'
        onChange={(event) => {
          setForm(event);
          form1.setFieldValue('rfs_type', event.currentTarget.value);
        }}
        disabled={isDisabled}
      />
      <div>
        <Text>{value}</Text>
      </div>
    </Group>
  );
};

const AccordionItem = ({ items }) =>
  items.map((item, key) => (
    <Text key={`key_${key}`}>
      <strong>{item.title}:</strong> {item.description}
    </Text>
  ));

const RadioAccordions = ({ value, init, errors, setForm, form1 }) => (
  <Grid>
    <Grid.Col span={12}>
      <Heading
        className='page-add-newRfs-headings'
        content={'WHAT WOULD YOU LIKE TO DO? (CHOOSE ONE)'}
      />
      {!init && errors && (
        <Text color='red' size='sm' weight={400} style={{ marginRight: '29px' }}>
          Required Field
        </Text>
      )}
    </Grid.Col>

    <Grid.Col sm={12} md={11} lg={11} offset={0.2}>
      <Accordion
        iconPosition='right'
        classNames={{
          label: 'accordion-label padding-0',
          contentInner: 'accordion-innerContent',
          control: 'accordion-control',
        }}
        initialItem={accordionItemNames[value] ? accordionItemNames[value] : -1}
      >
        <Accordion.Item value='0'>
          <Accordion.Control>
            <AccordionLabel
              value={'Innovation'}
              selectedValue={value}
              setForm={setForm}
              form1={form1}
            />
          </Accordion.Control>
          <Accordion.Panel className='accordion-innerContent'>
            <AccordionItem
              items={[
                {
                  title: 'Explanation',
                  description:
                    'Everything that is new to the world: new liquid, new pack type, new container, etc.',
                },
                {
                  title: 'Example',
                  description: 'Intro of Bud new intro cans in NL',
                },
                {
                  title: 'Tool',
                  description: 'Pegasus',
                },
              ]}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='1'>
          <Accordion.Control>
            <AccordionLabel
              value={'Artwork Modification'}
              setForm={setForm}
              selectedValue={value}
              form1={form1}
            />
          </Accordion.Control>

          <Accordion.Panel className='accordion-innerContent'>
            <AccordionItem
              items={[
                {
                  title: 'Explanation',
                  description: 'Modify the Artwork of an existing SKU',
                },
                {
                  title: 'Example',
                  description: 'New regulations to be added on the packaging in France',
                },
                {
                  title: 'Tool',
                  description: 'Pegasus',
                },
              ]}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='2'>
          <Accordion.Control>
            <AccordionLabel
              value={'New SKU (incl. promo SKU)'}
              setForm={setForm}
              selectedValue={value}
              form1={form1}
            />
          </Accordion.Control>
          <Accordion.Panel className='accordion-innerContent'>
            <AccordionItem
              items={[
                {
                  title: 'Explanation',
                  description:
                    'Creation of a new (based on reference SKU) with Artwork Modification',
                },
                {
                  title: 'Example',
                  description: 'Corono 6pk with German design for Germany',
                },
                {
                  title: 'Tool',
                  description: 'Pegasus',
                },
              ]}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='3'>
          <Accordion.Control>
            <AccordionLabel
              value={'Open SKU to market'}
              setForm={setForm}
              selectedValue={value}
              form1={form1}
            />
          </Accordion.Control>
          <Accordion.Panel className='accordion-innerContent'>
            <AccordionItem
              items={[
                {
                  title: 'Explanation',
                  description:
                    'Introducing an existing SKU to a new market - no modification in Artwork, logistics configuration, bar codes, etc.',
                },
                {
                  title: 'Example',
                  description: 'Intro of existing Corona 6pk to a new market',
                },
              ]}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='4'>
          <Accordion.Control>
            <AccordionLabel
              value={'Article Modification/Value Engineering'}
              setForm={setForm}
              selectedValue={value}
              form1={form1}
            />
          </Accordion.Control>
          <Accordion.Panel className='accordion-innerContent'>
            <AccordionItem
              items={[
                {
                  title: 'Explanation',
                  description:
                    'Change of materials of an existing SKU (often logistics new configuration)',
                },
                {
                  title: 'Example',
                  description: 'Change the pallet of an existing SKU',
                },
              ]}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='8'>
          <Accordion.Control>
            <AccordionLabel
              value={'Supplier Change'}
              setForm={setForm}
              selectedValue={value}
              form1={form1}
            />
          </Accordion.Control>
          <Accordion.Panel className='accordion-innerContent'>
            <AccordionItem
              items={[
                {
                  title: 'Explanation',
                  description:
                    'Process of transfer of packaging materials between different suppliers.',
                },
                {
                  title: 'Example',
                  description:
                    'Implementation of dual sourcing for cans: Leffe blond 50cl can to be produced at Benepack and Ardagh OR switching a specific folding carton from GPI to Westrock',
                },
              ]}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='5'>
          <Accordion.Control>
            <AccordionLabel
              value={'Delisting'}
              setForm={setForm}
              selectedValue={value}
              form1={form1}
            />
          </Accordion.Control>
          <Accordion.Panel className='accordion-innerContent'>
            <AccordionItem
              items={[
                {
                  title: 'Explanation',
                  description: 'Eliminating an SKU from a portfolio',
                },
                {
                  title: 'Example',
                  description: 'France no longer wants to order 12pk of Corona',
                },
              ]}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='6'>
          <Accordion.Control>
            <AccordionLabel
              value={'Production Site'}
              setForm={setForm}
              selectedValue={value}
              form1={form1}
            />
          </Accordion.Control>
          <Accordion.Panel className='accordion-innerContent'>
            <AccordionItem
              items={[
                {
                  title: 'Explanation',
                  description: 'Transfer of production location of SKU',
                },
                {
                  title: 'Example',
                  description:
                    'Production of Stella Artois from Inhouse to co-fillers, Cross-brewing Projects',
                },
              ]}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='7'>
          <Accordion.Control>
            <AccordionLabel
              value={'Re-activation (incl. promo SKU)'}
              setForm={setForm}
              selectedValue={value}
              form1={form1}
            />
          </Accordion.Control>
          <Accordion.Panel className='accordion-innerContent'>
            <AccordionItem
              items={[
                {
                  title: 'Explanation',
                  description: 'Re-use a blocked SKU',
                },
                {
                  title: 'Example',
                  description: 'Yearly Euro Cup promo activation',
                },
                {
                  title: 'Tool',
                  description: 'RFFS',
                },
              ]}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Grid.Col>
  </Grid>
);

export default AddRfsForm;
