import React, { useState, useMemo } from 'react';
import ContentPaper from '../../components/layouts/rfs/Content';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Row, Col } from 'react-bootstrap';

import FtrReportComp from './ftrReportComp';

function FtrRFSreport() {
  const [validationErrors, setValidationErrors] = useState({}); //for Report
  const [queryClient] = React.useState(() => new QueryClient());

  //Report Data for New UI
  const columns = useMemo(
    () => [
      {
        accessorKey: 'rfsId',
        header: 'RFS ID',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'gatekeeper_name',
        header: 'Gatekeeper',
      },
      {
        accessorKey: 'name',
        header: 'Initiator Name',
      },
      {
        accessorKey: 'changedFields',
        header: 'Number of Fields PMO modified',
      },
      {
        accessorKey: 'totalFields',
        header: 'Total Fields',
      },
      {
        accessorKey: 'ftrPercent',
        header: 'Percentage of FTR',
      },
    ],
    [validationErrors],
  );

  return (
    <ContentPaper page='ftr-report'>
      <Row>
        <Col
          md={{ span: 6 }}
          style={{
            marginTop: '1rem',
            display: 'inline-flex',
            justifyContent: 'flex-start',
          }}
        ></Col>
      </Row>
      <div id='customReport'>
        {
          <QueryClientProvider client={queryClient}>
            <FtrReportComp
              columns={columns}
              setValidationErrors={setValidationErrors}
              validationErrors={validationErrors}
              unique={'rfsId'}
              enableEditing={false}
              type={'ftrRfs'}
            />
          </QueryClientProvider>
        }
      </div>
    </ContentPaper>
  );
}

export default FtrRFSreport;
