import React, { useContext, useEffect, useState } from 'react';
import ProjectOverview from './projectOverview';
import RfsLayout from '../../components/layouts/RfsLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { RfsContext } from '../../contexts/RfsContext';
import { ProjectOverviewContext } from '../../contexts/projectOverviewContext';
import { rfsStatus } from '../../constants';
import { routes } from '../../routes';
import ContentPaper from '../../components/layouts/rfs/Content';
import { AppLoader } from '../../components/common/loader';

function ProjectOverviewPage() {
  const { rfsNumber } = useParams();
  const navigate = useNavigate();
  const { info, reset, form, fetchTabs } = useContext(RfsContext);
  const [fetch, setFetched] = useState({});
  const [tabs, setTabs] = useState([]);
  const { rfs_number, rfs_type } = form.rfsInformation;
  const {
    scopeUnlockInfo,
    PsScopeUnlockInfo,
    getScopeUnlockInfo,
    getVeamScopeUnlockInfo,
    getPsScopeUnlockInfo,
    getScScopeUnlockInfo,
    scScopeUnlockInfo,
  } = useContext(ProjectOverviewContext);

  const fetchScopeUnlockInfo = {
    'Article Modification/Value Engineering': () => getVeamScopeUnlockInfo(rfsNumber),
    'Production Site': () => getPsScopeUnlockInfo(rfsNumber),
    'Supplier Change': () => getScScopeUnlockInfo(rfsNumber),
  };
  const defaultInfoCall = () => getScopeUnlockInfo(rfsNumber);

  useEffect(() => {
    if (rfsNumber) info(rfsNumber);

    return () => reset();
  }, [rfsNumber]);

  useEffect(() => {
    if (rfs_number) {
      getData(rfs_number);
    }
  }, [rfs_number]);

  useEffect(() => {
    if (rfs_type) {
      if (fetchScopeUnlockInfo[rfs_type]) {
        fetchScopeUnlockInfo[rfs_type]();
      } else {
        defaultInfoCall();
      }
    }
  }, [rfsNumber, rfs_type]);

  const getData = (rfs_number) => {
    fetchTabs(rfs_number).then((res) => {
      setTabs(res.data.data);
    });
  };

  if (form.rfsInformation.status && form.rfsInformation.status === rfsStatus.draft) {
    navigate(routes.submission(rfsNumber).review);
  }

  if (!rfs_type || rfs_type.length === 0)
    return (
      <RfsLayout>
        <ContentPaper>
          <AppLoader center size='lg' />
        </ContentPaper>
      </RfsLayout>
    );

  return (
    <RfsLayout
      aside={true}
      //   fetch.are_trials_actually_needed === 'Yes'
      //     ? activeTab === 2 || activeTab === 4
      //     : activeTab === 3
      // }
      trials={fetch.are_trials_actually_needed === 'Yes'}
      osku={rfs_type === 'Open SKU to market'}
    >
      <ProjectOverview
        fetch={fetch}
        setFetched={setFetched}
        rfsNumber={rfsNumber}
        scopeUnlockInfo={
          rfs_type === 'Production Site'
            ? PsScopeUnlockInfo
            : rfs_type === 'Supplier Change'
            ? scScopeUnlockInfo
            : scopeUnlockInfo
        }
        tabs={tabs}
      />
    </RfsLayout>
  );
}

export default ProjectOverviewPage;
