import React from 'react';
import { Modal, Button, Container, Grid, Group, Text } from '@mantine/core';

import './ToggleModal.css';
import { InfoCircle } from 'tabler-icons-react';

function RejectDraftModal({ opened, setOpened }) {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title='Unable to Submit Project'
      className='submit-modal'
    >
      <Container>
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='invalid-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <Text size='sm'>Comment is mandatory.</Text>
            </Group>
          </Grid.Col>

          <Grid.Col span={3} offset={9}>
            <Button variant='gradient' onClick={() => setOpened(false)} fullWidth>
              OK
            </Button>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
}

export default RejectDraftModal;
