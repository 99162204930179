import React, { useContext } from 'react';
import { Modal } from '@mantine/core';
import './Modal.css';
import { List } from './List';
import { RfsContext } from '../../../contexts/RfsContext';
import { AppLoader } from '../../common/loader';

export const BomModal = ({ bomModal, setBomModal, data }) => {
  const { loading, setLiquidBom, setSkuBom } = useContext(RfsContext);

  const title = {
    liquid: 'Liquid BOM',
    skuBom: 'SKU BOM',
    skuUom: 'SKU UOM',
    skuEan: 'SKU EAN',
  };

  return (
    <>
      <Modal
        className='bom-modal'
        overflow='inside'
        opened={bomModal.state}
        onClose={() => {
          setBomModal({ ...bomModal, state: false });
          setLiquidBom([]);
          setSkuBom([]);
        }}
        title={title[bomModal.type]}
      >
        {Object.keys(data).length > 0 && !loading && <List data={data} type={bomModal.type} />}

        {loading && (
          <div className='loader-container'>
            <AppLoader size={'md'} />
          </div>
        )}
      </Modal>
    </>
  );
};
