import MantineTable from '../../../../common/table/mantine';
import { useContext, useMemo } from 'react';
import moment from 'moment';
import { IconRefresh } from '@tabler/icons-react';
import { Button, Grid, Tabs } from '@mantine/core';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { Link } from 'react-router-dom';
import { routes } from '../../../../routes';
import { rfsStatus } from '../../../../constants';

export const Mother = ({ activeSubTab, loading, skus, fetch, activePlant, setActivePlant }) => {
  const { info } = useContext(CostingRequestsContext);
  const { producing_plant_data = [] } = info;

  const columns = useMemo(
    () => [
      {
        accessorKey: 'material_number',
        header: 'Mat No',
      },
      {
        accessorKey: 'material_description',
        header: 'Mat Desc',
      },

      {
        accessorKey: 'producing_plant',
        header: 'Production Plant',
      },
      {
        accessorKey: 'base_unit',
        header: 'Base Unit',
      },
      {
        accessorKey: 'uom',
        header: 'UOM',
      },
      {
        accessorKey: 'currency',
        header: 'Currency',
      },
      {
        accessorKey: 'cost_value',
        header: 'Cost Value',
      },
      {
        accessorKey: 'mother_costing_request_id',
        header: 'M. Comp Req ID',
        Cell: ({ row }) => {
          const { mother_costing_request_id, monther_cr_status  } = row.original;
          return mother_costing_request_id !== 'N/A' ? (
            
            <Link
              to={
                monther_cr_status  && ![rfsStatus.draft].includes(monther_cr_status.toLowerCase())
                  ? routes.costingRequests(mother_costing_request_id).overview
                  : routes.costingRequests(mother_costing_request_id).request
                }
                target="_blank"
            >
              {mother_costing_request_id}
            </Link>
            ) : (
              <span>{mother_costing_request_id}</span> 
          );
        },
      },
      {
        accessorKey: 'step_names',
        header: 'CR Steps In Progress',
        filterFn: 'contains',
        Cell: ({ row }) => {  
          const stepNames = row.original.GeneralDetails?.checkstepstatus
            ?.flatMap(step => step.IntCrSteps?.map(intCrStep => intCrStep.name))
            .join(', ') || 'N/A';
          const stepsInProgress = stepNames.replace(/, /g, '<br />');
          return <div dangerouslySetInnerHTML={{ __html: stepsInProgress }} />;
        },
      },
      {
        accessorKey: 'mother_costing_request_created_on',
        header: 'CR Created On',
      },
      {
        accessorKey: 'monther_cr_status',
        header: 'CR Status',
      },
      {
        accessorKey: 'fpna_analyst_responsible',
        header: 'FP&A GCC SME',
      },
    ],
    [skus],
  );

  const rightButtons = [
    {
      button: (
        <Button
          variant='light'
          color='gray.6'
          onClick={() => {
            fetch(true);
          }}
        >
          <IconRefresh color='black' variant='filled' size={16} />
        </Button>
      ),
    },
  ];

  return (
    <>
      {' '}
      <Tabs value={activePlant} onTabChange={(value) => setActivePlant(value)} color='yellow'>
        <Tabs.List>
          {producing_plant_data.map(({ producing_plant, id }, index) => (
            <Tabs.Tab value={producing_plant} key={index} className='menu-tabs'>
              {producing_plant}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {producing_plant_data.map(({ producing_plant, id }, index) => {
          return (
            <Tabs.Panel value={producing_plant} key={index}>
              <Grid>
                <Grid.Col span={12}>
                  <MantineTable
                    columns={columns}
                    initialData={(skus || []).filter(
                      (sku) => sku.asm && sku.producing_plant === producing_plant,
                    )}
                    unique={'sku'}
                    enableRowSelection={false}
                    loading={loading}
                    showSelectedToggle={false}
                    hideSelectColumn={true}
                    showResetAll={false}
                    enableExport={true}
                    initialState={{
                      sorting: [
                        {
                          id: 'material_description',
                          desc: false,
                        },
                      ],
                    }}
                    rightButtons={rightButtons}
                    csvFilename={`bom_component_price_monther_${moment().format('YYYY-MM-DD')}`}
                    pageSize={10}
                  />
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          );
        })}
      </Tabs>
    </>
  );
};
