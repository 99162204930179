import React, { useState, useEffect, useContext, useMemo } from 'react';
import ContentPaper from '../../components/layouts/rfs/Content';
import RfsLayout from '../../components/layouts/RfsLayout';
import { RfsContext } from '../../contexts/RfsContext';
import { SkuDetailsTable } from '../../components/tables/SKUDetailsTable/SkuDetailsTable';
import { axios } from '../../utils/axios';
import { Row, Col } from 'react-bootstrap';
import { AppLoader } from '../../components/common/loader';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import ReportCustomComp from '../../components/report/report';
import moment from 'moment';

function PortfolioReport() {
  const [validationErrors, setValidationErrors] = useState({}); //for Report
  const { form, toggle, fullscreen } = useContext(RfsContext);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  const handleExport = (rows, data) => {
    return data;
  };

  const getOverAllStatus = (dateGiven) => {
    let status = dateGiven.split('-')[0];
    let date = dateGiven.split('-')[1];

    var months = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    if (date === '') return 'N/A';

    var parts = date.split(/[\s:]+/);
    date = new Date(parseInt(parts[2]), months[parts[0]], parseInt(parts[1]));
    var todaysdate = moment();
    let dateDifference = -moment(date).diff(todaysdate, 'days');
    let dateDifference_ed = '';

    if (parts2 && parts2 !== '') {
      var parts2 = dateGiven.split('-')[2].split(/[\s:]+/);
      let date2 = new Date(parseInt(parts2[2]), months[parts2[0]], parseInt(parts2[1]));

      if (!date2) return 'N/A';

      dateDifference_ed = -moment(date).diff(date2, 'days');
    }

    return status === 'im' && dateDifference > 2 && dateDifference < 8
      ? 'On Time'
      : status === 'im' && dateDifference <= 2
      ? 'At Risk'
      : status === 'im' && dateDifference > 10
      ? 'Delayed'
      : status === 'ed' && dateDifference_ed < 10
      ? 'On time'
      : '';
  };

  const fetchData = () => {
    axios
      .get('/reports/portfolio')
      .then(
        (res) => {
          let response = res.data.data;
          setTableData(response);
        },
        (err) => {},
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Report Data for New UI
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Material_Number',
        header: `Material Number`,
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'Material_description',
        header: 'Material description',
      },
      {
        accessorKey: 'Brand',
        header: 'Brand',
      },
      {
        accessorKey: 'Subbrand',
        header: 'Subbrand',
      },
      {
        accessorKey: 'Market',
        header: 'Market',
      },
      {
        accessorKey: 'Bussiness_unit',
        header: 'Business unit',
      },
      {
        accessorKey: 'Filling_line',
        header: 'Filling line',
      },
      {
        accessorKey: 'Vol_percent',
        header: 'Vol percent',
      },
      {
        accessorKey: 'Plato',
        header: 'Plato',
      },
      {
        accessorKey: 'Shelf_Life',
        header: 'Shelf Life',
      },
      {
        accessorKey: 'Decomplexity_Status',
        header: 'Decomplexity Status',
      },
      {
        accessorKey: 'L1_Unit_of_Measure',
        header: 'L1 Unit of Measure',
      },
      {
        accessorKey: 'L1_Pack_Type',
        header: 'L1 Pack Type',
      },
      {
        accessorKey: 'L1_Content',
        header: 'L1 Content',
      },
      {
        accessorKey: 'L1_EAN_code',
        header: 'L1 EAN code',
      },
      {
        accessorKey: 'L1_Length',
        header: 'L1 Length',
      },
      {
        accessorKey: 'L1_Width',
        header: 'L1 Width',
      },
      {
        accessorKey: 'L1_Height',
        header: 'L1 Height',
      },
      {
        accessorKey: 'L1_GrossWeight',
        header: 'L1 GrossWeight',
      },
      {
        accessorKey: 'L2_Unit_of_Measure',
        header: 'L2 Unit of Measure',
      },

      {
        accessorKey: 'L2_EAN_Code',
        header: 'L2 EAN Code',
      },
      {
        accessorKey: 'L2_Length',
        header: 'L2 Length',
      },
      {
        accessorKey: 'L2_Width',
        header: 'L2 Width',
      },
      {
        accessorKey: 'L2_Height',
        header: 'L2 Height',
      },
      {
        accessorKey: 'L2_GrossWeight',
        header: 'L2 GrossWeight',
      },
      {
        accessorKey: 'L3_Unit_of_Measure',
        header: 'L3 Unit of Measure',
      },
      {
        accessorKey: 'L3_Content_per_SKU_L',
        header: 'L3 Content per SKU L',
      },
      {
        accessorKey: 'L3_EAN_Code',
        header: 'L3 EAN Code',
      },
      {
        accessorKey: 'L3_Length',
        header: 'L3 Length',
      },
      {
        accessorKey: 'L3_Width',
        header: 'L3 Width',
      },
      {
        accessorKey: 'L3_Height',
        header: 'L3 Height',
      },
      {
        accessorKey: 'L3_GrossWeight',
        header: 'L3 GrossWeight',
      },
      {
        accessorKey: 'L4_Unit_of_Measure',
        header: 'L4 Unit of Measure',
      },
      {
        accessorKey: 'L4_Content_per_Pallet_L',
        header: 'L4 Content per Pallet L',
      },
      {
        accessorKey: 'L4_EAN_Code',
        header: 'L4 EAN Code',
      },
      {
        accessorKey: 'L4_Length',
        header: 'L4 Length',
      },
      {
        accessorKey: 'L4_Width',
        header: 'L4 Width',
      },
      {
        accessorKey: 'L4_Height',
        header: 'L4 Height',
      },
      {
        accessorKey: 'L4_GrossWeight',
        header: 'L4 GrossWeight',
      },
      {
        accessorKey: 'PC_Layer',
        header: 'PC Layer',
      },
      {
        accessorKey: 'PC_Pallet',
        header: 'PC Pallet',
      },
      {
        accessorKey: 'Layer_Pallet',
        header: 'Layer Pallet',
      },
      {
        accessorKey: 'PC_Truck',
        header: 'PC Truck',
      },
    ],
    [validationErrors],
  );

  if (loading)
    return (
      <RfsLayout>
        <ContentPaper page='portfolio-report'>
          <AppLoader center size='md' />
        </ContentPaper>
      </RfsLayout>
    );

  return (
    <RfsLayout>
      <ContentPaper page='portfolio-report'>
        <Row>
          <Col
            md={{ span: 6 }}
            style={{
              marginTop: '1rem',
              display: 'inline-flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              size='sm'
              color='dark'
              width='2rem'
              style={{ paddingLeft: '20', paddingRight: '20' }}
              onClick={() => {
                navigate('/reports');
              }}
            >
              Back{' '}
            </Button>
            <h1
              className='sectionHeadings'
              style={{ paddingLeft: '10px', fontSize: '28px', color: 'black' }}
            >
              Portfolio Catalog Report
            </h1>
          </Col>
        </Row>

        <div id='customReport'>
          {
            <ReportCustomComp
              data={tableData}
              columns={columns}
              setValidationErrors={setValidationErrors}
              validationErrors={validationErrors}
              unique={'Material_Number'}
              enableEditing={false}
            />
          }
        </div>
      </ContentPaper>
    </RfsLayout>
  );
}

export default PortfolioReport;
