import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useTable } from 'react-table';
import { Badge, Button, Checkbox, Text } from '@mantine/core';
import { Styles } from '../../../components/scopeComp/tableStyles';
import { ProductionLog } from '../../veam/productDevelopment/productionSiteLogModal';
import { ActionModal } from '../../veam/productDevelopment/actionModal';

export const ReversalTable = ({ data, save, actionModal, setActionModal, loading, type }) => {
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);

  function getLogs(imp_id) {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  }

  const columns = useMemo(
    () => [
      {
        Header: 'New Markets',
        accessor: 'market',
        minWidth: '100px',
        Cell: ({ row }) => row.original.int_rfs_sku.market,
      },
      {
        Header: 'Status Change',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.status === 'In Progress'
                  ? 'yellow'
                  : row.original.status === 'Closed'
                  ? 'green'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.status}
            </Badge>
          );
        },
      },
      {
        Header: 'Action',
        Cell: ({ row }) => {
          return row.original.status === 'In Progress' || row.original.status === 'Not Started' ? (
            <Checkbox
              onChange={() => {
                setActionModal({ open: true, id: row.original.id });
              }}
              defaultChecked={false}
              label='Confirm and Close'
              disabled={row.original.status === 'Not Started'}
            />
          ) : row.original.manually_closed && row.original.status === 'Closed' ? (
            <Text>Manual Closure</Text>
          ) : (
            <Text>Automatic Closure</Text>
          );
        },
      },
      {
        Header: 'Logs',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => getLogs(row.original.id)}
              style={{ background: 'black', color: 'white' }}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('YYYY-MM-DD')
                : row.original.status}
            </Text>
          );
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns: columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div>
      <Styles>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      width: column.width ? column.width : 'unset',
                      minWidth: column.minWidth ? column.minWidth : 'unset',
                      maxWidth: column.maxWidth ? column.maxWidth : 'unset',
                      textAlign: column?.columns?.length > 0 ? 'center' : 'unset',
                    }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Styles>

      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={actionModal.id}
          updateLoading={loading}
        />
      )}

      {logModal && (
        <ProductionLog logModal={logModal} id={imp_id} setLogModal={setLogModal} type={type} />
      )}
    </div>
  );
};
