import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import MantineTable from '../../../../common/table/mantine';
import { implementationColumns } from '../../../../common/table/template/implementation';
import { CommonColumnsContext } from '../../../../contexts/CommonColumns';
import { DownloadExcel } from '../../../../common/excel';
import { Button } from '@mantine/core';
import { ImpactedSkusPreview } from '../../../../components/supplierChange/impactedSkusPreview';
import { RfsContext } from '../../../../contexts/RfsContext';
import { roles } from '../../../../constants';
import { AccfComment } from '../../../../common/accf';

const SLA_WEEK_ID = 334;

export const Changeover = ({ permissions, userRole }) => {
  const { rfsNumber } = useParams();
  const {
    form: { scImpactedSkus = [] },
  } = useContext(RfsContext);
  const { setLogsModal, setActionModal } = useContext(CommonColumnsContext);
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [impactedSkus, setImpactedSkus] = useState({
    open: false,
    skus: [],
    material_id: null,
    material_code: null,
  });
  const [disableAccf, setDisableAccf] = useState(true);

  const readOnly = !permissions.includes(userRole);

  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo, roles.masterDataTeam].includes(userRole))
      setDisableAccf(false);
  }, [userRole]);

  const fetch = useCallback(
    (loading = true) => {
      setLoading(loading);
      axios
        .get(`/supplier-changes/changeover/${rfsNumber}`)
        .then(
          (res) => {
            const data = res.data.data;
            setMaterials(data);
          },
          (err) => {
            console.log(err);
          },
        )
        .finally(() => setLoading(false));
    },
    [rfsNumber],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  let columns = useMemo(
    () => [
      {
        accessorKey: 'int_rfs_sc_material.material_code',
        header: 'Material Code',
      },
      {
        accessorKey: 'int_rfs_sc_material.material_description',
        header: 'Material Description',
      },
      {
        accessorKey: 'new_material_code',
        header: 'New Material Code',
        Cell: ({
          row: {
            original: { int_rfs_impl_material_creation },
          },
        }) =>
          int_rfs_impl_material_creation?.new_material_code
            ? int_rfs_impl_material_creation?.new_material_code
            : 'N/A',
      },
      {
        accessorKey: 'new_material_desc',
        header: 'New Material Description',
        Cell: ({
          row: {
            original: { int_rfs_impl_material_creation },
          },
        }) =>
          int_rfs_impl_material_creation?.new_material_desc
            ? int_rfs_impl_material_creation?.new_material_desc
            : 'N/A',
      },
      {
        accessorKey: 'int_rfs_sc_material.changeover_type',
        header: 'Changeover Type',
      },
      {
        accessorKey: 'int_rfs_sc_material.changeover_date',
        header: 'Changeover Date',
      },
      {
        accessorKey: 'int_rfs_sc_material.comment',
        header: 'Comments',
      },
      {
        id: 'impacted_skus',
        header: 'Impacted SKUs',
        Cell: ({ row }) => {
          return (
            <Button
              color='dark'
              fullWidth
              size='sm'
              radius='sm'
              className='add-supplier-button'
              onClick={() =>
                setImpactedSkus({
                  open: true,
                  material_id: row.original.material_uid,
                  material_code: row.original.int_rfs_sc_material.material_code,
                })
              }
            >
              Impacted SKUs
            </Button>
          );
        },
      },
    ],
    [form],
  );

  columns = columns.concat(
    implementationColumns(
      setLogsModal,
      setActionModal,
      {
        title: 'Changeover',
        fetchInfo: fetch,
      },
      readOnly,
    ),
  );

  const isInProgress = () => {
    const index = materials.findIndex((material) => material.status === 'In Progress');

    return index > -1;
  };

  return (
    <div>
      <DownloadExcel
        data={materials.map(
          ({
            int_rfs_sc_material,
            int_rfs_impl_material_creation,
            status,
            sla_date,
            finished_date,
            manually_closed,
          }) => {
            return {
              'Material Code': int_rfs_sc_material.material_code,
              'Material Description': int_rfs_sc_material.material_description,
              'New Material Code': int_rfs_impl_material_creation?.new_material_code || '',
              'New Material Description': int_rfs_impl_material_creation?.new_material_desc || '',
              'Changeover Type': int_rfs_sc_material?.changeover_type || '',
              'Changeover Date': int_rfs_sc_material?.changeover_date || '',
              Comments: int_rfs_sc_material?.comment || '',
              Status: status,
              Action:
                status !== 'Closed' ? '' : manually_closed ? 'Manual Closure' : 'Automatic Closure',
              'Step Deadline': moment(sla_date).format('DD-MM-YYYY'),
              'Step Finished': finished_date
                ? moment(finished_date).format('DD-MM-YYYY')
                : 'Not Finished',
            };
          },
        )}
        filename='Changeover'
      />
      <MantineTable
        columns={columns}
        initialData={materials}
        unique={'id'}
        enableRowSelection={false}
        loading={loading}
        editAll={false}
        showSelectedToggle={false}
        showResetAll={false}
        hideSelectColumn={true}
      />
      {impactedSkus.open && (
        <ImpactedSkusPreview
          material_code={impactedSkus.material_code}
          skus={(scImpactedSkus || []).filter(
            (sku) => sku.material_id === impactedSkus.material_id,
          )}
          open={impactedSkus.open}
          close={() => setImpactedSkus({ open: false, material_id: null })}
        />
      )}
      {!disableAccf && (
        <div>
          <br />
          <AccfComment
            slaId={materials?.[0]?.sla_weeks_id || SLA_WEEK_ID}
            status={!isInProgress()}
            rfs_number={rfsNumber}
          />
        </div>
      )}
    </div>
  );
};
