import Info from '../pmo/info';
import { Col, Row } from 'react-bootstrap';
import { Select, Text, SimpleGrid, Textarea, TextInput } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { RfsContext } from '../../../../contexts/RfsContext';
import { useParams } from 'react-router-dom';
import { NpsIds, rfsStatus } from '../../../../constants';
import SubmitProjectModal from '../../../../components/modal/SubmitProjectModal';
import RejectDraftModal from '../../../../components/modal/RejectDraftModal';
import { NpsComponent } from '../../../../common/nps';
import TechFeasibilityModal from '../../../../components/modal/techFeasibilityModal/modal';
import { DatePickerInput } from '@mantine/dates';
import { CalendarIcon } from '@radix-ui/react-icons';
import moment from 'moment';
import { MaterialTable } from './table';

export const PmoFinal = (props) => {
  const {
    tfModal,
    setTfModal,
    npsOpened,
    setNpsOpened,
    techFeasibility,
    validate,
    submitProjectModal,
    setSubmitProjectModal,
    rejectDraftModal,
    setRejectDraftModalModal,
    pmo_comment_length,
    handleTechChange,
    firstShipmentPmo,
    setFirstShipmentPmo,
    fastTrackPmo,
    setFastTrackPmo,
    readOnly,
  } = props;

  const { rfsNumber } = useParams();
  const { form } = useContext(RfsContext);
  const { status, fast_track, scImpactedSkusRequired } = form.rfsInformation;
  const { scImpactedSkus = [] } = form;

  return (
    <div>
      <Info isSubmissionReview technicalFeasibility pmoApproval initialItems={0} {...props} />

      <div>
        <Row>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col
            md={1}
            style={{
              width: '2.0833%',
              flex: '0 0 2.0833%',
              maxWidth: ' 2.0833%',
            }}
          ></Col>
          <Col
            md={{ span: 3 }}
            style={{
              marginTop: '1rem',
            }}
          >
            <h5 className='sectionHeadings'>PMO Section</h5>
          </Col>
        </Row>

        {scImpactedSkusRequired && (
          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>
            <Col
              md={{ span: 11 }}
              style={{
                marginTop: '0.5rem',
              }}
            >
              <Text
                size='sm'
                weight={600}
                color={validate && scImpactedSkus.length === 0 ? 'red' : ''}
                style={{ marginBottom: '0.5rem' }}
              >
                Select impacted SKUs <span style={{ color: 'red', fontWeight: '400' }}>*</span>
              </Text>
              <MaterialTable readOnly={status !== rfsStatus.supplier_pmo2_active} />
            </Col>
          </Row>
        )}

        {fast_track ? (
          <Row style={{ marginTop: '1rem' }}>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>
            <Col md={{ span: 5, offset: 0 }}>
              <SimpleGrid cols={2}>
                <Text size='sm' weight={600}>
                  Fast Track:
                </Text>
                <Select
                  disabled={
                    readOnly ||
                    !fast_track ||
                    !(status === rfsStatus.supplier_pmo2_active && status !== rfsStatus.draft)
                  }
                  required
                  radius='md'
                  data={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                  ]}
                  name='fastTrackPmo'
                  value={fastTrackPmo}
                  onChange={(value) => setFastTrackPmo(value)}
                />
              </SimpleGrid>
            </Col>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            {fastTrackPmo === 'Yes' ? null : (
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2}>
                  <Text size='sm' weight={600}>
                    First Shipment Date:
                  </Text>
                  <FastTrackDatePicker
                    value={firstShipmentPmo || new Date()}
                    onChange={(e) => {
                      setFirstShipmentPmo(e);
                    }}
                    placeholder='Pick date'
                    name='firstShipmentPmo'
                    fast_track={fast_track}
                    fastTrackPmo={fastTrackPmo === 'Yes'}
                    disabled={
                      readOnly ||
                      !(status === rfsStatus.supplier_pmo2_active && status !== rfsStatus.draft)
                    }
                  />
                </SimpleGrid>
              </Col>
            )}
          </Row>
        ) : null}

        <Row style={{ marginTop: '1rem' }}>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>

          <Col md={{ span: 2 }}>
            <Text size='sm' weight={600}>
              PMO Comments: <span style={{ color: 'red', fontWeight: '400' }}>*</span>
            </Text>
          </Col>

          <Col md={{ span: 9 }} style={{ marginBottom: '1rem' }}>
            <>
              {status === rfsStatus.supplier_pmo2_active &&
              status !== rfsStatus.draft &&
              !readOnly ? (
                <Textarea
                  error={pmo_comment_length === 0 || (validate && !techFeasibility.pmo_comments)}
                  placeholder='PMO Comments (Character limit 500)'
                  disabled={readOnly}
                  name='pmo_comments'
                  value={techFeasibility.pmo_comments}
                  autosize
                  minRows={4}
                  onChange={(event) => handleTechChange(event)}
                />
              ) : (
                <Text size='sm'>{techFeasibility.pmo_comments}</Text>
              )}
              {techFeasibility.pmo_comments?.length > 499 && (
                <div className='custom-mantine-Textarea-error'>
                  500 Character limit reached, exceeding character would be truncated on saving.
                </div>
              )}
            </>
          </Col>
        </Row>
      </div>

      {tfModal && <TechFeasibilityModal opened={tfModal} setOpened={setTfModal} />}

      {submitProjectModal && (
        <SubmitProjectModal opened={submitProjectModal} setOpened={setSubmitProjectModal} />
      )}
      {rejectDraftModal && (
        <RejectDraftModal opened={rejectDraftModal} setOpened={setRejectDraftModalModal} />
      )}

      {npsOpened && !readOnly && (
        <NpsComponent
          rfs_number={rfsNumber}
          opened={npsOpened}
          setOpened={setNpsOpened}
          callback={() => {
            setNpsOpened(false);
          }}
          id={NpsIds().techFeasibility}
        />
      )}
    </div>
  );
};

const FastTrackDatePicker = ({
  value,
  disabled,
  fast_track,
  onChange,
  placeholder,
  name,
  fastTrackPmo,
}) => {
  const [initialMonth, setIntialMonth] = useState(moment().add('days', 1).toDate());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      if (fastTrackPmo) {
        setIntialMonth(moment().add('days', 1).toDate());
      } else {
        setIntialMonth(moment().add('days', 77).toDate());
      }

      setLoading(false);
    }, [50]);
  }, [fastTrackPmo]);

  if (loading)
    return (
      <TextInput
        icon={<CalendarIcon color='#e5b611' />}
        required
        radius='md'
        placeholder={fast_track ? '' : 'Select dates after 11 weeks from today.'}
      />
    );

  return (
    <DatePickerInput
      required
      placeholder={fast_track ? '' : 'Select dates after 11 weeks from today.'}
      amountOfMonths={2}
      minDate={initialMonth}
      initialMonth={initialMonth}
      radius='md'
      icon={<CalendarIcon color='#e5b611' />}
      disabled={disabled}
      value={value}
      onChange={(value) => onChange(value)}
    />
  );
};
