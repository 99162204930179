import React, { useContext, useEffect, useState } from 'react';
import { NpsEnv, NpsProductId, NpsTitle } from '../../constants';
import { AuthContext } from '../../contexts/AuthContext';
import { ProductFeedback } from '@abi-ds-beerblocs/product-feedback-module';

export const NpsComponent = ({
  opened,
  setOpened,
  rfsNumber,
  callback,
  id = 'general-feedback',
}) => {
  const { userEmail, userRole, fetchNpsToken } = useContext(AuthContext);
  const [token, setToken] = useState(null);
  let interval;
  var element = [];

  useEffect(() => {
    setToken(null);
    fetchNpsToken().then(
      (response) => {
        setToken(response);
      },
      (error) => {
        setToken(null);
      },
    );
  }, []);

  useEffect(() => {
    interval = setInterval(() => {
      element = document.getElementsByClassName('golden-text');
      if (element.length === 0 && opened && token) {
        setOpened(false);
        clearInterval(interval);
        callback();
      }
    }, [2500]);
  }, [token]);

  return (
    <div>
      {token && (
        <ProductFeedback
          titleLabel={NpsTitle}
          env={NpsEnv()}
          apiKey={''}
          token={token}
          productId={NpsProductId()}
          productActivityId={id}
          modalOpened={opened}
          setModalOpened={setOpened}
          feedbackMetaData={{
            email: userEmail,
            role: userRole,
            rfs_number: rfsNumber,
          }}
        />
      )}
    </div>
  );
};
