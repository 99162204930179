import React, { createContext } from 'react';
import { axios } from '../../utils/axios';

export const VeamContext = createContext();

export const VEAMProvider = ({ children }) => {

  const fetchAllSteps = (rfs_number) => {
    return axios.get(`veam/get_all_step_status/${rfs_number}`);
  };

  return (
    <VeamContext.Provider
      value={{
        fetchAllSteps
      }}
    >
      {children}
    </VeamContext.Provider>
  );
};