import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Space, Tabs } from '@mantine/core';
import { Col, Row } from 'react-bootstrap';

import ContentPaper from '../../../../components/layouts/rfs/Content';
import { tabStyles } from '../../../../utils/helpers';
import { CostComponent } from '../vic/cost';
import { BomComponent } from '../vic/bom';
import { ProjectOverview } from '../projectOverview';
import { Context, Provider } from '../vic/context';
import { RejectionModal } from '../vicApproval/rejectModal';
import { AppLoader } from '../../../../components/common/loader';

const tabs = [
  {
    name: 'CONSOLIDATED COST COMPONENT',
    value: 'cost',
    component: (props) => <CostComponent {...props} />,
  },

  {
    name: 'BOM LEVEL VIEW',
    value: 'bom',
    component: (props) => <BomComponent {...props} />,
  },
];

const VicContent = ({ activeTab }) => {
  const { resetAttachmentsModal, stepStatus, readOnly, onVicApprove, setStepStatus } =
    useContext(Context);
  const [activePlant, setActivePlant] = useState({ producing_plant: null, id: null });
  const [rejectModal, setRejectModal] = useState(false);
  const [approvalLoader, setApprovalLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeSubTab = searchParams.get('subtab') || 'cost';
  const { info: vicInfo } = useContext(Context);

  useEffect(() => {
    resetAttachmentsModal();
  }, [activeSubTab]);

  const handleSubmit = async () => {
    try {
      setApprovalLoader(true);
      await onVicApprove(activePlant.producing_plant);
    } catch (e) {
      console.log(e);
    } finally {
      setApprovalLoader(false);
      setStepStatus('Closed');
    }
  };
  const handleRefresh = () => {
    vicInfo(false, activePlant, true, 'bom_steps');
  };
  const Footer = () => (
    <Row className='justify-content-md-end' md={2}>
      <Col md='auto'>
        <Button
          className='next btn btn-danger'
          onClick={() => setRejectModal(true)}
          variant='gradient'
          color='red'
          radius='md'
          size='md'
          fullWidth
          disabled={(stepStatus || '').toLowerCase() !== 'in progress' || readOnly}
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
        >
          Reject
        </Button>
      </Col>
      <Col md='auto'>
        <Button
          className='next btn btn-success'
          onClick={handleSubmit}
          variant='gradient'
          radius='md'
          color='green'
          size='md'
          fullWidth
          disabled={
            (stepStatus || '').toLowerCase() !== 'in progress' || readOnly || approvalLoader
          }
          style={{
            marginRight: '0.5rem',
            width: '200px',
          }}
        >
          {approvalLoader ? <AppLoader size='sm' /> : 'Approve'}
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <ContentPaper footer={<Footer />}>
        <ProjectOverview />
        <Space h='sm' />
        <Tabs
          keepMounted={false}
          value={activeSubTab}
          onTabChange={(value) => setSearchParams({ tab: activeTab, subtab: value })}
          variant='unstyled'
          styles={tabStyles}
          className='prod-dev-tabs'
          color='#e5b611'
        >
          <Tabs.List>
            {tabs.map((tab, index) => (
              <Tabs.Tab value={tab.value} key={index} className='menu-tabs'>
                {tab.name}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {tabs.map((tab, index) => (
            <Tabs.Panel value={tab.value} key={index}>
              {tab.component({
                activeSubTab,
                activeTab,
                activePlant,
                setActivePlant,
                setStepStatus,
              })}
            </Tabs.Panel>
          ))}
        </Tabs>

        {rejectModal && (
          <RejectionModal
            activePlant={activePlant}
            modal={rejectModal}
            setModal={setRejectModal}
            handleRefresh={handleRefresh}
            setStepStatus={setStepStatus}
          />
        )}
      </ContentPaper>
    </>
  );
};

const VicApproval = (props) => (
  <Provider>
    <VicContent {...props} />
  </Provider>
);

export default VicApproval;
