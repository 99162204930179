import React, { useEffect, forwardRef, useRef } from 'react';

export const filterStartsWith = (rows, id, filterValue) => {
  return rows.filter((row) => {
    if (id[0] === 'fast_track') {
      const map = {
        true: 'Applied',
        false: 'Not Applied',
      };
      return (
        row.original[id[0]] &&
        map[row.original[id[0]]].toLowerCase().startsWith(filterValue.toLowerCase())
      );
    } else {
      return (
        row.original[id[0]] &&
        row.original[id[0]].toLowerCase().startsWith(filterValue.toLowerCase())
      );
    }
  });
};

export const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <div className='input-contain'>
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search`}
        id='fname'
      />
      <label className='placeholder-text' for='fname' id='placeholder-fname'></label>
    </div>
  );
};

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, handleCheckboxClick, id, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type='checkbox' className='table-checkbox' ref={resolvedRef} {...rest} onClick={handleCheckboxClick} id={id} />
      </>
    );
  },
);

export const checkboxColumn = (noSelectAll, handleCheckboxClick) => ({
  id: 'selection',
  disableResizing: true,
  minWidth: 35,
  width: 35,
  maxWidth: 35,
  sticky: 'left',
  disableSortBy: true,
  canFilter: false,
  Header: ({ getToggleAllRowsSelectedProps }) =>
    noSelectAll ? null : (
      <div style={{ paddingLeft: '0.7rem' }} className='select-all-checkbox'>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
  Cell: ({ row }) => {
    return (
      <div>
        <IndeterminateCheckbox
          handleCheckboxClick={() => handleCheckboxClick(row)}
          {...row.getToggleRowSelectedProps()}
          id={row.index}
        />
      </div>
    );
  },
});
