import React, { useState, useContext, useEffect } from 'react';
import { Checkbox, Textarea } from '@mantine/core';
import { Button } from '@mantine/core';
import { RfsContext } from '../../../contexts/RfsContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { axios } from '../../../utils/axios';
import { routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { FeedbackModal } from '../../modal/FeedbackModal';
import { SubmissionTrueModal } from '../../modal/SubmissionTrueModal';
import { AppLoader } from '../../common/loader';

import { NpsComponent } from '../../../common/nps';
import { NpsIds, roles } from '../../../constants';

export default function FeedbackFormTableVEAM() {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const { userName, userEmail } = useContext(AuthContext);
  const rfs = params.rfsNumber;
  const [getData, setgetData] = useState([]);
  const [data, setData] = useState([]);
  const [err_found, setErr_found] = useState(false);
  const [SubmissionSucess, setSubmissionSucess] = useState(false);
  const { form } = useContext(RfsContext);

  const [req, setReq] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [npsOpened, setNpsOpened] = useState(false);

  const checkboxes = (team, response) => {
    return {
      all_okay: response?.length > 0 ? getItem(team, response)?.all_okay : true,
      //team_to_feedback: team,
      instructions_not_clear:
        response?.length > 0 ? getItem(team, response)?.instructions_not_clear : false,
      scope_modified: response?.length > 0 ? getItem(team, response)?.scope_modified : false,
      not_correct_input: response?.length > 0 ? getItem(team, response)?.not_correct_input : false,
      others: response?.length > 0 ? getItem(team, response)?.others : false,
      comment: response?.length > 0 ? getItem(team, response)?.comment : '',
    };
  };

  useEffect(() => {
    axios
      .get('/feedback/f/get', {
        params: { rfsNumber: `${rfs}`, useremail: `${userEmail}` },
      })
      .then(
        (res) => {
          const response = res.data.data;

          setgetData(response);

          const data_arr_keys_veam = [
            roles.pmo,
            'masterdata',
            roles.procurement,
            roles.mrp,
            roles.scheduling,
            roles.zitec,
            roles.d2p,
          ];

          let data_arr = {};
          data_arr_keys_veam.forEach((key) => {
            data_arr[key] = checkboxes(key, response);
          });

          setData([
            {
              rfs_number: rfs,
              user: userName,
              data_arr,
            },
          ]);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(setLoading(false));
  }, []);

  function getItem(team, response) {
    return response?.find((item) => item.team_to_feedback === team);
  }

  const OSKU = () => form.rfsInformation.rfs_type === 'Open SKU to market';
  const PS = () => form.rfsInformation.rfs_type === 'Production Site';

  const handleChange = (event, index, team) => {
    const values = [...data];
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (event.target.type === 'checkbox') {
      values[index]['data_arr'][team] = getCheckboxValues(
        { ...values[index]['data_arr'][team] },
        event.target.name,
        value,
      );
    } else {
      values[index]['data_arr'][team][event.target.name] = value;
    }

    setData(values);
  };

  const getCheckboxValues = (currentValues, name, value) => {
    if (name === 'all_okay') {
      Object.keys(currentValues).forEach((key) => {
        if (key !== 'comment') {
          currentValues[key] = key !== 'all_okay' ? false : value;
        }
      });
    } else {
      Object.keys(currentValues).forEach((key) => {
        currentValues[key] = key === 'all_okay' ? false : name === key ? value : currentValues[key];
      });
    }

    return currentValues;
  };

  const validateBlanks = (payload, e) => {
    let isEmpty,
      arr = [];
    for (let item in payload[0].data_arr) {
      const dataItem = payload[0].data_arr[item];
      let a;
      if (dataItem.team_to_feedbak) {
        a = dataItem.team_to_feedbak;
        delete dataItem.team_to_feedbak;
      }
      isEmpty = !Object.values(dataItem).some((x) => x !== null && x !== false && x !== '');

      if (isEmpty) arr.push(item);
      if (a) dataItem['team_to_feedbak'] = a;
    }
    setReq(arr);
    arr.length ? setDisableSubmit(true) : setDisableSubmit(false);
    return isEmpty ? false : true;
  };

  const handleNext = (payload, callback) => {
    let isValid = true;

    for (let item in payload[0].data_arr) {
      const dataItem = payload[0].data_arr[item];
      if (dataItem?.others && (dataItem?.comment || '').trim().length === 0) {
        isValid = false;
        break;
      }
    }

    if (isValid) {
      Object.values(payload[0].data_arr).forEach((item, index) => {
        return (item['team_to_feedback'] = Object.keys(payload[0].data_arr)[index]);
      });
      payload[0]['email'] = userEmail;

      axios.post(`/feedback/f`, payload[0]).then(
        (res) => {
          setSubmissionSucess(true);
          setTimeout(() => {
            setSubmissionSucess(false);
            setNpsOpened(true);
          }, 2000);
        },
        (err) => {
          console.log(err);
        },
      );
    } else {
      setErr_found(true);
    }
  };

  const Columns = [
    { label: 'pmo', value: roles.pmo },
    { label: 'masterdata', value: 'masterdata' },
    { label: 'procurement', value: roles.procurement },
    { label: 'mrp', value: roles.mrp },
    { label: 'scheduling', value: roles.scheduling },
    { label: 'zitec', value: roles.zitec },
    { label: 'd2p', value: roles.d2p },
  ];

  const rows = Columns.map((column) => ({
    step_id: column.value,
    name: <b>{column.label.toUpperCase().replace('_', ' ')}</b>,
    render: (item, index, active) => (
      <>
        <div className={column.label}>
          {Object.entries(checkboxes(column.value, item)).map(([checkbox, val]) => {
            if (['team', 'comment'].includes(checkbox)) {
              return <></>;
            }

            return (
              <Checkbox
                style={{ padding: '0.2rem' }}
                label={checkbox.toUpperCase().replace('_', ' ')}
                name={checkbox}
                checked={data[index]['data_arr'][column.value][checkbox]}
                onChange={(event) => {
                  handleChange(event, index, column.value);
                  validateBlanks(data, event);
                }}
              />
            );
          })}
          {req.includes(column.value) && (
            <div style={{ color: 'red', fontStyle: 'italic', fontSize: '12px', marginTop: '8px' }}>
              Please choose at least one option
            </div>
          )}
        </div>
      </>
    ),
    comment: (item, index, active) => {
      const dataItem = data[0]['data_arr'][column.value];

      return (
        <>
          <div className={column.label}>
            <Textarea
              style={{ padding: '1rem' }}
              placeholder='Your comment (Max limit 500)'
              name={'comment'}
              radius='md'
              value={dataItem?.comment}
              error={
                ((!dataItem?.comment || (dataItem?.comment || '').length === 0) &&
                  dataItem['others']) ||
                ((dataItem?.comment || '').length > 249 ? 'Limit Reached (Max Length: 250)' : null)
              }
              maxLength={250}
              onChange={(event) => handleChange(event, index, column.value)}
            />
          </div>
        </>
      );
    },
  }));

  return loading ? (
    <AppLoader center size='lg' />
  ) : (
    <>
      {rows.map(({ name, render, comment }, index) => (
        <tr key={index}>
          <td
            colSpan={2}
            style={{
              position: 'sticky',
              backgroundColor: '#cdcdcd',
              width: '25%',
              padding: '15px',
              maxWidth: '25%',
              minWidth: '25%',
            }}
          >
            {name}
          </td>
          {(data || []).map((item, i) => (
            <>
              <td style={{ borderRight: '1px solid #dee2e6' }}>{render(item, i)}</td>
              <td style={{ borderRight: '1px solid #dee2e6' }}>{comment(item, i)}</td>
            </>
          ))}
        </tr>
      ))}
      <tr>
        <td
          colSpan={2}
          style={{
            position: 'sticky',
            width: '25%',
            padding: '15px',
            maxWidth: '25%',
            minWidth: '25%',
          }}
        ></td>
        <td></td>
        <td
          style={{
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={() => handleNext(data)}
            variant='gradient'
            size='md'
            style={{
              paddingLeft: 30,
              paddingRight: 30,
            }}
            disabled={disableSubmit}
          >
            Submit
          </Button>
        </td>
      </tr>
      {err_found && <FeedbackModal open={err_found} setOpen={setErr_found} />}
      {SubmissionSucess && (
        <SubmissionTrueModal open={SubmissionSucess} setOpen={setSubmissionSucess} />
      )}

      {npsOpened && (
        <NpsComponent
          rfs_number={params.rfsNumber}
          opened={npsOpened}
          setOpened={setNpsOpened}
          callback={() => {
            window.location.reload();
          }}
          id={NpsIds().ftr}
        />
      )}
    </>
  );
}
