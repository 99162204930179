import React from 'react';
import RfsLayout from '../../components/layouts/RfsLayout';
import { AllRfsTable } from '../../components/allrfs';

export default function AllRfsPage(props) {
  return (
    <RfsLayout>
      <AllRfsTable {...props} />
    </RfsLayout>
  );
}
