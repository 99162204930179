import React from 'react';
import { useTable, usePagination } from 'react-table';
import styled from 'styled-components';
import { Styles } from '../../../components/tables/skutable/production-site/styles';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { axios } from '../../../utils/axios';
import { Button } from '@mantine/core';

const Table = styled.table`
  width: fit-content;
`;

const ImpactedSkusTable = ({ data = [] }) => {
  // const [impactedSkus, setimpactedSkus] = useState([])

  // const params = useParams();
  //   const { rfsNumber } = params;
  //   console.log(params)

  //   useEffect (() => {
  //     axios.get(`/delisting/packmats/${rfsNumber}`).then (
  //       (res) => {
  //         console.log(rfsNumber)
  //         console.log(res.data.impacted_materials)
  //         // setimpactedSkus(res.data.data)
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //       );
  //     }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: 'SKU Code',
        accessor: 'SKUs',
      },
      {
        Header: 'SKU Description',
        accessor: 'SKUDescription',
      },
      {
        Header: 'ALT Bom',
        accessor: 'AlternativeBOM',
      },
      {
        Header: 'Plant',
        accessor: 'Plant',
      },
      {
        Header: 'Component Quantity',
        accessor: 'ComponentQty',
      },
      {
        Header: 'UOM',
        accessor: 'UoM',
      },
      {
        Header: 'Markets',
        accessor: 'Markets',
        Cell: ({ row }) => {
          const Markets = row.original.Markets.split(',').map((Markets) => Markets.trim());
          return Markets.map((Markets, index) => <div key={index}>{Markets}</div>);
        },
      },
      {
        Header: 'EAN Number',
        accessor: 'EANNumbers',
        Cell: ({ row }) => {
          const EANNumbers = row.original.EANNumbers.split(';').map((EANNumbers) =>
            EANNumbers.trim(),
          );
          return EANNumbers.map((EANNumbers, index) => <div key={index}>{EANNumbers}</div>);
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable({ columns, data }, usePagination);
  return (
    <Styles>
      <div style={{ overflowX: 'auto', marginBottom: '2rem' }}>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{ backgroundColor: '#FAF9F6' }}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} style={cell.column.style}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div>
        <Button
          style={{ background: '#e5b611' }}
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          Previous
        </Button>
        <span style={{ marginLeft: '10px', marginRight: '10px' }}>
          <strong>Page </strong>
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <Button style={{ background: '#e5b611' }} onClick={nextPage} disabled={!canNextPage}>
          Next
        </Button>
      </div>
    </Styles>
  );
};

export default ImpactedSkusTable;
