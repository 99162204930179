import React, { useState } from 'react';
import { Modal, Button, Grid, Group, Text, Textarea } from '@mantine/core';
import { Checkbox } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import { AppLoader } from '../../../../../components/common/loader';

export const ManualClosureModal = ({
  setTableChecked,
  manualClosure,
  setManualClosure,
  text = 'You have selected the manual closure option. This action cannot be undone once confirmed. Please check the checkbox and click on OK if you wish to proceed ',
  save,
  loading,
}) => {
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setTableChecked({ checked: false, id: null });
    setManualClosure({
      open: false,
      id: null,
    });
    if (document.querySelector('.mantine-Tabs-body .mantine-Checkbox-input:checked'))
      document.querySelector('.mantine-Tabs-body .mantine-Checkbox-input:checked').checked = false;
  };

  const handleNext = () => {
    const payload = {
      comment: value.substring(0, 250),
      status_id: manualClosure.id,
    };

    save(payload, () => handleClose());
  };

  return (
    <>
      <Modal
        className='Invalid-modal'
        opened={manualClosure?.open}
        onClose={() => handleClose()}
        title='Disclaimer'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='alert-modal-group'>
              <InfoCircle color='black' size='7rem' />
              <Text size='sm'>{text}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              placeholder='Max 250 characters'
              label='Enter Comments'
              value={value}
              onChange={(event) => setValue(event.currentTarget.value)}
            />
            {value?.length > 249 && (
              <div className='red-custom-mantine-Textarea-error'>
                250 Character limit reached, exceeding character would be truncated on saving.
              </div>
            )}
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox
              label='I accept the terms and conditions above'
              name='confirmed'
              onChange={handleChange}
            />
          </Grid.Col>
          <Grid.Col span={4} offset={8}>
            <Button
              onClick={() => handleNext()}
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              color='primary'
              fullWidth
              disabled={!checked || loading}
            >
              {!loading ? 'OK' : <AppLoader />}
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
