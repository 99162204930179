import React, { createContext, useContext, useState } from 'react';
import { saveAs } from 'file-saver';
import { useParams, useSearchParams } from 'react-router-dom';
import { axios } from '../../../../utils/axios';
import { getCommentUrl, getPlantsObject, transformBomData, transformCCData } from './helper';
import { AttachmentsModal } from '../../../../common/attachments';
import { attachmentChunks } from '../../../../utils/helpers';
import { MAX_ATTACHMENTS } from '../../constants';
import { EXTENSIONS } from '../../../../constants';
import { showAlert } from '../../../../utils/alerts';
import { CostingRequestsContext, useCrStatusCancelled } from '../../../../contexts/CostingRequest';
import { OpenPlantModal } from '../vicApproval/openPlantModal';

const initialAttachmentState = {
  open: false,
  plantId: null,
  attachments: [],
  existingAttachments: [],
};

export const stepsKeys = {
  vicSubmission: 'vic-submission',
  vicApproval: 'vic-approval',
};

export const Context = createContext();

export const Provider = ({ children }) => {
  const isCancelled = useCrStatusCancelled();
  const { crUserPermissions, getStepStatus } = useContext(CostingRequestsContext);
  const [initialLoading, setInitialLoading] = useState(false);
  const { crNumber } = useParams();
  const [form, setForm] = useState({});
  const [attachmentModal, setAttachmentModal] = useState(initialAttachmentState);
  const [attachmentLoading, setAttachmentsLoading] = useState(false);
  const [stepStatus, setStepStatus] = useState('Not Started');
  const [firstLoad, setFirstLoad] = useState(true);
  const [readOnly, setReadOnly] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [plantModal, setPlantModal] = useState(false);

  const activePage = searchParams.get('tab');

  const info = async (refresh = false, plantInfo, loading = true, type = 'cc_steps') => {
    try {
      setIsReadOnly(plantInfo.producing_plant);
      setInitialLoading(loading);
      if (firstLoad) await axios.get(`/costing-request/step/refresh-bom-adjustment/${crNumber}`);

      const { data } = await axios.get(`costing-request/step/vic-submission/${crNumber}`, {
        params: {
          [type === 'cc_steps' ? 'refresh_vic_cc' : 'refresh_vic_bom']: refresh,
          plant: plantInfo.producing_plant,
        },
      });

      const pageStatusAccessor = activePage === 'vicApproval' ? 'vic_approval' : 'vic_submission';

      const plants = getPlantsObject(data?.[pageStatusAccessor]);

      let formattedData =
        type === 'cc_steps'
          ? transformCCData(data.data?.[type] || [], plants)
          : transformBomData(data.data?.[type] || [], plants);

      if (activePage === 'vicApproval')
        formattedData = syncVicApprovalStatus(
          formattedData,
          data?.vic_submission || [],
          plantInfo.id,
        );

      return formattedData;
    } catch (e) {
      console.log(e);
    } finally {
      setFirstLoad(false);
      setInitialLoading(false);
    }
  };

  const syncVicApprovalStatus = (baseData, vicSubmission, plantId) => {
    const index = vicSubmission.findIndex(
      ({ producing_plant_id }) => producing_plant_id === plantId,
    );
    const submission = vicSubmission[index];
    if (submission) {
      baseData = baseData.map((plant) => {
        return {
          ...plant,
          status: submission.status === 'In Progress' ? 'Not Started' : plant.status,
          vic_submission_step_id: submission.id,
        };
      });
    }

    return baseData;
  };

  const setIsReadOnly = (producingPlant) => {
    const permissionKeys = {
      vicSubmission: 'standard/transfer_pricing',
      vicApproval: 'fpna_brewery_manager',
    };

    const permissionKey = permissionKeys[activePage];
    const hasPermission =
      crUserPermissions[producingPlant]?.[permissionKey]?.is_accessible ?? false;

    setReadOnly(!hasPermission || isCancelled);
  };

  const onFormChange = (value, id, name = 'comment') => {
    setForm((prevValues) => {
      return {
        ...prevValues,
        [id]: {
          ...prevValues[id],
          [name]: value,
        },
      };
    });
  };

  const onFormSubmit = async (payload, id, type) => {
    try {
      const url = getCommentUrl(crNumber, type, activePage);

      const { data } = await axios.post(url, {
        comment: activePage === 'vicSubmission' ? payload.comment : payload.vic_comment,
        id,
      });
      showAlert({ message: 'Comment saved Successfully' }, 'success');
      return data;
    } catch (e) {
      showAlert(e, 'error');
      console.log(e);
    }
  };

  const onPageSubmit = async (payload) => {
    try {
      const { data } = await axios.post(
        `/costing-request/step/vic-submission/submit/${crNumber}`,
        payload,
      );
      setStepStatus('Closed');
      getStepStatus(crNumber);
      showAlert({ message: 'VIC submitted successfully' }, 'success');
      return data;
    } catch (e) {
      setStepStatus(stepStatus);
      showAlert(e, 'error');
      console.log(e);
    }
  };

  const onVicApprove = async (plant) => {
    try {
      await axios.post(`costing-request/step/vic-approval/${crNumber}`, {
        status: 'Approved',
        comment: '', // to be updated
        plant: plant,
      });
      setStepStatus('Closed');
      getStepStatus(crNumber);
      showAlert({ message: 'VIC Approved successfully' }, 'success');
    } catch (e) {
      showAlert(e, 'error');
    }
  };

  const fetchAttachments = async (stepId) => {
    try {
      if (!stepId) return;
      setAttachmentsLoading(true);
      const { data } = await getAttachmentsRequest(stepId, stepsKeys[activePage]);

      setAttachmentModal({
        open: false,
        plantId: stepId,
        attachments: [],
        existingAttachments: data?.uploads || [],
      });
      return data;
    } catch (e) {
      console.log(e);
    } finally {
      setAttachmentsLoading(false);
    }
  };

  const getAttachmentsRequest = async (stepId, referenceType) => {
    return await axios.get(`/costing-request/attachments/${crNumber}/${stepId}`, {
      params: {
        reference_type: referenceType,
      },
    });
  };

  const handleFileSelect = (files) => {
    const currentValues = { ...attachmentModal };
    const attachments = currentValues.attachments || [];
    const existingAttachments = currentValues.existingAttachments || [];
    const filesLength = existingAttachments.length + attachments.length + files.length;

    if (filesLength <= MAX_ATTACHMENTS) {
      files.forEach((file) => attachments.push(file));
      currentValues.attachments = attachments;
      setAttachmentModal(currentValues);
    }
  };

  const handleFileRemove = (index) => {
    const currentValues = { ...attachmentModal };
    currentValues.attachments = currentValues.attachments.filter((i, k) => k !== index);

    setAttachmentModal(currentValues);
  };

  const resetAttachmentsModal = () => {
    setAttachmentModal(initialAttachmentState);
  };

  const handleAttachmentSubmit = async () => {
    try {
      setAttachmentsLoading(true);
      const { attachments, plantId } = attachmentModal;

      const formData = new FormData();
      formData.append('reference_type', stepsKeys[activePage]);

      for (const attachment of attachmentModal.existingAttachments) {
        formData.append('attachmentId[]', attachment.id);
      }

      const chunks = attachmentChunks(attachments.filter((file) => file.size));
      if (Object.keys(chunks).length > 0) {
        for (const chunk of Object.values(chunks)) {
          for (const file of chunk) {
            formData.append('attachments', file);
          }
          await uploadAttachment(formData, plantId);
        }
      } else {
        await uploadAttachment(formData, plantId);
      }

      setAttachmentModal({ ...attachmentModal, open: false });
      fetchAttachments(plantId);
    } catch (e) {
      showAlert(e, 'error');
    } finally {
      setAttachmentsLoading(false);
    }
  };

  const uploadAttachment = async (formData, plantId) => {
    try {
      const res = await axios.post(`/costing-request/attachments/${crNumber}/${plantId}`, formData);
      return res;
    } catch (error) {
      throw error;
    }
  };

  const downloadAttachment = async (name) => {
    try {
      const { data } = await axios.get(`/costing-request/attachments/download/${crNumber}`, {
        params: {
          name,
        },
      });
      const base64String = data.data.downloaded;
      const ext = name.split('.');
      saveAs(`${EXTENSIONS[ext[ext.length - 1].toLowerCase()]}${base64String}`, name);
    } catch (e) {
      throw e;
    }
  };

  const deleteAttachment = async (deletedAttachment, plantId) => {
    try {
      const formData = new FormData();
      formData.append('reference_type', 'step');
      for (const attachment of attachmentModal.existingAttachments) {
        if (deletedAttachment.id !== attachment.id)
          formData.append('attachmentId[]', attachment.id);
      }
      await uploadAttachment(formData, plantId);
      fetchAttachments(plantId);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Context.Provider
      value={{
        info,
        form,
        setForm,
        initialLoading,
        setInitialLoading,
        onFormChange,
        onFormSubmit,
        fetchAttachments,
        attachmentModal,
        setAttachmentModal,
        downloadAttachment,
        deleteAttachment,
        resetAttachmentsModal,
        attachmentLoading,
        onPageSubmit,
        stepStatus,
        readOnly,
        setStepStatus,
        setIsReadOnly,
        activePage,
        plantModal,
        setPlantModal,
        onVicApprove,
        getAttachmentsRequest,
      }}
    >
      {children}
      {attachmentModal.open && (
        <AttachmentsModal
          modal={attachmentModal}
          setModal={setAttachmentModal}
          onChange={handleFileSelect}
          onSubmit={handleAttachmentSubmit}
          onRemove={handleFileRemove}
          attachmentLoading={attachmentLoading}
          readOnly={readOnly}
        />
      )}

      {plantModal && <OpenPlantModal plantModal={plantModal} setPlantModal={setPlantModal} />}
    </Context.Provider>
  );
};
