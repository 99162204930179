import React, { useState, useContext } from 'react';
import { Text, Button } from '@mantine/core';
import { Photo } from 'tabler-icons-react';
import ProjectName from '../../../components/projectName/projectName';
import { Tabs } from '@mantine/core';
import { Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RfsContext } from '../../../contexts/RfsContext';
import { AttachmentModal } from '../../../components/modal/AttachmentModal';

import './attachmentPageDLST.css';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { AppLoader } from '../../../components/common/loader';
import { routes } from '../../../routes';
import { rfsStatus } from '../../../constants';
import { CustomDropzone } from '../../../common/dropzone';
import { FileList } from '../../../common/dropzone/fileList';

function AttachmentCompDLST(props) {
  const { fromOverview = false } = props;
  const params = useParams();
  const navigate = useNavigate();
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [error, setError] = useState({ state: false, message: null });
  const { form, addAttachments, loading, saveAsDraft } = useContext(RfsContext);
  const { status = '', capex_needed = '' } = form.rfsInformation;
  const { capexEmailApproval, emailAttachment } = form.attachments;

  const isDisabled = () => {
    return Boolean(capex_needed) && capexEmailApproval.length === 0;
  };

  const handleNext = (callback) => {
    if (!callback) {
      addAttachments(callback);
      return;
    }
    if (isDisabled()) {
      setAttachmentModal(true);
    } else {
      addAttachments(callback);
    }
  };

  const Footer = () =>
    props.disableButtons ? null : (
      <Row className='justify-content-md-end' md={4}>
        {!fromOverview && (
          <Col md='auto'>
            <div classname='buttonRow'>
              <Link to={routes.submission(params.rfsNumber).scopeDefine}>
                <Button
                  className='back'
                  color='primary'
                  radius='md'
                  size='md'
                  fullWidth
                  style={{
                    marginRight: '1rem',
                    background: 'white',
                    color: 'black',
                    border: ' 0.25px solid gray',
                    width: '200px',
                  }}
                >
                  Back
                </Button>
              </Link>
            </div>
          </Col>
        )}

        <Col md='auto'>
          <Button
            disabled={saveAsDraft}
            className='save'
            color='primary'
            radius='md'
            size='md'
            fullWidth
            style={{ marginRight: '1rem', background: 'black', color: 'white', width: '200px' }}
            onClick={() => handleNext()}
          >
            {!saveAsDraft ? 'Save' : <AppLoader />}
          </Button>
        </Col>
        {!fromOverview && (
          <Col md='auto'>
            <Button
              disabled={loading}
              className='next'
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              color='primary'
              radius='md'
              size='md'
              fullWidth
              style={{ marginRight: '1rem', width: '200px' }}
              onClick={() =>
                handleNext(() =>
                  status === rfsStatus.draft
                    ? navigate(routes.submission(params.rfsNumber).review)
                    : navigate(-1),
                )
              }
            >
              {!loading ? 'Next' : <AppLoader />}
            </Button>
          </Col>
        )}
      </Row>
    );

  return (
    <ContentPaper page={'attachments'} footer={<Footer />}>
      {props.withProjectName ? (
        <Row>
          <div className='projectNameComp'>
            <ProjectName initialItem={props.initialItem} />
          </div>
        </Row>
      ) : null}

      <Row>
        <Row>
          <Text size='sm' weight={600}>
            Please upload Additional Email Attachment
          </Text>

          <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
            <Tabs.List>
              <Tabs.Tab value='Files' icon={<Photo size={14} />}></Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value='Files'>
              <CustomDropzone type='emailAttachment' setError={setError} maxFiles={10} size={8} />
              <FileList
                files={emailAttachment}
                type={'emailAttachment'}
                error={Boolean(capex_needed) && capexEmailApproval.length === 0}
              />
              {error.state && (
                <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
              )}
            </Tabs.Panel>
          </Tabs>
        </Row>
      </Row>

      {attachmentModal && (
        <AttachmentModal invalidModal={attachmentModal} setInvalidModal={setAttachmentModal} />
      )}
    </ContentPaper>
  );
}

export default AttachmentCompDLST;
