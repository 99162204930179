import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import '../ScopeCompDLST/ScopeCompDLST.css';
import { DatePickerInput } from '@mantine/dates';
import ReactTable from '../dlstTable/dlstTable';
import { BrowserRouter as Router, useParams } from 'react-router-dom';
import { Checkbox, Text, Modal, Button } from '@mantine/core';
import { CalendarIcon } from '@modulz/radix-icons';
import { Select } from '@mantine/core';
import { Accordion } from '@mantine/core';
import { RfsContext } from '../../../contexts/RfsContext';
import { AppLoader } from '../../../components/common/loader';

import { DelistingContext } from '../../../contexts/DelistingContext';
import { axios } from '../../../utils/axios';

export default function ScopeCompReview({ disableButtons, delistingForm, selectedMarkets }) {
  const params = useParams();
  const { rfsNumber } = params;
  const { setDelistingForm, obsoletes, scopeErrors, setScopeErrors, validateDelisting } =
    useContext(DelistingContext);

  const { form, costCenters = [], costCentersObject = {}, infoLoading } = useContext(RfsContext);

  const [costCenterSearch, setCostCenterSearch] = useState('');
  const [accordionState, setAccordionState] = useState({});
  const [opened, setOpened] = useState({ open: false, id: null, item: null });
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ccModal, setCcModal] = useState({
    open: false,
    callback: null,
    query: null,
  });

  const [invalidModal, setInvalidModal] = useState({
    open: false,
    callback: false,
  });
  const { status = '', rfs_type } = form.rfsInformation;

  const getCostCenter = (scopeId) => {
    let filteredData = [...costCenters];

    if (costCenterSearch.length === 0) {
      filteredData = filteredData.splice(0, 100);
    } else {
      let data = filteredData.filter(
        (item) =>
          item?.value?.length > 0 &&
          item.label.toLowerCase().startsWith(costCenterSearch.toLowerCase()),
      );

      filteredData = data.splice(0, 100);
    }

    const cost_center = delistingForm[scopeId]?.cost_centre;

    const selectedValueIndex = filteredData.findIndex((data) => data.val === cost_center);

    if (selectedValueIndex === -1 && cost_center) {
      if (costCentersObject[cost_center]) {
        filteredData.push({
          label: costCentersObject[cost_center],
          value: cost_center,
        });
      } else {
        filteredData.push({ label: cost_center, value: cost_center });
      }
    }

    return filteredData;
  };

  const handleNext = (callback = null, type = null) => {
    const errors = validateDelisting();

    setTimeout(() => {
      setScopeErrors({
        errors: errors.errors,
        delistingErrors: errors.delistingErrors,
      });
    }, 500);

    Object.values(delistingForm).forEach((item) => {
      axios.put(`delisting/${rfsNumber}`, item);
    });
    callback();
  };

  const closeModal = (scope_id) => {
    setOpened({ open: false, id: null, item: null });
  };

  const handleDelistingFormChange = (value, name, scope_id) => {
    const currentValues = { ...delistingForm };
    currentValues[scope_id][name] = value;

    setDelistingForm(currentValues);
  };

  const handleCostCenterCreate = (query) => {
    handleDelistingFormChange(query, 'cost_center');
  };

  const getBulletList = (data, name) => {
    const listItems = data?.map((item, index) => <li key={index}>{item[`${name}`]}</li>);

    const bulletedList = <ul style={{ padding: '0 1rem' }}>{listItems}</ul>;

    return bulletedList;
  };

  if (infoLoading) return <AppLoader size='md' center />;

  return (
    <div>
      <Accordion onChange={(event) => setAccordionState(event)}>
        {Object.keys(form?.selectedScope || {}).map((name) =>
          form?.selectedScope[name].map((item, index) => {
            const accordionKey = `accordion_${index}`;

            return (
              <Accordion.Item key={accordionKey} value={accordionKey}>
                <Accordion.Control
                  className={scopeErrors.errors[item.id] ? 'error-label' : ''}
                >{`${name.substring(0, 3).toUpperCase()} - ${item['selection_number']} - ${
                  item['selection_name']
                }`}</Accordion.Control>
                <Accordion.Panel>
                  <Row>
                    <Col
                      md={1}
                      style={{
                        width: '4.1666%',
                        flex: '0 0 4.1666%',
                        maxWidth: ' 4.1666%',
                      }}
                    ></Col>
                    <Col md={{ span: 5, offset: 0 }}>
                      <Select
                        label={
                          <div>
                            Cost Center{' '}
                            <Text component='span' style={{ color: 'red' }}>
                              *
                            </Text>
                          </div>
                        }
                        data={getCostCenter(item.id)}
                        onSearchChange={(query) => setCostCenterSearch(query)}
                        nothingFound='Nothing found'
                        searchable
                        creatable
                        clearable={!disableButtons}
                        getCreateLabel={(query) => `+ Select ${query}`}
                        onCreate={(query) => {
                          setCcModal({
                            open: true,
                            callback: handleCostCenterCreate,
                            query,
                          });
                        }}
                        name='cost_centre'
                        value={delistingForm[item.id]?.cost_centre}
                        onChange={(value) =>
                          handleDelistingFormChange(value, 'cost_centre', item.id)
                        }
                        error={scopeErrors.delistingErrors[item.id]?.cost_centre}
                        radius='md'
                        className='InputField'
                        disabled={disableButtons}
                      />
                    </Col>

                    <Col md={{ span: 5, offset: 1 }} style={{ marginTop: '8px' }}>
                      <DatePickerInput
                        placeholder='Select Delisting date.'
                        icon={<CalendarIcon color='#e5b611' />}
                        label={
                          <div>
                            De-Listing Date{' '}
                            <Text component='span' style={{ color: 'red' }}>
                              *
                            </Text>
                          </div>
                        }
                        name='delisting_date'
                        value={
                          moment(delistingForm[item.id]?.delisting_date).isValid()
                            ? moment(delistingForm[item.id]?.delisting_date).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDelistingFormChange(
                            moment(date).format('YYYY-MM-DD'),
                            'delisting_date',
                            item.id,
                          )
                        }
                        error={scopeErrors.delistingErrors[item.id]?.delisting_date}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={1}
                      style={{
                        width: '4.1666%',
                        flex: '0 0 4.1666%',
                        maxWidth: ' 4.1666%',
                      }}
                    ></Col>
                    <Col md={{ span: 2, offset: 0 }} style={{ marginTop: '40px' }}>
                      <div>
                        {' '}
                        <p
                          className='fixedLeft'
                          style={{ marginBottom: '0px', paddingBottom: '0px' }}
                        >
                          Markets <span style={{ color: 'red' }}>*</span>
                        </p>{' '}
                      </div>
                    </Col>

                    <Col md={{ span: 2, offset: 1 }}>
                      <Text
                        style={{
                          padding: '0.5rem 0',
                          marginTop: '40px',
                          fontWeight: '500',
                          color: '#212529',
                          fontSize: '0.875rem',
                        }}
                      >
                        {delistingForm[item.id]?.allMarkets ? `\nFull Delist` : `\nPartial Delist`}
                      </Text>
                      <Text
                        style={{
                          fontSize: '0.875rem',
                        }}
                      >
                        {getBulletList(selectedMarkets[item.id], 'market_name')}
                      </Text>

                      {scopeErrors.delistingErrors[item.id]?.impacted_markets && (
                        <div className='FixedRight error'>Select atleast one market</div>
                      )}
                    </Col>

                    <Col md={{ span: 5, offset: 1 }} style={{ marginTop: '40px' }}>
                      <Checkbox
                        label={
                          <div>
                            De-Listing Approved by sales team{' '}
                            <Text component='span' style={{ color: 'red' }}>
                              *
                            </Text>
                          </div>
                        }
                        className='checkbox'
                        color='orange'
                        name='approved_by_sales'
                        disabled
                        checked={delistingForm[item.id]?.approved_by_sales}
                        onChange={(event) =>
                          handleDelistingFormChange(
                            event.target.checked,
                            'approved_by_sales',
                            item.id,
                          )
                        }
                      />
                      {scopeErrors.delistingErrors[item.id]?.approved_by_sales && (
                        <div className='FixedRight error'>This is a required field</div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={1}
                      style={{
                        width: '4.1666%',
                        flex: '0 0 4.1666%',
                        maxWidth: ' 4.1666%',
                      }}
                    ></Col>
                    <Col md={{ span: 2, offset: 0 }}>
                      <p
                        style={{
                          textDecoration: 'underline',
                          color: '#e5b611',
                          cursor: 'pointer',
                        }}
                        className='modalText'
                        onClick={() =>
                          setOpened({
                            open: true,
                            id: item['selection_number'],
                            item: item,
                          })
                        }
                      >
                        Show Obsoletes
                      </p>
                      {scopeErrors.delistingErrors[item.id]?.confirm_obsoletes && (
                        <div className='FixedRight error'>This is a required field</div>
                      )}
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  ></div>
                </Accordion.Panel>
              </Accordion.Item>
            );
          }),
        )}
      </Accordion>

      {opened.open && (
        <Modal
          opened={opened.open}
          onClose={() => closeModal(opened.item?.id)}
          size='75%'
          title={
            <Text weight={700} size='xl'>
              Obsoletes
            </Text>
          }
        >
          <ReactTable data={obsoletes[opened.item?.id] ? obsoletes[opened.item?.id] : null} view />
        </Modal>
      )}
    </div>
  );
}
