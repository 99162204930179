import { useMemo } from 'react';
import MantineTable from '../../../../common/table/mantine';

export const PreviewTable = ({ label, data, columns }) => {
  let memonizedColumns = useMemo(() => columns, [data]);

  if (label === 'Select Production Plant')
    memonizedColumns = memonizedColumns.concat([
      {
        accessorKey: 'vol_hl',
        header: 'Vol (HL)',
      },
    ]);

  return (
    <MantineTable
      columns={memonizedColumns}
      initialData={data}
      enablePagination={false}
      enableSorting={true}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      loading={false}
      enableColumnFilters={false}
      enableColumnActions={false}
      enableZoom={false}
      mantineTableHeadProps={{
        sx: {
          '& tr': {
            backgroundColor: '#D8D8D8',

            '& .mantine-TableHeadCell-Content': {
              color: '#1E1E1E',
            },
          },
        },
      }}
    />
  );
};
