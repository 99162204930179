import { useContext, useEffect, useMemo, useState } from 'react';
import MantineTable from '../../../../../common/table/mantine';
import { CustomTextArea } from '../../../../../common/inputs/CustomTextArea';
import { Context } from '../context';
import { Button } from '@mantine/core';
import { AppLoader } from '../../../../../components/common/loader';
import { CostValue, StyledTotal } from '../helper';
import moment from 'moment';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';

export const Table = ({ data = [], loading, rightButtons, activePlant }) => {
  const [initialState, setInitialState] = useState(data);
  const { crStepStatus } = useContext(CostingRequestsContext);
  const { form, onFormChange, onFormSubmit, readOnly, activePage } = useContext(Context);
  const [saveLoader, setSaveLoader] = useState({});

  const commentKey = activePage === 'vicApproval' ? 'vic_comment' : 'comment';

  useEffect(() => {
    setInitialState(data);
  }, [data]);

  const isInProgress = (cost) => {
    const pageKey = activePage === 'vicSubmission' ? 'VIC Submission' : 'VIC Approval';
    const isVicClosed =
      (crStepStatus?.[activePlant.producing_plant]?.[pageKey] || '').toLowerCase() === 'closed';
    return (cost.status || '').toLowerCase() === 'in progress' && !isVicClosed;
  };

  const handleSubmit = async (id) => {
    try {
      setSaveLoader({ ...saveLoader, [id]: true });
      await onFormSubmit(form[id], id, 'cc');
      updateInitialData(form[id], id);
    } catch (e) {
      console.log(e);
    } finally {
      setSaveLoader({ ...saveLoader, [id]: false });
    }
  };

  const updateInitialData = (info, id) => {
    const index = initialState.findIndex((item) => item.id === id);
    if (index > -1) {
      const updatedValues = [...initialState];
      updatedValues[index] = {
        ...updatedValues[index],
        [commentKey]: info?.[commentKey] || '',
      };
      setInitialState(updatedValues);
    }
  };

  const totalOverall = useMemo(() => {
    return initialState.reduce(
      (sum, row) => sum + (parseFloat(row?.overall_cost_final[0].split('Main: ')[1]) || 0),
      0,
    );
  }, [initialState]);

  const totalOverallReference = useMemo(() => {
    return initialState.reduce(
      (sum, row) => sum + (parseFloat(row?.overall_cost_final[1].split('Ref: ')[1]) || 0),
      0,
    );
  }, [initialState]);

  const dataWithTotals = useMemo(() => {
    const overAllCost = [
      `Main: ${totalOverall.toFixed(3)}`,
      `Ref: ${totalOverallReference.toFixed(3)}`,
    ];

    return [
      {
        cost_component: 'Total',
        cc_description: '',
        overall_cost_excel: overAllCost.join(','),
        overall_cost_final: overAllCost,
        currency: '',
        comment: '',
        vic_comment: '',
        overall_value: totalOverall,
        overall_value_ref: totalOverallReference,
        isTotal: true,
      },
      ...initialState,
    ];
  }, [initialState, totalOverall, totalOverallReference]);

  const columns = useMemo(
    () => [
      {
        id: 'general',
        columns: [
          {
            accessorKey: 'cost_component',
            header: 'CC Code',
            Cell: ({ row }) => {
              const data = row.original;
              return data.isTotal ? (
                <StyledTotal>Total</StyledTotal>
              ) : (
                <div>{data.cost_component}</div>
              );
            },
          },
          {
            accessorKey: 'cc_description',
            header: 'Name of CC',
          },
        ],
      },

      {
        id: 'overall_cost_excel',
        header: 'Overall Value',
        columns: [
          {
            header: 'Main',
            accessorKey: `overall_main`,
            Cell: ({ row }) => {
              if (row.original.isTotal)
                return <StyledTotal>{(row?.original?.overall_value || 0).toFixed(3)}</StyledTotal>;
              return <CostValue cost={row?.original?.overall_cost_final?.[0]} />;
            },
          },
          {
            header: 'Ref.',
            accessorKey: 'overall_ref',
            Cell: ({ row }) => {
              if (row.original.isTotal)
                return (
                  <StyledTotal>{(row?.original?.overall_value_ref || 0).toFixed(3)}</StyledTotal>
                );
              return <CostValue cost={row?.original?.overall_cost_final?.[1]} />;
            },
          },
          {
            header: 'Var.',
            accessorKey: 'overall_var',
            Cell: ({ row }) => {
              return <CostValue cost={row?.original?.overall_cost_final?.[2]} />;
            },
          },
        ],
      },

      {
        id: 'other',
        columns: [
          {
            accessorKey: 'currency',
            header: 'Curr',
          },

          activePage === 'vicApproval' && {
            header: 'Comment (Submitted)',
            accessorKey: 'comment',
            Cell: ({ row }) => {
              if (row.original.isTotal) return '';
              return (
                <div>
                  <div style={{ alignItems: 'center', marginBottom: '8px' }}>
                    <p key={row.original?.component}>{row?.original?.comment}</p>
                  </div>
                </div>
              );
            },
          },

          {
            header: 'Comment',
            accessorKey: commentKey,
            Cell: ({ row }) => {
              if (row.original.isTotal) return '';
              const { id } = row.original;
              return (
                <div>
                  <div style={{ alignItems: 'center', marginBottom: '8px' }}>
                    <CustomTextArea
                      value={form[id] ? form[id]?.[commentKey] : row.original?.[commentKey] || ''}
                      placeholder='Comment here'
                      onChange={(value) => onFormChange(value, row.original.id, commentKey)}
                      disabled={!isInProgress(row.original) || readOnly}
                    />
                  </div>
                </div>
              );
            },
          },

          {
            id: 'action',
            header: 'Action',
            Cell: ({ row }) => {
              if (row.original.isTotal) return '';
              const { id } = row.original;
              return (
                <Button
                  disabled={!form[id] || saveLoader[id] || !isInProgress(row.original) || readOnly}
                  color='dark'
                  fullWidth
                  size='sm'
                  radius='sm'
                  className='add-supplier-button'
                  onClick={() => handleSubmit(row.original.id)}
                >
                  {saveLoader[id] ? <AppLoader size='sm' /> : 'Submit'}
                </Button>
              );
            },
          },
        ].filter((column) => column),
      },
    ],
    [form, saveLoader, readOnly, crStepStatus],
  );

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={dataWithTotals}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        pageSize={10}
        enableColumnFilter={false}
        enableFilters={false}
        initialState={{
          sorting: [
            {
              id: 'cost_component',
              desc: false,
            },
          ],
        }}
        enableExport={true}
        columnFilterDisplayMode={false}
        rightButtons={rightButtons}
        csvFilename={`vic_${
          activePage === 'vicApproval' ? 'approval' : 'submission'
        }_consolidated_cost_${moment().format('YYYY-MM-DD')}`}
      />
    </>
  );
};
