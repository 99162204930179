import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { Styles } from '../../../components/tables/skutable/production-site/styles';
import { Button, Tooltip, Loader, Text, Group } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import { axios } from '../../../utils/axios';
import { AppLoader } from '../../../components/common/loader';
import { SkuDetailsTable } from '../../../components/tables/SKUDetailsTable/SkuDetailsTable';
import moment from 'moment';

const renameKeysAndOmitFields = (array, renameMap, fieldsToOmit) => {
  if (array) {
    return array.map((obj) => {
      const renamedObj = {};
      for (const key in obj) {
        if (fieldsToOmit.includes(key)) {
          continue; // Skip fields to omit
        }
        const newKey = renameMap[key] || key; // Use the renamed key if available
        renamedObj[newKey] = obj[key];
      }
      return renamedObj;
    });
  } else {
    return [];
  }
};

const addUnderscore = (string) => {
  let numberCount = 0;

  const result = string
    .split('') // Split the string into an array of individual characters
    .map((char) => {
      if (!isNaN(char)) {
        numberCount++;
        if (numberCount === 4) {
          numberCount = 0;
          return char + '_';
        }
      }
      return char;
    })
    .join(''); // Join the modified array back into a string

  return result;
};

const handleConfirm = (data, rfs_number, scope_id, callback, setConfirmLoading) => {
  setConfirmLoading(true);
  const updatedData = data.map((obj) => {
    return {
      rfs_number: rfs_number,
      scope_id: scope_id,
      ...obj,
    };
  });

  const fieldsToOmit = ['delisting_week', 'stock_remaining_on_delisting_week'];
  const renameMap = {
    Material_code: 'material_code',
    Material_description: 'material_desc',
    Obsolete_risk_max: 'obsoletes_risk_max',
    Obsolete_risk_min: 'obsoletes_risk_min',
    stock_covered_till_week: 'stock_coverage_till_week',
  };

  axios
    .post(`/delisting/obsoletes`, renameKeysAndOmitFields(updatedData, renameMap, fieldsToOmit))
    .then(
      (res) => {
        callback();
      },
      (err) => {
        console.log(err);
      },
    )
    .finally(() => setConfirmLoading(false));
};

const ReactTable = ({
  data = null,
  view = false,
  handleMainConfirm,
  delistingDate,
  sku_id,
  scope_id,
  item_id,
  rfsNumber,
  selectedMarkets,
}) => {
  const [tableData, setTableData] = useState([
    {
      Material_code: '0',
      Material_description: 'No BOM available',
      Obsolete_risk_min: '0',
      Obsolete_risk_max: '0',
      stock_covered_till_week: '0',
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    if (item_id && !view) {
      setLoading(true);
      const arrayOfMarkets = selectedMarkets.map((obj) => obj['market_label']);

      axios
        .get(
          `/delisting/obsoletes?sku_id=${sku_id}&delisting_date=${delistingDate}&markets=${arrayOfMarkets}`,
        )
        .then(
          (res) => {
            setTableData(res.data.data.obsoletes);
          },
          (err) => {
            console.log(err);
          },
        )
        .finally(() => setLoading(false));
    }
  }, [item_id]);

  const columns = useMemo(
    () => [
      {
        Header: 'Material Code',
        accessor: 'Material_code',
      },
      {
        Header: 'Material description',
        accessor: 'Material_description',
      },
      {
        Header: (
          <Tooltip
            label='Stock on hand + Stock on Supplier'
            placement='start'
            withArrow
            className='data'
          >
            <div>
              <InfoCircle
                style={{ marginRight: '0.5rem' }}
                size={'1.2rem'}
                color={'rgb(229, 182, 17)'}
              />
              Submitted Obsoletes Risk (Min) ($)
            </div>
          </Tooltip>
        ),
        accessor: 'Obsolete_risk_min',
        Cell: ({ row }) => {
          return row.original.Obsolete_risk_min === 'No Contract Found'
            ? 'No Contract Found'
            : parseInt(row.original.Obsolete_risk_max)?.toLocaleString();
        },
      },
      {
        Header: (
          <Tooltip
            label='Stock on hand + Stock on Supplier + Planned Production'
            placement='start'
            withArrow
            className='data'
          >
            <div>
              <InfoCircle
                style={{ marginRight: '0.5rem' }}
                size={'1.2rem'}
                color={'rgb(229, 182, 17)'}
              />
              Submitted Obsoletes Risk (Max) ($)
            </div>
          </Tooltip>
        ),
        accessor: 'Obsolete_risk_max',
        Cell: ({ row }) => {
          return row.original.Obsolete_risk_max === 'No Contract Found'
            ? 'No Contract Found'
            : parseInt(row.original.Obsolete_risk_max)?.toLocaleString();
        },
      },
      {
        Header: (
          <Tooltip
            label='Dependent on forecasted depletion of SKU etc.'
            placement='start'
            withArrow
            radius='md'
            className='data'
          >
            <div>
              <InfoCircle
                style={{ marginRight: '0.5rem' }}
                size={'1.2rem'}
                color={'rgb(229, 182, 17)'}
              />
              Obsoletes Stock Coverage until (YEAR_WEEK)
            </div>
          </Tooltip>
        ),
        accessor: 'stock_covered_till_week',
        Cell: ({ row }) => {
          const stock_covered_till_week = String(row.original.stock_covered_till_week);
          const modifiedString = addUnderscore(stock_covered_till_week);
          return modifiedString;
        },
      },
    ],
    [],
  );

  const renameMapView = {
    material_code: 'Material_code',
    material_desc: 'Material_description',
    obsoletes_risk_max: 'Obsolete_risk_max',
    obsoletes_risk_min: 'Obsolete_risk_min',
    stock_coverage_till_week: 'stock_covered_till_week',
  };

  const fieldsToOmitView = ['createdAt', 'id', 'rfs_number', 'scope_id', 'updatedAt'];

  const tableInstance = useTable({
    columns,
    data: data ? renameKeysAndOmitFields(data, renameMapView, fieldsToOmitView) : tableData,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (loading) return <AppLoader center size='md' />;
  return (
    <Styles>
      <div style={{ overflowX: 'auto', marginBottom: '2rem' }}>
        <Group noWrap spacing='xs' style={{ maxWidth: '40rem', marginBottom: '1rem' }}>
          <InfoCircle size={'3rem'} color={'red'} />
          <Text color='red' weight={500} size='xs'>
            Estimate figures based on today's value. This view shows highest obsolete risk, based on
            quantity of material, for all shared SKUs. Specific obsolete risk to be validated by MRP
            during implementation.
          </Text>
        </Group>
        <SkuDetailsTable
          columns={columns}
          data={data ? renameKeysAndOmitFields(data, renameMapView, fieldsToOmitView) : tableData}
          handleExport={false}
          fullScreenToggle={false}
          fullscreen={false}
          type={'users'}
          customPageIndex={pageIndex}
          setCustomPageIndex={setPageIndex}
          hideRowOptions
        />
      </div>
      {!view && (
        <Button
          variant='gradient'
          gradient={{ from: '#e3af32', to: '#f4e00f' }}
          style={{
            position: 'fixed',
            right: '35px',
            bottom: '1rem',
            marginTop: '-2rem',
          }}
          onClick={() => {
            handleConfirm(
              tableData,
              rfsNumber,
              scope_id,
              () => handleMainConfirm(),
              setConfirmLoading,
            );
          }}
        >
          {confirmLoading ? <Loader variant='dots' size='sm' color='#fff' /> : 'Confirm'}
        </Button>
      )}
    </Styles>
  );
};

export default ReactTable;
