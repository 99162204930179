import React from 'react';
import ImpFeasibility from './impFeasibility';
import RfsLayout from '../../components/layouts/RfsLayout';

function ImpFeasibilityPage() {
  return (
    <RfsLayout>
      <ImpFeasibility />
    </RfsLayout>
  );
}

export default ImpFeasibilityPage;
