import React, { useState, useEffect, useContext, useMemo } from 'react';
import ContentPaper from '../../components/layouts/rfs/Content';
import RfsLayout from '../../components/layouts/RfsLayout';
import { RfsContext } from '../../contexts/RfsContext';
import { SkuDetailsTable } from '../../components/tables/SKUDetailsTable/SkuDetailsTable';
import { axios } from '../../utils/axios';
import { Row, Col } from 'react-bootstrap';
import { AppLoader } from '../../components/common/loader';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import ReportCustomComp from '../../components/report/report';
import moment from 'moment';

function ConcordanceTableReport() {
  const [validationErrors, setValidationErrors] = useState({}); //for Report
  const { form, toggle, fullscreen } = useContext(RfsContext);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  const handleExport = (rows, data) => {
    return data;
  };
  const fetchData = () => {
    axios
      .get('/reports/concordance')
      .then(
        (res) => {
          let response = res.data.data;
          setTableData(response);
        },
        (err) => {},
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Report Data for New UI
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Material_Number',
        header: 'Material number',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'Material_Name',
        header: 'Material name',
      },
      {
        accessorKey: 'Material_Type',
        header: 'Material Type',
      },
      {
        accessorKey: 'Product_Heirarchy',
        header: 'Product Hierarchy',
      },
      {
        accessorKey: 'Category_Code',
        header: 'Category Code',
      },

      {
        accessorKey: 'Category_Value',
        header: 'Category Value',
      },

      {
        accessorKey: 'Brand_Code',
        header: 'Brand Code',
      },
      {
        accessorKey: 'Brand_Value',
        header: 'Brand Value',
      },

      {
        accessorKey: 'Subbrand_Code',
        header: 'Subbrand Code',
      },
      {
        accessorKey: 'Subbrand_Value',
        header: 'Subbrand Value',
      },
      {
        accessorKey: 'Returnability_Code',
        header: 'Returnability Code',
      },
      {
        accessorKey: 'Returnability_Value',
        header: 'Returnability Value',
      },
      {
        accessorKey: 'Container_Type_Code',
        header: 'Container Type Code',
      },
      {
        accessorKey: 'Container_type_value',
        header: 'Container Type Value',
      },
      {
        accessorKey: 'PackSize2_Code',
        header: 'PackSize2 Code',
      },
      {
        accessorKey: 'Tertiary_Pack_Size_Value',
        header: 'Tertiary pack size Value',
      },
      {
        accessorKey: 'PackSize1_Code',
        header: 'PackSize1 Code',
      },
      {
        accessorKey: 'Secondary_Pack_Size_Value',
        header: 'Secondary pack size Value ',
      },
      {
        accessorKey: 'Container_Size_Code',
        header: 'Container Size Code',
      },
      {
        accessorKey: 'Container_size_Value',
        header: 'Container Size Value',
      },
      {
        accessorKey: 'decomplexity_status',
        header: 'Decomplexity Status',
      },
      {
        accessorKey: 'Overall_Status',
        header: 'Overall Status',
      },
      {
        accessorKey: 'AT11',
        header: 'AT11',
      },
      {
        accessorKey: 'BE11',
        header: 'BE11',
      },
      {
        accessorKey: 'CH11',
        header: 'CH11',
      },
      {
        accessorKey: 'DE11',
        header: 'DE11',
      },
      {
        accessorKey: 'DK11',
        header: 'DK11',
      },
      {
        accessorKey: 'FI11',
        header: 'FI11',
      },
      {
        accessorKey: 'FR01',
        header: 'FR01',
      },
      {
        accessorKey: 'GB11',
        header: 'GB11',
      },
      {
        accessorKey: 'IT01',
        header: 'IT01',
      },
      {
        accessorKey: 'LU01',
        header: 'LU01',
      },
      {
        accessorKey: 'NL01',
        header: 'NL01',
      },
      {
        accessorKey: 'NO11',
        header: 'NO11',
      },
      {
        accessorKey: 'PL11',
        header: 'PL11',
      },
      {
        accessorKey: 'SE11',
        header: 'SE11',
      },
    ],
    [validationErrors],
  );

  if (loading)
    return (
      <RfsLayout>
        <ContentPaper page='concordance-report'>
          <AppLoader center size='md' />
        </ContentPaper>
      </RfsLayout>
    );

  return (
    <RfsLayout>
      <ContentPaper page='concordance-report'>
        <Row>
          <Col
            md={{ span: 6 }}
            style={{
              marginTop: '1rem',
              display: 'inline-flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              size='sm'
              color='dark'
              width='2rem'
              style={{ paddingLeft: '20', paddingRight: '20' }}
              onClick={() => {
                navigate('/reports');
              }}
            >
              Back{' '}
            </Button>
            <h1 className='sectionHeadings' style={{ paddingLeft: '10px', fontSize: '28px' , color:'black' }}>
              {' '}
              Concordance Table Report{' '}
            </h1>
          </Col>
        </Row>
        <div id='customReport'>
          {
            <ReportCustomComp
              data={tableData}
              columns={columns}
              setValidationErrors={setValidationErrors}
              validationErrors={validationErrors}
              unique={'Material_Number'}
              enableEditing={false}
            />
          }
        </div>
      </ContentPaper>
    </RfsLayout>
  );
}

export default ConcordanceTableReport;
