import React, { useContext, useEffect, useState } from 'react';
import { Modal, Accordion, Container, Table } from '@mantine/core';
// import { X, Check } from 'tabler-icons-react';
// import moment from 'moment';
import { RfsContext } from '../../../contexts/RfsContext';
import { Styles } from '../../../components/tables/skutable/production-site/styles';

export const VeamAffectedSKUs = ({ SKUModal, setSKUModal, MaterialID }) => {
  const { fetchAffectedSKUs } = useContext(RfsContext);

  const [data, setData] = useState([]);

  function getSKUData() {
    fetchAffectedSKUs(MaterialID).then((res) => {
      if (res.data.data) setData(res.data.data);
    });
  }
  useEffect(() => {
    if (SKUModal.open) {
      getSKUData();
    }
  }, [SKUModal]);

  return (
    <div>
      <Modal
        opened={SKUModal.open}
        onClose={() => setSKUModal({ open: false, id: SKUModal.id })}
        title='SKUs'
        className='confirm-modal w100'
      >
        <Container className='pd0 mwnone'>
          <Styles>
            <div className='pd-0 ovrflowAuto'>
              {data && (
                <Table>
                  <thead>
                    <tr id='header-row'>
                      {data[0] &&
                        Object.keys(data[0]).map((item, i) => (
                          <th className={item} key={i}>
                            {item.replace(/_/g, ' ').charAt(0).toUpperCase() +
                              item.replace(/_/g, ' ').substr(1).toLowerCase()}
                          </th>
                        ))}
                    </tr>
                  </thead>

                  {
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          {Object.values(item).map((it, i) => {
                            return (
                              i < 8 && (
                                <td
                                  className={Object.keys(data[index])[i]}
                                  key={i}
                                  style={{ padding: '0.8rem !important' }}
                                >
                                  {it}
                                </td>
                              )
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
              )}
            </div>
          </Styles>
        </Container>
      </Modal>
    </div>
  );
};

export default VeamAffectedSKUs;
