import styled from '@emotion/styled';

import abilogoback from '../../../images/abilogoback.jpg';
import { Grid } from '@mantine/core';

export const modules = [
  { value: 'RFS', label: 'RFS' },
  { value: 'Costing Request', label: 'Costing Request' },
];

export const StyledBackground = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: fixed ;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background-image: url('${abilogoback}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(8px);
    z-index: -1; /* Stay behind the content */
  }
`;

export const StyledContent = styled.div`
  width: 100%;
  padding: 3em 0;
`;

export const StyledGrid = styled(Grid)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;
