export const changeoverCancellation = 'Changeover cancellation/Project cancellation';
export const technicalSpecificationNumberChange = 'Technical specification number change';
export const artworkUpdate = 'Artwork update';
export const scopeRestatment = 'Scope restatement';
export const quantitiesChange = 'Quanitities Change';

export const existingMaterialTrialOptions = [changeoverCancellation];

export const newMaterialTrialOptions = [
  technicalSpecificationNumberChange,
  artworkUpdate,
  changeoverCancellation,
  scopeRestatment,
];

export const newLiquidsTrialOptions = [
  technicalSpecificationNumberChange,
  changeoverCancellation,
  scopeRestatment,
  quantitiesChange,
];

export const existingLiquidsTrialOptions = [changeoverCancellation];

export const liquidStepStatus = {
  liquid_code_creation: false,
  liquid_status: false,
  liquid_description: false,
  supplier_number_name: false,
  changeover: false,
  changeover_number: false,
  material_contract: false,
  pack_mat_change: false,
  filing_brewing_repacking: false,
  removal_of_changeover: false,
};

export const materialInterface = {
  id: null,
  rfs_number: null,
  material_code: null,
  plant: null,
  PackMatDesc: null,
  PlantDataDesc: null,
  material_number: null,
  material_type: null,
  vendor: null,
  vendor_description: null,
  sup_num: null,
  sup_name: null,
  po_created: null,
  po_num_provider: null,
  po_num: null,
  artwork_agency: null,
  colour_target_final: null,
  artwork_approved: null,
  po_supplier: null,
  po_sup_num_provider: null,
  po_sup_num: null,
  good_for_print: null,
  changeoverAppliedStatus: 0,
  changeoverAppliedStatusRemoval: 0,
  packMatPlantStatus: null,
  BOMPresence: null,
  conf_mat_num: null,
  ean_not_provided: null,
  material_contract: null,
  supplier_production: null,
  material_on_stock_at_supplier: null,
  material_on_stock_at_abi: null,
  trial_status: null,
  fail_reason: null,
  trial_fail: null,
  trials_failed_description: null,
  tech_spec: null,
  changeover_removal: null,
  mat_status_change: null,
  changeover_type: null,
  changeover_date: null,
  attachments: [],
  comment: null,
  technical_pack_change: null,
  technical_quality_change: null,
  artwork_change: null,
  tech_spec_matrix: null,
  packMatsPlantStatus: null,
  plantDataPlantStatus: null,
  packMatsMaterialType: null,
  ABICount: null,
  StockAtSupplier: null,
  StockAtSupplierProduction: null,
  fillingStatus: null,
};

export const liquidInterface = {
  id: null,
  plant: null,
  bom: null,
  bom_changes: null,
  ComponentPlant: null,
  quantity: null,
  new_quantity: null,
  selection_number: null,
  rfs_number: null,
  new_liquid_code: null,
  liquid_code: null,
  liquid_number: null,
  liquid_type: null,
  PackMatDesc: null,
  PlantDataDesc: null,
  changeoverAppliedStatus: null,
  activeCountPackMat: null,
  activeCountPlantData: null,
  changeover_date: null,
  changeover_type: null,
  comment: null,
  sup_number: null,
  sup_name: null,
  vendor: null,
  vendor_description: null,
  trial_status: null,
  trial_fail: null,
  fail_reason: null,
  changeover_removal: null,
  SelectionPlant: null,
  packMatsPlantStatus: null,
  plantDataPlantStatus: null,
  tech_spec_matrix: null,
  ABICount: 0,
  StockAtSupplier: 0,
  StockAtSupplierProduction: 0,
  fillingStatus: 0,
};
