import React, { createContext, useState } from 'react';
import ProductionLog from '../pages/veam/productDevelopment/productionSiteLogModal';
import { ActionModal } from '../pages/veam/productDevelopment/actionModal';
import { axios } from '../utils/axios';

export const CommonColumnsContext = createContext();

export const CommonColumnsProvider = ({ children }) => {
  const [logsModal, setLogsModal] = useState({ open: false, id: null, title: null });
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [manualClosureLoading, setManualClosureLoading] = useState(false);

  const save = (payload) => {
    payload = { ...payload, comment: payload.remark, status_id: payload.status_ids };
    delete payload.remark; // deleting remark because required fieldname is comment
    delete payload.status_ids; // deleting remark because required fieldname is comment

    setManualClosureLoading(true);
    axios
      .post(`/supplier-changes/manual_closure/${payload.status_id}`, payload)
      .then(() => {
        if (actionModal.callback) actionModal.callback(false);
        setManualClosureLoading(false);
      })
      .finally(() => setActionModal({ open: false, id: '', callback: null }));
  };

  return (
    <CommonColumnsContext.Provider
      value={{
        logsModal,
        setLogsModal,
        actionModal,
        setActionModal,
      }}
    >
      {children}
      {actionModal.open && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={actionModal.id}
          updateLoading={manualClosureLoading}
        />
      )}

      {logsModal && (
        <ProductionLog
          logModal={logsModal}
          setLogModal={setLogsModal}
          id={logsModal.id}
          type={logsModal.title || ''}
        />
      )}
    </CommonColumnsContext.Provider>
  );
};
