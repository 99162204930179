import React, { useEffect, useState } from 'react';
import { MagnifyingGlassIcon } from '@modulz/radix-icons';
import { axios } from '../../../../utils/axios';
import { Modal, Container, Input, Group, Grid, UnstyledButton } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';

export const ImpactedSkus = ({ state, setState }) => {
  const [impactedSkus, setImpactedSkus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');

  useEffect(() => {
    setLoading(true);
    axios
      .get('/materials/affected-skus', {
        params: {
          material: state.material,
          plant: state.plant,
        },
      })
      .then(
        (res) => {
          const affectedSkus = res?.data?.data?.affectedSkus || [];
          const uniqueAffectedSkus = {};
          affectedSkus.forEach((sku) => {
            if (!uniqueAffectedSkus[sku.SKUs]) {
              uniqueAffectedSkus[sku.SKUs] = `${sku.SKUs} - ${sku.SKUDescription}`;
            }
          });
          setImpactedSkus(uniqueAffectedSkus);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  }, []);

  return (
    <Modal
      size={'40%'}
      opened={state.open}
      onClose={() => setState({ open: false, material: null, plant: null })}
      title='Impacted SKUs'
    >
      {loading ? (
        <AppLoader center size='md' />
      ) : (
        <Container>
          <Grid>
            <Grid.Col span={12}>
              <Input
                className='search'
                radius='md'
                onChange={(event) => setSearchStr(event.target.value)}
                value={searchStr}
                icon={<MagnifyingGlassIcon />}
                style={{ margin: '0 4rem' }}
              />
            </Grid.Col>
          </Grid>

          <div style={{ marginTop: '12px', height: '50vh', overflowY: 'auto' }}>
            {Object.values(impactedSkus).map(
              (item, key) =>
                item.toLowerCase().includes(searchStr.toLowerCase()) && (
                  <div key={key}>
                    <UnstyledButton className={'lists'}>
                      <Group style={{ flexWrap: 'nowrap' }}>
                        <span style={{ color: 'inherit', fontSize: 'inherit', lineHeight: 1.55 }}>
                          {item}
                        </span>
                      </Group>
                    </UnstyledButton>
                  </div>
                ),
            )}
          </div>
        </Container>
      )}
    </Modal>
  );
};
