import React, { useContext } from 'react';
import { Table } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { RfsContext } from '../../../contexts/RfsContext';
import ProjectName from '../../projectName/projectName';
import ContentPaper from '../../../components/layouts/rfs/Content';
import FeedbackFormTable from './feedbackFormTable';
import { Row, Col } from 'react-bootstrap';
import { AppLoader } from '../../common/loader';
import { feedbackForFtr } from '../../../constants';
import { AuthContext } from '../../../contexts/AuthContext';

export default function FeedbackForm(props) {
  const params = useParams();
  const { userRole } = useContext(AuthContext);
  const { form } = useContext(RfsContext);

  const permissions = feedbackForFtr[form.rfsInformation.rfs_type];

  if (form.rfsInformation.rfs_type.length === 0) {
    return (
      <ContentPaper page={'FeedbackForm'}>
        <AppLoader center size='lg' />
      </ContentPaper>
    );
  }

  return (
    <>
      <ContentPaper page={'FeedbackForm'}>
        <ProjectName Pnumber={form.rfsInformation.project_name} initialItem={props.initialItems} />
        <div>
          <Row>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 6 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>Feedback for FTR</h5>
            </Col>
          </Row>
          <Table>
            <tbody
              style={{
                border: '2px solid white',

                color: 'black',
                backgroundColor: 'white',
                padding: '85px',
              }}
            >
              <FeedbackFormTable readOnly={!permissions['feedback'].includes(userRole)} />
            </tbody>
          </Table>
        </div>
      </ContentPaper>
    </>
  );
}
