import { Select, TextInput, Text, createStyles, rem, Badge } from '@mantine/core';
import styled from '@emotion/styled';
import { DatePickerInput } from '@mantine/dates';

export const CustomTextInput = styled(TextInput)`
  & .mantine-TextInput-input {
    height: 46px;
  }
`;

export const CustomDatePicker = styled(DatePickerInput)`
  & .mantine-DatePickerInput-input {
    height: 46px;
  }

  & .mantine-DatePickerInput-label {
    margin-bottom: 8px;
  }
`;

export const CustomSelect = styled(Select)`
  & .mantine-Select-input {
    height: 46px;
  }
`;

export const StyledText = styled(Text)`
  display: flex;
  align-items: center;
`;

export const StyledBadge = styled(Badge)`
  padding: '0.8rem';
`;

export const useAccordionStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.white,
    borderRadius: '20px',

    '& .mantine-UnstyledButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },

  item: {
    backgroundColor: theme.white,
    border: `${rem(1)} solid ${theme.colors['stm-yellow'][4]}`,
    zIndex: 0,
    borderRadius: '20px',

    '&[data-active]': {
      backgroundColor: theme.white,
      borderColor: theme.colors['stm-yellow'][4],
      borderRadius: '20px',
      zIndex: 1,
    },
  },
}));

export const Status = ({ status }) => {
  const colors = {
    Pending: 'yellow',
    'Not Started': 'gray',
    'In Progress': 'yellow',
    Completed: 'green',
  };
  return (
    <StyledBadge size='md' color={colors[status]}>
      {status}
    </StyledBadge>
  );
};

export const CountrySelect = styled(Select)`
  & .mantine-Select-input {
    height: 46px;
  }
`;

export const TableHeaderContent = styled.div`
  position: sticky;
  top: 46px;
  z-index: 10;
  background-color: #ffffff;
  margin-bottom: 10px;
`;
