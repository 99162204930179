import React, { useContext } from 'react';
import { Modal } from '@mantine/core';
import './Modal.css';
import { List } from './List';
import { RfsContext } from '../../../contexts/RfsContext';
import { AppLoader } from '../../../components/common/loader';

export const PsBomModal = ({ bomModal, setBomModal, data, unlockData }) => {
  const { loading } = useContext(RfsContext);

  const title = {
    liquid: 'Liquid BOM',
    skuBom: 'SKU BOM',
    skuUom: 'SKU UOM',
    skuEan: 'SKU EAN',
  };

  return (
    <>
      <Modal
        className='bom-modal'
        overflow='inside'
        opened={bomModal.state}
        onClose={() => {
          setBomModal({ ...bomModal, state: false });
        }}
        title={title[bomModal.type]}
      >
        {Object.keys(data).length > 0 && (
          <List data={data} unlockData={unlockData} type={bomModal.type} sku={bomModal.sku_id} />
        )}

        {loading && (
          <div className='loader-container'>
            <AppLoader size={'md'} />
          </div>
        )}
      </Modal>
    </>
  );
};
