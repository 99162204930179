import { Modal } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Table } from './bomTable';

export const ViewBom = ({ bomModal, handleClose }) => {
  const params = useParams();
  const [boms, setBoms] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (bomModal.open) {
      fetch();
    }
  }, [bomModal]);

  const fetch = async () => {
    try {
      setLoading(true);
      const { sku, plant } = bomModal;
      const response = await axios.post(`/costing-request/step/bom-component-list/`, {
        costing_request_id: params.crNumber,
        sku,
        plant,
      });

      setBoms(response.data.BOM || []);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size={'70%'} opened={bomModal.open} onClose={handleClose} title='BOM'>
      <Table loading={loading} boms={boms} />
    </Modal>
  );
};
