import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Space, Tabs } from '@mantine/core';
import { Col, Row } from 'react-bootstrap';

import ContentPaper from '../../../../components/layouts/rfs/Content';
import withConfirm from '../../../../components/common/confirm-dialog';
import { vicSubmissionConfirmPopup } from '../../constants';
import { tabStyles } from '../../../../utils/helpers';

import { CostComponent } from './cost';
import { BomComponent } from './bom';
import { ProjectOverview } from '../projectOverview';
import { Context, Provider } from './context';

const tabs = [
  {
    name: 'CONSOLIDATED COST COMPONENT',
    value: 'cost',
    component: (props) => <CostComponent {...props} />,
  },

  {
    name: 'BOM LEVEL VIEW',
    value: 'bom',
    component: (props) => <BomComponent {...props} />,
  },
];

const VicContent = ({ activeTab, confirm, setConfirmLoading }) => {
  const { onPageSubmit, resetAttachmentsModal, stepStatus, readOnly } = useContext(Context);
  const [activePlant, setActivePlant] = useState({ producing_plant: null, id: null });
  const [searchParams, setSearchParams] = useSearchParams();
  const activeSubTab = searchParams.get('subtab') || 'cost';

  useEffect(() => {
    resetAttachmentsModal();
  }, [activeSubTab]);

  const displayConfirmation = async () => {
    confirm(async () => {
      setConfirmLoading(true);
      await onPageSubmit({ plant: activePlant.producing_plant });
      setConfirmLoading(false);
    }, vicSubmissionConfirmPopup);
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        <Button
          className='next'
          onClick={displayConfirmation}
          variant='gradient'
          radius='md'
          size='md'
          fullWidth
          disabled={(stepStatus || '').toLowerCase() !== 'in progress' || readOnly}
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
        >
          Submit
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <ContentPaper footer={<Footer />}>
        <ProjectOverview />
        <Space h='sm' />
        <Tabs
          keepMounted={false}
          value={activeSubTab}
          onTabChange={(value) => setSearchParams({ tab: activeTab, subtab: value })}
          variant='unstyled'
          styles={tabStyles}
          className='prod-dev-tabs'
          color='#e5b611'
        >
          <Tabs.List>
            {tabs.map((tab, index) => (
              <Tabs.Tab value={tab.value} key={index} className='menu-tabs'>
                {tab.name}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {tabs.map((tab, index) => (
            <Tabs.Panel value={tab.value} key={index}>
              {tab.component({
                activeSubTab,
                activeTab,
                activePlant,
                setActivePlant,
              })}
            </Tabs.Panel>
          ))}
        </Tabs>
      </ContentPaper>
    </>
  );
};

const Vic = (props) => (
  <Provider>
    <VicContent {...props} />
  </Provider>
);

export default withConfirm(Vic);
