import React, { useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ScopeComp from '../../components/scopeComp/scopeComp';
import RfsLayout from '../../components/layouts/RfsLayout';
import { RfsContext } from '../../contexts/RfsContext';
import { AppLoader } from '../../components/common/loader';
import ScopeCompDLST from '../DLST/ScopeCompDLST/ScopeCompDLST';
import { DelistingProvider } from '../../contexts/DelistingContext';
import { CommonContext } from '../../contexts/common';
import SupplierScope from '../supplierChange/scope';
import { SupplierProvider } from '../../contexts/SupplierContext';

export default function ScopeInitiation2() {
  const params = useParams();
  const { info, form, fetchCostCenters, reset } = useContext(RfsContext);
  const { fetchUnits } = useContext(CommonContext);
  const { rfs_type } = form.rfsInformation;

  useEffect(() => {
    if (params.rfsNumber) {
      info(params.rfsNumber);
      fetchCostCenters();
      fetchUnits();
    }

    return () => reset();
  }, [params.rfsNumber]);

  const component = () => {
    const components = {
      Delisting: (
        <DelistingProvider>
          <ScopeCompDLST withProjectName initialItems={0} />
        </DelistingProvider>
      ),
      'Supplier Change': (
        <SupplierProvider>
          <SupplierScope withProjectName initialItem={0} />
        </SupplierProvider>
      ),
    };
    return components[rfs_type] ? (
      components[rfs_type]
    ) : (
      <ScopeComp withProjectName initialItem={0} />
    );
  };

  const ScopeComponent = useMemo(() => {
    return component();
  }, [params.rfsNumber, rfs_type]);

  // if (infoLoading)
  //   return (
  //     <RfsLayout>
  //       <AppLoader center size='lg' />
  //     </RfsLayout>
  //   );

  return <RfsLayout>{ScopeComponent}</RfsLayout>;
}
