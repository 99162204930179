import { useState, useContext } from 'react';
import { Button, Text, Navbar, Modal, Grid, Group } from '@mantine/core';
import {
  Home,
  InfoCircle,
  Download,
  Table,
  Forms,
  Plus,
  ReportAnalytics,
  ClipboardText,
  Logout,
  File,
} from 'tabler-icons-react';
import styled from '@emotion/styled';
import { Link, useParams } from 'react-router-dom';
import style from './sidebar.module.css';
import NavItem from './NavItem.js';
import { routes } from '../../routes';
import { AuthContext } from '../../contexts/AuthContext';
import { RfsContext } from '../../contexts/RfsContext';
import { PlusIcon } from '@radix-ui/react-icons';
import useActiveModule from '../../hooks/useActiveModule.js';

const BottomMenu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const StyledNav = styled(Navbar)`
  width: ${({ open }) => (open ? '250px' : '65px')};
  transition: width 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SideMenu = ({ open, setOpened }) => {
  const activeModule = useActiveModule();
  const { userRole } = useContext(AuthContext);
  const params = useParams();
  const [modal, setModal] = useState(false);

  const costingRequestMenu = [
    {
      label: (
        <Link style={{ color: 'unset' }} to={`${routes.costingRequests().root}`}>
          All Projects
        </Link>
      ),
      Icon: Home,
      visibility: true,
      to: `${routes.costingRequests().root}`,
      tooltip: 'All Projects',
    },
    {
      label: 'Create Project',
      Icon: PlusIcon,
      to: `${routes.costingRequests().create}`,
      visibility: true,
      tooltip: 'Create Project',
    },
  ];

  let rfsMenu = [
    {
      label: (
        <Link style={{ color: 'unset' }} to={routes.rfs.root}>
          All Projects
        </Link>
      ),
      Icon: Home,
      visibility: true,
      to: `${routes.rfs.root}`,
      tooltip: 'All Projects',
    },
    {
      label: 'Add New Project',
      Icon: Plus,
      to: `${routes.submission().add}`,
      visibility: true,
      tooltip: 'Add New Project',
      children: [
        {
          label: 'Information',
          Icon: InfoCircle,
          to: `${routes.submission(params.rfsNumber).info}`,
          type: 'rfs_submission',
          tooltip: 'Information',
        },
        {
          label: 'Scope',
          Icon: Table,
          to: `${routes.submission(params.rfsNumber).scopeSelect}`,
          type: 'rfs_submission',
          tooltip: 'Scope',
        },
        {
          label: 'Attachments',
          Icon: Download,
          to: `${routes.submission(params.rfsNumber).attachment}`,
          type: 'rfs_submission',
          tooltip: 'Attachments',
        },
        {
          label: 'Validate',
          Icon: Forms,
          to: `${routes.submission(params.rfsNumber).review}`,
          type: 'rfs_submission',
          tooltip: 'Validate',
        },
      ],
    },
    {
      label: 'ACCF Settings',
      Icon: ClipboardText,
      to: `${routes.accf.root}`,
      visibility: true,
      tooltip: 'ACCF Settings',
    },
    {
      label: 'Reports',
      Icon: ReportAnalytics,
      to: `${routes.reports.root}`,
      visibility: true,
      tooltip: 'Reports',
    },
  ];

  const modules = {
    rfs: rfsMenu,
    costingRequests: costingRequestMenu,
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const { downloadFile } = useContext(RfsContext);

  return (
    <StyledNav
      className={style.sidebar}
      hiddenBreakpoint={3000}
      onMouseEnter={() => setOpened(true)} // Set open to true on hover
      onMouseLeave={() => setOpened(false)} // Set open to false on hover exit
      open={open}
    >
      {/* Top Menu */}
      <div>
        {(modules[activeModule] || []).map((item, index) => {
          return (item.eligibleRoles && !item.eligibleRoles.includes(userRole)) ||
            !item.visibility ? null : (
            <NavItem key={`${item.label}-${index}`} item={item} onlyIcons={!open} />
          );
        })}
      </div>

      {/* Bottom Menu */}
      <BottomMenu>
        <NavItem
          item={{
            label: 'Privacy Policy',
            Icon: File,
            to: null,
            onClick: () => downloadFile('Privacy Policy.pdf'),
            visibility: true,
            tooltip: 'Privacy Policy',
          }}
          onlyIcons={!open}
        />

        <NavItem
          item={{
            label: 'Logout',
            Icon: Logout,
            to: null,
            onClick: () => setModal(true),
            visibility: true,
            tooltip: 'Logout',
          }}
          onlyIcons={!open}
        />
      </BottomMenu>

      <LogoutModal modal={modal} setModal={setModal} handleLogout={handleLogout} />
    </StyledNav>
  );
};

const LogoutModal = ({ modal, setModal, handleLogout }) => {
  return (
    <div>
      <Modal
        opened={modal}
        onClose={() => setModal(false)}
        title={
          <Text weight={500} align='center'>
            Are you sure you want to logout?
          </Text>
        }
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='invalid-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <Text size='sm'>
                You will be logged out of the website and all unsaved progress will be lost.
              </Text>
            </Group>
          </Grid.Col>

          <Grid.Col span={4} offset={8}>
            <Button
              onClick={() => {
                handleLogout();
              }}
              variant='gradient'
              color='primary'
              fullWidth
              style={{
                background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
                color: 'black',
              }}
            >
              Logout
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </div>
  );
};

export default SideMenu;
