import React, { useMemo, useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { useTable } from 'react-table';
import { Badge, Button, Checkbox, Text } from '@mantine/core';
import { Styles } from '../../../components/scopeComp/tableStyles';
import { roles } from '../../../constants';
import { AuthContext } from '../../../contexts/AuthContext';
export const SkuPlanningTable = ({ data, setLogModal, type, setManualClosureModal }) => {
  const { userRole } = useContext(AuthContext);
  const [disable, setDisable] = useState(true);
  useEffect(() => {
    if ([roles.pmo, roles.superAdmin, roles.snp].includes(userRole)) setDisable(false);
  }, [userRole]);
  const getLogs = (imp_id) => {
    setLogModal({ open: true, id: imp_id });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Plant',
        accessor: 'plant',
        minWidth: '100px',
        Cell: ({ row }) => {
          const { int_rfs_ps_sku_reference } = row.original;
          return <div>{int_rfs_ps_sku_reference.selected_plant}</div>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: '120px',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.status === 'In Progress' &&
                !row.original.finished_date &&
                moment(new Date()).format('YYYY-MM-DD') <=
                  moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'yellow'
                  : (row.original.status === 'In Progress' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.status === 'In Progress' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : row.original.status === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') <=
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'green'
                  : row.original.status === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'red'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.status}
            </Badge>
          );
        },
      },

      {
        Header: 'Action',
        accessor: 'col11',
        maxWidth: '120px',
        Cell: ({ row }) => {
          const step = row.original;
          if (step.status === 'Closed' && step.manually_closed) return 'Manual Closure';
          if (step.status === 'Closed' && !step.manually_closed) return 'Automatic Closure';

          return (
            <Checkbox
              style={{ padding: '10px' }}
              label={'Check and Close'}
              //disabled={disable}
              onChange={(event) => {
                if (event.target.checked) {
                  setManualClosureModal({
                    open: true,
                    id: step.id,
                    step: step.step,
                  });
                }
              }}
            />
          );
        },
      },
      {
        Header: 'Log',
        Cell: ({ row }) => {
          const info = row.original.int_rfs_impl_artwork_dev;
          return (
            <Button
              disabled={row.original.status === 'Not Started'}
              style={{ background: 'black', color:'white' }}
              onClick={() => getLogs(row.original.id)}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          const date = row.original.sla_date;
          return <Text>{date ? moment(date).format('YYYY-MM-DD') : 'N/A'}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          const date = row.original.finished_date;
          const status = row.original.status;
          return <Text>{date ? moment(date).format('YYYY-MM-DD') : status}</Text>;
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns: columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div>
      <Styles>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      width: column.width ? column.width : 'unset',
                      minWidth: column.minWidth ? column.minWidth : 'unset',
                      maxWidth: column.maxWidth ? column.maxWidth : 'unset',
                      textAlign: column?.columns?.length > 0 ? 'center' : 'unset',
                    }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Styles>
    </div>
  );
};
