import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from '@mantine/core';
import { ModalTable } from './table';
import { axios } from '../../../utils/axios';
import { AppLoader } from '../../common/loader';
import { msalConfig } from '../../../utils/authConfig';
// import './modal.css';

export const TechFeasibilityModal = ({ opened, setOpened }) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [otherProjectsInScope, setOtherProjectsInScope] = useState([]);

  const fetchModalData = (rfsNumber) => {
    setLoading(true);
    axios
      .get(`/tech-feasibility/${rfsNumber}`)
      .then(
        (res) => {
          const response = res.data.data;
          setOtherProjectsInScope(response);
        },
        (err) => {
          console.log(err);
        },
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchModalData(params.rfsNumber);
  }, []);

  return (
    <Modal
      // className='tech-feasibility-modal'
      overflow='inside'
      opened={opened}
      onClose={() => {
        setOpened(false);
      }}
      size='70%'
      title='Other Projects in scope'
    >
      {loading ? (
        <AppLoader size='sm' center />
      ) : (
        <ModalTable data={otherProjectsInScope} rfsNumber={params.rfsNumber} />
      )}
    </Modal>
  );
};

export default TechFeasibilityModal;
