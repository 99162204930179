import React from 'react';
import RfsLayout from '../../components/layouts/RfsLayout';
import ReportPage from './reportPage';

function ReportHomePage() {
  return (
    <RfsLayout>
      <ReportPage />
    </RfsLayout>
  );
}

export default ReportHomePage;
