import React from 'react';
import ReactDOM from 'react-dom';
import { Notifications } from '@mantine/notifications';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RfsProvider } from './contexts/RfsContext';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './utils/authConfig';
import { AuthProvider } from './contexts/AuthContext';
import { CommonProvider } from './contexts/common';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';

const msalInstance = new PublicClientApplication(msalConfig);

const stmTheme = {
  colors: {
    'stm-yellow': [
      '#fff8dc',
      '#fbebb2',
      '#f6de85',
      '#f3d157',
      '#efc329',
      '#d6aa10',
      '#a68408',
      '#775e04',
      '#483900',
      '#1b1300',
    ],
  },
  primaryColor: 'stm-yellow',
  defaultGradient: {
    from: '#e3af32',
    to: '#f4e00f',
    deg: 90,
  },
  components: {
    Button: {
      defaultProps: {
        color: 'primary',
        size: 'sm',
      },

      styles: (theme, { color, variant }) => {
        const isPrimaryGradient = variant === 'gradient' && ['primary', 'yellow'].includes(color);
        return {
          root: {
            color: ['primary', 'yellow'].includes(color) ? 'black' : 'white',
            background: isPrimaryGradient
              ? `linear-gradient(${theme.defaultGradient.deg}deg, ${theme.defaultGradient.from} 0%, ${theme.defaultGradient.to} 100%)`
              : theme.colors[color],
          },
        };
      },
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <MantineProvider withGlobalStyles withNormalizeCSS theme={stmTheme}>
      <MsalProvider instance={msalInstance}>
        <AuthProvider>
          <CommonProvider>
            <RfsProvider>
              <ModalsProvider>
                <Notifications position='top-right' limit={5} />
                <App style={{ documentbodystylezoom: '80%' }} />
              </ModalsProvider>
            </RfsProvider>
          </CommonProvider>
        </AuthProvider>
      </MsalProvider>
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
