import React, { useContext, useEffect, useState } from 'react';
import { MultiSelect, Text, TextInput, Textarea, Select } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';

import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';
import { routes } from '../../../routes';
import { CommonContext } from '../../../contexts/common';
import { Table } from './table';
import { SupplierContext } from '../../../contexts/SupplierContext';

const processTypes = [
  {
    value: '1',
    label: 'Scope Unlock',
  },
  {
    value: '0',
    label: 'Cancellation',
  },
];

function ScopeUnlockSupplierChange({
  form,
  footer,
  userName,
  values,
  setValues,
  readOnly = false,
}) {
  const { rfsNumber } = useParams();
  const [teams, setTeams] = useState([]);
  const { scScopeUnlockInfo = {}, materials: scopeMaterials } = useContext(ProjectOverviewContext);
  const { systemTeams } = useContext(CommonContext);
  const { fetchSavedMaterials } = useContext(SupplierContext);
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    if (scopeMaterials.length > 0) fetch();
  }, [rfsNumber, scopeMaterials]);

  const fetch = async () => {
    const data = await fetchSavedMaterials(rfsNumber);
    const formattedMaterials = [];
    data.forEach((material) => {
      const index = scopeMaterials.findIndex(
        (val) =>
          parseInt(val.id) === parseInt(material.scope_id) &&
          (!val.invalid || (val.invalid && val.selected)),
      );
      if (index > -1) {
        formattedMaterials.push({
          ...material,
          disabled: scopeMaterials[index].selected,
        });
      }
    });

    setMaterials(formattedMaterials);
  };

  const isDisabled = () => {
    return values.id;
  };

  const StatusMessage = () => {
    const { rfs_reversal_closure_date, child_rfs_number, scope_unlock_flag } = scScopeUnlockInfo;

    return rfs_reversal_closure_date ? (
      <div className='scope-unlock-status'>
        {scope_unlock_flag === '0' ? (
          'Scope unlock is complete'
        ) : (
          <>
            {`Scope unlock is complete.`} <br />
            <Link
              className='white rfs-link'
              to={`${routes.project(child_rfs_number).root}?tab=projectOverview`}
            >
              Click here to view child RFS <span className='blue'>{`${child_rfs_number}`}</span>
            </Link>
          </>
        )}
      </div>
    ) : (
      <div className='scope-unlock-status'>Reversal is in progress.</div>
    );
  };

  return (
    <>
      <ContentPaper page={'ScopeUnlock'} footer={footer}>
        <ProjectName />

        <div className='form-container'>
          <h5 className='sectionHeadings'>Scope Unlock</h5>

          {isDisabled() && <StatusMessage />}

          <TextInput
            className='linear-input'
            value={userName}
            label='Scope Unlock By'
            disabled={true}
          />
          <MultiSelect
            className='linear-input'
            placeholder='Pick the ones that apply'
            name='teams_involved'
            label={<Text size='xl'>Teams Involved</Text>}
            searchable
            clearable
            required
            nothingFound='No options'
            data={systemTeams}
            value={values?.teams_involved}
            onChange={(value) => {
              value = value.concat(teams);
              value = [...new Set(value)];
              setValues({ teams_involved: value }, null, 'sc');
            }}
            disabled={isDisabled() || readOnly}
          />

          <Select
            className='linear-input'
            placeholder='Team Responsible'
            name='teams_responsible'
            label={<Text size='xl'>Team Responsible</Text>}
            required
            searchable
            nothingFound='No options'
            data={[...systemTeams, { label: 'Initiator', value: 'initiator' }]}
            value={values?.teams_responsible}
            onChange={(value) => setValues({ teams_responsible: value }, null, 'sc')}
            disabled={isDisabled() || readOnly}
          />

          <Select
            className='linear-input'
            placeholder='Scope Unlock / Cancellation'
            name='scope_unlock_flag'
            label={<Text size='xl'>Select Process</Text>}
            required
            nothingFound='No options'
            data={processTypes}
            value={values?.scope_unlock_flag}
            onChange={(value) => setValues({ scope_unlock_flag: value }, null, 'sc')}
            disabled={isDisabled() || readOnly}
          />
          <Textarea
            className='linear-input'
            label='Comments'
            placeholder='State your reason for scope unlock/cancellation here (255 Character limit)'
            required
            name='comment'
            autosize
            value={values.comment?.length > 255 ? values.comment.substring(0, 255) : values.comment}
            minRows={4}
            onChange={(event) =>
              setValues(
                {
                  comment:
                    event.target.value.length > 255
                      ? event.target.value.substring(0, 255)
                      : event.target.value,
                },
                null,
                'sc',
              )
            }
            disabled={isDisabled() || readOnly}
          />
          <div style={{ display: 'flex' }}>
            <div style={{ width: '30rem', paddingLeft: '3rem' }}></div>
            <div>
              {values.comment?.length > 254 && (
                <div className='custom-mantine-Textarea-error'>
                  255 Character limit reached, exceeding character would be truncated on saving.
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='sku-table'>
          <h5 className='sectionHeadings'>Select materials for Scope Unlock/Cancellation</h5>

          <Table values={values} setValues={setValues} materials={materials} />
        </div>
      </ContentPaper>
    </>
  );
}

export default ScopeUnlockSupplierChange;
