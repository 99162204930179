import React, { useState, useEffect, useContext } from 'react';
import ObsoletesMrp from './obsoletesMrpTable/obsoletesMrp';
import { Text, Box, Button } from '@mantine/core';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { TableExport } from 'tabler-icons-react';
import { axios } from '../../../../utils/axios';
import { dlstImplPermissions } from '../../../../constants';
import { RfsContext } from '../../../../contexts/RfsContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import { sCodeStyles } from '../../../../utils/helpers';

export const ObsoletesApproval = ({ rfsNumber, activeTab }) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { form } = useContext(RfsContext);
  const { userRole } = useContext(AuthContext);
  const { rfs_type } = form.rfsInformation;

  const fetchObsoletesMrpApproval = () => {
    return axios.get(`delisting/obsoletes_approvals/fetch/${rfsNumber}`);
  };

  const refresh = (callback) => {
    fetchObsoletesMrpApproval().then((res) => {
      if (callback) callback();
      setTableData(res.data.data);
    });
  };

  const getCsvData = () => {
    const data = [];

    [...tableData].forEach((info) => {
      data.push({
        'SKU Code': info.int_rfs_scope_selection.selection_number,
        'SKU Description': info.int_rfs_scope_selection.selection_name,
        'Aligned with Obsoletes': info.int_rfs_impl_mrp_approval
          ? info.int_rfs_impl_mrp_approval.aligned_with_obsoletes === '1'
            ? 'Yes'
            : 'No'
          : 'Null',
        'Obsoletes Attachment': info.int_rfs_impl_mrp_approval
          ? info.int_rfs_impl_mrp_approval.obsolete_attachment
          : 'Null',
        'MRP Comments': info.int_rfs_impl_mrp_approval
          ? info.int_rfs_impl_mrp_approval.mrp_comment
          : 'Null',
        Status: info.status,
        'Step Deadline': moment(info.sla_date).format('DD-MM-YYYY'),
        'Step Finished': info.finished_date
          ? moment(info.finished_date).format('DD-MM-YYYY')
          : 'Not Finished',
      });
    });

    return data;
  };

  useEffect(() => {
    if (activeTab === 'oamrp') {
      setLoading(true);
      fetchObsoletesMrpApproval()
        .then(
          (res) => {
            setTableData(res.data.data);
          },
          (err) => {
            console.log(err);
          },
        )
        .finally(() => setLoading(false));
    }
  }, [rfsNumber, activeTab]);

  const permissions = dlstImplPermissions[rfs_type];

  return (
    <div>
      <Box sx={sCodeStyles}>SKU CODES</Box>
      <div style={{ marginTop: '1rem' }} className='exportToCsv-container'>
        <CSVLink
          data={getCsvData()}
          className='export-to-csv'
          filename={'Obsoletes Approval MRP_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button
            leftIcon={<TableExport />}
            color='primary'
            style={{
              marginBottom: '1rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      <ObsoletesMrp
        data={tableData}
        loading={loading}
        refresh={refresh}
        rfs_number={rfsNumber}
        readOnly={!permissions['obsoletes_approval'].includes(userRole)}
      />
    </div>
  );
};
