import React, { useState, useContext, useEffect } from 'react';
import { Text, Button } from '@mantine/core';
import { Photo } from 'tabler-icons-react';
import ProjectName from '../../../components/projectName/projectName';
import { Tabs } from '@mantine/core';
import { Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RfsContext } from '../../../contexts/RfsContext';
import { AttachmentModal } from '../../../components/modal/AttachmentModal';

import '../../DLST/attachmentPageDLST/attachmentPageDLST.css';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { AppLoader } from '../../../components/common/loader';
import { routes } from '../../../routes';
import { rfsStatus } from '../../../constants';
import { CustomDropzone } from '../../../common/dropzone';
import { FileList } from '../../../common/dropzone/fileList';

function SupplierChangeAttachment(props) {
  const params = useParams();
  const { fromOverview = false } = props;
  const navigate = useNavigate();
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [artworkRequired, setArtworkRequired] = useState(false);
  const [error, setError] = useState({ state: false, message: null });

  const { form, addAttachments, loading, saveAsDraft, downloadFile, downloadLocalFile } =
    useContext(RfsContext);

  const { status = '', fast_track } = form.rfsInformation;

  const { scopeSelectedMaterials = [] } = form;
  const { artworkBriefing, fastTrackApproval, emailAttachment } = form.attachments;

  useEffect(() => {
    if (scopeSelectedMaterials.length > 0) {
      const index = scopeSelectedMaterials.findIndex((material) => material.artwork_change);
      setArtworkRequired(index > -1);
    }
  }, [scopeSelectedMaterials]);

  const isDisabled = () => {
    return Boolean(fast_track) && fastTrackApproval.length === 0;
  };

  const handleNext = (callback) => {
    if (!callback) {
      addAttachments(callback);
      return;
    }
    if (isDisabled()) {
      setAttachmentModal(true);
    } else {
      addAttachments(callback);
    }
  };

  const Footer = () =>
    props.disableButtons ? null : (
      <Row className='justify-content-md-end' md={4}>
         {!fromOverview && (
        <Col md='auto'>
          <div classname='buttonRow'>
            <Link to={routes.submission(params.rfsNumber).scopeDefine}>
              <Button
                className='back'
                color='primary'
                radius='md'
                size='md'
                fullWidth
                style={{
                  marginRight: '1rem',
                  background: 'white',
                  color: 'black',
                  border: ' 0.25px solid gray',
                  width: '200px',
                }}
              >
                Back
              </Button>
            </Link>
          </div>
        </Col>
         )}

        <Col md='auto'>
          <Button
            disabled={saveAsDraft}
            className='save'
            color='primary'
            radius='md'
            size='md'
            fullWidth
            style={{ marginRight: '1rem', background: 'black', color: 'white', width: '200px' }}
            onClick={() => handleNext()}
          >
            {!saveAsDraft ? 'Save' : <AppLoader />}
          </Button>
        </Col>
        {!fromOverview && (
        <Col md='auto'>
          <Button
            disabled={loading}
            className='next'
            color='primary'
            radius='md'
            variant='gradient'
            gradient={{ from: '#e3af32', to: '#f4e00f' }}
            size='md'
            fullWidth
            style={{
              marginRight: '1rem',
              width: '200px',
            }}
            onClick={() =>
              handleNext(() =>
                status === rfsStatus.draft
                  ? navigate(routes.submission(params.rfsNumber).review)
                  : navigate(-1),
              )
            }
          >
            {!loading ? 'Next' : <AppLoader />}
          </Button>
        </Col>
        )}
      </Row>
    );

  return (
    <ContentPaper page={'attachments'} footer={<Footer />}>
      {props.withEdit && <div style={{ marginTop: '1rem' }} />}

      {props.withProjectName ? (
        <Row>
          <div className='projectNameComp'>
            <ProjectName initialItem={props.initialItem} />
          </div>
        </Row>
      ) : null}

      <Row>
        <Text size='sm' weight={600}>
          {props.withEdit ? 'Fast Track Approval' : 'Please upload Fast Track Approval'}
          {fast_track && (
            <span style={{ color: 'red', fontWeight: '400' }}>* (Required Field)</span>
          )}
        </Text>
        <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
          <Tabs.List>
            <Tabs.Tab value='Files' icon={<Photo size={14} />}>
              Files
            </Tabs.Tab>
            {!props.withEdit && (
              <Tabs.Tab value='Template' icon={<Photo size={14} />}>
                Template
              </Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value='Files' icon={<Photo size={14} />}>
            <CustomDropzone type={'fastTrackApproval'} setError={setError} />

            <FileList
              files={fastTrackApproval}
              type={'fastTrackApproval'}
              error={Boolean(fast_track) && fastTrackApproval.length === 0}
            />
            {error.state && error.type === 'fastTrackApproval' && (
              <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
            )}
          </Tabs.Panel>

          {!props.withEdit && (
            <Tabs.Panel value='Template' icon={<Photo size={14} />}>
              <Col md={{ span: 2 }}>
                <Button
                  style={{ marginBottom: '1rem', background: 'black', color: 'white' }}
                  color='primary'
                  fullWidth
                  onClick={() => downloadFile('Fast Track Document.pdf')}
                >
                  Download PDF
                </Button>
              </Col>
            </Tabs.Panel>
          )}
        </Tabs>
      </Row>

      <Row>
        <Row>
          <Text size='sm' weight={600}>
            {props.withEdit ? 'Artwork Briefing' : 'Please upload Artwork Briefing'}

            {artworkRequired && (
              <span style={{ color: 'red', fontWeight: '400' }}>* (Required Field)</span>
            )}
          </Text>

          <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
            <Tabs.List>
              <Tabs.Tab value='Files' icon={<Photo size={14} />}></Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value='Files'>
              <CustomDropzone type={'artworkBriefing'} setError={setError} />

              <FileList files={artworkBriefing} type={'artworkBriefing'} />
              {error.state && (
                <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
              )}
            </Tabs.Panel>
          </Tabs>
        </Row>
      </Row>

      <Row>
        <Row>
          <Text size='sm' weight={600}>
            {props.withEdit
              ? 'Additional Email Attachment'
              : 'Please upload Additional Email Attachment'}
          </Text>

          <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
            <Tabs.List>
              <Tabs.Tab value='Files' icon={<Photo size={14} />}></Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value='Files'>
              <CustomDropzone type={'emailAttachment'} setError={setError} maxFiles={10} size={8} />
              <FileList files={emailAttachment} type={'emailAttachment'} />
              {error.state && (
                <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
              )}
            </Tabs.Panel>
          </Tabs>
        </Row>
      </Row>

      {attachmentModal && (
        <AttachmentModal invalidModal={attachmentModal} setInvalidModal={setAttachmentModal} />
      )}
    </ContentPaper>
  );
}

export default SupplierChangeAttachment;
