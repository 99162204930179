import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import MantineTable from '../../../../common/table/mantine';
import { implementationColumns } from '../../../../common/table/template/implementation';
import { CommonColumnsContext } from '../../../../contexts/CommonColumns';
import { Button, NumberInput, Text, TextInput } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';
import { DownloadExcel } from '../../../../common/excel';

export const MaterialCodeCreation = ({ permissions, userRole }) => {
  const { rfsNumber } = useParams();
  const { setLogsModal, setActionModal } = useContext(CommonColumnsContext);
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeMaterials, setActiveMaterials] = useState({});
  const [form, setForm] = useState({});
  const readOnly = !permissions.includes(userRole);

  const fetch = useCallback(
    (loading = true) => {
      setLoading(loading);
      axios
        .get(`/supplier-changes/material_code_creation/${rfsNumber}`)
        .then(
          (res) => {
            const data = res.data.data;
            setMaterials(data);
          },
          (err) => {
            console.log(err);
          },
        )
        .finally(() => setLoading(false));
    },
    [rfsNumber],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleRowSelection = (data) => {
    let currentValues = { ...activeMaterials };
    currentValues = { ...data };

    setActiveMaterials(currentValues);
  };

  const handleDefaultSet = (event, type, selectedRows) => {};

  const handleChange = (event, info) => {
    const currentValues = { ...form };
    if (!currentValues[info.id]) currentValues[info.id] = {};
    currentValues[info.id] = { ...currentValues[info.id], [event.target.name]: event.target.value };

    setForm(currentValues);
  };

  const handleSubmit = ({ id, int_rfs_impl_material_creation, int_rfs_sc_material }, type) => {
    setForm((state) => {
      return {
        ...state,
        [id]: {
          ...state[id],
          [`${type}_loading`]: true,
        },
      };
    });
    const payload = {
      ...int_rfs_impl_material_creation,
      [type]: form[id][type],
      material_uid: int_rfs_sc_material.id,
    };

    axios
      .post(`supplier-changes/material_creation/${rfsNumber}`, payload)
      .then(
        (res) => {
          fetch(false);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() =>
        setForm((state) => {
          return {
            ...state,
            [id]: {
              ...state[id],
              [`${type}_loading`]: false,
            },
          };
        }),
      );
  };

  const SaveButton = ({ clickHandler, loading }) => (
    <Button
      style={{ padding: '10px', marginTop: '10px' }}
      onClick={clickHandler}
      position='Right'
      size='sm'
      color='primary'
      radius='xs'
      disabled={loading}
      fullWidth
    >
      {loading ? <AppLoader /> : 'Submit'}
    </Button>
  );

  let columns = useMemo(
    () => [
      {
        accessorKey: 'int_rfs_sc_material.material_code',
        header: 'Old Material Code',
      },
      {
        accessorKey: 'int_rfs_sc_material.material_description',
        header: 'Old Material Description',
      },
      {
        accessorKey: 'int_rfs_sc_material.artwork_change',
        header: 'Artwork Management',
      },
      {
        accessorKey: 'int_rfs_sc_material.comment',
        header: 'Comments',
      },
      {
        accessorKey: 'int_rfs_impl_material_creation.new_material_code',
        header: 'Material Code',
        Cell: ({ row }) => {
          const newMaterialCode = row.original.int_rfs_impl_material_creation?.new_material_code;
          const error =
            (form[row.original.id]?.new_material_code || '').toString().length > 10
              ? 'Invalid Material Code (Max Length: 10)'
              : false;
          return newMaterialCode ? (
            <Text>{newMaterialCode}</Text>
          ) : (
            <>
              <NumberInput
                placeholder='New Material Code'
                hideControls
                radius='md'
                value={form[row.original.id]?.new_material_code || ''}
                name='new_material_code'
                onChange={(value) =>
                  handleChange({ target: { name: 'new_material_code', value } }, row.original)
                }
                error={
                  (form[row.original.id]?.new_material_code || '').toString().length > 10
                    ? 'Invalid Material Code (Max Length: 10)'
                    : false
                }
                disabled={readOnly}
              />
              {form[row.original.id]?.new_material_code && (
                <SaveButton
                  disabled={error || readOnly}
                  loading={form[row.original.id]?.new_material_code_loading}
                  clickHandler={() => handleSubmit(row.original, 'new_material_code')}
                />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'int_rfs_impl_material_creation.new_material_desc',
        header: 'Material Description',
        Cell: ({ row }) => {
          const newMaterialCode = row.original.int_rfs_impl_material_creation?.new_material_code;
          const newMaterialDescription =
            row.original.int_rfs_impl_material_creation?.new_material_desc;

          const error =
            (form[row.original.id]?.new_material_desc || '').toString().length > 100
              ? 'Length exceeded (Max Length: 100)'
              : false;
          return newMaterialDescription ? (
            <Text>{newMaterialDescription}</Text>
          ) : (
            <>
              <TextInput
                placeholder='New Material Description'
                disabled={!newMaterialCode || readOnly}
                name='new_material_desc'
                onChange={(event) => handleChange(event, row.original)}
                value={form[row.original.id]?.new_material_desc || ''}
                error={error}
              />
              {form[row.original.id]?.new_material_desc && (
                <SaveButton
                  disabled={error || !newMaterialCode || readOnly}
                  loading={form[row.original.id]?.new_material_desc_loading}
                  clickHandler={() => handleSubmit(row.original, 'new_material_desc')}
                />
              )}
            </>
          );
        },
      },
    ],
    [form],
  );

  columns = columns.concat(
    implementationColumns(
      setLogsModal,
      setActionModal,
      {
        title: 'Material Code Creation',
        required: ['new_material_code'],
        fetchInfo: fetch,
      },
      readOnly,
    ),
  );

  return (
    <div>
      <DownloadExcel
        data={materials.map(
          ({
            int_rfs_sc_material,
            int_rfs_impl_material_creation,
            status,
            sla_date,
            finished_date,
            manually_closed,
          }) => {
            return {
              'Old Material Code': int_rfs_sc_material.material_code,
              'Old Material Description': int_rfs_sc_material.material_description,
              'Artwork Management': int_rfs_sc_material?.artwork_change || '',
              Comments: int_rfs_sc_material?.comment || '',
              'New Material Code': int_rfs_impl_material_creation?.new_material_code || '',
              'New Material Description': int_rfs_impl_material_creation?.new_material_desc || '',
              Status: status,
              Action:
                status !== 'Closed' ? '' : manually_closed ? 'Manual Closure' : 'Automatic Closure',
              'Step Deadline': moment(sla_date).format('DD-MM-YYYY'),
              'Step Finished': finished_date
                ? moment(finished_date).format('DD-MM-YYYY')
                : 'Not Finished',
            };
          },
        )}
        filename='Material_Code_Creation'
      />
      <MantineTable
        columns={columns}
        initialData={materials}
        unique={'id'}
        handleRowSelection={handleRowSelection}
        activeRows={activeMaterials}
        enableRowSelection={false}
        loading={loading}
        // resetTableData={resetTableData}
        editAll={false}
        applyToAll={handleDefaultSet}
        showSelectedToggle={false}
        showResetAll={false}
        hideSelectColumn={true}
      />
    </div>
  );
};
