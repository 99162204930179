import React, { useState, useMemo } from 'react';
import { useTable } from 'react-table';
import { Text, Badge, Button, Checkbox } from '@mantine/core';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { ProductionLog } from '../../../veam/productDevelopment/productionSiteLogModal';
import { ActionModal } from '../../../veam/productDevelopment/actionModal';
import { AppLoader } from '../../../../components/common/loader';
import moment from 'moment';

export function StatusAaAllMarketsTable({
  data,
  save,
  actionModal,
  setActionModal,
  loading,
  tableLoading,
  readOnly,
}) {
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const [otherStatus, setotherStatus] = useState({});

  function getLogs(imp_id) {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Plants',
        accessor: 'plants',
        Cell: ({ row }) => {
          return row.original.int_rfs_dlst_scope_plant.plant;
        },
      },
      {
        Header: 'Stock Volume (in HL)',
        accessor: 'stock_volume',
      },
      {
        Header: 'Type of delist',
        Cell: ({ row }) => {
          return <Text>{'Full'}</Text>;
        },
      },
      {
        Header: 'Status',
        Cell: ({ row }) => {
          const status = row?.original.status;

          return (
            <Badge
              color={status === 'In Progress' ? 'yellow' : status === 'Closed' ? 'green' : 'gray'}
              size='lg'
            >
              {status}
            </Badge>
          );
        },
      },

      {
        Header: 'Action',
        Cell: ({ row }) => {
          const status = row?.original?.status;

          return ['In Progress', 'Not Started'].includes(status) ? (
            <Checkbox
              onChange={() => {
                setotherStatus(row.original.id);
                setActionModal({ open: true, id: row.original.id });
              }}
              defaultChecked={false}
              label='Confirm and Close'
              disabled={status === 'Not Started' || readOnly}
            />
          ) : row.original.manually_closed && status === 'Closed' ? (
            <Text>Manual Closure</Text>
          ) : (
            <Text>Automatic Closure</Text>
          );
        },
      },
      {
        Header: 'Logs',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => getLogs(row.original.id)}
              style={{ background: 'black', color: 'white' }}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('YYYY-MM-DD')
                : row.original.status}
            </Text>
          );
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (tableLoading) return <AppLoader center size='md' />;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={otherStatus}
          updateLoading={loading}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          id={imp_id}
          setLogModal={setLogModal}
          type='Status change to AA'
        />
      )}
    </Styles>
  );
}
