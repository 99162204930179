import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, Text, Accordion } from '@mantine/core';
import { axios } from '../../utils/axios';

import './modal.css';
import { AppLoader } from '../../components/common/loader';

export const ImpFeasiblityModal = ({ opened, setOpened, fsd, materials = {} }) => {
  const [loading, setLoading] = useState(false);
  const [obsoletes, setObsoletes] = useState({});

  const fetchObsoletes = (material, fsd) => {
    const fsdWeek = moment(fsd, 'YYYY-MM-DD').format('ww');
    const fsdYear = moment(fsd, 'YYYY-MM-DD').format('yyyy');
    const fsdConverted = fsdYear + fsdWeek;

    if (!obsoletes[material]) {
      setLoading(true);
      axios
        .get(`/implementation/fetch/obsoletes/${material}/${fsdConverted}`)
        .then(
          (res) => {
            const currentValues = { ...obsoletes };
            currentValues[material] = res.data;

            setObsoletes(currentValues);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      className='imp-feasibility-modal'
      overflow='inside'
      opened={opened}
      onClose={() => {
        setOpened(false);
      }}
      title='Obsoletes Calculations: '
    >
      <div style={{ marginTop: '0.5rem' }}>
        {materials.map((material, key) => (
          <Accordion defaultValue='0'>
            <Accordion.Item
              className='imp-feas-accordion'
              onClick={() => fetchObsoletes(material.mat, fsd)}
              value='0'
            >
              <Accordion.Control>{`${material.mat} - ${material.matdesc}`}</Accordion.Control>
              <Accordion.Panel>
                {loading ? (
                  <AppLoader center />
                ) : (
                  <MaterialObsoletesCalculations obsoletes={obsoletes[material.mat]} />
                )}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        ))}
      </div>
    </Modal>
  );
};

const MaterialObsoletesCalculations = ({ obsoletes }) => (
  <>
    <Text className='caption' style={{ marginBottom: '1rem', fontWeight: 500, fontSize: '1rem' }}>
      Stock Coverage:{' '}
      <Text className='caption' size='300'>
        {obsoletes
          ? obsoletes?.stock.year
            ? `The stock lasts till ${obsoletes?.stock.week} weeks of
          ${obsoletes?.stock.year}`
            : obsoletes?.stock.message
          : 'No Data available'}
      </Text>
    </Text>

    <Text className='caption' style={{ marginBottom: '1rem', fontWeight: 500, fontSize: '1rem' }}>
      Obsoletes:{' '}
      <Text className='caption' size='300'>
        {obsoletes && obsoletes?.obsoletes !== 'Currency not available'
          ? `USD ${obsoletes?.obsoletes}`
          : 'No Data available'}
      </Text>
    </Text>

    <Text
      style={{
        marginBottom: '1rem',
        fontWeight: '500',
        fontSize: '1.1rem',
        color: '#e5b611',
      }}
    >
      Additional Information{' '}
      <Text className='caption' style={{ marginBottom: '1rem', fontWeight: 500, fontSize: '1rem' }}>
        Future Production:
        <Text className='caption' size='300'>
          {obsoletes && obsoletes?.futureProd.quantity !== null
            ? `${obsoletes?.futureProd.quantity} ${
                obsoletes.futureProd.unit ? obsoletes.futureProd.unit : ''
              }`
            : 'No Data available'}
        </Text>
      </Text>
    </Text>
  </>
);

export default ImpFeasiblityModal;
