import React from 'react';
import ContentPaper from '../../components/layouts/rfs/Content';
import {
  ChartInfographic,
  ReportAnalytics,
  ListCheck,
  FileAnalytics,
  ClipboardList,
  FileSpreadsheet,
  AddressBook,
} from 'tabler-icons-react';
import { Text, Card, Box, Grid, Title } from '@mantine/core';
import { routes } from '../../routes';
import { useNavigate } from 'react-router-dom';

function ReportPage() {
  const navigate = useNavigate();

  return (
    <ContentPaper page='reports-page' className='report-page'>
      <Title order={6}>
        <h5 className='report-page-headings'>Reports</h5>
      </Title>
      <Grid justify='left'>
        <Grid.Col shadow='md' span={8} md={1} lg={2}>
          <Card
            component='button'
            onClick={() => {
              navigate(routes.reports.rawData);
            }}
            className='report-button'
          >
            <Box mx='auto' maw={400}>
              <ChartInfographic py={20} size={46} />
              <Text className='report-text' fw={500}>
                Raw Data Report
              </Text>
            </Box>
          </Card>
        </Grid.Col>

        <Grid.Col shadow='md' span={6} md={1} lg={2}>
          <Card
            component='button'
            onClick={() => {
              navigate(routes.reports.bottleneck);
            }}
            className='report-button'
            fw={500}
          >
            <Box>
              <ReportAnalytics py={{ base: 'xs', sm: 'md', lg: 'xl' }} size={46} />
              <Text className='report-text' fw={500}>
                Bottleneck Report
              </Text>{' '}
            </Box>
          </Card>
        </Grid.Col>

        <Grid.Col span={6} md={1} lg={2}>
          <Card
            component='button'
            onClick={() => {
              navigate(routes.reports.ftr);
            }}
            className='report-button'
          >
            <Box justify='center'>
              <ListCheck py={{ base: 'xs', sm: 'md', lg: 'xl' }} size={46} />
              <Text className='report-text' fw={500}>
                First Time Right Report
              </Text>
            </Box>
          </Card>
        </Grid.Col>

        <Grid.Col span={6} md={1} lg={2}>
          <Card
            component='button'
            onClick={() => {
              navigate(routes.reports.smd);
            }}
            className='report-button'
          >
            <Box justify='center'>
              <FileAnalytics py={{ base: 'xs', sm: 'md', lg: 'xl' }} size={46} />
              <Text className='report-text' fw={500}>
                SMD Workload Report
              </Text>
            </Box>
          </Card>
        </Grid.Col>

        <Grid.Col span={6} md={1} lg={2}>
          <Card
            component='button'
            onClick={() => {
              navigate(routes.reports.concordance);
            }}
            className='report-button'
          >
            <Box justify='space-between'>
              <FileSpreadsheet py={{ base: 'xs', sm: 'md', lg: 'xl' }} size={46} />
              <Text className='report-text' fw={500}>
                Concordance Table Report
              </Text>
            </Box>
          </Card>
        </Grid.Col>
        <Grid.Col span={6} md={1} lg={2}>
          <Card
            component='button'
            onClick={() => {
              navigate(routes.reports.decomplexity);
            }}
            className='report-button'
          >
            <Box justify='space-between'>
              <ClipboardList py={{ base: 'xs', sm: 'md', lg: 'xl' }} size={46} />
              <Text className='report-text' fw={500}>
                Decomplexity Report
              </Text>
            </Box>
          </Card>
        </Grid.Col>
        <Grid.Col span={6} md={1} lg={2}>
          <Card
            component='button'
            onClick={() => {
              navigate(routes.reports.portfolio);
            }}
            className='report-button'
          >
            <Box justify='space-between'>
              <FileAnalytics py={{ base: 'xs', sm: 'md', lg: 'xl' }} size={46} />
              <Text className='report-text' fw={500}>
                Portfolio Catalog Report
              </Text>
            </Box>
          </Card>
        </Grid.Col>
        <Grid.Col span={6} md={1} lg={2}>
          <Card
            component='button'
            onClick={() => {
              navigate(routes.reports.stepsTeams);
            }}
            className='report-button'
          >
            <Box justify='space-between'>
              <AddressBook py={{ base: 'xs', sm: 'md', lg: 'xl' }} size={46} />
              <Text className='report-text' fw={500}>
                Teams And Steps Report
              </Text>
            </Box>
          </Card>
        </Grid.Col>
      </Grid>
    </ContentPaper>
  );
}

export default ReportPage;
