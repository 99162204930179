import { Table, Text } from '@mantine/core';
import { Styles } from '../../../components/tables/skutable/production-site/styles';

const separate = (data) => {
  let splitData = data.includes(',') ? data.split(', ') : [data];

  const separated = (splitData || []).map((item) => {
    const concat = item.split('-');
    return concat;
  });

  return separated;
};

const getVolume = (volume) => {
  return volume ? new Number(volume).toString() : 'N/A';
};

const ImpFeasTable = (data) => {
  return (
    <Styles>
      {Object.keys(data)?.map((k) => (
        <Table
          captionSide='top'
          verticalSpacing='xs'
          horizontalSpacing='md'
          striped
          style={{ width: '100%' }}
        >
          <thead>
            <tr>
              <th style={{ minWidth: '5rem', background :'#d8d8d8'}}>Sku Number</th>
              <th style={{ minWidth: '5rem', background :'#d8d8d8' }}>Description</th>
              <th style={{ minWidth: '5rem', background :'#d8d8d8' }}>Markets</th>
              <th style={{ minWidth: '5rem', background :'#d8d8d8' }}>52wks Volume</th>
              <th style={{ minWidth: '5rem', background :'#d8d8d8' }}>Unit</th>
            </tr>
          </thead>
          <tbody>
            {data[k]?.map((element, index) => (
              <tr key={`${index}`} className='imp-feasiblity-table'>
                <td>{element.skus}</td>
                <td>{element.desc}</td>
                <td>
                  {separate(element.markets_concat).map((markets) => (
                    <p style={{ marginBottom: '0px' }}>{markets[0]}</p>
                  ))}
                </td>
                <td>
                  {separate(element.markets_concat).map((volume) => (
                    <p style={{ marginBottom: '0px' }}>{getVolume(volume[1])}</p>
                  ))}
                </td>
                <td>
                  {separate(element.markets_concat).map((unit) => (
                    <p style={{ marginBottom: '0px' }}>{unit[2]}</p>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ))}
    </Styles>
  );
};

export default ImpFeasTable;
