import React from 'react';
import { Modal, Container, Table } from '@mantine/core';

const FTRLogModal = ({ logModal, setLogModal }) => {
  return (
    <Modal
      opened={logModal.open}
      onClose={() => setLogModal({ logs: false, id: null })}
      title='RFS ID'
      className='confirm-modal'
    >
      <Container>
        <RfsTable logs={(logModal?.logs || '').split(',')} />
      </Container>
    </Modal>
  );
};

const RfsTable = ({ logs }) => {
  return (
    <Table>
      <tbody>
        {logs.map((log, index) => (
          <tr key={index}>
            <td>{log}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default FTRLogModal;
