import React from 'react';
import { Modal, Accordion, Container, Text } from '@mantine/core';
import { X, Check } from 'tabler-icons-react';
import moment from 'moment';

export const Logs = ({ logsModal, setLogsModal }) => {
  const { logs = [], type, open } = { ...logsModal };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Modal
      opened={open}
      onClose={() => setLogsModal({ open: false, logs: [], type: null })}
      title='Logs'
      className='confirm-modal'
    >
      <Container>
        <Accordion
          defaultValue={'key_0'}
          overflow='inside'
          iconPosition='right'
          classNames={{
            label: 'accordion-label fw-bold',
            contentInner: 'accordion-innerContent',
            control: 'p-0',
          }}
        >
          <Accordion.Item className='scope-implementation-label' value='key_0'>
            <Accordion.Control>{`${capitalizeFirstLetter(type)}`}</Accordion.Control>
            <Accordion.Panel>
              {logs.length > 0 ? (
                logs.map((log, index) => {
                  return (
                    <div key={index}>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>Step: </span>
                        {capitalizeFirstLetter(type)}
                        <span style={{ paddingLeft: '0.5rem' }}>
                          {log.status === null || log.status === 'in progress' ? (
                            <X color='red' size='1rem' />
                          ) : (
                            <Check color='green' size='1rem' />
                          )}
                        </span>
                      </Text>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>Timestamp: </span>
                        {log.manually_closed_at
                          ? moment(log.manually_closed_at).format('DD-MM-YYYY')
                          : log.createdAt
                          ? moment(log.createdAt).format('DD-MM-YYYY')
                          : 'Not Started'}
                      </Text>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>Remark: </span>
                        {log.status === null || log.status === 'in progress'
                          ? log.remark
                          : log.status}
                      </Text>
                      {log.manually_closed_by ? (
                        <div>
                          <Text>
                            <span style={{ fontWeight: 'bold' }}>Manually closed by: </span>
                            {log.manually_closed_by}
                          </Text>
                          {log.manually_closed_comment && (
                            <Text>
                              <span style={{ fontWeight: 'bold' }}>Manually closed comment: </span>
                              {log.manually_closed_comment}
                            </Text>
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <br />
                    </div>
                  );
                })
              ) : (
                <div>
                  <Text>
                    <span style={{ fontWeight: 'bold' }}> No Logs Found </span>
                  </Text>
                </div>
              )}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Container>
    </Modal>
  );
};
