import { InfoSquare } from 'tabler-icons-react';
import { Text, Tooltip } from '@mantine/core';

export const UserRole = ({ activeModule, crUserRoles, userRole }) => {
  const getUserRole = () => {
    const stmRole =
      userRole &&
      userRole
        .split(/(?=[A-Z])/)
        .join(' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

    return activeModule === 'rfs' ? stmRole : crUserRoles[0];
  };

  const additionalRoles = crUserRoles.filter((role) => role !== crUserRoles[0]);

  const UserRoleText = (
    <Text size='xs' color='dimmed' className='data'>
      Current Role: {getUserRole()}
    </Text>
  );

  if (activeModule === 'rfs') {
    return UserRoleText;
  }

  if (additionalRoles.length > 0) {
    return (
      <Tooltip label={additionalRoles.join(',')} position='bottom' withArrow>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {UserRoleText}
          <InfoSquare size='1rem' color='rgb(229, 182, 17)' style={{ marginLeft: 4 }} />
        </div>
      </Tooltip>
    );
  }

  return UserRoleText;
};
