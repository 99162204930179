import React, { useContext, useEffect, useState } from 'react';
import { Box, TextInput, Button } from '@mantine/core';
import { RfsContext } from '../../contexts/RfsContext';
import { AppLoader } from '../../components/common/loader';
import { SubmissionTrueModal } from '../../components/modal/SubmissionTrueModal';

export const AccfComment = ({ slaId = 0, status, rfs_number }) => {
  const { storeAccfComment, sendAccf, getAccfComment } = useContext(RfsContext);
  const [accfComment, setAccfComment] = useState('comment');
  const [saveCommentLoading, setSaveCommentLoading] = useState(false);
  const [saveAccfLoading, setSaveAccfLoading] = useState(false);
  const [commentVal, setCommentVal] = useState('');
  const [alert, setAlert] = useState({ open: false, type: null });

  useEffect(() => {
    if (slaId > 0) getComment();
  }, [slaId]);

  const getComment = () => {
    getAccfComment({ rfs_number: rfs_number, sla_weeks_id: slaId }).then(
      (res) => {
        if (res.data.data.length > 0) setAccfComment(res?.data?.data[0]?.comment);
      },
      (error) => {},
    );
  };

  const sendComment = () => {
    setSaveCommentLoading(true);
    storeAccfComment({ rfs_number: rfs_number, comment: commentVal, sla_weeks_id: slaId })
      .then(
        (res) => {
          window.location.reload();
        },
        (error) => {},
      )
      .finally(() => setSaveCommentLoading(false));
  };

  const sendManualAccf = () => {
    setSaveAccfLoading(true);

    sendAccf({ rfs_number: rfs_number, sla_id: slaId, manual_accf: true })
      .then(
        (res) => {
          setAlert({ open: true, type: 'success' });
        },
        (error) => {
          setAlert({ open: true, type: 'error' });
        },
      )
      .finally(() => setSaveAccfLoading(false));
  };

  const handleClose = () => {
    setAlert({ open: false, type: null });
    window.location.reload();
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[3],

        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
        cursor: 'pointer',

        color: theme.colors.yellow[5],
        fontSize: theme.fontSizes.lg,
        fontWeight: 1000,

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        },
      })}
    >
      <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'end', width: '75%' }}>
          <TextInput
            disabled={status}
            placeholder={accfComment}
            label='ACCF Comment'
            onChange={(event) => setCommentVal(event.currentTarget.value)}
            style={{ width: '50%' }}
          />
          <Button
            disabled={saveCommentLoading || status}
            className='next'
            color='dark'
            radius='md'
            size='md'
            style={{ marginLeft: '1rem', width: '175px' }}
            onClick={sendComment}
          >
            {saveCommentLoading ? <AppLoader size='md' variant='dark' /> : 'Save Comment'}
          </Button>
        </div>
        <Button
          disabled={saveAccfLoading || status}
          className='next'
          color='dark'
          radius='md'
          size='md'
          style={{ marginRight: '2rem', width: '150px' }}
          onClick={sendManualAccf}
        >
          {saveAccfLoading ? <AppLoader size='md' variant='dark' /> : 'Send ACCF'}
        </Button>
      </div>

      {alert.open && (
        <SubmissionTrueModal
          text={
            alert.type === 'error'
              ? 'Unsuccessful, something went wrong'
              : 'Your ACCF has been sent successfully!'
          }
          open={alert.open}
          setOpen={handleClose}
          type={alert.type}
        />
      )}
    </Box>
  );
};
