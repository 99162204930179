import React, { useContext } from 'react';
import { Table } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { RfsContext } from '../../../contexts/RfsContext';
import ProjectName from '../../projectName/projectName';
import ContentPaper from '../../../components/layouts/rfs/Content';
import FeedbackFormTableVEAM from './feedbackFormTableVEAM';
import { Row, Col } from 'react-bootstrap';
import { AppLoader } from '../../common/loader';

export default function FeedbackFormVEAM(props) {
  const params = useParams();
  const { form } = useContext(RfsContext);

  if (form.rfsInformation.rfs_type.length === 0) {
    return (
      <ContentPaper page={'FeedbackForm'}>
        <AppLoader center size='lg' />
      </ContentPaper>
    );
  }

  return (
    <>
      <ContentPaper page={'FeedbackForm'}>
        <ProjectName Pnumber={form.rfsInformation.project_name} initialItem={props.initialItems} />
        <div>
          <Row>
            <Col
              md={{ span: 6 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>Feedback for FTR</h5>
            </Col>
          </Row>
          <Table>
            <tbody
              style={{
                border: '2px solid white',

                color: 'black',
                backgroundColor: 'white',
                padding: '85px',
              }}
            >
              <FeedbackFormTableVEAM />
            </tbody>
          </Table>
        </div>
      </ContentPaper>
    </>
  );
}
