export const roles = {
  superAdmin: 'superAdmin',
  developer: 'developer',
  pmo: 'pmo',
  admin: 'admin',
  initiator: 'initiator',
  masterDataTeam: 'masterDataTeam',
  procurement: 'procurement',
  d2p: 'd2p',
  dp: 'dp',
  mrp: 'mrp',
  zitec: 'zitec',
  scheduling: 'scheduling',
  snp: 'snp',
  tp: 'tp',
  user_admin: 'user_admin',
  req: 'req',
  standard_transfer_pricing: 'standard/transfer_pricing',
  masterdata: 'masterdata',
  transfer_pricing: 'transfer_pricing',
  standard: 'standard',
  fpna_brewery_manager:'fpna_brewery_manager',
  repack:'repack',
  fpna_brewery_heads:'fpna_brewery_heads'
};

export const crRoles = {
  requestor: 'Requestor',
  userAdmin: 'User Admin',
  scheduling: 'Scheduling',
  gcc: 'GCC FP&A',
  masterdata: 'MDM Spoc',
  fpna_brewery_manager:'FP&A Brewery Manager',
  procurement:'Procurement',
  repack:'Repack',
  fpna_brewery_heads:'FP&A Brewery Heads'
};

export const moduleRoles = {
  RFS: [
    { value: roles.superAdmin, label: 'Super Admin' },
    { value: roles.developer, label: 'Developer' },
    { value: roles.pmo, label: 'PMO' },
    { value: roles.admin, label: 'Admin' },
    { value: roles.initiator, label: 'Initiator' },
    { value: roles.masterDataTeam, label: 'Master Data' },
    { value: roles.procurement, label: 'Procurement' },
    { value: roles.d2p, label: 'Design to Print' },
    { value: roles.mrp, label: 'MRP' },
    { value: roles.zitec, label: 'Zitec' },
    { value: roles.scheduling, label: 'Scheduling' },
    { value: roles.standard_transfer_pricing, label: 'Standard/Transfer Pricing' },
  ],
  'Costing Request': [
    { value: roles.scheduling, label: crRoles.scheduling },
    { value: roles.req, label: crRoles.requestor },

    { value: 'standard/transfer_pricing', label: crRoles.gcc },
    { value: roles.user_admin, label: crRoles.userAdmin },
    { value: roles.fpna_brewery_manager, label: crRoles.fpna_brewery_manager },
    { value: roles.masterdata, label: crRoles.masterdata },
    { value: roles.procurement, label: crRoles.procurement },
    { value: roles.repack, label: crRoles.repack },
    { value: roles.fpna_brewery_heads, label: crRoles.fpna_brewery_heads },
  ],
};

export const teamroles = {
  superAdmin: 'Super Admin',
  developer: 'Developer',
  pmo: 'PMO',
  admin: 'Admin',
  initiator: 'Initiator',
  masterDataTeam: 'Master Data',
  procurement: 'Procurement',
  d2p: 'Design to Print',
  mrp: 'MRP',
  zitec: 'Zitec',
  scheduling: 'Scheduling',
};

export const teams = ['masterDataTeam', 'procurement', 'd2p', 'mrp', 'zitec', 'scheduling', 'pmo'];

export const teamMapping = {
  'trial material code open': teams[0],
  'changeover open': teams[0],
  'changeover removal open': teams[0],
  'changeover removal': teams[0],
  'supplier name open': teams[1],
  'contracts open': teams[1],
  'artwork development open': teams[2],
  'material production open': teams[3],
  'component liquid production open': teams[3],
  'ean approval open': teams[3],
  'trial failure status check open': teams[4],
  'filling and shipment open': teams[5],
  'technical feasibility': teams[6],
  'implementation feasibility': teams[6],
  //the conditions below are redundant and have to be removed when the string issue in the opentask api is resolved
  'component liquid in production at abi': teams[3],
};

export const rfsStatus = {
  draft: 'draft',
  submitted: 'submitted',
  rejected: 'rejected',
  tech_feasible: 'Tech. Feasible',
  tech_feasible2: 'Tech Feasible',
  product_development: 'Product Development',
  imp_feasible: 'Impl Feasible',
  implementation: 'Implementation',
  imp_feasible_rejected: 'Impl Feas Rejected',
  implemented: 'Implemented',
  scope_unlock: 'Scope Unlock',
  trial_material_code_completed: 'Trial Material Code Completed',
  supplier_name: 'Supplier Name Completed',
  artwork_development_not_needed: 'Artwork Development Not Needed',
  artwork_deelopment_completed: 'Artwork Development Completed',
  changeover_applied: 'Changeover Applied',
  ean_approved: 'EAN Approved',
  contracts_closed: 'Contracts Closed',
  reversal: 'Reversal',
  scope_restated: 'Scope Restated',
  reversed: 'Reversed',
  cancelled: 'Cancelled',
  scope_unlocked: 'Scope Unlocked',
  cancellation: 'Cancellation',
  supplier_pmo1_active: 'Tech Feasible',
  supplier_zitec_active: 'Approval Pending from ZITEC and D2P',
  supplier_d2p_active: 'Approval Pending from ZITEC and D2P',
  supplier_pmo2_active: 'Final Approval Pending from PMO',
};

export const materialchangeOverOptions = {
  hardChange: 'Hard Change',
  softChange: 'Soft change',
  mixChange: 'Mix Change',
  notSoonerThan: 'Not Sooner Than',
  notLaterThan: 'Not Later Than',
};

export const materialTechnicalPackChangeOptions = {
  location: 'Different "Production Location" Text',
  drawing: 'Change of technical drawing',
  removal: 'Removal of Material',
  thickness: 'Change of thickness (grammage of pack or my of crown caps or label paper)',
};

export const materialTechnicalQualityChangeOptions = {
  substrate: 'Change of substrate (alu/steel or solid /corrugated)',
  coating: 'Change coating ( lacquer / varnish)',
  prodTech: 'Change of production technology (embossing or rotogravure)',
  compQuality: 'Change of quality of component (internal coating or crown liner)',
};

export const materialArtworkChangeOptions = {
  text: 'Text change only',
  newColours: 'Design or technical specs change, with new colors',
  existingColours: 'Design or technical specs change, with existing colors',
  others: 'Others',
};

export const additionLiquid = {
  addition: 'Addition of Liquid BOM Material',
};

export const liquidBomChanges = {
  thickness: 'Liquid BOM Material change (New Material /supplier change/Material replacement)',
  drawing: 'Change of Liquid BOM quantities (ABV/RDF/Plato Change included)',
  removal: 'Removal of Liquid BOM Material',
  add: 'Addition of Liquid BOM Material',
};

export const materialTypes = {
  new: 'New Material',
  existing: 'Existing Material',
  remove: 'Remove Material',
};

export const liquidTypes = {
  same: 'Same Liquid',
  existing: 'Existing Liquid',
  existingSame: 'Existing Same Liquid',
  add: 'Add Liquid',
  new: 'New Liquid',
  remove: 'Remove Liquid',
};

export const implementationPermission = {
  'Open SKU to market': {
    market_extension: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
    demand_planning: [roles.pmo, roles.superAdmin, roles.dp], //is this dp or d2p
    transfer_pricing: [roles.pmo, roles.superAdmin, roles.standard_transfer_pricing], //what is GCC Pricing Team Member
    status_99: [roles.pmo, roles.superAdmin, roles.masterDataTeam], //what is SMD team member
  },
  'Supplier Change': {
    material_code_creation: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
    artowrk_development: [roles.pmo, roles.superAdmin, roles.d2p],
    material_contracts: [roles.pmo, roles.superAdmin, roles.procurement],
    changeover: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
  },
};

export const techFeasibilityPermissions = {
  'Supplier Change': {
    pmo_approval: [roles.pmo, roles.superAdmin],
    zitec_approval: [roles.pmo, roles.superAdmin, roles.zitec],
    d2p_approval: [roles.pmo, roles.superAdmin, roles.d2p],
    pmo_final_approval: [roles.pmo, roles.superAdmin],
  },
};

export const prodDevPermissions = {
  'Article Modification/Value Engineering': {
    material_code_creation: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
    changeover: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
    artowrk_development: [roles.pmo, roles.superAdmin, roles.d2p],
    trials: [roles.pmo, roles.superAdmin, roles.zitec],
    material_contracts: [roles.pmo, roles.superAdmin, roles.procurement],
    supplier_name: [roles.pmo, roles.superAdmin, roles.procurement],
    status_check: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
    ean: [roles.pmo, roles.superAdmin, roles.mrp],
    blocking_new_material: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
    filings_shipment: [roles.pmo, roles.superAdmin, roles.scheduling],
    material_status_change: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
    status_change: [roles.pmo, roles.superAdmin, roles.mrp],
    status_reversal: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
    pack_mats: [roles.pmo, roles.superAdmin, roles.procurement],
  },
};

export const dlstImplPermissions = {
  Delisting: {
    obsoletes_approval: [roles.pmo, roles.mrp, roles.superAdmin],
    demand_planning: [roles.pmo, roles.dp, roles.superAdmin],
    sku_planning: [roles.pmo, roles.snp, roles.superAdmin],
    status_zz: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
    status_aa: [roles.pmo, roles.superAdmin, roles.masterDataTeam],
  },
};

export const feedbackForFtr = {
  Delisting: {
    feedback: [
      roles.pmo,
      roles.mrp,
      roles.d2p,
      roles.procurement,
      roles.masterDataTeam,
      roles.superAdmin,
    ],
  },
  'Open SKU to market': {
    feedback: [roles.pmo, roles.dp, roles.masterDataTeam, roles.tp, roles.superAdmin],
  },
  'Production Site': {
    feedback: [
      roles.pmo,
      roles.mrp,
      roles.d2p,
      roles.procurement,
      roles.masterDataTeam,
      roles.tp,
      roles.scheduling,
      roles.zitec,
      roles.snp,
      roles.superAdmin,
    ],
  },
  'Supplier Change': {
    feedback: [
      roles.pmo,
      roles.d2p,
      roles.procurement,
      roles.masterDataTeam,
      roles.zitec,
      roles.superAdmin,
    ],
  },
};

export const reportPermissions = {
  decomplexity: [roles.pmo, roles.masterDataTeam, roles.superAdmin],
  rawDataReport: [roles.pmo, roles.masterDataTeam, roles.superAdmin],
  stepsteams: [roles.pmo, roles.masterDataTeam, roles.superAdmin],
  indivisualstepsteams: [roles.pmo, roles.masterDataTeam, roles.superAdmin],
};

export const RfsTypes = {
  veam: 'Article Modification/Value Engineering',
  delisting: 'Delisting',
  productionSite: 'Production Site',
  osku: 'Open SKU to market',
  supplierChange: 'Supplier Change',
};

export const liquidTypesMapping = {
  'Add Liquid': 'Add New Liquid Material',
  'Same Liquid': ' ',
  'New Liquid': 'Add New Liquid Material',
  'Existing Same Liquid': 'Add New Liquid Material',
};

export const NpsTitle = 'Help us improve the Speed to Market Tool!';

export const NpsIds = () => {
  const host = window.location.host;
  const prodEnv = ['stm-eu.ab-inbev.com'];
  const isProd = prodEnv.includes(host);
  return {
    submission: isProd ? 'submission-form' : undefined,
    techFeasibility: isProd ? 'tech-feasibility' : undefined,
    ftr: isProd ? 'feedback-ftr' : undefined,
  };
};

export const NpsProductId = () => {
  const host = window.location.host;
  const prodEnv = ['stm-eu.ab-inbev.com'];
  const isProd = prodEnv.includes(host);

  return isProd ? 'DB668216-8A49-46A4-BF96-38C68E2C8293' : '87216F1E-48A6-4D55-B3E0-70D01E4936CFE';
};

export const NpsEnv = () => {
  const host = window.location.host;
  const prodEnv = ['stm-eu.ab-inbev.com'];
  const isProd = prodEnv.includes(host);

  return isProd ? 'prod' : 'dev';
};

export const dateFormat = 'MM/DD/YYYY';

export const EXTENSIONS = {
  pdf: 'data:application/pdf;base64,',
  jpeg: 'data:image/jpeg;base64,',
  jpg: 'data:image/jpg;base64,',
  png: 'data:image/png;base64,',
  xls: 'data:application/vnd.ms-excel;base64,',
  xlsx: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
  msg: 'data:application/octet-stream;base64,',
  eml: 'data:application/octet-stream;base64,',
  csv: 'data:application/octet-stream;base64,',
  docx: 'data:application/msword;base64,',
  doc: 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,',
  ppt: 'data:application/vnd.ms-powerpoint;base64,',
  pptx: 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,',
};
