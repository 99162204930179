import { MantineProvider, useMantineTheme } from '@mantine/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { axios } from '../../utils/axios';
import { Text } from '@mantine/core';
import IndivisualStepsteamsModal from '../../pages/reports/indivisualStepModal';
import MantineTable from '../../common/table/mantine';

export const PercentTable = ({
  filters,
  target = 90,
  filteredRows,
  setFilteredRows,
  footerData,
  setFooterData,
}) => {
  const globalTheme = useMantineTheme;
  const [data, setData] = useState([]);

  const [individualStepModal, setIndividualStepModal] = useState({ id: null, open: false });
  const [selectedStepname, setStepName] = useState('Material Code Creation');
  const [selectedStepmonth, setStepMonth] = useState('9');
  const { gatekeeper, fast_track, year } = filters;

  const fetch = (payload) => {
    return axios.post('/reports/teams_and_steps', payload);
  };

  const useGet = () => {
    return useQuery({
      queryKey: ['fetch', filters],
      queryFn: () => fetch(filters),
      refetchOnWindowFocus: false,
    });
  };

  const formatData = (reportData) => {
    const teams = [];
    const subRows = {};

    reportData.forEach((step) => {
      if (!teams.includes(step.Teams)) {
        teams.push(step.Teams);
      }
      if (!subRows[step.Teams]) subRows[step.Teams] = [];
      subRows[step.Teams].push({
        ...step,
        Teams: step.Steps,
        total: step.numnerator === 0 ? null : step.total,
        clickable: true,
      });
    });

    Object.keys(subRows).forEach((key) => {
      const child = {};
      subRows[key].forEach((step, index) => {
        Object.keys(step).forEach((stepKey) => {
          if (stepKey.includes('RfsAgg')) {
            child[stepKey.split('RfsAgg')[0]] = step[stepKey];
          }
        });
        subRows[key][index]['subRows'] = [{ ...child, Teams: '# of RFS', clickable: false }];
      });
    });

    const finalData = [];
    teams.forEach((team) => {
      let totalData = {};
      subRows[team].forEach((step) => {
        if (Object.keys(totalData).length === 0) {
          totalData = { ...step, Teams: team };
        } else {
          totalData = {
            ...totalData,
            total: (totalData.total || 0) + (step.total || 0),
            January: (totalData.January || 0) + (step.January || 0),
            February: (totalData.February || 0) + (step.February || 0),
            March: (totalData.March || 0) + (step.March || 0),
            April: (totalData.April || 0) + (step.April || 0),
            May: (totalData.May || 0) + (step.May || 0),
            June: (totalData.June || 0) + (step.June || 0),
            July: (totalData.July || 0) + (step.July || 0),
            August: (totalData.August || 0) + (step.August || 0),
            September: (totalData.September || 0) + (step.September || 0),
            October: (totalData.October || 0) + (step.October || 0),
            November: (totalData.November || 0) + (step.November || 0),
            December: (totalData.December || 0) + (step.December || 0),
          };
        }
      });

      Object.keys(totalData).forEach((key) => {
        if (!['Teams', 'Steps'].includes(key)) {
          let totalRows = subRows[team].filter((row) => row[key]).length;
          totalRows = totalRows === 0 ? 1 : totalRows;
          totalData[key] = totalData[key] / totalRows;
          totalData[key] =
            totalData[key] === 0
              ? null
              : Number.isInteger(totalData[key])
              ? totalData[key]
              : totalData[key].toFixed(2);
        }
      });

      finalData.push({
        ...totalData,
        subRows: subRows[team],
        clickable: false,
      });
    });

    return finalData;
  };

  const formatFooterData = (data) => {
    const dataObject = {};
    const months = [
      'total',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (months.includes(key)) {
          const total =
            item[key] && dataObject[key]
              ? parseFloat(dataObject[key]) + parseFloat(item[key])
              : !item[key]
              ? dataObject[key]
              : !dataObject[key]
              ? item[key]
              : null;
          dataObject[key] = total;
        }
      });
    });

    Object.keys(dataObject).forEach((key) => {
      let totalRows = data.filter((row) => row[key]).length;
      totalRows = totalRows === 0 ? 1 : totalRows;

      dataObject[key] = dataObject[key] ? dataObject[key] / totalRows : null;
      dataObject[key] =
        dataObject[key] === 0 || !dataObject[key]
          ? null
          : Number.isInteger(dataObject[key])
          ? dataObject[key]
          : dataObject[key].toFixed(2);
    });

    return dataObject;
  };

  const { data: fetchedData = [], isFetching, isLoading, status } = useGet();

  useEffect(() => {
    if (status === 'success' && !isFetching) {
      let data = formatData(fetchedData.data.data);
      setData(data);
      setFilteredRows(data);
    }
  }, [status, isFetching]);

  useEffect(() => {
    const totalData = formatFooterData(filteredRows);
    setFooterData(totalData);
  }, [filteredRows]);

  const getClassName = (column, row) => {
    let className =
      column != null
        ? column < target
          ? 'column-option-3'
          : 'column-option-2'
        : 'column-option-1';
    return row.original.Teams === '# of RFS' ? 'column-option-1' : className;
  };

  function handleColumnClick(event, row, month, cellValue) {
    if (cellValue != null && cellValue >= 0) {
      setStepMonth(month);
      setStepName(row.original.Steps);
      setIndividualStepModal({ id: 1, open: true });
    }
  }

  const hidePercent = (row) => {
    return row.original.Teams !== '# of RFS' ? '%' : '';
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'Teams',
        header: 'Teams',
        sticky: true,
        footer: 'Total Performance',
        enablePinning: false,
      },
      {
        accessorKey: 'Target',
        header: 'Target',
        sticky: true,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue != null ? `${renderedCellValue}%` : `${target}%`}</Text>
        ),
        footer: `${target}%`,
        enablePinning: false,
      },
      {
        accessorKey: 'total',
        header: 'Total',
        enableEditing: false,
        enablePinning: false,
        size: 20,
        footer: footerData.total ? `${footerData.total}%` : 'N/A',
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.total}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'January',
        header: 'January',
        enableEditing: false,
        enableExpanding: true,
        enablePinning: false,
        footer: footerData.January ? `${footerData.January}%` : 'N/A',
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.January && row.original.January !== 0))
              return;

            handleColumnClick(event, row, 1, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.January && row.original.January !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.January}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'February',
        header: 'February',
        enableEditing: false,
        enablePinning: false,
        footer: footerData.February ? `${footerData.February}%` : 'N/A',
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.February && row.original.February !== 0))
              return;

            handleColumnClick(event, row, 2, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.February && row.original.February !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.February}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'March',
        header: 'March',
        footer: footerData.March ? `${footerData.March}%` : 'N/A',
        enableEditing: false,
        enablePinning: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.March && row.original.March !== 0))
              return;
            handleColumnClick(event, row, 3, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.March && row.original.March !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.March}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'April',
        header: 'April',
        footer: footerData.April ? `${footerData.April}%` : 'N/A',
        enableEditing: false,
        enablePinning: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.April && row.original.April !== 0))
              return;
            handleColumnClick(event, row, 4, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.April && row.original.April !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.April}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'May',
        header: 'May',
        footer: footerData.May ? `${footerData.May}%` : 'N/A',
        enableEditing: false,
        enablePinning: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.May && row.original.May !== 0)) return;
            handleColumnClick(event, row, 5, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.May && row.original.May !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.May}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'June',
        header: 'June',
        footer: footerData.June ? `${footerData.June}%` : 'N/A',
        enableEditing: false,
        enablePinning: false,
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.June && row.original.June !== 0)) return;
            handleColumnClick(event, row, 6, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.June && row.original.June !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.June}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'July',
        header: 'July',
        enableEditing: false,
        enablePinning: false,
        footer: footerData.July ? `${footerData.July}%` : 'N/A',
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.July && row.original.July !== 0)) return;
            handleColumnClick(event, row, 7, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.July && row.original.July !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.July}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'August',
        header: 'August',
        enableEditing: false,
        enablePinning: false,
        footer: footerData.August ? `${footerData.August}%` : 'N/A',
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.August && row.original.August !== 0))
              return;
            handleColumnClick(event, row, 8, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.August && row.original.August !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.August}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'September',
        header: 'September',
        enableEditing: false,
        enablePinning: false,
        footer: footerData.September ? `${footerData.September}%` : 'N/A',
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (
              !row.original.clickable ||
              (!row.original.September && row.original.September !== 0)
            )
              return;
            handleColumnClick(event, row, 9, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.September && row.original.September !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.September}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'October',
        header: 'October',
        enableEditing: false,
        enablePinning: false,
        footer: footerData.October ? `${footerData.October}%` : 'N/A',
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.October && row.original.October !== 0))
              return;
            handleColumnClick(event, row, 10, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.October && row.original.October !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.October}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'November',
        header: 'November',
        enableEditing: false,
        enablePinning: false,
        footer: footerData.November ? `${footerData.November}%` : 'N/A',
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.November && row.original.November !== 0))
              return;
            handleColumnClick(event, row, 11, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.November && row.original.November !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.November}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
      {
        accessorKey: 'December',
        header: 'December',
        enableEditing: false,
        enablePinning: false,
        footer: footerData.December ? `${footerData.December}%` : 'N/A',
        mantineTableBodyCellProps: ({ cell, row }) => ({
          className: getClassName(cell.getValue(), row),
          onClick: (event) => {
            if (!row.original.clickable || (!row.original.December && row.original.December !== 0))
              return;
            handleColumnClick(event, row, 12, cell.getValue());
          },
          sx: {
            cursor:
              !row.original.clickable || (!row.original.December && row.original.December !== 0)
                ? ''
                : 'pointer',
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue != null ? `${row.original.December}${hidePercent(row)}` : 'N/A'}
          </Text>
        ),
      },
    ],
    [target, filters, footerData],
  );

  return (
    <div>
      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 8 }}>
        <MantineTable
          columns={columns}
          initialData={data}
          enableExpanding={true}
          enablePinning={true}
          enableStickyFooter={true}
          enableRowSelection={false}
          hideSelectColumn={true}
          initialState={{
            showColumnFilters: true,
            columnPinning: {
              left: ['mrt-row-expand', 'Teams', 'Target'],
              right: [''],
            },
          }}
          sendFilteredData={setFilteredRows}
          loading={isLoading}
          showSelectedToggle={false}
          enablePagination={false}
          enableBottomToolbar={false}
        />
      </MantineProvider>
      {individualStepModal.open && (
        <IndivisualStepsteamsModal
          individualStepModal={individualStepModal}
          setIndivisualstepsModal={setIndividualStepModal}
          selecteddata={{
            selectedStepname,
            selectedFastrack: fast_track,
            selectedYear: year,
            selectedStepmonth,
            selectedStepGatekeeper: gatekeeper,
          }}
        />
      )}
    </div>
  );
};
