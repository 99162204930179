import React, { useEffect, useState } from 'react';
import { Text, Group } from '@mantine/core';
import { Clock, CircleCheck, CircleX } from 'tabler-icons-react';
import { axios } from '../../utils/axios';
import { Link, useParams } from 'react-router-dom';
import { AppLoader } from '../common/loader';

const implArray = ['Market Extension', 'Demand Planning', 'Transfer Pricing', 'Status 99'];

const teamResponsibleMapping = {
  'Project Submission': 'Initiator',
  'Technical Feasibility': 'PMO',
  'Implementation Feasibility': 'PMO',
  'Market Extension': 'Supply Master Data',
  'Demand Planning': 'Demand Planning',
  'Transfer Pricing': 'GCC FP&A',
  'Status 99': 'Supply Master Data',
};

const stepMapping = {
  'Market Extension': 'me',
  'Demand Planning': 'dp',
  'Transfer Pricing': 'tp',
  'Status 99': 's99',
  'Technical Feasibility': 'tf',
  'Project Submission': 'projectOverview',
  'Implementation Feasibility': 'if',
};

const OSTMWorkflow = () => {
  const params = useParams();
  const [steps, setSteps] = useState([]);
  const [stepsPnameImplArr, setStepsPnameImplArr] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const [firstStep, setFirstStep] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    function fetchSteps() {
      axios
        .get(`/openSku/sku/gantt/${params.rfsNumber}`)
        .then(
          (res) => {
            const info = res.data.steps.filter((step) => !['Implementation'].includes(step.pName));
            const implementation = res.data.steps.findIndex(
              (data) => data.pName === 'Implementation',
            );
            const prodDev = res.data.steps.findIndex(
              (data) => data.pName === 'Product Development',
            );

            setFirstStep({
              prodDev: res.data.steps[prodDev + 1]?.pName,
              implementation: res.data.steps[implementation + 1]?.pName,
            });
            setSteps(info);
          },
          (error) => {},
        )
        .finally(() => setLoading(false));
    }

    fetchSteps();
  }, [params.rfsNumber]);

  useEffect(() => {
    let implStepArray = steps
      .filter((item) => implArray.includes(item.pName))
      .map((item) => item.pName);

    setStepsPnameImplArr(implStepArray);
  }, [steps]);

  /*const tabURL = (name) => {
     let Index = 'imp' ;
     subIndex = stepMapping[name];
  
      return '?tab=' + Index + '&sub=' + subIndex;
    };*/

  const goToLink = (name, pID) => {
    let tab = 1;
    switch (name) {
      case 'Project Submission':
        tab = '?tab=projectOverview';
        break;
      case 'Technical Feasibility':
        tab = '?tab=tf';
        break;
      case 'Implementation Feasibility':
        tab = '?tab=if';
        break;
      case 'Market Extension':
        tab = '?tab=imp&sub=me';
        break;
      case 'Demand Planning':
        tab = '?tab=imp&sub=dp';
        break;
      case 'Transfer Pricing':
        tab = '?tab=imp&sub=tp';
        break;
      case 'Status 99':
        tab = '?tab=imp&sub=s99';
        break;
      case 'Filling and Shipment':
        tab = '?tab=imp&sub=fs';
        break;
      default:
        tab = '?tab=projectOverview';
    }
    return `/project/overview/${params.rfsNumber}${tab}`;
  };

  return (
    <div className='workflow-tab'>
      {loading && <AppLoader center size='md' />}
      {!loading && steps.length === 0 && (
        <Text ta='center' size='md' color='dimmed' weight={400} fontWeight={'bold'}>
          No steps to display
        </Text>
      )}
      {steps.map((step, index) => {
        const teamResponsible = teamResponsibleMapping[step.pName] || '';
        const isActive = urlParams.get('sub')
          ? stepMapping[step.pName.trim()] === urlParams.get('sub')
          : ['pd', 'imp'].includes(urlParams.get('tab'))
          ? urlParams.get('tab') === 'pd'
            ? firstStep.prodDev === step.pName
            : firstStep.implementation === step.pName
          : stepMapping[step.pName.trim()] === urlParams.get('tab');

        return (
          <Group
            key={step.pID}
            noWrap
            style={{ padding: '0.5rem', borderLeft: isActive ? '8px solid #f5e003' : 'none' }}
            className={isActive ? 'TabActive' : ''}
          >
            {step.status === 'Closed' ? (
              <CircleCheck color='#90EE90' size={18} />
            ) : step.status === 'Not Started' ? (
              <CircleX color='grey' size={18} />
            ) : step.status === 'In Progress' ? (
              <Clock color='orange' size={18} />
            ) : null}
            <div>
              <Link
                to={goToLink(step.pName, step.pID)}
                onClick={() => localStorage.setItem('pId', step.pID)}
              >
                <Text
                  color={isActive ? '#f5e003' : '#FFFFFF'}
                  fontWeight={isActive ? 'bold' : 'normal'}
                  size={isActive ? 'md' : 'sm'}
                >
                  {step.pName}
                </Text>
              </Link>

              <Text size='sm' color='dimmed' weight={400}>
                Team: {teamResponsible}
              </Text>
              <Text size='sm' color='dimmed' weight={400}>
                Status: {step.status}
              </Text>
            </div>
          </Group>
        );
      })}
    </div>
  );
};
export default OSTMWorkflow;
