import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { Text, Badge, Button, TextInput, Checkbox, NumberInput } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { useRef } from 'react';
import { RfsContext } from '../../../../contexts/RfsContext';
import { ProductionLog } from '../productionSiteLogModal';
import { ActionModal } from '../actionModal';
import { Id } from 'tabler-icons-react';
import { json } from 'react-router-dom';

export function MaterialCodeTableVeam({ name, materialData, onChange, save, rfsNumber, readOnly }) {
  const [filterLoading, setFilterLoading] = useState(false);
  const data = materialData;
  const { submitMaterialCreationVeam, fetchLogs } = useContext(RfsContext);
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const [status, setStatus] = useState('');
  const [checked, setChecked] = useState(false);

  const [form, setForm] = useState({
    material_id: 0,
    new_material_code: 0,
    new_material_desc: '',
  });
  const formRef = useRef(null);
  formRef.current = form;

  const handleChange = (name, value, id, scope_id, rfsNumber) => {
    const currentValues = { ...form };
    currentValues[name] = value;
    currentValues['material_id'] = id;
    currentValues['scope_id'] = scope_id;
    currentValues['rfsNumber'] = rfsNumber;
    setForm(currentValues);
  };

  function postMaterialCodeData(id) {
    submitMaterialCreationVeam(formRef.current);
    onChange(id, 'new_material_code', formRef.current.new_material_code, name);
  }

  function postMaterialDescData(id) {
    submitMaterialCreationVeam(formRef.current);
    onChange(id, 'new_material_desc', formRef.current.new_material_desc, name);
  }

  function getLogs(imp_id) {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Old Material Code',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_material.material_code}</Text>;
        },
      },
      {
        Header: 'Old Material Name',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_material.material_description}</Text>;
        },
      },
      {
        Header: 'Plant',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_material.plant}</Text>;
        },
      },
      {
        Header: 'Technical Pack Change',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_material.technical_pack_change}</Text>;
        },
      },
      {
        Header: 'Technical Quantity Change',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_material.technical_quality_change}</Text>;
        },
      },
      {
        Header: 'Artwork Management',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.int_rfs_material.artwork_change
                ? row.original.int_rfs_material.artwork_change
                : 'N/A'}
            </Text>
          );
        },
      },
      {
        Header: 'Technical Specification',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.int_rfs_material.tech_spec_matrix
                ? row.original.int_rfs_material.tech_spec_matrix
                : 'N/A'}
            </Text>
          );
        },
      },
      {
        Header: 'Comments',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_material.comment}</Text>;
        },
      },
      {
        Header: 'New Material Code',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_material_creation.new_material_code;
          const showSubmit =
            formRef.current.new_material_code > 0 &&
            formRef.current.material_id === row.original.int_rfs_material.id;
          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_material_creation.new_material_code}</Text>
          ) : (
            <>
              <NumberInput
                placeholder='New Material Code (Required)'
                radius='md'
                hideControls
                max={9999999999}
                onChange={(event) =>
                  handleChange(
                    'new_material_code',
                    event,
                    row.original.int_rfs_material.id,
                    row.original.int_rfs_material.scope_id,
                    rfsNumber,
                  )
                }
                disabled={readOnly}
                style={{ minWidth: '210px' }}
              />
              {showSubmit && (
                <Button
                  disabled={
                    !formRef.current ||
                    formRef.current.new_material_code === '' ||
                    formRef.current.new_material_code > 9999999999 ||
                    readOnly
                  }
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  onClick={() => postMaterialCodeData(row.original.id)}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'New Material Description',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_material_creation.new_material_desc;
          const value =
            formRef.current.material_id === row.original.int_rfs_material.id
              ? formRef.current.new_material_desc
              : '';
          const showSubmit =
            value &&
            value.length > 0 &&
            value.length < 257 &&
            formRef.current.new_material_desc.length > 0 &&
            formRef.current.material_id === row.original.int_rfs_material.id;

          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_material_creation.new_material_desc}</Text>
          ) : (
            <>
              <TextInput
                placeholder='New Material Description (Optional)'
                radius='md'
                value={row.original.int_rfs_impl_material_creation.new_material_desc}
                error={(value || '').length > 256 ? 'Limit Reached (Max Length: 256)' : false}
                onChange={(event) =>
                  handleChange(
                    'new_material_desc',
                    event.target.value,
                    row.original.int_rfs_material.id,
                    row.original.int_rfs_material.scope_id,
                    rfsNumber,
                  )
                }
                disabled={readOnly}
                style={{ minWidth: '248px' }}
              />
              {showSubmit && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  onClick={() => postMaterialDescData(row.original.id)}
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  disabled={readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Status',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.status === 'In Progress'
                  ? 'yellow'
                  : row.original.status === 'Closed'
                  ? 'green'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.status}
            </Badge>
          );
        },
      },
      {
        Header: 'Action',
        Cell: ({ row }) => {
          let checkDisabled =
            row.original.int_rfs_impl_material_creation.new_material_code && row.original.status;

          return row.original.status === 'In Progress' || row.original.status === 'Not Started' ? (
            <Checkbox
              disabled={!checkDisabled || readOnly}
              onChange={(event) => {
                setChecked(event.target.checked);
                setStatus(row.original.id);
                setActionModal({ open: true, id: row.original.id });
              }}
              label='Confirm and Close'
            />
          ) : row.original.manually_closed && row.original.status === 'Closed' ? (
            <Text>Manually Closed</Text>
          ) : (
            <Text>Automatic Closure</Text>
          );
        },
      },
      {
        Header: 'Log',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => getLogs(row.original.id)}
              style={{ background: 'black', color: 'white' }}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('YYYY-MM-DD')
                : row.original.status}
            </Text>
          );
        },
      },
    ],
    [materialData],
  );

  const columns_L = useMemo(
    () => [
      {
        Header: 'Old Liquid Code',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.int_rfs_liquid.liquid_code
                ? row.original.int_rfs_liquid.liquid_code
                : 'N/A'}
            </Text>
          );
        },
      },
      {
        Header: 'Old Liquid Name',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.int_rfs_liquid.liquid_description
                ? row.original.int_rfs_liquid.liquid_description
                : 'N/A'}
            </Text>
          );
        },
      },
      {
        Header: 'Plant',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_liquid.plant}</Text>;
        },
      },
      {
        Header: 'Type Of Change',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_liquid.bom_changes}</Text>;
        },
      },
      {
        Header: 'New Quantity',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_liquid.new_quantity}</Text>;
        },
      },
      {
        Header: 'Unit',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.int_rfs_liquid.unit ? row.original.int_rfs_liquid.unit : 'N/A'}
            </Text>
          );
        },
      },
      {
        Header: 'Technical Specification',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.int_rfs_liquid.tech_specification
                ? row.original.int_rfs_liquid.tech_specification
                : 'N/A'}
            </Text>
          );
        },
      },
      /* {
        Header: 'Technical Quantity Change',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_liquid.technical_quality_change}</Text>;
        },
      }, */
      /* {
        Header: 'Artwork Management',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.int_rfs_liquid.artwork_change
                ? row.original.int_rfs_liquid.artwork_change
                : 'N/A'}
            </Text>
          );
        },
      }, */
      /* {
        Header: 'New Tech. Spec.',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.int_rfs_liquid.tech_spec
                ? row.original.int_rfs_liquid.tech_spec
                : 'N/A'}
            </Text>
          );
        },
      }, */
      {
        Header: 'Comments',
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_liquid.comment}</Text>;
        },
      },
      {
        Header: 'New Liquid Code',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_material_creation.new_material_code;
          const showSubmit =
            formRef.current.new_material_code > 0 &&
            formRef.current.material_id === row.original.int_rfs_liquid.id;
          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_material_creation.new_material_code}</Text>
          ) : (
            <>
              <NumberInput
                placeholder='New Liquid Code (Required)'
                radius='md'
                hideControls
                max={9999999999}
                onChange={(event) =>
                  handleChange(
                    'new_material_code',
                    event,
                    row.original.int_rfs_liquid.id,
                    row.original.int_rfs_liquid.scope_id,
                    rfsNumber,
                  )
                }
                disabled={readOnly}
                style={{ minWidth: '210px' }}
              />
              {showSubmit && (
                <Button
                  disabled={
                    !formRef.current ||
                    formRef.current.new_material_code === '' ||
                    formRef.current.new_material_code > 9999999999 ||
                    readOnly
                  }
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  onClick={() => postMaterialCodeData(row.original.id)}
                  position='Right'
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'New Liquid Description',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_material_creation.new_material_desc;
          const value =
            formRef.current.material_id === row.original.int_rfs_liquid.id
              ? formRef.current.new_material_desc
              : '';
          const showSubmit =
            value &&
            value.length > 0 &&
            value.length < 257 &&
            formRef.current.new_material_desc.length > 0 &&
            formRef.current.material_id === row.original.int_rfs_liquid.id;

          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_material_creation.new_material_desc}</Text>
          ) : (
            <>
              <TextInput
                placeholder='New Liquid Description (Optional)'
                radius='md'
                value={row.original.int_rfs_impl_material_creation.new_material_desc}
                onChange={(event) =>
                  handleChange(
                    'new_material_desc',
                    event.target.value,
                    row.original.int_rfs_liquid.id,
                    row.original.int_rfs_liquid.scope_id,
                    rfsNumber,
                  )
                }
                disabled={readOnly}
                error={(value || '').length > 256 ? 'Limit Reached (Max Length: 256)' : false}
                style={{ minWidth: '235px' }}
              />
              {showSubmit && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  onClick={() => postMaterialDescData(row.original.id)}
                  size='sm'
                  color='primary'
                  radius='xs'
                  fullWidth
                  disabled={readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Status',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.status === 'In Progress'
                  ? 'yellow'
                  : row.original.status === 'Closed'
                  ? 'green'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.status}
            </Badge>
          );
        },
      },
      {
        Header: 'Action',
        Cell: ({ row }) => {
          let checkDisabled =
            row.original.int_rfs_impl_material_creation.new_material_code && row.original.status;

          return row.original.status === 'In Progress' || row.original.status === 'Not Started' ? (
            <Checkbox
              disabled={!checkDisabled || readOnly}
              onChange={(event) => {
                setChecked(event.target.checked);
                setStatus(row.original.id);
                setActionModal({ open: true, id: row.original.id });
              }}
              label='Confirm and Close'
            />
          ) : row.original.manually_closed && row.original.status === 'Closed' ? (
            <Text>Manually Closed</Text>
          ) : (
            <Text>Automatic Closure</Text>
          );
        },
      },
      {
        Header: 'Log',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => getLogs(row.original.id)}
              style={{ background: 'black', color: 'white' }}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('YYYY-MM-DD')
                : row.original.status}
            </Text>
          );
        },
      },
    ],
    [materialData],
  );

  const tableInstance = useTable({
    columns: name === 'Materials' ? columns : columns_L,
    data,
  });

  const getScrollPositionDiv = (event, crtrgt) => {
    createScrollStopListener(event.target, () => {
      let scrollValues = {
        scrollVal: crtrgt,
        divName: event.target.className,
      };
      localStorage.setItem('scrollValues', JSON.stringify(scrollValues));
    });
  };

  const createScrollStopListener = (element, callback) => {
    var handle = null;
    var onScroll = function () {
      if (handle) {
        clearTimeout(handle);
      }
      handle = setTimeout(callback, 1000);
    };
    element.addEventListener('scroll', onScroll);
    return () => element.removeEventListener('scroll', onScroll);
  };

  const scrollGoto = () => {
    let scrollValues = localStorage.getItem('scrollValues')
      ? JSON.parse(localStorage.getItem('scrollValues'))
      : '';
    const { divName, scrollVal } = scrollValues;
    if (document.querySelector('.' + divName))
      document.querySelector('.' + divName).scrollTo(scrollVal, 0);
  };

  useEffect(() => {
    scrollGoto();
  }, [actionModal, materialData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div
        className={name === 'Materials' ? 'divscM' : 'divscL'}
        style={{ overflowX: 'auto' }}
        onScroll={(event) => getScrollPositionDiv(event, event.currentTarget.scrollLeft)}
      >
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            {filterLoading && (
              <tr>
                <td colSpan={13}>
                  <div className='loader-container'>
                    <AppLoader size={'md'} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={status}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Material Code Creation'
        />
      )}
    </Styles>
  );
}
