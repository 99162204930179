import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { Checkbox, Modal, Button } from '@mantine/core';
import { MarketTable } from './markettable';
import './skuTable.css';
import { axios } from '../../../utils/axios';
import { RfsContext } from '../../../contexts/RfsContext';
import { AppLoader } from '../../common/loader';
import { Grid } from '@mantine/core';

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 10rem;
      padding: 0.8rem;
      text-align: center;
      width: 22rem;

      :last-child {
        border-right: 0;
      }
    }

    #header-row {
      background-color: #d8d8d8;
    }
    tr:nth-child(even) {
      background-color: #cdcdcd;
    }
    tr:nth-child(odd) {
      background-color: #fff;
    }
  }
  input {
    background-color: #fff;
  }
`;

export function SkuTable({ index, name, scope, isSubmissionReview = false, accordionState }) {
  const [availableMarkets, setAvailableMarkets] = useState([]);
  const [MModal, setMModal] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const { form, setForm, allMarkets } = useContext(RfsContext);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (isFirstLoad && allMarkets.length > 0 && accordionState === index) {
      setIsFirstLoad(false);
      setFilterLoading(true);
      axios
        .get(`openSku/sku/volumes/${name}`)
        .then((volumes) => {
          if (volumes.length === 0) volumes.data.data.push({ i: 'and' });

          const scopeSelectedSkus = form.scopeSelectedSkus.filter(
            (item) => item.scope_id.toString() === scope.id.toString(),
          );

          if (scopeSelectedSkus.length === 0) {
            const data = [
              ...form.scopeSelectedSkus,
              ...volumes.data.data.map((i) => {
                return {
                  ...i,
                  active: true,
                  new_market: false,
                  scope_id: scope.id,
                  sku_code: scope.selection_number,
                };
              }),
            ];
            setForm((o) => {
              return {
                ...o,
                scopeSelectedSkus: data,
              };
            });
          }

          setAvailableMarkets(allMarkets);
        })
        .finally(() => setFilterLoading(false));
    }
  }, [form.scopeSelectedSkus.length, allMarkets, accordionState]);

  const handleAddMarket = (data) => {
    const currentValues = [...form.scopeSelectedSkus];
    currentValues.push({
      ...data,
      active: true,
      new_market: true,
      scope_id: scope.id,
      sku_code: scope.selection_number,
    });

    setForm((o) => {
      return {
        ...o,
        scopeSelectedSkus: currentValues,
      };
    });

    setMModal(false);
  };

  const getVolume = (number = 0) => {
    number = number.toString();
    if (number.indexOf('.') > -1) {
      return parseFloat(number).toFixed(2);
    } else {
      return parseInt(number);
    }
  };

  const handleSkuSelect = (event) => {
    let currentValues = [...form.scopeSelectedSkus];
    const index = currentValues.findIndex(
      (market) =>
        market.sku_code.toString() === event.target.name.split('-')[0].toString() &&
        market.market === event.target.name.split('-')[1],
    );

    if (index > -1) {
      currentValues[index]['active'] = event.target.checked;
      setForm((o) => {
        return {
          ...o,
          scopeSelectedSkus: currentValues,
        };
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'checkbox',
        width: '3rem',
        Cell: ({ row }) => {
          return (
            <Checkbox
              name={`${scope.selection_number}-${row.original.market}`}
              size='xs'
              checked={row.original.active}
              disabled={!row.original.new_market || isSubmissionReview}
              onChange={handleSkuSelect}
            />
          );
        },
      },
      {
        Header: 'Markets',
        accessor: 'market_region',
        width: '20rem',
      },
      {
        Header: 'Volume (in HL)',
        accessor: 'volume',
        width: '20rem',
        Cell: ({ row }) => {
          return <div>{getVolume(row.original.volume)}</div>;
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: '20rem',
        Cell: ({ row }) => <div>{row?.original?.new_market ? 'New' : 'Existing'}</div>,
      },
    ],
    [form?.scopeSelectedSkus.length],
  );

  const data = form.scopeSelectedSkus.filter(
    (item) => item.scope_id.toString() === scope.id.toString(),
  );

  const tableInstance = useTable({
    columns,
    data: data,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {!isSubmissionReview && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color='dark' onClick={() => setMModal(true)}>
            {' '}
            Add New Markets
          </Button>
        </div>
      )}
      <Grid justify='center' align='center'>
        <Grid.Col span={12}>
          <Styles>
            <div style={{ overflowX: 'auto' }}>
              <table {...getTableProps()} style={{ margin: 'auto' }}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          style={{
                            width: column.width ? column.width : 'unset',
                            minWidth: column.minWidth ? column.minWidth : 'unset',
                            maxWidth: column.maxWidth ? column.maxWidth : 'unset',
                            textAlign: column?.columns?.length > 0 ? 'center' : 'unset',
                          }}
                        >
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {data.length === 0 && !filterLoading ? (
                    <td>No existing market found for this SKU. Please add new markets</td>
                  ) : (
                    rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={{
                                  width: cell.width ? cell.width : 'unset',
                                  minWidth: cell.minWidth ? cell.minWidth : 'unset',
                                  maxWidth: cell.maxWidth ? cell.maxWidth : 'unset',
                                  textAlign: cell?.cells?.length > 0 ? 'center' : 'unset',
                                }}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                  {filterLoading && (
                    <tr>
                      <td colSpan={13}>
                        <div className='loader-container'>
                          <AppLoader size={'md'} />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {MModal && (
              <Modal
                opened={MModal}
                onClose={() => {
                  setMModal(false);
                }}
                title='Markets'
              >
                <MarketTable
                  setOpen={setMModal}
                  availableMarkets={availableMarkets}
                  handleAddMarket={handleAddMarket}
                  existingVolumes={form.scopeSelectedSkus.filter(
                    (item) => item.scope_id.toString() === scope.id.toString(),
                  )}
                />
              </Modal>
            )}
          </Styles>
        </Grid.Col>
      </Grid>
    </div>
  );
}
